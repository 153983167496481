import React from 'react';

import StatisticsPage from '../StatisticsPage/StatisticsPage';

import { statisticsOverviewType } from 'util/statisticsOverviewType';

const MembersStatisticsPage = (props) => {
  return <StatisticsPage type={statisticsOverviewType.MEMBERS} {...props} />;
};

export default MembersStatisticsPage;
