import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { tabData } from 'common';

import './style.scss';

const TabsContainer = ({ selectedIndex, onSelect, tabList = [] }) => {
  const visibleTabList = useMemo(() => tabList.filter(({ hide }) => !hide), [tabList]);

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={onSelect}>
      <TabList>
        {visibleTabList.map(({ id, label, labelClass, hasError }) => (
          <Tab key={`label-${id}`}>
            <span
              className={classNames(
                {
                  error: hasError,
                },
                labelClass
              )}>
              {label}
            </span>
          </Tab>
        ))}
      </TabList>

      {visibleTabList.map(({ id, component, componentWrapperClass, forceRender }, index) => (
        <TabPanel
          forceRender={forceRender}
          key={`component-${id}`}
          className={classNames(
            'react-tabs__tab-panel',
            {
              'react-tabs__tab-panel--invisible': selectedIndex !== index,
            },
            componentWrapperClass
          )}>
          {component}
        </TabPanel>
      ))}
    </Tabs>
  );
};

TabsContainer.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  tabList: PropTypes.arrayOf(tabData),
};

export default TabsContainer;
