import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { withLoading } from 'hoc';
import { ClickableArrowIcon } from 'components';
import CompanySubsidiaryChartDetailContainer from '../CompanySubsidiaryChartDetailContainer/CompanySubsidiaryChartDetailContainer';
import {
  companySubsidiaryStatisticTypes,
  companySubsidiaryStatisticTypeConstants,
} from 'util/companySubsidiaryStatisticTypes';
import { getChartDetailTraslateNameId } from 'util/chartRenderUtil';

import '../style.scss';

const PrimaryChartDetail = ({ profile, type, chartDetail, intl: { formatMessage } }) => {
  const history = useHistory();

  const onArrowClick = ({ state, path, search }) => {
    history.push({ pathname: path, search }, state);
  };

  const getDetails = (type) => {
    const typeChartDetails =
      (companySubsidiaryStatisticTypeConstants[type] &&
        companySubsidiaryStatisticTypeConstants[type].getChartDetailData(chartDetail, profile)) ||
      [];

    return typeChartDetails.map(
      ({
        key,
        color,
        value,
        hasRoleToNavigate,
        navigationData: { disabled, ...navData } = {},
      }) => ({
        //TODO: Change user icon when icon is available
        firstIcon: <i className="fas fa-user-circle" style={{ color: color }} />,
        lastIcon: hasRoleToNavigate ? (
          <ClickableArrowIcon
            color={color}
            disabled={disabled}
            onClick={() => onArrowClick(navData)}
            orientation="right"
          />
        ) : null,
        label: getChartDetailTraslateNameId(key)
          ? formatMessage({ id: getChartDetailTraslateNameId(key) })
          : key,
        value: value || value === 0 ? value : formatMessage({ id: 'COMMON.NO_DATA' }),
      })
    );
  };

  return (
    <CompanySubsidiaryChartDetailContainer
      titleLabel={formatMessage({ id: 'COMPANY_SUBSIDIARY_STATISTICS.CURRENT_STATUS' })}
      details={getDetails(type)}
    />
  );
};

PrimaryChartDetail.propTypes = {
  type: PropTypes.oneOf(Object.values(companySubsidiaryStatisticTypes)),
  titleValue: PropTypes.number,
  chartDetail: PropTypes.object,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

export default connect(mapStateToProps)(withLoading(injectIntl(PrimaryChartDetail)));
