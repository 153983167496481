import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Collapse } from 'components';

const CollapseField = ({ children, title, initiallyOpen }) => {
  const [open, setOpen] = useState(initiallyOpen);

  return (
    <div className="col-12 mb-2">
      <Collapse open={open} setOpen={setOpen} className="col-12 mx-2" title={title}>
        <div className="pt-2 mx-4">{children}</div>
      </Collapse>
    </div>
  );
};

CollapseField.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  initiallyOpen: PropTypes.bool,
};

export default CollapseField;
