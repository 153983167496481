import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Tag } from 'components';
import { lessonsStatus, lessonsStatusConstants } from 'libs/lessonsStatus';

const LessonStatusTag = ({ status, intl: { formatMessage } }) => {
  const { tagColor, tagBackgroundColor, translateId } = lessonsStatusConstants[status] || {};
  return lessonsStatusConstants[status] ? (
    <Tag
      color={tagColor}
      backgroundColor={tagBackgroundColor}
      text={translateId ? formatMessage({ id: translateId }) : ''}
    />
  ) : null;
};

LessonStatusTag.propTypes = {
  status: PropTypes.oneOf(Object.values(lessonsStatus)),
};

export default injectIntl(LessonStatusTag);
