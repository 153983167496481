import * as LessonsService from '../services/lessons';

import * as actionTypes from 'actions/action-types';

export const getLessons = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_LESSONS,
      });

      //TODO After BE is finished the request call should be modified to match the search type expected by the backend
      //SearchType is an integer located in params.filter.searchType
      const lessons = await LessonsService.getLessons(params);

      dispatch({
        type: actionTypes.GET_LESSONS_SUCCESS,
        payload: lessons,
      });

      return lessons;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_LESSONS_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
