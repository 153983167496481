import React, { Component } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { IconButton } from '../';

import './style.scss';

export default class Dropdown extends Component {
  static propTypes = {
    alignRight: PropTypes.bool,
    iconClass: PropTypes.string,
    children: PropTypes.node.isRequired,
    opener: PropTypes.node,
  };

  static defaultProps = {
    alignRight: true,
    iconClass: 'fas fa-bars',
  };

  id = null;
  rootElement = null;
  dropdown = null;

  componentDidMount() {
    this.id = uniqueId('dropdown-');
  }

  componentWillUnmount() {
    this.close();
  }

  toggle() {
    window.$(this.rootElement).dropdown('toggle');
  }

  close() {
    if (this.dropdown.classList.contains('show')) {
      this.toggle();
    }
  }

  render() {
    const { alignRight, children, iconClass, opener, ...props } = this.props;
    return (
      <div className="Dropdown dropdown" ref={(r) => (this.rootElement = r)} {...props}>
        {opener ? (
          React.cloneElement(opener, { 'data-toggle': 'dropdown' })
        ) : (
          <IconButton
            id={this.id}
            className="menu-icon"
            type="button"
            iconClass={iconClass}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
        )}
        <div
          className={classNames('dropdown-menu', { 'dropdown-menu-right': alignRight })}
          ref={(r) => (this.dropdown = r)}
          aria-labelledby={this.id}>
          {children}
        </div>
      </div>
    );
  }
}
