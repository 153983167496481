import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getPreviewContent } from 'util/filePreviewUtil';
import { IconButton } from 'components/IconButton';

import './style.scss';

const FilePreview = ({
  file,
  type = 'file',
  getFileUrl,
  deleteSelection,
  disabledClick,
  onClick,
}) => (
  <div className="file-preview">
    {getPreviewContent({
      type,
      file,
      className: classNames('file-preview__preview', {
        'file-preview__preview--readonly': disabledClick,
      }),
      onClick: !disabledClick ? onClick : undefined,
      getFileUrl,
    })}
    {deleteSelection ? (
      <IconButton
        type="button"
        className="file-preview__close"
        iconClass="lemon icon-close purple"
        onClick={deleteSelection}
      />
    ) : null}
  </div>
);

FilePreview.propTypes = {
  file: PropTypes.object.isRequired,
  getFileUrl: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.string,
  deleteSelection: PropTypes.func,
  disabledClick: PropTypes.bool,
};

export default FilePreview;
