import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ApiErrorMessage, Notification, LogoAnimation } from 'components';
import AppLoader from './AppLoader';
import InitialRouting from '../InitialRouting';

import './style.scss';

const App = ({
  addNotification,
  removeNotification,
  resetApiError,
  validateToken,
  show,
  notificationList,
  apiError,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        await validateToken();
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [validateToken]);

  useEffect(() => {
    if (apiError.error && apiError.notification) {
      addNotification(<ApiErrorMessage error={apiError.error} />);
      resetApiError();
    }
  }, [apiError, addNotification, resetApiError]);

  return (
    <div>
      <AppLoader show={show} />
      <Notification notifications={notificationList} close={removeNotification} />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center app__loader-icon">
          <LogoAnimation size="lg" dark />
        </div>
      ) : (
        <Switch>
          <Route path="/" component={InitialRouting} />
        </Switch>
      )}
    </div>
  );
};

App.propTypes = {
  addNotification: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  resetApiError: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  notificationList: PropTypes.array.isRequired,
  apiError: PropTypes.shape({
    unauthorized: PropTypes.bool,
    error: PropTypes.object,
    notification: PropTypes.bool,
  }).isRequired,
};

export default App;
