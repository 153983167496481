import React from 'react';

import { match } from 'common';
import { OnPageRouter } from 'components';
import PathPage from './PathPage';
import PathForm from './PathForm';

const Path = ({ match }) => {
  return <OnPageRouter match={match} Page={PathPage} Editor={PathForm} />;
};

Path.propTypes = {
  match,
};

export default Path;
