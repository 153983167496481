import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Table = ({ className, children }) => {
  return (
    <div className={classNames('table-responsive')}>
      <table className={classNames('table base-table', className)}>{children}</table>
    </div>
  );
};

Table.propTypes = { className: PropTypes.string, children: PropTypes.node };

export default Table;
