import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { InlineError } from 'components';

const getErrorMessage = (error) =>
  error ? (
    typeof error === 'string' ? (
      <InlineError key={'error-' + uuidv4()} show={!!error} message={error} />
    ) : Array.isArray(error) ? (
      error.map((error) => getErrorMessage(error))
    ) : typeof error === 'object' && error.id ? (
      <InlineError key={'error-' + uuidv4()} show={!!error} message={error} />
    ) : null
  ) : null;

const withError = (Component) => {
  const wrappedWithError = (props) => {
    const {
      mainErrors,
      afterWrappedComponent,
      mainErrorsClassName = 'witherror error-container',
      ...remainingProps
    } = props;
    const errorOutput = <div className={mainErrorsClassName}>{getErrorMessage(mainErrors)}</div>;
    return (
      <>
        {!afterWrappedComponent && errorOutput ? errorOutput : null}
        <Component {...remainingProps} />
        {afterWrappedComponent && errorOutput ? errorOutput : null}
      </>
    );
  };

  wrappedWithError.propTypes = {
    afterWrappedComponent: PropTypes.bool,
    mainErrorsClassName: PropTypes.string,
    mainErrors: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        values: PropTypes.object,
      }),
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            values: PropTypes.object,
          }),
          PropTypes.string.isRequired,
        ])
      ),
    ]),
  };

  return wrappedWithError;
};

export default withError;
