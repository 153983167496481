import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import './style.scss';

const ExampleValues = ({ onClick, className, values, intl: { formatMessage } }) => {
  return (
    <div className={classNames('ExampleValues d-flex flex-wrap align-items-center', className)}>
      {values
        ? values.map((value) => {
            const title = formatMessage({ id: value });
            return (
              <div
                key={value}
                className="example-item me-3 mb-2"
                onClick={(e) => onClick(e, title)}>
                {title}
              </div>
            );
          })
        : null}
    </div>
  );
};

ExampleValues.propTypes = {
  onClick: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ExampleValues);
