import React from 'react';
import PropTypes from 'prop-types';

import BootStrapModal from 'react-bootstrap/Modal';

import { Button } from 'components';

import './style.scss';

const ModalFooter = ({
  className,
  saveTitle,
  isCloseButtonDisabled,
  showCloseButton,
  showSaveButton,
  closeTitle,
  showSecondarySaveButton,
  saveSecondaryTitle,
  areSaveButtonsDisabled,
  areSaveButtonsLoading,
  onCloseButtonClick,
  onSaveButtonClick,
  onSecondarySaveButtonClick,
}) => (
  <BootStrapModal.Footer bsPrefix="my-modal-footer" className={className}>
    {showCloseButton && (
      <Button
        type="button"
        className="modal-footer__button btn-secondary"
        disabled={isCloseButtonDisabled}
        aria-label="Close"
        onClick={onCloseButtonClick}>
        {closeTitle}
      </Button>
    )}
    {showSecondarySaveButton && (
      <Button
        type="button"
        className="modal-footer__button btn-third"
        disabled={areSaveButtonsDisabled}
        loading={areSaveButtonsLoading}
        savingContent={'Saving...'}
        onClick={onSecondarySaveButtonClick}>
        {saveSecondaryTitle}
      </Button>
    )}
    {showSaveButton && (
      <Button
        type="button"
        className="modal-footer__button btn-primary"
        disabled={areSaveButtonsDisabled}
        loading={areSaveButtonsLoading}
        savingContent={'Saving...'}
        onClick={onSaveButtonClick}>
        {saveTitle}
      </Button>
    )}
  </BootStrapModal.Footer>
);

ModalFooter.propTypes = {
  areSaveButtonsLoading: PropTypes.bool,
  areSaveButtonsDisabled: PropTypes.bool,
  isCloseButtonDisabled: PropTypes.bool,
  onCloseButtonClick: PropTypes.func,
  showSecondarySaveButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showSaveButton: PropTypes.bool,
  saveTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  closeTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  saveSecondaryTitle: PropTypes.string,
  onSaveButtonClick: PropTypes.func,
  onSecondarySaveButtonClick: PropTypes.func,
  className: PropTypes.string,
};

export default ModalFooter;
