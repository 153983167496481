export const getUser = (user = {}) => {
  return {
    ...user,
    id: user.id || null,
    email: user.email || '',
    username: '',
    birthDate: '',
    phoneNumber: user.phoneNumber || '',
    roles: user.roles || [],
    groups: user.groups || [],
    prevRoles: user.roles || [],
    position: user.position || null,
    workplaces: user.workplaces || [],
    address: user.address || {
      id: null,
      zip: '',
      country: '',
      state: '',
      city: '',
      street: '',
      publicPlace: '',
      houseNumber: '',
    },
    avatar: user.avatar || null,
    avatarFull: user.avatarFull || null,
  };
};

export const getUserIdentifier = ({ firstName, lastName, email }, alwaysShowEmail = false) =>
  firstName && lastName
    ? `${firstName} ${lastName}${alwaysShowEmail ? ' (' + email + ')' : ''}`
    : 'Anonymous (' + email + ')';
