import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Button } from 'components';

import './style.scss';

const SubmitFormButtons = ({ disabled, loading, showSubmitButton, visibleModal, onClose }) => {
  return (
    <div
      className={classNames('save-container', 'd-flex', 'justify-content-end', {
        'visible-modal': visibleModal,
      })}>
      <Button
        type="button"
        className="btn-secondary"
        disabled={disabled}
        loading={loading}
        onClick={onClose}>
        {<FormattedMessage id="COMMON.CANCEL" />}
      </Button>
      {showSubmitButton ? (
        <Button
          type="submit"
          className="btn-primary"
          disabled={disabled}
          loading={loading}
          savingContent={<FormattedMessage id="COMMON.SAVING" />}>
          {<FormattedMessage id="COMMON.FINISH_EDITING" />}
        </Button>
      ) : null}

      <div className="blanket" />
    </div>
  );
};

SubmitFormButtons.propTypes = {
  showSubmitButton: PropTypes.bool,
  visibleModal: PropTypes.bool,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SubmitFormButtons;
