import React from 'react';
import PropTypes from 'prop-types';

const AddSectionMarker = ({ onClick }) => {
  return (
    <button className="add-marker-button" type="button" onClick={() => onClick()}>
      add section marker
    </button>
  );
};

AddSectionMarker.propTypes = {
  onClick: PropTypes.func,
};

export default AddSectionMarker;
