import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { getLessonsDetailByMember } from 'services/statistics';
import { MessageComponent } from 'components';
import { axisDataKeys } from 'util/chartAxisDataKeys';
import { memberTypeParam } from 'util/statisticsOverviewType';
import Settings from 'config/settings';
import UsageTrendChart from './UsageTrendChart';
import LessonsTable from './LessonsTable';
import { statisticsTimePeriods } from 'util/statisticsTimePeriods';

import './style.scss';

const ITEMS_PER_PAGE = 5;

const DEFAULT_TABLE_PARAMS = {
  sortBy: Settings.SORT_BY.COURSE_TITLE,
  order: Settings.ORDER.ASC,
  page: 1,
};

const LessonsDetailContainer = ({
  isGroup,
  memberId,
  selectedData,
  startDate,
  endDate,
  period,
  intl: { formatMessage },
}) => {
  const [tableData, setTableData] = useState([]);
  const [tableDataCount, setTableDataCount] = useState(null);
  const [tableParams, setTableParams] = useState(DEFAULT_TABLE_PARAMS);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState(null);

  const loadTableData = useCallback(
    async (params = DEFAULT_TABLE_PARAMS) => {
      const { date, page, ...remParams } = params;
      const convertedParams = {
        date,
        modelId: memberId,
        page,
        period,
        type: isGroup ? memberTypeParam.GROUP : memberTypeParam.USER,
        ...remParams,
      };

      try {
        setSelectedTableItem(null);
        setLoadingTableData(true);
        setTableParams(params);

        const { count, data } = await getLessonsDetailByMember(convertedParams);

        setTableData(data);
        setTableDataCount(count);
      } catch (e) {
        setTableData([]);
        setTableDataCount(0);
        throw e;
      } finally {
        setLoadingTableData(false);
      }
    },
    [isGroup, period, memberId]
  );

  useEffect(() => {
    const date = selectedData && selectedData[axisDataKeys.DATE];
    if (date) {
      loadTableData({
        date,
        ...DEFAULT_TABLE_PARAMS,
      });
    }
  }, [selectedData, isGroup, loadTableData]);

  return (
    <div className="container-fluid">
      <div className="row">
        {selectedData ? (
          <>
            <div className="col-12 col-xl-7 p-2">
              <div className="member-course-table-container">
                <LessonsTable
                  selectedItem={selectedTableItem}
                  count={tableDataCount}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={tableParams.page}
                  loadPage={(page) => loadTableData({ ...tableParams, ...page })}
                  tableData={tableData}
                  loading={loadingTableData}
                  loadTableData={loadTableData}
                  params={tableParams}
                  selectItem={(item) => setSelectedTableItem(item)}
                />
              </div>
            </div>
            <div className="col-12 col-xl-5 p-2">
              {!loadingTableData ? (
                <div className="usage-trend-chart-container">
                  {selectedTableItem ? (
                    <UsageTrendChart
                      isGroup={isGroup}
                      startDate={startDate}
                      endDate={endDate}
                      memberId={memberId}
                      selectedItemId={selectedTableItem.id}
                    />
                  ) : (
                    <MessageComponent
                      message={formatMessage({ id: 'MEMBERS_STATISTICS.NO_TABLE_ITEM_SELECTED' })}
                      type="info"
                    />
                  )}
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div className="no-chart-item-selected-message">
            <MessageComponent
              message={formatMessage({ id: 'MEMBERS_STATISTICS.NO_CHART_ITEM_SELECTED' })}
              type="info"
            />
          </div>
        )}
      </div>
    </div>
  );
};

LessonsDetailContainer.propTypes = {
  memberId: PropTypes.number.isRequired,
  isGroup: PropTypes.bool,
  selectedData: PropTypes.object,
  chart: PropTypes.object,
  period: PropTypes.oneOf(Object.values(statisticsTimePeriods)),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LessonsDetailContainer);
