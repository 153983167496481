import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ConfirmModal } from 'components';

const OkModal = ({
  title,
  saveTitle,
  closeTitle,
  confirmElement,
  intl: { formatMessage },
  ...props
}) => {
  const defaultTitle = formatMessage({ id: 'COMMON.SUCCESS' });
  const defaultSaveTitle = formatMessage({ id: 'COMMON.OK' });
  const defaultCloseTitle = formatMessage({ id: 'COMMON.CANCEL' });
  const defaultConfirmElement = formatMessage({ id: 'COMMON.SUCCESSFUL_SAVE' });

  return (
    <ConfirmModal
      title={title || defaultTitle}
      saveTitle={saveTitle || defaultSaveTitle}
      closeTitle={closeTitle || defaultCloseTitle}
      confirmElement={confirmElement || defaultConfirmElement}
      closeButton={false}
      {...props}
    />
  );
};

OkModal.propTypes = {
  intl: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  saveTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  closeTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  confirmElement: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default injectIntl(OkModal);
