import React from 'react';
import PropTypes from 'prop-types';

import { DeleteControl, IconButton } from 'components';

import './style.scss';

const PathLesson = ({ title, deleteItem, dndAttributes, dndListeners }) => {
  return (
    <div className="base-lesson">
      {dndAttributes && dndListeners ? (
        <div>
          <IconButton {...dndAttributes} {...dndListeners} iconClass="fa fa-bars" />
        </div>
      ) : null}
      <div className="title-lesson">{title}</div>
      <div className="delete-lesson">
        <DeleteControl enabled show={!!deleteItem} deleteItem={deleteItem} />
      </div>
    </div>
  );
};

PathLesson.propTypes = {
  title: PropTypes.string,
  deleteItem: PropTypes.func,
  dndAttributes: PropTypes.object,
  dndListeners: PropTypes.object,
};

export default PathLesson;
