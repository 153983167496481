import {
  GET_OPENINGS,
  GET_OPENINGS_SUCCESS,
  GET_OPENINGS_FAILED,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_OPENINGS:
      return {
        ...state,
        loading: true,
      };
    case GET_OPENINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
      };
    case GET_OPENINGS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
