import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './style.scss';

const RequiredInfoMessage = ({ requiredMessage, intl: { formatMessage } }) => {
  return (
    <div className="required-info-message">
      {requiredMessage ? requiredMessage : formatMessage({ id: 'COMMON.FIELD_IMAGE_REQUIRED' })}
    </div>
  );
};

RequiredInfoMessage.propTypes = {
  requiredMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  intl: PropTypes.object.isRequired,
};

export default injectIntl(RequiredInfoMessage);
