import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import { Yup } from 'util/Yup';

import * as SubsidiaryService from 'services/subsidiaries';
import { Button, OkModal, SelectField } from 'components';
import CompanySubsciptionTable from './CompanySubsciptionTable';
import { editableSubscriptionStatus } from 'libs/subscriptionStatus';
import { SubsidiaryAccess } from 'libs/accessManagement';
import { createShallowMergedObject } from 'util/objectUtil';

const initialSubscription = {
  subscriptionId: '',
  status: '',
};

const CompanySubscriptionTab = ({ availableSubscriptions, disabled, profile, company }) => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState(null);

  const validationSchema = Yup.object().shape({
    subscriptionId: Yup.number().required(),
    status: Yup.number().required(),
  });

  const availableSubscriptionsList = availableSubscriptions
    ? availableSubscriptions.map(({ id, title }) => ({
        label: title,
        value: id,
      }))
    : [];

  const editableSubscriptionStatusList = editableSubscriptionStatus
    ? Object.values(editableSubscriptionStatus).map(({ id, label }) => ({
        label: label,
        value: id,
      }))
    : [];

  const changeSubscription = async (values) => {
    const {
      SubscriptionAccess: { hasRoleToEdit },
    } = SubsidiaryAccess.getHasRoles(profile.profile);
    if (hasRoleToEdit) {
      await SubsidiaryService.changeSubsidiarySubscription({
        subsidiaryId: company.id,
        ...values,
      });
      const resSubscriptions = await SubsidiaryService.getSubsidiarySubscriptions({
        subsidiaryId: company.id,
      });
      setSubscriptions(resSubscriptions);
    }
  };

  const openSuccessModal = useCallback(() => {
    setSuccessModalOpen(true);
  }, []);

  const closeSuccessModal = useCallback(() => {
    setSuccessModalOpen(false);
  }, []);

  const initValues = () => {
    const status = editableSubscriptionStatus
      ? Object.values(editableSubscriptionStatus).find(
          ({ id }) => +id === +company.latestSubscription?.state
        )
      : null;
    const loadedSubscription = {
      subscriptionId: company.latestSubscription?.subscription_id,
      status: status && status.id ? status.id : '',
    };
    return createShallowMergedObject(initialSubscription, loadedSubscription);
  };

  const preloadSaveData = (values) => {
    return { subsidiaryId: company?.id, ...values };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const data = preloadSaveData(values);
    setSubmitting(true);
    try {
      await changeSubscription(data);
      openSuccessModal();
    } finally {
      setSubmitting(false);
    }
  };

  const {
    SubscriptionAccess: { hasRoleToEdit },
  } = SubsidiaryAccess.getHasRoles(profile.profile);

  useEffect(() => {
    const getSubscriptions = async () => {
      if (company.id) {
        const resSubscriptions = await SubsidiaryService.getSubsidiarySubscriptions({
          subsidiaryId: company.id,
        });
        setSubscriptions(resSubscriptions);
      }
    };
    getSubscriptions();
  }, [company.id]);

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        initialValues={initValues()}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <div className="row">
              <div className="col-6">
                <Field
                  name="subscriptionId"
                  component={SelectField}
                  label={<FormattedMessage id="COMPANIES.SUBSCRIPTION" />}
                  disabled={disabled}
                  useOnlyValueAsFieldValue
                  options={availableSubscriptionsList}
                />
              </div>
              <div className="col-6">
                <Field
                  name="status"
                  component={SelectField}
                  label={<FormattedMessage id="COMPANIES.STATUS" />}
                  disabled={disabled}
                  useOnlyValueAsFieldValue
                  options={editableSubscriptionStatusList}
                />
                {hasRoleToEdit && (
                  <Button
                    type="button"
                    className="btn-primary float-end"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    savingContent={<FormattedMessage id="COMMON.SAVING" />}>
                    {<FormattedMessage id="SUBSIDIARY.CHANGE_SUBSCRIPTION" />}
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <FormattedMessage id="COMMON.HISTORY" />
      <CompanySubsciptionTable list={subscriptions} />
      <OkModal
        shadowed
        preventDismiss
        open={successModalOpen}
        onClose={closeSuccessModal}
        saveAction={closeSuccessModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

CompanySubscriptionTab.propTypes = {
  availableSubscriptions: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  profile: PropTypes.object,
  company: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(CompanySubscriptionTab);
