import { chartDetailDataKeys } from 'util/chartDetailDataKeys';
import { axisDataKeys } from 'util/chartAxisDataKeys';
import { successRateColor, failureRateColor, overallRateColor } from '../../../styles/_colors.scss';

const testSuccessYAxisId = 'testSuccessYAxis';

export const secondaryChartConstants = {
  chartDetail: [
    { key: chartDetailDataKeys.TOTAL_TEST_COUNT, color: overallRateColor },
    { key: chartDetailDataKeys.TEST_SUCCESS_COUNT, color: successRateColor },
    { key: chartDetailDataKeys.TEST_FAILURE_COUNT, color: failureRateColor },
  ],
  chartAreas: [
    { yAxisId: testSuccessYAxisId, key: axisDataKeys.TOTAL_TEST_COUNT, color: overallRateColor },
    { yAxisId: testSuccessYAxisId, key: axisDataKeys.TEST_SUCCESS_COUNT, color: successRateColor },
    { yAxisId: testSuccessYAxisId, key: axisDataKeys.TEST_FAILURE_COUNT, color: failureRateColor },
  ],
  xAxisDataKey: axisDataKeys.DATE,
  yAxes: [
    {
      yAxisId: testSuccessYAxisId,
      labelTranslateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_TESTS_Y_AXIS_LABEL',
    },
  ],
};
