import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { FilePreview } from 'components';
import PreviewModal from './FilePreviewModal/PreviewModal';

const FilePreviewContainer = ({
  files,
  type = 'file',
  getFileUrl,
  disabledClick,
  deleteSelection,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleOpenModal = useCallback((index) => {
    setIsModalOpen(true);
    setActiveIndex(index);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setActiveIndex(0);
  }, []);

  return (
    <div className="d-flex">
      {(files || []).map((file, index) => (
        <FilePreview
          key={file.key}
          file={file}
          getFileUrl={getFileUrl}
          disabledClick={disabledClick}
          type={type}
          files={files}
          index={index}
          deleteSelection={deleteSelection ? () => deleteSelection(index) : undefined}
          onClick={() => handleOpenModal(index)}
        />
      ))}
      <PreviewModal
        open={isModalOpen}
        activeIndex={activeIndex}
        files={files}
        getFileUrl={getFileUrl}
        onClose={handleModalClose}
        type={type}
        setActiveIndex={setActiveIndex}
      />
    </div>
  );
};

FilePreviewContainer.propTypes = {
  files: PropTypes.array,
  getFileUrl: PropTypes.func,
  disabledClick: PropTypes.bool,
  deleteSelection: PropTypes.func,
  type: PropTypes.string,
};

export default FilePreviewContainer;
