import request from '../libs/request';
import { Methods } from '../libs/http';
import Settings from '../config/settings';
import qs from 'query-string';
import { filterEmptyParam } from 'util/queryParam';
import { BASE_URL as TEMPLATE_BASE_URL } from 'services/templates';

const BASE_URL = '/lessons';

const SUBSIDIARYID_PARAM_NAME = 'subsidiaryId';
const filterEmptySubsidiaryId = filterEmptyParam(SUBSIDIARYID_PARAM_NAME);
export const getLessons = async ({ filter, ...params }) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/admin${params ? '?' : ''}${qs.stringify(
      filterEmptySubsidiaryId(params),
      Settings.QUERY_FORMAT
    )}`,
    data: filter,
  });
};

/**
 * @param {number} id of lesson
 * */
export const getLesson = async (id) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${id}/admin`,
  });
};

export const createLesson = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}`,
    data,
  });
};

export const editLesson = async (data, id) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}`,
    data,
  });
};

export const deleteLesson = async (id) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${id}`,
  });
};

export const getLessonTests = async ({ lessonId, ...params }) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${lessonId}/tests?${qs.stringify(params, Settings.QUERY_FORMAT)}`,
  });
};

/**
 * @param {number} id lesson ID
 * @param {Object} data test
 *
 * @returns {Promise}
 */
export const saveLessonTest = async (id, data) => {
  return await request({
    resource: `${BASE_URL}/${id}/tests`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} lessonId lesson ID
 * @param {Object} data test
 * @param {number} testId test id
 *
 * @returns {Promise}
 */
export const editLessonTest = async (lessonId, data, testId) => {
  return await request({
    resource: `${BASE_URL}/${lessonId}/tests/${testId}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} lessonId lesson ID
 * @param {number} testId test id
 *
 * @returns {Promise}
 */
export const deleteLessonTest = async (lessonId, testId) => {
  return await request({
    resource: `${BASE_URL}/${lessonId}/tests/${testId}`,
    method: Methods.DELETE,
  });
};

export const generateTests = async (lessonId) => {
  return await request({
    resource: `${BASE_URL}/${lessonId}/tests/generate`,
    method: Methods.POST,
  });
};

export const getAssignedUsers = async (lessonId) => {
  return await request({
    resource: `${BASE_URL}/${lessonId}/assign-user-list`,
    method: Methods.GET,
  });
};

export const assignUsers = async (lessonId, data) => {
  return await request({
    resource: `${BASE_URL}/${lessonId}/assign-user-list`,
    method: Methods.POST,
    data,
  });
};

export const getLessonsHistory = async (lessonId) => {
  return await request({
    resource: `${BASE_URL}/${lessonId}/history`,
    method: Methods.GET,
  });
};

export const importLesson = async (templateId, data) => {
  return await request({
    method: Methods.POST,
    resource: `${TEMPLATE_BASE_URL}/${templateId}/import`,
    data,
  });
};

export const unAssignCourse = async (lessonId, courseId) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${lessonId}/unassign`,
    data: {
      courseId,
    },
  });
};
