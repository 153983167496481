export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';

export const TOGGLE_ENABLE_COMPANY = 'TOGGLE_ENABLE_COMPANY';
export const TOGGLE_ENABLE_COMPANY_SUCCESS = 'TOGGLE_ENABLE_COMPANY_SUCCESS';
export const TOGGLE_ENABLE_COMPANY_FAILED = 'TOGGLE_ENABLE_COMPANY_FAILED';

export const DELETE_COMPANY = 'DELETE_COMPANY';

export const SET_TAB_INDEX = 'SET_TAB_INDEX';
