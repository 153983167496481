import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton, Popover } from '../../components';

import './style.scss';

const AddRow = ({
  onAdd,
  className,
  hideAdd,
  children,
  label = 'Add',
  hideLabel,
  disabled,
  wide,
  showPopover,
  iconClass = 'icon-add',
}) => {
  return (
    <div
      className={classNames(
        'AddRow d-flex',
        children ? 'justify-content-between align-items-center' : 'justify-content-end',
        className,
        { wide: wide }
      )}>
      {children}
      {!hideAdd && !hideLabel && (
        <span
          className={classNames('add-label', { disabled })}
          onClick={(e) => !disabled && onAdd(e)}>
          {label}
        </span>
      )}
      {!hideAdd && !showPopover ? (
        <IconButton
          iconClass={classNames('lemon green', iconClass)}
          onClick={onAdd}
          disabled={disabled}
        />
      ) : !hideAdd && showPopover ? (
        <Popover content={label} oneLine placement="top" trigger="hover">
          <IconButton
            iconClass={classNames('lemon green', iconClass)}
            onClick={onAdd}
            disabled={disabled}
          />
        </Popover>
      ) : null}
    </div>
  );
};

AddRow.propTypes = {
  onAdd: PropTypes.func.isRequired,
  children: PropTypes.node,
  label: PropTypes.node,
  className: PropTypes.string,
  hideAdd: PropTypes.bool,
  hideLabel: PropTypes.bool,
  showPopover: PropTypes.bool,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  iconClass: PropTypes.string,
};

export default AddRow;
