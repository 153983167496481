import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import isEqual from 'react-fast-compare';

import { ExpandIcon, MessageComponent, Modal } from 'components';
import ResizableWordCloud from './ResizableWordCloud';
import { getWords } from './wordCloudUtil';

import './style.scss';

const INLINE_MAX_WORD_LENGTH = 11;
const MODAL_MAX_WORD_LENGTH = 16;
const WORD_CLOUD_MODAL_HEIGHT = '600px';
const DEFAULT_MAX_WORDS_WHEN_CLOSED = 5;
const DEFAULT_MAX_WORDS_WHEN_OPEN = 30;

const WordCloudContainer = ({
  title,
  titleIcon,
  height,
  textColors,
  emptyMessage,
  maxWordsWhenClosed = DEFAULT_MAX_WORDS_WHEN_CLOSED,
  maxWordsWhenOpen = DEFAULT_MAX_WORDS_WHEN_OPEN,
  words,
  intl: { formatMessage },
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const defaultEmptyMessage = formatMessage({ id: 'WORD_CLOUD.EMPTY_MESSAGE' });
  const hasWords = words && words.length > 0;
  const closeModal = () => setIsModalOpen(false);

  const modalWords = useMemo(() => getWords(words, maxWordsWhenOpen, MODAL_MAX_WORD_LENGTH), [
    words,
    maxWordsWhenOpen,
  ]);

  const standardWords = useMemo(() => getWords(words, maxWordsWhenClosed, INLINE_MAX_WORD_LENGTH), [
    maxWordsWhenClosed,
    words,
  ]);

  return (
    <>
      <div className="wordcloud-container">
        <div className="word-cloud-header">
          <div className="word-cloud-header-title">
            <div className="word-cloud-header-title-icon">{titleIcon}</div>
            <div className="word-cloud-header-title-text">{title}</div>
          </div>
          <ExpandIcon
            noWrapper
            color="black"
            disabled={!hasWords}
            onClick={() => setIsModalOpen(!isModalOpen)}
          />
        </div>
        <div className="word-cloud-divider" />
        {hasWords ? (
          <ResizableWordCloud words={standardWords} textColors={textColors} height={height} />
        ) : (
          <MessageComponent message={emptyMessage || defaultEmptyMessage} type="info" />
        )}
      </div>
      <Modal
        open={isModalOpen}
        large
        onClose={closeModal}
        className="word-cloud-modal"
        title={title}
        hideFooter>
        <ResizableWordCloud
          words={modalWords}
          textColors={textColors}
          height={WORD_CLOUD_MODAL_HEIGHT}
        />
      </Modal>
    </>
  );
};

WordCloudContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  words: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string.isRequired, value: PropTypes.number.isRequired })
  ),
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleIcon: PropTypes.node,
  maxWordsWhenClosed: PropTypes.number,
  maxWordsWhenOpen: PropTypes.number,
  textColors: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const propsAreEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export default injectIntl(React.memo(WordCloudContainer, propsAreEqual));
