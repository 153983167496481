export const modalType = {
  ROLES: 'ROLES',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  DISABLE: 'DISABLE',
  ENABLE: 'ENABLE',
  ASSIGN: 'ASSIGN',
  HISTORY: 'HISTORY',
  REMINDER: 'REMINDER',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  IMPORT_LESSON: 'IMPORT_LESSON',
};
