import React from 'react';
import { injectIntl } from 'react-intl';

const TransferListCount = ({ list, intl, itemsName = 'items' }) => {
  const { formatMessage } = intl;
  return (
    <div className="TransferListCount">
      {formatMessage({ id: 'COMMON.NUMBER_OF_ITEMS' }, { items: itemsName }) +
        ': ' +
        (list && list.length ? list.length : 0)}
    </div>
  );
};
export default injectIntl(TransferListCount);
