import qs from 'query-string';

import request from '../libs/request';
import { Methods } from '../libs/http';
import Settings from '../config/settings';

const BASE_URL = '/auditors';

export const getLessonsForAudit = async (params, subsidiaryId) => {
  let resource = `${BASE_URL}/free-lessons-new?${qs.stringify(params, Settings.QUERY_FORMAT)}`;
  if (subsidiaryId) {
    resource = resource + `&subsidiaryId=${subsidiaryId}`;
  }
  return await request({
    method: Methods.GET,
    resource,
  });
};

export const getMyLessons = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/my-lessons`,
  });
};

export const addAuditor = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `/users/create/auditor`,
    data,
  });
};

export const assignLesson = async (lessonId) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/assign`,
    data: {
      lessonId,
    },
  });
};

export const detachLesson = async (lessonId) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/detach`,
    data: {
      lessonId,
    },
  });
};

export const validateLesson = async (lessonId, isValid, description) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/lesson-validation`,
    data: {
      lessonId,
      isValid,
      description,
    },
  });
};

export const getScoringList = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/score-tables`,
  });
};
