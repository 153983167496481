import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const ChartDetailContainer = ({ titleLabel, hideTitleUnderline, titleValue, children }) => {
  return (
    <div className="chart-detail-container">
      <div className="title-container">
        <div className="title-label">{titleLabel}</div>
        <div className="title-value">{titleValue}</div>
      </div>
      <div className={classNames('title-divider', { transparent: hideTitleUnderline })} />
      <div>{children}</div>
    </div>
  );
};

ChartDetailContainer.propTypes = {
  hideTitleUnderline: PropTypes.bool,
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

export default ChartDetailContainer;
