import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Indicator, IndicatorActivity } from 'components';
import { FILTERS } from 'libs/dashboardFilters';

const CompanyIndicators = ({ getCompanyTopData }) => {
  const [companiesCount, setCompaniesCount] = useState(0);
  const [subsidiariesCount, setSubsidiariesCount] = useState(0);
  const [subscribedPercent, setSubscribedPercent] = useState(0);
  const [subscriptionTypes, setSubsciptionTypes] = useState([]);

  useEffect(() => {
    const fetchCompanyTopData = async () => {
      const result = await getCompanyTopData();
      setCompaniesCount(result.numberOfCompanies ? result.numberOfCompanies : 0);
      setSubsidiariesCount(result.numberOfSubsidiaries ? result.numberOfSubsidiaries : 0);
      setSubscribedPercent(
        result.percentOfSubscribedSubsidiaries
          ? result.percentOfSubscribedSubsidiaries.toFixed(2)
          : 0
      );
      setSubsciptionTypes(
        result.subscriptionTypes
          ? result.subscriptionTypes.map((item) => ({ ...item, key: uuidv4() }))
          : []
      );
    };
    fetchCompanyTopData();
  }, [getCompanyTopData]);

  return (
    <div className="indicators col-md-12 row">
      <Indicator type={FILTERS.users} label="COMPANIES.TITLE" value={companiesCount} />
      <Indicator type={FILTERS.users} label="SUBSIDIARY.TITLE_PL" value={subsidiariesCount} />
      <Indicator
        type={FILTERS.users}
        label="SUBSIDIARY.SUBSCRIBED_PERCENT"
        value={subscribedPercent}
      />
      <Indicator
        type={FILTERS.activities}
        label="SUBSIDIARY.SUBSCRIPTION_TYPE_PL"
        value={
          <div className="row m-0">
            {subscriptionTypes.map((type) => (
              <IndicatorActivity
                key={type.key}
                className={`col-${12 / subscriptionTypes.length} p-0`}
                title={type.title}
                value={type.sum}
              />
            ))}
          </div>
        }
      />
    </div>
  );
};

CompanyIndicators.propTypes = {
  getCompanyTopData: PropTypes.func.isRequired,
};

export default CompanyIndicators;
