import React from 'react';
import './style.scss';

const NotFound = () => {
  return (
    <div className="NotFound container d-flex">
      <div className="row align-self-center">
        <h3 className="headline">Page not found</h3>
        <p>
          Unfortunately, we haven't been able to find the page you were looking for. If you typed in
          a URL, please make sure you have entered it correctly. It is also possible that the page
          you are looking for has been moved or deleted.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
