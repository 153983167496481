import React from 'react';

import StatisticsPage from '../StatisticsPage/StatisticsPage';

import { statisticsOverviewType } from 'util/statisticsOverviewType';

const ContentStatisticsPage = (props) => {
  return <StatisticsPage type={statisticsOverviewType.CONTENT} {...props} />;
};

export default ContentStatisticsPage;
