import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { TableContainer } from 'components';
import { withPagination } from 'hoc';
import { pathEditorSourceTableColumns } from 'util/pathEditorSourceTableColumns';
import { pathCourse, pathLesson, pathMarker } from 'common';

const PathTable = ({ intl: { formatMessage }, list, loadList, recordKey }) => (
  <TableContainer
    recordKey={recordKey}
    isTableRowDraggable={true}
    loadList={loadList}
    list={list}
    columns={pathEditorSourceTableColumns(formatMessage)}
  />
);

PathTable.propTypes = {
  intl: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.oneOfType([pathMarker, pathCourse, pathLesson])),
  loadList: PropTypes.func,
  recordKey: PropTypes.func,
};

export default withRouter(withPagination(injectIntl(PathTable)));
