import { getMultiValue } from 'util/getMultiValue';
import { Yup } from 'util/Yup';
import { getValidationMessageInput } from 'libs/validation/validation';
import Settings from '../../config/settings';
import { CompanyAccess, SubsidiaryAccess } from 'libs/accessManagement';

export const getCompanyData = (values) => {
  const { groupType, mainGroupType, priceRange, opening, ...company } = values;
  return {
    ...company,
    companyId: company.parentCompany,
    subsidiaryTypeId: groupType ? getMultiValue(groupType) : getMultiValue(mainGroupType),
    priceRangeId: getMultiValue(priceRange),
    openingId: getMultiValue(opening),
  };
};

export const getInitialCompanyFormValues = (company) => ({
  ...company,
  imageUrl: company.imageUrl || undefined,
  name: company.name || '',
  legalName: company.legalName || '',
  vatIdNumber: company.vatIdNumber || '',
  address: {
    city: company.address?.city || '',
    country: company.address?.country || '',
    houseNumber: company.address?.houseNumber || '',
    publicPlace: company.address?.publicPlace || '',
    street: company.address?.street || '',
    zip: company.address?.zip || '',
  },
  email: company.email || '',
  phoneNumber: company.phoneNumber || '',
  priceRange: company.priceRange || '',
  opening: company.opening || '',
  mainGroupType: company.mainGroupType || '',
});

export const checkExistingRows = (item, data) => {
  const errors = {};
  if (item.legalName === data.legalName) {
    errors.legalName = 'Company already exists!';
  } else if (item.email === data.email) {
    errors.email = 'Email already exists!';
  }

  return errors.email || errors.legalName ? errors : {};
};

export const validationSchema = (isCompany) =>
  Yup.object().shape({
    imageUrl: Yup.mixed().required(),
    name: Yup.string().required(),
    legalName: Yup.string().required(),
    vatIdNumber: Yup.string().required(),
    email: Yup.string().required(),
    phoneNumber: Yup.string()
      .required()
      .matches(
        new RegExp(Settings.PHONE_PATTERN),
        getValidationMessageInput('COMMON.FIELD_PHONE_ERROR')
      ),
    isEnabled: Yup.boolean(),
    address: Yup.object({
      city: Yup.string().required(),
      zip: Yup.string().required(),
      country: Yup.string().required(),
      street: Yup.string().required(),
      publicPlace: Yup.string().required(),
      houseNumber: Yup.string().required(),
    }),
    contacts: Yup.array(),
    priceRange: Yup.string().required(),
    opening: Yup.string().required(),
    mainGroupType: Yup.string().required(),
    groupType: Yup.string().nullable(),
    parentCompany: isCompany ? Yup.string() : Yup.string().required(),
  });

export const canModifySomethingInCompany = (profile) => {
  const {
    hasRoleToEdit,
    AdminAccess: {
      hasRoleToAdd: hasRoleToAddAdmin,
      hasRoleToEdit: hasRoleToEditAdmin,
      hasRoleToDelete: hasRoleToDeleteAdmin,
    },
    ContactAccess: {
      hasRoleToAdd: hasRoleToAddContact,
      hasRoleToEdit: hasRoleToEditContact,
      hasRoleToDelete: hasRoleToDeleteContact,
    },
  } = CompanyAccess.getHasRoles(profile);
  return (
    hasRoleToEdit ||
    hasRoleToAddAdmin ||
    hasRoleToEditAdmin ||
    hasRoleToDeleteAdmin ||
    hasRoleToAddContact ||
    hasRoleToEditContact ||
    hasRoleToDeleteContact
  );
};

export const canModifySomethingInSubsidiary = (profile) => {
  const {
    hasRoleToEdit,
    AdminAccess: {
      hasRoleToAdd: hasRoleToAddAdmin,
      hasRoleToEdit: hasRoleToEditAdmin,
      hasRoleToDelete: hasRoleToDeleteAdmin,
    },
    ContactAccess: {
      hasRoleToAdd: hasRoleToAddContact,
      hasRoleToEdit: hasRoleToEditContact,
      hasRoleToDelete: hasRoleToDeleteContact,
    },
    SubscriptionAccess: { hasRoleToEdit: hasRoleToAssignSubscription },
  } = SubsidiaryAccess.getHasRoles(profile);
  return (
    hasRoleToEdit ||
    hasRoleToAddAdmin ||
    hasRoleToEditAdmin ||
    hasRoleToDeleteAdmin ||
    hasRoleToAddContact ||
    hasRoleToEditContact ||
    hasRoleToDeleteContact ||
    hasRoleToAssignSubscription
  );
};

export const preLoadCompanyData = (company) => {
  return {
    ...company,
    priceRange: company.priceRange.id,
    opening: company.opening.id,
    vatIdNumber: company.vatIdentificationNumber,
    imageUrl: { fullUrl: company.logoFull, url: company.logo },
    mainGroupType:
      company.subsidiaryType && company.subsidiaryType.maingroupType
        ? company.subsidiaryType.maingroupType.id
        : company.subsidiaryType.id,
    groupType:
      company.subsidiaryType && company.subsidiaryType.maingroupType
        ? company.subsidiaryType.id
        : '',
    parentCompany: company.companies ? company.companies.id : '',
  };
};
