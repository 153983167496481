import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import { TableContainer, EditOrReadControl, DeleteControl, CheckBox } from 'components';
import { widthOptions, horizontalAlignOptions } from 'components/Table/tableConstants';
import Settings from 'config/settings';
import { canSubmitForm } from './courseFormUtil';
import { CourseAccess } from 'libs/accessManagement';

const CoursesTable = ({
  profile,
  list = [],
  openEditModal,
  openDeleteModal,
  loadList,
  params,
  setImportant,
  loading,
  intl: { formatMessage },
}) => {
  const {
    hasRoleToEdit,
    hasRoleToDelete,
    hasRoleToAssignLesson,
    hasRoleToAssignUserOrGroup,
  } = CourseAccess.getHasRoles(profile.profile);

  const renderEditorReadControl = (course) => (
    <EditOrReadControl
      canEdit={canSubmitForm({ profile, isEdit: !!course?.id, course })}
      isEnabled={!course.isDisabled}
      openEditModal={() => openEditModal(course)}
    />
  );

  const renderImportantCheckbox = (course) => {
    return (
      <CheckBox
        checked={course.isImportant}
        disabled={!hasRoleToEdit}
        onChange={async () => {
          await setImportant(course.id, !course.isImportant);
        }}
      />
    );
  };

  const renderDeleteControl = (course) => (
    <DeleteControl
      show={CourseAccess.getHasAccess(profile.profile, course).canDelete}
      enabled={CourseAccess.getHasAccess(profile.profile, course).canDelete}
      item={course}
      deleteItem={() => openDeleteModal(course)}
    />
  );

  return (
    <TableContainer
      loading={loading}
      columns={[
        {
          key: 'edit',
          hide: !(hasRoleToEdit || hasRoleToAssignLesson || hasRoleToAssignUserOrGroup),
          width: widthOptions.xs,
          render: renderEditorReadControl,
        },
        {
          key: 'title',
          minWidth: widthOptions.md,
          maxWidth: widthOptions.lg,
          label: formatMessage({ id: 'COMMON.TITLE' }),
          multiLine: true,
          sortBy: Settings.SORT_BY.TITLE,
        },
        {
          key: 'owner',
          width: widthOptions.md,
          label: formatMessage({ id: 'LESSONS.SUBSIDIARY' }),
          sortBy: Settings.SORT_BY.SUBSIDIARY,
        },
        {
          key: 'lessonCount',
          width: widthOptions.sm,
          label: formatMessage({ id: 'LESSONS.LESSONS_COUNT' }),
          horizontalAlign: horizontalAlignOptions.center,
          sortBy: Settings.SORT_BY.LESSON_COUNT,
        },
        {
          key: 'public_private',
          width: widthOptions.sm,
          label: formatMessage({ id: 'COMMON.PUBLIC_PRIVATE' }),
          sortBy: Settings.SORT_BY.PUBLICITY,
          render: (course) =>
            course.public
              ? formatMessage({ id: 'COMMON.PUBLIC' })
              : formatMessage({ id: 'COMMON.PRIVATE' }),
        },
        {
          key: 'assign',
          minWidth: widthOptions.sm,
          hide: !hasRoleToAssignLesson,
          label: formatMessage({ id: 'LESSONS.ASSIGN' }),
          iconCell: true,
          iconClass: 'lemon icon-info dark-grey',
          onClick: (course) => openEditModal(course, 1),
        },
        {
          key: 'important',
          minWidth: widthOptions.sm,
          label: formatMessage({ id: 'COURSES.IMPORTANT' }),
          render: renderImportantCheckbox,
        },
        {
          key: 'delete',
          hide: !hasRoleToDelete,
          render: renderDeleteControl,
        },
      ]}
      list={list}
      loadList={loadList}
      params={params}
    />
  );
};

CoursesTable.propTypes = {
  loading: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object),
  profile: PropTypes.object.isRequired,
  loadList: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  setImportant: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withPagination(injectIntl(CoursesTable));
