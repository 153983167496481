import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { statisticsType, statisticsTypeConstants } from 'util/statisticsType';

import './style.scss';

const GoTo = ({ type, baseData, intl: { formatMessage } }) => {
  const history = useHistory();

  const navigate = (type, baseData) => {
    const navigationParams = statisticsTypeConstants[type].navigationData(baseData);
    history.push(...navigationParams);
  };

  return (
    <div className="go-to-container" onClick={() => navigate(type, baseData)}>
      <div className="go-to-label">
        {formatMessage({ id: statisticsTypeConstants[type].translateId })}
      </div>
      {statisticsTypeConstants[type] && statisticsTypeConstants[type].getGoToIcon(type, baseData)}
    </div>
  );
};

GoTo.propTypes = {
  type: PropTypes.oneOf(Object.values(statisticsType)).isRequired,
  baseData: PropTypes.object.isRequired,
};

export default injectIntl(GoTo);
