import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import { TableContainer, TableCellButton, DeleteControl, IconButton } from 'components';
import { widthOptions, horizontalAlignOptions } from 'components/Table/tableConstants';
import { TemplateAccess } from 'libs/accessManagement';

const TemplatesTable = ({
  list = [],
  profile,
  openEditModal,
  openImportLessonModal,
  exportTemplate,
  openDeleteModal,
  loading,
  loadList,
  params,
  intl: { formatMessage },
}) => {
  const { getHasRoles, getHasAccess } = TemplateAccess;
  const { hasRoleToEdit, hasRoleToDelete } = getHasRoles(profile.profile);

  return (
    <TableContainer
      loading={loading}
      columns={[
        {
          key: 'edit',
          hide: !hasRoleToEdit,
          minWidth: widthOptions.sm,
          render: (template) =>
            getHasAccess(profile.profile, template).canEdit ? (
              <IconButton
                iconClass="lemon icon-edit purple"
                className="no-margin"
                onClick={() => openEditModal(template)}
              />
            ) : null,
        },
        {
          key: 'name',
          minWidth: widthOptions.md,
          maxWidth: widthOptions.xl,
          fillRemainingSpace: true,
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'TEMPLATES.TEMPLATE_NAME' }),
          render: (template) => (
            <>
              {template.name}
              <span className="ms-1">
                {template.isGlobal ? formatMessage({ id: 'TEMPLATES.GLOBAL' }) : null}
              </span>
            </>
          ),
          itemTooltip: (template) =>
            `${template.name}${
              template.isGlobal ? '(' + formatMessage({ id: 'TEMPLATES.GLOBAL' }) + ')' : ''
            }`,
        },
        {
          key: 'import',
          minWidth: widthOptions.sm,
          maxWidth: widthOptions.lg,
          render: (template) => (
            <TableCellButton onClick={() => openImportLessonModal(template)}>
              {formatMessage({ id: 'TEMPLATES.IMPORT' })}
            </TableCellButton>
          ),
        },
        {
          key: 'export',
          minWidth: widthOptions.sm,
          maxWidth: widthOptions.lg,
          render: (template) => (
            <TableCellButton onClick={() => exportTemplate(template)}>
              {formatMessage({ id: 'TEMPLATES.EXPORT' })}
            </TableCellButton>
          ),
        },
        {
          key: 'delete',
          hide: !hasRoleToDelete,
          width: widthOptions.xs,
          render: (template) => (
            <DeleteControl
              show={getHasAccess(profile.profile, template).canDelete}
              enabled={getHasAccess(profile.profile, template).canDelete}
              item={template}
              deleteItem={openDeleteModal}
            />
          ),
        },
      ]}
      list={list}
      loadList={loadList}
      params={params}
    />
  );
};

TemplatesTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  profile: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openImportLessonModal: PropTypes.func.isRequired,
  exportTemplate: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withPagination(injectIntl(TemplatesTable));
