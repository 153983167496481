import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Collapse } from 'react-collapse';

import { Button } from 'components';

import './style.scss';

const ActiveFilterIndicator = ({ isActive, message, resetFilter, intl: { formatMessage } }) => {
  const defaultMessage = formatMessage({ id: 'COMMON.HAS_ACTIVE_FILTER_MESSAGE' });

  return (
    <Collapse isOpened={isActive} theme={{ collapse: 'active-filter-indicator-collapse' }}>
      <div className="active-filter-indicator">
        <div className="active-filter-indicator-message">{message || defaultMessage}</div>
        <div className="active-filter-indicator-button-container">
          <Button size="small" onClick={resetFilter}>
            {formatMessage({ id: 'COMMON.RESET_ACTIVE_FILTER' })}
          </Button>
        </div>
      </div>
    </Collapse>
  );
};

ActiveFilterIndicator.propTypes = {
  isActive: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  resetFilter: PropTypes.func.isRequired,
};

export default injectIntl(ActiveFilterIndicator);
