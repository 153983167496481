import { combineReducers } from 'redux';

import token from './token';
import profile from './profile';
import list from './list';
import managedGroup from './managed-group';
import user from './user';

export default combineReducers({
  token,
  profile,
  list,
  managedGroup,
  user,
});
