import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { validateToken, logout } from '../../actions/users';
import { addNotification, removeNotification, resetApiError } from '../../actions/common';

import App from './App';
import { getToken } from '../../libs/refreshToken';

const mapStateToProps = (state) => {
  return {
    show: state.common.loader.show,
    notificationList: state.common.notifications.list,
    apiError: state.common.apiError,
    token: getToken(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      validateToken,
      addNotification,
      removeNotification,
      resetApiError,
      logout,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
