import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/image-or-video-preview-content.scss';

const getFilePreview = ({ file, getFileUrl }) => {
  const imageUrl = getFileUrl && getFileUrl(file) ? getFileUrl(file) : null;

  return file.preview
    ? file.preview
    : imageUrl
    ? imageUrl
    : file?.url
    ? file.url
    : file.fullUrl || '';
};

const ImagePreviewContent = ({ alt, file, getFileUrl, className, onClick }) => (
  <img
    src={getFilePreview({ file, getFileUrl })}
    alt={alt}
    className={classNames('image-or-video-content', className)}
    onClick={onClick}
  />
);

ImagePreviewContent.propTypes = {
  alt: PropTypes.string,
  file: PropTypes.object,
  getFileUrl: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ImagePreviewContent;
