import { axisDataKeys } from 'util/chartAxisDataKeys';
import {
  totalTimeSpentColor,
  actualTimeSpentColor,
  numberOfLessonsUsedColor,
  importantLessonsViewedColor,
  notImportantLessonsViewedColor,
  notImportantLessonsViewedBorderColor,
  usageTrendColor,
} from '../../styles/_colors.scss';

const barYAxisId = 'barYAxis';
const areaYAxisId = 'areaYAxis';
const usageTrandYAxisId = 'usageTrendYAxis';
const lessonBarStackId = 'lessonBars';
const commonRadius = [4, 4, 2, 2];
const INACTIVE_BAR_PROPS = { fillOpacity: 0.3, strokeOpacity: 0.3 };
const ACTIVE_BAR_PROPS = {};

const getSelectionBarStyles = (currentValue, selectedValue, activeStyles, inactiveStyles) => {
  return currentValue && selectedValue
    ? currentValue.date === selectedValue.date
      ? activeStyles
      : inactiveStyles
    : {};
};

const getOpacityBasedBarSelectionStyles = (currentValue, selectedValue) => {
  return getSelectionBarStyles(currentValue, selectedValue, ACTIVE_BAR_PROPS, INACTIVE_BAR_PROPS);
};

const getOpacityBasedGradientSelectionStyles = (selectedValue, baseGradientColor) => () =>
  selectedValue
    ? {
        topColor: baseGradientColor,
        topOpacity: 0.1,
        bottomColor: baseGradientColor,
        bottomOpacity: 0,
      }
    : {};

const getOpacityBasedLineSelectionStyles = (selectedValue) => () =>
  selectedValue
    ? {
        strokeOpacity: 0.1,
      }
    : {};

const getOpacityBasedDotSelectionStyles = (selectedValue, currentValue, fillColor) =>
  currentValue && selectedValue
    ? currentValue.date === selectedValue.date
      ? {
          fill: fillColor,
        }
      : { strokeOpacity: 0.1 }
    : {};

const getDynamicPropsForChartAreas = (color) => ({
  getDynamicGradientProps: (selectedValue) =>
    getOpacityBasedGradientSelectionStyles(selectedValue, color),
  getDynamicLineProps: (selectedValue) => getOpacityBasedLineSelectionStyles(selectedValue),
  getDynamicDotProps: (selectedValue) => (value) =>
    getOpacityBasedDotSelectionStyles(selectedValue, value, color),
});

export const primaryChartConstants = {
  chartAreas: [
    {
      key: axisDataKeys.TOTAL_TIME_SPENT,
      yAxisId: areaYAxisId,
      color: totalTimeSpentColor,
      legendAndTooltipOrder: 1,
      ...getDynamicPropsForChartAreas(totalTimeSpentColor),
    },
    {
      key: axisDataKeys.ACTUAL_TIME_SPENT,
      yAxisId: areaYAxisId,
      color: actualTimeSpentColor,
      legendAndTooltipOrder: 2,
      ...getDynamicPropsForChartAreas(actualTimeSpentColor),
    },
  ],
  chartBars: [
    {
      yAxisId: barYAxisId,
      key: axisDataKeys.IMPORTANT_LESSONS_VIEWED,
      color: importantLessonsViewedColor,
      stackId: lessonBarStackId,
      legendAndTooltipOrder: 4,
      getDynamicProps: (selectedValue) => (value) =>
        getOpacityBasedBarSelectionStyles(value, selectedValue),
    },
    {
      yAxisId: barYAxisId,
      key: axisDataKeys.NOT_IMPORTANT_LESSONS_VIEWED,
      color: notImportantLessonsViewedColor,
      stroke: notImportantLessonsViewedBorderColor,
      stackId: lessonBarStackId,
      strokeWidth: 2,
      radius: commonRadius,
      legendAndTooltipOrder: 5,
      getDynamicProps: (selectedValue) => (value) =>
        getOpacityBasedBarSelectionStyles(value, selectedValue),
    },
    {
      yAxisId: barYAxisId,
      key: axisDataKeys.NUM_OF_LESSONS_USED,
      color: numberOfLessonsUsedColor,
      stackId: lessonBarStackId,
      radius: commonRadius,
      legendAndTooltipOrder: 3,
      getDynamicProps: (selectedValue) => (value) =>
        getOpacityBasedBarSelectionStyles(value, selectedValue),
    },
  ],
  xAxisDataKey: axisDataKeys.DATE,
  yAxes: [
    {
      yAxisId: barYAxisId,
      orientation: 'left',
      labelTranslateId: 'MEMBERS_STATISTICS.NUMBER_OF_LESSONS_Y_AXIS_LABEL',
    },
    {
      yAxisId: areaYAxisId,
      orientation: 'right',
      labelTranslateId: 'MEMBERS_STATISTICS.TIME_Y_AXIS_LABEL',
    },
  ],
};

export const secondaryChartConstants = {
  xAxisDataKey: axisDataKeys.DATE,
  chartAreas: [
    {
      yAxisId: usageTrandYAxisId,
      key: axisDataKeys.USAGE_TREND,
      color: usageTrendColor,
    },
  ],
  yAxes: [
    {
      yAxisId: usageTrandYAxisId,
      labelTranslateId: 'MEMBERS_STATISTICS.USAGE_TREND_Y_AXIS_LABEL',
    },
  ],
};
