import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { pathLesson } from 'common/propTypes';
import { PathLesson, PathSectionMarker, DeleteControl, IconButton } from 'components';

import './style.scss';

const PathCourse = ({
  title,
  lessons,
  deleteItem,
  intl: { formatMessage },
  dndAttributes,
  dndListeners,
}) => {
  const markerStart = formatMessage({ id: 'PATH.COURSE_START' }, { name: title });
  const markerEnd = formatMessage({ id: 'PATH.COURSE_END' }, { name: title });

  return (
    <div className="base-course">
      {dndAttributes && dndListeners ? (
        <div>
          <IconButton {...dndAttributes} {...dndListeners} iconClass="fa fa-bars" />
        </div>
      ) : null}
      <div className="header-course">
        <PathSectionMarker name={markerStart} />
        <div className="delete-course">
          <DeleteControl enabled show={!!deleteItem} deleteItem={deleteItem} />
        </div>
      </div>
      <div className="lessons">
        {lessons.map((lesson) => (
          <PathLesson key={lesson.lessonId} title={lesson.title} />
        ))}
      </div>
      <div className="footer-course">
        <PathSectionMarker name={markerEnd} />
      </div>
    </div>
  );
};

PathCourse.propTypes = {
  title: PropTypes.string,
  lessons: PropTypes.arrayOf(pathLesson),
  deleteItem: PropTypes.func,
  intl: PropTypes.object,
  dndAttributes: PropTypes.object,
  dndListeners: PropTypes.object,
};

export default injectIntl(PathCourse);
