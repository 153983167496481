// TODO: Refactor to Subsidiary

import qs from 'query-string';

import request from '../libs/request';
import { Methods } from '../libs/http';
import Settings from '../config/settings';

const COMPANY_URL = '/companies';
const SUBSIDIARY_URL = '/subsidiaries';
const SUBSIDIARY_SUBSCRIPTIONS_URL = '/subsidiary-subscriptions';

export const getSubsidiaries = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${SUBSIDIARY_URL}?${qs.stringify(params, Settings.QUERY_FORMAT)}`,
  });
};

export const getSubsidiariesTopData = async () => {
  return await request({
    method: Methods.GET,
    resource: `${SUBSIDIARY_URL}/top-data`,
  });
};

export const getSubsidiaryById = async (id) => {
  return await request({
    method: Methods.GET,
    resource: `${SUBSIDIARY_URL}/${id}`,
  });
};

export const deleteSubsidiary = async (id) => {
  return await request({
    method: Methods.DELETE,
    resource: `${SUBSIDIARY_URL}/${id}`,
  });
};

export const addUserToSubsidiary = async (subsidiaryId, data) => {
  return await request({
    method: Methods.POST,
    resource: `${COMPANY_URL}/${subsidiaryId}/members`,
    data,
  });
};

export const editUserAtSubsidiary = async (subsidiaryId, data) => {
  return await request({
    method: Methods.PUT,
    resource: `${COMPANY_URL}/${subsidiaryId}/members`,
    data,
  });
};

export const deleteUserFromSubsidiary = async (subsidiaryId, memberId) => {
  return await request({
    method: Methods.DELETE,
    resource: `${COMPANY_URL}/${subsidiaryId}/members/${memberId}`,
  });
};

export const deleteAdminFromSubsidiary = async (subsidiaryId, userId) => {
  return await request({
    method: Methods.DELETE,
    resource: `${SUBSIDIARY_URL}/${subsidiaryId}/users/${userId}`,
  });
};

export const getSubsidiaryContacts = async (subsidiaryId) => {
  return await request({
    method: Methods.GET,
    resource: `${SUBSIDIARY_URL}/${subsidiaryId}/contacts`,
  });
};

export const addContactToSubsidiary = async (subsidiaryId, data) => {
  return await request({
    method: Methods.POST,
    resource: `${SUBSIDIARY_URL}/${subsidiaryId}/contacts`,
    data,
  });
};

export const editContactAtSubsidiary = async (subsidiaryId, contactId, data) => {
  return await request({
    method: Methods.PUT,
    resource: `${SUBSIDIARY_URL}/${subsidiaryId}/contacts/${contactId}`,
    data,
  });
};

export const deleteContactFromSubsidiary = async (subsidiaryId, contactId) => {
  return await request({
    method: Methods.DELETE,
    resource: `${SUBSIDIARY_URL}/${subsidiaryId}/contacts/${contactId}`,
  });
};

export const createSubsidiary = async (companyId, data) => {
  return await request({
    method: Methods.POST,
    resource: `${COMPANY_URL}/${companyId}/subsidiaries`,
    data,
  });
};

export const disableSubsidiary = async (companyId) => {
  return await request({
    method: Methods.PUT,
    resource: `${SUBSIDIARY_URL}/${companyId}/disable`,
  });
};

export const enableSubsidiary = async (companyId) => {
  return await request({
    method: Methods.PUT,
    resource: `${SUBSIDIARY_URL}/${companyId}/enable`,
  });
};

export const createSubsidiaryAddress = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `/addresses/admin`,
    data,
  });
};

export const editSubsidiaryAddress = async (data) => {
  return await request({
    method: Methods.PUT,
    resource: `/addresses/${data.id}`,
    data,
  });
};

export const editSubsidiary = async (subsidiaryId, data) => {
  return await request({
    method: Methods.PUT,
    resource: `${SUBSIDIARY_URL}/${subsidiaryId}`,
    data,
  });
};

export const saveSubsidiaryLogo = async (subsidiaryId, data) => {
  return await request({
    method: Methods.POST,
    resource: `${SUBSIDIARY_URL}/${subsidiaryId}/logo`,
    data,
  });
};

export const getSubsidiarySubscriptions = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${SUBSIDIARY_SUBSCRIPTIONS_URL}?${qs.stringify(params, Settings.QUERY_FORMAT)}`,
  });
};

export const changeSubsidiarySubscription = async (data) => {
  return await request({
    method: Methods.PUT,
    resource: `${SUBSIDIARY_SUBSCRIPTIONS_URL}`,
    data,
  });
};

export const deleteSubsidiarySubscription = async (subsidiaryId) => {
  return await request({
    method: Methods.DELETE,
    resource: `${SUBSIDIARY_SUBSCRIPTIONS_URL}/${subsidiaryId}}`,
  });
};
