import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'components';

const EditOrReadIconButton = ({ canEdit, edit, read }) => {
  return canEdit ? (
    <IconButton iconClass="lemon icon-edit purple" className="no-margin" onClick={edit} />
  ) : (
    <IconButton iconClass="far fa-eye" className="no-margin" onClick={read} />
  );
};

EditOrReadIconButton.propTypes = {
  canEdit: PropTypes.bool,
  edit: PropTypes.func.isRequired,
  read: PropTypes.func.isRequired,
};
export default EditOrReadIconButton;
