import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

import { useSelectValues } from 'hooks/useSelectValues';

const hasError = ({ errors, touched, fieldName }) =>
  Boolean(get(errors, fieldName) && get(touched, fieldName));

export const useSelectFieldValues = ({
  fieldName,
  values,
  optionKey = 'id',
  optionAsObject = false,
  onlyEnabledOptions = false,
  multi = false,
  isValueDisabled = (value) => !!value?.isDisabled,
  getOptionLabel,
}) => {
  const { values: formikValues, errors, touched, setFieldValue } = useFormikContext();
  const fieldValue = get(formikValues, fieldName);

  const { getSelectedValue, ...selectValues } = useSelectValues({
    values,
    optionKey,
    optionAsObject,
    onlyEnabledOptions,
    multi,
    isValueDisabled,
    getOptionLabel,
  });

  const getSelectedFieldValue = useCallback(() => getSelectedValue(fieldValue), [
    fieldValue,
    getSelectedValue,
  ]);

  const setSelectedValue = useCallback(
    (option) => {
      setFieldValue(fieldName, option?.value || null);
    },
    [fieldName, setFieldValue]
  );

  return {
    selectedValue: getSelectedFieldValue(),
    setSelectedValue,
    fieldError: get(errors, fieldName),
    hasErrorMessage: hasError({ errors, touched, fieldName }),
    ...selectValues,
  };
};
