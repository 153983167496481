import React from 'react';
import PropTypes from 'prop-types';

import { statisticsType, statisticsTypeConstants } from 'util/statisticsType';

import './style.scss';

const BaseStatisticsLabel = ({
  type,
  baseData,
  labelFilter,
  handleSetLabelFilter,
  loading,
  isAccordionOpen,
  setAccordionOpen,
}) => {
  return (
    <div className="base-statistics-component-header-label">
      {statisticsTypeConstants[type].getAccordionLabel({
        baseData,
        labelFilter,
        handleSetLabelFilter,
        loading,
        isAccordionOpen,
        setAccordionOpen,
      })}
    </div>
  );
};

BaseStatisticsLabel.propTypes = {
  type: PropTypes.oneOf(Object.values(statisticsType)).isRequired,
  baseData: PropTypes.object.isRequired,
  labelFilter: PropTypes.object,
  isAccordionOpen: PropTypes.bool,
  loading: PropTypes.bool,
  handleSetLabelFilter: PropTypes.func,
  setAccordionOpen: PropTypes.func,
};

export default BaseStatisticsLabel;
