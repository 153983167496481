import React from 'react';
import {
  Bar,
  Cell,
  Legend as RechartLegend,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartTooltip,
} from 'recharts';
import { v4 as uuidv4 } from 'uuid';

import AreaDot from './AreaDot/AreaDot';
import Legend from './Legend/Legend';
import Tooltip from './Tooltip/Tooltip';

import './style.scss';

const COLOR_PREFIX = 'color';
const gridColor = '#EAF0F4';
const axisColor = '#EAF0F4';
const tickStrokeColor = '#43425D';

export const orientationTypes = {
  LEFT: 'left',
  RIGHT: 'right',
};
export const shapeTypes = {
  LINE: 'line',
  RECT: 'rect',
};
export const defaultDotProps = {
  DEFAULT_RADIUS: 3,
  DEFAULT_FILL: 'white',
  DEFAULT_STROKE_WIDTH: 2,
};
export const defaultChartMargin = { top: 5, right: 30, left: 30, bottom: 5 };
export const defaultBarProps = { barSize: 35, radius: [4, 4, 0, 0] };
export const defaultLineProps = { strokeWidth: 2, type: 'monotone' };
export const defaultXAxisPadding = { left: 0, right: 0 };
export const defaultYAxisWidth = 10;

const defaultYAxisProps = {
  fontSize: '12px',
  dx: -30,
  className: 'y-axis-label-center',
  angle: -90,
  color: tickStrokeColor,
  fillOpacity: 0.5,
};
const tickStyling = {
  fill: tickStrokeColor,
  color: tickStrokeColor,
  fontSize: '10px',
  fillOpacity: 0.5,
};
const legend = {
  verticalAlign: 'top',
  horizontalAlign: 'left',
  margin: { top: '10px', bottom: '30px' },
};

const areaChartLinearGradient = {
  COORDINATES: {
    x1: '0',
    y1: '0',
    x2: '0',
    y2: '1',
  },
  TOP: {
    OFFSET_PERCENT: '5%',
    STOP_OPACITY: 0.5,
  },
  BOTTOM: {
    OFFSET_PERCENT: '75%',
    STOP_OPACITY: 0,
  },
};

export const orderComparator = (getOrderFromEntry = (entry) => (entry ? entry.order : null)) => (
  entry1,
  entry2
) => {
  const orderEntry1 = getOrderFromEntry(entry1);
  const orderEntry2 = getOrderFromEntry(entry2);

  return !isNaN(orderEntry1) && isNaN(orderEntry2)
    ? -1
    : isNaN(orderEntry1) && !isNaN(orderEntry2)
    ? 1
    : isNaN(orderEntry1) && isNaN(orderEntry2)
    ? 0
    : orderEntry1 - orderEntry2;
};

const getYAxisLabelProps = (label, orientation) => {
  const { dx: defaultDx, ...yAxisProps } = defaultYAxisProps;
  const dx = orientation === orientationTypes.RIGHT ? defaultDx * -1 : defaultDx;
  return {
    value: label,
    dx,
    ...yAxisProps,
  };
};

export const getCommonChartElements = ({
  xAxisDataKey,
  xAxisPadding,
  tooltipBorderColor,
  hideLegend,
  yAxisElements,
  yAxes,
}) => {
  return [
    <CartesianGrid key={uuidv4()} stroke={gridColor} />,
    <XAxis
      tick={tickStyling}
      stroke={axisColor}
      key={uuidv4()}
      dataKey={xAxisDataKey}
      padding={xAxisPadding || defaultXAxisPadding}
    />,
    ...(yAxes && yAxes.length > 0
      ? yAxes.map(({ yAxisId, stroke, tick, width, label, orientation, ...props }) => (
          <YAxis
            key={yAxisId}
            yAxisId={yAxisId}
            tick={tick || tickStyling}
            stroke={stroke || axisColor}
            width={width || defaultYAxisWidth}
            orientation={orientation}
            label={getYAxisLabelProps(label, orientation)}
            {...props}
          />
        ))
      : [
          <YAxis
            key={uuidv4()}
            tick={tickStyling}
            stroke={axisColor}
            width={defaultYAxisWidth}
            hide
          />,
        ]),
    <RechartTooltip
      key={uuidv4()}
      content={(props) => (
        <Tooltip
          {...props}
          yAxisElements={yAxisElements}
          xAxisDataKey={xAxisDataKey}
          borderColor={tooltipBorderColor}
        />
      )}
    />,
    ...(!hideLegend
      ? [
          <RechartLegend
            key={uuidv4()}
            verticalAlign={legend.verticalAlign}
            align={legend.horizontalAlign}
            content={(props) => <Legend {...props} margin={legend.margin} />}
          />,
        ]
      : []),
  ];
};

export const getAreas = (areasData = [], chartData) => {
  const linearGradients = areasData.map(({ dataKey, stroke, getDynamicGradientProps }) => {
    const { topColor, topOpacity, topOffset, bottomColor, bottomOpacity, bottomOffset } =
      (getDynamicGradientProps && getDynamicGradientProps(chartData)) || {};

    return (
      <linearGradient
        key={`linear-gradient-${dataKey}`}
        id={`${COLOR_PREFIX}${dataKey}`}
        {...areaChartLinearGradient.COORDINATES}>
        <stop
          offset={topOffset || areaChartLinearGradient.TOP.OFFSET_PERCENT}
          stopColor={topColor || stroke}
          stopOpacity={topOpacity || areaChartLinearGradient.TOP.STOP_OPACITY}
        />
        <stop
          offset={bottomOffset || areaChartLinearGradient.BOTTOM.OFFSET_PERCENT}
          stopColor={bottomColor || stroke}
          stopOpacity={bottomOpacity || areaChartLinearGradient.BOTTOM.STOP_OPACITY}
        />
      </linearGradient>
    );
  });

  const areas = areasData.map(
    ({ dataKey, stroke: areaStroke, getDynamicLineProps, getDynamicDotProps, ...lineProps }) => {
      const { stroke, ...dynamicLineProps } =
        (getDynamicLineProps && getDynamicLineProps(chartData)) || {};
      return (
        <Area
          key={`area-${dataKey}`}
          dataKey={dataKey}
          stroke={stroke || areaStroke}
          fill={`url(#${COLOR_PREFIX}${dataKey})`}
          dot={<AreaDot stroke={stroke || areaStroke} getDotProps={getDynamicDotProps} />}
          {...defaultLineProps}
          {...lineProps}
          {...dynamicLineProps}
        />
      );
    }
  );

  const defs = <defs key={uuidv4()}>{linearGradients}</defs>;
  return [defs, areas];
};

export const getBars = (barsData = [], chartData) =>
  barsData.map(({ dataKey, fill, getDynamicProps, ...barProps }) => (
    <Bar
      key={`bar-${dataKey}`}
      dataKey={dataKey}
      fill={fill}
      fillOpacity={1}
      {...defaultBarProps}
      {...barProps}>
      {(chartData || []).map((entry, index) => (
        <Cell
          cursor="pointer"
          fill={fill}
          {...(getDynamicProps && getDynamicProps(entry))}
          key={`cell-${index}`}
        />
      ))}
    </Bar>
  ));
