import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import { modalType } from 'util/modalType';
import Settings from 'config/settings';
import { TableContainer, EnableDisableControl, DeleteControl, IconButton } from 'components';
import UserHistory from './UserHistory';
import SendReminderOrForgotPasswordControl from './SendReminderOrForgotPasswordControl';
import UserRolesContainer from './UserRolesContainer/UserRolesContainer';
import {
  widthOptions,
  horizontalAlignOptions,
  verticalAlignOptions,
} from 'components/Table/tableConstants';
import { UserAccess } from 'libs/accessManagement';

const UsersTable = ({
  list = [],
  isSystemAdminPage,
  showCompanySubsidiaryChooser,
  availableRoles,
  existingEmails,
  onSaved,
  skipFetchOnChange,
  openEditModal,
  openDeleteModal,
  openEnableModal,
  openDisableModal,
  profile,
  loadList,
  params,
  loading,
  sendReminder,
  sendForgotPasswordEmail,
  intl: { formatMessage },
}) => {
  const [modal, setModal] = useState(null);
  const [userId, setUserId] = useState(null);

  const getUserCompanyName = (user = {}) =>
    user.companies && user.companies.length > 0
      ? user.companies.map(({ name }) => name).join(', ')
      : formatMessage({ id: 'COMMON.NO_DATA' });

  const getUserSubsidiaryName = (user = {}) =>
    user.subsidiaries && user.subsidiaries.length > 0
      ? user.subsidiaries.map(({ name }) => name).join(', ')
      : formatMessage({ id: 'COMMON.NO_DATA' });

  const getUserStatus = (user = {}) => {
    const baseMessage = user.isDisabled
      ? formatMessage({ id: 'USERS.DISABLED' })
      : user.lastName
      ? formatMessage({ id: 'USERS.REGISTERED' })
      : formatMessage({ id: 'USERS.PENDING' });
    const registrationDateMessage = `${formatMessage({
      id: 'USERS.REGISTRATION_DATE',
    })}: ${user.registrationDate}`;

    return `${baseMessage}, ${registrationDateMessage}`;
  };

  const openModal = (modal, userId) => {
    setModal(modal);
    setUserId(userId);
  };

  const closeModal = () => {
    setModal(null);
    setUserId(null);
  };

  const {
    hasRoleToEdit,
    hasRoleToDisable,
    hasRoleToDelete,
    RoleAccess: {
      hasRoleToRead: hasRoleToReadRole,
      hasRoleToAdd: hasRoleToAddRole,
      hasRoleToDelete: hasRoleToDeleteRole,
    },
  } = UserAccess.getHasRoles(profile.profile);

  const isRegistered = (user) => !!user?.lastName;

  return (
    <>
      <TableContainer
        hideCollapse={() => !hasRoleToReadRole || isSystemAdminPage}
        rowCollapseChildren={(item) => (open) => {
          return (
            <UserRolesContainer
              user={item}
              open={open}
              onSaved={onSaved}
              skipFetchOnChange={skipFetchOnChange}
              availableRoles={availableRoles}
              existingEmails={existingEmails}
              showCompanySubsidiaryChooser={showCompanySubsidiaryChooser}
              hasRoleToCreate={hasRoleToAddRole}
              hasRoleToDelete={hasRoleToDeleteRole}
            />
          );
        }}
        loading={loading}
        list={list}
        loadList={loadList}
        params={params}
        columns={[
          {
            key: 'edit',
            hide: !(hasRoleToEdit || isSystemAdminPage),
            iconCell: true,
            onClick: (group) => openEditModal(group),
            editIcon: true,
            minWidth: widthOptions.auto,
            maxWidth: widthOptions.xs,
          },
          {
            key: 'lastName',
            label: formatMessage({ id: 'USERS.LAST_NAME' }),
            sortBy: Settings.SORT_BY.LAST_NAME,
          },
          {
            key: 'firstName',
            label: formatMessage({ id: 'USERS.FIRST_NAME' }),
            sortBy: Settings.SORT_BY.FIRST_NAME,
          },
          {
            key: 'email',
            label: formatMessage({ id: 'COMMON.EMAIL' }),
          },
          {
            key: 'companies',
            label: formatMessage({ id: 'USERS.COMPANIES' }),
            width: widthOptions.md,
            horizontalAlign: horizontalAlignOptions.left,
            verticalAlignOptions: verticalAlignOptions.center,
            render: (user) => getUserCompanyName(user),
            itemTooltip: (user) => getUserCompanyName(user),
          },
          {
            key: 'subsidiaries',
            label: formatMessage({ id: 'USERS.SUBSIDIARIES' }),
            width: widthOptions.md,
            horizontalAlign: horizontalAlignOptions.left,
            verticalAlignOptions: verticalAlignOptions.center,
            render: (user) => getUserSubsidiaryName(user),
            itemTooltip: (user) => getUserSubsidiaryName(user),
          },
          {
            key: 'status',
            label: formatMessage({ id: 'USERS.STATUS' }),
            width: widthOptions.md,
            horizontalAlign: horizontalAlignOptions.left,
            verticalAlignOptions: verticalAlignOptions.center,
            render: (user) => getUserStatus(user),
            itemTooltip: (user) => getUserStatus(user),
          },
          {
            key: 'history',
            hide: isSystemAdminPage,
            label: formatMessage({ id: 'COMMON.HISTORY' }),
            width: widthOptions.sm,
            horizontalAlign: horizontalAlignOptions.left,
            verticalAlignOptions: verticalAlignOptions.center,
            render: (user) => (
              <IconButton
                iconClass="lemon icon-info dark-gray"
                onClick={() => openModal(modalType.HISTORY, user.id)}
              />
            ),
            itemTooltip: (user) => getUserStatus(user),
          },
          {
            key: 'reminder_forgot',
            hide: isSystemAdminPage,
            minWidth: widthOptions.sm,
            maxWidth: widthOptions.md,
            horizontalAlign: horizontalAlignOptions.center,
            verticalAlignOptions: verticalAlignOptions.center,
            render: (user) => (
              <SendReminderOrForgotPasswordControl
                sendReminder={sendReminder}
                sendForgotPasswordEmail={sendForgotPasswordEmail}
                user={user}
                isRegistered={isRegistered(user)}
              />
            ),
          },
          isSystemAdminPage
            ? {
                key: 'enable_disable',
                hide: !hasRoleToDisable,
                horizontalAlign: horizontalAlignOptions.center,
                verticalAlignOptions: verticalAlignOptions.center,
                render: (user) => (
                  <EnableDisableControl
                    item={user}
                    showEnable={hasRoleToDisable}
                    showDisable={hasRoleToDisable}
                    itemDisabled={user.isDisabled}
                    enableItem={(user) => {
                      openEnableModal(user);
                    }}
                    enableLabel={formatMessage({ id: 'COMMON.ENABLE' })}
                    disableItem={(user) => {
                      openDisableModal(user);
                    }}
                    disableLabel={formatMessage({ id: 'COMMON.DISABLE' })}
                  />
                ),
              }
            : {
                key: 'delete',
                hide: !hasRoleToDelete,
                horizontalAlign: horizontalAlignOptions.center,
                verticalAlignOptions: verticalAlignOptions.center,
                maxWidth: widthOptions.xs,
                render: (user) => (
                  <DeleteControl
                    show={true}
                    enabled={hasRoleToDelete}
                    item={user}
                    deleteItem={openDeleteModal}
                  />
                ),
              },
        ]}
      />
      <UserHistory open={modal === modalType.HISTORY} closeModal={closeModal} userId={userId} />
    </>
  );
};

UsersTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  isSystemAdminPage: PropTypes.bool,
  openEditModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openEnableModal: PropTypes.func.isRequired,
  openDisableModal: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
  skipFetchOnChange: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  loadList: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  showCompanySubsidiaryChooser: PropTypes.bool,
  sendReminder: PropTypes.func.isRequired,
  sendForgotPasswordEmail: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  availableRoles: PropTypes.array,
  existingEmails: PropTypes.func,
};

export default withPagination(injectIntl(UsersTable));
