import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Yup } from 'util/Yup';
import SpecificsFormContent from './SpecificsFormContent';
import { getTypeValueFromFormValues } from '../../util/specificsUtil';

const SpecificsForm = ({
  disabled,
  specific,
  arrayHelpers,
  formRef,
  editSpecificIndex,
  closeSpecificsModal,
}) => {
  const preloadSaveData = ({ value, ...values }) => {
    return { ...values, value: getTypeValueFromFormValues({ type: values.type, value }) };
  };

  const validationSchema = Yup.object().shape({
    key: Yup.string().required(),
    type: Yup.string().required(),
    level: Yup.string().required(),
    isSearchable: Yup.boolean(),
    isGenerate: Yup.boolean(),
    isShow: Yup.boolean(),
    isGeneral: Yup.boolean(),
    question: Yup.string().when('isGenerate', {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
  });

  const onSubmit = (values) => {
    const convertedValues = preloadSaveData(values);
    if (editSpecificIndex > -1) {
      arrayHelpers.replace(editSpecificIndex, convertedValues);
    } else {
      arrayHelpers.push(convertedValues);
    }
    closeSpecificsModal();
  };

  return (
    <Formik
      initialValues={specific}
      validateOnBlur={false}
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      onSubmit={(values) => onSubmit(values)}>
      <SpecificsFormContent disabled={disabled} />
    </Formik>
  );
};

SpecificsForm.propTypes = {
  specific: PropTypes.object.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  closeSpecificsModal: PropTypes.func.isRequired,
  formRef: PropTypes.object,
  disabled: PropTypes.bool,
  editSpecificIndex: PropTypes.number,
};

export default SpecificsForm;
