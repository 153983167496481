import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { BasicPage, StatisticsOverviewContainer } from 'components';
import OverviewFilterType from './OverviewTypeFilter/OverviewFilterType';
import {
  statisticsOverviewType,
  statisticsOverviewTypeConstants,
  doesTypeFilterContain,
  getNewTypeFilter,
  TYPE_FILTER_KEY,
} from 'util/statisticsOverviewType';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';

import './style.scss';

const StatisticsPage = ({ type: statisticsOverviewType, intl: { formatMessage } }) => {
  const { defaultParams } = useMemo(
    () => statisticsOverviewTypeConstants[statisticsOverviewType].paginationQueryParams,
    [statisticsOverviewType]
  );
  const fetchCompanyStatisticsOverview = useCallback(
    async (params) => {
      return await statisticsOverviewTypeConstants[statisticsOverviewType].fetchStatisticOverview(
        params
      );
    },
    [statisticsOverviewType]
  );

  const {
    data: statisticsOverviewData,
    isLoading,
    params,
    setCurrentParams,
    changeParams,
    changeParamsAndResetPage,
  } = useFetchWithQueryParams({
    fetch: fetchCompanyStatisticsOverview,
    queryParamsConfig: defaultParams,
  });

  const handleSelectorChange = useCallback(
    ({ firstSelectorValue, secondSelectorValue }) => {
      const type = getNewTypeFilter(
        firstSelectorValue,
        secondSelectorValue,
        statisticsOverviewType
      );
      changeParamsAndResetPage({ [TYPE_FILTER_KEY]: type });
    },
    [changeParamsAndResetPage, statisticsOverviewType]
  );

  const getTranslateMessage = (translateId) =>
    translateId ? formatMessage({ id: translateId }) : null;

  const count = statisticsOverviewData && statisticsOverviewData.count;
  const { translateId, firstSelector, secondSelector } =
    statisticsOverviewTypeConstants[statisticsOverviewType] || {};

  const getKey = useMemo(() => {
    return statisticsOverviewTypeConstants[statisticsOverviewType].getKey;
  }, [statisticsOverviewType]);

  return (
    <BasicPage
      title={getTranslateMessage(translateId)}
      count={count}
      params={params}
      setCurrentParams={setCurrentParams}
      filterBySearchText
      iconContainerClassName="statistics-page-icon-container"
      loading={isLoading}
      additionalFilters={() => (
        <div className="statistics-page-checkbox-filter">
          <OverviewFilterType
            firstSelectorDisabled={
              doesTypeFilterContain(firstSelector.value, params) &&
              !doesTypeFilterContain(secondSelector.value, params)
            }
            firstSelectorValue={doesTypeFilterContain(firstSelector.value, params)}
            firstSelectorPopoverText={getTranslateMessage(firstSelector.popoverTranslateId)}
            firstSelectorLabel={getTranslateMessage(firstSelector.translateId)}
            secondSelectorDisabled={
              doesTypeFilterContain(secondSelector.value, params) &&
              !doesTypeFilterContain(firstSelector.value, params)
            }
            secondSelectorValue={doesTypeFilterContain(secondSelector.value, params)}
            secondSelectorPopoverText={getTranslateMessage(secondSelector.popoverTranslateId)}
            secondSelectorLabel={getTranslateMessage(secondSelector.translateId)}
            onChange={({ firstSelectorValue, secondSelectorValue }) =>
              handleSelectorChange({
                firstSelectorValue,
                secondSelectorValue,
                statisticsOverviewType,
              })
            }
          />
        </div>
      )}>
      {() => (
        <StatisticsOverviewContainer
          statisticsOverviewData={statisticsOverviewData && statisticsOverviewData.data}
          count={count}
          currentPage={params.page}
          loadPage={(page) => changeParams({ ...page })}
          getKey={getKey}
        />
      )}
    </BasicPage>
  );
};

StatisticsPage.propTypes = {
  type: PropTypes.oneOf(Object.values(statisticsOverviewType)).isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(StatisticsPage);
