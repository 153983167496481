import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IconButton, ShowFor, LogoAnimation } from 'components';

import './style.scss';

const SideBar = ({ open, pages, close, spaceBeneathIndex, isShownPage, location, history }) => {
  const isActive = (location, page, childrenComponent) => {
    return childrenComponent
      ? location.pathname.includes(page) && location.pathname.includes(childrenComponent)
      : location.pathname.includes(page);
  };

  const toPage = (url) => {
    history.push(url);
  };

  const toHomePage = () => {
    history.push('/home');
  };

  const onClick = (page, toPage) => !page.disableRedirect && toPage(page.path);

  const isSpaceBeneath = (spaceBeneathIndex, pages, isShownPage, page) =>
    spaceBeneathIndex >= 0 &&
    pages.filter((page) => isShownPage(page)).indexOf(page) === spaceBeneathIndex;

  return (
    <div className={classNames('side-bar', { open })}>
      <div className="side-bar__header">
        <LogoAnimation size="xs" onClick={toHomePage} />
        <IconButton
          type="button"
          iconClass="lemon icon-close purple"
          className="side-bar__close"
          theme="light"
          onClick={close}
          aria-label="Close"
        />
      </div>
      <div className="side-bar__content">
        {pages.map((page, index) => {
          return (
            <ShowFor showFor={page.showFor} key={`${page.path}-${index}`}>
              <div
                className={classNames('side-bar__nav-item', {
                  'side-bar__nav-item--space-beneath': isSpaceBeneath(
                    spaceBeneathIndex,
                    pages,
                    isShownPage,
                    page
                  ),
                })}>
                <div
                  className={classNames('side-bar__nav-link', {
                    'side-bar__nav-link--active': isActive(
                      location,
                      page.match,
                      page.childrenComponent
                    ),
                    'side-bar__nav-link--clickable': !page.disableRedirect,
                    'side-bar__nav-link--sub-link': !!page.childrenComponent,
                    'side-bar__nav-link--root-link': !page.childrenComponent,
                  })}
                  onClick={() => onClick(page, toPage)}>
                  {page.linkName}
                </div>
              </div>
            </ShowFor>
          );
        })}
      </div>
    </div>
  );
};

SideBar.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  pages: PropTypes.array.isRequired,
  spaceBeneathIndex: PropTypes.number.isRequired,
  isShownPage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SideBar;
