import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { Form, Field } from 'formik';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getProvinces } from 'actions/provinces';
import Settings from 'config/settings';
import { getDateString } from 'libs/strings';
import { Yup } from 'util/Yup';
import { continueRegistration } from 'actions/continueRegistration';
import {
  CountryStateChooser,
  DatePickerField,
  TextField,
  Button,
  SelectField,
  RegistrationPage,
} from 'components';
import { getValidationMessageInput } from 'libs/validation/validation';
import { isLegalDrinkingAgeInCountry, isStateRequired } from 'libs/validation/user/validation';

import './style.scss';

const countryStateValueFields = { country: 'country', state: 'state' };

const ContinueRegistrationPage = ({
  provinces,
  getProvinces,
  continueRegistration,
  intl: { formatMessage },
}) => {
  const { search } = useLocation();

  const initialValues = {
    firstName: '',
    lastName: '',
    birthDate: null,
    password: '',
    phoneNumber: '',
    gender: '',
    street: '',
    publicPlace: '',
    houseNumber: '',
    city: '',
    zip: '',
    country: '',
    state: '',
  };

  const validationSchema = Yup.object().shape({
    lastName: Yup.string().required(),
    firstName: Yup.string().required(),
    password: Yup.string()
      .min(Settings.PASSWORD_MIN_LENGTH, getValidationMessageInput('COMMON.FIELD_PASSWORD_ERROR'))
      .matches(Settings.PASSWORD_PATTERN, getValidationMessageInput('COMMON.FIELD_PASSWORD_ERROR'))
      .required(),
    birthDate: Yup.date()
      .required()
      .nullable()
      .test(
        'validateCountrySelected',
        getValidationMessageInput('COMMON.FIELD_COUNTRY_NEEDED'),
        function () {
          return !!this.parent.country;
        }
      )
      .test('validateAge', getValidationMessageInput('COMMON.FIELD_AGE_ERROR'), function (value) {
        return isLegalDrinkingAgeInCountry(provinces, value, this.parent);
      }),
    gender: Yup.string(),
    country: Yup.string().required(),
    state: Yup.string()
      .nullable()
      .test(
        'validateState',
        getValidationMessageInput('COMMON.FIELD_STATE_NEEDED'),
        function (value) {
          return isStateRequired(provinces, this.parent.country, value, countryStateValueFields);
        }
      ),
    zip: Yup.string(),
    city: Yup.string(),
    street: Yup.string(),
    publicPlace: Yup.string(),
    houseNumber: Yup.string(),
    phoneNumber: Yup.string().matches(
      new RegExp(Settings.PHONE_PATTERN),
      getValidationMessageInput('COMMON.FIELD_PHONE_ERROR')
    ),
  });

  useEffect(() => {
    const fetchProvinces = async () => {
      await getProvinces();
    };
    fetchProvinces();
  }, [getProvinces]);

  const onSubmit = async (values) => {
    const params = {
      ...values,
      country: values.country,
      birthDate: values?.birthDate ? getDateString(values.birthDate) : '',
      phoneNumber: values.phoneNumber.length === 1 ? '' : values.phoneNumber,
      token: qs.parse(search, Settings.QUERY_FORMAT).token,
    };

    return await continueRegistration(params);
  };

  return (
    <RegistrationPage
      formClassName="continue-registration-page__registration-form"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      title={<FormattedMessage id="CONTINUE_REGISTRATION.COMPLETE_REGISTRATION" />}
      content={
        <Form>
          <Field
            name="lastName"
            component={TextField}
            label={<FormattedMessage id="USERS.LAST_NAME_REQ" />}
          />
          <Field
            name="firstName"
            component={TextField}
            label={<FormattedMessage id="USERS.FIRST_NAME_REQ" />}
          />

          <Field
            name="password"
            component={TextField}
            label={<FormattedMessage id="USERS.PASSWORD_REQ" />}
            type="password"
            autoComplete="new-password"
          />

          <Field
            name="birthDate"
            component={DatePickerField}
            placeholder={formatMessage({ id: 'USERS.BIRTH_DATE_REQ' })}
            label={<FormattedMessage id="USERS.BIRTH_DATE_REQ" />}
            showYearDropdown
            monthDatePicker
          />

          <Field
            name="gender"
            component={SelectField}
            label={<FormattedMessage id="USERS.GENDER" />}
            useOnlyValueAsFieldValue
            options={
              Settings.GENDERS &&
              Settings.GENDERS.map(({ label, value }) => {
                return {
                  label,
                  value,
                };
              })
            }
          />

          <CountryStateChooser
            selectClasses="col-12"
            countries={provinces}
            countryInputName="country"
            countryInputLabel={<FormattedMessage id="USERS.COUNTRY_REQ" />}
            stateInputName="state"
            stateInputLabel={<FormattedMessage id="USERS.STATE_REQ" />}
          />

          <Field
            name="zip"
            component={TextField}
            label={<FormattedMessage id="COMPANIES.ZIP_CODE" />}
          />

          <Field name="city" component={TextField} label={<FormattedMessage id="COMMON.CITY" />} />

          <Field
            name="street"
            component={TextField}
            label={<FormattedMessage id="COMPANIES.STREET" />}
          />

          <Field
            name="publicPlace"
            component={SelectField}
            useOnlyValueAsFieldValue
            label={<FormattedMessage id="COMPANIES.PUBLIC_SPACE_TYPE" />}
            options={Settings.PUBLIC_PLACES}
          />

          <Field
            name="houseNumber"
            component={TextField}
            label={<FormattedMessage id="COMPANIES.HOUSE_NUMBER" />}
          />

          <Field
            name="phoneNumber"
            component={TextField}
            label={<FormattedMessage id="COMMON.PHONE_NUMBER" />}
          />

          <div className="button-container d-grid">
            <Button type="submit" className="btn-primary btn-block">
              <FormattedMessage id="LOGIN.SEND" />
            </Button>
          </div>
        </Form>
      }
      label={<FormattedMessage id="CONTINUE_REGISTRATION.SUCCESSFUL_REQUEST" />}
      adminPageText={
        <>
          <FormattedMessage id="CONTINUE_REGISTRATION.LOGIN_HERE" />
          <br />
          <a href={Settings.PUBLIC_URL}>{Settings.PUBLIC_URL}</a>
        </>
      }
      userPageText={<FormattedMessage id="CONTINUE_REGISTRATION.DOWNLOAD_APP" />}
    />
  );
};

ContinueRegistrationPage.propTypes = {
  provinces: PropTypes.array.isRequired,
  getProvinces: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  continueRegistration: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    provinces: state.provinces.provinces.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ continueRegistration, getProvinces }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContinueRegistrationPage));
