import React, { useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { match, history, location } from 'common';
import { renderChildren } from 'components/ShowFor';
import Settings from 'config/settings';

import SideBar from './SideBar/SideBar';
import { getRoutes } from './routes';
import { isShownRoute, getSpaceBeneathIndex, getInitialRoute } from './utils';

import './style.scss';

const Home = ({
  closeSideBar,
  openSideBar,
  sideBar,
  profile,
  match,
  history,
  location,
  intl: { formatMessage },
}) => {
  const onResize = useCallback(() => {
    if (window.innerWidth >= Settings.BREAKPOINTS.large && !sideBar.open) {
      openSideBar();
    }
    if (window.innerWidth < Settings.BREAKPOINTS.large && sideBar.open) {
      closeSideBar();
    }
  }, [closeSideBar, openSideBar, sideBar.open]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    if (window.innerWidth <= Settings.BREAKPOINTS.large) {
      closeSideBar();
    }
  }, [location.path, closeSideBar]);

  const routes = getRoutes({ match, formatMessage });
  const { open: sideBarOpen } = sideBar;

  return (
    <div className={classNames('home d-flex', { 'home__side-bar--open': sideBarOpen })}>
      <SideBar
        open={sideBarOpen}
        close={closeSideBar}
        pages={routes}
        isShownPage={(route) => isShownRoute(route, profile.profile.roles)}
        spaceBeneathIndex={getSpaceBeneathIndex(routes, profile.profile.roles)}
        history={history}
        location={location}
        match={match}
      />
      <div className="home__main-content">
        {profile.profile.email && (
          <Switch>
            <Redirect exact from={`${match.path}/`} to={getInitialRoute(routes, profile.profile)} />
            {routes.map((route) => {
              return renderChildren(
                route.showFor,
                <Route
                  path={route.path}
                  render={(props) => {
                    return route.component
                      ? React.createElement(route.component, {
                          ...props,
                          ...route.props,
                          showFor: route.showFor,
                        })
                      : null;
                  }}
                  key={route.path}
                />
              );
            })}
            <Redirect to={`${match.path}/`} />
          </Switch>
        )}
      </div>
    </div>
  );
};

Home.propTypes = {
  openSideBar: PropTypes.func.isRequired,
  closeSideBar: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  sideBar: PropTypes.object.isRequired,
  profile: PropTypes.object,
  intl: PropTypes.object.isRequired,
  match,
  history,
  location,
};

export default injectIntl(Home);
