import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import { CheckBoxGroupField } from 'components';

const UserRolesTab = ({ availableRoles, availableRanks, disabled }) => {
  const rolesFieldName = 'roles';
  const groupFieldName = 'groups';

  return (
    <div>
      <Field
        component={CheckBoxGroupField}
        name={rolesFieldName}
        options={availableRoles}
        label={<FormattedMessage id="USERS.ROLES_LABEL" />}
        disabled={disabled}
      />
      {availableRanks ? (
        <Field
          component={CheckBoxGroupField}
          name={groupFieldName}
          options={availableRanks}
          label={<FormattedMessage id="GROUPS.TITLE" />}
          emptyMessage={<FormattedMessage id="USERS.GROUPS_EMPTY" />}
          disabled={disabled}
        />
      ) : null}
    </div>
  );
};

UserRolesTab.propTypes = {
  availableRoles: PropTypes.array.isRequired,
  availableRanks: PropTypes.array,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
};

export default UserRolesTab;
