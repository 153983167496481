import PropTypes from 'prop-types';

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children;
};
ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};
export default ConditionalWrapper;
