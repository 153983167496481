import {
  GET_COMPANIES,
  GET_COMPANIES_FAILED,
  GET_COMPANIES_SUCCESS,
  TOGGLE_ENABLE_COMPANY,
  TOGGLE_ENABLE_COMPANY_SUCCESS,
  TOGGLE_ENABLE_COMPANY_FAILED,
  DELETE_COMPANY,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: { data: [], count: 0 },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...INITIAL_STATE,
        loading: true,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          data: action.payload.data || [],
          count: action.payload.count,
        },
      };
    case GET_COMPANIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TOGGLE_ENABLE_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case TOGGLE_ENABLE_COMPANY_SUCCESS: {
      const data = [...state.data.data];
      const index = data.findIndex(({ id }) => id === action.payload.id);
      data[index] = { ...data[index], isDisabled: action.payload.isDisabled };
      return {
        ...state,
        loading: false,
        data: { ...state.data, data } || {},
      };
    }
    case TOGGLE_ENABLE_COMPANY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_COMPANY:
      return {
        ...state,
        loading: false,
        data: {
          data: state.data.data.filter((item) => item.id !== action.payload),
          count: state.data.count - 1,
        },
      };
    default:
      return state;
  }
};
