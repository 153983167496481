import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { InlineError } from 'components';

import './style.scss';

const Switcher = ({
  className,
  onText = 'On',
  offText = 'Off',
  disabled = false,
  field,
  form,
  warning,
  showWarning,
}) => {
  const errorMessage = get(form.errors, field.name);
  const hasErrorMessage = Boolean(errorMessage && get(form.touched, field.name));

  const onClick = () => {
    if (form && field) {
      form.setFieldValue(field.name, !field.value);
      form.setFieldTouched(field.name, true);
    }
  };

  return (
    <>
      <div className={classNames('Switcher', className, field.value && 'active', { disabled })}>
        {field.value && warning && showWarning && <div className="warning">{warning}</div>}
        <div className="d-flex">
          <div className="switch-button" onClick={() => !disabled && onClick()}>
            <div className="berry" />
          </div>
          <div className="label">{field.value ? onText : offText}</div>
        </div>
        <InlineError show={!!hasErrorMessage} message={errorMessage} />
      </div>
    </>
  );
};

Switcher.propTypes = {
  className: PropTypes.string,
  onText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  offText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  warning: PropTypes.node,
  showWarning: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
};

export default Switcher;
