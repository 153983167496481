import React from 'react';
import qs from 'query-string';

import { CompanySubsidiaryStatistics, ContentStatistics, MemberStatistics } from 'components';
import CompanySubsidiaryLabel from '../components/CompanySubsidiaryStatistics/CompanySubsidiaryLabel/CompanySubsidiaryLabel';
import ContentLabel from '../components/ContentStatistics/ContentLabel/ContentLabel';
import UserLabel from '../components/MemberStatistics/MemberLabels/UserLabel';
import GroupLabel from '../components/MemberStatistics/MemberLabels/GroupLabel';
import {
  getDefaultCompanySubsidiaryStatisticType,
  companySubsidiaryStatisticTypeConstants,
} from 'util/companySubsidiaryStatisticTypes';
import {
  companySubsidiaryTypeParam,
  contentTypeParam,
  memberTypeParam,
} from 'util/statisticsOverviewType';
import { statisticsTimePeriods } from 'util/statisticsTimeUtil';
import { getMemberStatistics, getContentSubscriptionTrend } from 'services/statistics';
import {
  CompanyAccess,
  SubsidiaryAccess,
  CourseAccess,
  LessonAccess,
  UserAccess,
} from 'libs/accessManagement';

const {
  NO_PERIOD,
  ONE_WEEK,
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  ONE_YEAR,
  TWO_YEARS,
  FIVE_YEARS,
} = statisticsTimePeriods;

export const statisticsType = {
  COMPANY: 'COMPANY',
  SUBSIDIARY: 'SUBSIDIARY',
  COURSE: 'COURSE',
  LESSON: 'LESSON',
  GROUP: 'GROUP',
  USER: 'USER',
};

const companySubsidiaryIntervalConfig = {
  options: [NO_PERIOD, ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR, TWO_YEARS, FIVE_YEARS],
  defaultOption: ONE_MONTH,
};

const contentIntervalConfig = {
  options: [NO_PERIOD, ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR],
  defaultOption: ONE_MONTH,
};

const membersIntervalConfig = {
  options: [NO_PERIOD, ONE_WEEK, ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR],
  defaultOption: ONE_WEEK,
};

const stringifySearchText = (input) => qs.stringify({ searchText: input });

const getCompanySubsidiaryStatisticsData = async (params = {}, type) => {
  const { id, filterType, startDate, endDate } = params;
  const res = await companySubsidiaryStatisticTypeConstants[filterType].fetchStatistic({
    modelId: id,
    from: startDate,
    to: endDate,
    type:
      type === statisticsType.COMPANY
        ? companySubsidiaryTypeParam.COMPANY
        : type === statisticsType.SUBSIDIARY
        ? companySubsidiaryTypeParam.SUBSIDIARY
        : null,
  });

  // TODO: Adjust according to BE endpoint and data structure rawChartData, yAxisDataKeys, xAxisDataKey
  return companySubsidiaryStatisticTypeConstants[filterType].getStatisticsDataFromResponse(res);
};

const getMembersStatisticsData = async (params, type) => {
  const { id, startDate, endDate } = params;
  const convertedParams = {
    modelId: id,
    from: startDate,
    to: endDate,
    type:
      type === statisticsType.USER
        ? memberTypeParam.USER
        : type === statisticsType.GROUP
        ? memberTypeParam.GROUP
        : null,
  };
  try {
    const result = await getMemberStatistics(convertedParams);
    const { activityData, assignedGroups, testStatistics, mostSearchWords, mostUsedTags, period } =
      result || {};

    return {
      primaryChart: activityData || [],
      period,
      chartDetail: {
        assignedGroups: assignedGroups || [],
        mostSearchedWords: mostSearchWords || [],
        mostUsedTags: mostUsedTags || [],
        testStatistics: testStatistics
          ? {
              successCount: testStatistics.successFulCount,
              failureCount: testStatistics.failedCount,
              totalCount: testStatistics.totalCount,
            }
          : {},
      },
    };
  } catch (e) {
    return {
      primaryChart: [],
      chartDetail: {
        assignedGroups: [],
        testStatistics: {},
        mostSearchedWords: [],
        mostUsedTags: [],
      },
    };
  }
};

const getContentStatisticsData = async (params, type) => {
  const { id, startDate, endDate } = params;
  const convertedParams = {
    modelId: id,
    from: startDate,
    to: endDate,
    type:
      type === statisticsType.COURSE
        ? contentTypeParam.COURSE
        : type === statisticsType.LESSON
        ? contentTypeParam.LESSON
        : null,
  };

  try {
    const result = await getContentSubscriptionTrend(convertedParams);
    const { subscriptionData, spentTimeData, ...rest } = result || {};

    return {
      primaryChart: subscriptionData ? subscriptionData : [],
      secondaryChart: spentTimeData ? spentTimeData : [],
      chartDetail: rest
        ? {
            successDetail: {
              value: rest.successfulTestsCount,
              percent: rest.successfulTestsRate,
              questions: rest.successfulQuestions,
            },
            failedDetail: {
              value: rest.failedTestsCount,
              percent: rest.failedTestsRate,
              questions: rest.failedQuestions,
            },
            totalDetail: { value: rest.totalTestsCount },
          }
        : {},
    };
  } catch (e) {
    return { primaryChart: [], secondaryChart: [], chartDetail: {} };
  }
};

const getGlobeIcon = () => <i className="fas fa-globe go-to-icon" />;

const getArrowIcon = () => <i className="far fa-arrow-alt-circle-right go-to-icon" />;

export const statisticsTypeConstants = {
  [statisticsType.COMPANY]: {
    getAccordionLabel: (props) => <CompanySubsidiaryLabel isCompany {...props} />,
    translateId: 'COMPANY_SUBSIDIARY_STATISTICS.GO_TO_COMPANY',
    navigationData: ({ name }) => [`/home/company-subsidiaries/?${stringifySearchText(name)}`],
    hasRoleToNavigate: (profile) => CompanyAccess.getHasRoles(profile.profile).hasRoleToAccess,
    getGoToIcon: () => getGlobeIcon(),
    intervalConfig: { ...companySubsidiaryIntervalConfig },
    fetchStatistic: async (params) =>
      getCompanySubsidiaryStatisticsData(params, statisticsType.COMPANY),
    getStatisticsComponent: (props) => <CompanySubsidiaryStatistics isCompany {...props} />,
    labelFilter: {
      defaultFilter: getDefaultCompanySubsidiaryStatisticType.filter,
      getFilterData: (type) => {
        return companySubsidiaryStatisticTypeConstants[type].filter;
      },
    },
  },
  [statisticsType.SUBSIDIARY]: {
    getAccordionLabel: (props) => <CompanySubsidiaryLabel {...props} />,
    translateId: 'COMPANY_SUBSIDIARY_STATISTICS.GO_TO_SUBSIDIARY',
    navigationData: ({ name }) => [`/home/companies/?${stringifySearchText(name)}`],
    hasRoleToNavigate: (profile) => SubsidiaryAccess.getHasRoles(profile.profile).hasRoleToAccess,
    getGoToIcon: () => getGlobeIcon(),
    intervalConfig: { ...companySubsidiaryIntervalConfig },
    fetchStatistic: async (params) =>
      getCompanySubsidiaryStatisticsData(params, statisticsType.SUBSIDIARY),
    getStatisticsComponent: (props) => <CompanySubsidiaryStatistics {...props} />,
    labelFilter: {
      defaultFilter: getDefaultCompanySubsidiaryStatisticType.filter,
      getFilterData: (type) => {
        return companySubsidiaryStatisticTypeConstants[type].filter;
      },
    },
  },
  [statisticsType.COURSE]: {
    getAccordionLabel: (props) => <ContentLabel {...props} />,
    translateId: 'CONTENT_STATISTICS.GO_TO_COURSE',
    navigationData: ({ id }) => [`/home/courses/`, { courseIds: [id] }],
    hasRoleToNavigate: (profile) => CourseAccess.getHasRoles(profile.profile).hasRoleToAccess,
    getGoToIcon: () => getArrowIcon(),
    intervalConfig: { ...contentIntervalConfig },
    fetchStatistic: async (params) => await getContentStatisticsData(params, statisticsType.COURSE),
    getStatisticsComponent: (props) => <ContentStatistics isCourse {...props} />,
  },
  [statisticsType.LESSON]: {
    getAccordionLabel: (props) => <ContentLabel {...props} />,
    translateId: 'CONTENT_STATISTICS.GO_TO_LESSON',
    navigationData: ({ id }) => [`/home/lessons/`, { lessonIds: [id] }],
    hasRoleToNavigate: (profile) => LessonAccess.getHasRoles(profile.profile).hasRoleToAccess,
    getGoToIcon: () => getArrowIcon(),
    intervalConfig: { ...contentIntervalConfig },
    fetchStatistic: async (params) => await getContentStatisticsData(params, statisticsType.LESSON),
    getStatisticsComponent: (props) => <ContentStatistics {...props} />,
  },
  [statisticsType.GROUP]: {
    getAccordionLabel: (props) => <GroupLabel {...props} />,
    translateId: 'MEMBERS_STATISTICS.GO_TO_GROUP',
    navigationData: ({ id }) => ['/home/groups/', { groupIds: [id] }],
    hasRoleToNavigate: (profile) =>
      SubsidiaryAccess.getHasRoles(profile.profile).GroupAccess.hasRoleToAccess,
    getGoToIcon: () => getArrowIcon(),
    intervalConfig: { ...membersIntervalConfig },
    fetchStatistic: async (params) => await getMembersStatisticsData(params, statisticsType.GROUP),
    getStatisticsComponent: (props) => <MemberStatistics isGroup {...props} />,
  },
  [statisticsType.USER]: {
    getAccordionLabel: (props) => <UserLabel {...props} />,
    translateId: 'MEMBERS_STATISTICS.GO_TO_USER',
    navigationData: ({ id }) => [`/home/users/subsidiary/`, { userIds: [id] }],
    hasRoleToNavigate: (profile) => UserAccess.getHasRoles(profile.profile).hasRoleToAccess,
    getGoToIcon: () => getArrowIcon(),
    intervalConfig: { ...membersIntervalConfig },
    fetchStatistic: async (params) => await getMembersStatisticsData(params, statisticsType.USER),
    getStatisticsComponent: (props) => <MemberStatistics {...props} />,
  },
};
