import React from 'react';
import PropTypes from 'prop-types';

const TableBody = React.forwardRef(({ className, children, sortable, style }, ref) => {
  return (
    <tbody style={style} ref={sortable ? ref : null} className={className}>
      {children}
    </tbody>
  );
});

TableBody.propTypes = {
  className: PropTypes.string,
  sortable: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
};

export default TableBody;
