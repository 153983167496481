import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { TypeSubTypeChooserComplexField } from 'components';

const defaultFetchCountryStates = (countries = []) => (inputCountryName) => {
  const selectedCountry = countries.find((country) => country?.country === inputCountryName);
  return selectedCountry && selectedCountry.states ? selectedCountry.states : [];
};

const CountryStateChooser = ({
  countries,
  countryKey = 'country',
  countryInputName,
  countryInputLabel,
  stateKey = 'state',
  stateInputName,
  stateInputLabel,
  countryOptionAsObject,
  stateOptionAsObject,
  hideSubtypeIfNoOptions = true,
  ...props
}) => {
  const loadSubtypeOptionsByTypeId = useCallback(defaultFetchCountryStates(countries), [countries]);

  const getCountryOptionLabel = useCallback((option) => option?.country, []);
  const getStateOptionLabel = useCallback((option) => option?.state, []);

  return (
    <TypeSubTypeChooserComplexField
      typeKey={countryKey}
      typeInputName={countryInputName}
      typeValueList={countries}
      typeLabel={countryInputLabel}
      typeOptionAsObject={countryOptionAsObject}
      getTypeOptionLabel={getCountryOptionLabel}
      subTypeKey={stateKey}
      subTypeLabel={stateInputLabel}
      subTypeInputName={stateInputName}
      subTypeOptionAsObject={stateOptionAsObject}
      getSubTypeOptionLabel={getStateOptionLabel}
      hideSubtypeIfNoOptions={hideSubtypeIfNoOptions}
      loadSubtypesByTypeKey={loadSubtypeOptionsByTypeId}
      {...props}
    />
  );
};

CountryStateChooser.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
  countryKey: PropTypes.string,
  countryInputName: PropTypes.string,
  countryInputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  stateKey: PropTypes.string,
  stateInputName: PropTypes.string,
  stateInputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  countryOptionAsObject: PropTypes.bool,
  stateOptionAsObject: PropTypes.bool,
  hasErrorComponent: PropTypes.bool,
  hideSubtypeIfNoOptions: PropTypes.bool,
};

export default CountryStateChooser;
