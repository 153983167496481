import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';
import { FormattedMessage } from 'react-intl';

export default class IndicatorListItem extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  render() {
    return (
      <div className={classNames('indicator-item', this.props.className)}>
        <div className={`value ${this.props.color}`}>{this.props.value}</div>
        <div className="label">
          <FormattedMessage id={this.props.label} />
        </div>
      </div>
    );
  }
}
