import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Settings from 'config/settings';
import { ConfirmModal } from 'components';
import { history, match } from 'common';
import TestTable from './TestsTable';
import TestForm from './TestForm';
import { templateLevel } from 'libs/template';
import { modalType } from 'util/modalType';
import { LessonAccess } from 'libs/accessManagement';
import TestTablePreHeader from './TestTablePreHeader';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';
import AppLoader from 'containers/App/AppLoader';
import {
  deleteLessonTest,
  editLessonTest,
  generateTests,
  getLessonTests,
  saveLessonTest,
} from 'services/lessons';

import './style.scss';

const defaultQuery = {
  sortBy: Settings.SORT_BY.TITLE,
  order: 'asc',
  levels: [templateLevel.BEGINNER.id],
};

const TestsTable = ({ profile, match, lesson, disabled }) => {
  const [selectedTest, setSelectedTest] = useState(null);
  const [modal, setModal] = useState(null);
  const testId = selectedTest?.id;

  const fetchTests = useCallback(
    async (params) => await getLessonTests({ ...params, lessonId: match.params.id }),
    [match.params.id]
  );

  const { data, changeParams, isLoading, params } = useFetchWithQueryParams({
    queryParamsConfig: defaultQuery,
    fetch: fetchTests,
  });

  const testList = data?.data || [];
  const count = data?.count || 0;

  const [selectedLevel, setSelectedLevel] = useState(
    Number.parseInt(params?.levels?.[0] || 0, 10) || templateLevel.BEGINNER.id
  );

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  const deleteTest = useCallback(async () => {
    if (testId) {
      await deleteLessonTest(match.params.id, testId);
    }
    setSelectedTest(null);
    changeParams({});
    closeModal();
  }, [match.params.id, testId, closeModal, changeParams]);

  const openModal = useCallback((modal, test) => {
    setModal(modal);
    setSelectedTest(test);
  }, []);

  const createTest = useCallback(
    async (values) => {
      const { id } = await saveLessonTest(match.params.id, values);
      setSelectedTest((prev) => ({ ...prev, id: id }));
      changeParams({});
      closeModal();
    },
    [match.params.id, closeModal, changeParams]
  );

  const editTest = async (values) => {
    await editLessonTest(match.params.id, values, values.id);
    changeParams({});
    closeModal();
  };

  const generateTestsFetch = async () => {
    await generateTests(lesson.id);
    changeParams({});
  };

  const {
    TestAccess: { canAdd },
  } = LessonAccess.getHasAccess(profile.profile, lesson);

  const templateLevelOptions = templateLevel
    ? Object.values(templateLevel).map(({ id, label }) => ({
        label: label,
        value: id,
      }))
    : [];

  return (
    <div className="test-table-container">
      <div>
        <AppLoader show={isLoading} />
        <div className="test-table-container__content">
          {canAdd && (
            <TestTablePreHeader
              selectedLevel={selectedLevel}
              templateLevelOptions={templateLevelOptions}
              setSelectedLevel={setSelectedLevel}
              lesson={lesson}
              disabled={disabled}
              generateTestsFetch={generateTestsFetch}
              openModal={openModal}
            />
          )}
          <div className="test-table-container__table">
            <TestTable
              lesson={lesson}
              list={testList}
              currentPage={params.page}
              count={count}
              loadPage={(page) => changeParams({ ...page })}
              params={params}
              profile={profile}
              openDeleteModal={(test) => openModal(modalType.DELETE, test)}
              openEditModal={(test) => openModal(modalType.EDIT, test)}
            />
          </div>
        </div>

        <TestForm
          open={modal === modalType.EDIT}
          edit={!!selectedTest}
          test={selectedTest}
          createTest={createTest}
          editTest={editTest}
          closeEditor={closeModal}
          disabled={disabled}
        />

        <ConfirmModal
          open={modal === modalType.DELETE}
          title={<FormattedMessage id="TESTS.DELETE_TEST" />}
          onClose={closeModal}
          saveAction={deleteTest}
          confirmElement={
            <FormattedMessage id="COMMON.DELETE_QUESTION" values={{ title: 'this question' }} />
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

TestsTable.propTypes = {
  profile: PropTypes.object,
  history,
  match,
  lesson: PropTypes.object,
  disabled: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps)(TestsTable));
