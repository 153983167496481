import React from 'react';
import PropTypes from 'prop-types';
import { DragOverlay } from '@dnd-kit/core';

import { TableFilter, PathTable } from 'components';
import RowOverlay from './RowOverlay';
import LessonSearchDropdown from 'containers/Lessons/LessonSearchDropdown/LessonSearchDropdown';
import { pathMarker, pathCourse, pathLesson } from 'common';

const PathEditorLessionList = ({
  activeId,
  isListItemDisabled,
  list,
  count,
  loadFilterData,
  searchType,
  changeSearchType,
  loadList,
  loadPage,
  page,
  itemsPerPage,
  recordKey,
  className,
}) => {
  return (
    <div className={className}>
      <div className="row">
        <div className="col-12 d-flex search-components">
          <div className="row">
            <div className="col-6">
              <TableFilter
                hasUnderline
                loadList={(searchText, page) => loadFilterData(searchText, page)}
              />
            </div>
            <div className="col-6">
              <LessonSearchDropdown searchType={searchType} onChange={changeSearchType} />
            </div>
          </div>
        </div>
        <PathTable
          list={(list || []).map((item) => ({
            ...item,
            isDragDisabled: isListItemDisabled(item),
          }))}
          loadList={loadList}
          count={count}
          loadPage={(page) => loadPage(page)}
          currentPage={page}
          itemsPerPage={itemsPerPage}
          recordKey={recordKey}
        />
        <DragOverlay dropAnimation={null}>
          {activeId ? (
            <RowOverlay overlayItem={list.find((item) => recordKey(item) === activeId)} />
          ) : null}
        </DragOverlay>
      </div>
    </div>
  );
};

PathEditorLessionList.propTypes = {
  pathItems: PropTypes.arrayOf(PropTypes.oneOfType([pathMarker, pathCourse, pathLesson])),
  setPathItems: PropTypes.func,
  itemIndex: PropTypes.number,
  activeId: PropTypes.number,
  isListItemDisabled: PropTypes.func,
  list: PropTypes.array,
  count: PropTypes.number,
  itemsPerPage: PropTypes.number,
  searchType: PropTypes.number,
  page: PropTypes.number,
  loadFilterData: PropTypes.func,
  changeSearchType: PropTypes.func,
  loadList: PropTypes.func,
  loadPage: PropTypes.func,
  recordKey: PropTypes.func,
  className: PropTypes.string,
};

export default PathEditorLessionList;
