import React from 'react';
import PropTypes from 'prop-types';

import { defaultDotProps } from '../chartUtil';

const getDot = ({
  cx,
  cy,
  fill = defaultDotProps.DEFAULT_FILL,
  fillOpacity,
  stroke,
  strokeOpacity,
  strokeWidth = defaultDotProps.DEFAULT_STROKE_WIDTH,
  radius = defaultDotProps.DEFAULT_RADIUS,
}) => {
  const radiusWithStroke = radius + strokeWidth;
  return (
    <svg
      x={cx - radiusWithStroke}
      y={cy - radiusWithStroke}
      height={2 * radiusWithStroke}
      width={2 * radiusWithStroke}>
      <circle
        cx={radiusWithStroke}
        cy={radiusWithStroke}
        style={{ fillOpacity, strokeOpacity }}
        r={radius}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

const AreaDot = ({ cx, cy, stroke: lineStroke, payload, getDotProps }) => {
  const { stroke, ...dotProps } = (getDotProps && getDotProps(payload)) || {};

  return (cx || cx === 0) && (cy || cy === 0)
    ? getDot({
        stroke: stroke || lineStroke,
        cx,
        cy,
        ...dotProps,
      })
    : null;
};

AreaDot.propTypes = {
  stroke: PropTypes.string,
  radius: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  payload: PropTypes.object,
  getDotProps: PropTypes.func,
};

export default AreaDot;
