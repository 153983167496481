import { horizontalAlignOptions } from 'components/Table/tableConstants';

const getTagListAsString = (lesson) =>
  lesson.tags ? lesson.tags.map(({ title }) => title).join(', ') : '';

const getDateAsString = (item) => {
  const date = new Date(item.updatedAt * 1000);
  return date.toLocaleDateString();
};

export const pathEditorSourceTableColumns = (formatMessage) => [
  {
    key: 'title',
    label: formatMessage({ id: 'COMMON.TITLE' }),
    horizontalAlign: horizontalAlignOptions.left,
  },
  {
    key: 'itemType',
    label: formatMessage({ id: 'COMMON.TYPE' }),
    horizontalAlign: horizontalAlignOptions.left,
  },
  {
    key: 'version',
    label: 'Version',
    horizontalAlign: horizontalAlignOptions.left,
  },
  {
    key: 'updatedAt',
    label: 'Updated at',
    horizontalAlign: horizontalAlignOptions.left,
    render: (item) => getDateAsString(item),
  },
  {
    key: 'tags',
    label: formatMessage({ id: 'LESSONS.LESSON_TAGS' }),
    render: (item) => getTagListAsString(item),
    itemTooltip: (item) => getTagListAsString(item),
    multiLine: true,
    horizontalAlign: horizontalAlignOptions.left,
  },
];
