import { roleTypes } from 'libs/roles';
import { MenuAccess } from 'libs/accessManagement';
import { MENUS } from 'util/menus';

import CompanySubsidiaryStatisticsPage from '../CompanySubsidiaryStatisticsPage/CompanySubsidiaryStatisticsPage';
import ContentStatisticsPage from '../ContentStatisticsPage/ContentStatisticsPage';
import MembersStatisticsPage from '../MembersStatisticsPage/MembersStatisticsPage';
import UsersPage from '../UsersPage/UsersPage';
import SubsidiariesPage from '../SubsidiariesPage/SubsidiariesPage';
import CompaniesPage from '../CompaniesPage/CompaniesPage';
import Lessons from '../Lessons/Lessons';
import LessonsForAudit from '../LessonsForAudit/LessonsForAudit';
import CoursesPage from '../Courses';
import Path from 'containers/Path/Path';

import Dashboard from '../Dashboard';
import AuditPage from '../Audit/AuditPage';
import GroupsPage from '../GroupsPage/GroupsPage';
import TemplatesPage from '../Templates/TemplatesPage';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsAndConditions from '../TermsAndConditions';

export const SYSTEM_USERS_PATH = `users/${roleTypes.SYSTEM}`;

export const getRoutes = ({ match, formatMessage }) => {
  return [
    {
      path: `${match.path}/${MENUS.DASHBOARD.match}`,
      component: Dashboard,
      linkName: formatMessage({ id: 'DASHBOARD.TITLE' }),
      match: MENUS.DASHBOARD.match,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.DASHBOARD.name),
      showFor: MenuAccess.getShowForRoles(MENUS.DASHBOARD.name),
    },
    {
      path: `${match.path}/${MENUS.STATISTICS.match}`,
      disableRedirect: true,
      linkName: formatMessage({ id: 'STATISTICS.TITLE' }),
      match: MENUS.STATISTICS.match,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.STATISTICS.name),
      showFor: MenuAccess.getShowForRoles(MENUS.STATISTICS.name),
    },
    {
      path: `${match.path}/${MENUS.COMPANY_SUBSIDIARY_STATISTICS.match}`,
      component: CompanySubsidiaryStatisticsPage,
      linkName: formatMessage({ id: 'COMPANY_SUBSIDIARY_STATISTICS.TITLE' }),
      match: MENUS.COMPANY_SUBSIDIARY_STATISTICS.match,
      childrenComponent: `/${MENUS.COMPANY_SUBSIDIARY_STATISTICS.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.COMPANY_SUBSIDIARY_STATISTICS.name),
      showFor: MenuAccess.getShowForRoles(MENUS.COMPANY_SUBSIDIARY_STATISTICS.name),
    },
    {
      path: `${match.path}/${MENUS.CONTENT_STATISTICS.match}`,
      component: ContentStatisticsPage,
      linkName: formatMessage({ id: 'CONTENT_STATISTICS.TITLE' }),
      match: MENUS.CONTENT_STATISTICS.match,
      childrenComponent: `/${MENUS.CONTENT_STATISTICS.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.CONTENT_STATISTICS.name),
      showFor: MenuAccess.getShowForRoles(MENUS.CONTENT_STATISTICS.name),
    },
    {
      path: `${match.path}/${MENUS.MEMBERS_STATISTICS.match}`,
      component: MembersStatisticsPage,
      linkName: formatMessage({ id: 'MEMBERS_STATISTICS.TITLE' }),
      match: MENUS.MEMBERS_STATISTICS.match,
      childrenComponent: `/${MENUS.MEMBERS_STATISTICS.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.MEMBERS_STATISTICS.name),
      showFor: MenuAccess.getShowForRoles(MENUS.MEMBERS_STATISTICS.name),
    },
    {
      path: `${match.path}/${MENUS.ACCESS_MANAGEMENT.match}`,
      linkName: formatMessage({ id: 'ACCESS_MANAGEMENT.TITLE' }),
      disableRedirect: true,
      match: MENUS.ACCESS_MANAGEMENT.match,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.ACCESS_MANAGEMENT.name),
      showFor: MenuAccess.getShowForRoles(MENUS.ACCESS_MANAGEMENT.name),
    },
    {
      path: `${match.path}/${MENUS.SLT_ADMINISTRATION.match}/${SYSTEM_USERS_PATH}`,
      component: UsersPage,
      linkName: formatMessage({ id: 'USERS.SLT_ADMINISTRATION' }),
      match: MENUS.SLT_ADMINISTRATION.match,
      childrenComponent: '/system',
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.SLT_ADMINISTRATION.name),
      showFor: MenuAccess.getShowForRoles(MENUS.SLT_ADMINISTRATION.name),
    },
    {
      path: `${match.path}/${MENUS.COMPANY.match}`,
      component: CompaniesPage,
      linkName: formatMessage({ id: 'COMPANY_GROUPS.TITLE' }),
      match: MENUS.COMPANY.match,
      childrenComponent: `/${MENUS.COMPANY.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.COMPANY.name),
      showFor: MenuAccess.getShowForRoles(MENUS.COMPANY.name),
    },
    {
      path: `${match.path}/${MENUS.SUBSIDIARY.match}`,
      component: SubsidiariesPage,
      linkName: formatMessage({ id: 'SUBSIDIARY.TITLE' }),
      match: MENUS.SUBSIDIARY.match,
      childrenComponent: `/${MENUS.SUBSIDIARY.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.SUBSIDIARY.name),
      showFor: MenuAccess.getShowForRoles(MENUS.SUBSIDIARY.name),
    },
    {
      path: `${match.path}/${MENUS.GROUP.match}`,
      component: GroupsPage,
      linkName: formatMessage({ id: 'GROUPS.TITLE' }),
      match: MENUS.GROUP.match,
      childrenComponent: `/${MENUS.GROUP.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.GROUP.name),
      showFor: MenuAccess.getShowForRoles(MENUS.GROUP.name),
    },
    {
      path: `${match.path}/${MENUS.USER.match}/${roleTypes.SUBSIDIARY}`,
      component: UsersPage,
      linkName: formatMessage({ id: 'USERS.TITLE' }),
      match: MENUS.USER.match,
      childrenComponent: `/${roleTypes.SUBSIDIARY}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.USER.name),
      showFor: MenuAccess.getShowForRoles(MENUS.USER.name),
    },
    {
      path: `${match.path}/${MENUS.CONTENT_MANAGEMENT.match}`,
      linkName: formatMessage({ id: 'CONTENT_MANAGEMENT.TITLE' }),
      disableRedirect: true,
      match: MENUS.CONTENT_MANAGEMENT.match,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.CONTENT_MANAGEMENT.name),
      showFor: MenuAccess.getShowForRoles(MENUS.CONTENT_MANAGEMENT.name),
    },
    {
      path: `${match.path}/${MENUS.TEMPLATE.match}`,
      component: TemplatesPage,
      linkName: formatMessage({ id: 'TEMPLATES.TITLE' }),
      match: MENUS.TEMPLATE.match,
      childrenComponent: `/${MENUS.TEMPLATE.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.TEMPLATE.name),
      showFor: MenuAccess.getShowForRoles(MENUS.TEMPLATE.name),
    },
    {
      path: `${match.path}/${MENUS.LESSON.match}`,
      component: Lessons,
      linkName: formatMessage({ id: 'LESSONS.TITLE' }),
      match: MENUS.LESSON.match,
      childrenComponent: `/${MENUS.LESSON.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.LESSON.name),
      showFor: MenuAccess.getShowForRoles(MENUS.LESSON.name),
    },
    {
      path: `${match.path}/${MENUS.COURSE.match}`,
      component: CoursesPage,
      linkName: formatMessage({ id: 'COURSES.TITLE' }),
      match: MENUS.COURSE.match,
      childrenComponent: `/${MENUS.COURSE.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.COURSE.name),
      showFor: MenuAccess.getShowForRoles(MENUS.COURSE.name),
    },
    {
      path: `${match.path}/${MENUS.PATH.match}`,
      component: Path,
      linkName: formatMessage({ id: 'PATH.TITLE' }),
      match: MENUS.PATH.match,
      childrenComponent: `/${MENUS.PATH.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.PATH.name),
      showFor: MenuAccess.getShowForRoles(MENUS.PATH.name),
    },
    // TODO: Uncomment if Jobs menu is needed
    // {
    //   path: `${match.path}/jobs`,
    //   url: `${match.url}/jobs?tab=jobs`,
    //   component: JobsPage,
    //   linkName: formatMessage({ id: "JOBS.TITLE" }),
    //   match: 'jobs',
    //   showFor: [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_MANAGER, Roles.SUBSIDIARY_ADMIN],
    // },
    // TODO Uncomment if need packages
    // {
    //   path: `${match.path}/packages`,
    //   url: `${match.url}/packages`,
    //   component: PackagesPage,
    //   linkName: formatMessage({ id: "PACKAGES.TITLE" }),
    //   match: 'packages',
    //   showFor: [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_ADMIN],
    // },
    {
      path: `${match.path}/${MENUS.AUDIT_PAGE.match}`,
      component: AuditPage,
      linkName: formatMessage({ id: 'LESSONS.AUDIT' }),
      match: MENUS.AUDIT_PAGE.match,
      childrenComponent: `${MENUS.AUDIT_PAGE.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.AUDIT_PAGE.name),
      showFor: MenuAccess.getShowForRoles(MENUS.AUDIT_PAGE.name),
    },
    {
      path: `${match.path}/${MENUS.FOR_AUDIT.match}`,
      component: LessonsForAudit,
      linkName: formatMessage({ id: 'LESSONS.FOR_AUDIT' }),
      match: MENUS.FOR_AUDIT.match,
      childrenComponent: `/${MENUS.FOR_AUDIT.match}`,
      defaultFor: MenuAccess.getDefaultForRoles(MENUS.FOR_AUDIT.name),
      showFor: MenuAccess.getShowForRoles(MENUS.FOR_AUDIT.name),
    },

    // TODO: Uncomment if Scoring-list menu is needed
    // {
    //   path: `${match.path}/scoring-list`,
    //   url: `${match.url}/scoring-list`,
    //   component: ScoringList,
    //   linkName: formatMessage({ id: "LESSONS.SCORING_LIST" }),
    //   match: 'scoring-list',
    //   showFor: [
    //     Roles.SYSTEM_ADMIN,
    //     Roles.SUBSIDIARY_MANAGER,
    //     Roles.SUBSIDIARY_ADMIN,
    //     Roles.CONTENT_VALIDATOR,
    //   ],
    // },
    {
      path: `${match.path}/${MENUS.PRIVACY_POLICY.match}`,
      component: PrivacyPolicy,
      linkName: formatMessage({ id: 'PRIVACY_POLICY.TITLE' }),
      match: MENUS.PRIVACY_POLICY.match,
    },
    {
      path: `${match.path}/${MENUS.TERMS_AND_CONDITIONS.match}`,
      component: TermsAndConditions,
      linkName: formatMessage({ id: 'TERMS_AND_CONDITIONS.TITLE' }),
      match: MENUS.TERMS_AND_CONDITIONS.match,
    },
  ];
};
