import { getChangedDate, YEARS, WEEKS, MONTHS } from 'util/timeUtil';

export const statisticsTimePeriods = {
  NO_PERIOD: 'NO_PERIOD',
  ONE_WEEK: 'ONE_WEEK',
  ONE_MONTH: 'ONE_MONTH',
  THREE_MONTHS: 'THREE_MONTHS',
  SIX_MONTHS: 'SIX_MONTHS',
  ONE_YEAR: 'ONE_YEAR',
  TWO_YEARS: 'TWO_YEARS',
  FIVE_YEARS: 'FIVE_YEARS',
};

export const statisticsTimePeriodsConstants = {
  [statisticsTimePeriods.NO_PERIOD]: {
    selectTranslateId: 'STATISTICS.NO_PERIOD',
  },
  [statisticsTimePeriods.ONE_WEEK]: {
    selectTranslateId: 'STATISTICS.ONE_WEEK',
    getChangeDate: (baseDate, increase = true) =>
      getChangedDate(baseDate, { [WEEKS]: 1 }, increase),
  },
  [statisticsTimePeriods.ONE_MONTH]: {
    selectTranslateId: 'STATISTICS.ONE_MONTH',
    getChangeDate: (baseDate, increase = true) =>
      getChangedDate(baseDate, { [MONTHS]: 1 }, increase),
  },
  [statisticsTimePeriods.THREE_MONTHS]: {
    selectTranslateId: 'STATISTICS.THREE_MONTHS',
    getChangeDate: (baseDate, increase = true) =>
      getChangedDate(baseDate, { [MONTHS]: 3 }, increase),
  },
  [statisticsTimePeriods.SIX_MONTHS]: {
    selectTranslateId: 'STATISTICS.SIX_MONTHS',
    getChangeDate: (baseDate, increase = true) =>
      getChangedDate(baseDate, { [MONTHS]: 6 }, increase),
  },
  [statisticsTimePeriods.ONE_YEAR]: {
    selectTranslateId: 'STATISTICS.ONE_YEAR',
    getChangeDate: (baseDate, increase = true) =>
      getChangedDate(baseDate, { [YEARS]: 1 }, increase),
  },
  [statisticsTimePeriods.TWO_YEARS]: {
    selectTranslateId: 'STATISTICS.TWO_YEARS',
    getChangeDate: (baseDate, increase = true) =>
      getChangedDate(baseDate, { [YEARS]: 2 }, increase),
  },
  [statisticsTimePeriods.FIVE_YEARS]: {
    selectTranslateId: 'STATISTICS.FIVE_YEARS',
    getChangeDate: (baseDate, increase = true) =>
      getChangedDate(baseDate, { [YEARS]: 5 }, increase),
  },
};

export const getDefaultStartDate = (interval) =>
  statisticsTimePeriodsConstants[interval].getChangeDate(new Date(), false);
export const getDefaultEndDate = () => new Date();
