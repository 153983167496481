import {
  lessonStatusPassedColor,
  lessonStatusPassedBackgroundColor,
  lessonStatusStartedColor,
  lessonStatusStartedBackgroundColor,
  lessonStatusNotStartedColor,
  lessonStatusNotStartedBackgroundColor,
} from '../styles/_colors.scss';

export const lessonsStatus = {
  NOT_STARTED: 0,
  STARTED: 1,
  PASSED: 2,
};

export const lessonsStatusConstants = {
  [lessonsStatus.NOT_STARTED]: {
    tagColor: lessonStatusNotStartedColor,
    tagBackgroundColor: lessonStatusNotStartedBackgroundColor,
    translateId: 'LESSON_STATUS.NOT_STARTED',
  },
  [lessonsStatus.STARTED]: {
    tagColor: lessonStatusStartedColor,
    tagBackgroundColor: lessonStatusStartedBackgroundColor,
    translateId: 'LESSON_STATUS.STARTED',
  },
  [lessonsStatus.PASSED]: {
    tagColor: lessonStatusPassedColor,
    tagBackgroundColor: lessonStatusPassedBackgroundColor,
    translateId: 'LESSON_STATUS.PASSED',
  },
};
