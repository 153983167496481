import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { ConfirmMessage, Modal } from 'components';

const UnsavedChangesModal = ({
  children,
  unsavedChanges,
  open,
  hideFooter,
  loading,
  confirm,
  shadowed,
  ...props
}) => {
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);

  const onClose = () => {
    if (unsavedChanges && unsavedChanges()) {
      setOpenUnsavedChangesModal(true);
    }
  };

  const handleConfirm = () => {
    if (confirm) {
      confirm();
    }
    setOpenUnsavedChangesModal(false);
  };

  const closeConfirmModal = () => {
    setOpenUnsavedChangesModal(false);
  };

  return (
    <>
      <Modal
        hideFooter={hideFooter}
        loading={loading}
        preventDismiss
        unsavedChanges={unsavedChanges}
        unsavedChangesAction={onClose}
        shadowed={shadowed}
        open={open}
        {...props}>
        {children}
      </Modal>
      <Modal
        className={classNames('shadowed-modal', { open: openUnsavedChangesModal })}
        shadowed
        title={<FormattedMessage id="COMMON.UNSAVED_CHANGES" />}
        open={openUnsavedChangesModal}
        large={false}
        preventDismiss
        saveButton
        saveTitle={<FormattedMessage id="COMMON.YES" />}
        closeTitle={<FormattedMessage id="COMMON.CANCEL" />}
        onClose={closeConfirmModal}
        saveAction={handleConfirm}
        onCloseAddModalClass>
        <ConfirmMessage>
          <FormattedMessage id="COMMON.ARE_YOU_SURE" />
        </ConfirmMessage>
      </Modal>
    </>
  );
};

UnsavedChangesModal.propTypes = {
  children: PropTypes.node.isRequired,
  unsavedChanges: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hideFooter: PropTypes.bool,
  loading: PropTypes.bool,
  confirm: PropTypes.func,
  shadowed: PropTypes.bool,
};

export default UnsavedChangesModal;
