import React from 'react';
import PropTypes from 'prop-types';

import { CaretIcon } from 'components';

import {
  caretSortAscIndicatorColor,
  caretSortDescIndicatorColor,
  caretSortActiveIndicatorColor,
} from '../../../styles/_colors.scss';

const CaretSortIndicator = ({ isActive, reverse }) => {
  return (
    <div className="caret-sort-indicator">
      <CaretIcon
        noWrapper
        orientation="up"
        color={isActive && !reverse ? caretSortActiveIndicatorColor : caretSortAscIndicatorColor}
      />
      <CaretIcon
        noWrapper
        orientation="down"
        color={isActive && reverse ? caretSortActiveIndicatorColor : caretSortDescIndicatorColor}
      />
    </div>
  );
};

CaretSortIndicator.propTypes = {
  isActive: PropTypes.bool,
  reverse: PropTypes.bool,
};

export default CaretSortIndicator;
