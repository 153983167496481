import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';

import {
  InlineError,
  UnsavedChangesModal,
  OnSubmitValidationError,
  OnServerError,
  OkModal,
} from 'components';
import { FormattedMessage } from 'react-intl';

const UpdateForm = ({
  children,
  modalTitle,
  initialValues,
  validateOnBlur,
  validationSchema,
  className,
  open,
  list,
  create,
  update,
  submitWithoutValidate,
  subsidiaryId,
  closeEditor,
  tabsInModal,
  checkAdditionalExistingRows,
  saveTitle,
  saveSecondaryTitle,
  saveButtonSecondary,
  saveButton,
  saveSecondaryValidate,
  shadowed,
  maxHeight,
  hideFooter,
  onSaved,
  additionalStatuses,
  ...props
}) => {
  const formikRef = useRef(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [secondarySaveAction, setSecondarySaveAction] = useState(false);

  const onSubmit = async (values) => {
    setError(null);
    const error = checkExistingRows(values);
    if (!error) {
      setIsLoading(true);
      try {
        if (initialValues.id) {
          if (update) {
            await update(values, subsidiaryId);
          }
        } else {
          if (create) {
            await create(values, subsidiaryId, secondarySaveAction);
          }
        }
        if (onSaved) {
          await onSaved();
        }
        closeEditor();
        setOpenSuccessModal(true);
      } finally {
        setIsLoading(false);
      }
    } else if (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  const checkExistingRows = (data) => {
    if (list) {
      let errors = {};
      for (const item of list) {
        if (item.name === data.name) {
          errors.name = <FormattedMessage id="GROUPS.ALREADY_EXISTS" />;
        }
        if (checkAdditionalExistingRows) {
          errors = { ...error, ...checkAdditionalExistingRows(item, data) };
        }
      }

      return errors.name ? errors : null;
    }
  };

  const closeModals = () => {
    setOpenSuccessModal(false);
  };

  const hasUnsavedChanges = () => {
    if (formikRef && formikRef.current) {
      return formikRef.current.dirty;
    }
  };

  const submitForm = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  const saveAction = () => {
    setSecondarySaveAction(false);
    submitForm();
  };

  const saveSecondaryAction = async () => {
    setSecondarySaveAction(true);
    if (saveSecondaryValidate) {
      submitForm();
    } else {
      await submitWithoutValidate(initialValues, subsidiaryId, true);
      closeEditor();
      setOpenSuccessModal(true);
    }
  };

  return (
    <>
      <UnsavedChangesModal
        hideFooter={hideFooter}
        loading={isLoading}
        className={classNames({ className })}
        title={modalTitle}
        large={true}
        saveAction={saveAction}
        saveSecondaryAction={saveSecondaryAction}
        preventDismiss={true}
        unsavedChanges={hasUnsavedChanges}
        saveTitle={saveTitle}
        saveSecondaryTitle={saveSecondaryTitle}
        saveButtonSecondary={saveButtonSecondary}
        saveButton={saveButton}
        confirm={closeEditor}
        onClose={closeEditor}
        open={open}
        shadowed={shadowed}
        tabsInModal={tabsInModal}
        maxHeight={maxHeight}>
        <OnServerError shadowed additionalStatuses={additionalStatuses} />
        <Formik
          initialValues={initialValues}
          validateOnBlur={validateOnBlur}
          validationSchema={validationSchema}
          innerRef={formikRef}
          onSubmit={onSubmit}
          enableReinitialize
          {...props}>
          {(formikProps) => (
            <>
              <OnSubmitValidationError shadowed />
              {children
                ? typeof children === 'function'
                  ? children(formikProps)
                  : children
                : null}
            </>
          )}
        </Formik>
        <InlineError show={!!error} message={error} />
      </UnsavedChangesModal>
      <OkModal
        shadowed={shadowed}
        open={openSuccessModal}
        saveAction={closeModals}
        onClose={closeModals}
      />
    </>
  );
};

UpdateForm.propTypes = {
  onSaved: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  submitWithoutValidate: PropTypes.func,
  modalTitle: PropTypes.node.isRequired,
  saveTitle: PropTypes.node,
  saveSecondaryTitle: PropTypes.node,
  saveSecondaryValidate: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  create: PropTypes.func,
  update: PropTypes.func,
  closeEditor: PropTypes.func.isRequired,
  subsidiaryId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  checkAdditionalExistingRows: PropTypes.func,
  list: PropTypes.array,
  validateOnBlur: PropTypes.bool,
  tabsInModal: PropTypes.bool,
  saveButtonSecondary: PropTypes.bool,
  saveButton: PropTypes.bool,
  shadowed: PropTypes.bool,
  maxHeight: PropTypes.bool,
  className: PropTypes.string,
  additionalStatuses: PropTypes.array,
  hideFooter: PropTypes.bool,
};

export default UpdateForm;
