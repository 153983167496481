import React from 'react';
import PropTypes from 'prop-types';

import { TableCellButton } from 'components';

const EnableDisableControls = ({
  item,
  showEnable,
  showDisable,
  itemDisabled,
  enableItem,
  enableLabel,
  disableItem,
  disableLabel,
}) => {
  const enabledContent = showEnable ? (
    <TableCellButton onClick={() => enableItem(item)}>{enableLabel}</TableCellButton>
  ) : null;

  const disabledContent = showDisable ? (
    <TableCellButton onClick={() => disableItem(item)}>{disableLabel}</TableCellButton>
  ) : null;

  return itemDisabled ? enabledContent : disabledContent;
};

EnableDisableControls.propTypes = {
  item: PropTypes.object.isRequired,
  itemDisabled: PropTypes.bool,
  showEnable: PropTypes.bool,
  enableLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  enableItem: PropTypes.func.isRequired,
  showDisable: PropTypes.bool,
  disableLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disableItem: PropTypes.func.isRequired,
};
export default EnableDisableControls;
