import React from 'react';
import PropTypes from 'prop-types';

import TransferListAction from './TransferListAction';

const TransferListHorizontalButtons = ({
  allToRight,
  toRight,
  toLeft,
  allToLeft,
  isAllToLeftDisabled,
  isAllToRightDisabled,
  isToRightDisabled,
  isToLeftDisabled,
}) => {
  return (
    <div className="TransferListsActions">
      <TransferListAction
        iconClass="fa fa-angle-double-right"
        onClick={allToRight}
        disabled={isAllToRightDisabled}
      />
      <TransferListAction
        iconClass="fa fa-angle-right"
        onClick={toRight}
        disabled={isToRightDisabled}
      />
      <TransferListAction
        iconClass="fa fa-angle-left"
        onClick={toLeft}
        disabled={isToLeftDisabled}
      />
      <TransferListAction
        iconClass="fa fa-angle-double-left"
        onClick={allToLeft}
        disabled={isAllToLeftDisabled}
      />
    </div>
  );
};

TransferListHorizontalButtons.propTypes = {
  allToRight: PropTypes.func,
  allToLeft: PropTypes.func,
  toRight: PropTypes.func,
  toLeft: PropTypes.func,
  isAllToRightDisabled: PropTypes.bool,
  isToRightDisabled: PropTypes.bool,
  isToLeftDisabled: PropTypes.bool,
  isAllToLeftDisabled: PropTypes.bool,
};

export default TransferListHorizontalButtons;
