import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';
import { Form } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { hideLoader, showLoader } from 'actions/common';
import { UpdateForm, AssignLesson, AssignGroupsAndUsers, TabsContainer } from 'components';

import CoursesBaseDataTab from './CoursesBaseDataTab';
import { getRolesByType } from 'actions/roles';
import { CourseAccess } from 'libs/accessManagement';
import { createShallowMergedObject } from 'util/objectUtil';
import {
  validationSchema,
  initialCourse,
  preloadSaveData,
  isAssignLessonDisabled,
  isBaseDataDisabled,
  isAssignGroupsAndUsersDisabled,
  canSubmitForm,
} from './courseFormUtil';

import { STATUS } from 'libs/http';

const CoursesForm = ({
  onSaved,
  profile,
  createCourse,
  editCourse,
  closeEditor,
  course,
  disabled,
  open,
  tabIndex,
  changeTabIndex,
  isLoading,
}) => {
  const initialValues = {
    ...createShallowMergedObject(initialCourse, course),
    subsidiary: course?.subsidiary || null,
    company: course?.subsidiary?.companies || null,
  };

  const getTabs = ({
    values,
    errors: { lessons: lessonsError, ...restErrors },
    touched,
    setFieldValue,
    isValid,
  }) => {
    const { hasRoleToAssignUserOrGroup, hasRoleToAssignLesson } = CourseAccess.getHasRoles(
      profile.profile
    );
    const assignLessonError = touched?.lessons ? lessonsError : null;

    return [
      {
        id: 'base-data',
        label: <FormattedMessage id="LESSONS.BASE_LESSON_DATA_TAB" />,
        component: (
          <CoursesBaseDataTab
            course={values}
            isCoursePublic={course.public}
            disabled={isBaseDataDisabled({ course, disabled, isEdit: !!course?.id, profile })}
            isLoading={isLoading}
            profile={profile}
          />
        ),
        hasError: !isValid && !isEmpty(restErrors),
      },
      {
        id: 'lessons',
        hide: !hasRoleToAssignLesson,
        label: <FormattedMessage id="LESSONS.TITLE" />,
        component: (
          <AssignLesson
            disabled={isAssignLessonDisabled({ course, disabled, isEdit: !!course?.id, profile })}
            fetchData={open}
            mainErrors={assignLessonError}
            isCoursePublic={values.public}
          />
        ),
        hasError: !isValid && lessonsError,
      },
      {
        id: 'assigns',
        hide: !hasRoleToAssignUserOrGroup,
        label: <FormattedMessage id="COURSES.ASSIGNS" />,
        component: (
          <AssignGroupsAndUsers
            values={values}
            disabled={isAssignGroupsAndUsersDisabled({ profile, disabled })}
            setFieldValue={setFieldValue}
          />
        ),
      },
    ].map((item) => ({ ...item, forceRender: true }));
  };

  const create = async (values) => {
    const preLoadedSaveData = preloadSaveData(values);
    await createCourse(preLoadedSaveData);
  };

  const update = async (values) => {
    const preLoadedSaveData = preloadSaveData(values);
    await editCourse(preLoadedSaveData);
  };

  return (
    <UpdateForm
      onSaved={onSaved}
      open={open}
      saveButton={canSubmitForm({ profile, isEdit: !!course?.id, course })}
      closeEditor={closeEditor}
      create={create}
      update={update}
      additionalStatuses={[STATUS.FORBIDDEN]}
      modalTitle={
        course.id ? (
          <FormattedMessage id="COURSES.EDIT_COURSE" />
        ) : (
          <FormattedMessage id="COURSES.ADD_COURSE" />
        )
      }
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={validationSchema}
      tabsInModal>
      {(formikProps) => {
        const tabList = getTabs(formikProps);

        return (
          <Form className="course-form">
            <TabsContainer
              tabList={tabList}
              selectedIndex={tabIndex}
              onSelect={(index) => changeTabIndex(index)}
            />
          </Form>
        );
      }}
    </UpdateForm>
  );
};

CoursesForm.propTypes = {
  onSaved: PropTypes.func,
  profile: PropTypes.object,
  createCourse: PropTypes.func.isRequired,
  editCourse: PropTypes.func.isRequired,
  closeEditor: PropTypes.func.isRequired,
  course: PropTypes.object,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  getRolesByType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  tabIndex: PropTypes.number,
  changeTabIndex: PropTypes.func,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
    isLoading: state.common.loader.show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
      getRolesByType,
    },
    dispatch
  );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(CoursesForm)));
