import React from 'react';
import { location, history, match } from 'common';
import qs from 'query-string';

/**
 * @param {Object} tabs Default filter options
 *
 * @returns {Function} - table component
 */
export default ({ tabs = [] } = {}) => (WrappedComponent) => {
  return class TabQueryParams extends React.Component {
    static propTypes = {
      location,
      history,
      match,
    };

    state = {
      tabIndex: this.getActiveTabIndex(),
    };

    /**
     * @returns {number} index of active tab
     * */
    getActiveTabIndex() {
      if (!tabs.length) {
        return 0;
      }
      const { tab } = qs.parse(this.props.location.search);
      return tab ? tabs.findIndex((data) => data.urlParam === tab) : 0;
    }

    /**
     * @param {number} tabIndex active tab
     * */
    setTab = (tabIndex) => {
      this.setState({ tabIndex });

      if (tabs.length > 0 && typeof tabIndex !== 'undefined') {
        this.props.history.replace({
          path: this.props.match.path,
          search: qs.stringify({
            ...qs.parse(this.props.location.search),
            tab: tabs[tabIndex].urlParam,
          }),
        });
      }
    };

    /**
     * @returns {JSX.Element} - Table component
     */
    render() {
      return (
        <WrappedComponent tabIndex={this.state.tabIndex} setTab={this.setTab} {...this.props} />
      );
    }
  };
};
