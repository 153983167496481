import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import './style.scss';

const getLabel = ({ isDragReject, isDragActive, formatMessage }) => {
  if (isDragReject) {
    return formatMessage({ id: 'DROPZONE.UNSUPPORTED_FILE_TYPE' });
  }
  if (isDragActive) {
    return formatMessage({ id: 'DROPZONE.DRAG_ACTIVE' });
  }
  return formatMessage({ id: 'DROPZONE.RESTING' });
};

const DropZone = ({
  onDrop,
  className,
  accept,
  id,
  onDropRejected,
  multiple,
  disabled,
  maxSize,
  hasError,
  intl: { formatMessage },
}) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, isFocused } = useDropzone({
    onDrop,
    accept,
    id,
    onDropRejected,
    multiple,
    disabled,
    maxSize,
  });
  return (
    <div
      className={classNames(
        'dropzone',
        {
          'dropzone--drag-active': isDragActive,
          'dropzone--drag-reject': isDragReject,
          'dropzone--active': isFocused,
          'dropzone--disabled': disabled,
          'dropzone--error': hasError,
        },
        className
      )}
      {...getRootProps()}>
      <input {...getInputProps()} />
      {getLabel({ isDragActive, isDragReject, formatMessage })}
    </div>
  );
};

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func,
  className: PropTypes.string,
  accept: PropTypes.object,
  multiple: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  hasError: PropTypes.bool,
};

export default injectIntl(DropZone);
