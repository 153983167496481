import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import {
  Roles,
  getCompaniesForRole,
  getSubsidiariesForRole,
  getRoleByName,
  ROLES_WITH_SUBSIDIARIES,
  ROLES_WITH_COMPANIES,
} from 'libs/roles';
import { Modal } from 'components';
import RoleParagraph from './RoleParagraph';

const getRole = (roleName, profile) => {
  const role = getRoleByName(roleName, profile);
  return role ? { roleName: role.displayName ? role.displayName : role.name } : null;
};

const getRoleWithItems = (roleName, profile, isRoleForSubsidiary = true) => {
  const role = getRole(roleName, profile);
  const itemsForRole = isRoleForSubsidiary
    ? getSubsidiariesForRole(roleName)(profile)
    : getCompaniesForRole(roleName)(profile);
  return role ? { ...role, ...(itemsForRole && { items: itemsForRole }), key: uuidv4() } : null;
};

const RolesModal = ({ open, closeModal, profile }) => {
  const system = getRole(Roles.SYSTEM_ADMIN, profile);
  const subsidiaryRolesWithItems = useMemo(
    () =>
      profile.roles
        .filter(({ name }) => ROLES_WITH_SUBSIDIARIES.includes(name))
        .map(({ name }) => getRoleWithItems(name, profile, true)),
    [profile]
  );
  const companyRolesWithItems = useMemo(
    () =>
      profile.roles
        .filter(({ name }) => ROLES_WITH_COMPANIES.includes(name))
        .map(({ name }) => getRoleWithItems(name, profile, false)),
    [profile]
  );
  const user = getRole(Roles.USER, profile);

  return (
    <Modal
      hideFooter
      title={<FormattedMessage id="USERS.PROFILE_ROLES" />}
      saveAction={closeModal}
      onClose={closeModal}
      open={open}>
      <div className="modal-body">
        {system && <RoleParagraph role={system} />}
        {companyRolesWithItems.map((role) => (
          <RoleParagraph role={role} key={role.key} />
        ))}

        {subsidiaryRolesWithItems.map((role) => (
          <RoleParagraph role={role} key={role.key} />
        ))}
        {user && <RoleParagraph role={user} />}
      </div>
    </Modal>
  );
};

RolesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

export default RolesModal;
