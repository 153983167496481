import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { ConfirmModal } from 'components';
import { modalType } from 'util/modalType';

const getFormatMessageWithEmail = ({ formatMessage, email }) => (id) =>
  formatMessage({ id }, { email });

const getModalProps = ({
  formatMessage,
  sendReminder,
  sendForgotPasswordEmail,
  disableUser,
  enableUser,
  email,
}) => {
  const formatMessageWithEmail = getFormatMessageWithEmail({ formatMessage, email });

  return {
    [modalType.REMINDER]: {
      title: formatMessage({ id: 'USERS.REMINDER' }),
      saveTitle: formatMessage({ id: 'DASHBOARD.SEND' }),
      saveAction: sendReminder,
      confirmElement: formatMessageWithEmail('USERS.REMINDER_QUESTION'),
    },
    [modalType.FORGOT_PASSWORD]: {
      title: formatMessage({ id: 'COMMON.FORGOT_PASSWORD' }),
      saveTitle: formatMessage({ id: 'DASHBOARD.SEND' }),
      saveAction: sendForgotPasswordEmail,
      confirmElement: formatMessageWithEmail('USERS.FORGOT_PASSWORD_QUESTION'),
    },
    [modalType.DISABLE]: {
      title: formatMessage({ id: 'USERS.DISABLE_USER' }),
      saveTitle: formatMessage({ id: 'COMMON.DISABLE' }),
      saveAction: disableUser,
      confirmElement: formatMessageWithEmail('USERS.DISABLE_USER_QUESTION'),
    },
    [modalType.ENABLE]: {
      title: formatMessage({ id: 'USERS.ENABLE_USER' }),
      saveTitle: formatMessage({ id: 'COMMON.ENABLE' }),
      saveAction: enableUser,
      confirmElement: formatMessageWithEmail('USERS.ENABLE_USER_QUESTION'),
    },
  };
};

const UserConfirmModal = ({
  modal,
  sendReminder,
  sendForgotPasswordEmail,
  disableUser,
  enableUser,
  closeModal,
  email,
  intl: { formatMessage },
}) => {
  const modalProps = getModalProps({
    formatMessage,
    sendReminder,
    sendForgotPasswordEmail,
    disableUser,
    enableUser,
    email,
  });

  const { title, saveTitle, saveAction, confirmElement } = modalProps[modal] || {};
  const open = !!title;

  return (
    <ConfirmModal
      open={open}
      title={title}
      saveTitle={saveTitle}
      closeTitle={formatMessage({ id: 'COMMON.CANCEL' })}
      preventDismiss
      onClose={closeModal}
      saveAction={saveAction}
      confirmElement={confirmElement}
    />
  );
};

UserConfirmModal.propTypes = {
  modal: PropTypes.oneOf([
    modalType.REMINDER,
    modalType.FORGOT_PASSWORD,
    modalType.DISABLE,
    modalType.ENABLE,
    null,
  ]),
  sendReminder: PropTypes.func.isRequired,
  sendForgotPasswordEmail: PropTypes.func.isRequired,
  disableUser: PropTypes.func.isRequired,
  enableUser: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(UserConfirmModal);
