import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withLoading } from 'hoc';
import { TableContainer, AddRow } from 'components';

const UserRoles = ({
  hasRoleToCreate,
  hasRoleToDelete,
  openEditModal,
  openDeleteModal,
  list,
  intl: { formatMessage },
}) => {
  return (
    <div className={'collapsed-table-container'}>
      {hasRoleToCreate ? (
        <AddRow
          onAdd={() => openEditModal()}
          label={formatMessage({ id: 'USERS.ADD_ROLE' })}
          className="mb-0"
        />
      ) : null}
      <TableContainer
        recordKey="roleId"
        columns={[
          { key: 'company', label: formatMessage({ id: 'COMPANIES.COMPANY' }) },
          { key: 'subsidiary', label: formatMessage({ id: 'SUBSIDIARY.TITLE' }) },
          { key: 'displayName', label: formatMessage({ id: 'USERS.ROLE' }) },
          { key: 'assignedAt', label: formatMessage({ id: 'USERS.ASSIGNED_ON' }) },
          { key: 'assignedBy', label: formatMessage({ id: 'USERS.ASSIGNED_BY' }) },
          {
            key: 'delete',
            hide: !hasRoleToDelete,
            iconCell: true,
            onClick: openDeleteModal,
            deleteIcon: true,
          },
        ]}
        list={list}
      />
    </div>
  );
};

UserRoles.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  hasRoleToCreate: PropTypes.bool,
  hasRoleToDelete: PropTypes.bool,
  openEditModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withLoading(injectIntl(UserRoles));
