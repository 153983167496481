import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { modalType } from 'util/modalType';
import { AddRow, BasicPage, ConfirmModal } from 'components';
import { isCollapsedEditModalOpen, isEditModalOpen } from './crudPageUtil';

const CrudPage = ({
  addRowLabel,
  hasRoleToCreate,
  children,
  initialData,
  onDelete,
  deleteItem,
  collapsedDelete,
  deleteTitle,
  deleteKey,
  hideAdd,
  addRow,
  ...props
}) => {
  const [data, setData] = useState(initialData);
  const [modal, setModal] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const addRowItem = hasRoleToCreate ? (
    <AddRow
      hideAdd={hideAdd}
      onAdd={() => (addRow ? addRow() : openEditModal())}
      label={addRowLabel}
      className="mb-0"
    />
  ) : null;

  const openModal = (modalType, data = initialData, collapsed = false) => {
    setModal(modalType);
    setData(data);
    setIsCollapsed(collapsed);
  };

  const closeModal = () => {
    setModal(null);
    setData(initialData || {});
  };

  const openEditModal = (data, collapsed) => {
    openModal(modalType.EDIT, data, collapsed);
  };

  const openDeleteModal = (data, collapsed) => {
    openModal(modalType.DELETE, data, collapsed);
  };

  const handleDeleteItem = async () => {
    try {
      if (isCollapsed && collapsedDelete) {
        collapsedDelete(data);
      } else {
        await deleteItem?.(data.id);
      }
      if (onDelete) {
        await onDelete();
      }
      setData({});
    } finally {
      closeModal();
    }
  };

  return (
    <BasicPage additionalPreHeaderIconContainerItem={addRowItem} {...props}>
      {({ setCurrentParams, loading }) => (
        <>
          {children
            ? typeof children === 'function'
              ? children({
                  data,
                  modal,
                  openModal,
                  closeModal,
                  openEditModal,
                  openDeleteModal,
                  isEditorOpen: isEditModalOpen({ modal, isCollapsed }),
                  isCollapsedEditorOpen: isCollapsedEditModalOpen({ modal, isCollapsed }),
                  setCurrentParams,
                  loading,
                })
              : children
            : null}
          <ConfirmModal
            open={modal === modalType.DELETE}
            onClose={closeModal}
            title={deleteTitle}
            saveAction={handleDeleteItem}
            confirmElement={
              <FormattedMessage
                id="COMMON.DELETE_QUESTION"
                values={{ title: data && data[deleteKey] }}
              />
            }
          />
        </>
      )}
    </BasicPage>
  );
};

CrudPage.propTypes = {
  hideAdd: PropTypes.bool,
  hasRoleToCreate: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.node.isRequired]),
  initialData: PropTypes.object,
  onDelete: PropTypes.func,
  deleteItem: PropTypes.func,
  collapsedDelete: PropTypes.func,
  deleteTitle: PropTypes.node,
  deleteKey: PropTypes.string,
  addRow: PropTypes.func,
  addRowLabel: PropTypes.node,
};

export default CrudPage;
