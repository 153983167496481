import React from 'react';
import PropTypes from 'prop-types';

import { DeleteControl, IconButton } from 'components';

import './style.scss';

const PathSectionMarker = ({ name, deleteItem, dndAttributes, dndListeners }) => {
  return (
    <div className="base-marker">
      {dndAttributes && dndListeners ? (
        <div>
          <IconButton {...dndAttributes} {...dndListeners} iconClass="fa fa-bars" />
        </div>
      ) : null}
      <div className="title-marker">{name}</div>
      <div className="delete-marker">
        <DeleteControl enabled show={!!deleteItem} deleteItem={deleteItem} />
      </div>
    </div>
  );
};

PathSectionMarker.propTypes = {
  name: PropTypes.string,
  deleteItem: PropTypes.func,
  dndAttributes: PropTypes.object,
  dndListeners: PropTypes.object,
};

export default PathSectionMarker;
