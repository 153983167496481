import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/image-or-video-preview-content.scss';

const VideoPreviewContent = ({ file, canStart, className, ...props }) => {
  const videoPlayerRef = useRef(null);

  const src = file.preview ? file.preview : file.url ? file.url : file.fullUrl;

  const toggleVideo = () => {
    if (videoPlayerRef.current) {
      if (videoPlayerRef.current.paused) {
        videoPlayerRef.current.play();
      } else {
        videoPlayerRef.current.pause();
      }
    }
  };

  const pauseVideo = useCallback(() => {
    if (videoPlayerRef.current && !videoPlayerRef.current.paused) {
      videoPlayerRef.current.pause();
    }
  }, []);

  useEffect(() => {
    return () => {
      pauseVideo();
    };
  }, [pauseVideo]);

  return (
    <video
      {...props}
      ref={videoPlayerRef}
      className={classNames('image-or-video-content', className)}
      onClick={canStart ? () => toggleVideo() : undefined}>
      <source src={src} />
      {file.name || file.filename}
    </video>
  );
};

VideoPreviewContent.propTypes = {
  file: PropTypes.object.isRequired,
  canStart: PropTypes.bool,
  controls: PropTypes.bool,
  className: PropTypes.string,
};

export default VideoPreviewContent;
