/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { LogoAnimation } from 'components';
import { FormattedMessage } from 'react-intl';

import './style.scss';

const LoginHeader = ({ className }) => {
  return (
    <div className={className}>
      <LogoAnimation className="login-header__animation" size="sm" />
      <h2 className="login-header__title">
        <FormattedMessage
          id="COMMON.WELCOME_ADMIN"
          values={{
            div: (chunks) => <div>{chunks}</div>,
            highlighted: (chunks) => <div className="login-header__highlighted">{chunks}</div>,
          }}
        />
      </h2>
    </div>
  );
};

LoginHeader.propTypes = { className: PropTypes.string };

export default LoginHeader;
