import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IconButton } from 'components';
import Settings from 'config/settings';

import './style.scss';

const TablePagination = ({
  loadList,
  itemsPerPage = Settings.ITEMS_PER_PAGE,
  count,
  currentPage,
  className,
  numberOfShownPageButtons = Settings.MAX_PAGES,
}) => {
  const [pages, setPages] = useState([]);
  const [smallestPage, setSmallestPage] = useState(0);
  const [largestPage, setLargestPage] = useState(0);

  const getAverage = useCallback(
    (currentPage) => Math.max(currentPage - Math.floor((numberOfShownPageButtons - 1) / 2), 1),
    [numberOfShownPageButtons]
  );

  const getSmallestPageNumberToShow = useCallback(
    (currentPage, numberOfPages) =>
      Math.min(getAverage(currentPage), numberOfPages - numberOfShownPageButtons + 1),
    [numberOfShownPageButtons, getAverage]
  );

  const getLargestPageNumberToShow = useCallback(
    (currentPage, numberOfPages) => {
      const smallestPage = getSmallestPageNumberToShow(currentPage, numberOfPages);
      return Math.min(numberOfPages, smallestPage + numberOfShownPageButtons - 1);
    },
    [getSmallestPageNumberToShow, numberOfShownPageButtons]
  );

  const getPages = useCallback(() => {
    const pagesCount = Math.ceil(count / itemsPerPage);
    const pagesArray = [];
    for (let pageIndex = 1; pageIndex <= pagesCount; pageIndex++) {
      pagesArray.push(pageIndex);
    }

    return pagesArray;
  }, [count, itemsPerPage]);

  const setPagesToShow = useCallback(() => {
    setSmallestPage(getSmallestPageNumberToShow(currentPage, getPages().length));
    setLargestPage(getLargestPageNumberToShow(currentPage, getPages().length));
  }, [currentPage, getLargestPageNumberToShow, getPages, getSmallestPageNumberToShow]);

  const disablePagination = (pages) => {
    return pages.length < 2;
  };

  const setActivePage = async (currentPage) => {
    await loadList({
      page: currentPage,
    });
    setSmallestPage(getSmallestPageNumberToShow(currentPage, getPages().length));
    setLargestPage(getLargestPageNumberToShow(currentPage, getPages().length));
  };

  useEffect(() => {
    setPages(getPages());
    setPagesToShow();
  }, [getPages, setPagesToShow]);

  const itemClass = 'basic-table-pagination__pagination-item';

  const disabledBackward = disablePagination(pages) || currentPage <= 1;
  const disabledForward = disablePagination(pages) || currentPage >= pages.length;

  return pages && pages.length ? (
    <div
      className={classNames(
        'basic-table-pagination',
        'mt-4 mb-4 d-flex justify-content-center',
        className
      )}>
      <div className={classNames('basic-table-pagination__pagination-container d-inline-flex')}>
        <IconButton
          iconClass="fa fa-step-backward"
          className={classNames(itemClass, {
            disabled: disabledBackward,
          })}
          disabled={disabledBackward}
          onClick={() => setActivePage(1)}
        />
        <IconButton
          iconClass="fa fa-chevron-left"
          className={classNames(itemClass, {
            disabled: disabledBackward,
          })}
          disabled={disabledBackward}
          onClick={() => setActivePage(currentPage - 1)}
        />
        {pages
          .filter((page) => page >= smallestPage && page <= largestPage)
          .map((data) => {
            return (
              <button
                key={data}
                type="button"
                disabled={disablePagination(pages)}
                onClick={() => setActivePage(data)}
                className={classNames(itemClass, {
                  'basic-table-pagination__pagination-item--active': currentPage === data,
                })}>
                {data}
              </button>
            );
          })}

        <IconButton
          iconClass="fa fa-chevron-right"
          className={classNames(itemClass, {
            disabled: disabledForward,
          })}
          disabled={disabledForward}
          onClick={() => setActivePage(currentPage + 1)}
        />
        <IconButton
          iconClass="fa fa-step-forward"
          className={classNames(itemClass, 'last-child', {
            disabled: disabledForward,
          })}
          disabled={disabledForward}
          onClick={() => setActivePage(pages.length)}
        />
      </div>
    </div>
  ) : null;
};

TablePagination.propTypes = {
  loadList: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number,
  count: PropTypes.number,
  currentPage: PropTypes.number,
  className: PropTypes.string,
  numberOfShownPageButtons: PropTypes.number,
};

export default TablePagination;
