import React, { Component } from 'react';
import { select, easeLinear } from 'd3';
import { scaleLinear } from 'd3-scale';
import { axisLeft } from 'd3-axis';
import ReactResizeDetector from 'react-resize-detector';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Settings from '../../config/settings';
import Tooltip from './Tooltip';

import './style.scss';

export default class BarChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      })
    ),
    filter: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.node = React.createRef();
  }

  state = {
    tooltip: {
      top: 0,
      left: 0,
      isVisible: false,
      value: 0,
    },
  };

  height = 0;
  width = 0;
  data = [];
  visibleBars = 7;
  maxValue = 1;

  componentDidMount() {
    this.data = this.props.data
      ? this.props.data.map((data) => {
          return { value: Math.round(data.value * 100) / 100, label: this.getXLabel(data.date) };
        })
      : [];
    this.maxValue = Math.max(...this.data.map((d) => d.value), 0);
    if (this.props.data && this.props.data.length) {
      this.draw();
    }
  }

  componentDidUpdate(prevProps) {
    this.data = this.props.data
      ? this.props.data.map((data) => {
          return { value: Math.round(data.value * 100) / 100, label: this.getXLabel(data.date) };
        })
      : [];
    this.maxValue = Math.max(...this.data.map((d) => d.value), 0);
    if (prevProps.data !== this.props.data) {
      this.draw();
    }
  }

  resize = () => {
    this.draw();
  };

  getXLabel = (date) => +date.split('-')[2] + '.' + +date.split('-')[1] + '.';

  getChartLabel = () => {
    if (!this.props.filter) return '';
    switch (this.props.filter) {
      case 1:
        return 'Users';
      case 2:
        return 'Minutes';
      case 3:
        return 'Failed exams';
      case 4:
        return 'Successful exams';
      default:
        return '';
    }
  };

  getColor = () => {
    if (!this.props.filter) return '#f3bc26';
    switch (this.props.filter) {
      case 1:
        return '#f3bc26';
      case 2:
        return '#e43778';
      case 3:
        return '#8a8a8a';
      case 4:
        return '#96c300';
      default:
        return '#f3bc26';
    }
  };

  static getTranslation(transform) {
    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    g.setAttributeNS(null, 'transform', transform);
    const matrix = g.transform.baseVal.consolidate().matrix;
    return [matrix.e, matrix.f];
  }

  onMouseOver = (event, d) => {
    const t = BarChart.getTranslation(select(event.currentTarget).attr('transform'));
    const scrollLeft = document.getElementsByClassName('bar-chart-container')[0].scrollLeft;
    this.setState({
      tooltip: {
        left: t[0] + 55 - scrollLeft,
        top: t[1] + 100 + this.scaleY(d.value),
        isVisible: true,
        value: d.value,
      },
    });
  };

  onMouseOut = () => {
    this.setState({ tooltip: { isVisible: false } });
  };

  scaleY = (value) => {
    return this.maxValue !== 0 ? this.height - (this.height * value) / this.maxValue : 0;
  };

  getTime = (value) => {
    const hours = Math.floor(value);
    const minutes = Math.round((value - Math.floor(value)) * 60);
    return hours + ':' + (minutes < 10 ? '0' + minutes : minutes);
  };

  draw = () => {
    if (!this.node.current) {
      return;
    }

    const parentWidth = this.node.current.clientWidth || 0;
    if (document.getElementsByClassName('bar-chart').length) {
      select('.bar-chart').remove();
    }
    this.selection = select(this.node?.current);

    const margin = {
      top: 50,
      right: 20,
      bottom: 30,
      left: 50,
    };

    this.width = parentWidth - margin.left - margin.right;
    const barWidth = 20;
    const barPadding = (this.width - barWidth * this.visibleBars) / this.visibleBars / 2;
    this.height = 300 - margin.top - margin.bottom;

    const svg = this.selection.append('svg').attr('class', 'bar-chart');

    const svgWidth =
      this.data.length === this.visibleBars
        ? this.width + margin.left + margin.right
        : barWidth * this.data.length +
          this.data.length * 2 * barPadding +
          barPadding +
          margin.left +
          margin.right;

    const y = scaleLinear().rangeRound([this.height, 0]);

    // outer-g group is for the chart label (year and month)
    svg
      .attr('width', svgWidth > 0 ? svgWidth : 0)
      .attr('height', this.height + margin.top + margin.bottom)
      .append('g')
      .attr('class', 'outer-g');

    // draw the chart label
    select('.outer-g')
      .append('text')
      .text(this.getChartLabel())
      .attr('transform', 'translate(10, 24)')
      .attr('class', 'chart-label')
      .style('fill', 'black');

    // axis-y data
    y.domain([0, this.maxValue]);

    // the main group
    const g = svg
      .append('g')
      .attr('class', 'chart-g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // axis-y
    g.append('g')
      .attr('class', 'axis axis--y')
      .call(
        axisLeft(y)
          .tickValues([0, this.maxValue])
          .tickFormat((value) => {
            return value === 0 ? this.bottomLabel : this.topLabel;
          })
      );

    // bars
    g.selectAll('.bar')
      .data(this.data)
      .enter()
      .append('rect')
      .on('mouseover', this.onMouseOver)
      .on('mouseout', this.onMouseOut)
      .style('fill', this.getColor())
      .style('stroke', 'none')
      .attr('class', 'bar')
      .attr('width', barWidth)
      .attr('y', this.height - 1)
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('transform', (d, i) => {
        return 'translate(' + [barWidth * i + i * 2 * barPadding + barPadding, 0] + ')';
      })
      .transition()
      .duration(Settings.ANIMATION_LENGTH)
      .ease(easeLinear)
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => this.height - y(d.value));

    g.selectAll('.bar-label')
      .data(this.data)
      .enter()
      .append('text')
      .attr('class', 'bar-label')
      .attr('width', barWidth)
      .attr('y', this.height - 1)
      .text((d) => d.label)
      .attr('transform', (d, i) => {
        return 'translate(' + [barWidth * i + i * 2 * barPadding + barPadding, 23] + ')';
      });

    // axis-x labels
    g.selectAll('.bar-label')
      .nodes()
      .forEach((node) => {
        const t = BarChart.getTranslation(select(node).attr('transform'));
        select(node).attr(
          'transform',
          () => 'translate(' + [t[0] + (barWidth - node.getComputedTextLength()) / 2, t[1]] + ')'
        );
      });
  };

  render() {
    return (
      <ReactResizeDetector
        handleWidth
        onResize={this.resize}
        render={() => (
          <FormattedMessage id="DASHBOARD.USER_MODAL_CHART_Y_TOP">
            {(topLabel) => {
              this.topLabel = topLabel;
              return (
                <FormattedMessage id="DASHBOARD.USER_MODAL_CHART_Y_BOTTOM">
                  {(bottomLabel) => {
                    this.bottomLabel = bottomLabel;
                    return (
                      <React.Fragment>
                        <div className="bar-chart-container" ref={this.node} />
                        <Tooltip
                          text={
                            Math.floor(this.state.tooltip.value) !== this.state.tooltip.value
                              ? this.getTime(this.state.tooltip.value)
                              : this.state.tooltip.value
                          }
                          top={this.state.tooltip.top}
                          left={this.state.tooltip.left}
                          isVisible={this.state.tooltip.isVisible}
                          color={this.getColor()}
                        />
                      </React.Fragment>
                    );
                  }}
                </FormattedMessage>
              );
            }}
          </FormattedMessage>
        )}
      />
    );
  }
}
