import * as RanksService from '../services/groups';

import * as actionTypes from 'actions/action-types';

//Currently unused
export const getAllGroups = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_ALL_GROUPS,
        loading: true,
      });

      const response = await RanksService.getAllGroups();

      dispatch({
        type: actionTypes.GET_ALL_GROUPS_SUCCESS,
        payload: response,
        loading: false,
      });

      return response;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ALL_GROUPS_FAILED,
        payload: error,
        loading: false,
      });
      throw error;
    }
  };
};

export const getGroups = (subsidiaryId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_GROUPS,
        loading: true,
      });

      const response = await RanksService.getGroups(subsidiaryId);

      dispatch({
        type: actionTypes.GET_GROUPS_SUCCESS,
        payload: response,
        loading: false,
      });

      return response;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_GROUPS_FAILED,
        payload: error,
        loading: false,
      });
      throw error;
    }
  };
};

export const createGroup = (data, subsidiaryId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.CREATE_GROUP,
        loading: true,
      });

      const response = await RanksService.createGroup({ group: data.name }, subsidiaryId);

      await dispatch({
        type: actionTypes.CREATE_GROUP_SUCCESS,
        payload: response,
        subsidiaryId,
        loading: false,
      });

      return response;
    } catch (error) {
      dispatch({
        type: actionTypes.CREATE_GROUP_FAILED,
        payload: error,
        loading: false,
      });
      throw error;
    }
  };
};

export const deleteGroup = (id, subsidiaryId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.DELETE_GROUP,
        loading: true,
      });

      await RanksService.deleteGroup(id);

      dispatch({
        type: actionTypes.DELETE_GROUP_SUCCESS,
        payload: id,
        subsidiaryId,
        loading: false,
      });

      return id;
    } catch (error) {
      dispatch({
        type: actionTypes.DELETE_GROUP_FAILED,
        payload: error,
        loading: false,
      });
      throw error;
    }
  };
};
