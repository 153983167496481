import qs from 'query-string';

import request from '../libs/request';
import { Methods } from '../libs/http';
import Settings from '../config/settings';
import { filterEmptyParam } from 'util/queryParam';

const BASE_URL = '/dashboard';

const SUBSIDIARYID_PARAM_NAME = 'subsidiaryId';
const filterEmptySubsidiaryId = filterEmptyParam(SUBSIDIARYID_PARAM_NAME);
export const getDashboardTopData = async (subsidiaryId) => {
  return await request({
    method: Methods.GET,
    resource: subsidiaryId
      ? `${BASE_URL}/top-data?${SUBSIDIARYID_PARAM_NAME}=${subsidiaryId}`
      : `${BASE_URL}/top-data`,
  });
};

export const getDashboardCoursesData = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/diagram-data?${qs.stringify(
      filterEmptySubsidiaryId(params),
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getDashboardUsersData = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/users-data?${qs.stringify(
      filterEmptySubsidiaryId(params),
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getDashboardUserData = async ({ userId, ...params }) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/users/${userId}?${qs.stringify(
      filterEmptySubsidiaryId(params),
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getRegisteredUsers = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/registered-users?${qs.stringify(
      filterEmptySubsidiaryId(params),
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const pokeUsers = async (data) => {
  return await request({ method: Methods.POST, resource: `/push/other-poke`, data });
};
