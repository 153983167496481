import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, Form, FieldArray } from 'formik';

import { createTemplate, editTemplate } from 'services/templates';
import { AddRow, TextField, UpdateForm } from 'components';
import SpecificsTable from './SpecificsTable';

import SpecificsModal from './SpecificsModal/SpecificsModal';
import { Yup } from 'util/Yup';

const TemplateEditor = ({ onSaved, template, open, disabled, closeEditor }) => {
  const [openSpecificModal, setOpenSpecificModal] = useState(false);
  const [editSpecificIndex, setEditSpecificIndex] = useState(-1);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    specifics: Yup.array(),
  });

  const create = useCallback(async (values) => {
    await createTemplate({
      ...values,
    });
  }, []);

  const update = useCallback(async (values) => {
    const { id, ...template } = values;
    await editTemplate(id, template);
  }, []);

  const toggleSpecificsModal = useCallback((index = -1) => {
    setOpenSpecificModal(true);
    setEditSpecificIndex(index);
  }, []);

  const closeSpecificsModal = useCallback(() => {
    setOpenSpecificModal(false);
    setEditSpecificIndex(-1);
  }, []);

  const removeTemplate = (index, arrayHelpers) => {
    arrayHelpers.remove(index);
  };

  return (
    <UpdateForm
      onSaved={onSaved}
      open={open}
      closeEditor={closeEditor}
      create={create}
      update={update}
      modalTitle={<FormattedMessage id="USERS.EDIT_USER" />}
      initialValues={template}
      validateOnBlur
      validationSchema={validationSchema}
      enableReinitialize>
      {({ values, setFieldValue }) => (
        <>
          <Form>
            <Field
              name="name"
              component={TextField}
              label={<FormattedMessage id="TEMPLATES.TEMPLATE_NAME_REQ" />}
            />
          </Form>
          <div className="row">
            <h5 className="col-6 mt-4">
              <FormattedMessage id="TEMPLATES.SPECIFICS" />
            </h5>

            <div className="col-6 mt-4 d-flex justify-content-end">
              <AddRow
                onAdd={toggleSpecificsModal}
                disabled={disabled}
                label={<FormattedMessage id="TEMPLATES.ADD_SPECIFIC" />}
              />
            </div>
          </div>

          <FieldArray
            name="specifics"
            render={(arrayHelpers) => (
              <>
                <SpecificsModal
                  open={openSpecificModal}
                  editSpecificIndex={editSpecificIndex}
                  closeSpecificsModal={closeSpecificsModal}
                  specifics={values.specifics}
                  disabled={disabled}
                  arrayHelpers={arrayHelpers}
                />
                <SpecificsTable
                  list={values.specifics}
                  openEditModal={toggleSpecificsModal}
                  openRemoveModal={(index) => removeTemplate(index, arrayHelpers)}
                  handleOrderChange={(specifics) => setFieldValue('specifics', specifics)}
                />
              </>
            )}
          />
        </>
      )}
    </UpdateForm>
  );
};

TemplateEditor.propTypes = {
  onSaved: PropTypes.func,
  template: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  closeEditor: PropTypes.func,
};

export default TemplateEditor;
