import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILED,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || [],
      };
    case GET_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
