export const getFileAsBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base65Data = reader.result.split('base64,')[1];
      resolve(base65Data);
    };
  });
};
