import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { InlineErrorMessage } from 'components';

const InlineError = ({ message, intl: { formatMessage }, ...props }) => {
  const error = message
    ? typeof message === 'object' && message.id
      ? formatMessage({ id: message.id }, message.values)
      : formatMessage({ id: message })
    : null;
  return <InlineErrorMessage message={error} {...props} />;
};

InlineError.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
    PropTypes.object,
  ]),
  show: PropTypes.bool,
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(InlineError);
