import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withLoading } from 'hoc';
import CompanySubsidiaryChartDetailContainer from '../CompanySubsidiaryChartDetailContainer/CompanySubsidiaryChartDetailContainer';
import { getChartDetailTraslateNameId } from 'util/chartRenderUtil';

import { secondaryChartConstants } from './chartConstants';
import '../style.scss';

const SecondaryChartDetail = ({ titleValue, chartDetail, intl: { formatMessage } }) => {
  const chartDetailIconElements = (secondaryChartConstants.chartDetail || []).map(
    ({ key, color }) => ({
      //TODO: Change user icon when icon is available
      firstIcon: <i className="fas fa-user-circle" style={{ color }} />,
      label: getChartDetailTraslateNameId(key)
        ? formatMessage({ id: getChartDetailTraslateNameId(key) })
        : key,
      value:
        chartDetail && (chartDetail[key] || chartDetail[key] === 0)
          ? chartDetail[key]
          : formatMessage({ id: 'COMMON.NO_DATA' }),
    })
  );

  return (
    <CompanySubsidiaryChartDetailContainer
      titleLabel={formatMessage({ id: 'COMPANY_SUBSIDIARY_STATISTICS.CURRENT_STATUS' })}
      details={chartDetailIconElements}
    />
  );
};

SecondaryChartDetail.propTypes = {
  titleValue: PropTypes.number,
  chartDetail: PropTypes.object,
};

export default withLoading(injectIntl(SecondaryChartDetail));
