export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const SET_PROFILE_IN_LIST = 'SET_PROFILE_IN_LIST';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILED = 'CREATE_ADMIN_FAILED';

export const SET_MANAGED_GROUP = 'SET_MANAGED_GROUP';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const GET_USERS_BY_ROLE = 'GET_USERS_BY_ROLE';
export const GET_USERS_BY_ROLE_SUCCESS = 'GET_USERS_BY_ROLE_SUCCESS';
export const GET_USERS_BY_ROLE_FAILED = 'GET_USERS_BY_ROLE_FAILED';
