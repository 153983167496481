import React from 'react';
import PropTypes from 'prop-types';

import { getUserIdentifier } from 'containers/UsersPage/User';
import { Popover } from 'components';

import './style.scss';

const AssignUserNameLabel = ({ firstName, lastName, email }) => {
  const content = getUserIdentifier({ firstName, lastName, email }, true);
  return (
    <div className="assign-user__label">
      <Popover content={content} onOverflow oneLine placement="top" trigger="hover">
        {content}
      </Popover>
    </div>
  );
};

AssignUserNameLabel.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
};

export default AssignUserNameLabel;
