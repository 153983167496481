import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ControlLabel from '../ControlLabel/ControlLabel';

import './style.scss';

const ControlLabelWrapper = forwardRef(
  (
    {
      id,
      className,
      controlLabelClass,
      childrenWrapperClass,
      disabled,
      children,
      label,
      hideLabel,
      hasInputValue,
      hasError,
      hideUnderLine,
      additions,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={classNames('control-label-wrapper', className, {
          'control-label--disabled': disabled,
          'control-label--has-value': hasInputValue,
          'control-label--no-label': hideLabel,
          hasError: hasError,
        })}>
        <div
          className={classNames(
            { 'control-label-wrapper__element--disabled': disabled },
            childrenWrapperClass
          )}>
          {children}
        </div>
        {!hideLabel && (
          <ControlLabel
            htmlFor={id}
            className={classNames('control-label-wrapper__control-label', controlLabelClass)}
            label={label}
          />
        )}
        {!hideUnderLine && (
          <span className="control-label-wrapper__underline-bar">
            <span
              className={classNames('control-label-wrapper__underline-line', {
                'control-label-wrapper__underline-line--hasError': hasError,
              })}
            />
          </span>
        )}
        {additions}
      </div>
    );
  }
);

ControlLabelWrapper.propTypes = {
  className: PropTypes.string,
  controlLabelClass: PropTypes.string,
  childrenWrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node,
  hideLabel: PropTypes.bool,
  hideUnderLine: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasInputValue: PropTypes.bool.isRequired,
  additions: PropTypes.node,
  hasError: PropTypes.bool,
};

export default ControlLabelWrapper;
