import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import DropZone from './DropZone/DropZone';
import DropzoneError from './FileUploadError/FileUploadError';
import FileUploadPreview from './FileUploadPreview/FileUploadPreview';
import FilePreviewContainer from 'components/FilePreviewContainer/FilePreviewContainer';
import { configureGetErrorMessage, getFileValues, getMaxSizeInMB } from './fileUploadUtil';
import InputError from '../InputError/InputError';
import { inputErrorPropTypes } from 'common';

import './style.scss';

const FileUpload = ({
  accept,
  disabled,
  disabledClick,
  maxSize = 10,
  multi,
  required,
  requiredMessage,
  intl: { formatMessage },
  file,
  hasErrorComponent,
  hasErrorMessage,
  hideValue,
  errorMessage,
  deleteSelection,
  onDrop: onDropProp,
  renderSelection,
  deletable,
}) => {
  const [browseErrorMessageList, setBrowseErrorMessageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id] = useState(() => uniqueId('file-upload-'));
  const getErrorMessage = configureGetErrorMessage({ maxSize, formatMessage });
  const maxSizeInMb = getMaxSizeInMB(maxSize);

  const renderPreview = useCallback(
    ({ files, deleteSelection }) => {
      const commonProps = {
        files,
        type: 'file',
        deleteSelection,
        disabledClick,
      };

      return <FilePreviewContainer {...commonProps} />;
    },
    [disabledClick]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        setLoading(true);
        setBrowseErrorMessageList([]);

        onDropProp(await getFileValues({ acceptedFiles, multi }));
      } finally {
        setLoading(false);
      }
    },
    [multi, onDropProp]
  );

  const onRejected = useCallback(
    (files) => {
      const errors = files
        .map((fileWithError) => getErrorMessage(fileWithError))
        .filter((item) => !!item);
      setBrowseErrorMessageList(errors);
    },
    [getErrorMessage]
  );

  return (
    <div className={classNames('file-upload')}>
      <div className={classNames('file-upload__container')}>
        <DropZone
          className={classNames({
            'file-upload__dropzone--readonly': disabledClick,
          })}
          onDrop={onDrop}
          disabled={disabled}
          id={id}
          onDropRejected={onRejected}
          accept={accept}
          multiple={multi}
          maxSize={maxSizeInMb}
          hasError={hasErrorMessage}
        />
        <FileUploadPreview
          className={classNames({
            'file-upload__file-upload-preview--readonly': disabledClick,
          })}
          requiredMessage={requiredMessage}
          required={required}
          loading={loading}
          file={file || []}
          renderSelection={renderSelection || renderPreview}
          deleteSelection={deleteSelection}
          disabled={disabled}
          deletable={deletable}
          disabledClick={disabledClick}
        />
      </div>

      <DropzoneError
        className="mt-2"
        show={!!browseErrorMessageList.length}
        message={browseErrorMessageList.join(', ')}
        withoutTranslation
      />
      <InputError
        hasErrorComponent={hasErrorComponent}
        hasErrorMessage={hasErrorMessage}
        hideValue={hideValue}
        errorMessage={errorMessage}
        renderErrorComponent={({ show, errorMessage }) => (
          <DropzoneError className="mt-2" show={show} message={errorMessage} />
        )}
      />
    </div>
  );
};

FileUpload.propTypes = {
  accept: PropTypes.object,
  requiredMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
  disabledClick: PropTypes.bool,
  required: PropTypes.bool,
  multi: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  intl: PropTypes.object.isRequired,
  renderSelection: PropTypes.func,
  deleteSelection: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  deletable: PropTypes.bool,
  ...inputErrorPropTypes,
};

export default injectIntl(FileUpload);
