import { Roles, hasRole } from 'libs/roles';
import { MENUS } from 'util/menus';

const MENU_NAMES = Object.entries(MENUS).reduce(
  (acc, [key, val]) => ({ ...acc, ...{ [key]: val.name } }),
  {}
);

export const isProfileUserId = (profile, userId) => {
  return profile.id === userId;
};

export const isProfileEmail = (profile, email) => {
  return profile.email === email;
};

const companyAccessRoles = [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN, Roles.SUBSIDIARY_ADMIN];

const subsidiaryAccessRoles = [
  Roles.SYSTEM_ADMIN,
  Roles.COMPANY_ADMIN,
  Roles.SUBSIDIARY_ADMIN,
  Roles.SUBSIDIARY_MANAGER,
];

const courseAccessRoles = [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_MANAGER, Roles.CONTENT_MANAGER];

const lessonAccessRoles = [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_MANAGER, Roles.CONTENT_MANAGER];

const assingLessonRoles = [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER, Roles.SUBSIDIARY_MANAGER];

const groupAccessRoles = [
  Roles.SYSTEM_ADMIN,
  Roles.COMPANY_ADMIN,
  Roles.SUBSIDIARY_ADMIN,
  Roles.SUBSIDIARY_MANAGER,
];

const userAccessRoles = [
  Roles.SYSTEM_ADMIN,
  Roles.COMPANY_ADMIN,
  Roles.SUBSIDIARY_ADMIN,
  Roles.SUBSIDIARY_MANAGER,
];

export const UserAccess = {
  getHasRoles: (profile) => ({
    hasRoleToAccess: !!hasRole(profile, userAccessRoles),
    hasRoleToCreate: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.COMPANY_ADMIN,
      Roles.SUBSIDIARY_ADMIN,
    ]),
    hasRoleToEdit: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.COMPANY_ADMIN,
      Roles.SUBSIDIARY_ADMIN,
    ]),
    hasRoleToDelete: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.COMPANY_ADMIN,
      Roles.SUBSIDIARY_ADMIN,
    ]),
    hasRoleToDisable: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.COMPANY_ADMIN,
      Roles.SUBSIDIARY_ADMIN,
    ]),
    RoleAccess: {
      hasRoleToRead: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToAdd: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToDelete: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
    },
  }),
};

export const SubsidiaryAccess = {
  getHasRoles: (profile) => ({
    hasRoleToAccess: !!hasRole(profile, subsidiaryAccessRoles),
    hasRoleToRead: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.COMPANY_ADMIN,
      Roles.SUBSIDIARY_ADMIN,
      Roles.SUBSIDIARY_MANAGER,
    ]),
    hasRoleToCreate: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
    hasRoleToEdit: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.COMPANY_ADMIN,
      Roles.SUBSIDIARY_ADMIN,
    ]),
    hasRoleToDelete: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
    hasRoleToDisable: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
    AdminAccess: {
      hasRoleToRead: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
      hasRoleToAdd: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
      hasRoleToEdit: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
      hasRoleToDelete: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
    },
    ContactAccess: {
      hasRoleToRead: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToAdd: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
      hasRoleToEdit: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
      hasRoleToDelete: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
    },
    GroupAccess: {
      hasRoleToAccess: !!hasRole(profile, groupAccessRoles),
      hasRoleToRead: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToCreate: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToEdit: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToAssignCourse: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToAssignLesson: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToAssignUser: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
      hasRoleToDelete: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
      ]),
    },
    SubscriptionAccess: {
      hasRoleToRead: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
      hasRoleToEdit: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
    },
  }),
};

export const CompanyAccess = {
  getHasRoles: (profile) => ({
    hasRoleToAccess: !!hasRole(profile, companyAccessRoles),
    hasRoleToRead: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.COMPANY_ADMIN,
      Roles.SUBSIDIARY_ADMIN,
    ]),
    hasRoleToCreate: !!hasRole(profile, [Roles.SYSTEM_ADMIN]),
    hasRoleToDelete: !!hasRole(profile, [Roles.SYSTEM_ADMIN]),
    hasRoleToDisable: !!hasRole(profile, [Roles.SYSTEM_ADMIN]),
    hasRoleToEdit: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),

    AdminAccess: {
      hasRoleToRead: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
      hasRoleToAdd: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
      hasRoleToEdit: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
      hasRoleToDelete: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
    },
    ContactAccess: {
      hasRoleToRead: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
      ]),
      hasRoleToAdd: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
      hasRoleToEdit: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
      hasRoleToDelete: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN]),
    },
  }),
};

export const LessonAccess = {
  getHasRoles: (profile) => ({
    hasRoleToAccess: !!hasRole(profile, lessonAccessRoles),
    hasRoleToCreate: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
    hasRoleToDelete: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
    hasRoleToEdit: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
    hasRoleToAssignUserOrGroup: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_MANAGER]),
    TestAccess: {
      hasRoleToRead: !!hasRole(profile, [
        Roles.SYSTEM_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
        Roles.CONTENT_MANAGER,
      ]),
      hasRoleToAdd: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
      hasRoleToEdit: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
      hasRoleToDelete: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
    },
  }),
  getHasAccess: (profile, lesson) => {
    return {
      canEdit: LessonAccess.getHasRoles(profile).hasRoleToEdit && lesson && lesson.canAccess,
      canDelete: LessonAccess.getHasRoles(profile).hasRoleToDelete && lesson && lesson.canBeDeleted,
      TestAccess: {
        canAdd: LessonAccess.getHasRoles(profile).TestAccess.hasRoleToAdd && lesson.canAccess,
        canEdit: LessonAccess.getHasRoles(profile).TestAccess.hasRoleToEdit && lesson.canAccess,
        canDelete: LessonAccess.getHasRoles(profile).TestAccess.hasRoleToDelete && lesson.canAccess,
      },
    };
  },
};

export const CourseAccess = {
  getHasRoles: (profile) => ({
    hasRoleToAccess: !!hasRole(profile, courseAccessRoles),
    hasRoleToCreate:
      !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER, Roles.SUBSIDIARY_MANAGER]) &&
      !!hasRole(profile, assingLessonRoles),
    hasRoleToDelete: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.CONTENT_MANAGER,
      Roles.SUBSIDIARY_MANAGER,
    ]),
    hasRoleToEdit: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.CONTENT_MANAGER,
      Roles.SUBSIDIARY_MANAGER,
    ]),
    hasRoleToAssignUserOrGroup: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_MANAGER]),
    hasRoleToAssignLesson: !!hasRole(profile, assingLessonRoles),
    hasRoleToUnAssignLesson: !!hasRole(profile, assingLessonRoles),
  }),

  getHasAccess: (profile, course) => ({
    canView: CourseAccess.getHasRoles(profile).hasRoleToAccess && course && course.canView,
    canEdit: CourseAccess.getHasRoles(profile).hasRoleToEdit && course && course.canEdit,
    canDelete: CourseAccess.getHasRoles(profile).hasRoleToDelete && course && course.canEdit,
    canAssignLesson:
      CourseAccess.getHasRoles(profile).hasRoleToAssignLesson &&
      CourseAccess.getHasRoles(profile).hasRoleToUnAssignLesson &&
      course &&
      course.canEdit,
  }),
};

const getParentMenuAccess = (childMenuArray = []) => [
  ...new Set(
    childMenuArray.reduce(
      (acc, childMenuName) => [...acc, ...MenuAccess.getShowForRoles(childMenuName)],
      []
    )
  ),
];
export const TemplateAccess = {
  getHasRoles: (profile) => ({
    hasRoleToRead: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
    hasRoleToCreate: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
    hasRoleToDelete: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
    hasRoleToEdit: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]),
  }),

  getHasAccess: (profile, template) => ({
    canEdit:
      !!hasRole(profile, [Roles.SYSTEM_ADMIN]) ||
      (!!hasRole(profile, [Roles.CONTENT_MANAGER]) && !template.isGlobal),
    canDelete:
      !!hasRole(profile, [Roles.SYSTEM_ADMIN]) ||
      (!!hasRole(profile, [Roles.CONTENT_MANAGER]) && !template.isGlobal),
  }),
};

export const PathAccess = {
  getHasRoles: (profile) => ({
    hasRoleToAccess: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.SUBSIDIARY_MANAGER,
      Roles.CONTENT_MANAGER,
    ]),
    hasRoleToCreate: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.SUBSIDIARY_MANAGER,
      Roles.CONTENT_MANAGER,
    ]),
    hasRoleToDelete: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.SUBSIDIARY_MANAGER,
      Roles.CONTENT_MANAGER,
    ]),
    hasRoleToEdit: !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.SUBSIDIARY_MANAGER,
      Roles.CONTENT_MANAGER,
    ]),
    hasRoleToAssignUserOrGroup: !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_MANAGER]),
  }),

  getHasAccess: (profile, path) => ({
    canEdit: PathAccess.getHasRoles(profile).hasRoleToEdit && path && path.canAccess,
    canDelete: PathAccess.getHasRoles(profile).hasRoleToDelete && path && path.canBeDeleted,
  }),
};

export const MenuAccess = {
  getShowForRoles: (menuName) => {
    switch (menuName) {
      case MENU_NAMES.DASHBOARD:
        return [
          Roles.SYSTEM_ADMIN,
          Roles.COMPANY_ADMIN,
          Roles.SUBSIDIARY_ADMIN,
          Roles.SUBSIDIARY_MANAGER,
        ];
      case MENU_NAMES.STATISTICS:
        return getParentMenuAccess([
          MENU_NAMES.COMPANY_SUBSIDIARY_STATISTICS,
          MENU_NAMES.CONTENT_STATISTICS,
          MENU_NAMES.MEMBERS_STATISTICS,
        ]);

      case MENU_NAMES.COMPANY_SUBSIDIARY_STATISTICS:
        return [
          Roles.SYSTEM_ADMIN,
          Roles.COMPANY_ADMIN,
          Roles.SUBSIDIARY_ADMIN,
          Roles.SUBSIDIARY_MANAGER,
        ];
      case MENU_NAMES.CONTENT_STATISTICS:
        return [
          Roles.SYSTEM_ADMIN,
          Roles.COMPANY_ADMIN,
          Roles.SUBSIDIARY_ADMIN,
          Roles.SUBSIDIARY_MANAGER,
          Roles.CONTENT_MANAGER,
        ];
      case MENU_NAMES.MEMBERS_STATISTICS:
        return [
          Roles.SYSTEM_ADMIN,
          Roles.COMPANY_ADMIN,
          Roles.SUBSIDIARY_ADMIN,
          Roles.SUBSIDIARY_MANAGER,
        ];
      case MENU_NAMES.ACCESS_MANAGEMENT:
        return getParentMenuAccess([
          MENU_NAMES.SLT_ADMINISTRATION,
          MENU_NAMES.COMPANY,
          MENU_NAMES.SUBSIDIARY,
          MENU_NAMES.GROUP,
          MENU_NAMES.USER,
        ]);
      case MENU_NAMES.SLT_ADMINISTRATION:
        return [Roles.SYSTEM_ADMIN];
      case MENU_NAMES.COMPANY:
        return companyAccessRoles;
      case MENU_NAMES.SUBSIDIARY:
        return subsidiaryAccessRoles;
      case MENU_NAMES.GROUP:
        return groupAccessRoles;
      case MENU_NAMES.USER:
        return userAccessRoles;
      case MENU_NAMES.CONTENT_MANAGEMENT:
        return getParentMenuAccess([
          MENU_NAMES.TEMPLATE,
          MENU_NAMES.LESSON,
          MENU_NAMES.COURSE,
          MENU_NAMES.AUDIT_PAGE,
          MENU_NAMES.FOR_AUDIT,
        ]);
      case MENU_NAMES.TEMPLATE:
        return [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER];
      case MENU_NAMES.LESSON:
        return lessonAccessRoles;
      case MENU_NAMES.COURSE:
        return courseAccessRoles;
      case MENU_NAMES.AUDIT_PAGE:
        return [Roles.SYSTEM_ADMIN, Roles.CONTENT_VALIDATOR];
      case MENU_NAMES.FOR_AUDIT:
        return [Roles.SYSTEM_ADMIN, Roles.CONTENT_VALIDATOR];
      case MENU_NAMES.PATH:
        return [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_MANAGER, Roles.CONTENT_MANAGER];
      default:
        return [Roles.SYSTEM_ADMIN];
    }
  },
  getDefaultForRoles: (menuName) => {
    switch (menuName) {
      case MENU_NAMES.DASHBOARD:
        return [
          Roles.SYSTEM_ADMIN,
          Roles.COMPANY_ADMIN,
          Roles.SUBSIDIARY_ADMIN,
          Roles.SUBSIDIARY_MANAGER,
        ];
      case MENU_NAMES.STATISTICS:
        return [];
      case MENU_NAMES.COMPANY_SUBSIDIARY_STATISTICS:
        return [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN, Roles.SUBSIDIARY_ADMIN];
      case MENU_NAMES.CONTENT_STATISTICS:
        return [Roles.CONTENT_MANAGER];
      case MENU_NAMES.MEMBERS_STATISTICS:
        return [Roles.SUBSIDIARY_MANAGER];
      case MENU_NAMES.ACCESS_MANAGEMENT:
        return [];
      case MENU_NAMES.SLT_ADMINISTRATION:
        return [];
      case MENU_NAMES.COMPANY:
        return [];
      case MENU_NAMES.SUBSIDIARY:
        return [];
      case MENU_NAMES.GROUP:
        return [];
      case MENU_NAMES.USER:
        return [];
      case MENU_NAMES.CONTENT_MANAGEMENT:
        return [];
      case MENU_NAMES.TEMPLATE:
        return [];
      case MENU_NAMES.LESSON:
        return [Roles.CONTENT_MANAGER];
      case MENU_NAMES.COURSE:
        return [];
      case MENU_NAMES.AUDIT_PAGE:
        return [Roles.CONTENT_VALIDATOR];
      case MENU_NAMES.FOR_AUDIT:
        return [];
      case MENU_NAMES.PATH:
        return [];
      default:
        return [];
    }
  },
};
