import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import { TableContainer, EditOrReadControl, DeleteControl, CheckBox } from 'components';

import { widthOptions, horizontalAlignOptions } from 'components/Table/tableConstants';
import { LessonAccess } from 'libs/accessManagement';
import { canModifySomethingInLesson } from './lessonEditorUtil';
import { templateLevel } from 'libs/template';
import Settings from 'config/settings';

const LessonsTable = ({
  loading,
  list,
  profile,
  loadList,
  params,
  openDeleteModal,
  editLesson,
  openAssignModal,
  openHistoryModal,
  intl: { formatMessage },
}) => {
  const {
    hasRoleToDelete,
    hasRoleToEdit,
    hasRoleToAssignUserOrGroup,
    hasRoleToAssignTest,
  } = LessonAccess.getHasRoles(profile.profile);

  const getTagListAsString = (lesson) =>
    lesson.tags ? lesson.tags.map(({ title }) => title).join(', ') : '';

  const renderEditControl = (lesson) => (
    <EditOrReadControl
      canEdit={canModifySomethingInLesson(profile, lesson)}
      isEnabled={!lesson.isDisabled}
      openEditModal={() => editLesson(lesson)}
    />
  );

  const renderTemplateLevelCheckBox = (lesson, index) => {
    const data = lesson.testLevels[index];
    return <CheckBox checked={!!data} disabled />;
  };

  const renderDeleteControl = (lesson) => (
    <DeleteControl
      show
      enabled={LessonAccess.getHasAccess(profile.profile, lesson).canDelete}
      item={lesson}
      deleteItem={() => openDeleteModal(lesson)}
      disabledHoverText={formatMessage({ id: 'LESSONS.DELETE_DISABLED' })}
    />
  );

  return (
    <TableContainer
      loading={loading}
      columns={[
        {
          key: 'edit_read',
          hide: !(hasRoleToEdit || hasRoleToAssignUserOrGroup || hasRoleToAssignTest),
          render: renderEditControl,
          width: widthOptions.xs,
        },
        {
          key: 'title',
          label: formatMessage({ id: 'COMMON.TITLE' }),
          sortBy: Settings.SORT_BY.TITLE,
        },
        {
          key: 'owner',
          label: formatMessage({ id: 'LESSONS.SUBSIDIARY' }),
          sortBy: Settings.SORT_BY.SUBSIDIARY,
        },
        {
          key: 'type',
          label: formatMessage({ id: 'LESSONS.LESSON_TYPE' }),
          render: (lesson) => (lesson.lessonType ? lesson.lessonType : ''),
          horizontalAlign: horizontalAlignOptions.left,
          sortBy: Settings.SORT_BY.LESSON_TYPE,
          width: widthOptions.sm,
        },
        {
          key: 'tags',
          label: formatMessage({ id: 'LESSONS.LESSON_TAGS' }),
          width: widthOptions.lg,
          horizontalAlign: horizontalAlignOptions.left,
          render: (lesson) => getTagListAsString(lesson),
          itemTooltip: (lesson) => getTagListAsString(lesson),
          multiLine: true,
        },
        {
          key: 'public_private',
          width: widthOptions.sm,
          label: formatMessage({ id: 'COMMON.PUBLIC_PRIVATE' }),
          horizontalAlign: horizontalAlignOptions.left,
          render: (lesson) =>
            lesson.isPublic
              ? formatMessage({ id: 'COMMON.PUBLIC' })
              : formatMessage({ id: 'COMMON.PRIVATE' }),
          sortBy: Settings.SORT_BY.PUBLICITY,
        },
        {
          key: 'assign',
          label: formatMessage({ id: 'LESSONS.ASSIGN' }),
          iconCell: true,
          hide: !hasRoleToAssignUserOrGroup,
          iconClass: 'lemon icon-info dark-grey',
          onClick: (lesson) => openAssignModal(lesson),
        },
        {
          key: 'history',
          label: formatMessage({ id: 'COMMON.HISTORY' }),
          iconCell: true,
          iconClass: 'lemon icon-info dark-grey',
          onClick: (lesson) => openHistoryModal(lesson),
        },
        ...Object.values(templateLevel).map(({ label, hoverLabel }, index) => ({
          key: `templateLevel-${label}`,
          label,
          width: widthOptions.xs,
          itemTooltip: () => hoverLabel || '',
          render: (lesson) => renderTemplateLevelCheckBox(lesson, index),
        })),
        {
          key: 'delete',
          hide: !hasRoleToDelete,
          render: renderDeleteControl,
        },
      ]}
      list={list}
      loadList={loadList}
      params={params}
    />
  );
};

LessonsTable.propTypes = {
  loading: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object),
  profile: PropTypes.object.isRequired,
  loadList: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  editLesson: PropTypes.func.isRequired,
  openAssignModal: PropTypes.func.isRequired,
  openHistoryModal: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withPagination(injectIntl(LessonsTable));
