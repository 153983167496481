import { useCallback, useEffect, useState } from 'react';
import { useQueryParams } from './useQueryParams';
import omit from 'lodash/omit';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'actions/common';

export const useFetchWithQueryParams = ({ queryParamsConfig, fetch, enableLoader = true }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { params, setCurrentParams } = useQueryParams(queryParamsConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadList = async (params) => {
      setIsLoading(true);
      setError(null);
      if (enableLoader) {
        dispatch(showLoader());
      }
      try {
        const res = await fetch(params);
        setData(res);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
        if (enableLoader) {
          dispatch(hideLoader());
        }
      }
    };
    loadList(params);
  }, [params, fetch, dispatch, enableLoader]);

  const changeParams = useCallback(
    (changedParams) => {
      setCurrentParams({ ...params, ...changedParams });
    },
    [params, setCurrentParams]
  );

  const changeParamsAndResetPage = useCallback(
    (changedParams) => {
      setCurrentParams({ page: 1, ...omit(params, 'page'), ...changedParams });
    },
    [params, setCurrentParams]
  );

  return {
    params,
    setCurrentParams,
    changeParams,
    changeParamsAndResetPage,
    isLoading,
    error,
    data,
    setData,
  };
};
