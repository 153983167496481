import qs from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import Settings from '../config/settings';
import request from '../libs/request';
import { Methods } from '../libs/http';

const BASE_URL = '/learning-paths';

export const getPaths = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/admin?${qs.stringify(params, Settings.QUERY_FORMAT)}`,
  });
};

export const getPath = async (id) => {
  const path = await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${id}/admin`,
  });
  return {
    ...path,
    pathItems: (path.pathItems || []).map((item) =>
      item.itemType === 'MARKER' ? { ...item, markerId: uuidv4() } : item
    ),
  };
};

export const createPath = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}`,
    data,
  });
};

export const editPath = async (data, id) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}`,
    data,
  });
};

export const deletePath = async (id) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${id}`,
  });
};

export const getPathAssignsList = async (id) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${id}/assigns-list`,
  });
};

export const addPathAssignsList = async (data, id) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${id}/assigns-list`,
    data,
  });
};

export const getCoursesLessons = async (searchText, page, searchType, params) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/courses-lessons?${qs.stringify(
      { searchText, page, searchType, ...params },
      Settings.QUERY_FORMAT
    )}`,
  });
};
