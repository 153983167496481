import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { SelectInput } from 'components';

const Filter = ({ title, onChange, options = [], availableOptions }) => {
  const value = options.map((option) => {
    const formatName = availableOptions.find(({ id }) => id === option)?.formatName;

    return {
      value: option,
      label: formatName ? <FormattedMessage id={formatName} /> : '',
    };
  });

  const filterOptions = (availableOptions || []).map(({ id, formatName }) => {
    return { value: id, label: <FormattedMessage id={formatName} /> };
  });

  return (
    <div className="ms-3 mb-3 d-flex align-items-center">
      <SelectInput
        label={title || <FormattedMessage id="COMMON.FILTER" />}
        value={value}
        multi
        options={filterOptions}
        onChange={(option) => {
          onChange(option ? option.map(({ value }) => value) : []);
        }}
      />
    </div>
  );
};

Filter.propTypes = {
  title: PropTypes.node.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  availableOptions: PropTypes.array.isRequired,
};

export default Filter;
