import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { membersChartDetail } from 'common/propTypes';
import MemberStatisticsPrimaryChart from './MemberStatisticsPrimaryChart/MemberStatisticsPrimaryChart';
import ChartDetailContainer from './ChartDetailContainer/ChartDetailContainer';
import LessonsDetailContainer from './LessonsDetailContainer/LessonsDetailContainer';
import { statisticsTimePeriods } from 'util/statisticsTimePeriods';

const MemberStatistics = ({
  isGroup,
  loading,
  statistics,
  baseData,
  startDate,
  endDate,
  ...props
}) => {
  const [selectedData, setSelectedData] = useState(null);

  const { primaryChart, chartDetail, period } = statistics || {};

  useEffect(() => {
    if (loading) {
      setSelectedData(null);
    }
  }, [loading]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 p-2">
          <MemberStatisticsPrimaryChart
            selectedData={selectedData}
            chart={primaryChart}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            {...props}
            handleChartClick={(data) => setSelectedData(data)}
          />
        </div>
        <div className="col-12 col-lg-6 p-2">
          <ChartDetailContainer isGroup={isGroup} chartDetail={chartDetail} />
        </div>
      </div>
      <div className="row pt-2 pb-2">
        <LessonsDetailContainer
          memberId={baseData && baseData.id}
          isGroup={isGroup}
          selectedData={selectedData}
          period={period}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  );
};

MemberStatistics.propTypes = {
  isGroup: PropTypes.bool,
  loading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  statistics: PropTypes.shape({
    primaryChart: PropTypes.arrayOf(PropTypes.object),
    chartDetail: membersChartDetail,
    period: PropTypes.oneOf(Object.values(statisticsTimePeriods)),
  }),
};

export default MemberStatistics;
