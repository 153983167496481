import add from 'date-fns/add';
import sub from 'date-fns/sub';
import dateFnsFormat from 'date-fns/format';
import parse from 'date-fns/parse';

import Settings from 'config/settings';

export const SEC_IN_MS = 1000;
export const MIN_IN_MS = 60 * SEC_IN_MS;
export const HOUR_IN_MS = 60 * MIN_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;

export const SECONDS = 'seconds';
export const MINUTES = 'minutes';
export const HOURS = 'hours';
export const WEEKS = 'weeks';
export const MONTHS = 'months';
export const YEARS = 'years';

export const getChangedDate = (date, deltaParams = {}, increase = true) => {
  return increase ? add(date, deltaParams) : sub(date, deltaParams);
};

export const getFormattedDate = (date = new Date(), format = Settings.DATE_FORMAT) =>
  dateFnsFormat(date, format);

export const getDateFromString = (dateString, format = Settings.DATE_FORMAT) =>
  parse(dateString, format, new Date());

export const getHoursMinutes = (timeInMs) => {
  let remTimeInMs = timeInMs;
  const hours = Math.floor(remTimeInMs / HOUR_IN_MS);
  remTimeInMs -= hours * HOUR_IN_MS;
  const mins = Math.floor(remTimeInMs / MIN_IN_MS);
  remTimeInMs -= mins * MIN_IN_MS;
  return { hours, mins, remMs: remTimeInMs };
};

export const getDaysHoursMinutes = (timeInMs) => {
  let remTimeInMs = timeInMs;
  const days = Math.floor(timeInMs / DAY_IN_MS);
  remTimeInMs -= days * DAY_IN_MS;
  return { days, ...getHoursMinutes(remTimeInMs) };
};

const getHumanizedTimePart = (timeUnit) => {
  const { value, labels } = timeUnit || {};
  if (typeof labels === 'object') {
    const { singular, plural } = labels || {};
    return value ? (value === 1 ? `${value} ${singular}` : `${value} ${plural}`) : '';
  }
  return value ? `${value} ${labels}` : '';
};

export const getHumanizedTimeUnits = (timeUnitArray = []) => {
  const convertedStrings = timeUnitArray.map((timeUnit) => getHumanizedTimePart(timeUnit));

  return convertedStrings.reduce(
    (acc, convertedString) =>
      convertedString && convertedString.length > 0 ? `${acc} ${convertedString}` : acc,
    ''
  );
};
