import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ContentPrimaryChart from './ContentPrimaryChart/ContentPrimaryChart';
import ContentSecondaryChart from './ContentSecondaryChart/ContentSecondaryChart';
import ContentStatisticsChartDetail from './ContentStatisticsChartDetail/ContentStatisticsChartDetail';

const ContentStatistics = ({ statistics, loading, ...props }) => {
  const { primaryChart, secondaryChart, chartDetail } = statistics || {};

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-9 p-2">
          <div>
            <ContentPrimaryChart loading={loading} chart={primaryChart} {...props} />
          </div>
          <div className="mt-4">
            <ContentSecondaryChart loading={loading} chart={secondaryChart} {...props} />
          </div>
        </div>
        <div className="col-12 col-lg-3 p-2">
          <ContentStatisticsChartDetail loading={loading} chartDetail={chartDetail} />
        </div>
      </div>
    </div>
  );
};

ContentStatistics.propTypes = {
  loading: PropTypes.bool,
  statistics: PropTypes.shape({
    primaryChart: PropTypes.arrayOf(PropTypes.object),
    secondaryChart: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default injectIntl(ContentStatistics);
