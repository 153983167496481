import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withCustomIconStyling from './withCustomIconStyling';

import './style.scss';

const ChartAreaIcon = ({ className, ...props }) => {
  return <i className={classNames('fas fa-chart-area', className)} {...props} />;
};

ChartAreaIcon.propTypes = {
  className: PropTypes.string,
};

export default withCustomIconStyling(ChartAreaIcon);
