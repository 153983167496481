import React from 'react';
import subYears from 'date-fns/subYears';
import { FormattedMessage } from 'react-intl';

window.__DEV__ =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development';

window.__SLT__ = process.env.REACT_APP_ENV === 'slt';

const development = {
  API_BASE_URL: 'https://api.slt-dev.attrecto-dev.com/api',
  PUBLIC_URL: 'https://admin.slt-dev.attrecto-dev.com',
};

const slt = {
  API_BASE_URL: 'https://api.slt.attrecto-dev.com/api',
  PUBLIC_URL: 'https://admin.slt.attrecto-dev.com',
};

const production = {
  API_BASE_URL: 'https://api.sweetlemontraining.com/api',
  PUBLIC_URL: 'https://admin.sweetlemontraining.com',
};

// Breakpoint values.
const general = {
  API_TOKEN: 'API_TOKEN',
  API_TOKEN_EXPIRES_AT: 'API_TOKEN_EXPIRES_AT',
  BREAKPOINTS: {
    tiny: 480,
    xsmall: 600,
    small: 740,
    medium: 960,
    large: 1200,
    xlarge: 1424,
    huge: 1584,
  },
  ITEMS_PER_PAGE: 20,
  MAX_PAGES: 9,
  QUERY_FORMAT: { arrayFormat: 'bracket' },
  map: {
    googleApiKey: 'AIzaSyAogN1FNfWuZGhG_8nPoHBQpkF585lrL04',
  },
  GROUPS: [
    { label: 'COMPANIES.GROUPS.EMPLOYEE', value: 'employee' },
    { label: 'COMPANIES.GROUPS.CONTENT_MANAGER', value: 'contentmanager' },
    { label: 'COMPANIES.GROUPS.MANAGER', value: 'manager' },
  ],
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_PATTERN: '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])([a-zA-Z0-9]+)$',
  PHONE_PATTERN: /^$|[+][(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/,
  NUMBER_PATTERN: '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$',
  RANGE_PATTERN_0_100: '^([0-9]|[1-9][0-9]|[0-1][0][0])$',
  NON_WHITE_CHARS_PATTERN: '^[a-zA-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u021B]*',
  URL_PATTERN: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  MAX_TEXT_FIELD_LENGTH: 255,
  GENDERS: [
    { label: <FormattedMessage id="USERS.GENDERS.ELSE" key={1} />, value: 'notKnown' },
    { label: <FormattedMessage id="USERS.GENDERS.MALE" key={2} />, value: 'male' },
    { label: <FormattedMessage id="USERS.GENDERS.FEMALE" key={3} />, value: 'female' },
  ],
  PUBLIC_PLACES: [
    { label: <FormattedMessage id="USERS.PUBLIC_PLACE.STREET" key={1} />, value: 'Street' },
    { label: <FormattedMessage id="USERS.PUBLIC_PLACE.SQUARE" key={2} />, value: 'Square' },
    { label: <FormattedMessage id="USERS.PUBLIC_PLACE.AVENUE" key={3} />, value: 'Avenue' },
  ],
  QUESTION_EXAMPLES: [
    'TESTS.QUESTION_EXAMPLE_1',
    'TESTS.QUESTION_EXAMPLE_2',
    'TESTS.QUESTION_EXAMPLE_3',
    'TESTS.QUESTION_EXAMPLE_4',
  ],
  ANIMATION_LENGTH: 700,
  REFRESH_TOKEN_RESOURCE: '/auth/login/refresh',
  REFRESH_TOKEN_IN_PROGRESS: 'refreshTokenInProgress',
  NO_ACCESS: 'No access to login!',
  BIRTH_DATE: 'Birth date',
  BIRTH_DATE_REQ: 'Birth date *',
  SANITARY_BOOK_EXPIRE: 'Sanitary book expire',
  LICENSE_EXPIRATION: 'License expiration',
  LICENSE_EXPIRATION_REQ: 'License expiration *',
  SORT_BY: {
    DESCRIPTION: 'description',
    EMAIL: 'email',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    GROUP: 'user_groups.rank',
    NAME: 'name',
    TITLE: 'title',
    LESSON_TYPE: 'lessonType',
    PUBLICITY: 'public',
    LESSON_COUNT: 'lesson_count',
    LESSONS_COUNT: 'lessons_count',
    PRICE: 'price',
    CITY: 'city',
    POSITION: 'position',
    FORM_OF_EMPLOYMENT: 'form_of_employment_id',
    CREATED_AT: 'created_at',
    COMPANY: 'subsidiaries.name',
    SUBSIDIARY: 'subsidiary',
    SUBSIDIARY_NAME: 'subsidiary_name',
    PARENT_COMPANY_NAME: 'company_name',
    SUBSCRIPTION_TYPE: 'subscription_type',
    LICENSE_EXPIRATION: 'license_expiration',
    USAGE_TIME: 'usage_time',
    DEADLINE: 'deadline',
    USERS_FIRST_NAME: 'users.first_name',
    USERS_COUNT: 'users_count',
    NUM_OF_SUBSIDIARIES: 'subsidiary_count',
    CATEGORY: 'category',
    COURSE: 'course',
    COURSES_COUNT: 'courses_count',
    COURSE_TITLE: 'course_title',
    LESSON: 'lesson',
    LESSON_TITLE: 'lesson_title',
    SPENT_TIME: 'time_spent',
    STATUS: 'status',
    ASSIGNMENT_DATE: 'assigned_at',
    OWNER: 'owner',
    PATH_LESSON_COUNT: 'lessonCount',
  },
  ORDER: {
    ASC: 'asc',
    DESC: 'desc',
  },
  GROUP: {
    CONTENT_MANAGER: 'Content manager',
    MANAGER: 'Manager',
  },
  MISSING_LESSONS: 'You have to add at least one lesson to the course!',
  MISSING_TAGS: 'You have to add at least one tag to the lesson!',
  MISSING_PACKAGE_IMAGE: 'A package image must be selected!',
  QUESTION_TYPES: [
    { title: 'Multiple choice', id: 1 },
    { title: 'Word puzzle', id: 2 },
  ],
  ADD: {
    COMPANY: 'Add Company',
    USER: 'Add User',
    AUDITOR: 'Add Validator',
    JOB: 'Add Job',
    COURSE: 'Add Course',
    COMPANY_GROUP: 'Add Company Group',
    LESSON: 'Add Lesson',
    PACKAGE: 'Add Package',
    TEST: 'Add question to this level',
    GENERATE_TESTS: 'Generate test for all levels',
    ADMIN: 'Add Admin',
  },
  INTERVAL: {
    MIN: 'From',
    MAX: 'To',
  },
  PRIVATE_LESSON_ERROR: 'Private lessons are not allowed in public courses!',
  NOT_VALIDATED_YET: 'Not validated yet.',
  MAX_BIRTH_DATE: subYears(new Date(), 18),
  MIN_STATISTICS_DATE: new Date('2000-01-01'),
  MAX_STATISTICS_DATE: new Date(),
  DATE_FORMAT_DATE_PICKER: 'yyyy-MM-dd',
  DATE_FORMAT: 'yyyy-MM-dd',
  DATE_FORMAT_MONTH: 'yyyy-MM',

  ITEM_TYPES: {
    MARKER: 'marker',
    COURSE: 'course',
    LESSON: 'lesson',
  },

  DROP_AREA_NAME: 'pathEditorDroppableArea',
};

export default {
  ...(window.__DEV__ ? development : window.__SLT__ ? slt : production),
  ...general,
};
