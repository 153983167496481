import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import StatisticTypeChooser from './StatisticTypeChooser';

const CompanySubsidiaryLabel = ({
  loading,
  isAccordionOpen,
  setAccordionOpen,
  handleSetLabelFilter,
  labelFilter,
  baseData,
  intl: { formatMessage },
}) => {
  const { name } = baseData || {};
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="company-name-container col-12 col-lg-4">
          <div>{name || formatMessage({ id: 'COMMON.NO_DATA' })}</div>
        </div>
        <div className="company-type-chooser-container col-12 col-lg-8">
          <StatisticTypeChooser
            baseData={baseData}
            isAccordionOpen={isAccordionOpen}
            setAccordionOpen={setAccordionOpen}
            loading={loading}
            filter={labelFilter}
            onTypeChange={handleSetLabelFilter}
          />
        </div>
      </div>
    </div>
  );
};

CompanySubsidiaryLabel.propTypes = {
  isAccordionOpen: PropTypes.bool,
  setAccordionOpen: PropTypes.func,
  baseData: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  handleSetLabelFilter: PropTypes.func.isRequired,
  labelFilter: PropTypes.object.isRequired,
};

export default injectIntl(CompanySubsidiaryLabel);
