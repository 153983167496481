import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { getLessons } from 'services/lessons';
import { TransferLists } from 'components';
import { withError } from 'hoc';

const AssignLesson = ({ disabled, isCoursePublic }) => {
  const [availableItems, setAvailableItems] = useState(null);
  const [listChanged, setListChanged] = useState(true);
  const [showLocalLoader, setShowLocalLoader] = useState(false);
  const lastPromise = useRef();

  const fetchLessons = useCallback(async (searchText = '') => {
    setShowLocalLoader(true);
    const availableLessons = getLessons({
      noPaginate: 1,
      searchIn: ['title'],
      sortBy: 'title',
      order: 'asc',
      searchText,
    });

    lastPromise.current = availableLessons;
    availableLessons
      .then((data) => {
        if (lastPromise.current === availableLessons) {
          setAvailableItems(data.data);
        }
      })
      .catch(() => setAvailableItems([]))
      .finally(() => {
        // TODO: Refactor TransferLists component, this is needed to prevent infinite loop
        setListChanged(true);
        setListChanged(false);
        setShowLocalLoader(false);
      });
  }, []);

  return (
    <Field
      name="lessons"
      itemsName={'lessons'}
      component={TransferLists}
      disabled={disabled}
      sourceList={
        availableItems &&
        availableItems.map(({ id, title, isPublic }) => ({ id, name: title, isPublic }))
      }
      isItemUnMoveableToRight={({ isPublic }) => isCoursePublic && isPublic === false}
      loadList={fetchLessons}
      listChanged={listChanged}
      canUseOrder
      showLocalLoader={showLocalLoader}
    />
  );
};

AssignLesson.propTypes = {
  isCoursePublic: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withError(AssignLesson);
