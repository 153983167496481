import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CompanyContactEditor from './ContactEditor/CompanyContactEditor';
import { getUserIdentifier } from 'containers/UsersPage/User';
import { TableContainer, AddRow, ConfirmModal } from 'components';
import { horizontalAlignOptions } from 'components/Table/tableConstants';
import { modalType } from 'util/modalType';

import { createShallowMergedObject } from 'util/objectUtil';
import { CompanyAccess, SubsidiaryAccess } from 'libs/accessManagement';

import {
  getCompanyContacts,
  addContactToCompany,
  editContactAtCompany,
  deleteContactFromCompany,
} from 'services/companies';

import {
  getSubsidiaryContacts,
  addContactToSubsidiary,
  editContactAtSubsidiary,
  deleteContactFromSubsidiary,
} from 'services/subsidiaries';

const initialContact = {
  firstName: '',
  lastName: '',
  position: '',
  title: '',
  contactType: '',
  email: '',
  department: '',
  phoneNumber: '',
};

const getFullNameFromContact = (contact, altName = 'element') => {
  return contact ? `${contact.firstName || ''} ${contact.lastName || ''}` : altName;
};

const CompanyContactsTab = ({ company, profile, disabled, isCompany, intl: { formatMessage } }) => {
  const [contact, setContact] = useState(initialContact);
  const [modal, setModal] = useState(null);
  const [contacts, setContacts] = useState([]);

  const getContacts = useCallback(async (companyId, isCompany) => {
    const res = companyId
      ? isCompany
        ? await getCompanyContacts(companyId)
        : await getSubsidiaryContacts(companyId)
      : [];
    setContacts(res);
  }, []);

  const createContact = useCallback(
    async (contact) => {
      const res = isCompany
        ? await addContactToCompany(company.id, contact)
        : await addContactToSubsidiary(company.id, contact);
      const newContacts = [...contacts, res];
      setContacts(newContacts);
    },
    [company.id, contacts, isCompany]
  );

  const editContact = useCallback(
    async (contact) => {
      const res = isCompany
        ? await editContactAtCompany(company.id, contact.id, contact)
        : await editContactAtSubsidiary(company.id, contact.id, contact);
      const newContacts = contacts.map((contact) =>
        contact.id === res.id ? { ...contact, ...res } : contact
      );
      setContacts(newContacts);
    },
    [company.id, contacts, isCompany]
  );

  const deleteContact = async (contact) => {
    if (isCompany) {
      await deleteContactFromCompany(company.id, contact.id);
    } else {
      await deleteContactFromSubsidiary(company.id, contact.id);
    }
    const newContacts = contacts.filter(({ id }) => id !== contact.id);
    setContacts(newContacts);
  };

  const openModal = (selectedModal, selectedContact) => {
    setModal(selectedModal);
    setContact(selectedContact);
  };

  const closeModal = useCallback(() => {
    setModal(null);
    setContact(initialContact);
  }, []);

  const handleDelete = async () => {
    try {
      await deleteContact(contact);
    } finally {
      closeModal();
    }
  };

  const {
    ContactAccess: {
      hasRoleToAdd: hasRoleToAddSubsidiaryContact,
      hasRoleToEdit: hasRoleToEditSubsidiaryContact,
      hasRoleToDelete: hasRoleToDeleteSubsidiaryContact,
    },
  } = SubsidiaryAccess.getHasRoles(profile.profile);

  const {
    ContactAccess: {
      hasRoleToAdd: hasRoleToAddCompanyContact,
      hasRoleToEdit: hasRoleToEditCompanyContact,
      hasRoleToDelete: hasRoleToDeleteCompanyContact,
    },
  } = CompanyAccess.getHasRoles(profile.profile);

  useEffect(() => {
    getContacts(company.id, isCompany);
  }, [company.id, isCompany, getContacts]);

  return (
    <>
      <div className="col-12 d-flex flex-wrap mt-4">
        <h5 className="col-6 p-0">{formatMessage({ id: 'CONTACTS.TITLE' })}</h5>

        {(isCompany ? hasRoleToAddCompanyContact : hasRoleToAddSubsidiaryContact) ? (
          <div className="col-6 p-0 d-flex justify-content-end">
            <AddRow
              onAdd={() => openModal(modalType.EDIT)}
              label={formatMessage({ id: 'CONTACTS.ADD' })}
              className="mb-0 align-items-center"
            />
          </div>
        ) : null}
      </div>
      <div className="col-12">
        <TableContainer
          columns={[
            {
              key: 'edit',
              hide: isCompany ? !hasRoleToEditCompanyContact : !hasRoleToEditSubsidiaryContact,
              iconCell: true,
              onClick: (contact) => openModal(modalType.EDIT, contact),
            },
            {
              key: 'name',
              label: formatMessage({ id: 'CONTACTS.NAME' }),
              horizontalAlign: horizontalAlignOptions.left,
              itemTooltip: (contact) => getFullNameFromContact(contact),
              render: (contact) => getUserIdentifier(contact),
            },
            {
              key: 'department',
              label: formatMessage({ id: 'CONTACTS.DEPARTMENT' }),
            },
            { key: 'email', label: formatMessage({ id: 'COMMON.EMAIL' }) },
            {
              key: 'phoneNumber',
              label: formatMessage({ id: 'COMMON.PHONE_NUMBER_TITLE' }),
            },
            {
              key: 'delete',
              hide: isCompany ? !hasRoleToDeleteCompanyContact : !hasRoleToDeleteSubsidiaryContact,
              iconCell: true,
              onClick: (contact) => openModal(modalType.DELETE, contact),
              deleteIcon: true,
            },
          ]}
          list={contacts || []}
        />
      </div>
      <CompanyContactEditor
        open={modal === modalType.EDIT}
        closeEditor={closeModal}
        createContact={createContact}
        editContact={editContact}
        edit={!!(contact && contact.id)}
        contact={createShallowMergedObject(initialContact, contact)}
        disabled={disabled}
      />

      <ConfirmModal
        open={modal === modalType.DELETE}
        onClose={closeModal}
        title={formatMessage({ id: 'CONTACTS.DELETE' })}
        saveAction={handleDelete}
        confirmElement={formatMessage(
          { id: 'COMMON.DELETE_QUESTION' },
          { title: `"${getFullNameFromContact(contact)}"` }
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

CompanyContactsTab.propTypes = {
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isCompany: PropTypes.bool,
};

export default connect(mapStateToProps)(injectIntl(CompanyContactsTab));
