import * as RoleService from '../services/roles';

import * as actionTypes from 'actions/action-types';

export const getRoles = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_ROLES,
      });

      const roles = await RoleService.getRoles();

      dispatch({
        type: actionTypes.GET_ROLES_SUCCESS,
        payload: roles,
      });

      return roles;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ROLES_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getRolesByType = (type = 'subsidiary') => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_ROLES_BY_TYPE,
      });

      const roles = await RoleService.getRolesByType(type);

      dispatch({
        type: actionTypes.GET_ROLES_BY_TYPE_SUCCESS,
        payload: roles,
      });

      return roles;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ROLES_BY_TYPE_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
