import React from 'react';
import PropTypes from 'prop-types';

import { CaretIcon, Popover } from 'components';

import './style.scss';

const GroupItem = ({ group, onClick }) => {
  const { name } = group || {};
  return (
    <div className="group-item">
      <div className="group-item-content">
        <Popover
          className="group-item-content-name-wrapper"
          content={name}
          oneLine
          placement="top"
          trigger="hover">
          <div className="group-item-content-name">{name}</div>
        </Popover>
        <div>
          <CaretIcon
            iconClassName="group-item-icon"
            noWrapper
            orientation="right"
            onClick={onClick}
          />
        </div>
      </div>
      <div className="group-item-divider" />
    </div>
  );
};

GroupItem.propTypes = {
  group: PropTypes.object,
  onClick: PropTypes.func,
};

export default GroupItem;
