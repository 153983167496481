import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const withCustomIconStyling = (Component) => {
  const wrappedWithCustomIconStyling = (props) => {
    const {
      noWrapper,
      iconContainerClassName,
      iconClassName,
      onClick,
      backgroundColor = 'transparent',
      color,
      disabled,
      ...remainingProps
    } = props;

    const iconComponent = (
      <Component
        className={classNames(iconClassName, 'custom-icon', {
          'clickable-icon': onClick,
          disabled,
        })}
        style={{ color }}
        color={color}
        onClick={!disabled && onClick && noWrapper ? onClick : null}
        {...remainingProps}
      />
    );

    return noWrapper ? (
      iconComponent
    ) : (
      <div
        className={classNames('icon-wrapper', {
          'clickable-icon': !noWrapper && onClick,
          disabled,
        })}
        style={{ backgroundColor }}
        onClick={!disabled && onClick && !noWrapper ? onClick : null}>
        {iconComponent}
      </div>
    );
  };

  wrappedWithCustomIconStyling.propTypes = {
    noWrapper: PropTypes.bool,
    disabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    iconContainerClassName: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
  };

  return wrappedWithCustomIconStyling;
};

export default withCustomIconStyling;
