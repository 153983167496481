import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DndContext, pointerWithin } from '@dnd-kit/core';

import { createId } from 'util/dndIdCreator';
import settings from 'config/settings';

import './style.scss';

const PathEditor = ({
  disabled,
  pathItems,
  setPathItems,
  renderSourceList,
  renderEditor,
  addPathItem,
}) => {
  const [activeId, setActiveId] = useState(null);

  const onDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const onDragEnd = (event) => {
    if (event.over && event.over.id === settings.DROP_AREA_NAME) {
      addPathItem(event.active.id);
    }
    setActiveId(null);
  };

  const isListItemDisabled = (item) => {
    return !!pathItems?.find((pathItem) => createId(pathItem) === createId(item));
  };

  return (
    <div className="path-editor-container container">
      <DndContext
        autoScroll={false}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        collisionDetection={pointerWithin}>
        <div className="path-editor row">
          <div className="col-12">
            <div className="row">
              {renderEditor({ disabled, pathItems, setPathItems })}
              {renderSourceList({ activeId, isListItemDisabled })}
            </div>
          </div>
        </div>
      </DndContext>
    </div>
  );
};

PathEditor.propTypes = {
  disabled: PropTypes.bool,
  pathItems: PropTypes.array,
  setPathItems: PropTypes.func,
  renderEditor: PropTypes.func.isRequired,
  renderSourceList: PropTypes.func.isRequired,
  addPathItem: PropTypes.func.isRequired,
};

export default PathEditor;
