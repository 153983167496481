import request from '../libs/request';
import { Methods } from '../libs/http';

const BASE_URL = '/users';

export const login = async (credentials) => {
  return await request({
    method: Methods.POST,
    resource: '/auth/login/email',
    data: credentials,
  });
};

export const logout = async () => {
  return await request({
    method: Methods.GET,
    resource: '/auth/logout',
  });
};

export const forgotPassword = async (email) => {
  return await request({
    method: Methods.POST,
    resource: '/auth/forgotten-password-email',
    data: email,
  });
};

export const refreshToken = async (token) => {
  return await request({
    method: Methods.POST,
    resource: '/auth/login/refresh',
    data: {
      token,
    },
  });
};

export const getUser = async () => {
  return await request({
    method: Methods.GET,
    resource: `/admin/users/me`,
  });
};

export const getUserRoles = async (userId) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${userId}/accordion`,
  });
};

export const getUserById = async (userId, subsidiaryId) => {
  return await request({
    method: Methods.GET,
    headers: { subsidiaryId },
    resource: `${BASE_URL}/${userId}/detail`,
  });
};

export const editUser = async (id, data) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}/profile`,
    data,
  });
};

export const editUserRolesAndRanks = async (id, data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${id}/roles`,
    data,
  });
};

export const createAdmin = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}`,
    data,
  });
};

export const saveProfileImage = async (id, data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${id}/profile/picture`,
    data,
  });
};

export const deleteUser = async (id) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${id}`,
  });
};

export const enableUser = async (id) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}/enable`,
  });
};

export const withdrawRole = async (userId, roleId) => {
  return await request({
    method: Methods.POST,
    resource: '/roles/remove',
    data: {
      userId,
      roleId,
    },
  });
};

export const getUserList = async (params) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/list`,
    data: { ...params },
  });
};

export const getUserHistory = async (id) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${id}/history`,
  });
};

export const getUsersByRole = async (roleId, page = 1) => {
  return await request({
    method: Methods.GET,
    resource: `/roles/${roleId}/users?page=${page}`,
  });
};

export const sendReminder = async (id) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${id}/send-reminder`,
  });
};

export const assignRoles = async (id, data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${id}/assign-roles`,
    data,
  });
};

export const deleteRole = async (id, data) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${id}/remove-role`,
    data,
  });
};

export const getUsersLookup = async (email) => {
  const users = await request({
    method: Methods.GET,
    resource: `${BASE_URL}/lookup?email=${email}`,
  });

  return users.map(({ email }) => ({
    value: email,
    label: email,
  }));
};
