import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { BarChart, ChartContainer } from 'components';
import { getChartLabelTraslateNameId } from 'util/chartRenderUtil';
import { secondaryChartConstants } from '../chartConstants';

const height = 400;

const ContentSecondaryChart = ({ chart, loading, intl: { formatMessage }, ...props }) => {
  const { yAxes: yAxesData, chartBars, xAxisDataKey } = secondaryChartConstants || {};

  const getBars = () => {
    return (chartBars || []).map(({ key, color, ...props }) => {
      return {
        ...props,
        dataKey: key,
        fill: color,
        name: getChartLabelTraslateNameId(key)
          ? formatMessage({ id: getChartLabelTraslateNameId(key) })
          : key,
      };
    });
  };

  const yAxes = (yAxesData || []).map(({ labelTranslateId, ...props }) => ({
    label: formatMessage({ id: labelTranslateId }),
    ...props,
  }));

  return (
    <ChartContainer
      chartTitle={<FormattedMessage id="CONTENT_STATISTICS.SECONDARY_CHART_TITLE" />}
      disabledTimeFilter
      loading={loading}
      {...props}>
      <BarChart
        loading={loading}
        height={height}
        bars={getBars()}
        data={chart}
        xAxisDataKey={xAxisDataKey}
        yAxes={yAxes}
      />
    </ChartContainer>
  );
};

ContentSecondaryChart.propTypes = {
  chart: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(ContentSecondaryChart);
