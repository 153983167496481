import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Settings from 'config/settings';
import SubsidiaryIndicators from './SubsidiaryIndicators';
import SubsidiariesTable from './SubsidiariesTable/SubsidiariesTable';
import {
  getSubsidiariesTopData,
  getSubsidiaries,
  enableSubsidiary,
  disableSubsidiary,
  deleteSubsidiary,
} from 'services/subsidiaries';
import { SubsidiaryAccess } from 'libs/accessManagement';
import CompanyUpdateForm from '../CompanyAndSubsidiaryEditor/CompanyUpdateForm';
import { CrudPage } from 'components';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';
import { getProfile } from 'actions/users';

import '../Dashboard/style.scss';

const defaultParams = {
  sortBy: Settings.SORT_BY.SUBSIDIARY_NAME,
  order: 'asc',
};

const initialValues = {
  id: null,
  name: '',
  legalName: '',
  email: '',
  phoneNumber: '',
  isEnabled: true,
  vatIdNumber: '',
  priceRange: '',
  mainGroupType: '',
  groupType: '',
  opening: '',
  active: '',
  imageUrl: '',
  imageUrlFull: '',
  parentCompany: '',
  address: {
    id: null,
    zip: '',
    country: '',
    city: '',
    publicPlace: '',
    street: '',
    houseNumber: '',
  },
};

const SubsidiariesPage = ({ profile, getProfile, intl: { formatMessage } }) => {
  const fetchSubsidiaries = useCallback(async (params) => await getSubsidiaries(params), []);

  const {
    data,
    setData,
    isLoading,
    params,
    setCurrentParams,
    changeParams,
  } = useFetchWithQueryParams({
    fetch: fetchSubsidiaries,
    queryParamsConfig: defaultParams,
  });
  const count = data?.count || 0;
  const subsidiariesList = data?.data || [];

  const handleDisableSubsidiary = async (subsidiaryId) => {
    await disableSubsidiary(subsidiaryId);
    setData({
      ...data,
      data: data?.data.map((subsidiary) =>
        subsidiary.id === subsidiaryId ? { ...subsidiary, isDisabled: true } : subsidiary
      ),
    });
  };

  const handleEnableSubsidiary = async (subsidiaryId) => {
    await enableSubsidiary(subsidiaryId);
    setData({
      ...data,
      data: data?.data.map((subsidiary) =>
        subsidiary.id === subsidiaryId ? { ...subsidiary, isDisabled: false } : subsidiary
      ),
    });
  };

  const deleteItem = async (id) => {
    await deleteSubsidiary(id);
    await getProfile();
  };

  const { hasRoleToCreate } = SubsidiaryAccess.getHasRoles(profile.profile);

  return (
    <CrudPage
      className="dashboard-page"
      title={formatMessage({ id: 'SUBSIDIARY.TITLE' })}
      isLoading={isLoading}
      params={params}
      setCurrentParams={setCurrentParams}
      initialData={initialValues}
      hasRoleToCreate={hasRoleToCreate}
      addGroupLabel={<FormattedMessage id="SUBSIDIARY.ADD_GROUP" />}
      deleteTitle={<FormattedMessage id="SUBSIDIARY.DELETE_SUBSIDIARY" />}
      addRowLabel={<FormattedMessage id="SUBSIDIARY.ADD_SUBSIDIARY" />}
      deleteItem={deleteItem}
      deleteKey="name"
      onDelete={changeParams}
      filterBySearchText
      searchFieldCentered
      indicators={<SubsidiaryIndicators getCompanyTopData={getSubsidiariesTopData} />}>
      {({ data, isEditorOpen, closeModal, openDeleteModal, openEditModal, setCurrentParams }) => {
        return (
          <>
            <SubsidiariesTable
              loading={isLoading}
              list={subsidiariesList}
              profile={profile}
              currentPage={params.page}
              count={count}
              loadList={setCurrentParams}
              loadPage={(page) => changeParams(page)}
              params={params}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              disableSubsidiary={(subsidiary) => handleDisableSubsidiary(subsidiary.id)}
              enableSubsidiary={(subsidiary) => handleEnableSubsidiary(subsidiary.id)}
            />
            <CompanyUpdateForm
              onSaved={changeParams}
              companyId={data.id}
              open={isEditorOpen}
              closeEditor={closeModal}
            />
          </>
        );
      }}
    </CrudPage>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProfile,
    },
    dispatch
  );
};

SubsidiariesPage.propTypes = {
  profile: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SubsidiariesPage));
