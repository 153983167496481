import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MessageComponent, SpinnerWrapper, TableFilter } from 'components';
import { moveArrayItem } from 'util/arrayItemMovement';
import { hideLoader, showLoader } from 'actions/common';

import TransferListItem from './TransferListItem';
import TransferListCount from './TransferListCount';
import TransferListHorizontalButtons from './TransferListHorizontalButtons';
import TransferListVerticalButtons from './TransferListVerticalButtons';

import './style.scss';

const TransferLists = ({
  sourceList,
  field,
  form,
  loadList,
  listChanged,
  renderSourceEntry,
  renderResultEntry,
  itemsName,
  disabled,
  onToLeft,
  overrideToLeft,
  onToRight,
  overrideToRight,
  filterSourceItems,
  isItemUnMoveableToLeft,
  isItemUnMoveableToRight,
  canUseOrder = false,
  showLocalLoader,
  showLoader,
  hideLoader,
  intl: { formatMessage },
}) => {
  const [selectedLeftItems, setSelectedLeftItems] = useState([]);
  const [selectedRightItems, setSelectedRightItems] = useState([]);
  const [sourceItems, setSourceItems] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [valuesChanged, setValuesChanged] = useState(false);

  const resultItems = field.value;

  const setResultList = useCallback(
    (value) => {
      if (form && field) {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name, true);
      }
    },
    [form, field]
  );

  useEffect(() => {
    if (
      (typeof sourceList !== 'undefined' && sourceList !== null && listChanged) ||
      valuesChanged
    ) {
      const filteredSourceList = sourceList.filter(
        filterSourceItems
          ? filterSourceItems
          : ({ id }) => !(resultItems || []).find((result) => result.id === id)
      );
      setSourceItems(filteredSourceList);
      setValuesChanged(false);
    }
  }, [
    sourceList,
    sourceItems,
    resultItems,
    filterSourceItems,
    listChanged,
    setResultList,
    valuesChanged,
  ]);

  const onChangeLeftSelection = (e, item) => {
    if (!e.target.checked) {
      setSelectedLeftItems(selectedLeftItems.filter(({ id }) => id !== item.id));
    } else {
      setSelectedLeftItems([...selectedLeftItems, item]);
    }
  };

  const handleToRight = (movedItems, remainingItems) => {
    if (overrideToRight) {
      overrideToRight(movedItems, remainingItems, setValuesChanged);
    } else {
      setResultList([...(resultItems || []), ...movedItems]);
      setSourceItems(remainingItems);

      if (onToRight) {
        onToRight(movedItems, remainingItems);
      }
    }
    setSelectedLeftItems([]);
  };

  const handleToLeft = (movedItems, remainingItems) => {
    if (overrideToLeft) {
      overrideToLeft(movedItems, remainingItems, setValuesChanged);
    } else {
      setSourceItems([...(sourceItems || []), ...movedItems]);
      setResultList(remainingItems);

      if (onToLeft) {
        onToLeft(movedItems, remainingItems);
      }
    }
    setSelectedRightItems([]);
  };

  const onChangeRightSelection = (e, item) => {
    if (!e.target.checked) {
      setSelectedRightItems(selectedRightItems.filter(({ id }) => id !== item.id));
    } else {
      setSelectedRightItems([...selectedRightItems, item]);
    }
  };

  const isNotSelectedRightItemDisabled = (item) => {
    if (selectedRightItems.length && selectedRightItems.filter(({ id }) => id !== item.id).length) {
      return true;
    }
    return false;
  };

  const toRight = () => {
    const movedItems = isItemUnMoveableToRight
      ? selectedLeftItems.filter((item) => item && !isItemUnMoveableToRight(item))
      : selectedLeftItems;
    const remainingItems = sourceItems
      ? sourceItems.filter(({ id }) => !movedItems.find((item) => item.id === id))
      : [];

    handleToRight(movedItems, remainingItems);
  };

  const toLeft = () => {
    const movedItems = isItemUnMoveableToLeft
      ? selectedRightItems.filter((item) => item && !isItemUnMoveableToLeft(item))
      : selectedRightItems;
    const remainingItems = resultItems
      ? resultItems.filter(({ id }) => !movedItems.find((item) => item.id === id))
      : [];

    handleToLeft(movedItems, remainingItems);
  };

  const allToRight = () => {
    const movedItems = isItemUnMoveableToRight
      ? sourceItems.filter((item) => item && !isItemUnMoveableToRight(item))
      : sourceItems;
    const remainingItems = isItemUnMoveableToRight
      ? sourceItems.filter((item) => item && isItemUnMoveableToRight(item))
      : [];

    handleToRight(movedItems, remainingItems);
  };

  const isToLeftDisabled = () => {
    if (!selectedRightItems.length || disabled) {
      return true;
    }
    return false;
  };

  const isToRightDisabled = () => {
    if (!selectedLeftItems.length || disabled) {
      return true;
    }
    return false;
  };

  const isAllToLeftDisabled = () => {
    if (!resultItems || (resultItems && !resultItems.length) || disabled) {
      return true;
    }
    return false;
  };

  const isAllToRightDisabled = () => {
    if (!sourceItems || (sourceItems && !sourceItems.length) || disabled) {
      return true;
    }
    return false;
  };

  const allToLeft = () => {
    const movedItems = isItemUnMoveableToLeft
      ? resultItems.filter((item) => item && !isItemUnMoveableToLeft(item))
      : resultItems;
    const remainingItems = isItemUnMoveableToLeft
      ? resultItems.filter((item) => item && isItemUnMoveableToLeft(item))
      : [];

    handleToLeft(movedItems, remainingItems);
  };

  const onChangeSearchText = async (searchText) => {
    showLoader();
    setSearchText(searchText);
    try {
      await loadList(searchText);
    } finally {
      hideLoader();
    }
  };

  const toTop = () => {
    moveArrayItem('top', resultItems, selectedRightItems, setSelectedRightItems);
  };

  const toBottom = () => {
    moveArrayItem('bottom', resultItems, selectedRightItems, setSelectedRightItems);
  };

  const oneUp = () => {
    moveArrayItem('oneUp', resultItems, selectedRightItems, setSelectedRightItems);
  };

  const oneDown = () => {
    moveArrayItem('oneDown', resultItems, selectedRightItems, setSelectedRightItems);
  };

  const isUpButtonDisabled = () => {
    if (
      !selectedRightItems.length ||
      resultItems.findIndex(({ id }) => id === selectedRightItems[0].id) === 0
    ) {
      return true;
    }
    return false;
  };

  const isDownButtonDisabled = () => {
    if (
      !selectedRightItems.length ||
      resultItems.length === resultItems.findIndex(({ id }) => id === selectedRightItems[0].id) + 1
    ) {
      return true;
    }
    return false;
  };

  const isItemDisabledIfCanUseOrder = (item) => {
    if (canUseOrder) {
      return isNotSelectedRightItemDisabled(item);
    }
    return false;
  };

  return (
    <>
      <div className="TransferLists d-flex justify-content-center">
        <div className="TransferListsItemsContainer">
          <TransferListCount list={sourceItems} itemsName={itemsName} />
          <div className="TransferSearch">
            <TableFilter
              hasUnderline
              loadList={onChangeSearchText}
              filter={searchText}
              disabled={disabled}
            />
          </div>
          <div className="TransferListsItems">
            {showLocalLoader ? <SpinnerWrapper /> : null}
            {sourceItems && sourceItems.length ? (
              sourceItems.map((item) => {
                return (
                  <TransferListItem
                    key={item.id}
                    item={item}
                    renderItem={renderSourceEntry}
                    isItemDisabled={isItemUnMoveableToRight}
                    onChangeSelection={onChangeLeftSelection}
                    selectedItems={selectedLeftItems}
                    disabled={disabled}
                  />
                );
              })
            ) : (
              <MessageComponent
                type="info"
                message={formatMessage({ id: 'COMMON.NO_DATA_TO_DISPLAY' })}
              />
            )}
          </div>
        </div>

        <TransferListHorizontalButtons
          allToLeft={allToLeft}
          allToRight={allToRight}
          toLeft={toLeft}
          toRight={toRight}
          isAllToLeftDisabled={isAllToLeftDisabled()}
          isAllToRightDisabled={isAllToRightDisabled()}
          isToRightDisabled={isToRightDisabled()}
          isToLeftDisabled={isToLeftDisabled()}
        />

        <div className="TransferListsItemsContainer">
          <TransferListCount list={resultItems} itemsName={itemsName} />
          <div className="TransferListsItems">
            {resultItems
              ? resultItems.map((item) => {
                  return (
                    <TransferListItem
                      disabled={disabled || isItemDisabledIfCanUseOrder(item)}
                      key={item.id}
                      item={item}
                      renderItem={renderResultEntry}
                      isItemDisabled={isItemUnMoveableToLeft}
                      onChangeSelection={onChangeRightSelection}
                      selectedItems={selectedRightItems}
                    />
                  );
                })
              : null}
          </div>
        </div>

        {canUseOrder ? (
          <TransferListVerticalButtons
            disabled={disabled}
            isUpButtonDisabled={isUpButtonDisabled()}
            isDownButtonDisabled={isDownButtonDisabled()}
            toTop={toTop}
            toBottom={toBottom}
            oneDown={oneDown}
            oneUp={oneUp}
          />
        ) : null}
      </div>
    </>
  );
};

TransferLists.propTypes = {
  onToRight: PropTypes.func,
  overrideToRight: PropTypes.func,
  onToLeft: PropTypes.func,
  overrideToLeft: PropTypes.func,
  filterSourceItems: PropTypes.func,
  disabled: PropTypes.bool,
  sourceList: PropTypes.array,
  itemsName: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  listChanged: PropTypes.bool,
  loadList: PropTypes.func,
  isItemUnMoveableToLeft: PropTypes.func,
  isItemUnMoveableToRight: PropTypes.func,
  renderSourceEntry: PropTypes.func,
  renderResultEntry: PropTypes.func,
  canUseOrder: PropTypes.bool,
  showLocalLoader: PropTypes.bool,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

export default injectIntl(connect(null, mapDispatchToProps)(TransferLists));
