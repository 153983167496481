import {
  GET_GROUPS,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILED,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILED,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  GET_ALL_GROUPS,
  GET_ALL_GROUPS_SUCCESS,
  GET_ALL_GROUPS_FAILED,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
  groupsByGroup: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...INITIAL_STATE,
        loading: true,
      };
    case GET_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload || [],
      };
    }
    case GET_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_GROUPS:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        groupsByGroup: action.payload || [],
      };
    }
    case GET_ALL_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_GROUP:
      return {
        ...state,
        loading: true,
      };
    case CREATE_GROUP_SUCCESS: {
      const data = [...state.data];
      const groupIndex = state.data.findIndex(({ id }) => +id === +action.subsidiaryId);
      if (groupIndex > -1) {
        data[groupIndex].groups.push(action.payload);
      }

      return {
        ...state,
        loading: false,
        data,
      };
    }
    case CREATE_GROUP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_GROUP:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GROUP_SUCCESS: {
      const data = [...state.data];
      const groupIndex = state.data.findIndex(({ id }) => +id === +action.subsidiaryId);
      data[groupIndex].groups = data[groupIndex].groups.filter(({ id }) => id !== action.payload);

      return {
        ...state,
        loading: false,
        data,
      };
    }
    case DELETE_GROUP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
