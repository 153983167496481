import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { openSideBar, closeSideBar, showLoader, hideLoader } from '../../actions/common';

import Home from './Home';

const mapStateToProps = (state) => {
  return {
    sideBar: state.common.sideBar,
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      openSideBar,
      showLoader,
      hideLoader,
      closeSideBar,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
