import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import Settings from 'config/settings';
import { CountryStateChooser, ImageFileUploadField, TextField, DatePickerField } from 'components';

const UserBaseDataTab = ({ disabled, create, countries }) => {
  return (
    <div className="row">
      <div className="col-12">
        <Field name="avatar" component={ImageFileUploadField} disabled={disabled} />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="lastName"
          component={TextField}
          disabled={disabled}
          label={<FormattedMessage id="USERS.LAST_NAME_REQ" />}
        />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="firstName"
          component={TextField}
          disabled={disabled}
          label={<FormattedMessage id="USERS.FIRST_NAME_REQ" />}
        />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="email"
          component={TextField}
          disabled={!create || disabled}
          label={<FormattedMessage id="COMMON.EMAIL_REQ" />}
        />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="phoneNumber"
          component={TextField}
          disabled={disabled}
          label={<FormattedMessage id="COMMON.PHONE_NUMBER" />}
        />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="dateOfBirth"
          component={DatePickerField}
          placeholder={Settings.BIRTH_DATE_REQ}
          disabled={disabled}
          label={<FormattedMessage id="USERS.BIRTH_DATE_REQ" />}
          showYearDropdown
          maxDate={Settings.MAX_BIRTH_DATE}
          showMonthYearPicker
          monthDatePicker
        />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="employeeBookNumber"
          component={TextField}
          disabled={disabled}
          label={<FormattedMessage id="USERS.EMPLOYEE_BOOK_NUMBER" />}
        />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="sanitaryBookExpire"
          component={DatePickerField}
          disabled={disabled}
          placeholder={Settings.SANITARY_BOOK_EXPIRE}
          showYearDropdown
          openingDirection="up"
          label={<FormattedMessage id="USERS.SANITARY_BOOK_EXPIRE" />}
        />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="address.zip"
          component={TextField}
          disabled={disabled}
          label={<FormattedMessage id="COMPANIES.ZIP_CODE" />}
        />
      </div>

      <CountryStateChooser
        countries={countries}
        countryInputName="address.country"
        countryInputLabel={<FormattedMessage id="USERS.COUNTRY_REQ" />}
        stateInputName="address.state"
        stateInputLabel={<FormattedMessage id="USERS.STATE_REQ" />}
      />
    </div>
  );
};

UserBaseDataTab.propTypes = {
  countries: PropTypes.array,
  disabled: PropTypes.bool,
  create: PropTypes.bool,
};

export default UserBaseDataTab;
