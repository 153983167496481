import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Popover } from 'components';

import styles from './style.module.scss';

const IndicatorActivity = ({ className, value, title }) => {
  return (
    <div className={className}>
      <div className={classNames(styles.ActivityValue, 'col-12 ps-1 pe-1 text-start')}>{value}</div>
      <div className={classNames(styles.ActivityLabel, 'col-12 ps-1 pe-1 text-start')}>
        <Popover content={title} oneLine placement="top" trigger="hover" onOverflow>
          {title}
        </Popover>
      </div>
    </div>
  );
};

IndicatorActivity.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popoverContent: PropTypes.string,
  title: PropTypes.string,
};

export default IndicatorActivity;
