import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import SelectInputBase from '../SelectInputBase/SelectInputBase';
import SelectWrapper from './SelectWrapper';

const SelectInput = ({ onInputChange, ...props }) => {
  const [typedText, setTypedText] = useState('');

  const handleInputChange = useCallback(
    (text) => {
      setTypedText(text);

      if (onInputChange) {
        onInputChange(text);
      }
    },
    [onInputChange]
  );

  return (
    <SelectInputBase {...props} typedText={typedText}>
      {(baseProps) => <SelectWrapper {...props} {...baseProps} onInputChange={handleInputChange} />}
    </SelectInputBase>
  );
};

SelectInput.propTypes = {
  onInputChange: PropTypes.func,
};

export default SelectInput;
