import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { hideLoader, showLoader } from 'actions/common';
import { ActiveFilterIndicator, RolesFilter, CrudPage } from 'components';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';
import Settings from 'config/settings';
import { removeHistoryState } from 'util/removeHistoryState';
import { modalType } from 'util/modalType';
import { getRoleByName, Roles } from 'libs/roles';
import { UserAccess } from 'libs/accessManagement';
import { forgotPassword } from 'services/users';
import { getRoles } from 'services/roles';
import { getUserList, sendReminder, deleteUser, enableUser } from 'services/users';

import {
  getFilterRoles,
  getAssignableRoles,
  getQueryOrDefaultRoles,
  isSystemUserPage,
} from './utils';
import UserDataEditor from './UserEditor/UserDataEditor/UserDataEditor';
import UserRoleEditor from './UserEditor/UserRoleEditor/UserRoleEditor';
import { getUser } from './User';
import UserConfirmModal from './UserConfirmModal';
import UsersTable from './UsersTable';

const defaultParams = {
  sortBy: Settings.SORT_BY.FIRST_NAME,
  order: 'asc',
};

const titles = {
  admin: {
    title: 'USERS.SLT_ADMINISTRATION',
    addRowLabel: 'USERS.ADD_ADMIN',
    deleteTitle: 'USERS.DISABLE_USER',
  },
  default: {
    title: 'USERS.TITLE',
    addRowLabel: 'USERS.ADD',
    deleteTitle: 'USERS.DELETE_USER',
  },
};

const UsersPage = ({ profile, hideLoader, showLoader }) => {
  const history = useHistory();
  const location = history.location;

  const [user, setUser] = useState(getUser());
  const [userIds, setUserIds] = useState(history.location?.state?.userIds || []);
  const [roles, setRoles] = useState();
  const [modal, setModal] = useState(null);

  const isSystemAdminPage = isSystemUserPage(location.pathname);
  const defaultRoles = useMemo(
    () =>
      getFilterRoles({
        roles: roles || [],
        isAdmin: isSystemAdminPage,
        profile: profile.profile,
      }),
    [profile.profile, roles, isSystemAdminPage]
  );
  const assignableRoles = useMemo(
    () =>
      getAssignableRoles({
        roles: roles || [],
        isAdmin: isSystemAdminPage,
        profile: profile.profile,
      }),
    [profile.profile, roles, isSystemAdminPage]
  );

  const fetchList = useCallback(
    async (currentParams) => {
      if (defaultRoles.length > 0 && currentParams.roles.length > 0) {
        showLoader();
        try {
          return await getUserList({
            ...currentParams,
            roles: getQueryOrDefaultRoles({
              roles: currentParams.roles,
              filteredRoles: defaultRoles,
            }),
            ...(userIds && userIds.length > 0 && { userIds }),
          });
        } finally {
          hideLoader();
        }
      }
      return null;
    },
    [defaultRoles, userIds, showLoader, hideLoader]
  );

  const {
    data,
    isLoading,
    params,
    setCurrentParams,
    changeParams,
    changeParamsAndResetPage,
  } = useFetchWithQueryParams({
    fetch: fetchList,
    queryParamsConfig: defaultParams,
    enableLoader: false,
  });

  const count = data?.count || 0;
  const total = data?.length || 0;
  const userList = data?.data || [];

  useEffect(() => {
    if (roles && !params?.roles) {
      setCurrentParams({
        ...params,
        roles: getQueryOrDefaultRoles({ roles: params.roles, filteredRoles: defaultRoles }),
      });
    }
  }, [roles, params, setCurrentParams, defaultRoles]);

  useEffect(() => {
    const fetchData = async () => {
      setRoles(await getRoles());
    };
    fetchData();
  }, []);

  const openModal = (modalProp, userProp) => {
    setModal(modalProp);
    setUser(userProp);
  };

  const close = () => {
    setModal(null);
    setUser(getUser());
  };

  const handleSendReminder = async () => {
    await sendReminder(user.id);
    close();
  };

  const sendForgotPasswordEmail = async () => {
    await forgotPassword({ email: user.email });
    close();
  };

  const handleDisableUser = async () => {
    await deleteUser(user.id);
    close();
  };

  const handleEnableUser = async () => {
    await enableUser(user.id);
    close();
  };

  const getSystemUserEmails = async () => {
    const systemAdminRole = getRoleByName(Roles.SYSTEM_ADMIN, profile.profile);
    const response = await getUserList({
      roles: [systemAdminRole.id],
      noPaginate: 1,
    });
    return response && Array.isArray(response.data) ? response.data.map(({ email }) => email) : [];
  };

  const getSystemAdminRole = () =>
    roles ? roles.filter(({ name }) => name === Roles.SYSTEM_ADMIN) : [];

  const getInitialRoleData = () => {
    return {
      ...(user && user.email && { email: user.email }),
      ...(isSystemAdminPage && { roles: getSystemAdminRole().map(({ id }) => id) }),
    };
  };

  const deleteItem = async (id) => {
    await deleteUser(id);
  };

  const { hasRoleToCreate } = UserAccess.getHasRoles(profile.profile);

  const initialRoleData = getInitialRoleData();

  const additionalFilters = () =>
    !isSystemAdminPage ? (
      <RolesFilter
        roles={params.roles || []}
        onChange={(rolesParam, page) => setCurrentParams({ ...params, page, roles: rolesParam })}
        availableRoles={defaultRoles}
      />
    ) : null;

  const filterData = [
    {
      label: <FormattedMessage id="USERS.ROLES_LABEL" />,
      key: 'roles',
      options: roles,
      chosenOptions: params.roles,
      required: true,
    },
    {
      label: <FormattedMessage id="USERS.GROUPS_LABEL" />,
      emptyMessage: <FormattedMessage id="USERS.GROUPS_EMPTY" />,
      key: 'groups',
      options: [],
      chosenOptions: params.groups,
    },
  ];
  const { title, addRowLabel, deleteTitle } = titles[isSystemAdminPage ? 'admin' : 'default'];
  return (
    <CrudPage
      title={<FormattedMessage id={title} />}
      addRow={() => openModal(modalType.ROLES, {})}
      addRowLabel={<FormattedMessage id={addRowLabel} />}
      deleteTitle={<FormattedMessage id={deleteTitle} />}
      setCurrentParams={setCurrentParams}
      deleteItem={deleteItem}
      onDelete={changeParams}
      params={params}
      filterBySearchText
      initialData={user}
      filter={changeParams}
      count={count}
      total={total}
      isLoading={isLoading}
      filterModal={!isSystemAdminPage}
      deleteKey="email"
      hasRoleToCreate={hasRoleToCreate}
      additionalFilters={additionalFilters}
      filterData={filterData}>
      {({ data: thisData, isEditorOpen, openDeleteModal, openEditModal, closeModal }) => {
        const resetActiveFilter = async () => {
          setUserIds([]);
          removeHistoryState(history, 'userIds');
          changeParamsAndResetPage();
        };

        const availableRoles = isSystemAdminPage ? getSystemAdminRole() : assignableRoles;
        const existingEmails = isSystemAdminPage ? getSystemUserEmails : null;
        const showCompanySubsidiaryChooser = !isSystemAdminPage;
        const userDataEditorRoles = isSystemAdminPage ? getSystemAdminRole() : roles;
        const isActive = userIds && userIds.length > 0;

        return (
          <div>
            <ActiveFilterIndicator isActive={isActive} resetFilter={resetActiveFilter} />
            <UsersTable
              isSystemAdminPage={isSystemAdminPage}
              profile={profile}
              list={userList}
              availableRoles={availableRoles}
              existingEmails={existingEmails}
              onSaved={changeParams}
              openEditModal={openEditModal}
              openDeleteModal={(user) => openDeleteModal(user)}
              sendReminder={(user) => openModal(modalType.REMINDER, user)}
              sendForgotPasswordEmail={(user) => openModal(modalType.FORGOT_PASSWORD, user)}
              openEnableModal={(user) => openModal(modalType.ENABLE, user)}
              openDisableModal={(user) => openModal(modalType.DISABLE, user)}
              count={count}
              currentPage={params.page}
              params={params}
              loadPage={(page) => changeParams(page)}
              loadList={changeParams}
              loading={isLoading}
            />
            <UserDataEditor
              showCompanySubsidiaryChooser={showCompanySubsidiaryChooser}
              open={isEditorOpen}
              userId={thisData.id}
              onSaved={changeParams}
              roles={userDataEditorRoles}
              closeEditor={closeModal}
            />
            <UserRoleEditor
              open={modal === modalType.ROLES}
              closeEditor={close}
              initialRoleData={initialRoleData}
              existingEmails={existingEmails}
              availableRoles={availableRoles}
              onSaved={changeParams}
              showCompanySubsidiaryChooser={showCompanySubsidiaryChooser}
            />
            <UserConfirmModal
              modal={modal}
              sendReminder={handleSendReminder}
              sendForgotPasswordEmail={sendForgotPasswordEmail}
              disableUser={handleDisableUser}
              enableUser={handleEnableUser}
              closeModal={close}
              email={user.email}
            />
          </div>
        );
      }}
    </CrudPage>
  );
};

UsersPage.propTypes = {
  profile: PropTypes.object.isRequired,
  getCompanies: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
