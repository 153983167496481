import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { CheckBox } from 'components';

function OnlyInTitleFilter({ onlyInTitles, onChange, intl: { formatMessage } }) {
  return (
    <div className="ms-5 mb-3 d-flex align-items-center">
      <CheckBox
        label={formatMessage({ id: 'COURSES.ONLY_IN_TITLES' })}
        checked={!!onlyInTitles}
        onChange={(e) => onChange(e.target.checked)}
      />
    </div>
  );
}

OnlyInTitleFilter.propTypes = {
  onlyInTitles: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(OnlyInTitleFilter);
