import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

const sizeClasses = { small: 'button-wrapper--small', medium: 'button-wrapper--medium' };

const Button = ({
  children,
  loading = false,
  className = 'btn-primary',
  size = 'medium',
  lessBorder,
  savingContent = children,
  fullWidth,
  ...props
}) => {
  return (
    <button
      className={classNames('button-wrapper btn', className, sizeClasses[size], {
        'button-wrapper--less-border': lessBorder,
        'button-wrapper--full-width': fullWidth,
      })}
      {...props}>
      {loading ? savingContent : children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  lessBorder: PropTypes.bool,
  savingContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default Button;
