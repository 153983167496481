import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withCustomIconStyling from './withCustomIconStyling';

import './style.scss';

const CheckIcon = ({ className, ...props }) => {
  return <i className={classNames('far fa-check-square', className)} {...props} />;
};

CheckIcon.propTypes = {
  className: PropTypes.string,
};

export default withCustomIconStyling(CheckIcon);
