import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ForgotPasswordForm from './ForgotPasswordForm';
import LoginContainer from './LoginContainer/LoginContainer';

import './style.scss';

const LoginPage = ({ showLoader, hideLoader, login, forgotPassword, token }) => {
  const [openForgotPasswordModal, setOpenForgomPasswordModal] = useState(false);

  const handleLogin = async (credentials) => {
    try {
      showLoader();
      await login(credentials);
    } finally {
      hideLoader();
    }
  };

  const sendForgotPassword = async (email) => {
    await forgotPassword(email);

    setOpenForgomPasswordModal(false);
  };

  return (
    <div className="login-page">
      <div className="h-100 row g-0">
        <div className="login-page__login-container-col col-lg-4 col-6">
          <LoginContainer
            className="login-page__login-container "
            onForgotPasswordClick={() => setOpenForgomPasswordModal(true)}
            onLoginSubmit={handleLogin}
            showError={token.error}
          />
        </div>
        <div className="col-lg-8 col-6">
          <div className="login-page__image" />
        </div>
      </div>

      <ForgotPasswordForm
        onSubmit={sendForgotPassword}
        open={openForgotPasswordModal}
        closeModal={() => setOpenForgomPasswordModal(false)}
      />
    </div>
  );
};

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
};

export default injectIntl(LoginPage);
