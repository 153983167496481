import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CrudPage } from 'components';
import LessonAuditTable from './LessonAuditTable';
import { hideLoader, showLoader } from 'actions/common';
import LessonForm from './LessonForm';
import { auditDecision } from 'libs/auditDecision';
import { getLesson } from 'services/lessons';
import { getImages } from 'util/imagesMapper';
import { usersMapper } from 'util/transferMapper';
import { mapToAssignedUsers } from 'util/assignUserAndGroupHelper';
import { getMyLessons, detachLesson, validateLesson } from 'services/audit';

const emptyParams = {};

const rejectedDecisionKey =
  Object.values(auditDecision).find(({ id }) => +id === +auditDecision.REJECTED.id)?.key || '';

const getDecisionKey = (lessonObj) =>
  lessonObj?.latestAction?.action === auditDecision.REJECTED.id ? rejectedDecisionKey : '';

const preloadLesson = ({ lesson = {}, lessonObj = {} }) => ({
  ...lesson,
  images: lesson.images ? getImages(lesson) : null,
  users: lesson.users ? mapToAssignedUsers(usersMapper(lesson), lesson.groups || []) : [],
  tags: lesson.tags ? lesson.tags.map(({ title, id }) => ({ label: title, value: id })) : [],
  auditorDescription: lessonObj?.latestAction?.description || '',
  lessonTypeId: lessonObj?.lessonType?.id || 1,
  decision: getDecisionKey(lessonObj),
});

const AuditPage = ({ showLoader, hideLoader }) => {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadList = useCallback(async () => {
    try {
      setLoading(true);
      showLoader();
      setLessons(await getMyLessons());
    } finally {
      setLoading(false);
      hideLoader();
    }
  }, [showLoader, hideLoader]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const loadLessonData = useCallback(
    async (lessonObj) => {
      try {
        showLoader();
        const lesson = await getLesson(lessonObj.id);
        if (lessonObj?.latestAction?.action === auditDecision.REJECTED.id) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }

        setSelectedLesson({ lesson, lessonObj });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader]
  );

  const validate = async (values) => {
    await validateLesson(values.id, values.decision === 'Accept', values.auditorDescription);
    await loadList();
  };

  const detach = async (values) => {
    await detachLesson(values.id);
    await loadList();
  };

  const submit = (values, subsidiaryId, secondarySubmit) => {
    if (!secondarySubmit) {
      validate(values);
    } else {
      detach(values);
    }
  };

  const initialValues = useMemo(
    () => preloadLesson({ lesson: selectedLesson?.lesson, lessonObj: selectedLesson?.lessonObj }),
    [selectedLesson]
  );

  return (
    <CrudPage
      title={<FormattedMessage id="LESSONS.AUDIT" />}
      isLoading={loading}
      setCurrentParams={loadList}
      hideAdd
      hidePreHeader>
      {({ isEditorOpen, openEditModal, closeModal }) => {
        const openValidateModal = (lessonObj) => {
          loadLessonData(lessonObj);
          openEditModal();
        };

        return (
          <>
            <LessonForm
              open={isEditorOpen}
              closeEditor={closeModal}
              lesson={initialValues}
              submit={submit}
              disabled={disabled}
              hasRoleToDecision
              saveButton
            />
            <LessonAuditTable
              list={lessons}
              params={emptyParams}
              loadList={loadList}
              openEditModal={openValidateModal}
            />
          </>
        );
      }}
    </CrudPage>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

AuditPage.propTypes = {
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(AuditPage);
