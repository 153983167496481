import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { templateLevel, templateTypes } from 'libs/template';
import { TableContainer, DeleteControl, CheckBox } from 'components';
import { widthOptions, horizontalAlignOptions } from 'components/Table/tableConstants';

const SpecificsTable = ({
  list,
  disabled,
  openRemoveModal,
  openEditModal,
  handleOrderChange,
  intl: { formatMessage },
}) => {
  const getTypeAsString = (specific) => {
    const type = Object.values(templateTypes).find(({ id }) => id === specific.type);
    return type ? type.label : '';
  };

  const getLevelAsString = (specific) => {
    const level = Object.values(templateLevel).find(({ id }) => id === specific.level);
    return level ? level.label : '';
  };

  const getCheckBox = (show) => (
    <div className="checkbox-container">
      <CheckBox checked={show} readOnly disabled />
    </div>
  );

  const getDeleteControl = (specific, index) => (
    <DeleteControl
      show={!disabled}
      enabled={!disabled}
      item={specific}
      deleteItem={() => openRemoveModal(index)}
    />
  );

  return (
    <TableContainer
      className="specifics-table"
      recordKey="tempId"
      list={list}
      sortable
      afterSort={(specifics) => handleOrderChange(specifics)}
      columns={[
        {
          key: 'edit',
          hide: disabled,
          iconCell: true,
          onClick: (_, index) => openEditModal(index),
          editIcon: true,
          width: widthOptions.xs,
        },
        {
          key: 'key',
          label: formatMessage({ id: 'TEMPLATES.KEY' }),
        },
        {
          key: 'type',
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'TEMPLATES.TYPE' }),
          render: (specific) => getTypeAsString(specific),
        },
        {
          key: 'searchable',
          horizontalAlign: horizontalAlignOptions.center,
          label: formatMessage({ id: 'TEMPLATES.SEARCHABLE' }),
          render: (specific) => getCheckBox(specific && specific.isSearchable),
        },
        {
          key: 'level',
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'TEMPLATES.LEVEL' }),
          render: (specific) => getLevelAsString(specific),
        },
        {
          key: 'is_generate',
          onlyShowHeaderTooltipOnOverflow: false,
          headerTooltip: `${formatMessage({ id: 'TEMPLATES.IS_GENERATE_G' })}${formatMessage({
            id: 'TEMPLATES.IS_GENERATE',
          })}`,
          label: formatMessage({ id: 'TEMPLATES.IS_GENERATE_G' }),
          render: (specific) => getCheckBox(specific && specific.isGenerate),
        },
        {
          key: 'is_show',
          onlyShowHeaderTooltipOnOverflow: false,
          headerTooltip: `${formatMessage({ id: 'TEMPLATES.IS_SHOW_S' })}${formatMessage({
            id: 'TEMPLATES.IS_SHOW',
          })}`,
          label: formatMessage({ id: 'TEMPLATES.IS_SHOW_S' }),
          render: (specific) => getCheckBox(specific && specific.isShow),
        },
        {
          key: 'is_general',
          onlyShowHeaderTooltipOnOverflow: false,
          headerTooltip: `${formatMessage({ id: 'TEMPLATES.IS_GENERAL_A' })}${formatMessage({
            id: 'TEMPLATES.IS_GENERAL',
          })}`,
          label: formatMessage({ id: 'TEMPLATES.IS_GENERAL_A' }),
          render: (specific) => getCheckBox(specific && specific.isGeneral),
        },
        {
          key: 'delete',
          render: (specific, index) => getDeleteControl(specific, index),
        },
      ]}
    />
  );
};

SpecificsTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  openRemoveModal: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  handleOrderChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(SpecificsTable);
