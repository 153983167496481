import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { TableContainer, IconButton } from 'components';
import { widthOptions } from 'components/Table/tableConstants';
import { getExpirationDate } from '../../../libs/companyUtils';

const RelatedCoursesTable = ({
  list,
  goToCourse,
  openDeleteModal,
  hasRoleToGoToCourse,
  hasRoleToDelete,
  intl: { formatMessage },
}) => {
  return (
    <TableContainer
      list={list}
      columns={[
        {
          key: 'name',
          label: formatMessage({ id: 'RELATED_COURSES.NAME' }),
          width: widthOptions.lg,
        },
        {
          key: 'owner',
          label: formatMessage({ id: 'RELATED_COURSES.OWNER' }),
          width: widthOptions.lg,
        },
        {
          key: 'assignDate',
          label: formatMessage({ id: 'RELATED_COURSES.DATE' }),
          width: widthOptions.lg,
          render: (course) => getExpirationDate(course.assignDate),
        },
        {
          key: 'goto',
          label: formatMessage({ id: 'RELATED_COURSES.GO' }),
          iconCell: true,
          render: (course) =>
            hasRoleToGoToCourse(course) && (
              <IconButton
                iconClass={'fa fa-arrow-circle-right'}
                onClick={() => goToCourse(course)}
              />
            ),
        },
        {
          key: 'delete',
          label: formatMessage({ id: 'RELATED_COURSES.REMOVE' }),
          iconCell: true,
          render: (course) =>
            hasRoleToDelete(course) && (
              <IconButton
                iconClass={'lemon icon-trash dark-gray'}
                onClick={() => openDeleteModal(course)}
              />
            ),
        },
      ]}
    />
  );
};

RelatedCoursesTable.propTypes = {
  list: PropTypes.array.isRequired,
  goToCourse: PropTypes.func,
  openDeleteModal: PropTypes.func,
  hasRoleToGoToCourse: PropTypes.func,
  hasRoleToDelete: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(RelatedCoursesTable);
