import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { Field } from 'formik';

import {
  CheckBoxField,
  ImageFileUploadField,
  IconButton,
  SelectField,
  TextAreaField,
  TextField,
  DragHandle,
} from 'components';
import {
  templateLevel,
  templateTypes,
  notAllowedToGenerate,
  notAllowedToGeneral,
  notAllowedToShow,
  notAllowedToSearch,
} from 'libs/template';

import './style.scss';

const requiredFieldIndicator = ' *';

const LessonsSortableElement = React.forwardRef(
  (
    {
      attributes,
      listeners,
      style,
      specific,
      removeSpecific,
      openModal,
      disabledForm,
      className,
      itemIndex,
      intl: { formatMessage },
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={classNames(
          'col-12 p-0 d-flex flex-wrap lesson-specifics-table__body',
          className,
          {
            'align-items-center': specific.type !== templateTypes.LONGTEXT.id,
          }
        )}>
        <div className="col lesson-specifics-table__action-cell">
          <DragHandle attributes={attributes} listeners={listeners} />
        </div>
        {!disabledForm ? (
          <div className="col lesson-specifics-table__action-cell">
            <IconButton iconClass="lemon icon-edit purple" onClick={() => openModal(itemIndex)} />
          </div>
        ) : null}
        <div className="col lesson-specifics-table__wide-cell me-3">
          {specific.type === templateTypes.SHORTTEXT.id ||
          specific.type === templateTypes.VIDEO.id ? (
            <Field
              component={TextField}
              name={`specifics[${itemIndex}]value`}
              label={specific.key + requiredFieldIndicator}
              disabled={disabledForm}
            />
          ) : specific.type === templateTypes.LONGTEXT.id ? (
            <Field
              component={TextAreaField}
              name={`specifics[${itemIndex}]value`}
              label={specific.key + requiredFieldIndicator}
              disabled={disabledForm}
            />
          ) : (
            <Field
              component={ImageFileUploadField}
              name={`specifics[${itemIndex}]value`}
              disabled={disabledForm}
              disabledClick={disabledForm}
            />
          )}
        </div>
        <div className="col checkbox-cell-with-label lesson-specifics-table__small-select-cell lesson-specifics-table__small-select-cell--select-control me-3">
          {!notAllowedToSearch(specific.type) ? (
            <Field
              component={CheckBoxField}
              name={`specifics[${itemIndex}]isSearchable`}
              label={formatMessage({ id: 'TEMPLATES.SEARCHABLE' })}
              disabled={disabledForm || notAllowedToSearch(specific.type)}
            />
          ) : null}
        </div>
        <div className="col lesson-specifics-table__small-select-cell lesson-specifics-table__small-select-cell--select-control">
          <Field
            component={SelectField}
            name={`specifics[${itemIndex}]level`}
            label={formatMessage({ id: 'TEMPLATES.LEVEL' })}
            disabled={disabledForm}
            useOnlyValueAsFieldValue
            options={
              templateLevel
                ? Object.values(templateLevel).map(({ id, label }) => ({
                    label: label,
                    value: id,
                  }))
                : []
            }
          />
        </div>
        <div className="col d-flex justify-content-center lesson-specifics-table__action-cell">
          <Field
            component={CheckBoxField}
            name={`specifics[${itemIndex}]isGenerate`}
            disabled={disabledForm || notAllowedToGenerate(specific.type)}
          />
        </div>
        <div className="col d-flex justify-content-center lesson-specifics-table__action-cell">
          <Field
            component={CheckBoxField}
            name={`specifics[${itemIndex}]isShow`}
            disabled={disabledForm || notAllowedToShow(specific.type)}
          />
        </div>
        <div className="col d-flex justify-content-center lesson-specifics-table__action-cell">
          <Field
            component={CheckBoxField}
            name={`specifics[${itemIndex}]isGeneral`}
            disabled={disabledForm || notAllowedToGeneral(specific.type)}
          />
        </div>
        <div
          className={classNames('col lesson-specifics-table__action-cell', {
            invisible: disabledForm,
          })}>
          <IconButton iconClass="lemon icon-trash" onClick={() => removeSpecific(itemIndex)} />
        </div>
      </div>
    );
  }
);

LessonsSortableElement.propTypes = {
  specific: PropTypes.object,
  removeSpecific: PropTypes.func,
  openModal: PropTypes.func,
  disabledForm: PropTypes.bool,
  className: PropTypes.string,
  itemIndex: PropTypes.number,
  intl: PropTypes.object,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  style: PropTypes.object,
};

export default injectIntl(LessonsSortableElement, { forwardRef: true });
