import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelectFieldValues } from '../SelectField/useSelectOptionField';
import { TypeSubTypeChooser } from 'components';

const getValueKey = ({ optionAsObject, optionKey, value }) =>
  optionAsObject ? value?.[optionKey] : value;

const TypeSubTypeChooserComplexField = ({
  typeKey,
  typeInputName,
  typeValueList,
  typeOptionAsObject,
  getTypeOptionLabel,
  isTypeValueDisabled,
  onlyEnabledTypes,
  subTypeKey,
  subTypeInputName,
  subTypeOptionAsObject,
  getSubTypeOptionLabel,
  isSubTypeValueDisabled,
  onlyEnabledSubTypes,
  loadSubtypesByTypeKey,
  hasErrorComponent = true,
  ...props
}) => {
  const [availableSubTypes, setAvailableSubTypes] = useState([]);

  const loadSubtypeOptionsByTypeKey = useCallback(
    (value) =>
      loadSubtypesByTypeKey(
        getValueKey({ value, optionAsObject: typeOptionAsObject, optionKey: typeKey })
      ),
    [typeOptionAsObject, typeKey, loadSubtypesByTypeKey]
  );

  const {
    selectedValue: selectedTypeValue,
    setSelectedValue: setSelectedTypeValue,
    options: typeOptions,
    isOptionDisabled: isTypeOptionDisabled,
    hasErrorMessage: hasTypeErrorMessage,
    fieldError: typeFieldError,
  } = useSelectFieldValues({
    fieldName: typeInputName,
    values: typeValueList,
    optionKey: typeKey,
    optionAsObject: typeOptionAsObject,
    onlyEnabledOptions: onlyEnabledTypes,
    getOptionLabel: getTypeOptionLabel,
    isValueDisabled: isTypeValueDisabled,
    multi: false,
  });

  const {
    selectedValue: subTypeSelectedValue,
    setSelectedValue: setSelectedSubTypeValue,
    options: subTypeOptions,
    isOptionDisabled: isSubsidiaryOptionDisabled,
    hasErrorMessage: hasSubsidiaryErrorMessage,
    fieldError: subsidiaryFieldError,
  } = useSelectFieldValues({
    fieldName: subTypeInputName,
    values: availableSubTypes,
    optionKey: subTypeKey,
    optionAsObject: subTypeOptionAsObject,
    onlyEnabledOptions: onlyEnabledSubTypes,
    getOptionLabel: getSubTypeOptionLabel,
    isValueDisabled: isSubTypeValueDisabled,
    multi: false,
  });

  return (
    <TypeSubTypeChooser
      typeOptions={typeOptions}
      typeKey={typeKey}
      subTypeKey={subTypeKey}
      subTypeOptions={subTypeOptions}
      onTypeChange={setSelectedTypeValue}
      onSubTypeChange={setSelectedSubTypeValue}
      setSubTypeOptions={setAvailableSubTypes}
      selectedType={selectedTypeValue}
      selectedSubType={subTypeSelectedValue}
      loadSubtypeOptionsByTypeId={loadSubtypeOptionsByTypeKey}
      isTypeOptionDisabled={isTypeOptionDisabled}
      isSubTypeOptionDisabled={isSubsidiaryOptionDisabled}
      hasTypeErrorMessage={hasTypeErrorMessage}
      typeErrorMessage={typeFieldError}
      subTypeErrorMessage={subsidiaryFieldError}
      hasSubTypeErrorMessage={hasSubsidiaryErrorMessage}
      hasErrorComponent={hasErrorComponent}
      {...props}
    />
  );
};

TypeSubTypeChooserComplexField.propTypes = {
  isLoading: PropTypes.bool,
  typeKey: PropTypes.string,
  typeInputName: PropTypes.string,
  typeValueList: PropTypes.array,
  typeOptionAsObject: PropTypes.bool,
  getTypeOptionLabel: PropTypes.func,
  isTypeValueDisabled: PropTypes.func,
  onlyEnabledTypes: PropTypes.bool,
  subTypeKey: PropTypes.string,
  subTypeInputName: PropTypes.string,
  subTypeOptionAsObject: PropTypes.bool,
  getSubTypeOptionLabel: PropTypes.func,
  isSubTypeValueDisabled: PropTypes.func,
  onlyEnabledSubTypes: PropTypes.bool,
  loadSubtypesByTypeKey: PropTypes.func.isRequired,
  hasErrorComponent: PropTypes.bool,
};

export default TypeSubTypeChooserComplexField;
