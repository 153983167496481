import { Yup } from 'util/Yup';
import { getMessageForImageMinError } from 'libs/validation/yupValidation';
import { getValidationMessageInput, notEmptyOrZero } from 'libs/validation/validation';
import { isCompanyEnabled } from 'libs/validation/company/validation';
import { CourseAccess } from 'libs/accessManagement';

export const initialCourse = {
  acceptanceRate: 30,
  author: '',
  deadline: 0,
  examQuestionCount: 1,
  images: [],
  imagesFull: [],
  isAdvert: false,
  isImportant: false,
  lessons: [],
  price: 0,
  public: false,
  title: '',
  users: [],
  groups: [],
  companyId: null,
  subsidiary: null,
  subsidiaryId: null,
  requirement: '',
  whatDoYouLearn: '',
};

export const preloadSaveData = (values) => {
  return {
    ...values,
    groups: values?.groups.map((group) => group.id || group) || [],
    subsidiaryId: values?.subsidiary?.id || null,
    companyId: values?.subsidiary?.companies?.id || null,
  };
};

const inputLimits = {
  minPrice: 0,
  minExamQuestionCount: 1,
  maxExamQuestionCount: 1000,
  minAcceptanceRate: 30,
  maxAcceptanceRate: 100,
  minDeadline: 0,
  maxDeadline: 100,
  maxTitle: 255,
  maxWhatDoYouLearn: 1500,
  maxRequirement: 1500,
  minImageCount: 1,
  minLessonCount: 1,
};

export const validationSchema = Yup.object().shape({
  images: Yup.array().min(
    inputLimits.minImageCount,
    getMessageForImageMinError(inputLimits.minImageCount)
  ),
  title: Yup.string()
    .required()
    .max(
      inputLimits.maxTitle,
      getValidationMessageInput('COMMON.FIELD_MAX_LENGTH_ERROR', {
        max: inputLimits.maxTitle,
      })
    ),
  subsidiary: Yup.object()
    .nullable()
    .required()
    .test(
      'validateCompanyEnabled',
      getValidationMessageInput('COMMON.FIELD_SUBSIDIARY_DISABLED_OR_NOT_FOUND'),
      function (value) {
        return isCompanyEnabled(value);
      }
    ),
  company: Yup.object().nullable().required(),
  price: Yup.number()
    .nullable()
    .test('isPriceNeeded', getValidationMessageInput('COMMON.FIELD_REQUIRED'), function (value) {
      return this.parent.isAdvert || !this.parent.public ? true : notEmptyOrZero(value);
    })
    .typeError(getValidationMessageInput('COMMON.FIELD_NUMBER_ERROR'))
    .min(
      inputLimits.minPrice,
      getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', {
        min: inputLimits.minPrice,
      })
    ),
  examQuestionCount: Yup.number()
    .typeError(
      getValidationMessageInput('COMMON.RANGE_NUMBER_BETWEEN_ERROR', {
        min: inputLimits.minExamQuestionCount,
        max: inputLimits.maxExamQuestionCount,
      })
    )
    .min(
      inputLimits.minExamQuestionCount,
      getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', {
        min: inputLimits.minExamQuestionCount,
      })
    )
    .max(
      inputLimits.maxExamQuestionCount,
      getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', {
        max: inputLimits.maxExamQuestionCount,
      })
    )
    .required(),
  acceptanceRate: Yup.number()
    .typeError(
      getValidationMessageInput('COMMON.RANGE_NUMBER_BETWEEN_ERROR', {
        min: inputLimits.minAcceptanceRate,
        max: inputLimits.maxAcceptanceRate,
      })
    )
    .min(
      inputLimits.minAcceptanceRate,
      getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', {
        min: inputLimits.minAcceptanceRate,
      })
    )
    .max(
      inputLimits.maxAcceptanceRate,
      getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', {
        max: inputLimits.maxAcceptanceRate,
      })
    )
    .required(),
  public: Yup.boolean().required(),
  isAdvert: Yup.boolean().required(),
  deadline: Yup.number()
    .typeError(
      getValidationMessageInput('COMMON.RANGE_NUMBER_BETWEEN_ERROR', {
        min: inputLimits.minDeadline,
        max: inputLimits.maxDeadline,
      })
    )
    .min(
      inputLimits.minDeadline,
      getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', {
        min: inputLimits.minDeadline,
      })
    )
    .max(
      inputLimits.maxDeadline,
      getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', {
        max: inputLimits.maxDeadline,
      })
    )
    .required(),
  whatDoYouLearn: Yup.string()
    .nullable()
    .max(
      inputLimits.maxWhatDoYouLearn,
      getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', {
        max: inputLimits.maxWhatDoYouLearn,
      })
    ),
  requirement: Yup.string().max(
    inputLimits.maxRequirement,
    getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', {
      max: inputLimits.maxRequirement,
    })
  ),
  lessons: Yup.array()
    .required(getValidationMessageInput('LESSONS.NEED_TO_ASSIGN'))
    .min(
      inputLimits.minLessonCount,
      getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', {
        min: inputLimits.minLessonCount,
      })
    ),
  groups: Yup.array(),
});

export const isAssignLessonDisabled = ({ disabled, profile, isEdit, course }) => {
  if (disabled) {
    return true;
  }
  if (isEdit) {
    return !CourseAccess.getHasAccess(profile.profile, course).canAssignLesson;
  }
  return !CourseAccess.getHasRoles(profile.profile).hasRoleToCreate;
};

export const isBaseDataDisabled = ({ disabled, profile, isEdit, course }) => {
  if (disabled) {
    return true;
  }
  if (isEdit) {
    return !CourseAccess.getHasAccess(profile.profile, course).canEdit;
  }

  return !CourseAccess.getHasRoles(profile.profile).hasRoleToCreate;
};

export const isAssignGroupsAndUsersDisabled = ({ disabled, profile }) => {
  if (disabled) {
    return true;
  }

  return !CourseAccess.getHasRoles(profile.profile).hasRoleToAssignUserOrGroup;
};

export const canSubmitForm = ({ profile, isEdit, course }) => {
  const { hasRoleToAssignUserOrGroup, hasRoleToCreate } = CourseAccess.getHasRoles(profile.profile);

  if (isEdit) {
    return (
      CourseAccess.getHasAccess(profile.profile, course).canEdit ||
      hasRoleToAssignUserOrGroup ||
      CourseAccess.getHasAccess(profile.profile, course).canAssignLesson
    );
  }

  return hasRoleToCreate;
};
