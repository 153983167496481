import React, { useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { FormattedMessage } from 'react-intl';

import { createGroup, editGroup, getGroup } from 'services/groups';
import { UpdateForm, AssignCourse, AssignLesson, AssignUser, TabsContainer } from 'components';
import { Yup } from 'util/Yup';
import CompanyGroupBaseDataTab from './SubsidiaryGroupBaseDataTab';
import { hideLoader, showLoader } from 'actions/common';
import { SubsidiaryAccess } from 'libs/accessManagement';

const SubsidiaryGroupEditor = ({
  profile,
  groupId,
  groupSubsidiaryId,
  closeEditor,
  loadList,
  showLoader,
  hideLoader,
  open,
}) => {
  const [group, setGroup] = useState({
    name: '',
    description: '',
    company: null,
    subsidiary: groupSubsidiaryId ? { id: groupSubsidiaryId } : null,
  });
  const [tabIndex, setTabIndex] = useState(0);

  const isEdit = !!groupId;
  const showCompanySubsidiaryChooser = !groupSubsidiaryId && !isEdit;

  const loadGroup = useCallback(async () => {
    if (groupId) {
      try {
        showLoader();
        setGroup(await getGroup(groupId));
      } finally {
        hideLoader();
      }
    }
  }, [hideLoader, groupId, showLoader]);

  useEffect(() => {
    loadGroup();
  }, [loadGroup]);

  const getTabs = ({ isValid }) => {
    const {
      GroupAccess: { hasRoleToAssignCourse, hasRoleToAssignLesson, hasRoleToAssignUser },
    } = SubsidiaryAccess.getHasRoles(profile.profile);

    const isTabHidden = (hasRole) => !isEdit || !hasRole;

    return [
      {
        id: 'base-data',
        label: <FormattedMessage id="LESSONS.BASE_LESSON_DATA_TAB" />,
        component: (
          <CompanyGroupBaseDataTab showCompanySubsidiaryChooser={showCompanySubsidiaryChooser} />
        ),
        hasError: !isValid,
      },
      {
        id: 'courses',
        label: <FormattedMessage id="COURSES.TITLE" />,
        component: <AssignCourse fetchData={open} />,
        hide: isTabHidden(hasRoleToAssignCourse),
      },
      {
        id: 'lessons',
        label: <FormattedMessage id="LESSONS.TITLE" />,
        component: <AssignLesson fetchData={open} />,
        hide: isTabHidden(hasRoleToAssignLesson),
      },
      {
        id: 'assigns',
        label: <FormattedMessage id="COURSES.ASSIGNS" />,
        component: <AssignUser fetchData={open} />,
        hide: isTabHidden(hasRoleToAssignUser),
      },
    ].map((tab) => ({ ...tab, forceRender: true }));
  };

  const getSaveValues = (values) => ({
    group: values.name,
    groupDescription: values.description,
    users: values.users ? values.users.map((user) => user.id) : [],
    lessons: values.lessons ? values.lessons.map((lesson) => lesson.id) : [],
    courses: values.courses ? values.courses.map((course) => course.id) : [],
  });

  const update = useCallback(
    async (values) => {
      const saveValues = getSaveValues(values);
      await editGroup(saveValues, groupId);
      await loadList(groupSubsidiaryId);
    },
    [loadList, groupId, groupSubsidiaryId]
  );

  const create = useCallback(
    async ({ subsidiary, ...values }) => {
      const saveValues = getSaveValues(values);
      await createGroup(saveValues, subsidiary.id);
      await loadList(groupSubsidiaryId);
    },
    [loadList, groupSubsidiaryId]
  );

  const close = useCallback(() => {
    setTabIndex(0);
    closeEditor();
  }, [closeEditor]);

  const title = group.id ? (
    <FormattedMessage id="GROUPS.EDIT" />
  ) : (
    <FormattedMessage id="GROUPS.ADD" />
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    company: showCompanySubsidiaryChooser
      ? Yup.object().nullable().required()
      : Yup.object().nullable(),
    subsidiary: showCompanySubsidiaryChooser
      ? Yup.object().nullable().required()
      : Yup.object().nullable(),
    description: Yup.string(),
    courses: Yup.array(),
    lessons: Yup.array(),
    users: Yup.array(),
  });

  return (
    <UpdateForm
      open={open}
      closeEditor={close}
      update={update}
      create={create}
      modalTitle={title}
      initialValues={group}
      validateOnBlur={false}
      validationSchema={validationSchema}
      tabsInModal>
      {(fomrikProps) => {
        const tabList = getTabs(fomrikProps);
        return (
          <>
            <div className="spacer" />
            <Form className="course-form">
              <TabsContainer
                tabList={tabList}
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              />
            </Form>
          </>
        );
      }}
    </UpdateForm>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

SubsidiaryGroupEditor.propTypes = {
  profile: PropTypes.object.isRequired,
  groupId: PropTypes.number,
  groupSubsidiaryId: PropTypes.number,
  closeEditor: PropTypes.func.isRequired,
  loadList: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubsidiaryGroupEditor);
