import request from '../libs/request';
import { Methods } from '../libs/http';

const BASE_URL = '/terms';

export const getLatestAgreements = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/latest`,
  });
};
