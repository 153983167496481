import React from 'react';
import PropTypes from 'prop-types';
import { ComposedChart, ResponsiveContainer } from 'recharts';

import useChartElements from '../useChartElements';
import withChartDataCheck from '../withChartDataCheck';
import { defaultChartMargin } from '../chartUtil';
import { basicChartProps, chartBarProps, chartAreaProps } from 'common/propTypes';

import '../style.scss';

const CompositeAreaBarChart = ({
  data,
  areas: areasProp,
  bars: barsProp,
  xAxisDataKey,
  xAxisPadding,
  yAxes,
  height,
  margin,
  hideLegend,
  tooltipBorderColor,
  ...props
}) => {
  const { areas, bars, commonChartElements } = useChartElements({
    xAxisDataKey,
    xAxisPadding,
    tooltipBorderColor,
    hideLegend,
    areas: areasProp,
    bars: barsProp,
    chartData: data,
    yAxes,
  });

  return (
    <ResponsiveContainer width={'100%'} height={height} {...props}>
      <ComposedChart data={data} margin={margin ? margin : defaultChartMargin} {...props}>
        {commonChartElements}
        {bars}
        {areas}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

CompositeAreaBarChart.propTypes = {
  ...basicChartProps,
  areas: PropTypes.arrayOf(chartAreaProps).isRequired,
  bars: PropTypes.arrayOf(chartBarProps).isRequired,
  tooltipBorderColor: PropTypes.string,
};

export default withChartDataCheck(CompositeAreaBarChart);
