import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './style.module.scss';

const WithLabel = ({ children, label, className, childrenClass, labelClass }) => {
  return (
    <label className={classNames(styles.WithLabel, className)}>
      <span className={classNames(styles.ChildrenWrapper, childrenClass)}>{children}</span>
      {label && <span className={classNames(styles.LabelText, labelClass, 'ms-3')}>{label}</span>}
    </label>
  );
};

WithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  className: PropTypes.string,
  childrenClass: PropTypes.string,
  labelClass: PropTypes.string,
};

export default WithLabel;
