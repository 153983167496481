import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ComponentWithLabel } from 'components';

import styles from './style.module.scss';

const CheckBox = ({ checked, onChange, disabled, className, boxClass, ...props }) => {
  return (
    <ComponentWithLabel className={className} disabled={disabled} {...props}>
      <input
        className={styles.CheckBoxInput}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span className={classNames(styles.Box, boxClass)}>
        <span className={classNames(styles.Tick, { [styles.checked]: checked })}>
          <i className="lemon icon-check" />
        </span>
      </span>
    </ComponentWithLabel>
  );
};

CheckBox.propTypes = {
  label: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  tooltipLabel: PropTypes.string,
  alwaysShowTooltip: PropTypes.bool,
  className: PropTypes.string,
  boxClass: PropTypes.string,
};

export default CheckBox;
