import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { wordCloudExpression } from 'common/propTypes';
import { SearchIcon, Popover, WordCloudContainer } from 'components';

import {
  expressionWordCloudIconColor,
  expressionWordWordCloudIconBackgroundColor,
  expressionWordCloudTextColor,
} from '../../../styles/_colors.scss';

import './style.scss';

const ExpressionsWordCloud = ({ words, intl: { formatMessage } }) => {
  const title = formatMessage({ id: 'MEMBERS_STATISTICS.EXPRESSION_WORDCLOUD_TITLE' });

  return (
    <WordCloudContainer
      words={(words || []).map(({ word, count }) => ({ text: `#${word}`, value: count }))}
      emptyMessage={formatMessage({ id: 'MEMBERS_STATISTICS.EXPRESSION_WORDCLOUD_NO_WORDS' })}
      textColors={[expressionWordCloudTextColor]}
      titleIcon={
        <SearchIcon
          color={expressionWordCloudIconColor}
          backgroundColor={expressionWordWordCloudIconBackgroundColor}
        />
      }
      title={
        <Popover content={title} oneLine placement="top" trigger="hover">
          <div className="tags-word-cloud-title">{title}</div>
        </Popover>
      }
    />
  );
};

ExpressionsWordCloud.propTypes = { words: PropTypes.arrayOf(wordCloudExpression) };

export default injectIntl(ExpressionsWordCloud);
