import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ContentTrendIndicator from './ContentTrendIndicator';

import './style.scss';

const ContentLabel = ({ baseData, intl: { formatMessage } }) => {
  const { title, ...props } = baseData || {};
  return (
    <div className="container">
      <div className="row">
        <div className="content-label-title col-12 col-lg-7">
          <div className="content-label-title-text">
            {title || formatMessage({ id: 'COMMON.NO_DATA' })}
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <ContentTrendIndicator {...props} />
        </div>
      </div>
    </div>
  );
};

ContentLabel.propTypes = { baseData: PropTypes.object };

export default injectIntl(ContentLabel);
