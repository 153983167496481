import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withLoading } from 'hoc';
import {
  ChartDetailContainer,
  IconChartDetailItem,
  ClickableArrowIcon,
  QuestionIcon,
  CheckIcon,
} from 'components';

import {
  totalCheckColor,
  totalCheckBackgroundColor,
  successCheckColor,
  successCheckBackgroundColor,
  failedCheckColor,
  failedCheckBackgroundColor,
} from '../../../styles/_colors.scss';
import './style.scss';

const questionTypes = { SUCCESS: 'success', FAILED: 'failed' };

const ContentStatisticsChartDetail = ({ chartDetail, intl: { formatMessage } }) => {
  const [questionType, setQuestionType] = useState(questionTypes.SUCCESS);
  const [activeQuestions, setActiveQuestions] = useState([]);

  useEffect(() => {
    const { successDetail, failedDetail } = chartDetail || {};
    setActiveQuestions(
      questionType === questionTypes.SUCCESS
        ? successDetail && successDetail.questions
        : questionType === questionTypes.FAILED
        ? failedDetail && failedDetail.questions
        : []
    );
  }, [chartDetail, questionType]);

  const getPercentValue = (value) =>
    value || value === 0 ? Math.floor(value) + '%' : formatMessage({ id: 'COMMON.NO_DATA' });

  const getValue = (value) =>
    value || value === 0 ? value : formatMessage({ id: 'COMMON.NO_DATA' });

  const totalCheckIcon = (
    <CheckIcon backgroundColor={totalCheckBackgroundColor} color={totalCheckColor} />
  );

  const successCheckIcon = (
    <CheckIcon backgroundColor={successCheckBackgroundColor} color={successCheckColor} />
  );

  const failedCheckIcon = (
    <CheckIcon backgroundColor={failedCheckBackgroundColor} color={failedCheckColor} />
  );

  const { successDetail, failedDetail, totalDetail } = chartDetail || {};
  const { value: successValue, percent: successPercent } = successDetail || {};
  const { value: failedValue, percent: failedPercent } = failedDetail || {};

  return (
    <ChartDetailContainer
      hideTitleUnderline
      titleLabel={formatMessage({ id: 'CONTENT_STATISTICS.NUM_OF_TESTS' })}>
      <div className="content-statistics-chart-detail-container">
        <IconChartDetailItem
          firstIcon={totalCheckIcon}
          label={formatMessage({ id: 'CHART_DETAIL.TOTAL' })}
          value={getValue(totalDetail && totalDetail.value)}
        />
        <IconChartDetailItem
          firstIcon={successCheckIcon}
          label={formatMessage({ id: 'CHART_DETAIL.SUCCESS' })}
          value={`${getValue(successValue)} - ${getPercentValue(successPercent)}`}
          lastIcon={
            <ClickableArrowIcon
              color={successCheckColor}
              onClick={() => setQuestionType(questionTypes.SUCCESS)}
            />
          }
        />
        <IconChartDetailItem
          firstIcon={failedCheckIcon}
          label={formatMessage({ id: 'CHART_DETAIL.FAILED' })}
          value={`${getValue(failedValue)} - ${getPercentValue(failedPercent)}`}
          lastIcon={
            <ClickableArrowIcon
              color={failedCheckColor}
              onClick={() => setQuestionType(questionTypes.FAILED)}
            />
          }
          noDivider
        />
        <div className="content-statistics-chart-detail-divider" />
        <div className="pb-2">
          <IconChartDetailItem
            firstIcon={
              questionType === questionTypes.SUCCESS
                ? successCheckIcon
                : questionType === questionTypes.FAILED
                ? failedCheckIcon
                : null
            }
            label={
              questionType === questionTypes.SUCCESS
                ? formatMessage({ id: 'CHART_DETAIL.SUCCESS_QUESTIONS' })
                : questionType === questionTypes.FAILED
                ? formatMessage({ id: 'CHART_DETAIL.FAILED_QUESTIONS' })
                : null
            }
          />
        </div>

        <div className="content-statistics-chart-detail-questions-container">
          {(activeQuestions || []).map(({ id, name, rate }) => (
            <div key={id} className="me-1">
              <IconChartDetailItem
                firstIcon={
                  questionType === questionTypes.SUCCESS ? (
                    <QuestionIcon
                      color={successCheckColor}
                      backgroundColor={successCheckBackgroundColor}
                    />
                  ) : questionType === questionTypes.FAILED ? (
                    <QuestionIcon
                      color={failedCheckColor}
                      backgroundColor={failedCheckBackgroundColor}
                    />
                  ) : null
                }
                label={name}
                value={`${
                  rate || rate === 0 ? rate + '%' : formatMessage({ id: 'COMMON.NO_DATA' })
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </ChartDetailContainer>
  );
};

ContentStatisticsChartDetail.propTypes = {
  intl: PropTypes.object,
  chartDetail: PropTypes.shape({
    totalDetail: PropTypes.shape({
      value: PropTypes.number,
    }),
    successDetail: PropTypes.shape({
      value: PropTypes.number,
      percent: PropTypes.number,
      questions: PropTypes.arrayOf(
        PropTypes.shape({ question: PropTypes.string, percent: PropTypes.number })
      ),
    }),
    failedDetail: PropTypes.shape({
      value: PropTypes.number,
      percent: PropTypes.number,
      questions: PropTypes.arrayOf(
        PropTypes.shape({ question: PropTypes.string, percent: PropTypes.number })
      ),
    }),
  }),
};

export default withLoading(injectIntl(ContentStatisticsChartDetail));
