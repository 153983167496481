export const arrayContainsArray = (superset, subset) => {
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0;
  });
};

export const difference = (
  minuend = [],
  subtrahend = [],
  isEqual = (minuendItem, subtrahendItem) => minuendItem === subtrahendItem
) => minuend.filter((item) => !subtrahend.find((subtrahendItem) => isEqual(item, subtrahendItem)));
