import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextAreaInput } from 'components';
import { getFieldError, handleFieldChange, hasFieldError } from '../formUtil';

const TextAreaField = ({ form, field, hasErrorComponent = true, hideValue, ...props }) => {
  const hasErrorMessage = hasFieldError({ form, field });
  const handleSetValue = useCallback(handleFieldChange({ form, field }), [form, field]);

  const handleTextAreaChange = (e) => handleSetValue(e.target.value);

  return (
    <TextAreaInput
      hideValue={hideValue}
      value={field.value}
      onChange={handleTextAreaChange}
      hasErrorComponent={hasErrorComponent}
      hasErrorMessage={hasErrorMessage}
      errorMessage={getFieldError({ form, field })}
      {...props}
    />
  );
};

TextAreaField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  hasErrorComponent: PropTypes.bool,
  hideValue: PropTypes.bool,
};

export default TextAreaField;
