import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { Form, Field } from 'formik';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { TextField, Button, RegistrationPage } from 'components';
import { getValidationMessageInput } from 'libs/validation/validation';
import { resetPassword } from '../../actions/forgotPassword';
import Settings from '../../config/settings';
import { Yup } from 'util/Yup';

import './style.scss';

const ResetPasswordPage = ({ resetPassword }) => {
  const { search } = useLocation();
  const initialValues = { password: '' };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(Settings.PASSWORD_MIN_LENGTH, getValidationMessageInput('COMMON.FIELD_PASSWORD_ERROR'))
      .matches(Settings.PASSWORD_PATTERN, getValidationMessageInput('COMMON.FIELD_PASSWORD_ERROR'))
      .required(),
  });

  const onSubmit = async ({ password }) => {
    return await resetPassword({
      token: qs.parse(search, Settings.QUERY_FORMAT).token,
      password,
    });
  };

  return (
    <RegistrationPage
      formClassName="forgot-password-page__registration-form"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      title={<FormattedMessage id="FORGOT_PASSWORD.CREATE_PASS" />}
      content={
        <Form>
          <Field
            component={TextField}
            name="password"
            label={<FormattedMessage id="FORGOT_PASSWORD.NEW_PASS" />}
            type="password"
            autoComplete="new-password"
          />
          <Button className="forgot-password-page__forgot-button btn" type="submit">
            <FormattedMessage id="FORGOT_PASSWORD.CREATE" />
          </Button>
        </Form>
      }
      label={<FormattedMessage id="FORGOT_PASSWORD.SUCCESSFUL_REQUEST" />}
      adminPageText={
        <>
          <FormattedMessage id="CONTINUE_REGISTRATION.LOGIN_HERE" />
          <br />
          <a href={Settings.PUBLIC_URL}>{Settings.PUBLIC_URL}</a>
        </>
      }
      userPageText={<FormattedMessage id="CONTINUE_REGISTRATION.LOGIN_IN_THE_MOBILE_APP" />}
    />
  );
};

ResetPasswordPage.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ resetPassword }, dispatch);
};

export default connect(null, mapDispatchToProps)(ResetPasswordPage);
