import { createShallowMergedObject } from 'util/objectUtil';
import { getDateFromString } from 'util/timeUtil';
import { getDateString } from 'libs/strings';
import { Roles } from 'libs/roles';
import { UserAccess, isProfileUserId } from 'libs/accessManagement';
import { getRequestDataForImage } from 'util/imagesMapper';
import { Yup } from 'util/Yup';
import { isLegalDrinkingAgeInCountry, isStateRequired } from 'libs/validation/user/validation';
import { getValidationMessageInput } from 'libs/validation/validation';
import Settings from 'config/settings';

export const emptyAddressData = { zip: '', country: '', state: '' };
export const emptyCorporateData = {
  company: '',
  subsidiary: '',
  department: '',
  role: '',
  location: '',
  employmentStatus: '',
  employmentStartDate: null,
};

const getCorporate = (corporate, isSLTAdmin) => {
  if (!isSLTAdmin && corporate) {
    return convertResponseCorporateDataToFormCorporateData(corporate);
  }
  return { ...emptyCorporateData };
};

export const preLoadUser = (user = {}) => {
  const isSLTAdmin = isUserSLTAdmin(user);
  return {
    ...user,
    avatar: user?.avatar ? { url: user.avatar } : null,
    lastName: user?.lastName || '',
    firstName: user?.firstName || '',
    email: user?.email || '',
    dateOfBirth: user?.dateOfBirth ? getDateFromString(user?.dateOfBirth) : null,
    employeeBookNumber: user?.employeeBookNumber || '',
    sanitaryBookExpire: user?.sanitaryBookExpire
      ? getDateFromString(user.sanitaryBookExpire)
      : null,
    phoneNumber: user?.phoneNumber || '',
    address: user?.address
      ? { ...user.address, state: user.address.state ? user.address.state : '' }
      : { ...emptyAddressData },
    corporate: getCorporate(user?.corporate, isSLTAdmin),
  };
};

export const convertResponseCorporateDataToFormCorporateData = (corporateData) => ({
  ...createShallowMergedObject(emptyCorporateData, corporateData),
  employmentStartDate: corporateData?.employmentStartDate
    ? getDateFromString(corporateData?.employmentStartDate)
    : null,
});

export const convertFormCorporateDataToRequestCorporateData = ({
  company,
  subsidiary,
  employmentStartDate,
  ...remCorporateData
}) => ({
  ...(company && company.id && { companyId: company.id }),
  ...(subsidiary && subsidiary.id && { subsidiaryId: subsidiary.id }),
  ...(employmentStartDate && { employmentStartDate: getDateString(employmentStartDate) }),
  ...remCorporateData,
});

export const isCompanyAndSubsidiaryDisabled = (data) => {
  return data.corporate ? !!(data.corporate.company || data.corporate.subsidiary) : false;
};

export const isProfileUser = (initialUserData, profile) => {
  return initialUserData.id ? isProfileUserId(profile.profile, initialUserData.id) : false;
};

export const hasRoleToEditUserProfile = (profile, initialUserData) => {
  const { hasRoleToEdit } = UserAccess.getHasRoles(profile.profile);
  return isProfileUser(initialUserData, profile) || hasRoleToEdit;
};

export const isUserSLTAdmin = (user) => {
  return user && user.roles ? !!user.roles.find((role) => role.name === Roles.SYSTEM_ADMIN) : false;
};

export const preloadEditData = async (values, initialUserData) => {
  const { avatar, dateOfBirth, sanitaryBookExpire, bloodType, corporate, ...remValues } = values;
  const isSLTAdmin = isUserSLTAdmin(initialUserData);
  let user = {};
  if (dateOfBirth) {
    user['birthDate'] = getDateString(dateOfBirth);
  }

  if (sanitaryBookExpire) {
    user['sanitaryBookExpire'] = getDateString(sanitaryBookExpire);
  } else {
    user['sanitaryBookExpire'] = null;
  }
  if (bloodType) {
    user['bloodTypeId'] = bloodType.id;
  }
  if (!isSLTAdmin && corporate) {
    user['corporate'] = convertFormCorporateDataToRequestCorporateData(corporate);
  }
  user['avatar'] = await getRequestDataForImage({
    image: avatar,
    getDataForExistingImage: (image) => image?.url,
    getDataForBrowsedImage: (image) => image,
  });

  delete remValues.workplaces;
  delete remValues.avatarFull;

  return { ...remValues, ...user };
};

export const validationSchema = (isSLTAdmin, provinces) =>
  Yup.object().shape({
    avatar: Yup.mixed().nullable(),
    email: Yup.string().email().required(),
    lastName: Yup.string().required(),
    firstName: Yup.string().required(),
    phoneNumber: Yup.string().matches(
      new RegExp(Settings.PHONE_PATTERN),
      getValidationMessageInput('COMMON.FIELD_PHONE_ERROR')
    ),
    dateOfBirth: Yup.date()
      .required()
      .nullable()
      .test(
        'validateCountrySelected',
        getValidationMessageInput('COMMON.FIELD_COUNTRY_NEEDED'),
        function () {
          return this.parent.address && this.parent.address.country;
        }
      )
      .test('validateAge', getValidationMessageInput('COMMON.FIELD_AGE_ERROR'), function (value) {
        return isLegalDrinkingAgeInCountry(provinces, value, this.parent.address, {
          country: 'country',
          state: 'state',
        });
      }),
    employeeBookNumber: Yup.string().nullable(),
    sanitaryBookExpire: Yup.date().nullable(),
    address: Yup.object({
      zip: Yup.string().nullable(),
      country: Yup.string().required(),
      state: Yup.string()
        .nullable()
        .test(
          'validateState',
          getValidationMessageInput('COMMON.FIELD_STATE_NEEDED'),
          function (value) {
            return isStateRequired(provinces, this.parent.country, value);
          }
        ),
    }).nullable(),
    ...(!isSLTAdmin && {
      corporate: Yup.object({
        company: Yup.object().nullable().required(),
        subsidiary: Yup.object().nullable().required(),
        department: Yup.string().nullable(),
        role: Yup.string().nullable(),
        location: Yup.string().nullable(),
        employment_status: Yup.string().nullable(),
        employement_start: Yup.date().nullable(),
      }),
    }),
    groups: Yup.array().nullable(),
  });
