import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const IconButton = React.forwardRef(
  ({ className, iconNode, iconClass, theme, disabled, ...props }, ref) => {
    return (
      <span className={classNames({ wrapper: disabled })}>
        <button
          ref={ref}
          type="button"
          className={classNames('IconButton', className, theme, {
            disabled,
          })}
          {...props}>
          {iconNode}
          {!iconNode && iconClass && <i className={iconClass} />}
        </button>
      </span>
    );
  }
);

IconButton.propTypes = {
  className: PropTypes.string,
  iconClass: PropTypes.string,
  iconNode: PropTypes.node,
  theme: PropTypes.oneOf(['light', 'dark']),
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  theme: 'dark',
};

export default IconButton;
