import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'components/IconButton';

const MaskToggle = ({ onClick, className }) => {
  return (
    <IconButton
      className={className}
      type="button"
      iconClass="far fa-eye"
      onClick={onClick}
      theme="dark"
    />
  );
};

MaskToggle.propTypes = {
  show: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default MaskToggle;
