import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import LoginHeader from '../LoginHeader/LoginHeader';
import LoginForm from '../LoginForm/LoginForm';

import './style.scss';

const LoginContainer = ({
  className,
  onForgotPasswordClick,
  onLoginSubmit,
  showError,
  intl: { formatMessage },
}) => {
  return (
    <div className={classNames('login-container', className)}>
      <LoginHeader />
      <div className="mt-3">
        <LoginForm onSubmit={onLoginSubmit} showError={showError} />
        <div className="login-container__forgot-password" onClick={onForgotPasswordClick}>
          {formatMessage({ id: 'COMMON.FORGOT_PASSWORD' })}
        </div>
      </div>
    </div>
  );
};

LoginContainer.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onLoginSubmit: PropTypes.func.isRequired,
  showError: PropTypes.bool,
};

export default injectIntl(LoginContainer);
