import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Popover } from 'components';

import './style.scss';

const Tag = ({ text, popoverContent, color, borderColor, backgroundColor, fullWidth }) => {
  return (
    <div
      className={classNames('tag-wrapper', { fullWidth })}
      style={{
        backgroundColor: backgroundColor,
        color,
        borderColor: borderColor || backgroundColor,
      }}>
      <Popover content={popoverContent || text} oneLine onOverflow placement="top" trigger="hover">
        {text || ''}
      </Popover>
    </div>
  );
};

Tag.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  popoverContent: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default Tag;
