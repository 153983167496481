import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import IndicatorImage from './IndicatorImage';

import './style.scss';

const Indicator = ({ type, label, value, onFilterClick, active }) => {
  return (
    <div
      className={classNames(
        `${type.label} indicator px-0`,
        {
          'indicator--has-click': onFilterClick,
        },
        active && `indicator--active-${type.label}`
      )}
      onClick={() => onFilterClick && onFilterClick(type.value)}>
      <IndicatorImage type={type} />
      <div className={`indicator__colored-strip indicator__colored-strip--${type.label}`} />
      <div className="indicator__text">
        <div className="indicator__label">
          <FormattedMessage id={label} />
        </div>
        <div className="indicator__value">{value}</div>
      </div>
    </div>
  );
};

Indicator.propTypes = {
  type: PropTypes.object,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  onFilterClick: PropTypes.func,
  active: PropTypes.bool,
};

export default Indicator;
