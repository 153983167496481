import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';

import {
  PathCourse,
  PathLesson,
  PathSectionMarker,
  AbstractPathDnDContainer,
  ConfirmModal,
  TextField,
} from 'components';
import AddSectionMarker from './AddSectionMarker';
import { Yup } from 'util/Yup';

const PathDnDContainer = ({
  pathItems,
  setPathItems,
  hasErrorMessage,
  intl: { formatMessage },
}) => {
  const [open, setOpen] = useState(false);
  const [handleAdd, setHandleAdd] = useState();

  const onAddSectionMarkerClick = useCallback((onAdd) => {
    setOpen(true);
    setHandleAdd(() => onAdd);
  }, []);

  const resetValues = {
    values: {
      sectionMarker: '',
    },
  };

  const onModalSave = useCallback(
    ({ sectionMarker }, { resetForm }) => {
      if (handleAdd) {
        handleAdd(sectionMarker);
        setOpen(false);
        resetForm(resetValues);
      }
    },
    [handleAdd, resetValues]
  );

  const renderAddSectionMarker = useCallback(
    ({ onAdd }) => {
      return <AddSectionMarker onClick={() => onAddSectionMarkerClick(onAdd)} />;
    },
    [onAddSectionMarkerClick]
  );

  const renderSectionMarker = useCallback(
    (item, deleteItem, attributes, listeners) => (
      <PathSectionMarker
        name={item.name}
        deleteItem={deleteItem}
        dndAttributes={attributes}
        dndListeners={listeners}
      />
    ),
    []
  );

  const renderCourse = useCallback(
    (item, deleteItem, attributes, listeners) => (
      <PathCourse
        title={item.title}
        lessons={item.lessons}
        deleteItem={deleteItem}
        dndAttributes={attributes}
        dndListeners={listeners}
      />
    ),
    []
  );

  const renderLesson = useCallback(
    (item, deleteItem, attributes, listeners) => (
      <PathLesson
        title={item.title}
        deleteItem={deleteItem}
        dndAttributes={attributes}
        dndListeners={listeners}
      />
    ),
    []
  );

  const closeModal = (formikProps) => {
    formikProps.resetForm(resetValues);
    setOpen(false);
  };

  const handleKeyDown = (e, formikProps) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formikProps.handleSubmit();
    }
  };

  return (
    <div>
      <AbstractPathDnDContainer
        pathItems={pathItems}
        setPathItems={setPathItems}
        showError={hasErrorMessage}
        renderAddSectionMarker={renderAddSectionMarker}
        renderSectionMarker={renderSectionMarker}
        renderCourse={renderCourse}
        renderLesson={renderLesson}
      />
      <Formik
        onSubmit={onModalSave}
        initialValues={{ sectionMarker: '' }}
        validateOnBlur={false}
        enableReinitialize
        validationSchema={Yup.object().shape({
          sectionMarker: Yup.string().required(),
        })}>
        {(formikProps) => {
          return (
            <Form>
              <ConfirmModal
                open={open}
                onClose={() => {
                  closeModal(formikProps);
                }}
                title={'Name the marker'}
                saveAction={formikProps.handleSubmit}
                confirmElement={
                  <Field
                    name="sectionMarker"
                    component={TextField}
                    label={formatMessage({ id: 'COMMON.TITLE' })}
                    autoFocus
                    onKeyDown={(e) => {
                      handleKeyDown(e, formikProps);
                    }}
                  />
                }
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

PathDnDContainer.propTypes = {
  setPathItems: PropTypes.func,
  pathItems: PropTypes.array,
  hasErrorMessage: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(PathDnDContainer);
