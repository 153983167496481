import { useMemo } from 'react';

import { getCommonChartElements, getAreas, getBars } from './chartUtil';

const useChartElements = ({
  xAxisDataKey,
  xAxisPadding,
  tooltipBorderColor,
  hideLegend,
  areas: areasProp,
  bars: barsProp,
  chartData,
  yAxes,
}) => {
  const commonChartElements = useMemo(
    () =>
      getCommonChartElements({
        xAxisDataKey,
        xAxisPadding,
        tooltipBorderColor,
        hideLegend,
        yAxes,
        yAxisElements: [...(barsProp ? barsProp : []), ...(areasProp ? areasProp : [])],
      }),
    [xAxisDataKey, xAxisPadding, tooltipBorderColor, hideLegend, areasProp, barsProp, yAxes]
  );

  const areas = useMemo(() => getAreas(areasProp, chartData), [areasProp, chartData]);
  const bars = useMemo(() => getBars(barsProp, chartData), [barsProp, chartData]);

  return { areas, bars, commonChartElements };
};

export default useChartElements;
