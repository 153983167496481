import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { AreaChart, ChartContainer } from 'components';
import SecondaryChartDetail from './SecondaryChartDetail';
import { getChartLabelTraslateNameId } from 'util/chartRenderUtil';
import { secondaryChartConstants } from './chartConstants';

const height = 400;

const CompanySubsidiarySecondaryChart = ({ loading, chart, intl: { formatMessage }, ...props }) => {
  const { chartData, chartDetail } = chart || {};
  const { chartAreas, xAxisDataKey, yAxes: yAxesData } = secondaryChartConstants;

  const getAreas = () => {
    return (chartAreas || []).map(({ key, color, ...props }) => ({
      ...props,
      dataKey: key,
      stroke: color,
      name: getChartLabelTraslateNameId(key)
        ? formatMessage({ id: getChartLabelTraslateNameId(key) })
        : key,
    }));
  };

  const yAxes = (yAxesData || []).map(({ labelTranslateId, ...props }) => ({
    label: formatMessage({ id: labelTranslateId }),
    ...props,
  }));

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-9 p-2">
          <ChartContainer
            chartTitle={
              <FormattedMessage id="COMPANY_SUBSIDIARY_STATISTICS.SECONDARY_CHART_TITLE" />
            }
            disabledTimeFilter
            loading={loading}
            {...props}>
            <AreaChart
              loading={loading}
              height={height}
              areas={getAreas()}
              data={chartData}
              xAxisDataKey={xAxisDataKey}
              yAxes={yAxes}
            />
          </ChartContainer>
        </div>
        <div className="col-12 col-lg-3 p-2">
          <SecondaryChartDetail
            loading={loading}
            titleValue={chartDetail && chartDetail.value}
            chartDetail={chartDetail}
          />
        </div>
      </div>
    </div>
  );
};

CompanySubsidiarySecondaryChart.propTypes = {
  loading: PropTypes.bool,
  chart: PropTypes.shape({
    chartData: PropTypes.arrayOf(PropTypes.object),
    chartDetail: PropTypes.object,
  }),
};

export default injectIntl(CompanySubsidiarySecondaryChart);
