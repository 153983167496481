export const createPathItemObject = (itemId, data, getItemId) => {
  return data.find((item) => getItemId(item) === itemId);
};

export const createNewArrayForRequest = (pathItems) => {
  const selectType = {
    lesson: (item, index) => {
      return {
        itemType: item.itemType.toUpperCase(),
        lessonId: item.lessonId,
        sort: index + 1,
      };
    },
    course: (item, index) => {
      return {
        itemType: item.itemType.toUpperCase(),
        courseId: item.courseId,
        sort: index + 1,
      };
    },
    marker: (item, index) => {
      return {
        itemType: item.itemType.toUpperCase(),
        name: item.name,
        sort: index + 1,
      };
    },
  };

  return pathItems.map((item, index) => {
    return selectType[item.itemType](item, index);
  });
};
