import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BootStrapModal from 'react-bootstrap/Modal';

import ModalHeader from './ModalHeader/ModalHeader';
import ModalFooter from './ModalFooter/ModalFooter';

import './style.scss';

const onCloseClick = ({ preventDismiss, onClose, unsavedChangesAction, unsavedChanges }) => {
  if (preventDismiss) {
    return unsavedChanges && typeof unsavedChanges === 'function' && unsavedChanges()
      ? unsavedChangesAction()
      : onClose();
  }
  return onClose();
};

const Modal = ({
  title = 'Modal',
  saveTitle = 'Save',
  closeTitle = 'Close',
  open = false,
  closeButton = true,
  saveButton = true,
  preventDismiss = false,
  saveAction,
  saveSecondaryAction,
  className,
  tabsInModal,
  maxHeight,
  shadowed,
  unsavedChanges,
  unsavedChangesAction,
  children,
  hideFooter,
  saveButtonSecondary,
  saveSecondaryTitle,
  large,
  loading: loadingProp,
  onClose,
  footerClass,
}) => {
  const [loading, setLoading] = useState(false);

  const isLoadingAnything = loading || loadingProp;

  const confirmAction = async (e, secondary) => {
    e.persist();
    try {
      setLoading(true);
      if (saveAction && !secondary) {
        await saveAction(e);
      }

      if (secondary && saveSecondaryAction) {
        await saveSecondaryAction(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const onCloseButtonClick = useCallback(
    () =>
      onCloseClick({
        preventDismiss,
        onClose,
        unsavedChanges,
        unsavedChangesAction,
      }),
    [preventDismiss, onClose, unsavedChanges, unsavedChangesAction]
  );

  return (
    <BootStrapModal
      className={classNames(className, {
        'modal--tabs-in-modal': tabsInModal,
        'modal--max-height': maxHeight,
        'modal--shadowed-modal': shadowed,
        'modal--without-footer': hideFooter,
      })}
      dialogClassName={classNames('my-modal-dialog', { 'modal-lg': large })}
      contentClassName="my-modal-content"
      show={open}>
      <ModalHeader
        title={title}
        closeIconDisabled={loading}
        showProgressBar={isLoadingAnything}
        onCloseIconClick={onCloseButtonClick}
      />
      <BootStrapModal.Body bsPrefix="my-modal-body">{children}</BootStrapModal.Body>
      {!hideFooter && (
        <ModalFooter
          className={footerClass}
          showCloseButton={closeButton}
          showSaveButton={saveButton}
          showSecondarySaveButton={saveButtonSecondary}
          areSaveButtonsDisabled={isLoadingAnything}
          areSaveButtonsLoading={isLoadingAnything}
          closeTitle={closeTitle}
          saveTitle={saveTitle}
          saveSecondaryTitle={saveSecondaryTitle}
          isCloseButtonDisabled={loading}
          onSaveButtonClick={confirmAction}
          onSecondarySaveButtonClick={(e) => confirmAction(e, true)}
          onCloseButtonClick={onCloseButtonClick}
        />
      )}
    </BootStrapModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  saveAction: PropTypes.func,
  saveSecondaryAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  saveTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  saveSecondaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  closeTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  closeButton: PropTypes.bool,
  saveButton: PropTypes.bool,
  saveButtonSecondary: PropTypes.bool,
  large: PropTypes.bool,
  preventDismiss: PropTypes.bool,
  unsavedChanges: PropTypes.func,
  unsavedChangesAction: PropTypes.func,
  className: PropTypes.string,
  tabsInModal: PropTypes.bool,
  maxHeight: PropTypes.bool,
  hideFooter: PropTypes.bool,
  footerClass: PropTypes.string,
  loading: PropTypes.bool,
  shadowed: PropTypes.bool,
};

export default Modal;
