import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Agreement, AgreementTypes, BasicPage } from 'components';

const TermsAndConditions = () => (
  <BasicPage title={<FormattedMessage id="TERMS_AND_CONDITIONS.TITLE" />} hidePreHeader>
    <Agreement type={AgreementTypes.TermsAndConditions} />
  </BasicPage>
);

export default TermsAndConditions;
