import React from 'react';
import PropTypes from 'prop-types';

import AssignUserNameLabel from './AssignUserNameLabel';

import './style.scss';

const AssignSourceUser = ({ firstName, lastName, email }) => {
  return (
    <div className="assign-user">
      <AssignUserNameLabel firstName={firstName} lastName={lastName} email={email} />
    </div>
  );
};

AssignSourceUser.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
};

export default AssignSourceUser;
