import { getHighestRole } from 'libs/roles';

export const isShownRoute = (route, roles) => {
  return (
    (route.showFor &&
      !!route.showFor.filter(
        (value) =>
          -1 !==
          (roles ? roles.map((role) => role.name.toLowerCase()).indexOf(value.toLowerCase()) : -1)
      ).length) ||
    !route.showFor
  );
};

export const getSpaceBeneathIndex = (routes, roles) =>
  routes
    .filter((route) => isShownRoute(route, roles))
    .findIndex((route) => route.path.endsWith('privacy-policy')) - 1;

export const getInitialRoute = (routes, profile) => {
  const route =
    routes.find(
      (route) =>
        isShownRoute(route, profile.roles) &&
        route.defaultFor &&
        route.defaultFor.includes(getHighestRole(profile))
    ) || routes.find((route) => isShownRoute(route, profile.roles) && !route.disableRedirect);
  return route && route.path;
};
