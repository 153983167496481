import React from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'components';

const OnSubmitValidationError = ({ callback, formik, shadowed = true }) => {
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const closeModal = () => {
    setOpenErrorModal(false);
  };

  const effect = () => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      if (callback) {
        callback(formik);
      }
      setOpenErrorModal(true);
    }
  };

  React.useEffect(effect, [formik.submitCount, formik.isSubmitting]);

  return (
    <Modal
      preventDismiss
      onClose={closeModal}
      shadowed={shadowed}
      className="error-modal"
      open={openErrorModal}
      title={<FormattedMessage id="COMMON.ERROR" />}
      saveButton={true}
      saveAction={closeModal}
      saveTitle={<FormattedMessage id="COMMON.OK" />}>
      <div className="default-modal-body-padding">
        <FormattedMessage id="COMMON.HAS_ERRORS" />
      </div>
    </Modal>
  );
};

OnSubmitValidationError.propTypes = {
  shadowed: PropTypes.bool,
  callback: PropTypes.func,
  formik: PropTypes.object.isRequired,
};

export default connect(OnSubmitValidationError);
