import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { forgotPassword, login } from '../../actions/users';
import { showLoader, hideLoader } from '../../actions/common';
import LoginPage from './LoginPage';

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ login, showLoader, hideLoader, forgotPassword }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
