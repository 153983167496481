import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { wordCloudTag } from 'common/propTypes';
import { HashtagIcon, WordCloudContainer, Popover } from 'components';

import {
  tagWordCloudIconColor,
  tagWordCloudIconBackgroundColor,
  tagWordCloudTextColor,
} from '../../../styles/_colors.scss';

import './style.scss';

const TagWordCloud = ({ tags, intl: { formatMessage } }) => {
  const title1 = formatMessage({ id: 'MEMBERS_STATISTICS.TAG_WORDCLOUD_TITLE_1' });
  const title2 = formatMessage({ id: 'MEMBERS_STATISTICS.TAG_WORDCLOUD_TITLE_2' });

  return (
    <WordCloudContainer
      words={(tags || []).map(({ title = '', count = 0 } = {}) => ({
        text: `#${title}`,
        value: count,
      }))}
      emptyMessage={formatMessage({ id: 'MEMBERS_STATISTICS.TAG_WORDCLOUD_NO_WORDS' })}
      textColors={[tagWordCloudTextColor]}
      titleIcon={
        <HashtagIcon
          color={tagWordCloudIconColor}
          backgroundColor={tagWordCloudIconBackgroundColor}
        />
      }
      title={
        <Popover content={`${title1} ${title2}`} oneLine placement="top" trigger="hover">
          <div className="tags-word-cloud-title">
            {title1}
            <br />
            {title2}
          </div>
        </Popover>
      }
    />
  );
};

TagWordCloud.propTypes = { tags: PropTypes.arrayOf(wordCloudTag) };

export default injectIntl(TagWordCloud);
