import React, { useState, useMemo } from 'react';
import { Resizable } from 're-resizable';
import ReactWordcloud from 'react-wordcloud';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

import {
  DEFAULT_MIN_FONT_SIZE,
  DEFAULT_MAX_FONT_SIZE,
  DEFAULT_WORD_CLOUD_HEIGHT,
  getDynamicFontSizes,
  commonWordCloudOptions,
  resizeStyle,
  defaultCallbacks,
} from './wordCloudUtil';

const ResizableWordCloud = ({ textColors, height, words, options, callbacks, ...props }) => {
  const [wordCloudFontSizes, setWordCloudFontSizes] = useState([
    DEFAULT_MIN_FONT_SIZE,
    DEFAULT_MAX_FONT_SIZE,
  ]);

  const wordCloudSize = useMemo(() => {
    return { width: 'auto', height: height || DEFAULT_WORD_CLOUD_HEIGHT };
  }, [height]);

  const wordCloudOptions = useMemo(() => {
    return {
      ...commonWordCloudOptions(textColors),
      fontSizes: wordCloudFontSizes,
    };
  }, [wordCloudFontSizes, textColors]);

  return (
    <ReactResizeDetector
      handleWidth
      onResize={(width) => setWordCloudFontSizes(getDynamicFontSizes(width))}>
      <Resizable defaultSize={wordCloudSize} style={resizeStyle}>
        <ReactWordcloud
          words={words}
          options={wordCloudOptions}
          callbacks={callbacks ? callbacks : defaultCallbacks}
          {...props}
        />
      </Resizable>
    </ReactResizeDetector>
  );
};

ResizableWordCloud.propTypes = {
  textColors: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  words: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string.isRequired, value: PropTypes.number.isRequired })
  ),
  callbacks: PropTypes.object,
  options: PropTypes.object,
};

export default ResizableWordCloud;
