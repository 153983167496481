import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Popover } from 'components';

import defaultLogo from 'assets/images/default-company-logo.png';

import './style.scss';

const TableCellAvatar = ({ src, tooltip, alt = 'image_not_found', errorImgSrc = defaultLogo }) => {
  const [imgUrl, setImgUrl] = useState(src);

  return (
    <Popover content={tooltip ? tooltip : ''} placement="top" oneLine trigger="hover">
      <img
        src={imgUrl}
        onError={() => setImgUrl(errorImgSrc)}
        className="basic-table-cell-avatar"
        alt={alt}
      />
    </Popover>
  );
};

TableCellAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  errorImgSrc: PropTypes.string,
  alt: PropTypes.string,
};

export default TableCellAvatar;
