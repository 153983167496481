import { combineReducers } from 'redux';

import common from './common';
import users from './users';
import lessons from './lessons';
import tags from './tags';
import courses from './courses';
import workplaces from './workplaces';
import jobs from './jobs';
import dashboard from './dashboard';
import audit from './audit';
import roles from './roles';
import provinces from './provinces';
import companies from './companies';
import priceRanges from './priceRanges';
import openings from './openings';
import groupTypes from './groupTypes';
import groups from './groups';
import subscriptions from './subscriptions';

const appReducer = combineReducers({
  common,
  users,
  lessons,
  tags,
  courses,
  workplaces,
  jobs,
  dashboard,
  audit,
  roles,
  provinces,
  companies,
  priceRanges,
  openings,
  groupTypes,
  groups,
  subscriptions,
});

export default (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};
