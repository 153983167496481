import request from '../libs/request';
import { Methods } from '../libs/http';
import Settings from '../config/settings';
import qs from 'query-string';

const BASE_URL = '/companies';

export const getCompanies = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}?${qs.stringify(params, Settings.QUERY_FORMAT)}`,
  });
};

export const getCompany = async (id) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${id}`,
  });
};

export const disableCompany = async (id) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}/disable`,
  });
};

export const enableCompany = async (id) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}/enable`,
  });
};

export const getCompanyTopData = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/top-data`,
  });
};

//THIS SERVICE IS NOT CALLED
export const getCompanyAdmins = async (companyId) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${companyId}/users`,
  });
};

export const deleteAdminFromCompany = async (companyId, userId) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${companyId}/users/${userId}`,
  });
};

export const getCompanyContacts = async (companyId) => {
  return await request({ method: Methods.GET, resource: `${BASE_URL}/${companyId}/contacts` });
};

export const addContactToCompany = async (companyId, data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${companyId}/contacts`,
    data,
  });
};

export const editContactAtCompany = async (companyId, contactId, data) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${companyId}/contacts/${contactId}`,
    data,
  });
};

export const deleteContactFromCompany = async (companyId, contactId) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${companyId}/contacts/${contactId}`,
  });
};

export const createCompany = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}`,
    data,
  });
};

export const deleteCompany = async (id) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${id}`,
  });
};

export const editCompany = async (data) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${data.id}`,
    data,
  });
};

export const saveCompanyLogo = async (companyId, data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${companyId}/logo`,
    data,
  });
};
