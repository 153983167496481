import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withEmptyMessage, withLoading } from 'hoc';
const withChartDataCheck = (Chart) => {
  const ChartWithEmptyMessage = withLoading(withEmptyMessage(Chart));

  const wrappedEmptyMessage = injectIntl((props) => {
    const {
      data,
      emptyMessage,
      intl: { formatMessage },
      ...remainingProps
    } = props;

    const defaultEmptyMessage = formatMessage({ id: 'COMMON.NO_CHART_DATA' });

    return (
      <ChartWithEmptyMessage
        isEmpty={!data || data.length === 0}
        data={data}
        emptyMessage={emptyMessage || defaultEmptyMessage}
        {...remainingProps}
      />
    );
  });

  wrappedEmptyMessage.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
  };

  return wrappedEmptyMessage;
};

export default withChartDataCheck;
