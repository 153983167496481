import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Popover } from 'components';
import WithLabel from './WithLabel';

import styles from './style.module.scss';

const ComponentWithLabel = ({
  tooltipLabel,
  className,
  disabled,
  hasHoverEffect = true,
  alwaysShowTooltip,
  ...props
}) => {
  const classes = classNames(styles.Wrapper, className, {
    [styles.disabled]: disabled,
    [styles.hasHoverEffect]: hasHoverEffect,
  });

  return tooltipLabel ? (
    <Popover
      className={classes}
      content={tooltipLabel}
      placement="top"
      trigger="hover"
      onOverflow
      always={alwaysShowTooltip}>
      <WithLabel {...props} />
    </Popover>
  ) : (
    <span className={classNames(classes, 'd-inline-block')}>
      <WithLabel labelClass={styles.HideOverflow} {...props} />
    </span>
  );
};

ComponentWithLabel.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radioButton']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  tooltipLabel: PropTypes.string,
  alwaysShowTooltip: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasHoverEffect: PropTypes.bool,
};

export default ComponentWithLabel;
