import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withCustomIconStyling from './withCustomIconStyling';
import { orientationOptions } from './iconConstants';

const ClickableArrowIcon = ({ orientation = orientationOptions.down, className, ...props }) => {
  const baseClassName = `fas fa-arrow-alt-circle-${orientation}`;
  return <i className={classNames(baseClassName, className)} {...props} />;
};

ClickableArrowIcon.propTypes = {
  orientation: PropTypes.oneOf(Object.values(orientationOptions)),
};

export default withCustomIconStyling(ClickableArrowIcon);
