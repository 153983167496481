import * as UserService from '../services/users';
import Settings from '../config/settings';
import { Roles } from 'libs/roles';
import store from '../config/store';
import { setToken } from '../libs/refreshToken';

import * as actionTypes from 'actions/action-types';
import { getRoles } from 'actions/roles';
import { getProvinces } from 'actions/provinces';

export const login = (credentials) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN,
    });

    try {
      const { access_token, expires_in } = await UserService.login(credentials);
      setToken({ token: access_token, expiresAt: expires_in });

      const user = await dispatch(getProfile());

      const hasRoleToLogin =
        user.roles.findIndex((role) => role.name.toLowerCase() !== Roles.USER) !== -1;

      if (!hasRoleToLogin) {
        dispatch(logout());
        throw new Error(Settings.NO_ACCESS);
      } else {
        dispatch({
          type: actionTypes.RESET_NOTIFICATIONS,
        });
      }

      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        payload: !hasRoleToLogin,
      });
    } catch (error) {
      const { response, message } = error;
      dispatch({
        type: actionTypes.LOGIN_FAILED,
        payload:
          response && response.data
            ? response.data.error || response.data.errors
            : message
            ? message
            : error,
      });
    }
  };
};

export const forgotPassword = (email) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FORGOT_PASSWORD,
    });

    try {
      const result = await UserService.forgotPassword({ email });

      dispatch({
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
      });

      return result;
    } catch (error) {
      dispatch({ type: actionTypes.GET_PROFILE_FAILED });
      throw error;
    }
  };
};

export const validateToken = () => {
  return async (dispatch) => {
    let token = localStorage.getItem(Settings.API_TOKEN);
    if (token) {
      await dispatch(getProfile());
    } else {
      dispatch(logout());
    }
  };
};

export const refreshToken = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LOGIN });

      const { access_token, expires_in } = await UserService.refreshToken(token);

      setToken({ token: access_token, expiresAt: expires_in });

      store.dispatch({
        type: actionTypes.LOGIN_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: actionTypes.LOGIN_FAILED });
      throw error;
    }
  };
};

export const getProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_PROFILE });

      let user = await UserService.getUser();

      await dispatch(getRoles());
      await dispatch(getProvinces());

      dispatch({
        type: actionTypes.GET_PROFILE_SUCCESS,
        payload: user,
      });

      return user;
    } catch (error) {
      dispatch({ type: actionTypes.GET_PROFILE_FAILED });
      throw error;
    }
  };
};

export const getUserById = (userId, subsidiaryId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_USER });

      let user = await UserService.getUserById(userId, subsidiaryId);

      dispatch({
        type: actionTypes.GET_USER_SUCCESS,
        payload: user,
      });

      return user;
    } catch (error) {
      dispatch({ type: actionTypes.GET_USER_FAILED });
      throw error;
    }
  };
};

export const logout = () => {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().users.token;
      if (token) {
        await UserService.logout(token);
      }
    } finally {
      localStorage.removeItem(Settings.API_TOKEN);
      localStorage.removeItem(Settings.API_TOKEN_EXPIRES_AT);
      localStorage.removeItem(Settings.REFRESH_TOKEN_IN_PROGRESS);
      dispatch({
        type: actionTypes.LOGOUT,
      });
    }
  };
};

export const resetLoginError = () => {
  return {
    type: actionTypes.RESET_LOGIN_ERROR,
  };
};

export const createAdmin = (adminData, isProfile) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.CREATE_ADMIN,
      });

      const user = await UserService.createAdmin(adminData);

      dispatch({
        type: actionTypes.CREATE_ADMIN_SUCCESS,
        payload: user,
        remove: !adminData.roles.length,
      });

      if (isProfile) {
        dispatch(getProfile());
      }

      return user;
    } catch (error) {
      dispatch({
        type: actionTypes.CREATE_ADMIN_FAILED,
        payload: error,
      });

      throw error;
    }
  };
};

export const setManagedSubsidiary = (selected) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_MANAGED_GROUP,
      payload: selected,
    });
  };
};

// Currently unused
export const getUsersByRole = (role, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_USERS_BY_ROLE,
      });

      const users = await UserService.getUsersByRole(role, page);

      dispatch({
        type: actionTypes.GET_USERS_BY_ROLE_SUCCESS,
        payload: users,
      });

      return users;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_USERS_BY_ROLE_FAILED,
        payload: error,
      });

      throw error;
    }
  };
};
