const hasSameKeyValue = ({ option = {}, value = {}, itemKey = 'id' }) =>
  value?.[itemKey] && option.value?.[itemKey] === value?.[itemKey];
const hasSameSimpleValue = ({ option = {}, value = '' }) => value === option.value;

const hasSameValue = ({ option, value, itemKey }) =>
  typeof value === 'object'
    ? hasSameKeyValue({ option, value, itemKey })
    : hasSameSimpleValue({ option, value });

const getMultiValue = ({ options = [], values = [], itemKey }) => {
  return values.reduce((acc, value) => {
    const valueInOptions = options.find((option) => hasSameValue({ option, value, itemKey }));

    return valueInOptions ? [...acc, valueInOptions] : acc;
  }, []);
};

const getSingleValue = ({ options = [], value, itemKey }) =>
  options.find((option) => hasSameValue({ option, value, itemKey })) || null;

const getOptionValue = ({ value, valueAsObject, itemKey }) =>
  valueAsObject ? value : value[itemKey];

export const getSelectValueFromOptions = ({ value, options, multi, itemKey = 'id' }) =>
  !multi
    ? getSingleValue({ options, value, itemKey })
    : getMultiValue({ options, values: value, itemKey });

export const isOptionDisabled = ({
  option = {},
  valueAsObject,
  itemKey,
  optionSourceList = [],
  isDisabled = (value) => value?.isDisabled,
}) => {
  const getValue = () => {
    const { value: optionValue } = option;

    return valueAsObject
      ? optionValue
      : optionSourceList.find((item) => item[itemKey] === optionValue);
  };

  return isDisabled(getValue());
};

export const getSelectOptions = ({
  values = [],
  itemKey = 'id',
  valueAsObject,
  getOptionLabel = (item) => item?.name,
}) =>
  values.map((item) => ({
    label: getOptionLabel(item),
    value: getOptionValue({ value: item, valueAsObject, itemKey }),
  }));
