import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UnmountClosed } from 'react-collapse';

import { IconButton, Spinner } from 'components';

import styles from './style.module.scss';

const Collapse = ({
  open,
  setOpen,
  children,
  headerClassName,
  titleClassName,
  className,
  loadData,
  title,
}) => {
  const [loading, setLoading] = useState(false);

  const handleLoading = useCallback(async () => {
    try {
      setLoading(true);

      if (loadData) {
        await loadData();
      }
    } finally {
      setLoading(false);
    }
  }, [loadData]);

  useEffect(() => {
    if (open) {
      handleLoading();
    }
  }, [open, handleLoading]);

  return (
    <>
      <div className={headerClassName}>
        <IconButton
          iconClass={classNames('lemon green', {
            'icon-arrow-up': open,
            'icon-arrow-down': !open,
          })}
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          className={classNames({ collapsed: !open })}
        />
        {title ? <span className={titleClassName ? titleClassName : 'ms-2'}>{title}</span> : null}
      </div>
      <UnmountClosed isOpened={open} theme={{ collapse: styles.collapse }}>
        <div className={classNames(className, { show: open })}>
          {loading ? (
            <div className="d-flex alig-items-center justify-content-center p-3">
              <Spinner />
            </div>
          ) : typeof children === 'function' ? (
            children({ shown: open })
          ) : (
            children
          )}
        </div>
      </UnmountClosed>
    </>
  );
};

Collapse.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  headerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  className: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.node.isRequired]),
  loadData: PropTypes.func,
  title: PropTypes.node,
};

export default Collapse;
