import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import {
  TableContainer,
  TableCellAvatar,
  EnableDisableControl,
  DeleteControl,
  EditOrReadControl,
} from 'components';
import SubsidiaryGroupsContainer from '../SubsidiaryGroupsContainer/SubsidiaryGroupsContainer';
import Settings from 'config/settings';
import {
  widthOptions,
  horizontalAlignOptions,
  verticalAlignOptions,
} from 'components/Table/tableConstants';

import { SubsidiaryAccess } from 'libs/accessManagement';
import { getNextLicenceExpirationFieldText } from 'libs/companyUtils';
import { canModifySomethingInSubsidiary } from '../../CompanyAndSubsidiaryEditor/companyUpdateFormUtil';

import defaultSubsidiaryLogo from 'assets/images/default-company-logo.png';

const getParentCompanyName = (subsidiary) => {
  return subsidiary.companies && subsidiary.companies.name ? subsidiary.companies.name : '-';
};

const SubsidiariesTable = ({
  loading,
  list,
  loadList,
  params,
  profile,
  openEditModal,
  openDeleteModal,
  enableSubsidiary,
  disableSubsidiary,
  intl: { formatMessage },
}) => {
  const {
    hasRoleToRead,
    hasRoleToEdit,
    hasRoleToDisable: hasRoleToEnable,
    hasRoleToDisable,
    hasRoleToDelete,
    GroupAccess: {
      hasRoleToRead: hasRoleToReadGroup,
      hasRoleToCreate: hasRoleToCreateGroup,
      hasRoleToEdit: hasRoleToEditGroup,
      hasRoleToDelete: hasRoleToDeleteGroup,
    },
  } = SubsidiaryAccess.getHasRoles(profile.profile);

  return (
    <TableContainer
      hideCollapse={(subsidiary) => !hasRoleToReadGroup || subsidiary.isDisabled}
      rowCollapseChildren={(item) => (open) => {
        return (
          <SubsidiaryGroupsContainer
            open={open}
            hasRoleToCreate={hasRoleToCreateGroup}
            hasRoleToEdit={hasRoleToEditGroup}
            hasRoleToDelete={hasRoleToDeleteGroup}
            subsidiaryId={item.id}
          />
        );
      }}
      loading={loading}
      list={list}
      loadList={loadList}
      params={params}
      columns={[
        {
          key: 'edit_or_read',
          hide: !(hasRoleToEdit || hasRoleToRead),
          render: (subsidiary) => (
            <EditOrReadControl
              canEdit={canModifySomethingInSubsidiary(profile.profile)}
              isEnabled={!subsidiary.isDisabled}
              openEditModal={() => openEditModal(subsidiary)}
            />
          ),
          minWidth: widthOptions.auto,
          maxWidth: widthOptions.xs,
        },
        {
          key: 'avatar',
          render: (subsidiary) => (
            <TableCellAvatar
              hasTooltip
              always
              src={subsidiary.logo || defaultSubsidiaryLogo}
              alt={'subsidiary_logo'}
              tooltip={subsidiary.name}
            />
          ),
        },
        {
          key: 'name',
          label: formatMessage({ id: 'COMMON.NAME' }),
          sortBy: Settings.SORT_BY.SUBSIDIARY_NAME,
        },
        {
          key: 'parent_company',
          label: formatMessage({ id: 'SUBSIDIARY.PARENT_COMPANY' }),
          sortBy: Settings.SORT_BY.PARENT_COMPANY_NAME,
          width: widthOptions.md,
          horizontalAlign: horizontalAlignOptions.left,
          verticalAlignOptions: verticalAlignOptions.center,
          render: (subsidiary) => getParentCompanyName(subsidiary),
          itemTooltip: (subsidiary) => getParentCompanyName(subsidiary),
        },
        {
          key: 'subscriptionType',
          label: formatMessage({ id: 'SUBSIDIARY.SUBSCRIPTION_TYPE' }),
          sortBy: Settings.SORT_BY.SUBSCRIPTION_TYPE,
          width: widthOptions.sm,
        },
        {
          key: 'licence_expiration',
          label: formatMessage({ id: 'SUBSIDIARY.NEXT_LICENCE_EXPIRATION' }),
          sortBy: Settings.SORT_BY.LICENSE_EXPIRATION,
          width: widthOptions.sm,
          horizontalAlign: horizontalAlignOptions.left,
          verticalAlignOptions: verticalAlignOptions.center,
          render: (subsidiary) => getNextLicenceExpirationFieldText(subsidiary.licenseExpiration),
          itemTooltip: (subsidiary) =>
            getNextLicenceExpirationFieldText(subsidiary.licenseExpiration),
        },
        {
          key: 'usageTime',
          label: formatMessage({ id: 'SUBSIDIARY.USAGE_TIME_LAST_30_DAYS' }),
          sortBy: Settings.SORT_BY.USAGE_TIME,
          width: widthOptions.sm,
        },
        {
          key: 'enable_disable',
          hide: !(hasRoleToEnable || hasRoleToDisable),
          horizontalAlign: horizontalAlignOptions.center,
          verticalAlignOptions: verticalAlignOptions.center,
          render: (subsidiary) => (
            <EnableDisableControl
              item={subsidiary}
              showEnable={hasRoleToEnable}
              showDisable={hasRoleToDisable}
              itemDisabled={subsidiary.isDisabled}
              enableItem={(subsidiary) => {
                enableSubsidiary(subsidiary);
              }}
              enableLabel={formatMessage({ id: 'SUBSIDIARY.ENABLE_SUBSIDIARY' })}
              disableItem={(subsidiary) => {
                disableSubsidiary(subsidiary);
              }}
              disableLabel={formatMessage({ id: 'SUBSIDIARY.DISABLE_SUBSIDIARY' })}
            />
          ),
        },
        {
          key: 'delete',
          hide: !hasRoleToDelete,
          horizontalAlign: horizontalAlignOptions.center,
          verticalAlignOptions: verticalAlignOptions.center,
          maxWidth: widthOptions.xs,
          render: (subsidiary) => (
            <DeleteControl
              show={subsidiary.isDisabled}
              enabled={subsidiary.isDisabled && subsidiary.canDeleted}
              disabledHoverText={formatMessage({ id: 'SUBSIDIARY.DELETE_SUBSIDIARY_DISABLED' })}
              item={subsidiary}
              deleteItem={openDeleteModal}
            />
          ),
        },
      ]}
    />
  );
};

SubsidiariesTable.propTypes = {
  loading: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object),
  loadList: PropTypes.func,
  params: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  enableSubsidiary: PropTypes.func.isRequired,
  disableSubsidiary: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default withPagination(injectIntl(SubsidiariesTable));
