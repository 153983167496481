import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MaskToggle from './MaskToggle';
import GuideText from '../GuideText/GuideText';
import InputInlineError from '../InputInlineError/InputInlineError';
import { inputErrorPropTypes } from 'common';

import './style.scss';

const TextInputAdditions = ({
  toggleMask,
  guideText,
  maskToggleClass,
  hasErrorComponent,
  hasErrorMessage,
  hideValue,
  errorMessage,
}) => {
  return (
    <>
      {toggleMask ? (
        <MaskToggle
          className={classNames('text-input__mask-toggle-button', maskToggleClass)}
          onClick={toggleMask}
        />
      ) : null}
      <InputInlineError
        hasErrorComponent={hasErrorComponent}
        hasErrorMessage={hasErrorMessage}
        errorMessage={errorMessage}
        hideValue={hideValue}
      />
      {guideText ? <GuideText>{guideText}</GuideText> : null}
    </>
  );
};

TextInputAdditions.propTypes = {
  toggleMask: PropTypes.func,
  maskToggleClass: PropTypes.string,
  guideText: PropTypes.node,
  ...inputErrorPropTypes,
};

export default TextInputAdditions;
