export const ERROR_FILE_TOO_LARGE = 'file-too-large';

const getErrorMessageWithFileName = ({ fileName, error }) =>
  fileName ? `${fileName}: ${error}` : error;

const createFileWithPreview = (file) =>
  Object.assign(file, {
    preview: URL.createObjectURL(file),
  });

export const hasBrowsedFile = ({ file }) => {
  if (file) {
    if (Array.isArray(file)) {
      return file.length > 0;
    }
    return true;
  }
  return false;
};

export const getMaxSizeInMB = (maxSizeInBytes) => {
  return maxSizeInBytes * 1024 * 1024;
};

export const getFileValues = async ({ acceptedFiles, multi }) => {
  const acceptedFilesWithPreview = acceptedFiles.map(createFileWithPreview);
  const file = multi ? acceptedFilesWithPreview : acceptedFilesWithPreview[0];

  return file || null;
};

export const configureGetErrorMessage = ({ maxSize, formatMessage } = {}) => (
  { file, errors } = { file: {}, errors: [] }
) => {
  const errorMessage = {
    [ERROR_FILE_TOO_LARGE]: formatMessage({ id: 'COMMON.FIELD_OVER_MAX_SIZE' }, { maxSize }),
  };
  if (errors.length) {
    return getErrorMessageWithFileName({
      fileName: file.path,
      error: errorMessage[errors[0].code] || formatMessage({ id: 'DROPZONE.GENERAL_ERROR' }),
    });
  }
  return null;
};
