import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Indicator, IndicatorActivity } from 'components';
import { FILTERS } from 'libs/dashboardFilters';

import './style.scss';

const Indicators = ({ topData, activities, changeType, activeType, intl: { formatMessage } }) => {
  return (
    <div className="indicators col-md-12 row">
      <Indicator
        type={FILTERS.users}
        label="DASHBOARD.REGISTERED_USERS"
        value={topData.registeredUsersCount}
        onFilterClick={changeType}
        active={activeType === FILTERS.users.value}
      />
      <Indicator
        type={FILTERS.unsuccessful}
        label="COURSES.TITLE"
        value={topData.coursesCount}
        onFilterClick={changeType}
        active={activeType === FILTERS.unsuccessful.value}
      />
      <Indicator
        type={FILTERS.successful}
        label="LESSONS.TITLE"
        value={
          <span>
            {topData.lessonsCount}
            <span className="total-count">
              /{topData && topData.latestSubscription ? topData.latestSubscription.lessonCount : ''}
            </span>
          </span>
        }
      />
      <Indicator
        type={FILTERS.activities}
        label="DASHBOARD.ACTIVITIES"
        value={
          <div className="row m-0">
            <IndicatorActivity
              className={`col-4 p-0`}
              title={formatMessage({ id: 'DASHBOARD.DAYS' })}
              value={activities.days}
            />
            <IndicatorActivity
              className={`col-4 p-0`}
              title={formatMessage({ id: 'DASHBOARD.HOURS' })}
              value={activities.hours}
            />
            <IndicatorActivity
              className={`col-4 p-0`}
              title={formatMessage({ id: 'DASHBOARD.MINUTES' })}
              value={activities.minutes}
            />
          </div>
        }
      />
    </div>
  );
};

Indicators.propTypes = {
  topData: PropTypes.object.isRequired,
  activities: PropTypes.object.isRequired,
  changeType: PropTypes.func.isRequired,
  activeType: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Indicators);
