import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Popover } from 'components';

import './style.scss';

const InlineErrorMessage = ({ show, className, messageClass, message, ...props }) => {
  return (
    <span className={classNames('inline-error-message', className)} {...props}>
      {show && message ? (
        <div
          className={classNames(
            'inline-error-message__text',
            { 'inline-error-message__text--show': show },
            messageClass
          )}>
          <Popover
            className="inline-error-message__popover"
            placement="bottom"
            trigger="hover"
            content={message || ''}
            onOverflow>
            {message}
          </Popover>
        </div>
      ) : null}
    </span>
  );
};

InlineErrorMessage.propTypes = {
  message: PropTypes.string,
  messageClass: PropTypes.string,
  className: PropTypes.string,
  show: PropTypes.bool,
};

export default InlineErrorMessage;
