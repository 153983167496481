import React from 'react';
import PropTypes from 'prop-types';

import { EditOrReadIconButton } from 'components';

const EditOrReadIcon = ({ isEnabled, openEditModal, canEdit }) => {
  return isEnabled ? (
    <EditOrReadIconButton canEdit={canEdit} edit={openEditModal} read={openEditModal} />
  ) : null;
};

EditOrReadIcon.propTypes = {
  isEnabled: PropTypes.bool,
  openEditModal: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

export default EditOrReadIcon;
