import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CrudPage } from 'components';
import { hideLoader, showLoader } from 'actions/common';
import LessonForm from '../Audit/LessonForm';
import LessonAuditTable from './LessonsForAuditTable';
import Settings from 'config/settings';
import { hasRole, Roles } from 'libs/roles';
import { assignLesson, getLessonsForAudit } from 'services/audit';
import { getLesson } from 'services/lessons';
import { getImages } from 'util/imagesMapper';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';

const defaultParams = { sortBy: Settings.SORT_BY.TITLE, order: Settings.ORDER.ASC };

const preloadLesson = (lesson) => ({
  ...lesson,
  images: getImages(lesson),
  tags: lesson?.tags.map(({ title, id }) => ({ label: title, value: id })) || [],
  lessonTypeId: lesson?.lessonType?.id || 1,
});

const LessonsForAudit = ({ showLoader, hideLoader, profile }) => {
  const [lesson, setLesson] = useState({});

  const fetchLessons = useCallback(async (paramsProp) => await getLessonsForAudit(paramsProp), []);

  const { data, isLoading, params, setCurrentParams, changeParams } = useFetchWithQueryParams({
    fetch: fetchLessons,
    queryParamsConfig: defaultParams,
  });

  const count = data?.count || 0;
  const lessonsList = data?.data || [];

  const openInfoModal = async (id) => {
    try {
      showLoader();
      setLesson(preloadLesson(await getLesson(id)));
    } finally {
      hideLoader();
    }
  };

  const isAuditor = () =>
    !!hasRole(profile, [
      Roles.SYSTEM_ADMIN,
      Roles.SUBSIDIARY_MANAGER,
      Roles.CONTENT_MANAGER,
      Roles.CONTENT_VALIDATOR,
    ]);

  const assign = async (lessonId) => {
    await assignLesson(lessonId);
    changeParams();
  };

  return (
    <CrudPage
      className="LessonsForAuditPage"
      title={<FormattedMessage id="LESSONS.FOR_AUDIT" />}
      hideAdd
      setCurrentParams={setCurrentParams}
      params={params}
      filterBySearchText
      count={count}>
      {({ isEditorOpen, openEditModal, closeModal }) => (
        <>
          <LessonForm
            open={isEditorOpen}
            lesson={lesson}
            assignLesson={(lessonId) => assign(lessonId)}
            onSaved={() => setCurrentParams(params)}
            saveTitle={<FormattedMessage id="LESSONS.ASSIGN_TO_ME" />}
            closeEditor={closeModal}
            disabled
            saveButton={isAuditor()}
          />
          <LessonAuditTable
            list={lessonsList}
            loading={isLoading}
            openEditModal={(lesson) => {
              openInfoModal(lesson.id);
              openEditModal();
            }}
            loadList={setCurrentParams}
            currentPage={params.page}
            loadPage={(page) => changeParams(page)}
            count={count}
            params={params}
          />
        </>
      )}
    </CrudPage>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

LessonsForAudit.propTypes = {
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonsForAudit);
