import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { rowMinHeightOptions } from '../tableConstants';
import { capitalizeFirstLetter } from 'libs/strings';

import './style.scss';

const TableRow = React.forwardRef(
  (
    {
      className,
      minHeight,
      children,
      isDragDisabled,
      isDragging,
      attributes,
      listeners,
      style,
      draggingWithOpacity,
    },
    ref
  ) => {
    const minHeightWithCapital = capitalizeFirstLetter(minHeight);

    const tableClasses = classNames('base-table-row', className, {
      [`base-table-row--min-height-${minHeight?.toLowerCase()}`]: minHeightWithCapital,
    });

    return isDragDisabled ? (
      <tr className={classNames(tableClasses, 'base-table-row--drag-disabled')}>{children}</tr>
    ) : (
      <tr
        ref={ref}
        style={style}
        {...listeners}
        {...attributes}
        className={classNames(tableClasses, {
          'base-table-row--is-dragging': isDragging && draggingWithOpacity,
        })}>
        {children}
      </tr>
    );
  }
);

TableRow.propTypes = {
  className: PropTypes.string,
  minHeight: PropTypes.oneOf(Object.values(rowMinHeightOptions)),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isDragDisabled: PropTypes.bool,
  isDragging: PropTypes.bool,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  draggingWithOpacity: PropTypes.bool,
  style: PropTypes.object,
};

export default TableRow;
