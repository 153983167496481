import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Dashboard from './Dashboard';
import {
  getDashboardTopData,
  getDashboardCoursesData,
  getDashboardUsersData,
  getDashboardUserData,
  getRegisteredUsers,
  pokeUsers,
  clearDashboard,
} from '../../actions/dashboard';
import { getSubscriptions } from '../../actions/subscriptions';
import { showLoader, hideLoader } from '../../actions/common';
import { getCourses } from '../../actions/courses';
import { getLessons } from '../../actions/lessons';
import { setManagedSubsidiary, getProfile } from '../../actions/users';
import { getGroups } from '../../actions/groups';
import { getManagedSubsidiaries } from 'libs/managedSubsidiary';

const mapStateToProps = (state) => {
  const managedSubsidiaries = getManagedSubsidiaries(state);

  return {
    dashboard: state.dashboard.dashboard,
    managedSubsidiaries,
    profile: state.users.profile.profile,
    rankList: state.groups.list.data,
    subscriptions: state.subscriptions.list.data,
    loading: state.common.loader.show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardTopData,
      getDashboardCoursesData,
      getDashboardUsersData,
      getDashboardUserData,
      getRegisteredUsers,
      clearDashboard,
      pokeUsers,
      getSubscriptions,
      getGroups,
      getCourses,
      getLessons,
      showLoader,
      hideLoader,
      setManagedSubsidiary,
      getProfile,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
