/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import omit from 'lodash/omit';

import ControlLabelWrapper from '../ControlLabelWrapper/ControlLabelWrapper';
import TextInputAdditions from './TextInputAdditions';
import { hasInputTextRendered, showErrorInputStyle } from '../inputUtil';

import './style.scss';

const defaultRenderTextInputAdditions = (props) => <TextInputAdditions {...props} />;

const getMaskedType = ({ type, isMasked }) => (isMasked ? 'text' : type);

const TextInput = forwardRef((props, ref) => {
  const [id] = useState(() => uniqueId('text-field-'));
  const [isMasked, setIsMasked] = useState(false);

  const {
    value,
    disabled,
    className,
    containerClass,
    childrenWrapperClass,
    autoComplete = 'off',
    placeholder,
    type,
    onChange,
    hideValue,
    getTextFromObject,
    shapeVariant,
    label,
    maskToggle,
    hasErrorMessage,
    renderTextFieldAdditions = defaultRenderTextInputAdditions,
    ...remProps
  } = props;
  const isShapeRound = shapeVariant === 'round';

  const toggleMask = () => {
    setIsMasked((prev) => !prev);
  };

  const inputProps = omit(remProps, [
    'maskToggleClass',
    'guideText',
    'errorMessage',
    'hasErrorMessage',
    'hasErrorComponent',
  ]);

  return (
    <ControlLabelWrapper
      id={id}
      ref={ref}
      className={classNames('text-input', containerClass, {
        'text-input--has-masked-toggle': !!maskToggle,
      })}
      childrenWrapperClass={childrenWrapperClass}
      hasInputValue={hasInputTextRendered({ value, placeholder })}
      hasError={showErrorInputStyle({ hasErrorMessage, hideValue })}
      hideLabel={isShapeRound}
      hideUnderLine={isShapeRound}
      label={label}
      disabled={disabled}
      additions={renderTextFieldAdditions({
        ...props,
        isMasked,
        id,
        toggleMask: maskToggle ? toggleMask : undefined,
      })}>
      <input
        {...inputProps}
        className={classNames('text-input__input', className, {
          'text-input__input--round': isShapeRound,
        })}
        id={id}
        disabled={disabled}
        autoComplete={autoComplete}
        placeholder={placeholder}
        value={!hideValue ? (getTextFromObject ? getTextFromObject(value) : value) : null}
        type={getMaskedType({ type, isMasked })}
        onChange={onChange}
      />
    </ControlLabelWrapper>
  );
});

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  getTextFromObject: PropTypes.func,
  className: PropTypes.string,
  containerClass: PropTypes.string,
  childrenWrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
  spellCheck: PropTypes.bool,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maskToggle: PropTypes.bool,
  maskToggleClass: PropTypes.string,
  hideValue: PropTypes.bool,
  hasErrorComponent: PropTypes.bool,
  hasErrorMessage: PropTypes.bool,
  guideText: PropTypes.node,
  onChange: PropTypes.func,
  shapeVariant: PropTypes.oneOf(['classic, round']),
  renderTextFieldAdditions: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TextInput;
