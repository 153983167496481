import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { SelectInput } from 'components';

import './style.scss';

const getInitialRoles = (roles = [], availableRoles) =>
  roles.map((role) => {
    const roleObject = availableRoles.find(({ id }) => id === role.id || id === parseInt(role));
    return {
      value: +role.id || +role,
      label: roleObject ? roleObject.displayName : '',
    };
  });

const RolesFilter = ({ onChange, roles = [], availableRoles }) => {
  const initialRoles = getInitialRoles(roles, availableRoles);
  const roleOptions = (availableRoles || []).map(({ id, displayName }) => {
    return { value: id, label: displayName };
  });

  return (
    <div className="RolesFilter ms-3 mb-3 d-flex align-items-center">
      <SelectInput
        label={<FormattedMessage id="USERS.ROLES_LABEL" />}
        multi
        value={initialRoles}
        options={roleOptions}
        onChange={(roles) => {
          onChange(roles ? roles.map(({ value }) => value) : [], 1);
        }}
      />
    </div>
  );
};

RolesFilter.propTypes = {
  roles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  availableRoles: PropTypes.array.isRequired,
};

export default RolesFilter;
