import qs from 'query-string';
import Settings from 'config/settings';

export const getManagedSubsidiaries = (state) => {
  let managedSubsidiaries = [];
  if (state.users.profile.profile.roles) {
    state.users.profile.profile.roles.forEach(({ subsidiaries }) => {
      subsidiaries &&
        subsidiaries.map((group) => {
          if (!managedSubsidiaries.find(({ id }) => group.id === id)) {
            managedSubsidiaries.push(group);
          }
          return group;
        });
      return true;
    });
  }

  managedSubsidiaries.sort((a, b) => a.name.localeCompare(b.name));

  return managedSubsidiaries;
};

export const setManagedSubsidiary = async ({
  subsidiary,
  history,
  location,
  setManagedSubsidiary,
}) => {
  setManagedSubsidiary(subsidiary);

  return await history.push({
    pathname: location.pathname,
    search: qs.stringify(
      {
        ...qs.parse(location.search, Settings.QUERY_FORMAT),
        subsidiaryId: subsidiary.id,
      },
      Settings.QUERY_FORMAT
    ),
  });
};

export const getManagedSubsidiary = (location) => {
  const queryParams = qs.parse(location.search, Settings.QUERY_FORMAT);

  return queryParams.subsidiaryId;
};
