import {
  CLEAR_DASHBOARD,
  GET_DASHBOARD_TOP_DATA,
  GET_DASHBOARD_TOP_DATA_SUCCESS,
  GET_DASHBOARD_TOP_DATA_FAILED,
  GET_DASHBOARD_COURSES_DATA,
  GET_DASHBOARD_COURSES_DATA_FAILED,
  GET_DASHBOARD_COURSES_DATA_SUCCESS,
  GET_DASHBOARD_USERS_DATA,
  GET_DASHBOARD_USERS_DATA_FAILED,
  GET_DASHBOARD_USERS_DATA_SUCCESS,
  GET_DASHBOARD_USER_DATA,
  GET_DASHBOARD_USER_DATA_FAILED,
  GET_DASHBOARD_USER_DATA_SUCCESS,
  GET_DASHBOARD_REGISTERED_USER_DATA,
  GET_DASHBOARD_REGISTERED_USER_DATA_SUCCESS,
  GET_DASHBOARD_REGISTERED_USER_DATA_FAILED,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  topData: {},
  coursesData: [],
  usersData: [],
  userData: {},
  registeredUsers: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_DASHBOARD:
      return {
        ...INITIAL_STATE,
      };
    case GET_DASHBOARD_TOP_DATA:
      return {
        ...state,
        topData: INITIAL_STATE.topData,
        loading: true,
      };
    case GET_DASHBOARD_TOP_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        topData: action.payload || {},
      };
    case GET_DASHBOARD_TOP_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DASHBOARD_COURSES_DATA:
      return {
        ...state,
        coursesData: INITIAL_STATE.coursesData,
        loading: true,
      };
    case GET_DASHBOARD_COURSES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        coursesData: action.payload || [],
      };
    case GET_DASHBOARD_COURSES_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DASHBOARD_USERS_DATA:
      return {
        ...state,
        usersData: INITIAL_STATE.usersData,
        loading: true,
      };
    case GET_DASHBOARD_USERS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        usersData: action.payload || [],
      };
    case GET_DASHBOARD_USERS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DASHBOARD_REGISTERED_USER_DATA:
      return {
        ...state,
        registeredUsers: INITIAL_STATE.registeredUsers,
        loading: true,
      };
    case GET_DASHBOARD_REGISTERED_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        registeredUsers: action.payload || [],
      };
    case GET_DASHBOARD_REGISTERED_USER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DASHBOARD_USER_DATA:
      return {
        ...state,
        userData: INITIAL_STATE.userData,
        loading: true,
      };
    case GET_DASHBOARD_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload || [],
      };
    case GET_DASHBOARD_USER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
