import React from 'react';
import { injectIntl } from 'react-intl';

import { STATUS } from '../../libs/http';

const ApiErrorMessage = ({ error, intl }) => {
  const { formatMessage } = intl;
  const getErrorFormattedMessage = (status) => {
    switch (status) {
      case STATUS.FORBIDDEN:
        return formatMessage({ id: 'API_ERRORS.FORBIDDEN' });
      case STATUS.NOT_FOUND:
        return formatMessage({ id: 'API_ERRORS.NOT_FOUND' });
      case STATUS.UNPROCESSABLE_ENTITY:
        return formatMessage({ id: 'API_ERRORS.UNPROCESSABLE_ENTITY' });
      case STATUS.UNAUTHORIZED:
        return formatMessage({ id: 'API_ERRORS.UNAUTHORIZED' });
      case STATUS.GATEWAY_TIMEOUT:
        return formatMessage({ id: 'API_ERRORS.GATEWAY_TIMEOUT' });
      case STATUS.SERVICE_UNAVAILABLE:
        return formatMessage({ id: 'API_ERRORS.SERVICE_UNAVAILABLE' });
      case STATUS.INTERNAL_SERVER_ERROR:
        return formatMessage({ id: 'API_ERRORS.INTERNAL_SERVER_ERROR' });
      default:
        return formatMessage({ id: 'API_ERRORS.UNKNOWN_ERROR' });
    }
  };
  return (
    <div>
      <h6>{error.status}</h6>
      {error.statusText}
      {error && error.status ? ' - ' + getErrorFormattedMessage(error.status) : null}
    </div>
  );
};
export default injectIntl(ApiErrorMessage);
