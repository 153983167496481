import request from '../libs/request';
import { Methods } from '../libs/http';

const BASE_SUBSCRIPTIONS_URL = '/subscriptions';

export const getSubscriptions = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_SUBSCRIPTIONS_URL}`,
  });
};
