import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { removeHistoryState } from 'util/removeHistoryState';
import { ActiveFilterIndicator, CrudPage } from 'components';
import LessonTable from './LessonsTable';
import Settings from 'config/settings';
import { LessonAccess } from 'libs/accessManagement';
import AssignLesson from './AssignLesson';
import LessonHistory from './LessonHistory';
import { modalType } from 'util/modalType';
import { deleteLesson as deleteLessonService, getLessons } from 'services/lessons';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';
import Filter from 'components/Filter/Filter';
import { lessonsFilterTypes } from 'libs/filterTypes';

const defaultParams = {
  sortBy: Settings.SORT_BY.TITLE,
  order: 'asc',
  searchText: '',
  searchIn: ['title', 'subsidiary', 'lessonType', 'tag'],
};

const LessonsPage = ({ profile, setPreviousParams }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const [lessonIds, setLessonIds] = useState(history?.location?.state?.lessonIds || []);
  const [modal, setModal] = useState(null);
  const [selectedLessonId, setSelectedLessonId] = useState(null);

  const fetchLessons = useCallback(
    async ({ searchType, onlySubsidiaries, ...restParams }) =>
      await getLessons({
        ...restParams,
        filter: {
          searchType: searchType,
          onlySubsidiaries: onlySubsidiaries,
          ...(lessonIds?.length && { lessonIds }),
        },
      }),
    [lessonIds]
  );

  const {
    data,
    isLoading,
    params,
    setCurrentParams,
    changeParams,
    changeParamsAndResetPage,
  } = useFetchWithQueryParams({
    fetch: fetchLessons,
    queryParamsConfig: defaultParams,
  });

  useEffect(() => {
    setPreviousParams(params);
  }, [setPreviousParams, params]);

  const count = data?.count || 0;
  const total = data?.length || 0;
  const lessonsList = data?.data || [];

  const createLesson = () => {
    history.push(
      `${match.url.endsWith('/') ? match.url.slice(0, -1) : match.url}/new?tab=base-data`
    );
  };

  const editLesson = (lesson) => {
    const { id } = lesson || {};
    history.push(
      `${match.url.endsWith('/') ? match.url.slice(0, -1) : match.url}/${id}/edit?tab=base-data`
    );
  };

  const deleteLesson = async (id) => {
    await deleteLessonService(id);
    changeParams();
  };

  const openModal = (modalOpen, modelLessonId) => {
    setModal(modalOpen);
    setSelectedLessonId(modelLessonId);
  };

  const closeModal = () => {
    setModal(null);
    setSelectedLessonId(null);
  };

  const { hasRoleToCreate } = LessonAccess.getHasRoles(profile.profile);

  return (
    <CrudPage
      title={<FormattedMessage id="LESSONS.TITLE" />}
      addRowLabel={<FormattedMessage id="LESSONS.ADD" />}
      deleteTitle={<FormattedMessage id="LESSONS.DELETE_LESSON" />}
      deleteKey="title"
      setCurrentParams={setCurrentParams}
      hasRoleToCreate={hasRoleToCreate}
      deleteItem={deleteLesson}
      params={params}
      filterBySearchText
      initialData={{}}
      count={count}
      total={total}
      addRow={createLesson}
      additionalFilters={() => (
        <Filter
          options={params.searchIn || []}
          onChange={(searchIn) => changeParamsAndResetPage({ searchIn })}
          availableOptions={lessonsFilterTypes}
        />
      )}>
      {({ openDeleteModal }) => {
        const resetActiveFilter = async () => {
          setLessonIds([]);
          removeHistoryState(history, 'lessonIds');
          changeParamsAndResetPage();
        };

        return (
          <div>
            <ActiveFilterIndicator isActive={!!lessonIds?.length} resetFilter={resetActiveFilter} />
            <LessonTable
              editLesson={editLesson}
              openAssignModal={(lesson) => openModal(modalType.ASSIGN, lesson.id)}
              openHistoryModal={(lesson) => openModal(modalType.HISTORY, lesson.id)}
              openDeleteModal={openDeleteModal}
              loading={isLoading}
              list={lessonsList}
              params={params}
              profile={profile}
              count={count}
              currentPage={params.page}
              loadList={setCurrentParams}
              loadPage={(page) => setCurrentParams({ ...params, ...page })}
            />
            <AssignLesson
              open={modal === modalType.ASSIGN}
              closeEditor={closeModal}
              lessonId={selectedLessonId}
            />
            <LessonHistory
              open={modal === modalType.HISTORY}
              closeModal={closeModal}
              lessonId={selectedLessonId}
            />
          </div>
        );
      }}
    </CrudPage>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

LessonsPage.propTypes = {
  profile: PropTypes.object,
  setPreviousParams: PropTypes.func,
};

export default connect(mapStateToProps)(injectIntl(LessonsPage));
