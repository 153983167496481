import * as PasswordService from '../services/forgotPassword';

import * as actionTypes from 'actions/action-types';

export const resetPassword = (passwordObj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.RESET_PASSWORD,
      });

      const result = await PasswordService.resetPassword(passwordObj);

      dispatch({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
      });

      return result;
    } catch (error) {
      dispatch({
        type: actionTypes.RESET_PASSWORD_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
