import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, Popover } from 'components';

import './style.scss';

const TableCellButton = ({ children, tooltip, onClick }) => {
  return (
    <Button
      className={classNames('basic-table-cell-button btn-primary')}
      size="small"
      onClick={onClick}>
      <Popover
        onOverflow
        content={tooltip ? tooltip : children}
        placement="bottom"
        oneLine
        trigger="hover">
        {children}
      </Popover>
    </Button>
  );
};

TableCellButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
};

export default TableCellButton;
