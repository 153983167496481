import request from '../libs/request';
import { Methods } from '../libs/http';

const BASE_URL = '/provinces';

export const getProvinces = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}`,
  });
};
