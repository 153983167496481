import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { TableCellButton } from 'components';

const SendReminderOrForgotPasswordControl = ({
  user,
  sendReminder,
  sendForgotPasswordEmail,
  isRegistered,
  intl: { formatMessage },
}) => {
  const onClick = isRegistered ? () => sendForgotPasswordEmail(user) : () => sendReminder(user);

  const buttonText = isRegistered
    ? formatMessage({ id: 'COMMON.FORGOT_PASSWORD' })
    : formatMessage({ id: 'USERS.REMINDER' });

  return <TableCellButton onClick={onClick}>{buttonText}</TableCellButton>;
};

SendReminderOrForgotPasswordControl.propTypes = {
  user: PropTypes.object,
  sendReminder: PropTypes.func.isRequired,
  sendForgotPasswordEmail: PropTypes.func.isRequired,
  isRegistered: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(SendReminderOrForgotPasswordControl);
