export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

export const API_ERROR = 'OTHER_API_ERROR';
export const RESET_API_ERROR = 'RESET_API_ERROR';

export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';
