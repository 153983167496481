import axios from 'axios';
import Settings from '../config/settings';
import { Methods } from './http';
import { refreshToken, logout } from '../actions/users';
import store from '../config/store';
import { isRefreshInProgress, isRefreshNeeded, setRefreshInProgress } from './refreshToken';
import { API_ERROR } from '../actions/action-types';

export const requestCreator = axios.create({});

export default async (
  { resource = '/', method = Methods.GET, data, headers = {} } = {},
  showToastMessageError = true
) => {
  const url = `${Settings.API_BASE_URL}${resource}`;

  const token = localStorage.getItem(Settings.API_TOKEN);

  if (token) {
    if (isRefreshNeeded() && resource !== Settings.REFRESH_TOKEN_RESOURCE) {
      try {
        const waitUntilRefresh = async () => {
          await new Promise((resolve) => setTimeout(() => resolve(), 500));
          if (isRefreshInProgress()) {
            await waitUntilRefresh();
          }
        };
        if (isRefreshInProgress()) {
          await waitUntilRefresh();
        } else {
          setRefreshInProgress(true);
          await store.dispatch(refreshToken(token));
          setRefreshInProgress(false);
        }
      } catch (err) {
        setRefreshInProgress(false);
        await store.dispatch(logout());
        throw err;
      }
    }

    headers['Authorization'] = 'bearer ' + localStorage.getItem(Settings.API_TOKEN);
  }

  try {
    const { data: response } = await requestCreator.request({
      method,
      url,
      data,
      headers,
    });

    return response;
  } catch (err) {
    if (showToastMessageError) {
      const { response } = err;
      const errorAction = { type: API_ERROR, payload: response };
      await store.dispatch(errorAction);
      throw err;
    }

    throw err;
  }
};
