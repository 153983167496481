import { templateLevel, templateTypes } from 'libs/template';
import { createShallowMergedObject } from 'util/objectUtil';
import { notAllowedToSearch } from 'libs/template';

export const getInitialSpecific = () => {
  return {
    key: '',
    type: '',
    value: '',
    isSearchable: false,
    isGenerate: true,
    isShow: true,
    isGeneral: true,
    level: templateLevel.BEGINNER.id,
    question: '',
  };
};

export const convertSpecificsToSpecificsFormData = (specifics = []) => {
  return specifics
    ? specifics.map((specific) => ({
        ...createShallowMergedObject(getInitialSpecific(), specific),
        ...(specific &&
          specific.type &&
          notAllowedToSearch(specific.type) && { isSearchable: false }),
        ...(specific.type === templateTypes.IMAGE.id && {
          value: specific.value ? { url: specific.value } : null,
        }),
      }))
    : [];
};
