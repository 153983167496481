import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const MESSAGE_TYPES = { info: 'info' };

const MessageComponent = ({ message, type }) => {
  const baseIconClassName = 'message-component-icon';
  return (
    <div className="message-component">
      <div className="message-component-text">{message}</div>
      <div className={baseIconClassName}>
        {type === MESSAGE_TYPES.info ? <i className={classNames('fas fa-info')} /> : null}
      </div>
    </div>
  );
};

MessageComponent.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(Object.values(MESSAGE_TYPES)),
};

export default MessageComponent;
