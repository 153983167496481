import { axisDataKeys } from 'util/chartAxisDataKeys';
import {
  newSubscriptionColor,
  totalSubscriptionColor,
  subscriptionTrendColor,
  spentTimeColor,
} from '../../styles/_colors.scss';

const numOfSubscriptionYAxisId = 'numOfSubscriptions';
const subTrendPercentYAxisId = 'subTrendPercent';
const timeAxis = 'timeAxis';

export const primaryChartConstants = {
  chartAreas: [
    {
      yAxisId: numOfSubscriptionYAxisId,
      key: axisDataKeys.TOTAL_SUBSCRIPTION_COUNT,
      color: totalSubscriptionColor,
      legendAndTooltipOrder: 0,
    },
    {
      yAxisId: subTrendPercentYAxisId,
      key: axisDataKeys.SUBSCRIPTION_TREND,
      color: subscriptionTrendColor,
      legendAndTooltipOrder: 1,
    },
  ],
  chartBars: [
    {
      yAxisId: numOfSubscriptionYAxisId,
      key: axisDataKeys.NEW_SUBSCRIPTION_COUNT,
      color: newSubscriptionColor,
      legendAndTooltipOrder: 2,
    },
  ],
  xAxisDataKey: axisDataKeys.DATE,
  xAxisPadding: { left: 15, right: 15 },
  yAxes: [
    {
      yAxisId: numOfSubscriptionYAxisId,
      labelTranslateId: 'CONTENT_STATISTICS.NUM_OF_SUBS_Y_AXIS_LABEL',
    },
    {
      yAxisId: subTrendPercentYAxisId,
      labelTranslateId: 'CONTENT_STATISTICS.SUB_PERCENT_Y_AXIS_LABEL',
      orientation: 'right',
    },
  ],
};

export const secondaryChartConstants = {
  chartBars: [
    { yAxisId: timeAxis, key: axisDataKeys.NUM_OF_USERS, color: spentTimeColor, barSize: 50 },
  ],
  xAxisDataKey: axisDataKeys.SPENT_TIME_PERIOD,
  yAxes: [
    {
      yAxisId: timeAxis,
      orientation: 'left',
      labelTranslateId: 'CONTENT_STATISTICS.SPENT_TIME_Y_AXIS_LABEL',
      allowDecimals: false,
    },
  ],
};
