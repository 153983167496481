import request from '../libs/request';
import { Methods } from '../libs/http';

const BASE_URL = '/tags';

/**
 * @param {string} text to filter
 * @description get all tags
 * */
export const getTags = async (text) => {
  return await request({
    method: Methods.GET,
    resource: text ? `${BASE_URL}/search/${text}` : `${BASE_URL}`,
  });
};
