const LABEL_TRANSLATE_PREFIX = 'CHART_LABELS';

export const axisDataKeys = {
  ACTIVE_USERS_COUNT: 'activeUsersCount',
  INACTIVE_USERS_COUNT: 'inactiveUsersCount',
  TOTAL_USERS_COUNT: 'totalUsersCount',
  TEST_SUCCESS_COUNT: 'testSuccessCount',
  TEST_FAILURE_COUNT: 'testFailureCount',
  TOTAL_TEST_COUNT: 'totalTestsCount',
  ACTIVE_GROUPS_COUNT: 'activeGroupsCount',
  INACTIVE_GROUPS_COUNT: 'inactiveGroupsCount',
  TOTAL_GROUPS_COUNT: 'totalGroupsCount',
  ACTIVE_COURSES_COUNT: 'activeCourseCount',
  INACTIVE_COURSES_COUNT: 'inactiveCourseCount',
  TOTAL_COURSES_COUNT: 'totalCourseCount',
  ACTIVE_LESSONS_COUNT: 'activeLessonCount',
  INACTIVE_LESSONS_COUNT: 'inactiveLessonCount',
  TOTAL_LESSONS_COUNT: 'totalLessonCount',

  //TODO: Update keys if BE is finished:
  NEW_SUBSCRIPTION_COUNT: 'newCount',
  TOTAL_SUBSCRIPTION_COUNT: 'total',
  SUBSCRIPTION_TREND: 'trend',
  DATE: 'date',
  NUM_OF_USERS: 'count',
  SPENT_TIME_PERIOD: 'label',
  TOTAL_TIME_SPENT: 'totalSum',
  ACTUAL_TIME_SPENT: 'actualSum',
  NUM_OF_LESSONS_USED: 'lessonCount',
  IMPORTANT_LESSONS_VIEWED: 'importantCount',
  NOT_IMPORTANT_LESSONS_VIEWED: 'notImportantCount',
  USAGE_TREND: 'activityTime',
};

export const chartLabelConstants = {
  [axisDataKeys.ACTIVE_USERS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.ACTIVE_USERS_COUNT`,
  },
  [axisDataKeys.INACTIVE_USERS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.INACTIVE_USERS_COUNT`,
  },
  [axisDataKeys.TOTAL_USERS_COUNT]: { translateId: `${LABEL_TRANSLATE_PREFIX}.TOTAL_USERS_COUNT` },
  [axisDataKeys.TEST_SUCCESS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.TEST_SUCCESS_COUNT`,
  },
  [axisDataKeys.TEST_FAILURE_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.TEST_FAILURE_COUNT`,
  },
  [axisDataKeys.TOTAL_TEST_COUNT]: { translateId: `${LABEL_TRANSLATE_PREFIX}.TOTAL_TEST_COUNT` },
  [axisDataKeys.ACTIVE_GROUPS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.ACTIVE_GROUPS_COUNT`,
  },
  [axisDataKeys.INACTIVE_GROUPS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.INACTIVE_GROUPS_COUNT`,
  },
  [axisDataKeys.TOTAL_GROUPS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.TOTAL_GROUPS_COUNT`,
  },
  [axisDataKeys.ACTIVE_COURSES_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.ACTIVE_COURSES_COUNT`,
  },
  [axisDataKeys.INACTIVE_COURSES_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.INACTIVE_COURSES_COUNT`,
  },
  [axisDataKeys.TOTAL_COURSES_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.TOTAL_COURSES_COUNT`,
  },
  [axisDataKeys.ACTIVE_LESSONS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.ACTIVE_LESSONS_COUNT`,
  },
  [axisDataKeys.INACTIVE_LESSONS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.INACTIVE_LESSONS_COUNT`,
  },
  [axisDataKeys.TOTAL_LESSONS_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.TOTAL_LESSONS_COUNT`,
  },
  [axisDataKeys.NEW_SUBSCRIPTION_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.NEW_SUBSCRIPTION_COUNT`,
  },
  [axisDataKeys.TOTAL_SUBSCRIPTION_COUNT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.TOTAL_SUBSCRIPTION_COUNT`,
  },
  [axisDataKeys.SUBSCRIPTION_TREND]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.SUBSCRIPTION_TREND`,
  },
  [axisDataKeys.NUM_OF_USERS]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.NUM_OF_USERS`,
  },
  [axisDataKeys.SPENT_TIME_PERIOD]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.SPENT_TIME_PERIOD`,
  },
  [axisDataKeys.TOTAL_TIME_SPENT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.TOTAL_TIME_SPENT`,
  },
  [axisDataKeys.ACTUAL_TIME_SPENT]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.ACTUAL_TIME_SPENT`,
  },
  [axisDataKeys.NUM_OF_LESSONS_USED]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.NUM_OF_LESSONS_USED`,
  },
  [axisDataKeys.IMPORTANT_LESSONS_VIEWED]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.IMPORTANT_LESSONS_VIEWED`,
  },
  [axisDataKeys.NOT_IMPORTANT_LESSONS_VIEWED]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.NOT_IMPORTANT_LESSONS_VIEWED`,
  },
  [axisDataKeys.USAGE_TREND]: {
    translateId: `${LABEL_TRANSLATE_PREFIX}.USAGE_TREND`,
  },
};
