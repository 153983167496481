import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { ActiveFilterIndicator, CrudPage } from 'components';
import CoursesForm from './CoursesForm';
import CoursesTable from './CoursesTable';
import { removeHistoryState } from 'util/removeHistoryState';

import { CourseAccess } from 'libs/accessManagement';
import { getRequestDataForImage } from 'util/imagesMapper';
import { filterUsersAssignedOnlyAsUser } from 'util/assignUserAndGroupHelper';
import {
  getCourses,
  createCourse,
  editCourse,
  getCourse,
  deleteCourse,
  setImportant,
} from 'services/courses';
import { getImages } from 'util/imagesMapper';
import { usersMapper } from 'util/transferMapper';
import { mapToAssignedUsers } from 'util/assignUserAndGroupHelper';

import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';

import './style.scss';
import Filter from 'components/Filter/Filter';
import { coursesFilterTypes } from 'libs/filterTypes';

const COURSE_IDS = 'courseIds';

const defaultParams = { sortBy: 'title', order: 'asc', searchIn: ['title', 'subsidiary'] };
const initialCourse = {
  images: [],
  imageFull: null,
  title: '',
  price: 0,
  public: false,
  isAdvert: false,
  examQuestionCount: null,
  acceptanceRate: null,
  deadline: null,
  groups: [],
  canEdit: false,
};

const CoursesPage = ({ showLoader, hideLoader, profile }) => {
  const history = useHistory();

  const [course, setCourse] = useState(initialCourse);
  const [courseFormTabIndex, setCourseFormTabIndex] = useState(0);
  const [courseIds, setCourseIds] = useState(history?.location?.state?.[COURSE_IDS] || []);

  const fetchCourses = useCallback(
    async (paramsCourse) =>
      await getCourses({
        ...paramsCourse,
        onlySubsidiaries: paramsCourse.onlySubsidiaries ? 1 : 0,
        onlyInTitles: paramsCourse.onlyInTitles ? 1 : 0,
        ...(courseIds?.length > 0 && { courseIds }),
      }),
    [courseIds]
  );

  const {
    data,
    setData,
    isLoading,
    params,
    setCurrentParams,
    changeParams,
    changeParamsAndResetPage,
  } = useFetchWithQueryParams({
    fetch: fetchCourses,
    queryParamsConfig: defaultParams,
  });
  const count = data?.count || 0;
  const total = data?.length || 0;
  const courseList = data?.data || [];

  const transformImagesToSave = async (course) =>
    getRequestDataForImage({
      image: course?.images,
      getDataForExistingImage: (image) => image?.url,
    });

  const transformUsersToSave = (course) => {
    return course?.users ? filterUsersAssignedOnlyAsUser(course.users).map(({ id }) => id) : [];
  };

  const preLoadCourse = async (values) => ({
    ...values,
    images: await transformImagesToSave(values),
    lessons: values.lessons ? values.lessons.map((lesson) => lesson.id) : [],
    users: transformUsersToSave(values),
  });

  const create = async (values) => {
    const course = await preLoadCourse(values);
    await createCourse(course);
  };

  const edit = async (values) => {
    const course = await preLoadCourse(values);
    await editCourse(course, course.id);
  };

  const loadCourse = async ({ id, canEdit }, courseFormTabIndex = 0) => {
    try {
      showLoader();
      const course = await getCourse(id);
      setCourse({
        ...course,
        lessons: course.lessons.map(({ title, id }) => {
          return { name: title, id };
        }),
        users: mapToAssignedUsers(usersMapper(course), course.groups || []),
        images: getImages(course),
        canEdit,
      });
      setCourseFormTabIndex(courseFormTabIndex);
    } finally {
      hideLoader();
    }
  };

  const closeEditModal = () => {
    setCourse(initialCourse);
    setCourseFormTabIndex(0);
  };

  const toggleImportant = async (courseId, important) => {
    await setImportant(courseId, important);
    setData({
      ...data,
      data: data.data.map((course) =>
        course.id === courseId ? { ...course, isImportant: important } : course
      ),
    });
  };

  const { hasRoleToCreate } = CourseAccess.getHasRoles(profile.profile);
  return (
    <CrudPage
      title={<FormattedMessage id="COURSES.TITLE" />}
      addRowLabel={<FormattedMessage id="COURSES.ADD_COURSE" />}
      deleteTitle={<FormattedMessage id="COURSES.DELETE_COURSE" />}
      deleteKey="title"
      setCurrentParams={setCurrentParams}
      deleteItem={deleteCourse}
      onDelete={() => changeParams()}
      params={params}
      initialData={course}
      count={count}
      total={total}
      hasRoleToCreate={hasRoleToCreate}
      filterBySearchText
      additionalFilters={() => (
        <Filter
          options={params.searchIn || []}
          onChange={(searchIn) => changeParamsAndResetPage({ searchIn })}
          availableOptions={coursesFilterTypes}
        />
      )}>
      {({ isEditorOpen, openEditModal, closeModal, openDeleteModal }) => {
        const resetActiveFilter = async () => {
          setCourseIds([]);
          removeHistoryState(history, COURSE_IDS);
          changeParamsAndResetPage();
        };

        return (
          <>
            <ActiveFilterIndicator
              isActive={courseIds && courseIds.length > 0}
              resetFilter={resetActiveFilter}
            />
            <CoursesTable
              profile={profile}
              list={courseList}
              openEditModal={async (course, tabIndex) => {
                await loadCourse(course, tabIndex);
                openEditModal(course);
              }}
              openDeleteModal={(course) => openDeleteModal(course)}
              count={count}
              params={params}
              loadList={setCurrentParams}
              loadPage={(page) => changeParams(page)}
              setImportant={toggleImportant}
              loading={isLoading}
              currentPage={params.page}
            />

            <CoursesForm
              open={isEditorOpen}
              closeEditor={() => {
                closeModal();
                closeEditModal();
              }}
              onSaved={() => changeParams()}
              createCourse={create}
              editCourse={edit}
              edit={!!course.id}
              course={course}
              tabIndex={courseFormTabIndex}
              changeTabIndex={setCourseFormTabIndex}
            />
          </>
        );
      }}
    </CrudPage>
  );
};

CoursesPage.propTypes = {
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  courseList: PropTypes.object.isRequired,
  params: PropTypes.object,
  setCurrentParams: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default CoursesPage;
