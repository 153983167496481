import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PathEditor from '../../PathEditor/PathEditor';
import PathEditorLessionList from './PathEditorLessionList';
import PathEditorForm from './PathEditorForm';
import { getCoursesLessons } from 'services/path';
import settings from 'config/settings';
import { createPathItemObject } from 'util/createPathItemObject';
import { createId } from 'util/dndIdCreator';

const ITEMS_PER_PAGE = 20;
const { MARKER, COURSE, LESSON } = settings.ITEM_TYPES;

const PathEditorField = ({ field, form }) => {
  const [sourceListData, setSourceListData] = useState([]);
  const [sourceListCount, setSourceListCount] = useState();
  const [searchType, setSearchType] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  const getRecordKey = useCallback((item) => createId(item), []);

  const mapPathItem = useCallback((item) => {
    const selectType = {
      [LESSON]: ({ id, type, ...item }) => ({ lessonId: id, itemType: type, ...item }),
      [MARKER]: ({ id, type, ...item }) => ({ markerId: id, itemType: type, ...item }),
      [COURSE]: ({ id, type, ...item }) => ({ courseId: id, itemType: type, ...item }),
    };

    return selectType[item.type](item);
  }, []);

  const loadSourceList = useCallback(
    async (params) => {
      const list = await getCoursesLessons(searchText, page, searchType, params);
      setSourceListData(list.data.map(mapPathItem));
      setSourceListCount(list.count);
    },
    [searchText, page, searchType, mapPathItem]
  );

  useEffect(() => {
    loadSourceList();
  }, [loadSourceList]);

  const handleChange = useCallback(
    (pathItemList) => {
      if (form && field) {
        form.setFieldValue(field.name, [...pathItemList]);
        form.setFieldTouched(field.name, true);
      }
    },
    [field, form]
  );

  const addPathItem = useCallback(
    (itemId) => {
      const pathItem = createPathItemObject(itemId, sourceListData, getRecordKey);

      handleChange(field.value.concat(pathItem));
    },
    [handleChange, field.value, sourceListData, getRecordKey]
  );

  const loadSourcePage = useCallback(
    (page) => {
      loadSourceList(page);
      setPage(page.page);
    },
    [loadSourceList]
  );

  const loadFilterData = useCallback(
    (searchText, page) => {
      loadSourceList({ page, searchText });
      setPage(page);
      setSearchText(searchText);
    },
    [loadSourceList]
  );

  const changeSearchType = useCallback(
    (searchType) => {
      loadSourceList({ page, searchType });
      setPage(1);
      setSearchType(searchType);
    },
    [loadSourceList, page]
  );

  const renderSourceList = useCallback(
    ({ activeId, isListItemDisabled }) => (
      <PathEditorLessionList
        recordKey={getRecordKey}
        list={sourceListData}
        count={sourceListCount}
        activeId={activeId}
        isListItemDisabled={isListItemDisabled}
        loadFilterData={loadFilterData}
        searchType={searchType}
        changeSearchType={changeSearchType}
        loadList={loadSourceList}
        loadPage={loadSourcePage}
        page={page}
        itemsPerPage={ITEMS_PER_PAGE}
        className="col-6 order-1"
      />
    ),
    [
      changeSearchType,
      loadFilterData,
      loadSourceList,
      loadSourcePage,
      page,
      searchType,
      sourceListData,
      sourceListCount,
      getRecordKey,
    ]
  );

  const renderEditor = useCallback(
    ({ disabled, pathItems, setPathItems }) => (
      <PathEditorForm
        pathEditorFieldName={field.name}
        disabled={disabled}
        pathItems={pathItems}
        setPathItems={setPathItems}
      />
    ),
    [field.name]
  );

  return (
    <PathEditor
      pathItems={field.value}
      setPathItems={handleChange}
      renderSourceList={renderSourceList}
      renderEditor={renderEditor}
      addPathItem={addPathItem}
    />
  );
};

PathEditorField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default PathEditorField;
