import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ConfirmModal } from 'components';
import { unAssignCourse, getLesson } from 'services/lessons';
import { CourseAccess } from 'libs/accessManagement';
import RelatedCoursesTable from './RelatedCoursesTable';

function RelatedCourses({ profile, lesson, showLoader, hideLoader }) {
  const [courses, setCourses] = useState(null);
  const [show, setShow] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const history = useHistory();

  const openDeleteModal = (course) => {
    setDeleteItem(course);
    setShow(true);
  };

  const closeDeleteModal = () => {
    setShow(false);
  };

  const fetchCourses = useCallback(async () => {
    try {
      showLoader();
      if (lesson?.id) {
        const res = await getLesson(lesson.id);
        setCourses(res.courses);
      }
    } finally {
      hideLoader();
    }
  }, [lesson, showLoader, hideLoader]);

  const handleDeleteItem = async () => {
    await unAssignCourse(lesson.id, deleteItem.id);
    fetchCourses();
    setShow(false);
  };

  const goToCourse = (course) => history.push(...[`/home/courses/`, { courseIds: [course.id] }]);

  const canGoToCourse = (course) => CourseAccess.getHasAccess(profile.profile, course).canView;

  const canDeleteCourse = (course) =>
    CourseAccess.getHasAccess(profile.profile, course).canAssignLesson;

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  return (
    <div>
      <ConfirmModal
        open={show}
        onClose={closeDeleteModal}
        title={<FormattedMessage id="RELATED_COURSES.REMOVE" />}
        saveAction={handleDeleteItem}
        confirmElement={
          <FormattedMessage
            id="COMMON.DELETE_QUESTION"
            values={{ title: deleteItem && deleteItem.name }}
          />
        }
      />
      {courses !== null && (
        <RelatedCoursesTable
          list={courses}
          openDeleteModal={openDeleteModal}
          goToCourse={goToCourse}
          hasRoleToGoToCourse={canGoToCourse}
          hasRoleToDelete={canDeleteCourse}
        />
      )}
    </div>
  );
}

RelatedCourses.propTypes = {
  profile: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
};

export default injectIntl(RelatedCourses);
