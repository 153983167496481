import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import { templateTypes } from 'libs/template';
import { getRequestDataForImage } from './imagesMapper';

const getSameValue = (value) => value;

export const getTypeValueFromFormValues = ({ type, value }) => {
  const typeOptions = {
    [templateTypes.IMAGE.id]: (value) => (isObject(value) ? value : null),
    [templateTypes.VIDEO.id]: (value) => (isString(value) ? value : ''),
    [templateTypes.SHORTTEXT.id]: (value) => (isString(value) ? value : ''),
    [templateTypes.LONGTEXT.id]: (value) => (isString(value) ? value : ''),
  };

  return typeOptions[type](value);
};

export const getSpecificForRequest = async ({ value, ...specific }) => {
  const typeOptions = {
    [templateTypes.IMAGE.id]: async (value) =>
      await getRequestDataForImage({
        image: value,
        getDataForBrowsedImage: (image) => image,
        getDataForExistingImage: (image) => image?.url,
      }),
    [templateTypes.VIDEO.id]: getSameValue,
    [templateTypes.SHORTTEXT.id]: getSameValue,
    [templateTypes.LONGTEXT.id]: getSameValue,
  };

  return {
    ...specific,
    value: await typeOptions[specific.type](value),
  };
};
