import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { assignedGroup } from 'common/propTypes';
import { ExpandIcon, Modal, Popover, MessageComponent } from 'components';
import { statisticsType, statisticsTypeConstants } from 'util/statisticsType';
import GroupItem from './GroupItem';
import { useHistory } from 'react-router-dom';

import './style.scss';

const AssignedGroups = ({ groups, intl: { formatMessage } }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const hasGroups = groups && groups.length > 0;

  const handleGroupClick = (group) => {
    const navigationParams = statisticsTypeConstants[statisticsType.GROUP].navigationData(group);
    history.push(...navigationParams);
  };

  const closeModal = () => setIsModalOpen(false);

  const title = formatMessage({ id: 'MEMBERS_STATISTICS.ASSINED_GROUPS_TITLE' });
  const assignedGroupList = (groups || []).map((group) => (
    <GroupItem key={group.id} group={group} onClick={() => handleGroupClick(group)} />
  ));

  return (
    <>
      <div className="assigned-groups">
        <div className="assigned-groups-header">
          <div className="assigned-groups-header-text">
            <Popover content={title} oneLine placement="top" trigger="hover">
              {title}
            </Popover>
          </div>
          <div className="assigned-groups-header-icon">
            <ExpandIcon
              noWrapper
              color="black"
              disabled={!hasGroups}
              onClick={() => setIsModalOpen(!isModalOpen)}
            />
          </div>
        </div>

        <div className="assigned-groups-divider" />
        {hasGroups ? (
          <div className="assigned-groups-content">{assignedGroupList}</div>
        ) : (
          <MessageComponent
            message={formatMessage({ id: 'MEMBERS_STATISTICS.NO_GROUP_ASSIGNED' })}
            type="info"
          />
        )}
      </div>
      <Modal open={isModalOpen} onClose={closeModal} title={title} hideFooter>
        <div className="assigned-groups-modal-content">{assignedGroupList}</div>
      </Modal>
    </>
  );
};

AssignedGroups.propTypes = {
  groups: PropTypes.arrayOf(assignedGroup),
};

export default injectIntl(AssignedGroups);
