import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import StatisticsPeriodPicker from './StatisticsPeriodPicker';
import StatisticsDatePicker from './StatisticsDatePicker';
import { statisticsTimePeriods } from 'util/statisticsTimeUtil';

import './style.scss';

const TimePeriodAndFromToFilter = ({
  startDate,
  minEndDate,
  maxEndDate,
  minStartDate,
  maxStartDate,
  endDate,
  interval,
  intervalOptions,
  handleStartDateChange,
  handleEndDateChange,
  handlePeriodChange,
  loading,
  asLabel,
  intl: { formatMessage },
}) => {
  const timeFilterContainerClasses = `time-filter-container ${asLabel ? 'as-label' : ''}`;

  return (
    <div>
      <div className={timeFilterContainerClasses}>
        <StatisticsDatePicker
          value={startDate}
          asLabel={asLabel}
          name="startDate"
          label={<FormattedMessage id="STATISTICS.START_DATE" />}
          placeholder={formatMessage({ id: 'STATISTICS.START_DATE_PLACEHOLDER' })}
          minDate={minStartDate ? new Date(minStartDate) : null}
          maxDate={maxStartDate ? new Date(maxStartDate) : null}
          disabled={loading}
          showYearDropdown
          onChange={(startDate) => handleStartDateChange(startDate)}
        />
      </div>
      <div className={timeFilterContainerClasses}>
        <StatisticsDatePicker
          value={endDate}
          asLabel={asLabel}
          disabled={loading}
          name="endDate"
          label={<FormattedMessage id="STATISTICS.END_DATE" />}
          placeholder={formatMessage({ id: 'STATISTICS.END_DATE_PLACEHOLDER' })}
          minDate={minEndDate ? new Date(minEndDate) : null}
          maxDate={maxEndDate ? new Date(maxEndDate) : null}
          showYearDropdown
          onChange={(endDate) => handleEndDateChange(endDate)}
        />
      </div>
      <div className={timeFilterContainerClasses}>
        <StatisticsPeriodPicker
          value={interval}
          asLabel={asLabel}
          disabled={loading}
          name="interval"
          options={intervalOptions}
          label={<FormattedMessage id="STATISTICS.INTERVAL" />}
          onChange={handlePeriodChange}
        />
      </div>
    </div>
  );
};

TimePeriodAndFromToFilter.propTypes = {
  asLabel: PropTypes.bool,
  loading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  interval: PropTypes.string,
  intervalOptions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(statisticsTimePeriods))),
  handleStartDateChange: PropTypes.func.isRequired,
  handleEndDateChange: PropTypes.func.isRequired,
  minEndDate: PropTypes.instanceOf(Date),
  maxEndDate: PropTypes.instanceOf(Date),
  minStartDate: PropTypes.instanceOf(Date),
  maxStartDate: PropTypes.instanceOf(Date),
  handlePeriodChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(TimePeriodAndFromToFilter);
