import * as DashboardService from '../services/dashboard';

import * as actionTypes from 'actions/action-types';

export const getDashboardTopData = (subsidiaryId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_DASHBOARD_TOP_DATA,
      });

      const dashboard = await DashboardService.getDashboardTopData(subsidiaryId);

      dispatch({
        type: actionTypes.GET_DASHBOARD_TOP_DATA_SUCCESS,
        payload: dashboard,
      });

      return dashboard;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DASHBOARD_TOP_DATA_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getDashboardCoursesData = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_DASHBOARD_COURSES_DATA,
      });

      const dashboard = await DashboardService.getDashboardCoursesData(params);

      dispatch({
        type: actionTypes.GET_DASHBOARD_COURSES_DATA_SUCCESS,
        payload: dashboard,
      });

      return dashboard;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DASHBOARD_COURSES_DATA_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getDashboardUsersData = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_DASHBOARD_USERS_DATA,
      });

      const dashboard = await DashboardService.getDashboardUsersData(params);

      dispatch({
        type: actionTypes.GET_DASHBOARD_USERS_DATA_SUCCESS,
        payload: dashboard,
      });

      return dashboard;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DASHBOARD_USERS_DATA_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getDashboardUserData = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_DASHBOARD_USER_DATA,
      });

      const dashboard = await DashboardService.getDashboardUserData(params);

      dispatch({
        type: actionTypes.GET_DASHBOARD_USER_DATA_SUCCESS,
        payload: dashboard,
      });

      return dashboard;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DASHBOARD_USER_DATA_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getRegisteredUsers = (params, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_DASHBOARD_REGISTERED_USER_DATA,
      });

      const response = await DashboardService.getRegisteredUsers(params);

      dispatch({
        type: actionTypes.GET_DASHBOARD_REGISTERED_USER_DATA_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DASHBOARD_REGISTERED_USER_DATA_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const pokeUsers = (data) => {
  return async () => {
    await DashboardService.pokeUsers(data);
  };
};

export const clearDashboard = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_DASHBOARD,
    });
  };
};
