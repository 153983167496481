import { uniqBy } from 'lodash';
import { difference } from 'util/array';

const IS_ASSIGNED_AS_USER = 'isAssignedAsUser';
const IS_ASSIGNED_AS_GROUP = 'isGroup';

export const mapToAssignedUsers = (users = [], groups = []) => {
  const usersAssignedAsGroup = getUniqueUsersFromGroupArray(groups);
  const onlyAssignedAsUser = filterUniqueUsers(users, usersAssignedAsGroup);
  const onlyAssignedAsGroup = filterUniqueUsers(usersAssignedAsGroup, users);
  const assignedAsBoth = filterUniqueUsers(users, onlyAssignedAsUser);

  return [
    ...onlyAssignedAsUser.map((user) => getUserAsAssignedAsUser(user)),
    ...assignedAsBoth.map((user) => getUserAsAssignedAsUser(getUserAsAssignedAsGroup(user))),
    ...onlyAssignedAsGroup.map((user) => getUserAsAssignedAsGroup(user)),
  ];
};

export const filterUniqueUsers = (minuendUsers, subtrahendUsers) =>
  difference(
    minuendUsers,
    subtrahendUsers,
    (minuendUser, subtrahendUser) =>
      minuendUser && subtrahendUser && minuendUser.id === subtrahendUser.id
  );

export const isAssignedAsUser = (user) => !!user && user[IS_ASSIGNED_AS_USER];
export const isAssignedAsGroup = (user) => !!user && user[IS_ASSIGNED_AS_GROUP];

export const getUserAsAssignedAsUser = (user = {}) => ({ ...user, [IS_ASSIGNED_AS_USER]: true });

export const getUserAsUnAssignedAsUser = (user = {}) => ({ ...user, [IS_ASSIGNED_AS_USER]: false });

export const getUserAsAssignedAsGroup = (user = {}) => ({ ...user, [IS_ASSIGNED_AS_GROUP]: true });

export const getUserAsUnAssignedAsGroup = (user = {}) => ({
  ...user,
  [IS_ASSIGNED_AS_GROUP]: false,
});

export const filterUsersAssignedOnlyAsUser = (users) =>
  users ? users.filter((user) => user[IS_ASSIGNED_AS_USER]) : [];

export const getUniqueUsersFromGroupArray = (groups = []) =>
  uniqBy(
    groups.reduce((acc, group) => [...acc, ...(group && group.users ? group.users : [])], []),
    'id'
  );
