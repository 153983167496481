import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from 'components';
import { getFieldError, hasFieldError } from '../formUtil';

const DatePickerField = ({ form, field, hasErrorComponent = true, ...props }) => {
  const hasErrorMessage = hasFieldError({ form, field });

  const onChange = (date) => {
    if (form && field) {
      form.setFieldValue(field.name, date);
      form.setFieldTouched(field.name, true);
    }
  };

  return (
    <DatePicker
      {...props}
      value={field.value}
      onChange={onChange}
      hasErrorMessage={hasErrorMessage}
      errorMessage={getFieldError({ form, field })}
      hasErrorComponent={hasErrorComponent}
    />
  );
};

DatePickerField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  hasErrorComponent: PropTypes.bool,
};

export default DatePickerField;
