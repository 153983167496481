import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  RESET_LOGIN_ERROR,
  LOGOUT,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_LOGIN_ERROR:
      return {
        ...state,
        error: null,
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
