import React, { useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { Switch, Route } from 'react-router-dom';

const OnPageRouter = ({ match, Page, Editor }) => {
  const [previousParams, setPreviousParams] = useState();
  const previousPage = qs.stringify(previousParams)
    ? `${match.path}?${qs.stringify(previousParams, { arrayFormat: 'bracket' })}`
    : match.path;

  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${match.path}/`}
          render={(props) => (
            <Page
              {...props}
              previousParams={previousParams}
              setPreviousParams={setPreviousParams}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/new`}
          render={(props) => <Editor {...props} previousPage={previousPage} />}
        />
        <Route
          exact
          path={`${match.path}/:id/edit`}
          render={(props) => <Editor {...props} previousPage={previousPage} edit />}
        />
      </Switch>
    </div>
  );
};

OnPageRouter.propTypes = {
  match: PropTypes.object,
  Page: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  Editor: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default OnPageRouter;
