import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import isString from 'lodash/isString';
import union from 'lodash/union';

import Settings from 'config/settings';
import { isFalseOrStringFalse, isTrueOrStringTrue } from 'util/boolUtil';
import { consoleErrorOnDev } from 'util/consoleErrorOnDev';

const hasType = (item) => !!item?.type;

const getError = (value, type) => new Error(`"${value}" query value is not of type "${type}"!`);

const typeConversion = ({ type }) => {
  const conversion = {
    boolean: (value) => {
      if (isTrueOrStringTrue(value)) {
        return true;
      }
      if (isFalseOrStringFalse(value)) {
        return false;
      }
      throw getError(value, 'boolean');
    },
    number: (value) => {
      const number = toNumber(value);
      if (isNaN(number)) {
        throw getError(value, 'number');
      }
      return number;
    },
    string: (value) => {
      if (isString(value)) {
        return value;
      }
      throw getError(value, 'string');
    },
  };
  const conversionFunc = conversion[type];

  if (!conversionFunc) {
    throw new Error('Wrong conversion type!');
  }
  return conversionFunc;
};

const configureGetConvertedValue = (param) => {
  if (hasType(param)) {
    return typeConversion(param);
  }

  return (value) => value;
};

const getDefaultQueryParamValue = (defaultParam) =>
  hasType(defaultParam) ? defaultParam?.defaultValue : defaultParam;

const getCurrentQueryParams = ({ currentQuery = {}, defaultQueryParams = {} }) =>
  union(Object.keys(currentQuery), Object.keys(defaultQueryParams)).reduce((acc, param) => {
    if (currentQuery[param] !== undefined) {
      return { ...acc, [param]: currentQuery[param] };
    }
    if (defaultQueryParams[param] !== undefined) {
      return { ...acc, [param]: getDefaultQueryParamValue(defaultQueryParams[param]) };
    }
    return acc;
  }, {});

export const useQueryParams = (queryParamsConfig) => {
  const { replace } = useHistory();
  const { pathname, search } = useLocation();

  const paramConfig = useMemo(
    () =>
      Object.entries(queryParamsConfig || {}).reduce(
        (acc, [key, config]) => ({ ...acc, [key]: configureGetConvertedValue(config) }),
        {}
      ),
    [queryParamsConfig]
  );

  const getConvertedParams = useCallback(
    (params = {}) =>
      Object.entries(params).reduce((acc, [key, value]) => {
        try {
          return {
            ...acc,
            [key]: paramConfig[key] ? paramConfig[key](value) : value,
          };
        } catch (e) {
          consoleErrorOnDev(e);
          return acc;
        }
      }, {}),
    [paramConfig]
  );

  const getParams = useCallback(() => {
    const currentQueryParams = qs.parse(search, Settings.QUERY_FORMAT);

    const { page, searchText, sortBy, order, ...params } = getCurrentQueryParams({
      currentQuery: currentQueryParams,
      defaultQueryParams: queryParamsConfig,
    });

    return {
      page: parseInt(page) || 1,
      searchText,
      sortBy,
      order,
      ...getConvertedParams(params),
    };
  }, [queryParamsConfig, search, getConvertedParams]);

  const [params, setParams] = useState(getParams());

  const setCurrentParams = useCallback(({ page, searchText, sortBy, order, ...filter }) => {
    const params = {
      page,
      searchText,
      sortBy,
      order,
      ...filter,
    };

    setParams(params);
  }, []);

  useEffect(() => {
    replace({
      path: pathname,
      search: qs.stringify(
        {
          ...qs.parse(search, Settings.QUERY_FORMAT),
          ...params,
        },
        Settings.QUERY_FORMAT
      ),
    });
  }, [params, pathname, replace, search]);

  return { params, setCurrentParams };
};
