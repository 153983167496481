import React from 'react';
import PropTypes from 'prop-types';

import { shapeTypes, orderComparator } from '../chartUtil';

import './styles.scss';

const CustomLegend = ({ payload, margin }) => {
  const orderComparatorForLegendItems = orderComparator((legendItem) =>
    legendItem && legendItem.payload ? legendItem.payload.legendAndTooltipOrder : null
  );
  const legendItems = (payload ? [...payload] : []).sort(orderComparatorForLegendItems);

  return (
    <div
      className="legend"
      style={{
        marginTop: (margin && margin.top) || 0,
        marginBottom: (margin && margin.bottom) || 0,
        marginLeft: (margin && margin.left) || 0,
        marginRight: (margin && margin.right) || 0,
      }}>
      {legendItems.map((entry, index) => (
        <div className="legend-item" key={`item-${index}`}>
          <div
            className="legend-item-icon"
            style={{
              border:
                entry.type === shapeTypes.RECT &&
                entry.payload &&
                entry.payload.stroke &&
                `1px solid ${entry.payload.stroke}`,
              backgroundColor: entry.color,
            }}
          />
          <div className="legend-item-label">{entry.value}</div>
        </div>
      ))}
    </div>
  );
};

CustomLegend.propTypes = {
  payload: PropTypes.array,
  margin: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
};

export default CustomLegend;
