import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'formik';

import Settings from 'config/settings';
import { UpdateForm, TextField } from 'components';
import { Yup } from 'util/Yup';
import { getValidationMessageInput } from 'libs/validation/validation';

const CompanyContactEditor = ({ createContact, editContact, closeEditor, contact, open }) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    contactType: Yup.string().required(),
    department: Yup.string().required(),
    position: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNumber: Yup.string()
      .matches(
        new RegExp(Settings.PHONE_PATTERN),
        getValidationMessageInput('COMMON.FIELD_PHONE_ERROR')
      )
      .required(),
  });
  return (
    <UpdateForm
      open={open}
      shadowed
      closeEditor={closeEditor}
      create={createContact}
      update={editContact}
      modalTitle={
        contact?.id ? (
          <FormattedMessage id="CONTACTS.EDIT" />
        ) : (
          <FormattedMessage id="CONTACTS.ADD" />
        )
      }
      initialValues={contact}
      validateOnBlur={false}
      validationSchema={validationSchema}>
      <Form>
        <div className="default-modal-body-padding">
          <div className="row">
            <div className="col-6">
              <Field
                name="firstName"
                component={TextField}
                label={<FormattedMessage id="USERS.FIRST_NAME_REQ" />}
              />
            </div>
            <div className="col-6">
              <Field
                name="lastName"
                component={TextField}
                label={<FormattedMessage id="USERS.LAST_NAME_REQ" />}
              />
            </div>
            <div className="col-6">
              <Field
                name="position"
                component={TextField}
                label={<FormattedMessage id="JOBS.POSITION_REQ" />}
              />
            </div>
            <div className="col-6">
              <Field
                name="email"
                component={TextField}
                label={<FormattedMessage id="COMMON.EMAIL_REQ" />}
              />
            </div>
            <div className="col-6">
              <Field
                name="phoneNumber"
                component={TextField}
                label={<FormattedMessage id="COMMON.PHONE_NUMBER_REQ" />}
              />
            </div>
            <div className="col-6">
              <Field
                name="contactType"
                component={TextField}
                label={<FormattedMessage id="COMMON.CONTACT_TYPE_REQ" />}
              />
            </div>
            <div className="col-6">
              <Field
                name="department"
                component={TextField}
                label={<FormattedMessage id="COMMON.DEPARTMENT_REQ" />}
              />
            </div>
          </div>
        </div>
      </Form>
    </UpdateForm>
  );
};

CompanyContactEditor.propTypes = {
  createContact: PropTypes.func.isRequired,
  editContact: PropTypes.func.isRequired,
  closeEditor: PropTypes.func.isRequired,
  contact: PropTypes.object,
  open: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default injectIntl(withRouter(CompanyContactEditor));
