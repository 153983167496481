import { connect } from 'react-redux';
import store from '../../config/store';

export const renderChildren = (showFor, children, user) => {
  const _user = user || store.getState().users.profile;

  if (
    (_user.profile &&
      showFor &&
      showFor.filter(
        (value) =>
          -1 !==
          (_user.profile.roles
            ? _user.profile.roles
                .map((role) => role.name.toLowerCase())
                .indexOf(value.toLowerCase())
            : -1)
      ).length) ||
    !showFor
  ) {
    return children;
  }
  return null;
};

const ShowFor = ({ showFor, children, user }) => {
  return renderChildren(showFor, children, user);
};

const mapStateToProps = (state) => ({ user: state.users.profile });

export default connect(mapStateToProps)(ShowFor);
