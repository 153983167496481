import React from 'react';
import PropTypes from 'prop-types';

const ConfirmMessage = ({ children }) => {
  return <div className="text-center py-0 px-1">{children}</div>;
};

ConfirmMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default ConfirmMessage;
