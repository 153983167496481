import { compose } from 'lodash/fp';
import differenceInDays from 'date-fns/differenceInDays';

import Settings from 'config/settings';
import { getDateByFormat, getDateFromTimeStamp } from 'libs/date';

export const getDaysUntilExpiration = (licenseExpiration) =>
  differenceInDays(getDateFromTimeStamp(licenseExpiration), Date.now());

export const getExpirationDate = compose(
  getDateByFormat(Settings.DATE_FORMAT),
  getDateFromTimeStamp
);

export const getNextLicenceExpirationFieldText = (licenseExpiration) => {
  return licenseExpiration && Number.isInteger(licenseExpiration)
    ? `${getExpirationDate(licenseExpiration)} (${getDaysUntilExpiration(licenseExpiration)} days)`
    : '-';
};
