import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CheckBoxGroupHeader from './CheckBoxGroupHeader';
import { CheckBox } from 'components';

const isCheckBoxChecked = (selectedOptions, option) =>
  selectedOptions.find((value) =>
    typeof option.id !== 'undefined'
      ? value === option.id || value.id === option.id
      : value === option || value.id === option
  );

const CheckBoxGroup = ({
  options,
  label,
  disabled,
  emptyMessage,
  selectedOptions,
  onChange,
  selectFilters,
  clearFilters,
  className,
  intl: { formatMessage },
}) => {
  return (
    <div className={className}>
      <CheckBoxGroupHeader
        label={label}
        disabled={disabled}
        hasSelectedOptions={selectedOptions.length > 0}
        clearFilters={clearFilters}
        selectFilters={selectFilters}
      />

      <div className="d-flex row justify-content-start">
        {options && options.length > 0
          ? options
              .filter(
                (v, i, a) =>
                  a
                    .map((option) => option.name || option.title || option)
                    .indexOf(v.name || v.title || v) === i
              )
              .map((option) => (
                <CheckBox
                  className="col-6 d-flex mb-2"
                  key={option.id}
                  label={option.displayName || option.name || option.title || option.toUpperCase()}
                  disabled={disabled}
                  checked={isCheckBoxChecked(selectedOptions, option)}
                  name={`${option.id}`}
                  onChange={(e) => onChange(e, option.id)}
                />
              ))
          : emptyMessage || formatMessage({ id: 'COMMON.EMPTY_CHECKBOX_GROUP' })}
      </div>
    </div>
  );
};

CheckBoxGroup.propTypes = {
  label: PropTypes.node.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  clearFilters: PropTypes.func.isRequired,
  selectFilters: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.node,
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CheckBoxGroup);
