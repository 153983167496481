import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Field } from 'formik';

import { CompanySubsidiaryChooser, TextField } from 'components';

const CompanyGroupBaseDataTab = ({ showCompanySubsidiaryChooser, intl: { formatMessage } }) => (
  <>
    {showCompanySubsidiaryChooser && (
      <CompanySubsidiaryChooser
        companyInputName="company"
        subsidiaryInputName="subsidiary"
        companyLabel={formatMessage({ id: 'COMPANIES.COMPANY_REQ' })}
        subsidiaryLabel={formatMessage({ id: 'SUBSIDIARY.TITLE_REQ' })}
        companyOptionAsObject
        subsidiaryOptionAsObject
      />
    )}
    <Field name="name" component={TextField} label={formatMessage({ id: 'COMMON.NAME' })} />
    <Field
      name="description"
      component={TextField}
      label={formatMessage({ id: 'GROUPS.DESCRIPTION' })}
    />
  </>
);

CompanyGroupBaseDataTab.propTypes = {
  showCompanySubsidiaryChooser: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CompanyGroupBaseDataTab);
