import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { hideLoader, showLoader } from 'actions/common';
import { getLessonsHistory } from 'services/lessons';
import { Modal, TableContainer } from 'components';

const LessonHistory = ({
  closeModal,
  showLoader,
  hideLoader,
  lessonId,
  open,
  intl: { formatMessage },
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (open && lessonId) {
      const fetchData = async () => {
        showLoader();
        try {
          const response = await getLessonsHistory(lessonId);
          setUsers(response.users);
        } finally {
          hideLoader();
        }
      };
      fetchData();
    }
  }, [open, lessonId, hideLoader, showLoader]);

  const close = useCallback(() => {
    setUsers([]);
    closeModal();
  }, [closeModal]);

  return (
    <Modal
      open={open}
      onClose={close}
      large
      saveButton={false}
      title={<FormattedMessage id="COMMON.HISTORY" />}>
      <TableContainer
        list={users || []}
        columns={[
          { key: 'userEmail', label: formatMessage({ id: 'COMMON.EMAIL' }) },
          { key: 'userName', label: formatMessage({ id: 'COMMON.NAME' }) },
          { key: 'level', label: formatMessage({ id: 'TEMPLATES.LEVEL' }) },
          { key: 'receivedXP', label: formatMessage({ id: 'LESSONS.RECEIVED_XP' }) },
          { key: 'result', label: formatMessage({ id: 'LESSONS.RESULT' }) },
          { key: 'testDone', label: formatMessage({ id: 'LESSONS.TEST_DONE' }) },
          { key: 'testSuccess', label: formatMessage({ id: 'LESSONS.TEST_SUCCESSFUL' }) },
        ]}
      />
    </Modal>
  );
};

LessonHistory.propTypes = {
  closeModal: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  lessonId: PropTypes.number,
  open: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

export default injectIntl(connect(null, mapDispatchToProps)(LessonHistory));
