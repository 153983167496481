import React from 'react';
import PropTypes from 'prop-types';

import ExampleValues from './ExampleValues/ExampleValues';
import { TextInputAdditions } from 'components';

const TextFieldAdditions = ({ exampleValues, onExampleValueChange, ...props }) => {
  return (
    <>
      <TextInputAdditions {...props} />
      {exampleValues ? (
        <ExampleValues
          className="pt-3"
          onClick={
            onExampleValueChange
              ? (_, exampleValue) => onExampleValueChange(exampleValue)
              : undefined
          }
          values={exampleValues}
        />
      ) : null}
    </>
  );
};

TextFieldAdditions.propTypes = {
  exampleValues: PropTypes.array,
  onExampleValueChange: PropTypes.func,
};

export default TextFieldAdditions;
