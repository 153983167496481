import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Settings from 'config/settings';
import { CrudPage, tableResizeDetector, Filter } from 'components';
import { getPaths, deletePath as deleteLearningPath } from 'services/path';
import { PathAccess } from 'libs/accessManagement';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';
import { pathFilterTypes } from 'libs/filterTypes';

import PathTable from './PathTable';
import AssignPath from './AssignPath';

const initialPath = {
  id: null,
  title: '',
  isPublic: true,
  canBeDeleted: false,
  canAccess: true,
  owner: '',
  lessonCount: 0,
};

const defaultParams = {
  sortBy: Settings.SORT_BY.TITLE,
  order: Settings.ORDER.ASC,
  searchIn: ['title', 'subsidiary'],
};

const fetchPath = async (params) => await getPaths(params);

const PathPage = ({ profile, match, history }) => {
  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const [assignPath, setAssignPath] = useState(null);

  const {
    data,
    isLoading,
    params,
    setCurrentParams,
    changeParams,
    changeParamsAndResetPage,
  } = useFetchWithQueryParams({
    fetch: fetchPath,
    queryParamsConfig: defaultParams,
  });
  const count = data?.count || 0;
  const total = data?.length || 0;
  const pathList = data?.data || [];

  const openNew = () => {
    history.push(`${match.url}/new`);
  };

  const openEditor = (path) => {
    history.push(`${match.url}/${path.id}/edit`);
  };

  const deletePath = async (id) => {
    await deleteLearningPath(id);
    setCurrentParams(params);
  };

  const openAssign = (path) => {
    setAssignPath(path);
    setIsAssignOpen(true);
  };

  const closeAssignModel = () => {
    setIsAssignOpen(false);
  };

  const { hasRoleToCreate } = PathAccess.getHasRoles(profile.profile);

  return (
    <CrudPage
      title={<FormattedMessage id="PATH.TITLE" />}
      addRowLabel={<FormattedMessage id="PATH.ADD_PATH" />}
      deleteTitle={<FormattedMessage id="PATH.DELETE_PATH" />}
      deleteKey="title"
      setCurrentParams={setCurrentParams}
      deleteItem={deletePath}
      onDelete={() => setCurrentParams(params)}
      params={params}
      initialData={initialPath}
      count={count}
      total={total}
      hasRoleToCreate={hasRoleToCreate}
      filterBySearchText
      addRow={openNew}
      loading={isLoading}
      additionalFilters={() => (
        <Filter
          options={params.searchIn || []}
          onChange={(searchIn) => changeParamsAndResetPage({ searchIn })}
          availableOptions={pathFilterTypes}
        />
      )}>
      {({ openDeleteModal }) => (
        <>
          <PathTable
            profile={profile}
            list={pathList ? pathList : []}
            openEditor={openEditor}
            openAssign={openAssign}
            openDeleteModal={(path) => openDeleteModal(path)}
            count={count}
            params={params}
            loadList={setCurrentParams}
            loadPage={(page) => changeParams({ ...page })}
            loading={isLoading}
            currentPage={params.page}
          />
          <AssignPath open={isAssignOpen} path={assignPath} close={closeAssignModel} />
        </>
      )}
    </CrudPage>
  );
};

PathPage.propTypes = {
  profile: PropTypes.object.isRequired,
  maxWidth: PropTypes.number.isRequired,
  resize: PropTypes.func.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

export default connect(mapStateToProps)(tableResizeDetector()(PathPage));
