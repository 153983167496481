import PropTypes from 'prop-types';
import { inputErrorPropTypes } from 'common';

const InputError = ({
  hasErrorComponent,
  hasErrorMessage,
  hideValue,
  errorMessage,
  renderErrorComponent,
}) => {
  return hasErrorComponent
    ? renderErrorComponent({ show: !!hasErrorMessage && !hideValue, errorMessage })
    : null;
};

InputError.propTypes = { ...inputErrorPropTypes, renderErrorComponent: PropTypes.func.isRequired };

export default InputError;
