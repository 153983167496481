import intersection from 'lodash/intersection';
import union from 'lodash/union';

export const Roles = {
  SYSTEM_ADMIN: 'system_admin',
  COMPANY_ADMIN: 'company_admin',
  SUBSIDIARY_ADMIN: 'subsidiary_admin',
  SUBSIDIARY_MANAGER: 'subsidiary_manager',
  CONTENT_MANAGER: 'content_manager',
  CONTENT_VALIDATOR: 'content_validator',
  CORPORATE_USER: 'corporate_user',
  USER: 'user',
};

export const ROLES_WITH_SUBSIDIARIES = [
  Roles.SUBSIDIARY_ADMIN,
  Roles.SUBSIDIARY_MANAGER,
  Roles.CONTENT_MANAGER,
  Roles.CONTENT_VALIDATOR,
  Roles.CORPORATE_USER,
];

export const ROLES_WITH_COMPANIES = [Roles.COMPANY_ADMIN];

export const RoleItemsName = {
  SUBSIDIARIES: 'subsidiaries',
  COMPANIES: 'companies',
};

const getRolesToDistributeForRole = (role) => {
  switch (role) {
    case Roles.SYSTEM_ADMIN:
      return [
        Roles.SYSTEM_ADMIN,
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
        Roles.CONTENT_MANAGER,
        Roles.CONTENT_VALIDATOR,
        Roles.CORPORATE_USER,
      ];
    case Roles.COMPANY_ADMIN:
      return [
        Roles.COMPANY_ADMIN,
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
        Roles.CONTENT_MANAGER,
        Roles.CONTENT_VALIDATOR,
        Roles.CORPORATE_USER,
      ];
    case Roles.SUBSIDIARY_ADMIN:
      return [
        Roles.SUBSIDIARY_ADMIN,
        Roles.SUBSIDIARY_MANAGER,
        Roles.CONTENT_MANAGER,
        Roles.CONTENT_VALIDATOR,
        Roles.CORPORATE_USER,
      ];
    case Roles.SUBSIDIARY_MANAGER:
      return [
        Roles.SUBSIDIARY_MANAGER,
        Roles.CONTENT_MANAGER,
        Roles.CONTENT_VALIDATOR,
        Roles.CORPORATE_USER,
      ];
    default:
      return [];
  }
};

export const getRoleNamesToDistributeForProfile = (profile) => {
  const profileRoleNameList =
    profile && profile.roles && profile.roles.map ? profile.roles.map(({ name }) => name) : [];
  return profileRoleNameList.reduce(
    (acc, profileRole) => union(acc, getRolesToDistributeForRole(profileRole)),
    []
  );
};

export const getListableRoleNamesForProfile = (profile) => {
  const res = union(getRoleNamesToDistributeForProfile(profile), [Roles.USER]);
  return res;
};

export const getRolePrefix = (role) => {
  if (role === Roles.SYSTEM_ADMIN) {
    return 'System';
  } else if (role === Roles.COMPANY_ADMIN || role === Roles) {
    return 'Company';
  } else {
    return 'Subsidiary';
  }
};

const getItemsForRole = (itemType) => (roleType) => (profile) => {
  const queriedRole = profile && profile.roles.find(({ name }) => name === roleType);
  return queriedRole && queriedRole[itemType] ? queriedRole[itemType] : [];
};

export const getSubsidiariesForRole = getItemsForRole(RoleItemsName.SUBSIDIARIES);
export const getCompaniesForRole = getItemsForRole(RoleItemsName.COMPANIES);

export const hasRoleFor = (listType) => (roleType) => (profile) => (elementid) => {
  return !!(
    hasRole(profile, roleType) &&
    profile.roles.find(({ name }) => name === roleType)[listType] &&
    profile.roles
      .find(({ name }) => name === roleType)
      [listType].find((obj) => obj.id === elementid)
  );
};

export const getHighestRole = (profile) => {
  return hasRole(profile, [Roles.SYSTEM_ADMIN])
    ? Roles.SYSTEM_ADMIN
    : hasRole(profile, [Roles.COMPANY_ADMIN])
    ? Roles.COMPANY_ADMIN
    : hasRole(profile, [Roles.SUBSIDIARY_ADMIN])
    ? Roles.SUBSIDIARY_ADMIN
    : hasRole(profile, [Roles.SUBSIDIARY_MANAGER])
    ? Roles.SUBSIDIARY_MANAGER
    : hasRole(profile, [Roles.CONTENT_MANAGER])
    ? Roles.CONTENT_MANAGER
    : hasRole(profile, [Roles.CONTENT_VALIDATOR])
    ? Roles.CONTENT_VALIDATOR
    : Roles.USER;
};

export const getRoleByName = (roleName, profile) => {
  return profile && profile.roles ? profile.roles.find(({ name }) => name === roleName) : null;
};

export const hasRole = (profile, roles) => {
  return !!profile.roles && profile.roles.find(({ name }) => roles.indexOf(name) > -1);
};

export const hasCompanyRole = (profile) => !!hasRole(profile, [Roles.COMPANY_ADMIN]);

export const hasSubsidiaryRole = (profile) =>
  !!hasRole(profile, [Roles.SUBSIDIARY_ADMIN, Roles.SUBSIDIARY_MANAGER]);

export const hasAdminRole = (profile) =>
  !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.COMPANY_ADMIN, Roles.SUBSIDIARY_ADMIN]);

export const hasSystemAdminRole = (profile) => !!hasRole(profile, [Roles.SYSTEM_ADMIN]);

export const hasRoleToAssignUser = (profile) =>
  !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.SUBSIDIARY_MANAGER]);

export const hasRoleToAssignLesson = (profile) =>
  !!hasRole(profile, [Roles.SYSTEM_ADMIN, Roles.CONTENT_MANAGER]);

export default {
  Roles,
  hasRole,
  hasCompanyRole,
  hasSubsidiaryRole,
  hasAdminRole,
  hasRoleToAssignUser,
  hasRoleToAssignLesson,
};

export const updateProfileGroups = (getState, subsidiaryId, key, negative) => {
  const roles = JSON.parse(JSON.stringify(getState().users.profile.profile.roles));

  let maingroupIds = null;

  roles.some((role) => {
    const group = role.subsidiaries.find(({ id }) => +id === +subsidiaryId);
    maingroupIds = group ? group.maingroupIds : null;
    return !!maingroupIds;
  });

  roles.map((role) => {
    role.subsidiaries.map((group) => {
      if (intersection(group.maingroupIds, maingroupIds).length > 0) {
        group[key] = group[key] + (negative ? -1 : 1);
      }
      return group;
    });
    return role;
  });

  return roles;
};

export const roleTypes = {
  SYSTEM: 'system',
  COMPANY: 'company',
  SUBSIDIARY: 'subsidiary',
};

export const getRoleType = (location) => {
  return location.pathname.substr(location.pathname.lastIndexOf('/') + 1, location.pathname.length);
};

export const isCompany = (location) => {
  return getRoleType(location) === roleTypes.COMPANY;
};
