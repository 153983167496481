import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';

import SpecificsModal from '../../Templates/SpecificsModal/SpecificsModal';
import LessonsSpecificsHeader from './LessonsSpecificsHeader';
import LessonsSortableElement from './LessonsSortableElement';
import SortableDnDWrapper from 'components/Table/Sortable/SortableDnDWrapper';

import { withError } from 'hoc';

const LessonsSortableContainer = ({
  specifics,
  onChange,
  removeSpecific,
  disabled,
  disabledForm,
  className,
  onSortEnd,
  ...props
}) => {
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = specifics.findIndex((item) => item.id === active.id);
      const newIndex = specifics.findIndex((item) => item.id === over.id);

      if (onSortEnd) {
        onSortEnd(arrayMove(specifics, oldIndex, newIndex));
      }
    }
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
      <SortableContext
        items={specifics.map((item) => item.id)}
        strategy={verticalListSortingStrategy}>
        <div className="col-12 p-0">
          {specifics && specifics.length > 0
            ? specifics.map((specific, index) => (
                <SortableDnDWrapper id={specific.id} key={specific.id}>
                  {({ setNodeRef, attributes, listeners, style }) => (
                    <LessonsSortableElement
                      ref={setNodeRef}
                      attributes={attributes}
                      listeners={listeners}
                      style={style}
                      onChange={onChange}
                      removeSpecific={removeSpecific}
                      disabled={disabled}
                      disabledForm={disabledForm}
                      className={className}
                      specific={specific}
                      itemIndex={index}
                      index={index}
                      {...props}
                    />
                  )}
                </SortableDnDWrapper>
              ))
            : null}
        </div>
      </SortableContext>
    </DndContext>
  );
};

const LessonsSpecificsTable = ({ ...props }) => {
  const [openModal, setOpenModal] = useState(false);
  const [editSpecificIndex, setEditSpecificIndex] = useState(-1);
  const openModalSpecific = (editSpecificIndex) => {
    setOpenModal(true);
    setEditSpecificIndex(editSpecificIndex);
  };

  const closeSpecificsModal = () => {
    setOpenModal(false);
    setEditSpecificIndex(-1);
  };
  return (
    <FieldArray
      name="specifics"
      render={(arrayHelpers) => (
        <>
          <LessonsSpecificsHeader
            onAdd={() => openModalSpecific(editSpecificIndex)}
            disabled={props.disabled}
          />
          <SpecificsModal
            open={openModal}
            editSpecificIndex={editSpecificIndex}
            closeSpecificsModal={closeSpecificsModal}
            specifics={props.specifics}
            disabled={props.disabled}
            arrayHelpers={arrayHelpers}
          />
          <LessonsSortableContainer
            {...props}
            removeSpecific={(index) => {
              arrayHelpers.remove(index);
            }}
            openModal={openModalSpecific}
            helperClass="Table padded specifics-table sortable-item d-flex align-items-center"
            useDragHandle
            arrayHelpers={arrayHelpers}
          />
        </>
      )}
    />
  );
};

LessonsSpecificsTable.propTypes = {
  specifics: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  disabledForm: PropTypes.bool,
  className: PropTypes.string,
  errors: PropTypes.object,
  injectIntl: PropTypes.object,
};

LessonsSortableContainer.propTypes = {
  specifics: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  disabledForm: PropTypes.bool,
  className: PropTypes.string,
  removeSpecific: PropTypes.func,
  onChange: PropTypes.func,
  onSortEnd: PropTypes.func,
};

export default withError(injectIntl(LessonsSpecificsTable));
