import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { TableContainer, AddRow, ConfirmModal } from 'components';
import { horizontalAlignOptions, widthOptions } from 'components/Table/tableConstants';
import { roleTypes, Roles } from 'libs/roles';
import { getUser } from '../UsersPage/User';
import UserDataEditor from '../UsersPage/UserEditor/UserDataEditor/UserDataEditor';
import UserRoleEditor from '../UsersPage/UserEditor/UserRoleEditor/UserRoleEditor';
import { modalType } from 'util/modalType';
import { CompanyAccess, SubsidiaryAccess } from 'libs/accessManagement';
import { getUserIdentifier } from 'containers/UsersPage/User';
import { deleteAdminFromSubsidiary } from 'services/subsidiaries';
import { deleteAdminFromCompany } from 'services/companies';

const getInitialUserData = (user) => {
  return { id: user.id };
};

const CompanyUsersTab = ({
  roles,
  profile,
  company,
  onSaved,
  isCompany,
  intl: { formatMessage },
}) => {
  const [user, setUser] = useState(getUser());
  const [modal, setModal] = useState(null);
  const admins = company?.admins || [];

  const deleteAdmin = async (admin) => {
    if (isCompany) {
      await deleteAdminFromCompany(company.id, admin.id);
    } else {
      await deleteAdminFromSubsidiary(company.id, admin.id);
    }
    onSaved();
  };

  const openModal = (thisModal, thisUser = getUser()) => {
    setUser(thisUser);
    setModal(thisModal);
  };

  const closeModal = (fetchCompany) => {
    if (onSaved && fetchCompany && !!modal) {
      onSaved();
    }
    setUser(getUser());
    setModal(null);
  };

  const handleDelete = async () => {
    try {
      await deleteAdmin(user);
    } finally {
      closeModal();
    }
  };

  const getAdminRole = (isCompany) => {
    return roles.filter(
      ({ name }) => name === (isCompany ? Roles.COMPANY_ADMIN : Roles.SUBSIDIARY_ADMIN)
    );
  };

  const getInitialRoleData = () => {
    return {
      companyObject: isCompany ? company : company.companies,
      roles: getAdminRole(isCompany).map(({ id }) => id),
      ...(!isCompany && company && { subsidiaryObject: company }),
    };
  };

  const {
    AdminAccess: {
      hasRoleToAdd: hasRoleToAddSubsidiaryAdmin,
      hasRoleToEdit: hasRoleToEditSubsidiaryAdmin,
      hasRoleToDelete: hasRoleToDeleteSubsidiaryAdmin,
    },
  } = SubsidiaryAccess.getHasRoles(profile.profile);

  const {
    AdminAccess: {
      hasRoleToAdd: hasRoleToAddCompanyAdmin,
      hasRoleToEdit: hasRoleToEditCompanyAdmin,
      hasRoleToDelete: hasRoleToDeleteCompanyAdmin,
    },
  } = CompanyAccess.getHasRoles(profile.profile);

  const initialRoleData = getInitialRoleData();
  return (
    <div className="row">
      {admins && (
        <>
          <div className="col-12 d-flex flex-wrap mt-4">
            <h5 className="col-6 p-0">
              <FormattedMessage id="COMPANY_GROUPS.MANAGEMENT_MEMBERS" />
            </h5>

            {(isCompany ? hasRoleToAddCompanyAdmin : hasRoleToAddSubsidiaryAdmin) && (
              <div className="col-6 p-0 d-flex justify-content-end">
                <AddRow
                  onAdd={() => openModal(modalType.ROLES)}
                  label={<FormattedMessage id="COMMON.ADD" />}
                  className="mb-0 align-items-center"
                />
              </div>
            )}
          </div>

          <UserDataEditor
            open={modal === modalType.EDIT}
            userId={getInitialUserData(user).id}
            shadowed
            closeEditor={() => closeModal(true)}
          />
          <UserRoleEditor
            open={modal === modalType.ROLES}
            closeEditor={() => closeModal(true)}
            initialRoleData={initialRoleData}
            shadowed
            existingEmails={admins.map(({ email }) => email)}
            availableRoles={getAdminRole(isCompany)}
            type={isCompany ? roleTypes.COMPANY : roleTypes.SUBSIDIARY}
            showCompanySubsidiaryChooser={false}
          />

          <ConfirmModal
            open={modal === modalType.DELETE}
            onClose={() => closeModal()}
            title={<FormattedMessage id="USERS.DELETE_USER" />}
            saveAction={handleDelete}
            confirmElement={
              <FormattedMessage id="COMMON.DELETE_QUESTION" values={{ title: `${user.email}` }} />
            }
          />

          <div className="col-12">
            <TableContainer
              list={admins}
              columns={[
                {
                  key: 'edit',
                  width: widthOptions.sm,
                  hide: isCompany ? !hasRoleToEditCompanyAdmin : !hasRoleToEditSubsidiaryAdmin,
                  iconCell: true,
                  onClick: (user) => openModal(modalType.EDIT, user),
                },
                {
                  key: 'name',
                  maxWidth: widthOptions.xl,
                  fillRemainingSpace: true,
                  label: formatMessage({ id: 'COMPANY_GROUPS.MEMBERS' }),
                  horizontalAlign: horizontalAlignOptions.left,
                  itemTooltip: (contact) => getUserIdentifier(contact),
                  render: (contact) => getUserIdentifier(contact),
                },
                {
                  key: 'delete',
                  width: widthOptions.sm,
                  hide: isCompany ? !hasRoleToDeleteCompanyAdmin : !hasRoleToDeleteSubsidiaryAdmin,
                  iconCell: true,
                  onClick: (user) => openModal(modalType.DELETE, user),
                  deleteIcon: true,
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.roles.roles.data,
    profile: state.users.profile,
  };
};

CompanyUsersTab.propTypes = {
  roles: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  intl: PropTypes.object,
  company: PropTypes.object,
  onSaved: PropTypes.func,
  isCompany: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps)(injectIntl(CompanyUsersTab)));
