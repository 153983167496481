import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { hideLoader, showLoader } from 'actions/common';
import { PageHeader } from 'components';
import PagePreHeader from './PagePreHeader';
import AppLoader from 'containers/App/AppLoader';

import './style.scss';

const BasicPage = ({
  className,
  count,
  children,
  showLoader,
  hideLoader,
  filterSubmit,
  filterData,
  filterBySearchText,
  indicators,
  params,
  hidePreHeader,
  additionalPreHeaderIconContainerItem,
  additionalFilters,
  searchFieldCentered,
  iconContainerClassName,
  setCurrentParams,
  title,
  total,
  loading,
}) => {
  return (
    <div className={className}>
      <PageHeader title={title} />
      <AppLoader show={loading} />
      <div className={classNames('basic-page__content')}>
        {!hidePreHeader && (
          <PagePreHeader
            count={count}
            filterSubmit={filterSubmit}
            filterData={filterData}
            filterBySearchText={filterBySearchText}
            indicators={indicators}
            additionalPreHeaderIconContainerItem={additionalPreHeaderIconContainerItem}
            additionalFilters={additionalFilters}
            searchFieldCentered={searchFieldCentered}
            iconContainerClassName={iconContainerClassName}
            total={total}
            params={params}
            loading={loading}
            showLoader={showLoader}
            hideLoader={hideLoader}
            setCurrentParams={setCurrentParams}
          />
        )}
        {children && typeof children === 'function'
          ? children({ setCurrentParams, loading })
          : children}
      </div>
    </div>
  );
};

BasicPage.propTypes = {
  className: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  count: PropTypes.number,
  total: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.node.isRequired]),
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  filterBySearchText: PropTypes.bool,
  filterSubmit: PropTypes.func,
  filterData: PropTypes.array,
  indicators: PropTypes.node,
  params: PropTypes.object,
  hidePreHeader: PropTypes.bool,
  additionalFilters: PropTypes.func,
  additionalPreHeaderIconContainerItem: PropTypes.node,
  searchFieldCentered: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  setCurrentParams: PropTypes.func,
  loading: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(BasicPage);
