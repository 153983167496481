import request from '../libs/request';
import { Methods } from '../libs/http';
import Settings from '../config/settings';
import qs from 'query-string';

import { getDummyContentStatisticsData } from 'util/getDummyData';

const DASHBOARD_URL = '/dashboard';

const simulateBECall = async (message) => {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  console.log(message);
  await delay(3000);
};

const logParams = (params) => {
  console.log('params: ', params);
};

export const getCompanySubsidiaryStatisticsOverview = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/company-dashboard?${qs.stringify(params, Settings.QUERY_FORMAT)}`,
  });
};

export const getContentStatisticsOverview = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/content-dashboard?${qs.stringify(params, Settings.QUERY_FORMAT)}`,
  });
};

export const getMemberStatisticsOverview = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/user-dashboard?${qs.stringify(params, Settings.QUERY_FORMAT)}`,
  });
};

export const getCompanySubsidiaryUserStatistics = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/company-dashboard/user-trend?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getCompanySubsidiaryGroupStatistics = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/company-dashboard/group-trend?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getCompanySubsidiaryCourseStatistics = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/company-dashboard/course-trend?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getCompanySubsidiaryLessonStatistics = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/company-dashboard/lesson-trend?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getCompanySubsidiaryTestStatistics = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/company-dashboard/test-trend?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getContentSubscriptionTrend = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/content-dashboard/subscription-trend?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getMemberStatistics = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/user-dashboard/activity-trend?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getContentStatistics = async (params) => {
  const { id, ...remParams } = params;
  await simulateBECall(`getting content statistics for id: ${id}`);
  logParams(remParams);
  return getDummyContentStatisticsData(id, remParams);
};

export const getLessonsDetailByMember = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/user-dashboard/lesson-list?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};

export const getLessonUsage = async (params) => {
  return await request({
    method: Methods.GET,
    resource: `${DASHBOARD_URL}/user-dashboard/lesson-activity-trend?${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}`,
  });
};
