import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const PreviewSlideWrapper = ({ children }) => (
  <div className="preview-slide-wrapper">{children}</div>
);

PreviewSlideWrapper.propTypes = { children: PropTypes.node };

export default PreviewSlideWrapper;
