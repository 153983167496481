import {
  GET_USER_FAILED,
  GET_USERS_BY_ROLE,
  GET_USERS_BY_ROLE_FAILED,
  GET_USERS_BY_ROLE_SUCCESS,
  LOGOUT,
  SET_PROFILE_IN_LIST,
  CREATE_ADMIN,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: { data: [], count: 0 },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USERS_BY_ROLE:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || { data: [], count: 0, total: 0 },
      };
    case GET_USERS_BY_ROLE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_PROFILE_IN_LIST: {
      const data = [...state.data.data];
      data[data.findIndex(({ id }) => id === action.payload.id)] = action.payload;
      return {
        ...state,
        loading: false,
        data: { ...state.data, data },
      };
    }
    case CREATE_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ADMIN_SUCCESS:
      const updatedUserList = action.remove
        ? state.data.data.filter((user) => user.id !== action.payload.id)
        : [...state.data.data.filter((user) => user.id !== action.payload.id), action.payload] ||
          [];
      return {
        ...state,
        loading: false,
        data: {
          data: updatedUserList,
          count: updatedUserList.length,
        },
      };
    case CREATE_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        data: { data: [], count: 0 },
      };
    default:
      return state;
  }
};
