import React from 'react';
import PropTypes from 'prop-types';

import { Popover } from 'components';

import './style.scss';

const BasicFileUploadPreview = ({ fileName }) => {
  return (
    <div className="basic-file-upload-preview">
      <div className="basic-file-upload-preview__box">
        <i className="fa fa-file" />
      </div>
      <Popover content={fileName} trigger="hover" placement="top">
        <div className="basic-file-upload-preview__text-box">{fileName}</div>
      </Popover>
    </div>
  );
};

BasicFileUploadPreview.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default BasicFileUploadPreview;
