import React from 'react';
import PropTypes from 'prop-types';
import BootstrapCarousel from 'react-bootstrap/Carousel';

import { IconButton } from 'components';

import './style.scss';

const Carousel = ({
  items,
  activeIndex,
  setActiveIndex,
  indicators = false,
  interval = null,
  nextIcon = <IconButton iconClass="fa fa-angle-right" />,
  prevIcon = <IconButton iconClass="fa fa-angle-left" />,
}) => {
  return (
    <BootstrapCarousel
      activeIndex={activeIndex}
      onSelect={setActiveIndex}
      indicators={indicators}
      interval={interval}
      controls={items && items.length > 1}
      nextIcon={nextIcon}
      prevIcon={prevIcon}>
      {items
        ? items.map(({ id, content }) => (
            <BootstrapCarousel.Item key={id}>{content}</BootstrapCarousel.Item>
          ))
        : null}
    </BootstrapCarousel>
  );
};

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  indicators: PropTypes.bool,
  interval: PropTypes.oneOfType([PropTypes.number]),
  nextIcon: PropTypes.node,
  prevIcon: PropTypes.node,
};

export default Carousel;
