import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { SelectInput } from 'components';
import { SEARCHTYPES } from 'libs/lessonSearch';
import { getSelectValueFromOptions } from 'util/selectInputUtil';

const LessonSearchDropdown = ({ searchType, disabled, onChange, intl: { formatMessage } }) => {
  const translatedAvailableSearchTypes = Object.values(SEARCHTYPES).map(({ id, formatName }) => ({
    value: id,
    label: formatMessage({ id: formatName }),
  }));

  return (
    <SelectInput
      value={getSelectValueFromOptions({
        value: searchType,
        options: translatedAvailableSearchTypes,
      })}
      label={<FormattedMessage id="LESSONS.SEARCH_TYPE" />}
      options={translatedAvailableSearchTypes}
      onChange={({ value }) => onChange(value)}
      disabled={disabled}
    />
  );
};

LessonSearchDropdown.propTypes = {
  searchType: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LessonSearchDropdown);
