import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const TableHeader = ({ className, children }) => {
  return (
    <thead
      className={classNames('basic-table-header', 'basic-table-header--borderless', className)}>
      {children}
    </thead>
  );
};

TableHeader.propTypes = {
  className: PropTypes.string,
  borderless: PropTypes.bool,
  children: PropTypes.node,
};

export default TableHeader;
