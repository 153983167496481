import { select } from 'd3-selection';
import 'tippy.js/themes/translucent.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import 'd3-transition';

export const DEFAULT_MIN_FONT_SIZE = 12;
export const DEFAULT_MAX_FONT_SIZE = 35;
export const DEFAULT_WORD_CLOUD_HEIGHT = '200px';

const WORD_MOUSE_OVER_EVENT = 'onWordMouseOver';
const WORD_MOUSE_OUT_EVENT = 'onWordMouseOut';
const WORD_HOVER_MAGNIFICATION = 1.5;

export const resizeStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
};

const WORD_CLOUD_OPTIONS = {
  rotations: 1,
  rotationAngles: [0],
  fontFamily: 'Roboto',
  padding: 4,
  tooltipOptions: { theme: 'regular' },
};

const shortenWord = (word, maxWordLength) => {
  const { text, ...props } = word || {};
  return {
    text: text && text.substring ? text.substring(0, maxWordLength) : text,
    originalText: text,
    ...props,
  };
};

export const getWords = (words = [], maxNumOfWords, maxWordLength) => {
  return (
    words &&
    words
      .slice(0, maxNumOfWords)
      .map((word) => (maxWordLength ? shortenWord(word, maxWordLength) : word))
  );
};

export const getWordTooltip = (word) => (word && word.originalText ? word.originalText : word.text);

export const getDynamicFontSizes = (width) => {
  return width > 800
    ? [DEFAULT_MIN_FONT_SIZE, 42]
    : width <= 800 && width > 500
    ? [DEFAULT_MIN_FONT_SIZE, 35]
    : width <= 500 && width > 400
    ? [DEFAULT_MIN_FONT_SIZE, 28]
    : width <= 400 && width > 300
    ? [DEFAULT_MIN_FONT_SIZE, 24]
    : width <= 300
    ? [DEFAULT_MIN_FONT_SIZE, 18]
    : [DEFAULT_MIN_FONT_SIZE, DEFAULT_MAX_FONT_SIZE];
};

export const getCallback = (callback) => {
  return function (_, event) {
    const isActive = callback !== WORD_MOUSE_OUT_EVENT;
    const element = event.target;
    const text = select(element);
    text
      .style('transition', 'font-size 0.2s linear')
      .style(
        'font-size',
        isActive
          ? `calc(${text.attr('font-size')} * ${WORD_HOVER_MAGNIFICATION})`
          : `${text.attr('font-size')}`
      )
      .attr('background', 'white')
      .attr('font-weight', isActive ? 'bold' : 'normal');
  };
};

export const commonWordCloudOptions = (textColors) => ({
  ...WORD_CLOUD_OPTIONS,
  colors: textColors || ['black'],
});

export const defaultCallbacks = {
  getWordTooltip: getWordTooltip,
  onWordMouseOut: getCallback(WORD_MOUSE_OUT_EVENT),
  onWordMouseOver: getCallback(WORD_MOUSE_OVER_EVENT),
};
