import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import RDatePicker from 'react-datepicker';
import { isMobile } from 'react-device-detect';

import InputInlineError from '../InputInlineError/InputInlineError';
import Settings from 'config/settings';
import { inputErrorPropTypes } from 'common';
import ControlLabelWrapper from '../ControlLabelWrapper/ControlLabelWrapper';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import { hasInputTextRendered, showErrorInputStyle } from '../inputUtil';

const offsetFunction = ({ placement }) => {
  const yOffSet = [0, 15];
  const xOffSet = [15, 0];
  const noOffSet = [0, 0];
  const placementConfig = {
    top: yOffSet,
    'top-start': yOffSet,
    'top-end': yOffSet,
    bottom: yOffSet,
    'bottom-start': yOffSet,
    'bottom-end': yOffSet,
    left: xOffSet,
    right: xOffSet,
    'right-start': xOffSet,
    'right-end': xOffSet,
    'left-start': xOffSet,
    'left-end': xOffSet,
    auto: noOffSet,
    'auto-start': noOffSet,
    'auto-end': noOffSet,
  };

  return placementConfig[placement] || [0, 15];
};

const DatePicker = ({
  label,
  value,
  hideUnderLine,
  hideValue,
  placeholder,
  disabled,
  hasErrorMessage,
  hasErrorComponent,
  errorMessage,
  monthDatePicker,
  popperModifiers,
  fixedHeight = true,
  popperPlacement = 'bottom-start',
  dropdownMode = 'select',
  containerClass,
  childrenWrapperClass,
  ...props
}) => {
  const [id] = useState(() => uniqueId('date-picker-'));

  const datePickerFormat = monthDatePicker
    ? Settings.DATE_FORMAT_MONTH
    : Settings.DATE_FORMAT_DATE_PICKER;

  const currentPopperModifiers = popperModifiers || [
    { name: 'offset', options: { offset: offsetFunction } },
  ];

  return (
    <ControlLabelWrapper
      id={id}
      className={classNames('date-picker-container', containerClass)}
      childrenWrapperClass={childrenWrapperClass}
      hasInputValue={hasInputTextRendered({ value, placeholder })}
      hasError={showErrorInputStyle({ hasErrorMessage, hideValue })}
      label={label}
      disabled={disabled}
      hideUnderLine={hideUnderLine}
      additions={
        <InputInlineError
          hasErrorComponent={hasErrorComponent}
          hasErrorMessage={hasErrorMessage}
          errorMessage={errorMessage}
          hideValue={hideValue}
        />
      }>
      <RDatePicker
        {...props}
        selected={!hideValue && value ? value : null}
        dropdownMode={dropdownMode}
        isClearable={true}
        placeholderText={placeholder}
        autoComplete="off"
        id={id}
        disabled={disabled}
        dateFormat={datePickerFormat}
        withPortal={isMobile}
        fixedHeight={fixedHeight}
        popperModifiers={currentPopperModifiers}
        popperPlacement={popperPlacement}
      />
    </ControlLabelWrapper>
  );
};

DatePicker.propTypes = {
  containerClass: PropTypes.string,
  childrenWrapperClass: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.instanceOf(Date),
  hideUnderLine: PropTypes.bool,
  hideValue: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  monthDatePicker: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  popperModifiers: PropTypes.object,
  popperPlacement: PropTypes.string,
  dropdownMode: PropTypes.string,
  ...inputErrorPropTypes,
};

export default DatePicker;
