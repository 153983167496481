import request from '../libs/request';
import { Methods } from '../libs/http';

const BASE_URL = '/courses';

const SUBSIDIARYID_PARAM_NAME = 'subsidiaryId';
export const getCourses = async (params) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/list`,
    data: { ...params },
  });
};

/**
 * @param {number} id of course
 * */
export const getCourse = async (id, subsidiaryId) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${id}${
      subsidiaryId ? `?${SUBSIDIARYID_PARAM_NAME}=${subsidiaryId}` : ''
    }`,
  });
};

export const setImportant = async (courseId, important) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${courseId}/set-important`,
    data: {
      important,
    },
  });
};

export const createCourse = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}`,
    data,
  });
};

export const editCourse = async (data, id) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}`,
    data,
  });
};

export const editCourseRanks = async (data, id) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}/groups`,
    data,
  });
};

export const deleteCourse = async (id) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${id}`,
  });
};

export const saveCourseImage = async (courseId, data) => {
  return await request({
    resource: `${BASE_URL}/${courseId}/image`,
    method: Methods.POST,
    data,
  });
};
