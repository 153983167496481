import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Modal } from 'components';
import { templateLevel } from 'libs/template';
import SpecificsForm from '../SpecificsForm';

const getSpecific = () => ({
  key: '',
  tempId: uuidv4(),
  type: '',
  value: '',
  isSearchable: true,
  isGenerate: true,
  isShow: true,
  isGeneral: true,
  level: templateLevel.BEGINNER.id,
  question: '',
});

const SpecificsModal = ({
  specifics: specificsList,
  arrayHelpers,
  closeSpecificsModal,
  disabled,
  open,
  editSpecificIndex,
}) => {
  const templateItem = React.createRef();

  const [specific, setSpecific] = useState(() => getSpecific());

  useEffect(() => {
    if (open && specificsList) {
      if (editSpecificIndex > -1) {
        setSpecific(specificsList[editSpecificIndex]);
      } else {
        setSpecific(() => getSpecific());
      }
    }
  }, [open, specificsList, editSpecificIndex]);

  const triggerFormSubmit = () => {
    if (templateItem && templateItem.current) {
      templateItem.current.setSubmitting(true);
      templateItem.current.handleSubmit();
      templateItem.current.setSubmitting(false);
    }
  };

  const onModalClose = () => {
    closeSpecificsModal();
  };

  const title =
    editSpecificIndex === -1 ? (
      <FormattedMessage id="TEMPLATES.ADD_SPECIFIC" />
    ) : (
      <FormattedMessage id="TEMPLATES.EDIT_SPECIFIC" />
    );

  const loading = templateItem && templateItem.current && templateItem?.current.isSubmitting;

  return (
    <Modal
      shadowed
      title={title}
      saveButton
      saveTitle={<FormattedMessage id="COMMON.OK" />}
      saveAction={triggerFormSubmit}
      large
      loading={loading}
      confirm={closeSpecificsModal}
      open={open}
      preventDismiss
      onClose={onModalClose}>
      <SpecificsForm
        editSpecificIndex={editSpecificIndex}
        closeSpecificsModal={closeSpecificsModal}
        arrayHelpers={arrayHelpers}
        specific={specific}
        formRef={templateItem}
        disabled={disabled}
      />
    </Modal>
  );
};

SpecificsModal.propTypes = {
  specifics: PropTypes.array.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  closeSpecificsModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  editSpecificIndex: PropTypes.number,
};

export default SpecificsModal;
