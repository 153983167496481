import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Agreement, AgreementTypes, BasicPage } from 'components';

const PrivacyPolicy = () => (
  <BasicPage title={<FormattedMessage id="PRIVACY_POLICY.TITLE" />} hidePreHeader>
    <Agreement type={AgreementTypes.Privacy} />
  </BasicPage>
);

export default PrivacyPolicy;
