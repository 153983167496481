import { uniqueId } from 'lodash';

import * as actionTypes from 'actions/action-types';

export const showLoader = () => {
  return {
    type: actionTypes.TOGGLE_LOADER,
    payload: true,
  };
};

export const hideLoader = () => {
  return {
    type: actionTypes.TOGGLE_LOADER,
    payload: false,
  };
};

// Currently unused
export const toggleLoader = () => {
  return {
    type: actionTypes.TOGGLE_LOADER,
  };
};

export const addNotification = (content) => {
  return {
    type: actionTypes.ADD_NOTIFICATION,
    payload: {
      id: uniqueId('notification-'),
      content,
    },
  };
};

export const removeNotification = (id) => {
  return {
    type: actionTypes.REMOVE_NOTIFICATION,
    payload: id,
  };
};

// Currently unused
export const resetNotifications = () => {
  return {
    type: actionTypes.RESET_NOTIFICATIONS,
  };
};

export const openSideBar = () => {
  return {
    type: actionTypes.OPEN_SIDEBAR,
  };
};

export const closeSideBar = () => {
  return {
    type: actionTypes.CLOSE_SIDEBAR,
  };
};

export const resetApiError = () => {
  return {
    type: actionTypes.RESET_API_ERROR,
  };
};

export const setFilterParams = (data) => {
  return {
    type: actionTypes.SET_FILTER_PARAMS,
    payload: data,
  };
};
