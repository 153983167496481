import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Field } from 'formik';

import {
  Button,
  CheckBox,
  UpdateForm,
  TableFilter,
  TextField,
  TextAreaField,
  Popover,
} from 'components';
import User from '../User/User';
import { FILTERS, PERIODS, FILTER_TYPES } from 'libs/dashboardFilters';
import { Yup } from 'util/Yup';

class RegisteredUsers extends Component {
  static propTypes = {
    usersData: PropTypes.array.isRequired,
    type: PropTypes.number.isRequired,
    onUserClick: PropTypes.func.isRequired,
    pokeUsers: PropTypes.func.isRequired,
    getRegisteredUsers: PropTypes.func,
    subsidiaryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    loading: PropTypes.bool,
    hideFilter: PropTypes.bool,
    course: PropTypes.object,
    intl: PropTypes.object.isRequired,
  };

  form = null;
  filterModal = null;

  getDefaultFilter = (type) => {
    return {
      type,
      valueFrom: 0,
      valueTo: +type === FILTER_TYPES.TIME.value ? 15 : 50,
      period: PERIODS.WEEK.value,
      courseId: -1,
      lessonId: -1,
      from: new Date(new Date().getFullYear(), 0, 1),
      to: new Date(),
    };
  };

  state = {
    search: '',
    groups: [],
    pokeUsers: [],
    title: '',
    message: '',
    filter: this.getDefaultFilter(FILTER_TYPES.UNDEFINED.value),
    filtered: false,
    openPokeModal: false,
  };

  componentDidMount() {
    if (this.props.getRegisteredUsers) {
      this.filterUserList();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.subsidiaryId && +prevProps.subsidiaryId !== +this.props.subsidiaryId) {
      this.setState(
        {
          groups: [],
          search: '',
          filter: this.getDefaultFilter(FILTER_TYPES.UNDEFINED.value),
        },
        () => {
          this.filterUserList();
        }
      );
    }
  }

  filterUserList = async (searchOrRanks, searchUpdate) => {
    if (searchUpdate && searchOrRanks !== this.state.search) {
      this.setState({ search: searchOrRanks });
    } else if (!searchUpdate && searchOrRanks && searchOrRanks !== this.state.groups) {
      this.setState({ groups: searchOrRanks.groups });
    }

    let filterObject = {
      subsidiaryId: this.props.subsidiaryId,
      search: searchUpdate ? searchOrRanks : this.state.search,
      groups: !searchUpdate && searchOrRanks ? searchOrRanks.groups : this.state.groups,
    };

    if (this.props.getRegisteredUsers) {
      await this.props.getRegisteredUsers(filterObject);
    }
  };

  onChangePokeUsers = (id) => {
    this.setState((prevState) => ({
      pokeUsers: prevState.pokeUsers.find((userId) => id === userId)
        ? prevState.pokeUsers.filter((userId) => userId !== id)
        : [...prevState.pokeUsers, id],
    }));
  };

  onChangeAllPokeUsers = () => {
    const selectAll = this.props.usersData.length !== this.state.pokeUsers.length;
    this.setState({
      pokeUsers: selectAll ? this.props.usersData.map(({ id }) => id) : [],
    });
  };

  pokeUsers = async (values) => {
    const { pokeUsers } = this.state;

    await this.props.pokeUsers({
      title: values.title,
      body: values.message,
      users: pokeUsers,
    });
  };

  openPokeModal = () => {
    this.setState({ openPokeModal: true });
  };

  closePokeModal = () => {
    this.setState({ openPokeModal: false });
  };

  render() {
    const {
      usersData,
      loading,
      type,
      onUserClick,
      hideFilter,
      course,
      intl: { formatMessage },
    } = this.props;
    const { pokeUsers, openPokeModal } = this.state;

    return (
      <div className="col-12 ps-3 pe-3 pb-3 details flex-wrap">
        {!hideFilter ? (
          <div className="col-12 p-0 pre-header d-flex align-items-center">
            {formatMessage({ id: 'DASHBOARD.ALL_USERS' })}

            <div className="search-container ms-auto mt-1">
              <TableFilter
                hasUnderline
                filter={this.state.search}
                loadList={(search) => this.filterUserList(search, true)}
              />
            </div>
          </div>
        ) : null}
        <div className="col-12 d-flex align-items-center select-all-user">
          <div className="me-4 mt-1">
            <CheckBox
              label={formatMessage({ id: 'DASHBOARD.SELECT_ALL' })}
              checked={pokeUsers.length > 0 && usersData.length === pokeUsers.length}
              onChange={this.onChangeAllPokeUsers}
            />
          </div>
          <Button
            type="button"
            className="btn-blue"
            size="small"
            lessBorder
            onClick={this.openPokeModal}
            disabled={!pokeUsers.length}>
            {formatMessage({ id: 'DASHBOARD.POKE' }, { count: pokeUsers.length })}
          </Button>
        </div>
        <div className="user-list col-12 p-0">
          <div className="d-flex align-items-center justify-content-between user-list-head">
            <div className="user-list-head-item">{formatMessage({ id: 'COMMON.NAME' })}</div>
            <div className="d-flex align-items-center me-5">
              <div className="user-list-head-item">
                <Popover
                  content={formatMessage({ id: 'DASHBOARD.ACTIVITY_IN_MINS_EXPLANATION' })}
                  oneLine
                  placement="top"
                  trigger="hover">
                  {formatMessage({ id: 'DASHBOARD.ACTIVITY_IN_MINS' })}
                </Popover>
              </div>
              <div className="user-list-head-item">
                <Popover
                  content={formatMessage({ id: 'DASHBOARD.UNSUCCESSFUL_EXAMS_EXPLANATION' })}
                  oneLine
                  placement="top"
                  trigger="hover">
                  {formatMessage({ id: 'DASHBOARD.UNSUCCESSFUL_EXAMS' })}
                </Popover>
              </div>
              <div className="user-list-head-item">
                <Popover
                  content={formatMessage({ id: 'DASHBOARD.SUCCESSFUL_EXAMS_EXPLANATION' })}
                  oneLine
                  placement="top"
                  trigger="hover">
                  {formatMessage({ id: 'DASHBOARD.SUCCESSFUL_EXAMS' })}
                </Popover>
              </div>
            </div>
          </div>
          <div className="users">
            {usersData && usersData.length > 0 ? (
              usersData.map((member) => (
                <User
                  user={member}
                  key={member.id}
                  onUserClick={(user) =>
                    onUserClick(
                      user,
                      +this.state.filter.type === +FILTER_TYPES.TIME.value
                        ? { from: this.state.filter.from, to: this.state.filter.to }
                        : {}
                    )
                  }
                  filterable={type === FILTERS.activities}
                  filtered={!!course}
                  onChange={() => this.onChangePokeUsers(member.id)}
                  checked={!!pokeUsers.find((userId) => userId === member.id)}
                />
              ))
            ) : !loading ? (
              <div className="d-flex align-items-center justify-content-center p-3">
                {formatMessage({ id: 'DASHBOARD.NO_USERS_INFO' })}
              </div>
            ) : null}
          </div>
        </div>

        <UpdateForm
          open={openPokeModal}
          closeEditor={this.closePokeModal}
          create={this.pokeUsers}
          modalTitle={formatMessage({ id: 'DASHBOARD.POKE_USERS' })}
          saveTitle={formatMessage({ id: 'DASHBOARD.SEND' })}
          initialValues={{ title: '', message: '' }}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            title: Yup.string().required(),
            message: Yup.string().required(),
          })}>
          {() => (
            <Form>
              <Field
                component={TextField}
                name="title"
                label={formatMessage({ id: 'COMMON.TITLE' })}
              />

              <Field
                component={TextAreaField}
                name="message"
                label={formatMessage({ id: 'COMMON.MESSAGE' })}
              />
            </Form>
          )}
        </UpdateForm>
      </div>
    );
  }
}

export default injectIntl(RegisteredUsers);
