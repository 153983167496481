import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { SelectInput } from 'components';
import { getSelectValueFromOptions } from 'util/selectInputUtil';
import { getFieldError, hasFieldError } from '../formUtil';

const getValueToBeSet = ({ selectedOption, multi, useOnlyValueAsFieldValue }) => {
  if (useOnlyValueAsFieldValue) {
    return multi ? (selectedOption || []).map(({ value }) => value) : selectedOption.value;
  }
  return selectedOption;
};

const SelectField = ({
  form,
  field,
  multi,
  itemKey = 'id',
  options = [],
  hasErrorComponent = true,
  useOnlyValueAsFieldValue,
  ...props
}) => {
  const onChange = useCallback(
    (inputValue) => {
      if (form && field) {
        form.setFieldValue(
          field.name,
          getValueToBeSet({ selectedOption: inputValue, multi, useOnlyValueAsFieldValue })
        );
        form.setFieldTouched(field.name, true);
      }
    },
    [form, field, multi, useOnlyValueAsFieldValue]
  );

  const getValueFromOptions = useCallback(
    () => getSelectValueFromOptions({ value: field?.value, options, itemKey, multi }),
    [field, multi, itemKey, options]
  );

  const value = useOnlyValueAsFieldValue ? getValueFromOptions() : field?.value;

  return (
    <SelectInput
      value={value}
      onChange={onChange}
      multi={multi}
      options={options}
      hasErrorComponent={hasErrorComponent}
      hasErrorMessage={hasFieldError({ form, field })}
      errorMessage={getFieldError({ form, field })}
      {...props}
    />
  );
};

SelectField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  multi: PropTypes.bool,
  hasErrorComponent: PropTypes.bool,
  itemKey: PropTypes.string,
  options: PropTypes.array.isRequired,
  useOnlyValueAsFieldValue: PropTypes.bool,
};

export default SelectField;
