import { GET_JOB, GET_JOB_FAILED, GET_JOB_SUCCESS } from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_JOB:
      return {
        ...state,
        loading: true,
      };
    case GET_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || [],
      };
    case GET_JOB_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
