const getErrorObject = (error) => {
  return typeof error === 'object' && error.id ? error : typeof error === 'string' ? error : null;
};

export const getValidationMessageInput = (messageId, params) => {
  return params ? { id: messageId, values: params } : messageId;
};

export const notEmptyOrZero = (value) => !!value || value === 0;

export const getTopLevelErrors = (errors) => {
  return Array.isArray(errors)
    ? errors.map((error) => getErrorObject(error))
    : getErrorObject(errors);
};
