import { SET_MANAGED_GROUP } from '../../actions/action-types';

const INITIAL_STATE = { id: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MANAGED_GROUP:
      return action.payload || {};
    default:
      return state;
  }
};
