import { chartLabelConstants } from 'util/chartAxisDataKeys';
import { chartDetailConstants } from 'util/chartDetailDataKeys';

const getRndColor = () => Math.floor(Math.random() * 16777215).toString(16);

export const getChartItemColor = (index, primaryColors = []) => {
  return index < primaryColors.length ? primaryColors[index] : getRndColor();
};

export const getChartLabelTraslateNameId = (valueKey) =>
  chartLabelConstants[valueKey] && chartLabelConstants[valueKey].translateId;

export const getChartDetailTraslateNameId = (valueKey) =>
  chartDetailConstants[valueKey] && chartDetailConstants[valueKey].translateId;
