import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const ControlLabel = ({ htmlFor, label, className }) => {
  return (
    <label htmlFor={htmlFor} className={classNames('control-label', className)}>
      {label}
    </label>
  );
};

ControlLabel.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
};

export default ControlLabel;
