import flatten from 'flat';

export default class TranslationContext {
  languages = {};

  /**
   * @param {string} defaultLocale Default language setting.
   */
  constructor(defaultLocale = 'en') {
    this.defaultLocale = defaultLocale;
  }

  /**
   * @param {string} locale - Language locale code eg. "en".
   * @param {Array} context - List of files for the given language.
   */
  addLanguage(locale, context) {
    this.languages[locale] = context;
  }

  /**
   * @param {string} locale - Language locale code eg. "en".
   * @returns {Object} Flatten object of translation keys and values.
   */
  getTranslation(locale) {
    const req = this._getContext(locale);

    const translation = req.keys().reduce((result, key) => {
      result = {
        ...result,
        ...req(key),
      };

      return result;
    }, {});

    return flatten(translation);
  }

  /**
   *
   * @param {string} locale - Language locale code eg. "en".
   * @returns {Array} context - List of files for the given language.
   */
  _getContext(locale) {
    return this.languages[locale] || this.languages[this.defaultLocale];
  }
}
