import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { AddRow, Popover, IconButton, DragHandle } from 'components';

import './style.scss';

const LessonsSpecificsHeader = ({ onAdd, disabled, intl: { formatMessage } }) => {
  const getTranslatedPopoverContent = (firstTranstlateKey, secondTranslateKey) =>
    `${formatMessage({ id: firstTranstlateKey })}${formatMessage({
      id: secondTranslateKey,
    })}`;

  return (
    <div className="d-flex align-items-center lesson-specifics-table">
      <div className="col lesson-specifics-table__action-cell">
        <DragHandle hideIcon />
      </div>
      {!disabled ? (
        <div className="col lesson-specifics-table__action-cell invisible">
          <IconButton iconClass="lemon icon-edit purple" />
        </div>
      ) : null}{' '}
      <div className="col lesson-specifics-table__wide-cell">
        {formatMessage({ id: 'TEMPLATES.SPECIFICS_REQ' })}
      </div>
      <div className="col lesson-specifics-table__action-cell d-flex justify-content-center">
        <Popover
          always
          content={getTranslatedPopoverContent('TEMPLATES.IS_GENERATE_G', 'TEMPLATES.IS_GENERATE')}
          oneLine
          placement="top"
          trigger="hover">
          {formatMessage({ id: 'TEMPLATES.IS_GENERATE_G' })}
        </Popover>
      </div>
      <div className="col lesson-specifics-table__action-cell d-flex justify-content-center">
        <Popover
          always
          content={getTranslatedPopoverContent('TEMPLATES.IS_SHOW_S', 'TEMPLATES.IS_SHOW')}
          oneLine
          placement="top"
          trigger="hover">
          {formatMessage({ id: 'TEMPLATES.IS_SHOW_S' })}
        </Popover>
      </div>
      <div className="col lesson-specifics-table__action-cell d-flex justify-content-center">
        <Popover
          always
          content={getTranslatedPopoverContent('TEMPLATES.IS_GENERAL_A', 'TEMPLATES.IS_GENERAL')}
          oneLine
          placement="top"
          trigger="hover">
          {formatMessage({ id: 'TEMPLATES.IS_GENERAL_A' })}
        </Popover>
      </div>
      <div className="col lesson-specifics-table__action-cell d-flex justify-content-start ps-2">
        {onAdd ? (
          <AddRow
            onAdd={onAdd}
            disabled={disabled}
            className="align-items-center justify-content-start mb-0"
            hideLabel
            showPopover
            label={formatMessage({ id: 'TEMPLATES.ADD_SPECIFIC' })}
          />
        ) : null}
      </div>
    </div>
  );
};

LessonsSpecificsHeader.propTypes = {
  onAdd: PropTypes.func,
  disabled: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LessonsSpecificsHeader);
