import React from 'react';
import Bowser from 'bowser';
import classNames from 'classnames';

import './style.scss';

const Spinner = () => {
  const ms = Bowser.msedge || Bowser.msie;

  return (
    <svg className={classNames('Spinner', { ms })}>
      <circle className="outline" cx="27" cy="27" r="25.5" />
      <circle className="spinner-circle" cx="27" cy="27" r="25.5" />
    </svg>
  );
};

export default Spinner;
