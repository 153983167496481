import request from '../libs/request';
import { Methods } from '../libs/http';

const BASE_URL = '/roles';

export const getRoles = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}`,
  });
};

export const getRolesByType = async (type = 'group') => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/gettype/${type}`,
  });
};

export const getRoleByName = async (name) => {
  const roles = await getRoles();
  let result = null;
  if (roles) {
    result = roles.filter((role) => role.name === name);
  }
  return result[0];
};
