import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UnmountClosed } from 'react-collapse';

import { TableRow, TableCell, SortableTableCell, IconButton, DragHandle } from 'components';

import Settings from 'config/settings';
import {
  sortIndicatorTypes,
  horizontalAlignOptions,
  verticalAlignOptions,
  widthOptions,
  rowMinHeightOptions,
} from '../tableConstants';

const TableRowContainer = React.forwardRef(
  (
    {
      params,
      loadList,
      sortIndicatorType,
      header,
      cellData,
      open,
      toggle,
      hideCollapse,
      collapseChildren,
      sortable,
      item,
      attributes,
      listeners,
      style,
      isDragging,
      draggingWithOpacity = true,
      tbodyAttributes,
      tbodyListeners,
      isTableRowDraggable,
      ...props
    },
    ref
  ) => {
    const accordionCell = {
      key: 'tableRowAccordion',
      horizontalAlign: horizontalAlignOptions.center,
      verticalAlignOptions: verticalAlignOptions.center,
      render: () => {
        return !hideCollapse ? (
          <IconButton
            disabled={hideCollapse}
            iconClass={classNames('lemon green', open ? 'icon-arrow-up' : 'icon-arrow-down')}
            onClick={toggle}
          />
        ) : null;
      },
    };

    const sortableCell = {
      key: 'sortableDragHandle',
      minWidth: widthOptions.xs,
      render: () => (
        <DragHandle
          attributes={attributes || tbodyAttributes}
          listeners={listeners || tbodyListeners}
        />
      ),
    };

    const getTooltipConfig = (
      {
        headerTooltip,
        itemTooltip,
        onlyShowHeaderTooltipOnOverflow = true,
        onlyShowItemTooltipOnOverflow = true,
        key,
      },
      isHeader,
      content
    ) => {
      const headerCellTooltip = headerTooltip || content || '';
      const contentKey = content && key ? content[key] || '' : '';
      const bodyCellTooltip = itemTooltip ? itemTooltip(content) : contentKey;

      return {
        content: isHeader ? headerCellTooltip : bodyCellTooltip,
        onOverflow: isHeader ? onlyShowHeaderTooltipOnOverflow : onlyShowItemTooltipOnOverflow,
      };
    };

    const getBaseCellProps = (
      {
        headerTooltip,
        itemTooltip,
        key,
        headerVerticalAlign,
        headerHorizontalAlign,
        verticalAlign,
        horizontalAlign,
        onlyShowHeaderTooltipOnOverflow,
        onlyShowItemTooltipOnOverflow,
        ...props
      },
      isHeader,
      content
    ) => ({
      key,
      header: isHeader,
      hasTooltip: true,
      horizontalAlign: isHeader ? headerHorizontalAlign || horizontalAlign : horizontalAlign,
      verticalAlign: isHeader ? headerVerticalAlign || verticalAlign : verticalAlign,
      tooltipConfig: getTooltipConfig(
        {
          headerTooltip,
          itemTooltip,
          onlyShowHeaderTooltipOnOverflow,
          onlyShowItemTooltipOnOverflow,
          key,
        },
        isHeader,
        content
      ),
      ...props,
    });

    const getIconTableCellData = (
      {
        width,
        iconClass,
        verticalAlign,
        horizontalAlign,
        onClick,
        tableRowIndex,
        edit = true,
        deleteIcon,
        infoIcon,
        goToIcon,
        ...props
      },
      isHeader,
      content
    ) => {
      const tableCellContent = isHeader ? (
        content
      ) : (
        <IconButton
          iconClass={classNames(iconClass, {
            'lemon icon-edit purple': edit && !iconClass,
            'lemon icon-trash dark-gray': deleteIcon && !iconClass,
            'fa fa-arrow-circle-right': goToIcon && !iconClass,
            'lemon icon-info dark-grey': infoIcon && !iconClass,
          })}
          onClick={() => onClick(content, tableRowIndex)}
        />
      );
      const tableCellProps = {
        width: width || widthOptions.auto,
        ...getBaseCellProps(
          {
            horizontalAlign: horizontalAlign || horizontalAlignOptions.center,
            verticalAlign: verticalAlign || verticalAlignOptions.center,
            ...props,
          },
          isHeader,
          content
        ),
      };

      return { tableCellProps, tableCellContent };
    };

    const getRenderTableCellData = (
      { width, verticalAlign, horizontalAlign, render, tableRowIndex, ...props },
      isHeader,
      content
    ) => {
      const tableCellProps = {
        width: width || widthOptions.auto,
        ...getBaseCellProps(
          {
            horizontalAlign: horizontalAlign || horizontalAlignOptions.center,
            verticalAlign: verticalAlign || verticalAlignOptions.center,
            ...props,
          },
          isHeader,
          content
        ),
      };
      const tableCellContent = isHeader ? content : render && render(content, tableRowIndex);

      return { tableCellProps, tableCellContent };
    };

    const getCommonTableCellData = ({ key, ...props }, isHeader, content) => {
      const tableCellProps = { ...getBaseCellProps({ key, ...props }, isHeader, content) };
      const tableCellContent = isHeader ? content : (content && content[key]) || '-';
      return { tableCellProps, tableCellContent };
    };

    const getTableCell = (
      { iconCell, doubleIconCell, sortBy, render, ...props },
      isHeader,
      content
    ) => {
      const { tableCellProps, tableCellContent } = render
        ? getRenderTableCellData({ render, ...props }, isHeader, content)
        : iconCell || doubleIconCell
        ? getIconTableCellData({ ...props }, isHeader, content)
        : getCommonTableCellData(props, isHeader, content);

      return sortBy && isHeader ? (
        <SortableTableCell
          indicatorType={sortIndicatorType}
          onClick={() =>
            loadList({
              ...params,
              sortBy,
              order:
                params?.order === Settings.ORDER.ASC ? Settings.ORDER.DESC : Settings.ORDER.ASC,
            })
          }
          reverse={params?.order === Settings.ORDER.DESC}
          isActive={params?.sortBy === sortBy}
          {...tableCellProps}>
          {tableCellContent}
        </SortableTableCell>
      ) : (
        <TableCell {...tableCellProps}>{tableCellContent}</TableCell>
      );
    };

    const getHeaderCells = (cells) =>
      cells &&
      cells.map(({ hide, label, ...props }) => {
        return hide ? null : getTableCell(props, true, label);
      });

    const getBodyCells = (cells) => {
      return (
        cells &&
        cells.map(({ hide, item, ...props }) => (hide ? null : getTableCell(props, false, item)))
      );
    };
    const extendedCellData = [
      ...(sortable ? [sortableCell] : []),
      ...(collapseChildren ? [accordionCell] : []),
      ...cellData,
    ];

    const isDragDisabled = (item) => !!item?.isDragDisabled;

    const showCollapse = !header && collapseChildren && !isDragging;

    const passRef = isTableRowDraggable || sortable;

    return (
      <>
        <TableRow
          {...props}
          isDragDisabled={isDragDisabled(item)}
          attributes={isTableRowDraggable ? attributes : null}
          listeners={isTableRowDraggable ? listeners : null}
          ref={passRef ? ref : null}
          style={style}
          isDragging={isDragging}
          draggingWithOpacity={draggingWithOpacity}>
          {header ? getHeaderCells(extendedCellData) : getBodyCells(extendedCellData)}
        </TableRow>
        {showCollapse ? (
          <TableRow minHeight={rowMinHeightOptions.none} isDragDisabled={isDragDisabled(item)}>
            <TableCell
              noPadding
              noTopBorder
              colSpan={extendedCellData && extendedCellData.length}
              horizontalAlign={horizontalAlignOptions.center}
              width={widthOptions.auto}>
              <UnmountClosed isOpened={open} theme={{ collapse: 'basic-table-cell--collapse' }}>
                {collapseChildren
                  ? typeof collapseChildren === 'function'
                    ? collapseChildren(open)
                    : collapseChildren
                  : null}
              </UnmountClosed>
            </TableCell>
          </TableRow>
        ) : null}
      </>
    );
  }
);

TableRowContainer.propTypes = {
  cellData: PropTypes.arrayOf(PropTypes.object),
  sortIndicatorType: PropTypes.oneOf(Object.values(sortIndicatorTypes)),
  toggle: PropTypes.func,
  collapseChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  hideCollapse: PropTypes.bool,
  params: PropTypes.object,
  item: PropTypes.object,
  open: PropTypes.bool,
  sortable: PropTypes.bool,
  isDragging: PropTypes.bool,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  draggingWithOpacity: PropTypes.bool,
  tbodyAttributes: PropTypes.object,
  tbodyListeners: PropTypes.object,
  style: PropTypes.object,
  isTableRowDraggable: PropTypes.bool,
  header: PropTypes.bool,
  loadList: PropTypes.func,
};

export default TableRowContainer;
