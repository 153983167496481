import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { orderComparator } from '../chartUtil';

import './styles.scss';

const CustomTooltip = ({
  active,
  payload,
  borderColor,
  xAxisDataKey,
  yAxisElements,
  intl: { formatMessage },
}) => {
  const orderComparatorForTooltipItems = orderComparator((toolTipItem) => {
    const queriedItem = (yAxisElements || []).find(
      (yAxisElement) => yAxisElement && toolTipItem && yAxisElement.dataKey === toolTipItem.dataKey
    );
    return queriedItem ? queriedItem.legendAndTooltipOrder : null;
  });
  const tooltipItems = (payload ? [...payload] : []).sort(orderComparatorForTooltipItems);

  if (active) {
    const xAxisValue =
      payload &&
      payload.length > 0 &&
      payload[0] &&
      payload[0].payload &&
      payload[0].payload[xAxisDataKey];

    return (
      <div className="chart-tooltip" style={{ borderColor }}>
        <div className="x-axis-value">{xAxisValue || formatMessage({ id: 'COMMON.NO_DATA' })}</div>
        {(tooltipItems || [])
          .filter((item) => item && item.dataKey && item.dataKey !== xAxisDataKey)
          .map((yAxisItem) => {
            return yAxisItem ? (
              <div
                key={uuidv4()}
                className="y-axis-value"
                style={{
                  color: yAxisItem.stroke || yAxisItem.color,
                }}>
                {`${yAxisItem.name}: ${yAxisItem.payload && yAxisItem.payload[yAxisItem.dataKey]}`}
              </div>
            ) : null;
          })}
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  xAxisDataKey: PropTypes.string,
  yAxisElements: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(CustomTooltip);
