import qs from 'query-string';

import request from '../libs/request';
import { Methods } from '../libs/http';
import { usersMapper } from '../util/transferMapper';
import Settings from '../config/settings';

const BASE_URL = '/groups';

export const getAllGroups = (params) => {
  return request({
    method: Methods.POST,
    resource: `${BASE_URL}/list`,
    data: { ...params },
  });
};

export const getAssignableGroups = async (searchText) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/assign/list?${qs.stringify({ searchText }, Settings.QUERY_FORMAT)}`,
  });
};

export const getGroup = async (groupId) => {
  const group = await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${groupId}`,
  });

  group.courses = group.courses.map(({ title, id }) => {
    return { name: title, id };
  });
  group.lessons = group.lessons.map(({ title, id }) => {
    return { name: title, id };
  });
  group.users = usersMapper(group);

  return group;
};

export const getGroups = (subsidiaryId) => {
  return request({
    method: Methods.GET,
    resource: subsidiaryId ? `${BASE_URL}/subsidiary/${subsidiaryId}` : BASE_URL,
  });
};

export const getGroupsBySubsidiaryId = async (subsidiaryId) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/subsidiary/${subsidiaryId}/accordion`,
  });
};

export const createGroup = (data, subsidiaryId) => {
  return request({
    method: Methods.POST,
    resource: `${BASE_URL}/subsidiary/${subsidiaryId}`,
    data,
  });
};

export const editGroup = (data, groupId) => {
  return request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${groupId}`,
    data,
  });
};

export const deleteGroup = (id) => {
  return request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${id}`,
  });
};
