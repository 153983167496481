import Settings from '../config/settings';

export const isRefreshNeeded = () => {
  const expiresAt = localStorage.getItem(Settings.API_TOKEN_EXPIRES_AT);
  // we refresh the token if it will expire in half a minute
  return expiresAt && new Date().getTime() > new Date(expiresAt * 1000).getTime() - 1000 * 60 * 0.5;
};

export const getToken = () => {
  return {
    token: localStorage.getItem(Settings.API_TOKEN),
    expiresAt: localStorage.getItem(Settings.API_TOKEN_EXPIRES_AT),
  };
};

export const setToken = (token) => {
  localStorage.setItem(Settings.API_TOKEN, token.token);
  localStorage.setItem(Settings.API_TOKEN_EXPIRES_AT, token.expiresAt);
};

export const setRefreshInProgress = (isInProgress) => {
  isInProgress
    ? localStorage.setItem(Settings.REFRESH_TOKEN_IN_PROGRESS, 'true')
    : localStorage.removeItem(Settings.REFRESH_TOKEN_IN_PROGRESS);
};

export const isRefreshInProgress = () => {
  return localStorage.getItem(Settings.REFRESH_TOKEN_IN_PROGRESS) === 'true';
};
