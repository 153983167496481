import {
  TOGGLE_LOADER,
  RESET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  API_ERROR,
  RESET_API_ERROR,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  SET_FILTER_PARAMS,
  SET_TAB_INDEX,
} from '../actions/action-types';
import Settings from '../config/settings';

const INITIAL_STATE = {
  loader: {
    show: false,
  },
  notifications: {
    list: [],
  },
  apiError: {
    notification: false,
    error: null,
  },
  sideBar: {
    open: window.innerWidth >= Settings.BREAKPOINTS.large,
  },
  tabIndex: -1,
  filterParams: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOADER:
      const show = action.payload === undefined ? !state.loader.show : action.payload;
      return {
        ...state,
        loader: { show },
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: { list: [...state.notifications.list, action.payload] },
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: {
          list: state.notifications.list.filter(({ id }) => id !== action.payload),
        },
      };
    case RESET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          list: [],
        },
      };
    case API_ERROR:
      return {
        ...state,
        apiError: { error: action.payload, notification: action.meta },
      };
    case RESET_API_ERROR: {
      return {
        ...state,
        apiError: { notification: false, error: null },
      };
    }
    case OPEN_SIDEBAR: {
      return {
        ...state,
        sideBar: { open: true },
      };
    }
    case CLOSE_SIDEBAR: {
      return {
        ...state,
        sideBar: { open: false },
      };
    }
    case SET_TAB_INDEX: {
      return {
        ...state,
        tabIndex: action.payload,
      };
    }
    case SET_FILTER_PARAMS: {
      return {
        ...state,
        filterParams: action.payload,
      };
    }
    default:
      return state;
  }
};
