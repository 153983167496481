import {
  getCompanySubsidiaryStatisticsOverview,
  getContentStatisticsOverview,
  getMemberStatisticsOverview,
} from 'services/statistics';
import { statisticsType } from 'util/statisticsType';
import Settings from 'config/settings';

export const companySubsidiaryTypeParam = {
  COMPANY: 1,
  SUBSIDIARY: 2,
};

export const contentTypeParam = {
  COURSE: 2,
  LESSON: 1,
};

export const contentChangeTypes = {
  DECREASE: 0,
  INCREASE: 1,
  UNCHANGED: 2,
};

export const contentChangeTypeConstants = {
  [contentChangeTypes.DECREASE]: {
    iconClassName: 'far fa-thumbs-down',
    indicatorClassName: 'decreasing',
  },
  [contentChangeTypes.INCREASE]: {
    iconClassName: 'far fa-thumbs-up',
    indicatorClassName: 'increasing',
  },
  [contentChangeTypes.UNCHANGED]: {
    iconClassName: 'fas fa-equals',
    indicatorClassName: 'unchanged',
  },
};

export const memberTypeParam = {
  GROUP: 1,
  USER: 2,
};

export const TYPE_FILTER_KEY = 'types';

export const doesTypeFilterContain = (value, params) => {
  const res =
    params &&
    params[TYPE_FILTER_KEY] &&
    params[TYPE_FILTER_KEY].find &&
    params[TYPE_FILTER_KEY].find((item) => item + '' === value + '');
  return !!res;
};

export const getNewTypeFilter = (
  firstSelectorSelected,
  secondSelectorSelected,
  statisticsOverviewType
) => {
  const overviewTypeConstant = statisticsOverviewTypeConstants[statisticsOverviewType];
  return [
    ...(firstSelectorSelected
      ? [overviewTypeConstant.firstSelector && overviewTypeConstant.firstSelector.value]
      : []),
    ...(secondSelectorSelected
      ? [overviewTypeConstant.secondSelector && overviewTypeConstant.secondSelector.value]
      : []),
  ];
};

export const statisticsOverviewType = {
  COMPANY_SUBSIDIARY: 'COMPANY_SUBSIDIARY',
  CONTENT: 'COURSE',
  MEMBERS: 'MEMBERS',
};

const getCompanyAndSubsidiaryStatisticsOverviewData = async (params) => {
  const res = await getCompanySubsidiaryStatisticsOverview(params);

  return {
    count: res.count,
    data: (res.data || []).map((item) => ({
      ...item,
      type:
        item.type === companySubsidiaryTypeParam.COMPANY
          ? statisticsType.COMPANY
          : statisticsType.SUBSIDIARY,
    })),
  };
};

const getContentStatisticsOverviewData = async (params) => {
  const res = await getContentStatisticsOverview(params);

  return {
    count: res.count,
    data: (res.data || []).map(
      ({ name, type, change_type, change_rate, subscription_count, ...item }) => ({
        ...item,
        title: name,
        type: type === contentTypeParam.COURSE ? statisticsType.COURSE : statisticsType.LESSON,
        changeType:
          change_type === 0
            ? contentChangeTypes.UNCHANGED
            : change_type === 1
            ? contentChangeTypes.INCREASE
            : contentChangeTypes.DECREASE,
        value: subscription_count,
        percent: change_rate,
      })
    ),
  };
};

const getMembersStatisticsOverviewData = async (params) => {
  const result = await getMemberStatisticsOverview(params);
  return {
    count: result.count,
    data: (result.data || []).map(({ type, ...item }) => ({
      ...item,
      type: type === memberTypeParam.USER ? statisticsType.USER : statisticsType.GROUP,
    })),
  };
};

const companyFirstSelector = {
  translateId: 'COMPANY_SUBSIDIARY_STATISTICS.COMPANY_SELECTOR_LABEL',
  value: companySubsidiaryTypeParam.COMPANY,
};

const companySecondSelector = {
  translateId: 'COMPANY_SUBSIDIARY_STATISTICS.SUBSIDIARY_SELECTOR_LABEL',
  value: companySubsidiaryTypeParam.SUBSIDIARY,
};

const contentFirstSelector = {
  translateId: 'CONTENT_STATISTICS.COURSE_SELECTOR_LABEL',
  popoverTranslateId: 'CONTENT_STATISTICS.COURSE_SELECTOR_POPOVER',
  value: contentTypeParam.COURSE,
};

const contentSecondSelector = {
  translateId: 'CONTENT_STATISTICS.LESSON_SELECTOR_LABEL',
  popoverTranslateId: 'CONTENT_STATISTICS.LESSON_SELECTOR_POPOVER',
  value: contentTypeParam.LESSON,
};

const memberFirstSelector = {
  translateId: 'MEMBERS_STATISTICS.GROUP_SELECTOR_LABEL',
  value: memberTypeParam.GROUP,
};

const memberSecondSelector = {
  translateId: 'MEMBERS_STATISTICS.USER_SELECTOR_LABEL',
  value: memberTypeParam.USER,
};

const getStatisticsKey = ({ item, typeKeys, fallbackKey = 'id' }) => {
  const currentType = typeKeys[item?.type];
  return currentType ? currentType(item) : item[fallbackKey];
};

const getCompanySubsidiaryKey = (item) =>
  getStatisticsKey({
    item,
    typeKeys: {
      [statisticsType.COMPANY]: ({ id }) => `company-${id}`,
      [statisticsType.SUBSIDIARY]: ({ id }) => `subsidiary-${id}`,
    },
  });

const getContentKey = (item) =>
  getStatisticsKey({
    item,
    typeKeys: {
      [statisticsType.COURSE]: ({ id }) => `course-${id}`,
      [statisticsType.LESSON]: ({ id }) => `lesson-${id}`,
    },
  });

const getMemberKey = (item) =>
  getStatisticsKey({
    item,
    typeKeys: {
      [statisticsType.GROUP]: ({ id }) => `group-${id}`,
      [statisticsType.USER]: ({ id }) => `user-${id}`,
    },
  });

const commonFilters = [TYPE_FILTER_KEY];

export const statisticsOverviewTypeConstants = {
  [statisticsOverviewType.COMPANY_SUBSIDIARY]: {
    translateId: 'COMPANY_SUBSIDIARY_STATISTICS.TITLE',
    firstSelector: companyFirstSelector,
    secondSelector: companySecondSelector,
    getKey: getCompanySubsidiaryKey,
    paginationQueryParams: {
      filter: [...commonFilters],
      defaultParams: {
        [TYPE_FILTER_KEY]: [companyFirstSelector.value, companySecondSelector.value],
        sortBy: Settings.SORT_BY.NAME,
        order: 'asc',
      },
    },
    fetchStatisticOverview: async (params) =>
      await getCompanyAndSubsidiaryStatisticsOverviewData(params),
  },
  [statisticsOverviewType.CONTENT]: {
    translateId: 'CONTENT_STATISTICS.TITLE',
    firstSelector: contentFirstSelector,
    secondSelector: contentSecondSelector,
    getKey: getContentKey,
    paginationQueryParams: {
      filter: [...commonFilters],
      defaultParams: {
        [TYPE_FILTER_KEY]: [contentFirstSelector.value, contentSecondSelector.value],
      },
    },
    fetchStatisticOverview: async (params) => await getContentStatisticsOverviewData(params),
  },
  [statisticsOverviewType.MEMBERS]: {
    translateId: 'MEMBERS_STATISTICS.TITLE',
    firstSelector: memberFirstSelector,
    secondSelector: memberSecondSelector,
    getKey: getMemberKey,
    paginationQueryParams: {
      filter: [...commonFilters],
      defaultParams: {
        [TYPE_FILTER_KEY]: [memberFirstSelector.value, memberSecondSelector.value],
      },
    },
    fetchStatisticOverview: async (params) => await getMembersStatisticsOverviewData(params),
  },
};
