import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { removeHistoryState } from 'util/removeHistoryState';
import Settings from 'config/settings';
import { getAllGroups, deleteGroup as deleteGroupService } from 'services/groups';
import { ActiveFilterIndicator, CrudPage } from 'components';
import GroupsTable from './GroupsTable';
import SubsidiaryGroupEditor from '../SubsidiariesPage/SubsidiaryGroupEditor/SubsidiaryGroupEditor';
import { SubsidiaryAccess } from 'libs/accessManagement';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';

const initialGroup = {
  id: null,
  name: '',
  description: '',
  numberOfCourses: 0,
  numberOfLessons: 0,
  numberOfUsers: 0,
};
const defaultParams = {
  sortBy: Settings.SORT_BY.NAME,
  order: 'asc',
};

const GroupsPage = ({ profile, intl: { formatMessage }, ...props }) => {
  const { state } = useLocation();
  const history = useHistory();

  const [filterGroupIds, setFilterGroupIds] = useState(state?.groupIds || []);

  const fetchList = useCallback(
    async (params) =>
      await getAllGroups({
        ...params,
        ...(filterGroupIds && filterGroupIds.length > 0 && { groupIds: filterGroupIds }),
      }),
    [filterGroupIds]
  );

  const {
    data,
    isLoading,
    params,
    setCurrentParams,
    changeParams,
    changeParamsAndResetPage,
  } = useFetchWithQueryParams({
    fetch: fetchList,
    queryParamsConfig: defaultParams,
  });
  const count = data?.count || 0;
  const total = data?.length || 0;
  const groupList = data?.data || [];

  const deleteGroup = async (groupId) => {
    if (groupId) {
      await deleteGroupService(groupId);
    }
  };

  const {
    GroupAccess: { hasRoleToCreate, hasRoleToEdit, hasRoleToDelete },
  } = SubsidiaryAccess.getHasRoles(profile.profile);

  return (
    <CrudPage
      className="dashboard-page company-subsidiaries-page"
      title={formatMessage({ id: 'GROUPS.TITLE' })}
      addRowLabel={formatMessage({ id: 'GROUPS.ADD' })}
      hasRoleToCreate={hasRoleToCreate}
      params={params}
      deleteKey="name"
      deleteItem={deleteGroup}
      onDelete={() => setCurrentParams(params)}
      initialData={initialGroup}
      deleteTitle={formatMessage({ id: 'GROUPS.DELETE_GROUP' })}
      searchFieldCentered
      setCurrentParams={setCurrentParams}
      loading={isLoading}
      total={total}
      filterBySearchText
      {...props}>
      {({ data, isEditorOpen, closeModal, openDeleteModal, openEditModal, setCurrentParams }) => {
        const resetActiveFilter = () => {
          setFilterGroupIds([]);
          removeHistoryState(history, 'groupIds');
          changeParamsAndResetPage(params);
        };

        return (
          <>
            <ActiveFilterIndicator
              isActive={filterGroupIds && filterGroupIds.length > 0}
              resetFilter={resetActiveFilter}
            />
            <GroupsTable
              profile={profile}
              list={groupList || []}
              loading={isLoading}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              currentPage={params.page}
              loadList={(params) => setCurrentParams(params)}
              loadPage={(page) => changeParams({ ...page })}
              count={count}
              params={params}
              hasRoleToEdit={hasRoleToEdit}
              hasRoleToDelete={hasRoleToDelete}
            />
            <SubsidiaryGroupEditor
              open={isEditorOpen}
              groupId={data?.id}
              groupSubsidiaryId={data?.subsidiaryId}
              loadList={() => setCurrentParams(params)}
              closeEditor={closeModal}
            />
          </>
        );
      }}
    </CrudPage>
  );
};

GroupsPage.propTypes = {
  profile: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

export default connect(mapStateToProps)(injectIntl(GroupsPage));
