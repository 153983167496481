import isObject from 'lodash/isObject';

export const createShallowMergedObject = (initialValues, values) => {
  return {
    ...initialValues,
    ...(values && isObject(values)
      ? Object.entries(values).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: value !== null && value !== undefined ? value : initialValues[key],
          };
        }, {})
      : {}),
  };
};

export const equalEntityOrAttribute = (obj1, obj2, attribute) => {
  return obj1 && obj2
    ? obj1 === obj2 ||
        (attribute
          ? (obj2[attribute] && obj2[attribute] === obj1) ||
            (obj1[attribute] && obj1[attribute] === obj2) ||
            (obj1[attribute] && obj2[attribute] && obj1[attribute] === obj2[attribute])
          : false)
    : false;
};

const shouldRemoveProperty = (
  value,
  { removeNull, removeUndefined, removeCheck } = {
    removeNull: true,
    removeUndefined: true,
    removeCheck: undefined,
  }
) => {
  if (removeNull && value === null) {
    return true;
  }
  if (removeUndefined && value === undefined) {
    return true;
  }
  if (removeCheck && removeCheck(value)) {
    return true;
  }

  return false;
};

export const getNonEmptyAttributeObject = (
  object = {},
  config = { removeNull: true, removeUndefined: true, removeCheck: undefined }
) =>
  Object.entries(object).reduce(
    (acc, [key, value]) =>
      shouldRemoveProperty(value, config)
        ? acc
        : {
            ...acc,
            [key]: value,
          },
    {}
  );
