export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';

export const GET_ALL_GROUPS = 'GET_ALL_GROUPS';
export const GET_ALL_GROUPS_FAILED = 'GET_ALL_GROUPS_FAILED';
export const GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';

export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
