import * as ProvinceService from '../services/province';
import * as actionTypes from 'actions/action-types';
import { provinceMapper } from 'util/provinceMapper';

export const getProvinces = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_PROVINCES,
      });

      const provinces = await ProvinceService.getProvinces();
      const convertedProvinces = provinceMapper(provinces);

      dispatch({
        type: actionTypes.GET_PROVINCES_SUCCESS,
        payload: convertedProvinces,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.GET_PROVINCES_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
