import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CompaniesTable from './CompaniesTable';
import {
  getCompanies,
  getCompanyTopData,
  deleteCompany,
  enableCompany,
  disableCompany,
} from 'services/companies';
import CompanyUpdateForm from '../CompanyAndSubsidiaryEditor/CompanyUpdateForm';
import CompanyIndicators from './CompanyIndicators';
import { CompanyAccess } from 'libs/accessManagement';
import { CrudPage } from 'components';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';
import { getProfile } from 'actions/users';

import '../Dashboard/style.scss';

const initialValues = {
  id: null,
  name: '',
  legalName: '',
  email: '',
  phoneNumber: '',
  isEnabled: true,
  vatIdNumber: '',
  priceRange: '',
  mainGroupType: '',
  groupType: '',
  opening: '',
  active: '',
  imageUrl: '',
  imageUrlFull: '',
  address: {
    id: null,
    zip: '',
    country: '',
    publicPlace: '',
    city: '',
    street: '',
    houseNumber: '',
  },
  contacts: [],
  subscriptions: [],
  subscription: {},
};

const CompaniesPage = ({ profile, getProfile }) => {
  const history = useHistory();

  const fetchCompany = useCallback(async (params) => await getCompanies(params), []);
  const {
    data,
    setData,
    isLoading,
    params,
    setCurrentParams,
    changeParams,
  } = useFetchWithQueryParams({
    fetch: fetchCompany,
  });
  const count = data?.count || 0;
  const companyList = data?.data || [];

  const navigateToCompanySubsidiariesPage = (company) => {
    history.push(`/home/companies/?companyId=${company.id}`);
  };

  const toggleEnableCompany = async (companyId, enable) => {
    if (enable) {
      await enableCompany(companyId);
    } else {
      await disableCompany(companyId);
    }
    setData({
      ...data,
      data: data?.data.map((company) =>
        company.id === companyId ? { ...company, isDisabled: !enable } : company
      ),
    });
  };

  const deleteItem = async (id) => {
    await deleteCompany(id);
    await getProfile();
    changeParams();
  };

  const { hasRoleToCreate } = CompanyAccess.getHasRoles(profile.profile);

  return (
    <CrudPage
      className="dashboard-page company-subsidiaries-page"
      title={<FormattedMessage id="COMPANY_GROUPS.TITLE" />}
      addRowLabel={<FormattedMessage id="COMPANY_GROUPS.ADD_COMPANY_GROUP" />}
      hasRoleToCreate={hasRoleToCreate}
      params={params}
      deleteItem={deleteItem}
      onDelete={changeParams}
      initialData={initialValues}
      addGroupLabel={<FormattedMessage id="COMPANY_GROUPS.ADD_COMPANY_GROUP" />}
      deleteTitle={<FormattedMessage id="COMPANIES.DELETE_COMPANY" />}
      searchFieldCentered
      setCurrentParams={setCurrentParams}
      indicators={<CompanyIndicators getCompanyTopData={getCompanyTopData} />}
      isLoading={isLoading}
      filterBySearchText>
      {({ data, isEditorOpen, closeModal, openDeleteModal, openEditModal, setCurrentParams }) => {
        return (
          <>
            <CompaniesTable
              loading={isLoading}
              profile={profile}
              list={companyList}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              disableCompany={(company) => toggleEnableCompany(company.id, false)}
              enableCompany={(company) => toggleEnableCompany(company.id, true)}
              currentPage={params.page}
              loadList={setCurrentParams}
              loadPage={(page) => changeParams(page)}
              count={count}
              params={params}
              onClickCompany={navigateToCompanySubsidiariesPage}
            />
            <CompanyUpdateForm
              onSaved={changeParams}
              companyId={data.id}
              open={isEditorOpen}
              closeEditor={closeModal}
              isCompany
            />
          </>
        );
      }}
    </CrudPage>
  );
};

CompaniesPage.propTypes = {
  profile: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPage);
