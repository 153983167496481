import { createNewArrayForRequest } from 'util/createPathItemObject';
import { getRequestDataForImage } from 'util/imagesMapper';

const mapPathItemsBEtoFE = ({ itemType, ...item }) => ({
  itemType: itemType.toLowerCase(),
  ...item,
});

export const mapPathItemsFEtoBE = async (values) => ({
  title: values.title,
  shortDescription: values.shortDescription,
  subsidiaryId: values.subsidiary.id,
  companyId: values.company.id,
  images: await getRequestDataForImage({ image: [values.image] }),
  public: values.public,
  pathItems: createNewArrayForRequest(values.pathEditor),
});

export const getInitialValuesFromPath = (initialValues, path) => {
  return {
    ...initialValues,
    title: path.title ? path.title : '',
    shortDescription: path.shortDescription ? path.shortDescription : '',
    subsidiary: path.subsidiary ? path.subsidiary : null,
    company:
      path.subsidiary && path.subsidiary.companies && path.subsidiary.companies
        ? path.subsidiary.companies
        : null,
    image: path.images ? path.images[0] : null,
    public: path.public ? path.public : false,
    pathEditor: (path?.pathItems || []).map(mapPathItemsBEtoFE),
  };
};
