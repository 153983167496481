import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InlineError, InlineErrorMessage } from 'components';

import './style.scss';

const FileUploadError = ({ show, className, withoutTranslation, ...props }) => {
  const commonProps = {
    className: classNames('file-upload-error-message'),
    messageClass: 'file-upload-error-message__text',
    show,
    ...props,
  };
  const errorComponent = withoutTranslation ? (
    <InlineErrorMessage {...commonProps} />
  ) : (
    <InlineError {...commonProps} />
  );

  return show ? (
    <div className={classNames('file-upload-error p-2', className)}>{errorComponent}</div>
  ) : null;
};

FileUploadError.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  show: PropTypes.bool,
  withoutTranslation: PropTypes.bool,
};

export default FileUploadError;
