import request from '../libs/request';
import { Methods } from '../libs/http';

export const resetPassword = async (data) => {
  return await request({
    method: Methods.POST,
    resource: '/auth/password/reset-by-token',
    data,
  });
};
