export const pathFilterTypes = [
  { id: 'title', formatName: 'COMMON.TITLE' },
  { id: 'subsidiary', formatName: 'SUBSIDIARY.TITLE' },
];

export const coursesFilterTypes = [
  { id: 'title', formatName: 'COMMON.TITLE' },
  { id: 'subsidiary', formatName: 'SUBSIDIARY.TITLE' },
];

export const lessonsFilterTypes = [
  { id: 'title', formatName: 'COMMON.TITLE' },
  { id: 'subsidiary', formatName: 'SUBSIDIARY.TITLE' },
  { id: 'lessonType', formatName: 'LESSONS.LESSON_TYPE' },
  { id: 'tag', formatName: 'LESSONS.LESSON_TAGS' },
];
