import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DatePicker } from 'components';

import './style.scss';

const CalendarIconInput = React.forwardRef(
  ({ value, disabled, noDisabledStyling, asLabel, onClick }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames('statistics-date-picker-input', {
          disabled,
          'no-disabled-styling': noDisabledStyling,
        })}
        onClick={!disabled ? onClick : undefined}>
        <div className="statistics-date-picker-input-value">{value}</div>
        {!asLabel && (
          <div>
            <i className={classNames('fas fa-calendar-alt', 'statistics-date-picker-icon')} />
          </div>
        )}
      </div>
    );
  }
);

CalendarIconInput.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  asLabel: PropTypes.bool,
  onClick: PropTypes.func,
  noDisabledStyling: PropTypes.bool,
};

const StatisticsDatePicker = ({ asLabel, disabled, value, ...props }) => {
  return (
    <div className={classNames('statistics-date-picker', { 'as-label': asLabel })}>
      <DatePicker
        value={value}
        disabled={disabled || asLabel}
        childrenWrapperClass={classNames({ 'statistics-select--no-disabled-styling': asLabel })}
        showYearDropdown
        fullWidth
        hideErrorLabel
        hideUnderLine
        containerClass="statistics-date-picker__date-picker"
        customInput={<CalendarIconInput noDisabledStyling={asLabel} asLabel={asLabel} />}
        {...props}
      />
    </div>
  );
};

StatisticsDatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  asLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default StatisticsDatePicker;
