import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { CompositeLineBarChart, ChartContainer } from 'components';
import { getChartLabelTraslateNameId } from 'util/chartRenderUtil';
import { primaryChartConstants } from '../chartConstants';

import './style.scss';

const height = 400;

const MemberStatisticsPrimaryChart = ({
  chart,
  loading,
  handleChartClick,
  selectedData,
  intl: { formatMessage },
  ...props
}) => {
  const { yAxes: yAxesData, chartAreas, chartBars, xAxisDataKey } = primaryChartConstants || {};

  const bars = useMemo(
    () =>
      (chartBars || []).map(({ key, color, getDynamicProps, ...props }) => ({
        ...props,
        dataKey: key,
        fill: color,
        getDynamicProps: getDynamicProps && getDynamicProps(selectedData),
        name: getChartLabelTraslateNameId(key)
          ? formatMessage({ id: getChartLabelTraslateNameId(key) })
          : key,
      })),
    [chartBars, formatMessage, selectedData]
  );

  const areas = useMemo(
    () =>
      (chartAreas || []).map(
        ({
          key,
          color,
          getDynamicGradientProps,
          getDynamicLineProps,
          getDynamicDotProps,
          ...props
        }) => ({
          ...props,
          dataKey: key,
          stroke: color,
          getDynamicGradientProps: getDynamicGradientProps && getDynamicGradientProps(selectedData),
          getDynamicLineProps: getDynamicLineProps && getDynamicLineProps(selectedData),
          getDynamicDotProps: getDynamicDotProps && getDynamicDotProps(selectedData),
          name: getChartLabelTraslateNameId(key)
            ? formatMessage({ id: getChartLabelTraslateNameId(key) })
            : key,
        })
      ),
    [chartAreas, formatMessage, selectedData]
  );

  const yAxes = (yAxesData || []).map(({ labelTranslateId, ...props }) => ({
    label: formatMessage({ id: labelTranslateId }),
    ...props,
  }));

  const onChartClick = useCallback(
    (data) => {
      handleChartClick(
        (data && data.activePayload && data.activePayload[0] && data.activePayload[0].payload) ||
          null
      );
    },
    [handleChartClick]
  );
  return (
    <ChartContainer
      headerClassName="member-statistics-chart-container-header"
      chartTitle={<FormattedMessage id="MEMBERS_STATISTICS.PRIMARY_CHART_TITLE" />}
      loading={loading}
      {...props}>
      <CompositeLineBarChart
        cursor="pointer"
        onClick={onChartClick}
        loading={loading}
        height={height}
        bars={bars}
        areas={areas}
        data={chart || []}
        xAxisDataKey={xAxisDataKey}
        yAxes={yAxes}
      />
    </ChartContainer>
  );
};

MemberStatisticsPrimaryChart.propTypes = {
  chart: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  selectedData: PropTypes.object,
  handleChartClick: PropTypes.func.isRequired,
};

export default injectIntl(MemberStatisticsPrimaryChart);
