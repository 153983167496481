import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextInput } from 'components';
import TextFieldAdditions from './TextFieldAdditions';
import { getFieldError, hasFieldError, handleFieldChange } from '../formUtil';

const TextField = ({
  form,
  field,
  hideValue,
  hasErrorComponent = true,
  renderTextFieldAdditions,
  exampleValues,
  ...props
}) => {
  const hasErrorMessage = hasFieldError({ form, field });
  const handleSetValue = useCallback(handleFieldChange({ form, field }), [form, field]);

  const onChange = useCallback((event) => handleSetValue(event.target.value), [handleSetValue]);

  const defaultRenderTextFieldAdditions = useCallback(
    (props) => (
      <TextFieldAdditions
        exampleValues={exampleValues}
        onExampleValueChange={handleSetValue}
        {...props}
      />
    ),
    [exampleValues, handleSetValue]
  );

  return (
    <TextInput
      hideValue={hideValue}
      value={field.value}
      onChange={onChange}
      hasErrorComponent={hasErrorComponent}
      hasErrorMessage={hasErrorMessage}
      errorMessage={getFieldError({ form, field })}
      renderTextFieldAdditions={renderTextFieldAdditions || defaultRenderTextFieldAdditions}
      {...props}
    />
  );
};

TextField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  hideValue: PropTypes.bool,
  hasErrorComponent: PropTypes.bool,
  renderTextFieldAdditions: PropTypes.func,
  exampleValues: PropTypes.array,
};

export default TextField;
