import React from 'react';
import PropTypes from 'prop-types';

import { CheckBox } from 'components';

const OverviewFilterType = ({
  firstSelectorDisabled,
  firstSelectorValue,
  firstSelectorLabel,
  firstSelectorPopoverText,
  secondSelectorDisabled,
  secondSelectorValue,
  secondSelectorLabel,
  secondSelectorPopoverText,
  onChange,
}) => {
  return (
    <div className="ms-3 mb-3 d-sm-flex flex-xs-column align-items-center">
      <CheckBox
        className="me-4"
        label={firstSelectorLabel}
        tooltipLabel={firstSelectorPopoverText}
        disabled={firstSelectorDisabled}
        checked={firstSelectorValue}
        alwaysShowTooltip={!!firstSelectorPopoverText}
        onChange={(e) => onChange({ firstSelectorValue: e.target.checked, secondSelectorValue })}
      />
      <CheckBox
        label={secondSelectorLabel}
        tooltipLabel={secondSelectorPopoverText}
        disabled={secondSelectorDisabled}
        checked={secondSelectorValue}
        alwaysShowTooltip={!!secondSelectorPopoverText}
        onChange={(e) => onChange({ firstSelectorValue, secondSelectorValue: e.target.checked })}
      />
    </div>
  );
};

OverviewFilterType.propTypes = {
  firstSelectorDisabled: PropTypes.bool,
  secondSelectorDisabled: PropTypes.bool,
  firstSelectorValue: PropTypes.bool,
  firstSelectorLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  secondSelectorValue: PropTypes.bool,
  secondSelectorLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func.isRequired,
  firstSelectorPopoverText: PropTypes.string,
  secondSelectorPopoverText: PropTypes.string,
};

export default OverviewFilterType;
