import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withLoading } from 'hoc';
import { TableContainer, AddRow } from 'components';
import { widthOptions } from 'components/Table/tableConstants';

import './style.scss';

const SubsidiaryGroups = ({
  hasRoleToCreate,
  hasRoleToEdit,
  hasRoleToDelete,
  openEditModal,
  openDeleteModal,
  list,
  intl: { formatMessage },
}) => {
  return (
    <div className={'collapsed-table-container'}>
      {hasRoleToCreate ? (
        <AddRow
          onAdd={() => openEditModal()}
          label={formatMessage({ id: 'GROUPS.ADD' })}
          className="mb-0"
        />
      ) : null}
      <div className="table">
        <TableContainer
          columns={[
            { key: 'edit', hide: !hasRoleToEdit, iconCell: true, onClick: openEditModal },
            { label: formatMessage({ id: 'GROUPS.TITLE' }), key: 'name' },
            {
              key: 'description',
              label: formatMessage({ id: 'GROUPS.DESCRIPTION' }),
              width: widthOptions.lg,
              multiLine: true,
            },
            {
              key: 'numberOfUsers',
              label: formatMessage({ id: 'USERS.NUMBER_OF_USERS' }),
              width: widthOptions.sm,
            },
            {
              key: 'numberOfCourses',
              label: formatMessage({ id: 'COURSES.NUMBER_OF_COURSES' }),
              width: widthOptions.sm,
            },
            {
              key: 'numberOfLessons',
              label: formatMessage({ id: 'LESSONS.NUMBER_OF_LESSONS' }),
              width: widthOptions.sm,
            },
            {
              key: 'delete',
              hide: !hasRoleToDelete,
              iconCell: true,
              onClick: openDeleteModal,
              deleteIcon: true,
            },
          ]}
          list={list}
          emptyMessage={formatMessage({ id: 'SUBSIDIARY.NO_GROUPS' })}
        />
      </div>
    </div>
  );
};

SubsidiaryGroups.propTypes = {
  list: PropTypes.array,
  hasRoleToCreate: PropTypes.bool.isRequired,
  hasRoleToEdit: PropTypes.bool.isRequired,
  hasRoleToDelete: PropTypes.bool.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(withLoading(SubsidiaryGroups));
