import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { useIsMounted } from 'hooks/useIsMounted';
import { getAssignableGroups } from 'services/groups';
import { TransferLists } from 'components';

const AssignGroup = ({ disabled, fetchData, onGroupAssign, onGroupUnAssign }) => {
  const [availableItems, setAvailableItems] = useState(null);
  const [listChanged, setListChanged] = useState(true);
  const isMounted = useIsMounted();

  const fetchGroups = useCallback(
    async (searchText) => {
      const groups = await getAssignableGroups(searchText);
      if (isMounted.current) {
        setAvailableItems(groups);
        setListChanged(true);
        setListChanged(false);
      }
    },
    [isMounted]
  );

  useEffect(() => {
    if (fetchData && !availableItems) {
      fetchGroups();
    }
  }, [availableItems, fetchData, fetchGroups]);

  const formatGroupEntry = ({ id, name }) => ({
    id,
    name,
  });

  return (
    <Field
      name="groups"
      itemsName="groups"
      onToLeft={onGroupUnAssign}
      onToRight={onGroupAssign}
      component={TransferLists}
      formatSourceEntry={formatGroupEntry}
      formatResultEntry={formatGroupEntry}
      disabled={disabled}
      loadList={fetchGroups}
      sourceList={availableItems}
      listChanged={listChanged}
    />
  );
};

AssignGroup.propTypes = {
  onGroupAssign: PropTypes.func,
  onGroupUnAssign: PropTypes.func,
  fetchData: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default AssignGroup;
