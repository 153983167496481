import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useHistory, useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Yup } from 'util/Yup';
import { getValidationMessageInput } from 'libs/validation/validation';
import { createPath, editPath, getPath } from 'services/path';
import { hideLoader, showLoader } from 'actions/common';
import { BasicPage, SubmitFormButtons, UnsavedChangesModal, PathEditorField } from 'components';

import { getInitialValuesFromPath, mapPathItemsFEtoBE } from './util/getInitialValues';

const inputLimits = {
  maxTitle: 255,
  maxShortDescription: 500,
};

const PathForm = ({ showLoader, hideLoader }) => {
  const { id } = useParams();
  const [pathData, setPathData] = useState({});
  const history = useHistory();
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);
  const formikRef = useRef(null);

  const closeUnsavedChangesModal = () => {
    setUnsavedChangesModalOpen(false);
  };

  const closeEditor = () => {
    history.push('/home/path');
  };

  const onClose = () => {
    formikRef.current.dirty ? setUnsavedChangesModalOpen(true) : closeEditor();
  };

  useEffect(() => {
    const fetchPathItems = async () => {
      if (id) {
        const response = await getPath(id);

        setPathData(response);
      }
    };
    fetchPathItems();
  }, [id]);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    showLoader();

    try {
      const requestData = await mapPathItemsFEtoBE(values);
      if (id) {
        return await editPath(requestData, id);
      }
      await createPath(requestData);
    } finally {
      hideLoader();
      setSubmitting(false);
      history.push('/home/path');
    }
  };

  const initialValues = {
    title: '',
    shortDescription: '',
    image: null,
    company: null,
    subsidiary: null,
    public: false,
    pathEditor: [],
  };

  const { maxTitle, maxShortDescription } = inputLimits;

  return (
    <BasicPage>
      <Formik
        initialValues={getInitialValuesFromPath(initialValues, pathData)}
        validateOnBlur={false}
        onSubmit={onSubmit}
        enableReinitialize
        innerRef={formikRef}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required()
            .max(
              maxTitle,
              getValidationMessageInput('COMMON.FIELD_MAX_LENGTH_ERROR', {
                max: maxTitle,
              })
            ),
          shortDescription: Yup.string()
            .required()
            .max(
              maxShortDescription,
              getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', {
                max: maxShortDescription,
              })
            ),
          company: Yup.object().nullable().required(),
          subsidiary: Yup.object().nullable().required(),
          image: Yup.mixed().nullable().required(),
          public: Yup.boolean().required(),
          pathEditor: Yup.array(),
        })}>
        {(formikProps) => {
          return (
            <Form>
              <Field name="pathEditor" component={PathEditorField} />

              <UnsavedChangesModal
                className="shadowed-modal"
                title={<FormattedMessage id="COMMON.UNSAVED_CHANGES" />}
                open={unsavedChangesModalOpen}
                large={false}
                onClose={closeUnsavedChangesModal}
                saveButton={true}
                saveTitle={<FormattedMessage id="COMMON.YES" />}
                closeTitle={<FormattedMessage id="COMMON.CANCEL" />}
                saveAction={closeEditor}>
                <FormattedMessage id="COMMON.ARE_YOU_SURE" />
              </UnsavedChangesModal>

              <SubmitFormButtons
                onSubmit={onSubmit}
                disabled={formikProps.isSubmitting}
                loading={formikProps.isSubmitting}
                showSubmitButton
                onClose={onClose}
              />
            </Form>
          );
        }}
      </Formik>
    </BasicPage>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

PathForm.propTypes = {
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(PathForm);
