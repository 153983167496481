import React from 'react';
import PropTypes from 'prop-types';

import blackboard from '../../assets/images/blackboard.svg';
import exam from '../../assets/images/exam.svg';
import { FILTERS } from 'libs/dashboardFilters';

const IndicatorImage = ({ type }) => {
  const src = {
    [FILTERS.unsuccessful.value]: exam,
    [FILTERS.successful.value]: blackboard,
  };
  const imgSrc = type?.value ? src[type.value] : null;
  return (
    <div
      className={`indicator__image indicator__image--${type.label} d-flex justify-content-center align-items-center`}>
      {imgSrc && <img src={imgSrc} alt="" />}
    </div>
  );
};

IndicatorImage.propTypes = {
  type: PropTypes.object,
};

export default IndicatorImage;
