import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ArrowSortIndicator = ({ isActive, reverse }) => {
  return (
    <span>
      <i
        className={classNames(
          'lemon',
          isActive && 'yellow',
          reverse ? 'icon-arrow-down' : 'icon-arrow-up'
        )}
      />
    </span>
  );
};

ArrowSortIndicator.propTypes = {
  isActive: PropTypes.bool,
  reverse: PropTypes.bool,
};

export default ArrowSortIndicator;
