import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import { FileUpload } from 'components';
import { getFieldError, hasFieldError } from '../formUtil';

const removeSelectedItem = ({ index, multi, file }) => {
  if (!multi) {
    return null;
  }
  if (multi && typeof index !== 'undefined') {
    const files = cloneDeep(file);
    if (files) {
      files.splice(index, 1);

      return files.length ? files : [];
    }
  }
  return [];
};

const FileUploadField = ({ form, field, multi, hasErrorComponent = true, ...props }) => {
  const errorMessage = getFieldError({ form, field });
  const hasErrorMessage = hasFieldError({ form, field });

  const onDrop = useCallback(
    async (files) => {
      if (form && field) {
        form.setFieldValue(field.name, files);
        form.setFieldTouched(field.name, true);
      }
    },
    [form, field]
  );

  const deleteSelection = useCallback(
    (index) => {
      if (form && field) {
        form.setFieldValue(field.name, removeSelectedItem({ index, multi, file: field.value }));
        form.setFieldTouched(field.name, true);
      }
    },
    [form, field, multi]
  );

  return (
    <FileUpload
      {...props}
      deleteSelection={deleteSelection}
      onDrop={onDrop}
      file={field.value}
      hasErrorComponent={hasErrorComponent}
      errorMessage={errorMessage}
      hasErrorMessage={hasErrorMessage}
      multi={multi}
    />
  );
};

FileUploadField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  multi: PropTypes.bool,
  hasErrorComponent: PropTypes.bool,
};

export default FileUploadField;
