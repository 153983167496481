import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Modal, BasicPage, SelectInput } from '../../components';
import { match, location } from 'common';
import { setManagedSubsidiary, getManagedSubsidiary } from '../../libs/managedSubsidiary';
import UserModalContent from './UserModalContent/UserModalContent';
import RegisteredUsers from './RegisteredUsers/RegisteredUsers';
import Indicators from './Indicators/Indicators';
import Courses from './Courses/Courses';
import { hasRole, Roles } from 'libs/roles';
import { FILTERS } from 'libs/dashboardFilters';
import Settings from '../../config/settings';
import { getDateString } from 'libs/strings';
import { getSelectValueFromOptions } from 'util/selectInputUtil';

import './style.scss';

export default class Dashboard extends Component {
  static propTypes = {
    getDashboardTopData: PropTypes.func.isRequired,
    getDashboardCoursesData: PropTypes.func.isRequired,
    getDashboardUsersData: PropTypes.func.isRequired,
    getDashboardUserData: PropTypes.func.isRequired,
    getRegisteredUsers: PropTypes.func.isRequired,
    getCourses: PropTypes.func.isRequired,
    getLessons: PropTypes.func.isRequired,
    pokeUsers: PropTypes.func.isRequired,
    clearDashboard: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    getSubscriptions: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    dashboard: PropTypes.object,
    profile: PropTypes.object,
    loading: PropTypes.bool,
    managedSubsidiaries: PropTypes.array,
    subscriptions: PropTypes.array,
    rankList: PropTypes.array,
    match,
    location,
  };

  userModal = null;

  TODAY = new Date();
  LAST_WEEK = new Date(this.TODAY.getFullYear(), this.TODAY.getMonth(), this.TODAY.getDate() - 6);

  state = {
    type: FILTERS.users.value,
    subsidiaryId:
      this.props.managedSubsidiaries &&
      this.props.managedSubsidiaries.length > 0 &&
      this.props.managedSubsidiaries[0].id,
    course: null,
    intervalMin: this.LAST_WEEK,
    intervalMax: this.TODAY,
    filter: '',
    user: null,
    usersData: [],
    groups: [],
    activities: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    modal: false,
  };

  async componentDidMount() {
    this.props.showLoader();
    this.props.clearDashboard();
    await this.props.getProfile();

    const subsidiaryId = getManagedSubsidiary(this.props.location);
    const subsidiary =
      this.props.managedSubsidiaries &&
      this.props.managedSubsidiaries.find(({ id }) => +id === +subsidiaryId);

    if (!subsidiary) {
      this.setState(
        {
          subsidiaryId:
            this.props.managedSubsidiaries &&
            this.props.managedSubsidiaries.length > 0 &&
            this.props.managedSubsidiaries[0].id,
        },
        async () => {
          await setManagedSubsidiary({
            subsidiary: { id: this.state.subsidiaryId },
            ...this.props,
          });
          this.getData();
        }
      );
    } else {
      this.setState({ subsidiaryId: +subsidiaryId }, () => {
        this.getData();
      });
    }
  }

  getData = async () => {
    await this.props.getSubscriptions();

    await Promise.all([this.getDashboard(true), this.props.getGroups(this.state.subsidiaryId)]);

    await this.getCoursesAndLessons();

    this.props.hideLoader();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.dashboard.topData.activities !== this.props.dashboard.topData.activities &&
      this.props.dashboard.topData.activities
    ) {
      this.getActivities(this.props.dashboard.topData.activities);
    }
  }

  getDashboard = async (init, justUsers) => {
    try {
      this.props.showLoader();
      const { subsidiaryId, intervalMin, intervalMax, course, filter } = this.state;

      const from = getDateString(intervalMin);
      const to = getDateString(intervalMax);

      if (init) {
        await this.props.getDashboardTopData(subsidiaryId);
      }

      if (!justUsers) {
        await this.props.getDashboardCoursesData({
          subsidiaryId,
          from,
          to,
          groups: this.state.groups,
        });
      }

      await this.props.getDashboardUsersData({
        subsidiaryId,
        from,
        to,
        courseId: course ? course.id : null,
        search: filter,
      });
    } finally {
      this.props.hideLoader();
    }
  };

  onChange = async (subsidiaryId) => {
    await this.setState({ subsidiaryId });

    if (typeof subsidiaryId !== 'undefined') {
      this.setState({ course: null });
      await this.getDashboard(true);
      await setManagedSubsidiary({ subsidiary: { id: subsidiaryId }, ...this.props });
      this.getCoursesAndLessons();
    }
  };

  openUserModal = async (filter = {}) => {
    const { subsidiaryId, intervalMin, intervalMax } = this.state;
    const from = filter.from || (this.state.type === FILTERS.users.value ? null : intervalMin);
    const to = filter.to || intervalMax;

    await this.props.getDashboardUserData({
      userId: this.state.user.id,
      subsidiaryId,
      from: getDateString(from),
      to: getDateString(to),
    });

    this.setState({ modal: true });
  };

  onUserClick = async (user, filter) => {
    await this.setState({ user });
    this.openUserModal(filter);
  };

  getActivities(activitiesInMiliSec) {
    const minutes = activitiesInMiliSec / 1000 / 60;
    const hours = minutes / 60;
    const roundedMinutes = Math.floor(minutes % 60);
    const roundedDays = Math.floor(hours / 24);
    const roundedHours = Math.floor(hours % 24);

    this.setState({
      activities: {
        minutes: roundedMinutes,
        hours: roundedHours,
        days: roundedDays,
      },
    });
  }

  filterDashboard = async ({ groups }) => {
    await this.setState({ groups });
    this.getDashboard();
  };

  changeCourse = (course) => {
    this.setState({ course });
    this.getDashboard(false, true);
  };

  isGroupPremium = () => {
    const subsidiary = this.props.managedSubsidiaries.find(
      ({ id }) => id === this.state.subsidiaryId
    );
    return (
      this.props.subscriptions &&
      !!this.props.subscriptions.length &&
      subsidiary &&
      subsidiary.latestSubscription.subscription_id === this.props.subscriptions[1].id
    );
  };

  hasSystemAdminRole = () => {
    return !!hasRole(this.props.profile, [Roles.SYSTEM_ADMIN]);
  };

  filterUserList = (filter) => {
    this.setState(
      {
        filter,
      },
      async () => {
        this.getDashboard(false, true);
      }
    );
  };

  changeType = (type) => {
    this.setState({ type }, async () => {
      await this.getCoursesAndLessons();
    });
  };

  getCoursesAndLessons = async () => {
    if (this.state.type === FILTERS.users.value) {
      const params = {
        subsidiaryId: this.state.subsidiaryId,
        page: 1,
        sortBy: Settings.SORT_BY.TITLE,
        order: Settings.ORDER.ASC,
      };

      this.props.showLoader();
      await this.props.getCourses(params);
      await this.props.getLessons(params);
      this.props.hideLoader();
    }
  };

  onChangedInterval = ({ intervalMin, intervalMax }) => {
    this.setState({ intervalMin, intervalMax }, () => {
      this.getDashboard();
    });
  };

  render() {
    const { subsidiaryId, intervalMin, intervalMax, type } = this.state;
    const { topData, coursesData, userData, usersData, registeredUsers } = this.props.dashboard;
    const { showLoader, hideLoader, rankList, loading, getRegisteredUsers, pokeUsers } = this.props;

    const group = rankList.find(({ id }) => +id === +this.state.subsidiaryId);
    const groups = group ? group.groups : [];

    const managedSubsidiariesOptions = (this.props.managedSubsidiaries || []).map(
      ({ name, id }) => ({
        label: name,
        value: id,
      })
    );

    return (
      <BasicPage
        className="dashboard-page"
        title={<FormattedMessage id="DASHBOARD.TITLE" />}
        hidePreHeader>
        <>
          {this.props.managedSubsidiaries && this.props.managedSubsidiaries.length > 1 ? (
            <div className="dropdowns">
              <SelectInput
                containerClass="mb-4"
                label={<FormattedMessage id="DASHBOARD.SUBSIDIARY" />}
                value={getSelectValueFromOptions({
                  value: subsidiaryId,
                  options: managedSubsidiariesOptions,
                })}
                onChange={({ value }) => this.onChange(value)}
                options={managedSubsidiariesOptions}
              />
            </div>
          ) : null}
          <Indicators
            activities={this.state.activities}
            topData={topData}
            changeType={this.changeType}
            activeType={this.state.type}
          />
          {type === FILTERS.unsuccessful.value ? (
            <Courses
              usersData={usersData}
              coursesData={coursesData}
              onUserClick={this.onUserClick}
              showLoader={showLoader}
              intervalMax={intervalMax}
              onChangedInterval={this.onChangedInterval}
              groups={groups}
              hideLoader={hideLoader}
              intervalMin={intervalMin}
              filterDashboard={this.filterDashboard}
              filter={this.state.filter}
              coursesChartDisable={!this.hasSystemAdminRole() && !this.isGroupPremium()}
              course={this.state.course}
              filterUserList={this.filterUserList}
              type={type}
              chosenOptionsRanks={this.state.groups}
              changeCourse={this.changeCourse}
              loading={loading}
              pokeUsers={pokeUsers}
            />
          ) : type === FILTERS.users.value ? (
            <RegisteredUsers
              type={type}
              onUserClick={this.onUserClick}
              subsidiaryId={subsidiaryId}
              usersData={registeredUsers}
              getRegisteredUsers={getRegisteredUsers}
              pokeUsers={pokeUsers}
            />
          ) : null}
          <Modal
            open={!!this.state.modal}
            className="user-modal"
            title={<FormattedMessage id="DASHBOARD.USER_MODAL_TITLE" />}
            closeTitle={<FormattedMessage id="COMMON.CLOSE" />}
            saveButton={false}
            onClose={() => this.setState({ modal: null })}
            large>
            <UserModalContent data={userData} />
          </Modal>
        </>
      </BasicPage>
    );
  }
}
