import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import arrayMove from 'array-move';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';

import {
  Table,
  TableHeader,
  TableCell,
  TableRow,
  TableBody,
  TableRowContainer,
  ConditionalWrapper,
} from 'components';
import { sortIndicatorTypes, horizontalAlignOptions, widthOptions } from '../tableConstants';
import SortableDnDWrapper from '../Sortable/SortableDnDWrapper';

const TableContainer = ({
  className,
  columns,
  sortable,
  afterSort,
  sortIndicatorType = sortIndicatorTypes.arrow,
  list = [],
  loadList,
  params,
  getRecordRowClassName,
  rowCollapseChildren,
  hideCollapse,
  emptyMessage,
  recordKey = 'id',
  loading,
  hideHeader = false,
  intl: { formatMessage },
  isTableRowDraggable = false,
}) => {
  const [openedRowIds, setOpenedRowIds] = useState([]);

  const defaultNoDataMessage = formatMessage({ id: 'COMMON.EMPTY_TABLE' });

  const getRecordKey = (record, recordIndex) =>
    typeof recordKey === 'function' ? recordKey(record, recordIndex) : record[recordKey];

  const toggleRow = (rowId) => {
    const isRowOpen = openedRowIds.includes(rowId);
    if (isRowOpen) {
      setOpenedRowIds(openedRowIds.filter((id) => id !== rowId));
    } else {
      setOpenedRowIds([rowId, ...openedRowIds]);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = list.findIndex((item, index) => getRecordKey(item, index) === active.id);
      const newIndex = list.findIndex((item, index) => getRecordKey(item, index) === over.id);

      if (afterSort) {
        afterSort(arrayMove(list, oldIndex, newIndex));
      }
    }
  };

  const getCommonBodyRowContainerProps = (item, itemIndex) => ({
    toggle: () => toggleRow(getRecordKey(item, itemIndex)),
    hideCollapse: hideCollapse && hideCollapse(item),
    collapseChildren: rowCollapseChildren
      ? typeof rowCollapseChildren === 'function'
        ? rowCollapseChildren(item)
        : rowCollapseChildren
      : null,
    open: openedRowIds.includes(getRecordKey(item, itemIndex)),
    className: getRecordRowClassName && getRecordRowClassName(item),
    sortable: sortable,
    cellData: columns.map((columnData) => ({
      ...columnData,
      item,
      tableRowIndex: itemIndex,
    })),
  });

  const getSortableItems = () =>
    list.map((item, itemIndex) => (
      <SortableDnDWrapper id={getRecordKey(item, itemIndex)} key={getRecordKey(item, itemIndex)}>
        {({ setNodeRef, attributes, listeners, style, isDragging }) =>
          rowCollapseChildren ? (
            <TableBody ref={setNodeRef} style={style} sortable={sortable}>
              <TableRowContainer
                tbodyAttributes={attributes}
                tbodyListeners={listeners}
                isDragging={isDragging}
                draggingWithOpacity={false}
                {...getCommonBodyRowContainerProps(item, itemIndex)}
              />
            </TableBody>
          ) : (
            <TableRowContainer
              ref={setNodeRef}
              style={style}
              attributes={attributes}
              listeners={listeners}
              draggingWithOpacity={sortable ? false : true}
              isTableRowDraggable={isTableRowDraggable}
              item={item}
              isDragging={isDragging}
              {...getCommonBodyRowContainerProps(item, itemIndex)}
            />
          )
        }
      </SortableDnDWrapper>
    ));

  const getSortableContent = () =>
    rowCollapseChildren ? getSortableItems() : <TableBody>{getSortableItems()}</TableBody>;

  const headerContent = (
    <TableHeader>
      <TableRowContainer
        header
        sortable={sortable}
        collapseChildren={rowCollapseChildren}
        loadList={loadList}
        params={params}
        sortIndicatorType={sortIndicatorType}
        cellData={columns}
      />
    </TableHeader>
  );

  const tableContent =
    list && list.length > 0 ? (
      getSortableContent()
    ) : !loading ? (
      <TableBody>
        <TableRow>
          <TableCell
            colSpan={columns && columns.length}
            horizontalAlign={horizontalAlignOptions.center}
            width={widthOptions.auto}
            multiLine
            fillRemainingSpace>
            {emptyMessage || defaultNoDataMessage}
          </TableCell>
        </TableRow>
      </TableBody>
    ) : null;

  return (
    <ConditionalWrapper
      condition={sortable}
      wrapper={(children) => (
        <DndContext
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
          <SortableContext
            items={list.map((item) => getRecordKey(item))}
            strategy={verticalListSortingStrategy}>
            {children}
          </SortableContext>
        </DndContext>
      )}>
      <Table className={className}>
        {hideHeader ? null : headerContent}
        {tableContent}
      </Table>
    </ConditionalWrapper>
  );
};

TableContainer.propTypes = {
  sortIndicatorType: PropTypes.oneOf(Object.values(sortIndicatorTypes)),
  columns: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired })
  ).isRequired,
  list: PropTypes.array.isRequired,
  loadList: PropTypes.func,
  params: PropTypes.object,
  getRecordRowClassName: PropTypes.func,
  rowCollapseChildren: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  recordKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  hideCollapse: PropTypes.func,
  emptyMessage: PropTypes.string,
  loading: PropTypes.bool,
  hideHeader: PropTypes.bool,
  intl: PropTypes.object,
  sortable: PropTypes.bool,
  className: PropTypes.string,
  isTableRowDraggable: PropTypes.bool,
  afterSort: PropTypes.func,
  draggable: PropTypes.bool,
};

export default injectIntl(TableContainer);
