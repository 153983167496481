import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { getCourses } from 'services/courses';
import { TransferLists } from 'components';

const AssignCourse = ({ disabled, fetchData }) => {
  const [availableItems, setAvailableItems] = useState(null);
  const [listChanged, setListChanged] = useState(true);

  useEffect(() => {
    if (fetchData && !availableItems) {
      fetchCourses();
    }
  }, [fetchData, availableItems]);

  const fetchCourses = async (searchText) => {
    const { data: availableCourses } = await getCourses({
      sortBy: 'title',
      searchIn: ['title'],
      order: 'asc',
      searchText,
    });

    setAvailableItems(availableCourses);
    setListChanged(true);
    setListChanged(false);
  };

  return (
    <Field
      name="courses"
      component={TransferLists}
      disabled={disabled}
      sourceList={availableItems && availableItems.map(({ id, title }) => ({ id, name: title }))}
      loadList={fetchCourses}
      listChanged={listChanged}
    />
  );
};

AssignCourse.propTypes = {
  fetchData: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default AssignCourse;
