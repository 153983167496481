import React from 'react';

import { match } from 'common';
import { OnPageRouter } from 'components';
import LessonsPage from './LessonsPage';
import LessonsEditor from './LessonsEditor';
import './style.scss';

const Lessons = ({ match }) => {
  return <OnPageRouter match={match} Page={LessonsPage} Editor={LessonsEditor} />;
};

Lessons.propTypes = {
  match,
};

export default Lessons;
