import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { ImageFileUploadField, TextField, CheckBox, SelectField, CollapseField } from 'components';
import Settings from 'config/settings';
import CompanySubsidiaryTypeChooser from './CompanySubsidiaryTypeChooser';

const getIdAndNameBasedOption = ({ id, name }) => ({
  label: name,
  value: id,
});

const getCompanyOption = ({ id, name, isDisabled }) => ({
  label: name,
  value: id,
  disabled: isDisabled,
});

const getIdAndNameBasedOptionList = (optionList = []) => optionList.map(getIdAndNameBasedOption);

const getCompanyOptionList = (optionList = []) => optionList.map(getCompanyOption);

const publicPlaceOptions = Settings.PUBLIC_PLACES.map(({ label, value }) => {
  return {
    label,
    value,
  };
});

const CompanyGeneralTab = ({
  showIsEnabled,
  isCompany,
  isAdmin,
  priceRanges,
  openings,
  groupTypes,
  disabled,
  availableCompanies,
  intl: { formatMessage },
}) => {
  const parentCompanyOptions = getCompanyOptionList(availableCompanies);

  const openingOptions = getIdAndNameBasedOptionList(openings);

  const groupTypeOptions = getIdAndNameBasedOptionList(groupTypes);

  const priceRangeOptions = (priceRanges || []).map(({ id, name, range }) => ({
    label: `${name} ${range}`,
    value: id,
  }));

  return (
    <div className="row">
      {!isCompany && (
        <>
          <div className="col-12 col-lg-6">
            <Field
              disabled={disabled}
              name="parentCompany"
              component={SelectField}
              options={parentCompanyOptions}
              useOnlyValueAsFieldValue
              label={formatMessage({ id: 'SUBSIDIARY.PARENT_COMPANY_REQ' })}
              isOptionDisabled={(item) => item.disabled}
            />
          </div>
          <div className="col-12 col-lg-6" />
        </>
      )}

      <div className="col-6">
        <div className="col-12 p-0">
          <Field
            disabled={disabled}
            name="name"
            component={TextField}
            label={formatMessage({ id: 'COMMON.NAME_REQ' })}
          />
        </div>
        <div className="col-12 p-0">
          <Field
            disabled={disabled}
            name="legalName"
            component={TextField}
            label={formatMessage({ id: 'COMPANIES.LEGAL_NAME_REQ' })}
          />
        </div>
      </div>

      <div className="col-6">
        <Field
          name="imageUrl"
          component={ImageFileUploadField}
          disabled={disabled}
          deletable
          required
        />
      </div>

      {showIsEnabled && (
        <div className="col-12 col-lg-6">
          <Field
            name="isEnabled"
            className="mb-3"
            component={CheckBox}
            label={formatMessage({ id: 'COMPANIES.ENABLED' })}
            disabled={disabled || !isAdmin}
          />
        </div>
      )}

      <CollapseField title={formatMessage({ id: 'COMPANIES.BASIC' })} initiallyOpen>
        <div className="col-12">
          <Field
            disabled={disabled}
            name="vatIdNumber"
            component={TextField}
            label={formatMessage({ id: 'COMPANIES.TAX_REG_NUMBER_REQ' })}
          />
        </div>

        <div className="col-12">
          <Field
            disabled={disabled}
            name="email"
            component={TextField}
            label={formatMessage({ id: 'COMMON.EMAIL_REQ' })}
          />
        </div>

        <div className="col-12">
          <Field
            disabled={disabled}
            name="phoneNumber"
            component={TextField}
            label={formatMessage({ id: 'COMMON.PHONE_NUMBER_REQ' })}
          />
        </div>
      </CollapseField>

      <CollapseField title={formatMessage({ id: 'COMPANIES.ADDRESS' })} initiallyOpen>
        <div className="col-12">
          <Field
            disabled={disabled}
            name="address.city"
            component={TextField}
            label={formatMessage({ id: 'COMMON.CITY_REQ' })}
          />
        </div>

        <div className="col-12">
          <Field
            disabled={disabled}
            name="address.zip"
            component={TextField}
            label={formatMessage({ id: 'COMPANIES.ZIP_CODE_REQ' })}
          />
        </div>

        <div className="col-12">
          <Field
            disabled={disabled}
            name="address.country"
            component={TextField}
            label={formatMessage({ id: 'COMPANIES.COUNTRY_REQ' })}
          />
        </div>

        <div className="col-12">
          <Field
            disabled={disabled}
            name="address.street"
            component={TextField}
            label={formatMessage({ id: 'COMPANIES.STREET_REQ' })}
          />
        </div>

        <div className="col-12">
          <Field
            disabled={disabled}
            name="address.publicPlace"
            component={SelectField}
            label={formatMessage({ id: 'COMPANIES.PUBLIC_SPACE_TYPE_REQ' })}
            options={publicPlaceOptions}
            useOnlyValueAsFieldValue
          />
        </div>

        <div className="col-12">
          <Field
            disabled={disabled}
            name="address.houseNumber"
            component={TextField}
            label={formatMessage({ id: 'COMPANIES.HOUSE_NUMBER_REQ' })}
          />
        </div>
      </CollapseField>

      <CollapseField
        title={formatMessage({ id: 'COMPANIES.COMPANY_CATEGORIZATION' })}
        initiallyOpen>
        <div className="col-12">
          <Field
            disabled={disabled}
            name="priceRange"
            component={SelectField}
            label={formatMessage({ id: 'COMPANIES.PRICE_RANGES_REQ' })}
            options={priceRangeOptions}
            useOnlyValueAsFieldValue
          />
        </div>

        <div className="col-12">
          <Field
            disabled={disabled}
            name="opening"
            component={SelectField}
            label={formatMessage({ id: 'COMPANIES.OPENINGS_REQ' })}
            options={openingOptions}
            useOnlyValueAsFieldValue
          />
        </div>

        <div className="col-12">
          {isCompany ? (
            <Field
              disabled={disabled}
              name="mainGroupType"
              component={SelectField}
              label={formatMessage({ id: 'COMPANIES.MAIN_GROUP_TYPES_REQ' })}
              options={groupTypeOptions}
              useOnlyValueAsFieldValue
            />
          ) : (
            <CompanySubsidiaryTypeChooser
              serviceTypes={groupTypes}
              companyServiceTypeInputName="mainGroupType"
              companyServiceTypeInputLabel={formatMessage({
                id: 'COMPANIES.MAIN_GROUP_TYPES_REQ',
              })}
              subsidiaryServiceTypeInputName="groupType"
              subsidiaryServiceTypeInputLabel={formatMessage({ id: 'COMPANIES.GROUP_TYPES' })}
            />
          )}
        </div>
      </CollapseField>
    </div>
  );
};

CompanyGeneralTab.propTypes = {
  company: PropTypes.object.isRequired,
  priceRanges: PropTypes.array.isRequired,
  openings: PropTypes.array.isRequired,
  groupTypes: PropTypes.array.isRequired,
  availableCompanies: PropTypes.array,
  mainGroupType: PropTypes.object,
  showIsEnabled: PropTypes.bool,
  isCompany: PropTypes.bool,
  isAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(CompanyGeneralTab);
