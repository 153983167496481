const CHART_DETAIL_TRANSLATE_PREFIX = 'CHART_DETAIL';

export const chartDetailDataKeys = {
  ACTIVE_USERS_COUNT: 'activeUserCount',
  ACTIVE_USERS_IDS: 'activeUserIds',
  INACTIVE_USERS_COUNT: 'inactiveUserCount',
  INACTIVE_USERS_IDS: 'inactiveUserIds',
  TOTAL_USERS_COUNT: 'totalUserCount',
  TOTAL_USERS_IDS: 'totalUserIds',
  TEST_SUCCESS_COUNT: 'testSuccessCount',
  TEST_FAILURE_COUNT: 'testFailedCount',
  TOTAL_TEST_COUNT: 'testTotalCount',
  ACTIVE_GROUPS_COUNT: 'activeGroupCount',
  ACTIVE_GROUPS_IDS: 'activeGroupIds',
  INACTIVE_GROUPS_COUNT: 'inactiveGroupCount',
  INACTIVE_GROUPS_IDS: 'inactiveGroupIds',
  TOTAL_GROUPS_COUNT: 'totalGroupCount',
  TOTAL_GROUPS_IDS: 'totalGroupIds',
  ACTIVE_COURSES_COUNT: 'activeCourseCount',
  ACTIVE_COURSES_IDS: 'activeCourseIds',
  INACTIVE_COURSES_COUNT: 'inactiveCourseCount',
  INACTIVE_COURSES_IDS: 'inactiveCourseIds',
  TOTAL_COURSES_COUNT: 'totalCourseCount',
  TOTAL_COURSES_IDS: 'totalCourseIds',
  ACTIVE_LESSONS_COUNT: 'activeLessonCount',
  ACTIVE_LESSONS_IDS: 'activeLessonIds',
  INACTIVE_LESSONS_COUNT: 'inactiveLessonCount',
  INACTIVE_LESSONS_IDS: 'inactiveLessonIds',
  TOTAL_LESSONS_COUNT: 'totalLessonCount',
  TOTAL_LESSONS_IDS: 'totalLessonIds',

  //TODO: Update keys if BE is finished:
  NEW_SUBSCRIPTION_COUNT: 'newSubscriptionCount',
  TOTAL_SUBSCRIPTION_COUNT: 'totalSubscriptionCount',
  SUBSCRIPTION_TREND: 'subscriptionTrend',
};

export const chartDetailConstants = {
  [chartDetailDataKeys.ACTIVE_USERS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.ACTIVE`,
  },
  [chartDetailDataKeys.INACTIVE_USERS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.INACTIVE`,
  },
  [chartDetailDataKeys.TOTAL_USERS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.TOTAL`,
  },
  [chartDetailDataKeys.TEST_SUCCESS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.SUCCESSFUL`,
  },
  [chartDetailDataKeys.TEST_FAILURE_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.FAILED`,
  },
  [chartDetailDataKeys.TOTAL_TEST_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.TOTAL`,
  },
  [chartDetailDataKeys.ACTIVE_GROUPS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.ACTIVE`,
  },
  [chartDetailDataKeys.INACTIVE_GROUPS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.INACTIVE`,
  },
  [chartDetailDataKeys.TOTAL_GROUPS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.TOTAL`,
  },
  [chartDetailDataKeys.ACTIVE_COURSES_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.ACTIVE`,
  },
  [chartDetailDataKeys.INACTIVE_COURSES_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.INACTIVE`,
  },
  [chartDetailDataKeys.TOTAL_COURSES_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.TOTAL`,
  },
  [chartDetailDataKeys.ACTIVE_LESSONS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.ACTIVE`,
  },
  [chartDetailDataKeys.INACTIVE_LESSONS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.INACTIVE`,
  },
  [chartDetailDataKeys.TOTAL_LESSONS_COUNT]: {
    translateId: `${CHART_DETAIL_TRANSLATE_PREFIX}.TOTAL`,
  },
};
