export const moveArrayItem = (
  direction,
  resultItems,
  selectedRightItems,
  setSelectedRightItems
) => {
  const index = resultItems.findIndex(({ id }) => id === selectedRightItems[0].id);
  const item = resultItems[index];
  const size = resultItems.length;

  const determineDirection = {
    top: () => {
      resultItems.splice(index, 1);
      resultItems.splice(0, 0, item);
    },
    bottom: () => {
      resultItems.splice(index, 1);
      resultItems.splice(size - 1, 0, item);
    },
    oneUp: () => {
      resultItems.splice(index, 1);
      resultItems.splice(index - 1, 0, item);
    },
    oneDown: () => {
      resultItems.splice(index, 1);
      resultItems.splice(index + 1, 0, item);
    },
  };

  determineDirection[direction]();

  setSelectedRightItems((prev) => [...prev]);
};
