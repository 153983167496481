import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withLoading } from 'hoc';
import { ConfirmModal } from 'components';
import SubsidiaryGroups from './SubsidiaryGroups';
import SubsidiaryGroupEditor from '../SubsidiaryGroupEditor/SubsidiaryGroupEditor';
import { getGroupsBySubsidiaryId, deleteGroup } from 'services/groups';
import { modalType } from 'util/modalType';

const defaultLoaderHeight = '200px';

const SubsidiaryGroupsContainer = ({
  open,
  subsidiaryId,
  hasRoleToCreate,
  hasRoleToEdit,
  hasRoleToDelete,
  intl: { formatMessage },
}) => {
  const [list, setList] = useState([]);
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState();

  const openEditModal = (group) => {
    setGroup({ subsidiaryId, ...group });
    setModal(modalType.EDIT);
  };

  const openDeleteModal = (group) => {
    setGroup({ subsidiaryId, ...group });
    setModal(modalType.DELETE);
  };

  const closeModal = () => {
    setModal(null);
  };

  const handleDelete = async (group) => {
    try {
      await deleteGroup(group.id);
    } finally {
      closeModal();
    }
  };

  const fetchGroupList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getGroupsBySubsidiaryId(subsidiaryId);
      setList(res);
    } catch (e) {
      setList([]);
    } finally {
      setLoading(false);
    }
  }, [subsidiaryId]);

  useEffect(() => {
    if (open) {
      fetchGroupList();
    }
  }, [open, subsidiaryId, fetchGroupList]);

  return (
    <div>
      <SubsidiaryGroups
        list={list}
        height={defaultLoaderHeight}
        loading={loading}
        hasRoleToCreate={hasRoleToCreate}
        hasRoleToDelete={hasRoleToDelete}
        hasRoleToEdit={hasRoleToEdit}
        openDeleteModal={openDeleteModal}
        openEditModal={openEditModal}
      />
      <SubsidiaryGroupEditor
        open={modal === modalType.EDIT}
        groupId={group?.id}
        groupSubsidiaryId={group?.subsidiaryId}
        loadList={fetchGroupList}
        closeEditor={closeModal}
      />
      <ConfirmModal
        open={modal === modalType.DELETE}
        onClose={closeModal}
        saveAction={async () => {
          await handleDelete(group);
          await fetchGroupList();
        }}
        confirmElement={formatMessage(
          { id: 'COMMON.DELETE_QUESTION' },
          { title: group && group.name }
        )}
      />
    </div>
  );
};

SubsidiaryGroupsContainer.propTypes = {
  open: PropTypes.bool,
  subsidiaryId: PropTypes.number,
  hasRoleToCreate: PropTypes.bool.isRequired,
  hasRoleToEdit: PropTypes.bool.isRequired,
  hasRoleToDelete: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withLoading(injectIntl(SubsidiaryGroupsContainer));
