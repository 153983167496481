import qs from 'query-string';

import {
  getCompanySubsidiaryTestStatistics,
  getCompanySubsidiaryUserStatistics,
  getCompanySubsidiaryGroupStatistics,
  getCompanySubsidiaryCourseStatistics,
  getCompanySubsidiaryLessonStatistics,
} from 'services/statistics';
import { SubsidiaryAccess, CourseAccess, LessonAccess, UserAccess } from 'libs/accessManagement';

import { axisDataKeys } from 'util/chartAxisDataKeys';
import { chartDetailDataKeys } from 'util/chartDetailDataKeys';
import {
  numberOfUsersColor,
  numberOfGroupsColor,
  numberOfCoursesColor,
  numberOfLessonsColor,
  totalUsersColor,
  activeUsersColor,
  inactiveUsersColor,
  totalGroupsColor,
  activeGroupsColor,
  inactiveGroupsColor,
  totalCoursesColor,
  activeCoursesColor,
  inactiveCoursesColor,
  totalLessonsColor,
  activeLessonsColor,
  inactiveLessonsColor,
} from '../styles/_colors.scss';

export const companySubsidiaryStatisticTypes = {
  NUM_OF_USERS: 'NUM_OF_USERS',
  NUM_OF_GROUPS: 'NUM_OF_GROUPS',
  NUM_OF_COURSES: 'NUM_OF_COURSES',
  NUM_OF_LESSONS: 'NUM_OF_LESSONS',
};

const fetchCompanyStatisticsData = async (fetchTrendChart, params) => {
  const [primaryChartResult, secondaryChartResult] = await Promise.allSettled([
    fetchTrendChart(),
    getCompanySubsidiaryTestStatistics(params),
  ]);
  return {
    primaryChart: primaryChartResult.status === 'fulfilled' ? primaryChartResult.value : {},
    secondaryChart: secondaryChartResult.status === 'fulfilled' ? secondaryChartResult.value : {},
  };
};

const getChartAndChartDetailData = (chart) => {
  const { data, period, ...remainingResponseData } = chart || {};
  return { chartData: data, chartDetail: remainingResponseData };
};

const getCompanyStatisticsDataFromResponse = (response) => {
  const { primaryChart, secondaryChart } = response || {};

  return {
    primaryChart: getChartAndChartDetailData(primaryChart),
    secondaryChart: getChartAndChartDetailData(secondaryChart),
  };
};

const getNavigationData = (listName, path, query) => (chartDetail, idPropertyName) => {
  const idsList = getChartDetailPropertyValue(chartDetail, idPropertyName) || [];
  return {
    disabled: idsList.length === 0,
    path,
    state: {
      [listName]: idsList,
    },
    search: query ? `?${qs.stringify(query)}` : undefined,
  };
};

const getNumOfUsersNavigationData = getNavigationData('userIds', '/home/users/subsidiary');
const getNumOfGroupsNavigationData = getNavigationData('groupIds', '/home/groups');
const getNumOfCoursesNavigationData = getNavigationData('courseIds', '/home/courses');
const getNumOfLessonsNavigationData = getNavigationData('lessonIds', '/home/lessons');

const getChartDetailPropertyValue = (chartDetail, property) => chartDetail && chartDetail[property];

export const companySubsidiaryStatisticTypeConstants = {
  [companySubsidiaryStatisticTypes.NUM_OF_USERS]: {
    translateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_USERS',
    filter: { filterType: companySubsidiaryStatisticTypes.NUM_OF_USERS },
    color: numberOfUsersColor,
    buttonClassname: 'number-of-users',
    fetchStatistic: async (params) =>
      fetchCompanyStatisticsData(() => getCompanySubsidiaryUserStatistics(params), params),
    getStatisticsDataFromResponse: (responseData) =>
      getCompanyStatisticsDataFromResponse(responseData),
    getCountFromBaseData: (baseData) => baseData && baseData.userCount,
    chartConstants: {
      chartAreas: [
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_USERS,
          key: axisDataKeys.TOTAL_USERS_COUNT,
          color: totalUsersColor,
        },
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_USERS,
          key: axisDataKeys.ACTIVE_USERS_COUNT,
          color: activeUsersColor,
        },
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_USERS,
          key: axisDataKeys.INACTIVE_USERS_COUNT,
          color: inactiveUsersColor,
        },
      ],
      yAxes: [
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_USERS,
          labelTranslateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_USERS_Y_AXIS_LABEL',
        },
      ],
      xAxisDataKey: axisDataKeys.DATE,
    },
    getChartDetailData: (chartDetail, profile) => {
      const hasRoleToNavigate = UserAccess.getHasRoles(profile.profile).hasRoleToAccess;
      return [
        {
          key: chartDetailDataKeys.TOTAL_USERS_COUNT,
          color: totalUsersColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.TOTAL_USERS_COUNT),
          navigationData: getNumOfUsersNavigationData(
            chartDetail,
            chartDetailDataKeys.TOTAL_USERS_IDS
          ),
        },
        {
          key: chartDetailDataKeys.ACTIVE_USERS_COUNT,
          color: activeUsersColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.ACTIVE_USERS_COUNT),
          navigationData: getNumOfUsersNavigationData(
            chartDetail,
            chartDetailDataKeys.ACTIVE_USERS_IDS
          ),
        },
        {
          key: chartDetailDataKeys.INACTIVE_USERS_COUNT,
          color: inactiveUsersColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.INACTIVE_USERS_COUNT),
          navigationData: getNumOfUsersNavigationData(
            chartDetail,
            chartDetailDataKeys.INACTIVE_USERS_IDS
          ),
        },
      ].map((item) => ({ ...item, hasRoleToNavigate }));
    },
  },
  [companySubsidiaryStatisticTypes.NUM_OF_GROUPS]: {
    translateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_GROUPS',
    filter: { filterType: companySubsidiaryStatisticTypes.NUM_OF_GROUPS },
    buttonClassname: 'number-of-groups',
    color: numberOfGroupsColor,
    fetchStatistic: async (params) =>
      fetchCompanyStatisticsData(() => getCompanySubsidiaryGroupStatistics(params), params),
    getStatisticsDataFromResponse: (responseData) =>
      getCompanyStatisticsDataFromResponse(responseData),
    getCountFromBaseData: (baseData) => baseData && baseData.groupCount,
    chartConstants: {
      chartAreas: [
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_GROUPS,
          key: axisDataKeys.TOTAL_GROUPS_COUNT,
          color: totalGroupsColor,
        },
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_GROUPS,
          key: axisDataKeys.ACTIVE_GROUPS_COUNT,
          color: activeGroupsColor,
        },
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_GROUPS,
          key: axisDataKeys.INACTIVE_GROUPS_COUNT,
          color: inactiveGroupsColor,
        },
      ],
      yAxes: [
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_GROUPS,
          labelTranslateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_GROUPS_Y_AXIS_LABEL',
        },
      ],
      xAxisDataKey: axisDataKeys.DATE,
    },

    getChartDetailData: (chartDetail, profile) => {
      const hasRoleToNavigate = SubsidiaryAccess.getHasRoles(profile.profile).GroupAccess
        .hasRoleToAccess;
      return [
        {
          key: chartDetailDataKeys.TOTAL_GROUPS_COUNT,
          color: totalGroupsColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.TOTAL_GROUPS_COUNT),
          navigationData: getNumOfGroupsNavigationData(
            chartDetail,
            chartDetailDataKeys.TOTAL_GROUPS_IDS
          ),
        },
        {
          key: chartDetailDataKeys.ACTIVE_GROUPS_COUNT,
          color: activeGroupsColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.ACTIVE_GROUPS_COUNT),
          navigationData: getNumOfGroupsNavigationData(
            chartDetail,
            chartDetailDataKeys.ACTIVE_GROUPS_IDS
          ),
        },
        {
          key: chartDetailDataKeys.INACTIVE_GROUPS_COUNT,
          color: inactiveGroupsColor,
          value: getChartDetailPropertyValue(
            chartDetail,
            chartDetailDataKeys.INACTIVE_GROUPS_COUNT
          ),
          navigationData: getNumOfGroupsNavigationData(
            chartDetail,
            chartDetailDataKeys.INACTIVE_GROUPS_IDS
          ),
        },
      ].map((item) => ({ ...item, hasRoleToNavigate }));
    },
  },
  [companySubsidiaryStatisticTypes.NUM_OF_COURSES]: {
    translateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_COURSES',
    filter: { filterType: companySubsidiaryStatisticTypes.NUM_OF_COURSES },
    buttonClassname: 'number-of-courses',
    color: numberOfCoursesColor,
    fetchStatistic: async (params) =>
      fetchCompanyStatisticsData(() => getCompanySubsidiaryCourseStatistics(params), params),
    getStatisticsDataFromResponse: (responseData) =>
      getCompanyStatisticsDataFromResponse(responseData),
    getCountFromBaseData: (baseData) => baseData && baseData.courseCount,
    chartConstants: {
      chartAreas: [
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_COURSES,
          key: axisDataKeys.TOTAL_COURSES_COUNT,
          color: totalCoursesColor,
        },
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_COURSES,
          key: axisDataKeys.ACTIVE_COURSES_COUNT,
          color: activeCoursesColor,
        },
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_COURSES,
          key: axisDataKeys.INACTIVE_COURSES_COUNT,
          color: inactiveCoursesColor,
        },
      ],
      yAxes: [
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_COURSES,
          labelTranslateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_COURSES_Y_AXIS_LABEL',
        },
      ],
      xAxisDataKey: axisDataKeys.DATE,
    },
    getChartDetailData: (chartDetail, profile) => {
      const hasRoleToNavigate = CourseAccess.getHasRoles(profile.profile).hasRoleToAccess;
      return [
        {
          key: chartDetailDataKeys.TOTAL_COURSES_COUNT,
          color: totalCoursesColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.TOTAL_COURSES_COUNT),
          navigationData: getNumOfCoursesNavigationData(
            chartDetail,
            chartDetailDataKeys.TOTAL_COURSES_IDS
          ),
        },
        {
          key: chartDetailDataKeys.ACTIVE_COURSES_COUNT,
          color: activeCoursesColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.ACTIVE_COURSES_COUNT),
          navigationData: getNumOfCoursesNavigationData(
            chartDetail,
            chartDetailDataKeys.ACTIVE_COURSES_IDS
          ),
        },
        {
          key: chartDetailDataKeys.INACTIVE_COURSES_COUNT,
          color: inactiveCoursesColor,
          value: getChartDetailPropertyValue(
            chartDetail,
            chartDetailDataKeys.INACTIVE_COURSES_COUNT
          ),
          navigationData: getNumOfCoursesNavigationData(
            chartDetail,
            chartDetailDataKeys.INACTIVE_COURSES_IDS
          ),
        },
      ].map((item) => ({ ...item, hasRoleToNavigate }));
    },
  },
  [companySubsidiaryStatisticTypes.NUM_OF_LESSONS]: {
    translateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_LESSONS',
    filter: { filterType: companySubsidiaryStatisticTypes.NUM_OF_LESSONS },
    buttonClassname: 'number-of-lessons',
    color: numberOfLessonsColor,
    fetchStatistic: async (params) =>
      fetchCompanyStatisticsData(() => getCompanySubsidiaryLessonStatistics(params), params),
    getStatisticsDataFromResponse: (responseData) =>
      getCompanyStatisticsDataFromResponse(responseData),
    getCountFromBaseData: (baseData) => baseData && baseData.lessonCount,
    chartConstants: {
      chartAreas: [
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_LESSONS,
          key: axisDataKeys.TOTAL_LESSONS_COUNT,
          color: totalLessonsColor,
        },
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_LESSONS,
          key: axisDataKeys.ACTIVE_LESSONS_COUNT,
          color: activeLessonsColor,
        },
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_LESSONS,
          key: axisDataKeys.INACTIVE_LESSONS_COUNT,
          color: inactiveLessonsColor,
        },
      ],
      yAxes: [
        {
          yAxisId: companySubsidiaryStatisticTypes.NUM_OF_LESSONS,
          labelTranslateId: 'COMPANY_SUBSIDIARY_STATISTICS_TYPES.NUM_OF_LESSONS_Y_AXIS_LABEL',
        },
      ],
      xAxisDataKey: axisDataKeys.DATE,
    },

    getChartDetailData: (chartDetail, profile) => {
      const hasRoleToNavigate = LessonAccess.getHasRoles(profile.profile).hasRoleToAccess;
      return [
        {
          key: chartDetailDataKeys.TOTAL_LESSONS_COUNT,
          color: totalLessonsColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.TOTAL_LESSONS_COUNT),
          navigationData: getNumOfLessonsNavigationData(
            chartDetail,
            chartDetailDataKeys.TOTAL_LESSONS_IDS
          ),
        },
        {
          key: chartDetailDataKeys.ACTIVE_LESSONS_COUNT,
          color: activeLessonsColor,
          value: getChartDetailPropertyValue(chartDetail, chartDetailDataKeys.ACTIVE_LESSONS_COUNT),
          navigationData: getNumOfLessonsNavigationData(
            chartDetail,
            chartDetailDataKeys.ACTIVE_LESSONS_IDS
          ),
        },
        {
          key: chartDetailDataKeys.INACTIVE_LESSONS_COUNT,
          color: inactiveLessonsColor,
          value: getChartDetailPropertyValue(
            chartDetail,
            chartDetailDataKeys.INACTIVE_LESSONS_COUNT
          ),
          navigationData: getNumOfLessonsNavigationData(
            chartDetail,
            chartDetailDataKeys.INACTIVE_LESSONS_IDS
          ),
        },
      ].map((item) => ({ ...item, hasRoleToNavigate }));
    },
  },
};

export const getDefaultCompanySubsidiaryStatisticType =
  companySubsidiaryStatisticTypeConstants[companySubsidiaryStatisticTypes.NUM_OF_USERS];
