import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { statisticsType, statisticsTypeConstants } from 'util/statisticsType';

const BaseStatistics = ({ setAccordionOpen, type, shown, ...props }) => {
  useEffect(() => {
    setAccordionOpen(shown);
  }, [shown, setAccordionOpen]);

  return (
    <div>
      {type
        ? statisticsTypeConstants[type].getStatisticsComponent({
            shown,
            setAccordionOpen,
            ...props,
          })
        : null}
    </div>
  );
};

BaseStatistics.propTypes = {
  shown: PropTypes.bool,
  setAccordionOpen: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(statisticsType)).isRequired,
};

export default BaseStatistics;
