import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import debounce from 'lodash/debounce';

const SELECT = 'select';
const CREATABLE = 'creatable';
const ASYNC = 'async';
const ASYNC_CREATABLE = 'async-creatable';

const getFetchOptions = (loadOptions, asyncDelay = 500) =>
  debounce((inputValue, callback) => {
    if (inputValue) {
      loadOptions(inputValue).then((options) => callback(options));
    } else {
      callback([]);
    }
  }, asyncDelay);

const getCommonProps = (props) => {
  const { hideValue, value, disabled, multi, ...remProps } = props;

  return {
    value: !hideValue ? value : null,
    isDisabled: disabled,
    isMulti: multi,
    ...remProps,
  };
};

const getCommonAsyncProps = ({
  cacheOptions = true,
  defaultOptions = true,
  formatCreateLabel = (newOptionValue) => `Create "${newOptionValue}"`,
  ...props
}) => {
  return {
    ...getCommonProps(props),
    cacheOptions,
    defaultOptions,
    formatCreateLabel,
  };
};

const renderProps = {
  [SELECT]: (props) => getCommonProps(props),
  [CREATABLE]: (props) => getCommonProps(props),
  [ASYNC]: (props) => getCommonAsyncProps(props),
  [ASYNC_CREATABLE]: (props) => getCommonAsyncProps(props),
};

const getTypeFromProps = ({ loadOptions, addNewOption }) => {
  const isAsync = !!loadOptions;

  if (isAsync) {
    return addNewOption ? ASYNC_CREATABLE : ASYNC;
  }

  return addNewOption ? CREATABLE : SELECT;
};

const renderComponent = {
  [SELECT]: Select,
  [CREATABLE]: Creatable,
  [ASYNC]: AsyncSelect,
  [ASYNC_CREATABLE]: AsyncCreatableSelect,
};

const SelectWrapper = ({ loadOptions, asyncDelay = 500, ...props }) => {
  const debouncedLoadOptions = useCallback(
    loadOptions ? getFetchOptions(loadOptions, asyncDelay) : undefined,
    [loadOptions, asyncDelay]
  );

  const getComponent = ({ loadOptions, addNewOption }, props) => {
    const type = getTypeFromProps({ loadOptions, addNewOption });

    const Component = renderComponent[type];
    const currentProps = renderProps[type](props);

    return <Component {...currentProps} />;
  };

  return (
    <div>
      {getComponent(
        { loadOptions, addNewOption: props.addNewOption },
        { ...props, loadOptions: debouncedLoadOptions }
      )}
    </div>
  );
};

SelectWrapper.propTypes = {
  loadOptions: PropTypes.func,
  addNewOption: PropTypes.bool,
  asyncDelay: PropTypes.number,
};

export default SelectWrapper;
