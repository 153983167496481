import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './style.scss';

const UserLabel = ({ baseData, intl: { formatMessage } }) => {
  const { name, email } = baseData || {};

  return (
    <div className="title-label-container">
      {name ? name : formatMessage({ id: 'COMMON.ANONYMUS' })}
      {' - '}
      {email ? email : formatMessage({ id: 'COMMON.NO_DATA' })}
    </div>
  );
};

UserLabel.propTypes = {
  baseData: PropTypes.object,
};

export default injectIntl(UserLabel);
