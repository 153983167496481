import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CoursesPage from './CoursesPage';
import { showLoader, hideLoader } from '../../actions/common';

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursesPage);
