//This file is only for generating test data
//TODO: Delete when BE is finished
import add from 'date-fns/add';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';

import Settings from 'config/settings';
import { axisDataKeys } from 'util/chartAxisDataKeys';
import { chartDetailDataKeys } from 'util/chartDetailDataKeys';
import { companySubsidiaryStatisticTypes } from 'util/companySubsidiaryStatisticTypes';
import { getFormattedDate, SECONDS, MINUTES, HOURS } from 'util/timeUtil';
import { lessonsStatus } from 'libs/lessonsStatus';

const DATA_POINTS = 10;

const differenceMethods = {
  [SECONDS]: differenceInSeconds,
  [MINUTES]: differenceInMinutes,
  [HOURS]: differenceInHours,
};

const MEMBER_COURSES_PER_PAGE = 5;

const {
  ACTIVE_USERS_COUNT,
  INACTIVE_USERS_COUNT,
  TOTAL_USERS_COUNT,
  ACTIVE_COURSES_COUNT,
  INACTIVE_COURSES_COUNT,
  TOTAL_COURSES_COUNT,
  ACTIVE_LESSONS_COUNT,
  INACTIVE_LESSONS_COUNT,
  TOTAL_LESSONS_COUNT,
  ACTIVE_GROUPS_COUNT,
  INACTIVE_GROUPS_COUNT,
  TOTAL_GROUPS_COUNT,
  TEST_SUCCESS_COUNT: TEST_SUCCESS_RATE,
  TEST_FAILURE_COUNT: TEST_FAILURE_RATE,
  TOTAL_TEST_COUNT: TEST_OVERALL_RATE,
  NEW_SUBSCRIPTION_COUNT,
  TOTAL_SUBSCRIPTION_COUNT,
  SUBSCRIPTION_TREND,
  NUM_OF_USERS,
  SPENT_TIME_PERIOD,
  TOTAL_TIME_SPENT,
  ACTUAL_TIME_SPENT,
  NUM_OF_LESSONS_USED,
  IMPORTANT_LESSONS_VIEWED,
  NOT_IMPORTANT_LESSONS_VIEWED,
  USAGE_TREND,
} = axisDataKeys;

const dummyMemberCourseList = [
  {
    id: 0,
    isImportant: true,
    course: 'Alma',
    lesson: 'Alma lecke',
    spentTime: 12312,
    status: lessonsStatus.PASSED,
  },
  {
    id: 1,
    isImportant: false,
    course: 'Barack',
    lesson: 'Barack lecke',
    spentTime: 31231,
    status: lessonsStatus.NOT_STARTED,
  },
  {
    id: 2,
    isImportant: true,
    course: 'Citrom',
    lesson: 'Citrom lecke',
    spentTime: 33322,
    status: lessonsStatus.PASSED,
  },
  {
    id: 3,
    isImportant: true,
    course: 'Dinnye',
    lesson: 'Dinnye lecke',
    spentTime: 123,
    status: lessonsStatus.NOT_STARTED,
  },
  {
    id: 4,
    isImportant: true,
    course: 'Gyömbéralma',
    lesson:
      'Gyömbéralma lecke Gyömbéralma lecke Gyömbéralma lecke Gyömbéralma lecke Gyömbéralma lecke Gyömbéralma lecke',
    spentTime: 54345,
    status: lessonsStatus.STARTED,
  },
  {
    id: 5,
    isImportant: false,
    course: 'Rambután',
    lesson: 'Rambután lecke',
    spentTime: 1356,
    status: lessonsStatus.STARTED,
  },
  {
    id: 6,
    isImportant: true,
    course: 'Szelídgeszteny',
    lesson: 'Szelídgeszteny lecke',
    spentTime: 33322,
    status: lessonsStatus.NOT_STARTED,
  },
  {
    id: 7,
    isImportant: false,
    course: 'Papaja',
    lesson: 'Papaja lecke',
    spentTime: 987,
    status: lessonsStatus.NOT_STARTED,
  },
  {
    id: 8,
    isImportant: false,
    course: 'Dió',
    lesson: 'Dió lecke',
    spentTime: 853,
    status: lessonsStatus.COMPLETED,
  },
  {
    id: 9,
    isImportant: true,
    course: 'Öreg szőlő',
    lesson: 'Öreg szőlő lecke',
    spentTime: 666,
    status: lessonsStatus.NOT_STARTED,
  },
  {
    id: 10,
    isImportant: true,
    course: 'Egres',
    lesson: 'Egres lecke',
    spentTime: 443,
    status: lessonsStatus.NOT_STARTED,
  },
  {
    id: 11,
    isImportant: true,
    course: 'Kumkvat',
    lesson: 'Kumkvat lecke',
    spentTime: 9871,
    status: lessonsStatus.STARTED,
  },
  {
    id: 12,
    isImportant: true,
    course: 'Licsi',
    lesson: 'Licsi lecke',
    spentTime: 3765,
    status: lessonsStatus.STARTED,
  },
  {
    id: 13,
    isImportant: false,
    course: 'Szőlőtörköly',
    lesson: 'Szőlőtörköly lecke',
    spentTime: 9987,
    status: lessonsStatus.COMPLETED,
  },
  {
    id: 14,
    isImportant: true,
    course: 'Kaktuszfüge',
    lesson: 'Kaktuszfüge lecke',
    spentTime: 1965,
    status: lessonsStatus.STARTED,
  },
  {
    id: 15,
    isImportant: false,
    course: 'Durián',
    lesson: 'Durián lecke',
    spentTime: 9643,
    status: lessonsStatus.NOT_STARTED,
  },
  {
    id: 16,
    isImportant: true,
    course: 'Európai homoktövis',
    lesson: 'Európai homoktövis lecke',
    spentTime: 2222,
    status: lessonsStatus.NOT_STARTED,
  },
  {
    id: 17,
    isImportant: true,
    course: 'Csirimojó',
    lesson: 'Csirimojó lecke',
    spentTime: 9567,
    status: lessonsStatus.COMPLETED,
  },
  {
    id: 18,
    isImportant: false,
    course: 'Kuruba',
    lesson: 'Kuruba lecke',
    spentTime: 6432,
    status: lessonsStatus.COMPLETED,
  },
  {
    id: 19,
    isImportant: true,
    course: 'Földicseresznye',
    lesson: 'Földicseresznye lecke',
    spentTime: 4444,
    status: lessonsStatus.COMPLETED,
  },
  {
    id: 20,
    isImportant: true,
    course: 'Fekete törpeberkenye',
    lesson: 'Fekete törpeberkenye lecke',
    spentTime: 1943,
    status: lessonsStatus.STARTED,
  },
  {
    id: 21,
    isImportant: false,
    course: 'Medjool',
    lesson: 'Medjool lecke',
    spentTime: 96544,
    status: lessonsStatus.STARTED,
  },
];

const dummySuccessQuestions = [
  { question: 'Success Question 1', percent: 100 },
  { question: 'Success Question 2', percent: 89 },
  {
    question:
      'Success Question 31 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3',
    percent: 21,
  },
  {
    question:
      'Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3',
    percent: 21,
  },
  {
    question:
      'Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3 Success Question 3',
    percent: 21,
  },
  {
    question:
      'Question 13 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 13 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 33 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 34 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 35 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 36 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 37 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 38 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 39 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 310 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 311 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
];

const dummyFailedQuestions = [
  { question: 'Failed Question 1', percent: 100 },
  { question: 'Failed Question 2', percent: 89 },
  {
    question:
      'Failed Question 31 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3',
    percent: 21,
  },
  {
    question:
      'Failed Question 32 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3',
    percent: 21,
  },
  {
    question:
      'Failed Question 33 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3 Failed Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 34 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 53 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 77 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 36 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 37 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 38 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 39 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 310 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 311 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
  {
    question:
      'Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3 Question 3',
    percent: 21,
  },
];

const baseBoolComperator = (val1, val2) => {
  return val1 === false && val2 === true ? 1 : val2 === false && val1 === true ? -1 : 0;
};

const baseStringComperator = (val1, val2) => {
  const upperCaseVal1 = val1.toUpperCase();
  const upperCaseVal2 = val2.toUpperCase();
  return upperCaseVal1 > upperCaseVal2 ? 1 : upperCaseVal2 > upperCaseVal1 ? -1 : 0;
};

const baseNumberComperator = (val1, val2) => {
  return val1 > val2 ? 1 : val2 > val1 ? -1 : 0;
};

const getBasicParams = (startDate, endDate) => ({
  from: startDate,
  to: endDate,
});

const generateDummyTimeData = (from = new Date(), to = add(new Date(), { years: 1 })) => {
  const stepInSeconds = (differenceInSeconds(to, from) / DATA_POINTS).toFixed(0);

  let date = new Date(from);
  const dates = [];
  while (differenceInSeconds(to, date) > 0) {
    dates.push(getFormattedDate(date));
    date = add(date, { [SECONDS]: stepInSeconds });
  }
  return dates;
};

const generateDummyIntervalData = (
  fromDate = new Date(),
  toDate = add(new Date(), { months: 1 }),
  numberOfIntervals = 10,
  timeUnit = MINUTES
) => {
  const diff = differenceMethods[timeUnit](toDate, fromDate);
  const step = Math.floor(diff / numberOfIntervals);

  const intervals = [];
  let classStart = 0;
  let classEnd = classStart + step;
  while (classEnd <= diff && diff > 0) {
    intervals.push(`${classStart}-${classEnd}`);
    classStart = classEnd;
    classEnd = classEnd + step;
  }
  return intervals;
};

const getCompanySubsidiaryStatisticsRecord = (type) => {
  const firstValue = Math.floor(Math.random() * 100) + 100;
  const secondValue = Math.floor(Math.random() * 30) + 10;
  return type === companySubsidiaryStatisticTypes.NUM_OF_USERS
    ? {
        [ACTIVE_USERS_COUNT]: firstValue,
        [INACTIVE_USERS_COUNT]: secondValue,
        [TOTAL_USERS_COUNT]: firstValue + secondValue,
      }
    : type === companySubsidiaryStatisticTypes.NUM_OF_GROUPS
    ? {
        [ACTIVE_GROUPS_COUNT]: firstValue,
        [INACTIVE_GROUPS_COUNT]: secondValue,
        [TOTAL_GROUPS_COUNT]: firstValue + secondValue,
      }
    : type === companySubsidiaryStatisticTypes.NUM_OF_LESSONS
    ? {
        [ACTIVE_LESSONS_COUNT]: firstValue,
        [INACTIVE_LESSONS_COUNT]: secondValue,
        [TOTAL_LESSONS_COUNT]: firstValue + secondValue,
      }
    : type === companySubsidiaryStatisticTypes.NUM_OF_COURSES
    ? {
        [ACTIVE_COURSES_COUNT]: firstValue,
        [INACTIVE_COURSES_COUNT]: secondValue,
        [TOTAL_COURSES_COUNT]: firstValue + secondValue,
      }
    : {};
};

const getContentStatisticsRecord = () => {
  const firstValue = Math.floor(Math.random() * 20);
  const secondValue = Math.floor(Math.random() * 30) + 10;
  return {
    [NEW_SUBSCRIPTION_COUNT]: firstValue,
    [TOTAL_SUBSCRIPTION_COUNT]: secondValue,
    [SUBSCRIPTION_TREND]: firstValue + secondValue,
  };
};

const getOverallActivityStatisticsRecord = () => {
  const actualTimeSpent = Math.floor(Math.random() * 10);
  const notActualTimeSpent = Math.floor(Math.random() * 10) + 1;
  const importantLessonsViewed = Math.floor(Math.random() * 2) + 10;
  const notImportantLessonsViewed = Math.floor(Math.random() * 30) + 10;

  return {
    [ACTUAL_TIME_SPENT]: actualTimeSpent,
    [TOTAL_TIME_SPENT]: actualTimeSpent + notActualTimeSpent,
    [IMPORTANT_LESSONS_VIEWED]: importantLessonsViewed,
    [NOT_IMPORTANT_LESSONS_VIEWED]: notImportantLessonsViewed,
    [NUM_OF_LESSONS_USED]:
      importantLessonsViewed + notImportantLessonsViewed + Math.floor(Math.random() * 20) + 5,
  };
};

const getTestResultsRecord = () => {
  const firstValue = Math.floor(Math.random() * 200);
  const secondValue = Math.floor(Math.random() * 300) + 100;
  return {
    [TEST_SUCCESS_RATE]: firstValue,
    [TEST_FAILURE_RATE]: secondValue,
    [TEST_OVERALL_RATE]: firstValue + secondValue,
  };
};

const getTimeSpentRecord = () => {
  return {
    [NUM_OF_USERS]: Math.floor(Math.random() * 200),
  };
};

const getContentUsageRecord = () => {
  return {
    [USAGE_TREND]: Math.floor(Math.random() * 200),
  };
};

const generateTimeBasedDummyChartData = (generateRecord = () => ({}), fromDate, toDate) => {
  const dates = generateDummyTimeData(fromDate, toDate);

  return dates.map((date) => ({
    date,
    ...generateRecord(),
  }));
};

const generateIntervalBasedDummyChartData = (
  generateRecord = () => ({}),
  fromDate,
  toDate,
  numberOfIntervals,
  timeUnit,
  intervalName = 'interval'
) => {
  const classes = generateDummyIntervalData(fromDate, toDate, numberOfIntervals, timeUnit);

  return classes.map((classTitle) => ({
    [intervalName]: classTitle,
    ...generateRecord(),
  }));
};

const getCompanySubsidiaryPrimaryChartDetailData = (type) => {
  const firstValue = Math.floor(Math.random() * 20);
  const secondValue = Math.floor(Math.random() * 30) + 10;

  const generateRandomInts = (num, startingNum = 0) =>
    Array(num)
      .fill()
      .map((_, index) => startingNum + index);

  const firstValueRandomIds = generateRandomInts(firstValue, 0);
  const secondValueRandomIds = generateRandomInts(secondValue, 0);

  return type === companySubsidiaryStatisticTypes.NUM_OF_USERS
    ? {
        [chartDetailDataKeys.ACTIVE_USERS_COUNT]: firstValue,
        [chartDetailDataKeys.ACTIVE_USERS_IDS]: firstValueRandomIds,
        [chartDetailDataKeys.INACTIVE_USERS_COUNT]: secondValue,
        [chartDetailDataKeys.INACTIVE_USERS_IDS]: secondValueRandomIds,
        [chartDetailDataKeys.TOTAL_USERS_COUNT]: firstValue + secondValue,
        [chartDetailDataKeys.TOTAL_USERS_IDS]: [...firstValueRandomIds, ...secondValueRandomIds],
      }
    : type === companySubsidiaryStatisticTypes.NUM_OF_GROUPS
    ? {
        [chartDetailDataKeys.ACTIVE_GROUPS_COUNT]: firstValue,
        [chartDetailDataKeys.ACTIVE_GROUPS_IDS]: firstValueRandomIds,
        [chartDetailDataKeys.INACTIVE_GROUPS_COUNT]: secondValue,
        [chartDetailDataKeys.INACTIVE_GROUPS_IDS]: secondValueRandomIds,
        [chartDetailDataKeys.TOTAL_GROUPS_COUNT]: firstValue + secondValue,
        [chartDetailDataKeys.TOTAL_GROUPS_IDS]: [...firstValueRandomIds, ...secondValueRandomIds],
      }
    : type === companySubsidiaryStatisticTypes.NUM_OF_LESSONS
    ? {
        [chartDetailDataKeys.ACTIVE_LESSONS_COUNT]: firstValue,
        [chartDetailDataKeys.ACTIVE_LESSONS_IDS]: firstValueRandomIds,
        [chartDetailDataKeys.INACTIVE_LESSONS_COUNT]: secondValue,
        [chartDetailDataKeys.INACTIVE_LESSONS_IDS]: secondValueRandomIds,
        [chartDetailDataKeys.TOTAL_LESSONS_COUNT]: firstValue + secondValue,
        [chartDetailDataKeys.TOTAL_LESSONS_IDS]: [...firstValueRandomIds, ...secondValueRandomIds],
      }
    : type === companySubsidiaryStatisticTypes.NUM_OF_COURSES
    ? {
        [chartDetailDataKeys.ACTIVE_COURSES_COUNT]: firstValue,
        [chartDetailDataKeys.ACTIVE_COURSES_IDS]: firstValueRandomIds,
        [chartDetailDataKeys.INACTIVE_COURSES_COUNT]: secondValue,
        [chartDetailDataKeys.INACTIVE_COURSES_IDS]: secondValueRandomIds,
        [chartDetailDataKeys.TOTAL_COURSES_COUNT]: firstValue + secondValue,
        [chartDetailDataKeys.TOTAL_COURSES_IDS]: [...firstValueRandomIds, ...secondValueRandomIds],
      }
    : {};
};

const getCompanySubsidiarySecondaryChartDetailData = () => {
  const firstValue = Math.floor(Math.random() * 2000);
  const secondValue = Math.floor(Math.random() * 3000) + 500;
  return {
    [chartDetailDataKeys.TOTAL_TEST_COUNT]: firstValue + secondValue,
    [chartDetailDataKeys.TEST_SUCCESS_COUNT]: firstValue,
    [chartDetailDataKeys.TEST_FAILURE_COUNT]: secondValue,
  };
};

export const getDummyCompanySubsidiaryPrimaryChartData = (params = {}, filterType) => {
  const { from, to } = getBasicParams(params.from, params.to);
  const generatePrimaryRecord = (filterType) => () =>
    getCompanySubsidiaryStatisticsRecord(filterType);

  return {
    data: generateTimeBasedDummyChartData(generatePrimaryRecord(filterType), from, to),
    ...getCompanySubsidiaryPrimaryChartDetailData(filterType),
  };
};

export const getDummyCompanySubsidiarySecondaryChartData = (params = {}) => {
  const { from, to } = getBasicParams(params.from, params.to);
  return {
    data: generateTimeBasedDummyChartData(getTestResultsRecord, from, to),
    ...getCompanySubsidiarySecondaryChartDetailData(),
  };
};

export const getDummyContentStatisticsData = (id, params = {}) => {
  const { from, to } = getBasicParams(params.startDate, params.endDate);
  return {
    primaryChartData: generateTimeBasedDummyChartData(getContentStatisticsRecord, from, to),
    secondaryChartData: generateIntervalBasedDummyChartData(
      getTimeSpentRecord,
      from,
      to,
      10,
      HOURS,
      SPENT_TIME_PERIOD
    ),
    chartDetail: {
      totalDetail: { value: 2600 },
      successDetail: { value: 2000, percent: 85, questions: dummySuccessQuestions },
      failedDetail: { value: 600, percent: 15, questions: dummyFailedQuestions },
    },
  };
};

export const getDummyMembersStatisticsData = (id, params = {}) => {
  const { from, to } = getBasicParams(params.startDate, params.endDate);
  return {
    primaryChartData: generateTimeBasedDummyChartData(getOverallActivityStatisticsRecord, from, to),
    secondaryChartData: generateTimeBasedDummyChartData(getTestResultsRecord, from, to),
  };
};

const sortOptions = {
  [Settings.SORT_BY.CATEGORY]: (a, b) => baseBoolComperator(a.isImportant, b.isImportant),
  [Settings.SORT_BY.COURSE]: (a, b) => baseStringComperator(a.course, b.course),
  [Settings.SORT_BY.LESSON]: (a, b) => baseStringComperator(a.lesson, b.lesson),
  [Settings.SORT_BY.SPENT_TIME]: (a, b) => baseNumberComperator(a.spentTime, b.spentTime),
  [Settings.SORT_BY.STATUS]: (a, b) => baseNumberComperator(a.status, b.status),
};

export const getDummyCoursesAndLessonsByMember = (params = {}) => {
  const { sortBy, order, page } = params;

  return {
    count: dummyMemberCourseList.length,
    data: dummyMemberCourseList
      .slice(
        MEMBER_COURSES_PER_PAGE * (page - 1),
        MEMBER_COURSES_PER_PAGE * (page - 1) + MEMBER_COURSES_PER_PAGE
      )
      .sort((a, b) =>
        sortOptions[sortBy]
          ? sortOptions[sortBy](a, b)
          : 0 * (order === Settings.ORDER.DESC ? -1 : 1)
      ),
  };
};

export const getDummyMemberContentUsage = () => {
  const { from, to } = getBasicParams('2000-01-01', '2001-01-01');
  return generateTimeBasedDummyChartData(getContentUsageRecord, from, to);
};
