import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { TableContainer } from 'components';
import { pathEditorSourceTableColumns } from 'util/pathEditorSourceTableColumns';

const RowOverlay = ({ overlayItem, intl: { formatMessage } }) => {
  return (
    <div style={{ border: '1px solid black', backgroundColor: 'white' }}>
      <TableContainer
        hideHeader
        list={[overlayItem]}
        columns={pathEditorSourceTableColumns(formatMessage)}
      />
    </div>
  );
};

RowOverlay.propTypes = {
  overlayItem: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(RowOverlay);
