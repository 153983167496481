import React from 'react';
import PropTypes from 'prop-types';

import GuideText from '../GuideText/GuideText';
import InputInlineError from '../InputInlineError/InputInlineError';
import { inputErrorPropTypes } from 'common';

const TextAreaAdditions = ({
  hasErrorComponent,
  hasErrorMessage,
  hideValue,
  errorMessage,
  guideText,
}) => {
  return (
    <>
      <InputInlineError
        hasErrorComponent={hasErrorComponent}
        hasErrorMessage={hasErrorMessage}
        errorMessage={errorMessage}
        hideValue={hideValue}
      />
      {guideText ? <GuideText>{guideText}</GuideText> : null}
    </>
  );
};

TextAreaAdditions.propTypes = {
  guideText: PropTypes.node,
  ...inputErrorPropTypes,
};

export default TextAreaAdditions;
