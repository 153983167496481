import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import { TableContainer } from 'components';
import Settings from 'config/settings';
import { widthOptions, horizontalAlignOptions } from 'components/Table/tableConstants';

import { getLessonTagsAsString, getAuthorAsString } from 'util/lessonTableUtil';

const LessonsForAuditTable = ({
  list = [],
  loading,
  openEditModal,
  loadList,
  params,
  intl: { formatMessage },
}) => {
  return (
    <TableContainer
      loading={loading}
      list={list}
      loadList={loadList}
      params={params}
      columns={[
        {
          key: 'title',
          minWidth: widthOptions.md,
          maxWidth: widthOptions.lg,
          label: formatMessage({ id: 'COMMON.TITLE' }),
          sortBy: Settings.SORT_BY.TITLE,
        },
        {
          key: 'tags',
          label: formatMessage({ id: 'LESSONS.LESSON_TAGS' }),
          multiLine: true,
          width: widthOptions.lg,
          horizontalAlign: horizontalAlignOptions.left,
          itemTooltip: (lesson) => getLessonTagsAsString(lesson),
          render: (lesson) => getLessonTagsAsString(lesson),
        },
        {
          key: 'subsidiaryName',
          label: formatMessage({ id: 'LESSONS.SUBSIDIARY' }),
          sortBy: Settings.SORT_BY.COMPANY,
          width: widthOptions.md,
        },
        {
          key: 'author',
          label: formatMessage({ id: 'LESSONS.AUTHOR' }),
          horizontalAlign: horizontalAlignOptions.left,
          sortBy: Settings.SORT_BY.USERS_FIRST_NAME,
          width: widthOptions.md,
          itemTooltip: (lesson) => getAuthorAsString(lesson),
          render: (lesson) => getAuthorAsString(lesson),
        },
        {
          key: 'edit',
          iconCell: true,
          width: widthOptions.sm,
          iconClass: 'lemon icon-info dark-grey',
          onClick: (lesson) => openEditModal(lesson),
        },
      ]}
    />
  );
};

LessonsForAuditTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  openEditModal: PropTypes.func.isRequired,
  loadList: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withPagination(injectIntl(LessonsForAuditTable));
