import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { useHistory, useRouteMatch } from 'react-router-dom';

import LessonsBaseDataForm from './LessonsBaseDataForm';
import TestsTable from './tests/TestsTableContainer';
import RelatedCourses from './RelatedCourses/RelatedCourses';
import {
  tabQueryParams,
  BasicPage,
  AssignGroupsAndUsers,
  OnServerError,
  OnSubmitValidationError,
  Modal,
  SubmitFormButtons,
  OkModal,
  TabsContainer,
} from 'components';
import { LessonAccess } from 'libs/accessManagement';
import { assignUsers } from 'services/lessons';
import { hideLoader, showLoader } from 'actions/common';
import { getLesson, editLesson, createLesson } from 'services/lessons';
import { getTemplates } from 'services/templates';
import { getTags } from 'services/tags';

import {
  lessonEditorValidationSchema,
  onSortEnd,
  getInitialValuesFromLesson,
  showSubmitButton,
  canEditWholeLesson,
  preTransformationForSavingLesson,
} from './lessonEditorUtil';

import { getImages } from 'util/imagesMapper';
import { usersMapper } from 'util/transferMapper';
import { mapToAssignedUsers } from 'util/assignUserAndGroupHelper';

const TABS = [
  {
    urlParam: 'base-data',
    label: <FormattedMessage id="LESSONS.BASE_LESSON_DATA_TAB" />,
  },
  {
    urlParam: 'assigns',
    label: <FormattedMessage id="LESSONS.ASSIGNS" />,
  },
  {
    urlParam: 'test',
    label: <FormattedMessage id="LESSONS.TEST_TAB" />,
  },
  {
    urlParam: 'related-courses',
    label: <FormattedMessage id="LESSONS.RELATED_COURSES" />,
  },
];

const beforeInitialSaveTabContent = (message) => (
  <div className="lesson-editor__no-data">{message}</div>
);

const LessonsEditor = ({
  disabled,
  tabIndex,
  setTab,
  profile,
  edit,
  showLoader,
  hideLoader,
  isLoading,
  previousPage,
}) => {
  const fomrikRef = React.createRef();
  const history = useHistory();
  const match = useRouteMatch();

  const [lesson, setLesson] = useState();
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        if (edit) {
          const responseLesson = await getLesson(match.params.id);
          setLesson({
            ...responseLesson,
            images: getImages(responseLesson),
            users: mapToAssignedUsers(usersMapper(responseLesson), responseLesson.groups || []),
          });
        } else {
          const responseTemplateList = await getTemplates();
          setTemplateList(responseTemplateList.data);
        }
        const responseTags = await getTags();
        setTags(responseTags);
      } finally {
        hideLoader();
      }
    };
    fetchData();
  }, [hideLoader, match.params.id, edit, showLoader]);

  const closeEditor = () => {
    history.push(previousPage);
  };

  const lessonEdit = async (editedLesson) => {
    if (canEditWholeLesson(profile, lesson)) {
      await editLesson(editedLesson, editedLesson.id || match.params.id);
    } else {
      await assignUsers(editedLesson.id, {
        users: editedLesson.users,
        groups: editedLesson.groups || [],
      });
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const submitLesson = await preTransformationForSavingLesson(values);
    setSubmitting(true);
    try {
      if (!edit && !submitLesson.id) {
        const responseLesson = await createLesson(submitLesson);
        history.replace(`/home/lessons/${responseLesson.id}/edit?tab=base-data`);
        showSuccessModal();
        setLesson(responseLesson);
      } else {
        await lessonEdit(submitLesson);
        closeEditor();
      }
    } finally {
      setSubmitting(false);
    }
  };

  const closeOkModal = () => {
    setSuccessModalOpen(false);
  };

  const showSuccessModal = () => {
    setSuccessModalOpen(true);
  };

  const onClose = () => {
    fomrikRef.current.dirty ? setUnsavedChangesModalOpen(true) : closeEditor();
  };

  const closeUnsavedChangesModal = () => {
    setUnsavedChangesModalOpen(false);
  };

  const getTabs = ({ values, errors, setFieldValue, isValid }) => {
    const {
      hasRoleToAssignUserOrGroup,
      TestAccess: { hasRoleToRead: hasRoleToReadTest },
    } = LessonAccess.getHasRoles(profile.profile);

    const disabledLessonsBaseDataForm = disabled || (edit && !canEditWholeLesson(profile, lesson));

    return [
      {
        urlParam: TABS[0].urlParam,
        label: TABS[0].label,
        forceRender: true,
        component: (
          <LessonsBaseDataForm
            isLoading={isLoading}
            lesson={lesson}
            profile={profile}
            edit={edit}
            canLessonBePrivate={lesson?.canBePrivate}
            isLessonPublic={lesson?.public}
            onSubmit={onSubmit}
            disabled={disabledLessonsBaseDataForm}
            errors={errors}
            templateList={templateList}
            onSortEnd={(specifics) => onSortEnd(specifics, setFieldValue)}
            values={values}
            setFieldValue={setFieldValue}
            filteredTags={tags}
          />
        ),
        hasError: !isValid,
      },
      {
        hide: !hasRoleToAssignUserOrGroup,
        urlParam: TABS[1].urlParam,
        label: TABS[1].label,
        forceRender: true,
        component: edit ? (
          <AssignGroupsAndUsers
            values={values}
            fetchData={tabIndex === 1}
            disabled={disabled || !hasRoleToAssignUserOrGroup}
            setFieldValue={setFieldValue}
          />
        ) : (
          beforeInitialSaveTabContent(<FormattedMessage id="USERS.NOT_AVAILABLE" />)
        ),
      },
      {
        hideSubmit: true,
        hide: !hasRoleToReadTest,
        urlParam: TABS[2].urlParam,
        label: TABS[2].label,
        component: edit ? (
          <TestsTable lesson={lesson} disabled={false} />
        ) : (
          beforeInitialSaveTabContent(<FormattedMessage id="TESTS.NOT_AVAILABLE" />)
        ),
      },
      {
        urlParam: TABS[3].urlParam,
        label: TABS[3].label,
        component: edit ? (
          <RelatedCourses
            lesson={lesson}
            showLoader={showLoader}
            hideLoader={hideLoader}
            profile={profile}
          />
        ) : (
          beforeInitialSaveTabContent(<FormattedMessage id="RELATED_COURSES.NOT_AVAILABLE" />)
        ),
      },
    ].map((tab) => ({
      id: tab.urlParam,
      ...tab,
    }));
  };

  return (
    <BasicPage
      className="lesson-editor"
      title={<FormattedMessage id="LESSONS.TITLE" />}
      hidePreHeader>
      <Formik
        initialValues={getInitialValuesFromLesson(lesson, edit)}
        enableReinitialize
        validateOnBlur={false}
        validationSchema={lessonEditorValidationSchema}
        onSubmit={onSubmit}
        innerRef={fomrikRef}>
        {(formikProps) => {
          const tabList = getTabs(formikProps);

          return (
            <Form className="forgot-password-form">
              <OnServerError />
              <OnSubmitValidationError />
              <TabsContainer
                tabList={tabList}
                selectedIndex={tabIndex}
                onSelect={(tabIndex) => setTab(tabIndex)}
              />

              {!tabList[tabIndex]?.hideSubmit && (
                <SubmitFormButtons
                  disabled={formikProps.isSubmitting}
                  loading={formikProps.isSubmitting}
                  showSubmitButton={showSubmitButton(!!lesson?.id, lesson, profile)}
                  onClose={onClose}
                />
              )}

              <OkModal
                open={successModalOpen}
                onClose={closeOkModal}
                saveAction={closeOkModal}
                confirmElement={
                  <FormattedMessage
                    id={edit ? 'COMMON.SUCCESSFUL_SAVE_WRITE_TESTS' : 'COMMON.SUCCESSFUL_SAVE'}
                  />
                }
              />

              <Modal
                className="shadowed-modal"
                title={<FormattedMessage id="COMMON.UNSAVED_CHANGES" />}
                open={unsavedChangesModalOpen}
                large={false}
                onClose={closeUnsavedChangesModal}
                saveButton={true}
                saveTitle={<FormattedMessage id="COMMON.YES" />}
                closeTitle={<FormattedMessage id="COMMON.CANCEL" />}
                saveAction={closeEditor}>
                <FormattedMessage id="COMMON.ARE_YOU_SURE" />
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </BasicPage>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
    isLoading: state.common.loader.show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

LessonsEditor.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  tabIndex: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  profile: PropTypes.object,
  edit: PropTypes.bool,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  previousPage: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(tabQueryParams({ tabs: TABS })(LessonsEditor));
