import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const RoleParagraph = ({ role }) => {
  return (
    <div>
      {role.roleName}
      {role.items ? (
        <>
          {' for:'}
          <ul>
            {role.items.map((item) => (
              <li key={uuidv4()}>{item.name}</li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  );
};

RoleParagraph.propTypes = {
  role: PropTypes.object.isRequired,
};

export default RoleParagraph;
