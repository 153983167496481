import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import IconButton from '../IconButton/IconButton';

import './style.scss';

const CheckBoxGroupControls = ({
  label,
  disabled,
  hasSelectedOptions,
  clearFilters,
  selectFilters,
  intl: { formatMessage },
}) => {
  return (
    <div className="check-box-group-header">
      {label}
      {!disabled && hasSelectedOptions ? (
        <div className="check-box-group-header__clear-container" onClick={clearFilters}>
          {formatMessage({ id: 'COMMON.CLEAR_FILTER' })}
          <IconButton iconClass="lemon icon-trash" name="clear" tooltip="Clear filters" />
        </div>
      ) : !disabled ? (
        <div className="check-box-group-header__clear-container" onClick={selectFilters}>
          {formatMessage({ id: 'COMMON.SELECT_FILTER' })}
          <IconButton iconClass="lemon icon-check" name="clear" tooltip="Select filters" />
        </div>
      ) : null}
    </div>
  );
};

CheckBoxGroupControls.propTypes = {
  label: PropTypes.node.isRequired,
  clearFilters: PropTypes.func.isRequired,
  selectFilters: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasSelectedOptions: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CheckBoxGroupControls);
