import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import max from 'lodash/max';
import omit from 'lodash/omit';

import { hasInputTextRendered, showErrorInputStyle } from '../inputUtil';
import ControlLabelWrapper from '../ControlLabelWrapper/ControlLabelWrapper';
import TextAreaAdditions from './TextAreaAdditions';

import './style.scss';

const getScrollMinHeight = ({ scrollHeight, clientHeight }) => max([scrollHeight, clientHeight]);

const setElementMinHeight = (element, minHeightValue) =>
  element.style.setProperty('min-height', minHeightValue, 'important');

const defaultRenderTextAreaInputAdditions = (props) => <TextAreaAdditions {...props} />;

const TextAreaInput = (props) => {
  const {
    containerClass,
    childrenWrapperClass,
    className,
    value,
    placeholder,
    hasErrorMessage,
    hideValue,
    label,
    hasErrorComponent = false,
    onChange,
    rows = 5,
    renderTextAreaInputAdditions = defaultRenderTextAreaInputAdditions,
    disabled,
    ...remProps
  } = props;

  const [id] = useState(() => uniqueId('textarea-'));
  const textAreaRef = useRef();

  useEffect(() => {
    const element = textAreaRef.current;
    if (!element) {
      return;
    }

    setElementMinHeight(element, 'unset');
    setElementMinHeight(element, `${getScrollMinHeight(element)}px`);
  }, [value]);

  const inputProps = omit(remProps, ['errorMessage']);

  return (
    <ControlLabelWrapper
      id={id}
      className={classNames('text-area-input', containerClass)}
      childrenWrapperClass={childrenWrapperClass}
      hasInputValue={hasInputTextRendered({ value, placeholder })}
      hasError={showErrorInputStyle({ hasErrorMessage, hideValue })}
      label={label}
      disabled={disabled}
      additions={renderTextAreaInputAdditions({
        ...props,
        hasErrorComponent,
        id,
      })}>
      <textarea
        {...inputProps}
        disabled={disabled}
        ref={textAreaRef}
        rows={rows}
        className={classNames('text-area-input__text-area', className)}
        placeholder={placeholder}
        onChange={onChange}
        id={id}
        value={!hideValue ? value : null}
      />
    </ControlLabelWrapper>
  );
};

TextAreaInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  className: PropTypes.string,
  containerClass: PropTypes.string,
  childrenWrapperClass: PropTypes.string,
  infoMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  infoClass: PropTypes.string,
  hideValue: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  hasErrorComponent: PropTypes.bool,
  hasErrorMessage: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  rows: PropTypes.number,
  renderTextAreaInputAdditions: PropTypes.func,
};

export default TextAreaInput;
