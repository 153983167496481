import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import IndicatorListItem from './../IndicatorListItem';
import { getUserIdentifier } from '../../UsersPage/User';
import { Button, CheckBox } from 'components';

import './style.scss';

export default class User extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.number,
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      unsuccessfulExamsCount: PropTypes.number,
      successfulExamsCount: PropTypes.number,
      successfulExamAverage: PropTypes.number,
      activeMins: PropTypes.number,
      exams: PropTypes.arrayOf(PropTypes.object),
    }),
    onUserClick: PropTypes.func,
    filterable: PropTypes.bool,
    poke: PropTypes.func,
    inactive: PropTypes.bool,
    filtered: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
  };

  render() {
    const {
      user,
      onUserClick,
      filterable,
      inactive,
      filtered,
      poke,
      onChange,
      checked,
    } = this.props;
    return (
      <div className={`user-container ${inactive ? 'inactive' : ''}`}>
        <div className={`d-flex align-items-center ${filterable ? 'filterable ' : ''} user`}>
          {onChange ? (
            <div className="d-flex">
              <CheckBox checked={checked || false} onChange={onChange} />
            </div>
          ) : null}
          {user.avatar ? (
            <div
              className={classNames('avatar ms-3', { clickable: !!onUserClick })}
              style={{ backgroundImage: `url(${user.avatar})` }}
              {...(onUserClick && { onClick: () => onUserClick(user) })}
            />
          ) : (
            <div
              className={classNames('avatar default ms-3', { clickable: !!onUserClick })}
              onClick={() => onUserClick(user)}
            />
          )}
          <div className="text-data">
            <div
              className={classNames('name', { clickable: !!onUserClick })}
              {...(onUserClick && { onClick: () => onUserClick(user) })}>
              {getUserIdentifier(user)}
            </div>
            {onUserClick ? (
              <div
                className={classNames('d-flex align-items-center', {
                  'me-5 ms-auto': !filtered,
                  'ms-5 me-auto': filtered,
                })}>
                <IndicatorListItem
                  label="DASHBOARD.ACTIVITY_IN_MINS"
                  value={
                    user.activities ? Math.floor(user.activities / 1000 / 60) : user.activities
                  }
                  color="purple"
                  className="me-5"
                />
                <IndicatorListItem
                  label="DASHBOARD.UNSUCCESSFUL_EXAMS"
                  value={user.failed}
                  color="grey"
                  className="me-5"
                />
                <IndicatorListItem
                  label="DASHBOARD.SUCCESSFUL_EXAMS"
                  value={user.success}
                  color="green"
                  className="me-5"
                />
                {filtered ? (
                  <IndicatorListItem
                    label="DASHBOARD.SUCCESSFUL_EXAMS_AVERAGE"
                    value={user.latestExam ? `${user.latestExam.result}%` : '-'}
                    color="green"
                  />
                ) : null}
              </div>
            ) : null}

            {filtered ? (
              <Button
                type="button"
                className="btn-blue"
                size="small"
                lessBorder
                onClick={poke}
                disabled={user.latestExam && !!user.latestExam.success}>
                {<FormattedMessage id="DASHBOARD.POKE" />}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
