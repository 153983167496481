import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';

import { LogoAnimation } from 'components';
import { hideLoader, showLoader } from 'actions/common';

import './style.scss';

const RegistrationPage = ({
  content,
  formClassName,
  label,
  adminPageText,
  userPageText,
  initialValues,
  validationSchema,
  onSubmit,
  showLoader,
  hideLoader,
  title,
}) => {
  const [isSent, setIsSent] = useState(false);
  const [adminPageAccess, setAdminPageAccess] = useState(false);

  const submit = async (values) => {
    try {
      showLoader();
      const { success, adminPageAccess: enableAdminPageAccess } = await onSubmit(values);
      if (success) {
        setIsSent(true);
        setAdminPageAccess(enableAdminPageAccess);
      }
    } finally {
      hideLoader();
    }
  };

  return (
    <div>
      <div className="registration-page__logo-container">
        <LogoAnimation size="sm" />
      </div>
      <div className={classNames(formClassName, 'registration-page__registration-form')}>
        {!isSent ? (
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={submit}>
            <>
              <div className="registration-page__label">{title}</div>
              {content}
            </>
          </Formik>
        ) : (
          <>
            <div className="registration-page__label">{label}</div>
            <div className="registration-page__label">
              {adminPageAccess ? adminPageText : userPageText}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

RegistrationPage.propTypes = {
  content: PropTypes.node,
  label: PropTypes.node,
  adminPageText: PropTypes.node,
  userPageText: PropTypes.node,
  formClassName: PropTypes.string,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  title: PropTypes.node,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showLoader, hideLoader }, dispatch);
};

export default connect(null, mapDispatchToProps)(RegistrationPage);
