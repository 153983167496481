import React from 'react';
import PropTypes from 'prop-types';

import { CheckBoxGroup, InlineError } from 'components';
import { getErrorMessage, isTouched } from 'util/form';

const CheckBoxGroupField = ({ form, field, options, ...props }) => {
  const clearFilters = () => {
    form.setFieldValue(field.name, []);
    form.setFieldTouched(field.name, true);
  };

  const selectFilters = () => {
    form.setFieldValue(
      field.name,
      options.map(({ id }) => id)
    );
    form.setFieldTouched(field.name, true);
  };

  const onChange = (e, itemId) => {
    const data = e.target.checked
      ? [...field.value, itemId]
      : field.value.filter((selectedItemId) => selectedItemId !== itemId);

    form.setFieldValue(field.name, data);
    form.setFieldTouched(field.name, true);
  };

  const errorMessage = getErrorMessage(form, field) || '';
  const showError = isTouched(form, field) && errorMessage;

  return (
    <>
      <CheckBoxGroup
        {...props}
        options={options}
        selectedOptions={field?.value || []}
        onChange={onChange}
        selectFilters={selectFilters}
        clearFilters={clearFilters}
      />
      <InlineError show={showError} message={errorMessage} />
    </>
  );
};

CheckBoxGroupField.propTypes = {
  label: PropTypes.node.isRequired,
  options: PropTypes.array.isRequired,
  clearFilters: PropTypes.func,
  selectFilters: PropTypes.func,
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.node,
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
};

export default CheckBoxGroupField;
