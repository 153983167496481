import * as Yup from 'yup';

Yup.setLocale({
  mixed: { required: 'ERROR.ANY.EMPTY' },
  string: {
    email: 'COMMON.FIELD_EMAIL_ERROR',
  },
});

export { Yup };
