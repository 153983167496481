import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { injectIntl } from 'react-intl';

import './style.scss';

const SearchInput = ({
  value,
  className,
  placeholder,
  onChange,
  hasFocusLine,
  disabled,
  intl: { formatMessage },
  ...props
}) => {
  const [id] = useState(() => uniqueId('search-input-'));

  return (
    <div className={classNames('search-input', { 'search-input--disabled': disabled }, className)}>
      <i className="search-input__search-icon fas fa-search" />
      <input
        value={value}
        className="search-input__input-field"
        type="text"
        id={id}
        onChange={onChange}
        placeholder={placeholder || formatMessage({ id: 'COMMON.SEARCH' })}
        disabled={disabled}
        {...props}
      />
      {hasFocusLine && <span className="search-input__focus-line" />}
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  hasFocusLine: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default injectIntl(SearchInput);
