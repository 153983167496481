import { v4 as uuidv4 } from 'uuid';

export const mapSpecificsToSpecificsWithTempId = (specifics = []) => {
  return specifics
    ? specifics.map((specific) => ({
        ...specific,
        tempId: uuidv4(),
      }))
    : [];
};
