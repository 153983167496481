import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import { Popover } from 'components';

const IconChartDetailItem = ({ firstIcon, label, value, lastIcon, noDivider }) => {
  return (
    <>
      <div className="icon-chart-detail-item-container">
        <div className="label-container">
          <div className="first-icon-container">{firstIcon}</div>
          <Popover content={label || ''} oneLine placement="top" trigger="hover">
            {<div className="label">{label}</div>}
          </Popover>
        </div>
        <div className="value-container">
          <Popover content={value || ''} oneLine placement="top" trigger="hover">
            {<div className="value">{value}</div>}
          </Popover>
          <div className="last-icon-container">{lastIcon ? lastIcon : null}</div>
        </div>
      </div>

      {!noDivider && <div className="icon-chart-detail-item-divider" />}
    </>
  );
};

IconChartDetailItem.propTypes = {
  noDivider: PropTypes.bool,
  firstIcon: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lastIcon: PropTypes.node,
};

export default IconChartDetailItem;
