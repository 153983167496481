import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Tag } from 'components';
import {
  lessonImportantColor,
  lessonImportantBackgroundColor,
  lessonNotImportantColor,
  lessonNotImportantBackgroundColor,
  lessonNotImportantBorderColor,
} from '../../../styles/_colors.scss';

const ImportanceTag = ({ isImportant, intl: { formatMessage } }) => {
  return (
    <Tag
      color={isImportant ? lessonImportantColor : lessonNotImportantColor}
      backgroundColor={
        isImportant ? lessonImportantBackgroundColor : lessonNotImportantBackgroundColor
      }
      borderColor={isImportant ? null : lessonNotImportantBorderColor}
      text={formatMessage({ id: isImportant ? 'LESSONS.IMPORTANT' : 'LESSONS.NOT_IMPORTANT' })}
    />
  );
};

ImportanceTag.propTypes = {
  isImportant: PropTypes.bool,
};

export default injectIntl(ImportanceTag);
