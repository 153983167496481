import React, { Component } from 'react';
import { select, easeLinear } from 'd3';
import { scaleLinear } from 'd3-scale';
import ReactResizeDetector from 'react-resize-detector';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import Settings from '../../config/settings';

import './style.scss';

export default class CoursesSummaryChart extends Component {
  static propTypes = {
    changeCourse: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        course: PropTypes.string,
        success: PropTypes.number,
        failed: PropTypes.number,
      })
    ),
    filter: PropTypes.number,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.node = React.createRef();
  }

  state = {
    selectedBars: null,
  };

  height = 0;
  width = 0;
  maxValue = 1;

  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data.length !== this.props.data.length ||
      !!differenceWith(prevProps.data, this.props.data, isEqual).length
    ) {
      this.initChart();
    }
  }

  initChart() {
    this.maxValue = Math.max(
      ...this.props.data.map((d) => d.success),
      ...this.props.data.map((d) => d.failed),
      0
    );
    this.draw();
  }

  resize = () => {
    this.draw(true);
  };

  getColor = (index, key) => {
    index++;
    if (index % 5 === 1) {
      return `yellow-${key}`;
    } else if (index % 5 === 2) {
      return `purple-${key}`;
    } else if (index % 5 === 3) {
      return `pink-${key}`;
    } else if (index % 5 === 4) {
      return `green-${key}`;
    } else if (index % 5 === 0) {
      return `orange-${key}`;
    }
  };

  onClickBar = (selectedBars) => {
    if (!this.props.disabled) {
      this.setState({ selectedBars }, () => {
        this.draw(true);
      });
      this.props.changeCourse(selectedBars);
    }
  };

  draw = (resize) => {
    if (!this.node.current) {
      return;
    }
    const parentWidth = this.node.current.clientWidth;
    if (document.getElementsByClassName('course-bar-chart').length) {
      select('.course-bar-chart').remove();
    }

    this.selection = select(this.node.current);

    const margin = {
      top: 100,
      right: 0,
      bottom: 60,
      left: 0,
    };

    this.width = parentWidth - margin.left - margin.right;
    const barWidth = 35;
    const barPadding = 35;
    this.height = 380 - margin.top - margin.bottom;

    const svg = this.selection.append('svg').attr('class', 'course-bar-chart');

    const isOverflow = () => this.props.data.length * (barWidth * 2 + barPadding) > this.width;

    const svgWidth = !isOverflow()
      ? this.width + margin.left + margin.right
      : barWidth * this.props.data.length +
        this.props.data.length * 2 * barPadding +
        barPadding +
        margin.left +
        margin.right;

    const y = scaleLinear().rangeRound([this.height, 0]);

    svg
      .attr('width', svgWidth)
      .attr('height', this.height + margin.top + margin.bottom)
      .on('click', () => {
        this.onClickBar(null);
      })
      .append('g');

    // axis-y data
    y.domain([0, this.maxValue]);

    // the main group
    const g = svg
      .append('g')
      .attr('class', 'chart-g')
      .attr('transform', () => {
        //default is when there is only 1 element
        const doubleBarWidth = barWidth * 2 + 1;
        let lengthCompensation = barWidth * 1.5;
        if (this.props.data.length > 1 && this.props.data.length % 2 === 0) {
          // even elements
          lengthCompensation = (this.props.data.length / 2) * (doubleBarWidth + barPadding);
        } else if (this.props.data.length > 1 && this.props.data.length % 2 !== 0) {
          // odd elements
          lengthCompensation =
            (doubleBarWidth + barPadding) * Math.floor(this.props.data.length / 2) + barWidth * 1.5;
        }

        return `translate(${
          isOverflow() ? margin.left : (svgWidth - barPadding) / 2 - lengthCompensation
        },${margin.top})`;
      });

    //x axis
    svg
      .selectAll('.x-axis')
      .data([0])
      .enter()
      .append('rect')
      .style('stroke', 'none')
      .attr('class', 'x-axis')
      .attr('width', svgWidth)
      .attr('height', 1)
      .attr('x', 0)
      .attr('y', this.height + margin.top);

    // bars
    this.drawBars({ g, barWidth, barPadding, y }, resize);

    // bar value
    this.drawBarValue({ g, barWidth, barPadding, y }, resize);

    // bar label
    g.selectAll('.bar-label')
      .data(this.props.data)
      .enter()
      .append('text')
      .attr('class', (d) => `bar-label ${this.getBarState(d.id)}`)
      .attr('width', barWidth)
      .attr('y', this.height - 1)
      .text((d) => d.title)
      .attr('transform', (d, i) => {
        return 'translate(' + [barWidth * i + i * 2 * barPadding + barPadding, 23] + ')';
      });

    // bar legends
    this.drawBarLegends({ g, barWidth, barPadding, margin });
  };

  getBarState = (id) =>
    (this.state.selectedBars && this.state.selectedBars.id === id) ||
    this.state.selectedBars === null
      ? 'active'
      : 'inactive';

  drawBars = ({ g, barWidth, barPadding, y }, resize) => {
    const successBars = g
      .selectAll('.success-bar')
      .data(this.props.data)
      .enter()
      .append('rect')
      .on('click', (event, d) => {
        event.stopPropagation();
        this.onClickBar(d);
      })
      .style('stroke', 'none')
      .attr(
        'class',
        (d, i) => `success-bar ${this.getColor(i, 'success')} ${this.getBarState(d.id)}`
      )
      .attr('width', barWidth)
      .attr('y', (d) => (resize ? y(d.success) : this.height - 1))
      .attr('height', (d) => (resize ? this.height - y(d.success) : 0))
      .attr('transform', (d, i) => {
        return 'translate(' + [barWidth * i + i * 2 * barPadding + barPadding, 0] + ')';
      });

    if (!resize) {
      successBars
        .transition()
        .duration(Settings.ANIMATION_LENGTH)
        .ease(easeLinear)
        .attr('y', (d) => y(d.success))
        .attr('height', (d) => this.height - y(d.success));
    }

    const failedBars = g
      .selectAll('.failed-bar')
      .data(this.props.data)
      .enter()
      .append('rect')
      .on('click', (event, d) => {
        event.stopPropagation();
        this.onClickBar(d);
      })
      .style('stroke', 'none')
      .attr('class', (d, i) => `failed-bar ${this.getColor(i, 'failed')} ${this.getBarState(d.id)}`)
      .attr('width', barWidth)
      .attr('y', (d) => (resize ? y(d.failed) : this.height - 1))
      .attr('height', (d) => (resize ? this.height - y(d.failed) : 0))
      .attr('transform', (d, i) => {
        return (
          'translate(' + [barWidth * i + i * 2 * barPadding + barPadding + barWidth + 1, 0] + ')'
        );
      });

    if (!resize) {
      failedBars
        .transition()
        .duration(Settings.ANIMATION_LENGTH)
        .ease(easeLinear)
        .attr('y', (d) => y(d.failed))
        .attr('height', (d) => this.height - y(d.failed));
    }
  };

  drawBarValue = ({ g, barWidth, barPadding, y }, resize) => {
    const barValueMargin = 7;

    const successBarValues = g
      .selectAll('.bar-value-success')
      .data(this.props.data)
      .enter()
      .append('text')
      .attr('class', (d) => `bar-value-success ${this.getBarState(d.id)}`)
      .attr('width', barWidth)
      .text((d) => d.success)
      .attr('y', (d) => (resize ? y(d.success) - barValueMargin : this.height - 1))
      .attr('transform', (d, i) => {
        const xCompensation = d.success <= 9 ? 5 : 0;
        return `translate(${
          barWidth * i + i * 2 * barPadding + barPadding + barWidth / 4 + xCompensation
        } ,${0})`;
      });

    if (!resize) {
      successBarValues
        .transition()
        .duration(Settings.ANIMATION_LENGTH)
        .ease(easeLinear)
        .attr('y', (d) => y(d.success) - barValueMargin);
    }

    const failedBarValues = g
      .selectAll('.bar-value-failed')
      .data(this.props.data)
      .enter()
      .append('text')
      .attr('class', (d) => `bar-value-failed ${this.getBarState(d.id)}`)
      .attr('width', barWidth)
      .text((d) => d.failed)
      .attr('y', (d) => (resize ? y(d.failed) - barValueMargin : this.height - 1))
      .attr('transform', (d, i) => {
        const xCompensation = d.failed <= 9 ? 5 : 0;
        return `translate(
            ${
              barWidth * i +
              i * 2 * barPadding +
              barPadding +
              barWidth +
              1 +
              barWidth / 4 +
              xCompensation
            },
            ${0}
          )`;
      });

    if (!resize) {
      failedBarValues
        .transition()
        .duration(Settings.ANIMATION_LENGTH)
        .ease(easeLinear)
        .attr('y', (d) => y(d.failed) - barValueMargin);
    }
  };

  drawBarLegends = ({ g, barWidth, barPadding, margin }) => {
    const legendRectWidth = 7;
    const legendLabelLeftMargin = 18;
    const legendLabelTopMargin = 6;

    g.selectAll('.bar-legend-success-rect')
      .data(this.props.data)
      .enter()
      .append('rect')
      .style('stroke', 'none')
      .attr(
        'class',
        (d, i) => `bar-legend-success-rect ${this.getColor(i, 'success')} ${this.getBarState(d.id)}`
      )
      .attr('width', legendRectWidth)
      .attr('height', legendRectWidth)
      .attr('y', this.height + margin.bottom / 2 + 5)
      .attr('transform', (d, i) => {
        return 'translate(' + [barWidth * i + i * 2 * barPadding + barPadding, 0] + ')';
      });

    g.selectAll('.bar-legend-failed-rect')
      .data(this.props.data)
      .enter()
      .append('rect')
      .style('stroke', 'none')
      .attr(
        'class',
        (d, i) => `bar-legend-failed-rect ${this.getColor(i, 'failed')} ${this.getBarState(d.id)}`
      )
      .attr('width', legendRectWidth)
      .attr('height', legendRectWidth)
      .attr('y', this.height + margin.bottom / 2 + 20)
      .attr('transform', (d, i) => {
        return 'translate(' + [barWidth * i + i * 2 * barPadding + barPadding, 0] + ')';
      });

    g.selectAll('.bar-legend-success-text')
      .data(this.props.data)
      .enter()
      .append('text')
      .attr('class', (d) => `bar-legend-success-text ${this.getBarState(d.id)}`)
      .attr('width', barWidth)
      .attr('y', this.height + margin.bottom / 2 + 5)
      .text('Success')
      .attr('transform', (d, i) => {
        return (
          'translate(' +
          [
            barWidth * i + i * 2 * barPadding + barPadding + legendLabelLeftMargin,
            legendLabelTopMargin,
          ] +
          ')'
        );
      });

    g.selectAll('.bar-legend-failed-text')
      .data(this.props.data)
      .enter()
      .append('text')
      .attr('class', (d) => `bar-legend-failed-text ${this.getBarState(d.id)}`)
      .attr('width', barWidth)
      .attr('y', this.height + margin.bottom / 2 + 20)
      .text('Failed')
      .attr('transform', (d, i) => {
        return (
          'translate(' +
          [
            barWidth * i + i * 2 * barPadding + barPadding + legendLabelLeftMargin,
            legendLabelTopMargin,
          ] +
          ')'
        );
      });
  };

  render() {
    return (
      <ReactResizeDetector
        handleWidth
        skipOnMount
        onResize={this.resize}
        render={() => (
          <React.Fragment>
            <div
              className={classNames('bar-chart-container', this.props.className, {
                'has-selected-course': this.state.selectedBars !== null,
                disabled: this.props.disabled,
              })}
              ref={this.node}>
              <span className="chart-title">
                <FormattedMessage id="DASHBOARD.USERS_BY_COURSES" />
              </span>
              {!this.props.data.length && !this.props.loading ? (
                <span className="empty-message">
                  <FormattedMessage id="DASHBOARD.NO_COURSE_INFO" />
                </span>
              ) : null}
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}
