import differenceInYears from 'date-fns/differenceInYears';

import { arrayContainsArray } from 'util/array';
import { Roles, roleTypes, ROLES_WITH_SUBSIDIARIES, ROLES_WITH_COMPANIES } from 'libs/roles';

export const findCountry = (countries, query, attributeName = 'country') => {
  return countries ? countries.find((item) => item[attributeName] + '' === query + '') : null;
};

export const findStateByName = (states, query, queryAttribute = 'state') => {
  return states ? states.find((item) => item[queryAttribute] + '' === query + '') : null;
};

export const isLegalDrinkingAgeInCountry = (
  countries,
  birthDate,
  { country, state },
  queryAttributes = { country: 'country', state: 'state' }
) => {
  if (!country) {
    return false;
  }
  const age = differenceInYears(new Date(), birthDate);

  const countryOfResidence = findCountry(countries, country, queryAttributes.country);
  const stateOfResidence = state
    ? countryOfResidence && countryOfResidence.states
      ? findStateByName(countryOfResidence.states, state, queryAttributes.state)
      : null
    : null;
  return stateOfResidence
    ? age >= stateOfResidence.ageLimit
    : countryOfResidence && age >= countryOfResidence.ageLimit;
};

export const hasCountryStates = (countries, countryQuery, attributeName = 'country') => {
  const country = findCountry(countries, countryQuery, attributeName);
  return country ? !!country.states : false;
};

export const isStateRequired = (
  countries,
  countryQuery,
  stateQuery,
  queryAttributes = { country: 'country', state: 'state' }
) => {
  return countryQuery
    ? hasCountryStates(countries, countryQuery, queryAttributes.country)
      ? !!stateQuery
      : true
    : true;
};
export const getType = (roleIds = [], roles) => {
  const selectedRoleNames = roles.filter(({ id }) => roleIds.includes(id)).map(({ name }) => name);
  const type = arrayContainsArray(ROLES_WITH_SUBSIDIARIES, selectedRoleNames)
    ? roleTypes.SUBSIDIARY
    : arrayContainsArray(ROLES_WITH_COMPANIES, selectedRoleNames)
    ? roleTypes.COMPANY
    : arrayContainsArray([Roles.SYSTEM_ADMIN], selectedRoleNames)
    ? roleTypes.SYSTEM
    : null;
  return type;
};

export const isSubsidiaryRequiredForSelectedRoles = (roleIds = [], roles, value) => {
  const roleType = getType(roleIds, roles);
  return (roleIds.length > 0 ? roleType === null || roleType === roleTypes.SUBSIDIARY : false)
    ? !!value
    : true;
};
