import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { ConfirmMessage, Modal } from 'components';

import './style.scss';

const ConfirmModal = ({
  title,
  saveTitle,
  closeTitle,
  preventDismiss = true,
  shadowed = true,
  confirmElement,
  intl: { formatMessage },
  ...props
}) => {
  const defaultTitle = formatMessage({ id: 'COMMON.CONFIRM' });
  const defaultSaveTitle = formatMessage({ id: 'COMMON.CONFIRM' });
  const defaultCloseTitle = formatMessage({ id: 'COMMON.CANCEL' });

  return (
    <Modal
      title={title || defaultTitle}
      saveTitle={saveTitle || defaultSaveTitle}
      closeTitle={closeTitle || defaultCloseTitle}
      preventDismiss={preventDismiss}
      shadowed={shadowed}
      footerClass="confirm-modal__footer"
      {...props}>
      <ConfirmMessage>{confirmElement}</ConfirmMessage>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  saveTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  closeTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  intl: PropTypes.object.isRequired,
  preventDismiss: PropTypes.bool,
  shadowed: PropTypes.bool,
  confirmElement: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default injectIntl(ConfirmModal);
