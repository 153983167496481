import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import { TableContainer } from 'components';
import { widthOptions } from 'components/Table/tableConstants';
import Settings from 'config/settings';

const GroupsTable = ({
  loading,
  list,
  loadList,
  params,
  openEditModal,
  openDeleteModal,
  hasRoleToEdit,
  hasRoleToDelete,
  intl: { formatMessage },
}) => {
  return (
    <TableContainer
      loading={loading}
      list={list}
      loadList={loadList}
      params={params}
      columns={[
        {
          key: 'edit',
          hide: !hasRoleToEdit,
          iconCell: true,
          onClick: (group) => openEditModal(group),
          editIcon: true,
        },
        {
          key: 'name',
          label: formatMessage({ id: 'GROUPS.NAME' }),
          sortBy: Settings.SORT_BY.NAME,
        },
        {
          key: 'subsidiaryName',
          label: formatMessage({ id: 'GROUPS.SUBSIDIARY' }),
        },
        {
          key: 'description',
          sortBy: Settings.SORT_BY.DESCRIPTION,
          label: formatMessage({ id: 'GROUPS.DESCRIPTION' }),
          multiLine: true,
          width: widthOptions.lg,
        },
        {
          key: 'coursesCount',
          label: formatMessage({ id: 'GROUPS.NUM_OF_COURSES' }),
          sortBy: Settings.SORT_BY.COURSES_COUNT,
          width: widthOptions.sm,
        },
        {
          key: 'lessonsCount',
          label: formatMessage({ id: 'GROUPS.NUM_OF_LESSONS' }),
          sortBy: Settings.SORT_BY.LESSONS_COUNT,
          width: widthOptions.sm,
        },
        {
          key: 'usersCount',
          label: formatMessage({ id: 'GROUPS.NUM_OF_USERS' }),
          sortBy: Settings.SORT_BY.USERS_COUNT,
          width: widthOptions.sm,
        },
        {
          key: 'delete',
          hide: !hasRoleToDelete,
          iconCell: true,
          onClick: (group) => openDeleteModal(group),
          deleteIcon: true,
        },
      ]}
    />
  );
};

GroupsTable.propTypes = {
  params: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  hasRoleToEdit: PropTypes.bool,
  hasRoleToDelete: PropTypes.bool,
};

export default withPagination(injectIntl(GroupsTable));
