import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Modal, Carousel } from 'components';
import { getPreviewContent } from 'util/filePreviewUtil';
import PreviewSlideWrapper from './PreviewSlideWrapper';

import './style.scss';

const PreviewModal = ({
  open,
  onClose,
  files,
  activeIndex,
  getFileUrl,
  newModal,
  type,
  setActiveIndex,
  shadowed = true,
}) => (
  <Modal
    open={open}
    title="Preview"
    className={classNames('file-preview-modal', {
      'file-preview-modal--new-modal': newModal,
    })}
    shadowed={shadowed}
    closeButton={false}
    saveButton={false}
    large
    onClose={onClose}
    preventDismiss>
    <div className="d-flex justify-content-center">
      {files?.length > 0 ? (
        <Carousel
          items={files.map((item, index) => ({
            id: `${item.name}-${index}`,
            content: (
              <PreviewSlideWrapper>
                {getPreviewContent({
                  type,
                  file: item,
                  getFileUrl,
                  className: 'carousel-slide-content',
                  isInModal: true,
                })}
              </PreviewSlideWrapper>
            ),
          }))}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      ) : null}
    </div>
  </Modal>
);

PreviewModal.propTypes = {
  open: PropTypes.bool,
  shadowed: PropTypes.bool,
  activeIndex: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  files: PropTypes.array,
  type: PropTypes.string,
  getFileUrl: PropTypes.func,
  newModal: PropTypes.bool,
  setActiveIndex: PropTypes.func.isRequired,
};

export default PreviewModal;
