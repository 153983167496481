export const OS = { WIN: 'win', MAC: 'mac', UNKNOWN: 'unknown' };
export const mimeTypeConstants = { ZIP: 'zip' };

export const getUserOs = () => {
  const os =
    navigator.appVersion.indexOf('Win') !== -1
      ? OS.WIN
      : navigator.appVersion.indexOf('Mac') !== -1
      ? OS.MAC
      : OS.UNKNOWN;
  return os;
};

export const zipMimeType = 'application/zip';

export const xlsxMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
