import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getCompanies } from 'services/companies';
import { getSubsidiaries } from 'services/subsidiaries';
import { TypeSubTypeChooserComplexField } from 'components';

const loadSubtypesByTypeKey = async (key) => {
  try {
    const res = await getSubsidiaries({
      companyId: key,
    });
    return res || [];
  } catch (e) {
    return [];
  }
};

const CompanySubsidiaryChooser = ({
  companyInputName = 'company',
  companyOptionAsObject,
  companyLabel = <FormattedMessage id="COMPANIES.COMPANY" />,
  onlyEnabledCompanies = true,
  subsidiaryInputName = 'subsidiary',
  subsidiaryOptionAsObject,
  subsidiaryLabel = <FormattedMessage id="SUBSIDIARY.TITLE" />,
  onlyEnabledSubsidiaries = true,
  companyKey = 'id',
  subsidiaryKey = 'id',
  companyPlaceholder = 'Company',
  subsidiaryPlaceholder = 'Subsidiary',
  companyLoadingPlaceholder = 'Loading company...',
  subsidiaryLoadingPlaceholder = 'Loading subsidiary...',
  ...props
}) => {
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [companies, setCompany] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    const fetchCompany = async () => {
      try {
        setIsLoadingCompanies(true);
        const res = await getCompanies();
        if (!isCancelled) {
          setCompany(res || []);
        }
      } finally {
        setIsLoadingCompanies(false);
      }
    };
    fetchCompany();
    return () => {
      isCancelled = true;
    };
  }, [companyOptionAsObject]);

  return (
    <TypeSubTypeChooserComplexField
      typeKey={companyKey}
      typeInputName={companyInputName}
      typeValueList={companies}
      typeLabel={companyLabel}
      typeOptionAsObject={companyOptionAsObject}
      typePlaceholder={companyPlaceholder}
      subTypePlaceholder={subsidiaryPlaceholder}
      typeLoadingPlaceholder={companyLoadingPlaceholder}
      subTypeLoadingPlaceholder={subsidiaryLoadingPlaceholder}
      onlyEnabledTypes={onlyEnabledCompanies}
      subTypeKey={subsidiaryKey}
      subTypeLabel={subsidiaryLabel}
      subTypeInputName={subsidiaryInputName}
      subTypeOptionAsObject={subsidiaryOptionAsObject}
      onlyEnabledSubTypes={onlyEnabledSubsidiaries}
      loadSubtypesByTypeKey={loadSubtypesByTypeKey}
      isLoadingTypeOptions={isLoadingCompanies}
      {...props}
    />
  );
};

CompanySubsidiaryChooser.propTypes = {
  companyInputName: PropTypes.string.isRequired,
  companyOptionAsObject: PropTypes.bool,
  subsidiaryInputName: PropTypes.string.isRequired,
  subsidiaryOptionAsObject: PropTypes.bool,
  companyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onlyEnabledCompanies: PropTypes.bool,
  subsidiaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onlyEnabledSubsidiaries: PropTypes.bool,
  companyKey: PropTypes.string,
  subsidiaryKey: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrorComponent: PropTypes.bool,
  companyPlaceholder: PropTypes.bool,
  subsidiaryPlaceholder: PropTypes.bool,
  companyLoadingPlaceholder: PropTypes.bool,
  subsidiaryLoadingPlaceholder: PropTypes.bool,
};

export default CompanySubsidiaryChooser;
