import TranslationManager from '../common/TranslationManager';

const defaultLocale = 'en';
const translationManager = new TranslationManager(defaultLocale);

/**
 * @returns {string} Default locale setting from .env.
 */

export default () => {
  const locale = defaultLocale;

  translationManager.addLanguage(
    'en',
    require.context('../assets/translations/en', true, /.json$/)
  );

  return {
    messages: translationManager.getTranslation(locale),
    locale,
  };
};
