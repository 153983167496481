import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

const ProgressBar = ({ indeterminate = true, width = 0, className, hide = false, ...props }) => {
  return (
    <div className={classNames('ProgressBar', className, { hide })} {...props}>
      <div
        className={classNames('progress-line', {
          determinate: !indeterminate,
          indeterminate,
        })}
        style={!indeterminate ? { width: `${width}%` } : null}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  indeterminate: PropTypes.bool,
  width: PropTypes.number,
  className: PropTypes.string,
  hide: PropTypes.bool,
};

export default ProgressBar;
