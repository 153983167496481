import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import { v4 as uuidv4 } from 'uuid';

import { Spinner } from 'components';
import { hasBrowsedFile } from '../fileUploadUtil';
import RequiredInfoMessage from '../RequiredInfoMessage/RequiredInfoMessage';

import './style.scss';

const getFilesWithKey = (file = []) =>
  (isArray(file) ? file : [file]).map((file) => ({ ...file, key: uuidv4() }));

const FileUploadPreview = ({
  requiredMessage,
  required,
  loading,
  file,
  renderSelection,
  deletable,
  disabled,
  deleteSelection,
}) => {
  const canBeDeleted = deletable && !disabled;
  const hasBrowsedFiles = hasBrowsedFile({ file });
  const requiredElement =
    required && !loading ? <RequiredInfoMessage requiredMessage={requiredMessage} /> : null;

  const getRenderSelection = useCallback(
    ({ file }) =>
      renderSelection({
        files: getFilesWithKey(file),
        deleteSelection: canBeDeleted ? deleteSelection : null,
      }),
    [deleteSelection, canBeDeleted, renderSelection]
  );

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : null}
      {hasBrowsedFiles && !loading ? (
        <div className="file-upload-preview__preview-container">
          {getRenderSelection({ file, deleteSelection })}
        </div>
      ) : (
        requiredElement
      )}
    </>
  );
};

FileUploadPreview.propTypes = {
  requiredMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  required: PropTypes.bool,
  loading: PropTypes.bool,
  canBeDeleted: PropTypes.bool,
  deleteSelection: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  renderSelection: PropTypes.func.isRequired,
  deletable: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FileUploadPreview;
