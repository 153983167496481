import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './style.scss';

const GroupLabel = ({ baseData, intl: { formatMessage } }) => {
  const { name } = baseData || {};
  return (
    <div className="title-label-container">
      {name ? name : formatMessage({ id: 'COMMON.NO_DATA' })}
    </div>
  );
};

GroupLabel.propTypes = {
  baseData: PropTypes.object,
};

export default injectIntl(GroupLabel);
