import isArray from 'lodash/isArray';

import { isOverflown } from 'util/overflowUtil';

export const popoverMouseEvents = {
  HOVER: 'hover',
  CLICK: 'click',
};

export const getTitle = (title) => title || '';

export const hasTriggerType = ({ currentTrigger, triggers }) => {
  if (isArray(triggers)) {
    return triggers.includes(currentTrigger);
  }
  return currentTrigger === triggers;
};

export const getContent = ({ element, content, overflowContent }) =>
  isOverflown(element) && overflowContent ? overflowContent : content || '';

export const shouldShowTooltip = ({ always, onOverflow, element }) =>
  always || (onOverflow && isOverflown(element));

export const getPopperConfig = (offset) => ({
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: () => offset,
      },
    },
  ],
});
