import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withCustomIconStyling from './withCustomIconStyling';

const HashtagIcon = ({ className, ...props }) => {
  return <i className={classNames(className, 'fas fa-hashtag')} {...props} />;
};

HashtagIcon.propTypes = {
  className: PropTypes.string,
};

export default withCustomIconStyling(HashtagIcon);
