import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableDnDWrapper = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return <>{children({ setNodeRef, attributes, listeners, style, isDragging })}</>;
};

SortableDnDWrapper.propTypes = {
  id: PropTypes.number,
  children: PropTypes.func,
};

export default SortableDnDWrapper;
