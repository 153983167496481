import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, Field } from 'formik';

import { TextField, Modal } from 'components';
import { Yup } from 'util/Yup';

const ForgotPasswordForm = ({ onSubmit, closeModal, open }) => {
  const fomrikRef = React.createRef();
  const initialValues = { email: '' };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const [successFullModalOpen, setSuccessFullModalOpen] = useState(false);

  const closeSuccessFullModal = () => {
    setSuccessFullModalOpen(false);
  };

  const submit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await onSubmit(values.email);
    setSuccessFullModalOpen(true);
    setSubmitting(false);
  };

  const save = () => {
    fomrikRef.current.submitForm();
  };

  const isLoading = fomrikRef?.current?.isSubmitting;

  return (
    <>
      <Modal
        className="forgot-password-modal"
        title={<FormattedMessage id="COMMON.FORGOT_PASSWORD" />}
        open={open}
        large={false}
        saveAction={save}
        saveTitle={<FormattedMessage id="LOGIN.SEND" />}
        loading={isLoading}
        onClose={closeModal}>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={submit}
          innerRef={fomrikRef}>
          <Form className="forgot-password-form">
            <Field
              name="email"
              component={TextField}
              label={<FormattedMessage id="COMMON.EMAIL" />}
            />
          </Form>
        </Formik>
      </Modal>
      <Modal
        className="email-sent-modal"
        title={<FormattedMessage id="COMMON.SUCCESS" />}
        open={successFullModalOpen}
        large={false}
        onClose={closeSuccessFullModal}
        saveAction={closeSuccessFullModal}
        saveTitle={<FormattedMessage id="COMMON.OK" />}
        closeButton={false}>
        <FormattedMessage id="LOGIN.SUCCESSFUL_PASSWORD_RENEWAL" />
      </Modal>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default ForgotPasswordForm;
