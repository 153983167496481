import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'components/Input';

const AsyncSelectField = ({ form, field, hasErrorComponent = true, ...props }) => {
  const onChange = useCallback(
    (inputValue) => {
      if (form && field) {
        form.setFieldValue(field.name, inputValue);
        form.setFieldTouched(field.name, true);
      }
    },
    [form, field]
  );

  return (
    <SelectInput
      value={field?.value}
      onChange={onChange}
      hasErrorComponent={hasErrorComponent}
      options={undefined}
      {...props}
    />
  );
};

AsyncSelectField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  multi: PropTypes.bool,
  hasErrorComponent: PropTypes.bool,
};

export default AsyncSelectField;
