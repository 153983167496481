import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'components/Input';

import { selectOption } from 'common';

const TypeSubTypeChooser = ({
  selectedType = null,
  selectedSubType = null,
  disabled,
  hideSubtypeIfNoOptions,
  typeOptions = [],
  subTypeOptions = [],
  setSubTypeOptions,
  onTypeChange,
  onSubTypeChange,
  loadSubtypeOptionsByTypeId,
  typeLabel = 'Type',
  typeKey = 'id',
  subTypeLabel = 'Subtype',
  subTypeKey = 'id',
  selectClasses = 'col-12 col-md-6',
  isTypeOptionDisabled,
  isSubTypeOptionDisabled,
  typeErrorMessage,
  subTypeErrorMessage,
  hasTypeErrorMessage,
  hasSubTypeErrorMessage,
  isLoadingTypeOptions,
  typePlaceholder,
  subTypePlaceholder,
  typeLoadingPlaceholder,
  subTypeLoadingPlaceholder,
  isLoading,
  ...props
}) => {
  const [hasTypeChanged, setHasTypeChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectedTypeValueId = selectedType?.value;
  const showSubTypeSelect = !hideSubtypeIfNoOptions || subTypeOptions?.length > 0;
  const isLoadingType = isLoading || isLoadingTypeOptions;
  const isLoadingSubType = isLoading || loading;

  useEffect(() => {
    const fetchSubTypeOptionsForType = async (option) => {
      try {
        setLoading(true);
        const res = await loadSubtypeOptionsByTypeId(option);
        setSubTypeOptions(res);
      } catch (e) {
        setSubTypeOptions([]);
      } finally {
        setLoading(false);
      }
    };
    if (selectedTypeValueId) {
      fetchSubTypeOptionsForType(selectedTypeValueId);
    }
  }, [selectedTypeValueId, loadSubtypeOptionsByTypeId, onSubTypeChange, setSubTypeOptions]);

  useEffect(() => {
    if (hasTypeChanged) {
      onSubTypeChange(null);
      setHasTypeChanged(false);
    }
  }, [hasTypeChanged, onSubTypeChange]);

  const handleTypeChange = useCallback(
    async (option) => {
      await onTypeChange(option);
      setHasTypeChanged(true);
    },
    [onTypeChange]
  );

  return (
    <div className="row">
      <div className={selectClasses}>
        <SelectInput
          {...props}
          value={selectedType}
          disabled={disabled}
          itemKey={typeKey}
          label={typeLabel}
          options={typeOptions || []}
          onChange={handleTypeChange}
          isOptionDisabled={isTypeOptionDisabled}
          errorMessage={typeErrorMessage}
          hasErrorMessage={hasTypeErrorMessage}
          isLoading={isLoadingType}
          placeholder={isLoadingType ? typeLoadingPlaceholder : typePlaceholder}
        />
      </div>
      <div className={selectClasses}>
        {showSubTypeSelect ? (
          <SelectInput
            {...props}
            value={selectedSubType}
            disabled={disabled}
            itemKey={subTypeKey}
            label={subTypeLabel}
            options={subTypeOptions}
            onChange={onSubTypeChange}
            isOptionDisabled={isSubTypeOptionDisabled}
            errorMessage={subTypeErrorMessage}
            hasErrorMessage={hasSubTypeErrorMessage}
            isLoading={isLoadingSubType}
            placeholder={isLoadingSubType ? subTypeLoadingPlaceholder : subTypePlaceholder}
          />
        ) : null}
      </div>
    </div>
  );
};

TypeSubTypeChooser.propTypes = {
  typeOptions: PropTypes.arrayOf(selectOption),
  subTypeOptions: PropTypes.arrayOf(selectOption),
  setSubTypeOptions: PropTypes.func.isRequired,
  selectedType: selectOption,
  onTypeChange: PropTypes.func.isRequired,
  selectedSubType: selectOption,
  onSubTypeChange: PropTypes.func.isRequired,
  loadSubtypeOptionsByTypeId: PropTypes.func.isRequired,
  typeKey: PropTypes.string,
  subTypeKey: PropTypes.string,
  typeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTypeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasTypeErrorMessage: PropTypes.bool,
  typeErrorMessage: PropTypes.string,
  hasSubTypeErrorMessage: PropTypes.bool,
  subTypeErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  hideSubtypeIfNoOptions: PropTypes.bool,
  selectClasses: PropTypes.string,
  isTypeOptionDisabled: PropTypes.func,
  isSubTypeOptionDisabled: PropTypes.func,
  isLoadingTypeOptions: PropTypes.bool,
  typePlaceholder: PropTypes.bool,
  subTypePlaceholder: PropTypes.bool,
  typeLoadingPlaceholder: PropTypes.bool,
  subTypeLoadingPlaceholder: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default TypeSubTypeChooser;
