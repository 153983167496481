import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { membersChartDetail } from 'common/propTypes';
import AssignedGroups from './AssignedGroups';
import TestStats from './TestStats';
import TagsWordCloud from './TagsWordCloud';
import ExpressionsWordCloud from './ExpressionsWordCloud';

import './style.scss';

const ChartDetailContainer = ({ isGroup, chartDetail }) => {
  const { assignedGroups, testStatistics, mostUsedTags, mostSearchedWords } = chartDetail || {};

  return (
    <div className="container-fluid h-100 px-0">
      <div className="row h-100">
        <div className={classNames('', isGroup ? 'col-12' : 'col-xl-8')}>
          <div className="teststats-and-wordcloud-container">
            <div className="teststats-container">
              <TestStats statistics={testStatistics} />
            </div>
            <div className="d-flex h-100 container-fluid px-2 flex-column pt-2">
              <div className="row flex-grow-1">
                <div className="col-12 col-md-6 col-lg-12 px-1 pe-xl-1  pt-2">
                  <TagsWordCloud tags={mostUsedTags} />
                </div>
                <div className="col-12 col-md-6 col-lg-12 px-1 ps-xl-1  pt-2">
                  <ExpressionsWordCloud words={mostSearchedWords} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isGroup && (
          <div className="col-12 col-xl-4 pt-3 pt-xl-0 ps-xl-0">
            <AssignedGroups groups={assignedGroups} />
          </div>
        )}
      </div>
    </div>
  );
};

ChartDetailContainer.propTypes = { isGroup: PropTypes.bool, chartDetail: membersChartDetail };

export default ChartDetailContainer;
