import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { CheckBox, InlineError } from 'components';

import './style.scss';

const CheckBoxField = ({ form, field, containerClass, showError, ...props }) => {
  const errorMessage = get(form.errors, field.name);
  const hasErrorMessage = Boolean(errorMessage && get(form.touched, field.name));
  const showErrorMessage = !!hasErrorMessage && showError;

  const handleChange = (e) => {
    if (form && field) {
      form.setFieldValue(field.name, e.target.checked);
      form.setFieldTouched(field.name, true);
    }
  };

  return (
    <div className={containerClass}>
      <CheckBox
        {...props}
        boxClass={classNames({ 'check-box-field__box--error': showErrorMessage })}
        checked={!!field.value}
        onChange={handleChange}
      />
      {showError && <InlineError show={showErrorMessage} message={errorMessage} />}
    </div>
  );
};

CheckBoxField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltipLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hideValue: PropTypes.bool,
  alwaysShowTooltip: PropTypes.bool,
  error: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  containerClass: PropTypes.string,
  showError: PropTypes.bool,
};

export default CheckBoxField;
