import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { companySubsidiaryStatisticTypeConstants } from 'util/companySubsidiaryStatisticTypes';

import './style.scss';

const StatisticTypeChooser = ({
  isAccordionOpen,
  setAccordionOpen,
  loading,
  filter,
  onTypeChange,
  baseData,
  intl: { formatMessage },
}) => {
  const handleClick = async (type) => {
    if (!isAccordionOpen) {
      setAccordionOpen(true);
    }
    onTypeChange(type);
  };

  return (
    <div className="statistic-type-chooser">
      {Object.keys(companySubsidiaryStatisticTypeConstants).map((type) => {
        const { translateId, buttonClassname, getCountFromBaseData } =
          companySubsidiaryStatisticTypeConstants[type] || {};
        const count = getCountFromBaseData(baseData);
        const isDisabled = (loading && isAccordionOpen) || (filter && filter.filterType === type);

        return (
          <button
            className={classNames('num-of-button', buttonClassname, {
              active: filter && filter.filterType === type,
              disabled: isDisabled,
            })}
            disabled={isDisabled}
            key={uuidv4()}
            onClick={() => handleClick(type)}>
            <div className="button-label-container">
              <div className="count">
                {count || count === 0 ? count : formatMessage({ id: 'COMMON.NO_DATA' })}
              </div>
              <div className="count-label">{formatMessage({ id: translateId })}</div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

StatisticTypeChooser.propTypes = {
  baseData: PropTypes.object,
  setAccordionOpen: PropTypes.func.isRequired,
  childFilter: PropTypes.object,
  handleSetChildFilter: PropTypes.func,
  loading: PropTypes.bool,
  isAccordionOpen: PropTypes.bool,
};

export default injectIntl(StatisticTypeChooser);
