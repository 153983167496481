import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import isObject from 'lodash/isObject';

import { TextField, Button, InlineError } from 'components';
import { Yup } from 'util/Yup';

import './style.scss';

const initialValues = { email: '', password: '' };

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const LoginForm = ({ showError = '', onSubmit }) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={loginSchema}
    onSubmit={onSubmit}>
    <Form className="login-form">
      <Field
        containerClass="login-form__input"
        name="email"
        component={TextField}
        label={<FormattedMessage id="COMMON.EMAIL" />}
        shapeVariant="round"
      />
      <Field
        containerClass="login-form__input"
        name="password"
        component={TextField}
        label={<FormattedMessage id="LOGIN.PASSWORD" />}
        type="password"
        shapeVariant="round"
        maskToggle
        maskToggleClass="login-form__eye-icon"
      />
      <InlineError
        show={!!showError}
        message={
          showError && showError.length > 0
            ? 'LOGIN.INCORRECT_EMAIL_OR_PASSWORD'
            : !isObject(showError)
            ? showError
            : ''
        }
        messageClass="login-form__error"
      />
      <div className="login-form__button-container">
        <Button type="submit" className="login-form__button-container__button btn-block">
          <FormattedMessage id="LOGIN.TITLE" />
        </Button>
      </div>
    </Form>
  </Formik>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showError: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default LoginForm;
