import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { ConfirmModal } from 'components';
import UserRoles from './UserRoles';
import UserRoleEditor from '../UserEditor/UserRoleEditor/UserRoleEditor';
import { getUserRoles, deleteRole } from 'services/users';
import { modalType } from 'util/modalType';

const defaultLoaderHeight = '200px';

const UserRolesContainer = ({
  open,
  user,
  hasRoleToCreate,
  hasRoleToDelete,
  availableRoles,
  existingEmails,
  showCompanySubsidiaryChooser,
  skipFetchOnChange,
  onSaved,
  intl: { formatMessage },
}) => {
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState();

  const fetchRolesList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getUserRoles(user.id);
      setRoles(res);
    } catch (e) {
      setRoles([]);
    } finally {
      setLoading(false);
    }
  }, [user.id]);

  useEffect(() => {
    if (open) {
      fetchRolesList();
    }
  }, [open, fetchRolesList]);

  const setRoleWithUserData = (role) => {
    setRole({
      email: user && user.email,
      companyObject: user && user.companies && user.companies[0],
      subsidiaryObject: user && user.subsidiaries && user.subsidiaries[0],
      ...role,
    });
  };

  const openEditModal = (role) => {
    setRoleWithUserData(role);
    setModal(modalType.EDIT);
  };

  const openDeleteModal = (role) => {
    setRole(role);
    setModal(modalType.DELETE);
  };

  const closeModal = () => {
    setModal(null);
  };

  const handleDelete = async () => {
    const { roleId, companyId, subsidiaryId } = role || {};
    try {
      await deleteRole(user.id, {
        companyId: companyId,
        subsidiaryId: subsidiaryId,
        roleId: roleId,
      });
      if (!skipFetchOnChange) {
        await fetchRolesList();
      }

      if (onSaved) {
        onSaved();
      }
    } finally {
      closeModal();
    }
  };

  const onEdit = async () => {
    if (!skipFetchOnChange) {
      await fetchRolesList();
    }

    if (onSaved) {
      onSaved();
    }
  };

  const getEditRoleInitialValues = (roles) => ({
    roles: roles?.map((role) => role.roleId) || [],
    email: user && user.email,
    companyObject: user && user.companies && user.companies[0],
    subsidiaryObject: user && user.subsidiaries && user.subsidiaries[0],
  });

  const disabledCompanySubsidiaryChooser = !!(
    getEditRoleInitialValues().companyObject && getEditRoleInitialValues().subsidiaryObject
  );

  return (
    <div>
      <UserRoles
        list={roles}
        height={defaultLoaderHeight}
        loading={loading}
        hasRoleToCreate={hasRoleToCreate}
        hasRoleToDelete={hasRoleToDelete}
        openDeleteModal={openDeleteModal}
        openEditModal={openEditModal}
      />
      <UserRoleEditor
        open={modal === modalType.EDIT}
        closeEditor={closeModal}
        initialRoleData={getEditRoleInitialValues(roles)}
        fixedEmail={!!user.email}
        existingEmails={existingEmails}
        availableRoles={availableRoles}
        onSaved={onEdit}
        showCompanySubsidiaryChooser={showCompanySubsidiaryChooser}
        disabledCompanySubsidiaryChooser={disabledCompanySubsidiaryChooser}
        modalTitle={<FormattedMessage id="USERS.ADD_ROLE" />}
      />
      <ConfirmModal
        open={modal === modalType.DELETE}
        onClose={closeModal}
        saveAction={handleDelete}
        confirmElement={formatMessage(
          { id: 'COMMON.DELETE_QUESTION' },
          { title: role && role.displayName }
        )}
      />
    </div>
  );
};

UserRolesContainer.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  hasRoleToCreate: PropTypes.bool,
  hasRoleToDelete: PropTypes.bool,
  availableRoles: PropTypes.arrayOf(PropTypes.object),
  existingEmails: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  onSaved: PropTypes.func,
  showCompanySubsidiaryChooser: PropTypes.bool,
  skipFetchOnChange: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(UserRolesContainer);
