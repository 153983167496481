import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const DragHandle = ({ hideIcon, attributes, listeners }) => (
  <div className="drag-handle" {...attributes} {...listeners}>
    <div className="h-100 d-flex align-items-center justify-content-center">
      <i className={classNames('fa fa-bars', { visible: !hideIcon, invisible: hideIcon })} />
    </div>
  </div>
);

DragHandle.propTypes = {
  hideIcon: PropTypes.bool,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
};

export default DragHandle;
