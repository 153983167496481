import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';

import animationData from 'assets/animation/logoAnimation.json';
import animationDataDark from 'assets/animation/logoAnimationDark.json';

import styles from './style.module.scss';

const sizes = {
  lg: { height: 365, width: 302 },
  md: { height: 300, width: 248 },
  sm: { height: 200, width: 165 },
  xs: { height: 100, width: 87 },
  xxs: { height: 60, width: 52 },
};

const LogoAnimation = ({
  size = 'lg',
  loop = false,
  autoplay = true,
  dark,
  className,
  ...props
}) => {
  const currentAnimationData = dark ? animationDataDark : animationData;

  const { height, width } = sizes[size] || { height: 365, width: 302 };

  return (
    <div className={classNames(styles.LogoAnimation, className)} {...props}>
      <Lottie
        animationData={currentAnimationData}
        loop={loop}
        autoPlay={autoplay}
        height={height}
        width={width}
        style={{ height, width }}
      />
    </div>
  );
};

LogoAnimation.propTypes = {
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  dark: PropTypes.bool,
  className: PropTypes.string,
};

export default LogoAnimation;
