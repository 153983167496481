import { getValidationMessageInput } from 'libs/validation/validation';
import { Yup } from 'util/Yup';
import {
  getMessageForImageMinError,
  getMessageForMinItemsError,
} from 'libs/validation/yupValidation';
import { isCompanyEnabled } from 'libs/validation/company/validation';
import { getRequestDataForImage } from 'util/imagesMapper';
import { convertSpecificsToSpecificsFormData } from 'util/lessonSpecificMapper';
import { filterUsersAssignedOnlyAsUser } from 'util/assignUserAndGroupHelper';
import { getSpecificForRequest } from 'util/specificsUtil';
import { LessonAccess } from 'libs/accessManagement';

const MAX_230_CHARS = ['nose', 'palate', 'finish'];
const MAX_1300_CHARS = [
  'description',
  'interestingFacts',
  'ingredients',
  'garnish',
  'method',
  'comment',
  'about',
];

const MAX_230_CHARS_LENGTH = 230;
const MAX_1300_CHARS_LENGTH = 1300;

const minPrice = 0;
const minAcceptanceRate = 30;
const maxAcceptanceRate = 100;
const minExpirationDays = 0;
const maxExpirationDays = 100;
const maxTitle = 255;
const maxWhatDoYouLearn = 1500;
const maxRequirement = 1500;
const minTagNumber = 1;

export const specificSchema = Yup.object().shape({
  key: Yup.string().required(),
  value: Yup.mixed()
    .when('key', {
      is: (val) => MAX_230_CHARS.includes(val),
      then: Yup.string()
        .max(MAX_230_CHARS_LENGTH, ({ value }) =>
          getValidationMessageInput('COMMON.TEXT_AREA_MAX_LENGTH', {
            max: MAX_230_CHARS_LENGTH,
            current: value.length,
          })
        )
        .required(),
      otherwise: (schema) => schema.required(),
    })
    .when('key', {
      is: (val) => MAX_1300_CHARS.includes(val),
      then: Yup.string()
        .max(MAX_1300_CHARS_LENGTH, ({ value }) =>
          getValidationMessageInput('COMMON.TEXT_AREA_MAX_LENGTH', {
            max: MAX_1300_CHARS_LENGTH,
            current: value.length,
          })
        )
        .required(),
      otherwise: (schema) => schema.required(),
    }),
  level: Yup.string().required(),
  isGeneral: Yup.boolean(),
  isShow: Yup.boolean(),
  isGenerate: Yup.boolean(),
});

export const lessonEditorValidationSchema = Yup.object().shape({
  company: Yup.object().nullable().required(),
  subsidiary: Yup.object()
    .nullable()
    .required()
    .test(
      'validateCompanyEnabled',
      getValidationMessageInput('COMMON.FIELD_SUBSIDIARY_DISABLED_OR_NOT_FOUND'),
      function (value) {
        return isCompanyEnabled(value);
      }
    ),
  title: Yup.string()
    .required()
    .max(
      maxTitle,
      getValidationMessageInput('COMMON.FIELD_MAX_LENGTH_ERROR', {
        max: maxTitle,
      })
    ),
  public: Yup.boolean(),
  tags: Yup.array()
    .nullable()
    .required()
    .min(minTagNumber, getMessageForMinItemsError(minTagNumber)),
  images: Yup.array().min(1, getMessageForImageMinError(1)),
  price: Yup.number()
    .typeError(getValidationMessageInput('COMMON.FIELD_NUMBER_ERROR'))
    .min(
      minPrice,
      getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', {
        min: minPrice,
      })
    )
    .required(),
  lessonType: Yup.string().required(),
  acceptanceRate: Yup.number()
    .typeError(
      getValidationMessageInput('COMMON.RANGE_NUMBER_BETWEEN_ERROR', {
        min: minAcceptanceRate,
        max: maxAcceptanceRate,
      })
    )
    .min(
      minAcceptanceRate,
      getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', {
        min: minAcceptanceRate,
      })
    )
    .max(
      maxAcceptanceRate,
      getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', {
        max: maxAcceptanceRate,
      })
    )
    .required(),
  expirationDays: Yup.number()
    .typeError(
      getValidationMessageInput('COMMON.RANGE_NUMBER_BETWEEN_ERROR', {
        min: minExpirationDays,
        max: maxExpirationDays,
      })
    )
    .min(
      minExpirationDays,
      getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', {
        min: minExpirationDays,
      })
    )
    .max(
      maxExpirationDays,
      getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', {
        max: maxExpirationDays,
      })
    )
    .required(),
  whatDoYouLearn: Yup.string()
    .nullable()
    .max(
      maxWhatDoYouLearn,
      getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', { max: maxWhatDoYouLearn })
    ),
  requirement: Yup.string()
    .nullable()
    .max(
      maxRequirement,
      getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', { max: maxRequirement })
    ),
  specifics: Yup.array()
    .of(specificSchema)
    .required(getValidationMessageInput('LESSONS.NEED_AT_LEAST_ONE_SPECIFIC'))
    .min(1, getValidationMessageInput('LESSONS.NEED_AT_LEAST_ONE_SPECIFIC')),
  users: Yup.array(),
  groups: Yup.array(),
  canAccess: Yup.boolean(),
});

const initialValues = {
  title: '',
  public: false,
  tags: [],
  images: [],
  specifics: [],
  canAccess: true,
  price: 0,
  lessonType: '',
  acceptanceRate: 30,
  expirationDays: 0,
  subsidiary: null,
  company: null,
  groups: [],
  users: [],
  whatDoYouLearn: '',
  requirement: '',
};

export const getInitialValuesFromLesson = (lesson, edit) =>
  edit
    ? {
        ...initialValues,
        ...lesson,
        subsidiary: lesson?.subsidiary || null,
        oldSubsidiary: lesson?.subsidiary || null,
        specifics: convertSpecificsToSpecificsFormData(lesson?.specifics),
        company: lesson?.subsidiary?.companies || null,
        whatDoYouLearn: lesson?.whatDoYouLearn || '',
        requirement: lesson?.requirement || '',
      }
    : initialValues;

export const canModifySomethingInLesson = (profile, lesson) => {
  const { hasRoleToAssignUserOrGroup } = LessonAccess.getHasRoles(profile.profile);
  return LessonAccess.getHasAccess(profile.profile, lesson).canEdit || hasRoleToAssignUserOrGroup;
};

export const showSubmitButton = (edit, lesson, profile) => {
  const { hasRoleToCreate, hasRoleToAssignUserOrGroup } = LessonAccess.getHasRoles(profile.profile);
  return edit
    ? LessonAccess.getHasAccess(profile.profile, lesson).canEdit || hasRoleToAssignUserOrGroup
    : hasRoleToCreate;
};

export const transformSpecficsToSave = async (lesson) =>
  Promise.all(lesson.specifics.map(getSpecificForRequest));

export const transformTagsToSave = (lesson) =>
  lesson.tags.map((tag) => {
    return {
      id: tag.new ? null : tag.value || tag.id,
      title: tag.label || tag.title,
    };
  });

export const transformImagesToSave = async (lesson) =>
  getRequestDataForImage({
    image: lesson.images,
    getDataForExistingImage: (image) => image?.url,
  });

export const transformUsersToSave = (lesson) =>
  filterUsersAssignedOnlyAsUser(lesson?.users || []).map(({ id }) => id);

export const onSortEnd = (specifics, setFieldValue) => {
  setFieldValue('specifics', specifics);
};

export const canEditWholeLesson = (profile, lesson) =>
  lesson
    ? LessonAccess.getHasAccess(profile.profile, lesson).canEdit
    : LessonAccess.getHasRoles(profile.profile).hasRoleToEdit;

export const preTransformationForSavingLesson = async (values) => {
  return {
    ...values,
    subsidiaryId: values?.subsidiary?.id || null,
    lessonTypeId: 1,
    images: await transformImagesToSave(values),
    specifics: await transformSpecficsToSave(values),
    tags: transformTagsToSave(values),
    users: transformUsersToSave(values),
    groups: values?.groups.map((group) => group.id || group) || [],
    whatDoYouLearn: values?.whatDoYouLearn || null,
    requirement: values?.requirement || null,
  };
};
