import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { hideLoader, showLoader } from 'actions/common';
import { getUserHistory } from 'services/users';
import { Modal, TableContainer } from 'components';

const TEST_DONE_MSG = 'Done';
const TEST_IN_PROGRESS_MSG = 'In progress';
const TEST_SUCCESS_MSG = 'Done';
const TEST_FAILED_MSG = 'In progress';

const UserHistory = ({ closeModal, showLoader, hideLoader, intl, userId, open }) => {
  const [lessons, setLessons] = useState();
  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        try {
          showLoader();
          const res = await getUserHistory(userId);
          setLessons(
            res.lessons.map((item) => ({
              ...item,
              level: item.level + '',
              result: item.result + '',
              receivedXP: item.receivedXP + '',
              testDone: item.testDone ? TEST_DONE_MSG : TEST_IN_PROGRESS_MSG,
              testSuccess: item.testSuccess ? TEST_SUCCESS_MSG : TEST_FAILED_MSG,
            }))
          );
        } finally {
          hideLoader();
        }
      };
      fetchData();
    }
  }, [hideLoader, showLoader, userId]);

  const close = () => {
    setLessons([]);
    closeModal();
  };

  const { formatMessage } = intl;
  return (
    <Modal
      open={open}
      onClose={close}
      large
      saveButton={false}
      title={<FormattedMessage id="COMMON.HISTORY" />}>
      <TableContainer
        list={lessons || []}
        columns={[
          { key: 'lessonName', label: formatMessage({ id: 'LESSONS.NAME' }) },
          { key: 'level', label: formatMessage({ id: 'TEMPLATES.LEVEL' }) },
          { key: 'receivedXP', label: formatMessage({ id: 'LESSONS.RECEIVED_XP' }) },
          { key: 'result', label: formatMessage({ id: 'LESSONS.RESULT' }) },
          { key: 'testDone', label: formatMessage({ id: 'LESSONS.TEST_DONE' }) },
          { key: 'testSuccess', label: formatMessage({ id: 'LESSONS.TEST_SUCCESSFUL' }) },
        ]}
      />
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

UserHistory.propTypes = {
  closeModal: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  userId: PropTypes.number,
  open: PropTypes.bool,
};

export default injectIntl(connect(null, mapDispatchToProps)(UserHistory));
