import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import User from '../User/User';
import BarChart from '../BarChart';
import { lessonsStatus } from 'libs/lessonsStatus';

import sandGlass from 'assets/images/sand-glass.png';
import check from 'assets/images/check.png';
import close from 'assets/images/close.png';
import './style.scss';

class UserModalContent extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = {
    openCourseId: -1,
    courses: [],
  };

  componentDidMount() {
    this.setState({
      courses: (this.props.data?.courses || []).map(() => ({ ...{}, isOpen: false })),
    });
  }

  toggle = (index) => {
    const courses = [...this.state.courses];
    courses[index].isOpen = !courses[index].isOpen;
    this.setState({ courses });
  };

  render() {
    const { data } = this.props;

    return (
      <div className="UserModalContent row m-0">
        <div className="col-12 p-0">
          <User
            user={{ avatar: data.avatar, firstName: data.firstName, lastName: data.lastName }}
          />
        </div>
        <div className="col-12 p-0">
          {data.courses &&
            data.courses.map(({ title, lessons, passedLessonCount, id, exam }, index) => {
              const isOpen = this.state.courses[index] && this.state.courses[index].isOpen;
              return (
                <div key={id} className="courses-container">
                  <div className="col-12 d-flex align-items-center justify-content-between courses">
                    <span className="course-title">{title}</span>
                    <div className="d-flex">
                      {!isOpen ? (
                        <div
                          className={classNames('course-status me-4', { success: exam.success })}>
                          {exam.success ? (
                            <FormattedMessage id="DASHBOARD.SUCCESSFUL_EXAM" />
                          ) : (
                            <FormattedMessage id="DASHBOARD.FAILED_EXAM" />
                          )}
                          <span className="ms-2">{exam.result}%</span>
                        </div>
                      ) : null}
                      <FormattedMessage id="DASHBOARD.TESTED" />:
                      <span className="ms-2 me-2">
                        {passedLessonCount}/{lessons.length}
                      </span>
                      {!isOpen ? (
                        <i className="lemon icon-arrow-down" onClick={() => this.toggle(index)} />
                      ) : (
                        <i className="lemon icon-arrow-up" onClick={() => this.toggle(index)} />
                      )}
                    </div>
                  </div>
                  <div className={classNames('lessons-container', { open: isOpen })}>
                    {lessons &&
                      lessons.map(
                        ({ title: lessonTitle, result: lessonResult, passed, status, id }) => (
                          <div
                            className="col-12 d-flex align-items-center justify-content-between lessons"
                            key={id}>
                            <span className="lesson-title"> {lessonTitle}</span>
                            <div className="d-flex">
                              {status === lessonsStatus.PASSED ? (
                                <span className="me-3">{lessonResult}%</span>
                              ) : null}
                              {status === lessonsStatus.NOT_STARTED ? (
                                <img src={sandGlass} alt="sand-glass" />
                              ) : status === lessonsStatus.PASSED && passed ? (
                                <img src={check} alt="check" />
                              ) : status === lessonsStatus.STARTED && !passed ? (
                                <img src={close} alt="close" />
                              ) : null}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="chart-container col-12 p-0">
          <div className="intervals">
            {data.activities && data.activities.length > 0
              ? `${data.activities[0].date} - ${data.activities[data.activities.length - 1].date}`
              : ''}
          </div>
          <BarChart data={data.activities} />
        </div>
      </div>
    );
  }
}

export default UserModalContent;
