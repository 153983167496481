import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const GuideText = ({ className, children }) => {
  return <div className={classNames('guide-text', className)}>{children}</div>;
};

GuideText.propTypes = { className: PropTypes.string, children: PropTypes.node };

export default GuideText;
