import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import InitialRouting from './InitialRouting';
import { getToken } from '../../libs/refreshToken';

const mapStateToProps = (state) => {
  return {
    token: getToken(),
    tokenError: state.users.token.error,
  };
};

export default withRouter(connect(mapStateToProps)(InitialRouting));
