import qs from 'query-string';

import request from '../libs/request';
import { Methods } from '../libs/http';
import Settings from '../config/settings';

export const BASE_URL = '/templates';

export const getTemplates = async (params, subsidiaryId) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}${params || subsidiaryId ? '?' : ''}${qs.stringify(
      params,
      Settings.QUERY_FORMAT
    )}${subsidiaryId ? `${params ? '&' : ''}subsidiaryId=${subsidiaryId}` : ''}`,
  });
};

export const createTemplate = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}`,
    data,
  });
};

export const editTemplate = async (id, data) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}`,
    data,
  });
};

export const deleteTemplate = async (id) => {
  return await request({
    method: Methods.DELETE,
    resource: `${BASE_URL}/${id}`,
  });
};
