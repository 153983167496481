import get from 'lodash/get';

export const isSubmitted = (form) => {
  return Boolean(form && form.submitCount);
};

export const isTouched = (form, field) => {
  return Boolean(form && field && get(form.touched, field.name)) || isSubmitted(form);
};

export const getErrorMessage = (form, field) => {
  return form && field ? get(form.errors, field.name) || '' : '';
};
