import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/Overlay';
import BootstrapPopover from 'react-bootstrap/Popover';

import {
  popoverMouseEvents,
  shouldShowTooltip,
  hasTriggerType,
  getTitle,
  getContent,
  getPopperConfig,
} from './popoverUtil';

import './style.scss';

const Popover = ({
  popoverId = 'popover',
  oneLine,
  className,
  transition = true,
  container,
  delay = 0,
  placement = 'top',
  onOverflow,
  trigger = popoverMouseEvents.CLICK,
  offset = [0, 10],
  title,
  overflowContent,
  content,
  always,
  children,
  onMouseEnter,
  onMouseLeave,
  onMouseClick,
}) => {
  const [show, setShow] = useState(false);
  const [showWithDelay, setShowWithDelay] = useState(false);
  const ref = useRef(null);
  const popperConfig = useMemo(() => getPopperConfig(offset), [offset]);
  const { CLICK, HOVER } = popoverMouseEvents;

  useEffect(() => {
    let timer = setTimeout(() => {
      setShow(showWithDelay);
    }, delay);

    return () => clearTimeout(timer);
  }, [showWithDelay, delay]);

  const handleSetShow = useCallback(
    (show) => {
      const showTooltip = show
        ? shouldShowTooltip({ always, onOverflow, element: ref?.current })
        : show;
      if (delay) {
        setShowWithDelay(showTooltip);
      } else {
        setShow(showTooltip);
      }
    },
    [always, delay, onOverflow]
  );

  const handleEvent = useCallback(
    ({ actionType: eventType, show }) => {
      if (hasTriggerType({ currentTrigger: eventType, triggers: trigger })) {
        handleSetShow(show);
      }
    },
    [trigger, handleSetShow]
  );

  const handleMouseEnter = (e) => {
    handleEvent({ actionType: HOVER, show: true });
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  };

  const handleMouseLeave = (e) => {
    handleEvent({ actionType: HOVER, show: false });
    if (onMouseEnter) {
      onMouseLeave(e);
    }
  };

  const handleMouseClick = (e) => {
    handleEvent({ actionType: CLICK, show: !show });
    if (onMouseClick) {
      onMouseClick(e);
    }
  };

  return (
    <span
      className={classNames(
        'popover-wrapper',
        oneLine ? 'd-block one-line' : 'd-inline-block',
        className
      )}
      ref={ref}
      onClick={handleMouseClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {children}
      <Overlay
        target={ref}
        show={show}
        placement={placement}
        transition={transition}
        container={container}
        popperConfig={popperConfig}>
        <BootstrapPopover id={popoverId}>
          {getTitle(title) && <BootstrapPopover.Header>{getTitle(title)}</BootstrapPopover.Header>}
          <BootstrapPopover.Body>
            {getContent({ element: ref?.current, content, overflowContent })}
          </BootstrapPopover.Body>
        </BootstrapPopover>
      </Overlay>
    </span>
  );
};

Popover.propTypes = {
  popoverId: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
  children: PropTypes.node.isRequired,
  overflowContent: PropTypes.node,
  oneLine: PropTypes.bool,
  onOverflow: PropTypes.bool,
  always: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  transition: PropTypes.bool,
  container: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseClick: PropTypes.func,
  delay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
  ]),
  html: PropTypes.bool,
  placement: PropTypes.oneOfType([
    PropTypes.oneOf(['auto', 'top', 'bottom', 'left', 'right']),
    PropTypes.func,
  ]),
  trigger: PropTypes.oneOfType([
    PropTypes.oneOf([popoverMouseEvents.CLICK, popoverMouseEvents.HOVER]),
    PropTypes.arrayOf(PropTypes.oneOf([popoverMouseEvents.CLICK, popoverMouseEvents.HOVER])),
  ]),
  offset: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
    PropTypes.func,
  ]),
  boundary: PropTypes.string,
  template: PropTypes.string,
};

export default Popover;
