import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TableCell, Popover } from 'components';
import ArrowSortIndicator from '../SortIndicators/ArrowSortIndicator';
import CaretSortIndicator from '../SortIndicators/CaretSortIndicator';
import { sortIndicatorTypes } from '../tableConstants';

import './style.scss';

const SortableTableCell = ({
  indicatorType = sortIndicatorTypes.arrow,
  className,
  isActive,
  reverse,
  children,
  ...props
}) => {
  const sortIndicator =
    indicatorType === sortIndicatorTypes.arrow ? (
      <ArrowSortIndicator isActive={isActive} reverse={reverse} />
    ) : (
      <CaretSortIndicator isActive={isActive} reverse={reverse} />
    );

  const sortLabel = (
    <Popover content={children} onOverflow oneLine trigger="hover">
      {children}
    </Popover>
  );

  return (
    <TableCell
      hasTooltip={false}
      className={classNames('basic-table-sortable-cell', className)}
      tooltipConfig={{ content: children }}
      {...props}>
      <div className="basic-table-sortable-cell__content-container">
        <div className="basic-table-sortable-cell__label">{sortLabel}</div>
        <div className="basic-table-sortable-cell__indicator">{sortIndicator}</div>
      </div>
    </TableCell>
  );
};

SortableTableCell.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  reverse: PropTypes.bool,
  indicatorType: PropTypes.oneOf(Object.values(sortIndicatorTypes)),
  children: PropTypes.node,
};

export default SortableTableCell;
