import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TableFilter, FilterModal } from 'components';

import PreHeaderAdditionalItem from './PreHeaderAdditionalItem';

const PagePreHeader = ({
  count,
  filterSubmit,
  filterData,
  filterBySearchText,
  indicators,
  additionalPreHeaderIconContainerItem,
  additionalFilters,
  searchFieldCentered,
  iconContainerClassName,
  total,
  params,
  loading,
  setCurrentParams,
  showLoader,
  hideLoader,
}) => {
  return (
    <div className="basic-page-pre-header">
      <div className="basic-page-pre-header__indicator"> {!!indicators && indicators}</div>

      <div
        className={
          iconContainerClassName ? iconContainerClassName : 'basic-page-pre-header__icons-container'
        }>
        {searchFieldCentered && (
          <PreHeaderAdditionalItem
            additionalPreHeaderIconContainerItem={additionalPreHeaderIconContainerItem}
            className="basic-page-pre-header__additional-item-left"
            hide
          />
        )}
        {filterBySearchText && (
          <div
            className={classNames('basic-page-pre-header__search-container', {
              'basic-page-pre-header__search-container--centered': searchFieldCentered,
            })}>
            <TableFilter
              filter={params.searchText}
              loadList={
                setCurrentParams
                  ? (searchText, page) => {
                      if (searchText !== params?.searchText) {
                        setCurrentParams({ ...params, page, searchText });
                      }
                    }
                  : undefined
              }
            />
          </div>
        )}
        {additionalFilters && additionalFilters(setCurrentParams)}
        {filterSubmit && (
          <FilterModal
            largeModal={true}
            hideLoader={hideLoader}
            showLoader={showLoader}
            filterSubmit={filterSubmit}
            count={count}
            total={total}
            isLoading={loading}
            filterData={filterData}
          />
        )}
        <PreHeaderAdditionalItem
          additionalPreHeaderIconContainerItem={additionalPreHeaderIconContainerItem}
          className="basic-page-pre-header__additional-item-right"
        />
      </div>
    </div>
  );
};

PagePreHeader.propTypes = {
  count: PropTypes.number,
  filterSubmit: PropTypes.func,
  filterData: PropTypes.array,
  indicators: PropTypes.node,
  filterBySearchText: PropTypes.bool,
  additionalPreHeaderIconContainerItem: PropTypes.node,
  additionalFilters: PropTypes.func,
  searchFieldCentered: PropTypes.bool,
  iconContainerClassName: PropTypes.string,
  total: PropTypes.number,
  params: PropTypes.object,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setCurrentParams: PropTypes.func,
};

export default PagePreHeader;
