import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { STATUS } from 'libs/http';
import { resetApiError } from 'actions/common';
import { Modal } from 'components';

const OnServerError = ({ shadowed = true, errors, resetApiError, additionalStatuses }) => {
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const defaultStatuses = [STATUS.UNPROCESSABLE_ENTITY, STATUS.BAD_REQUEST];

  const allStatuses = defaultStatuses.concat(additionalStatuses);

  useEffect(() => {
    if (errors) {
      if (allStatuses.filter((status) => status === errors.status).length) {
        setOpenErrorModal(true);
      }
    } else {
      setOpenErrorModal(false);
    }
  }, [errors, allStatuses]);

  const closeModal = () => {
    if (errors) {
      resetApiError();
    }
  };

  const getErrorMessage = (errors) => {
    const errorRoot =
      errors && errors.data
        ? errors.data.errors
          ? errors.data.errors
          : errors.data.error
          ? errors.data.error
          : errors.data
        : null;
    return errorRoot ? (
      Array.isArray(errorRoot) ? (
        <ul>
          {errorRoot.map((errorMessage) => (
            <li key={uuidv4()}>{errorMessage}</li>
          ))}
        </ul>
      ) : typeof errorRoot === 'string' ? (
        errorRoot
      ) : (
        <FormattedMessage id="COMMON.HAS_ERRORS" />
      )
    ) : (
      <FormattedMessage id="COMMON.HAS_ERRORS" />
    );
  };

  return (
    <Modal
      preventDismiss
      onClose={closeModal}
      shadowed={shadowed}
      className="error-modal"
      open={openErrorModal}
      title={<FormattedMessage id="COMMON.ERROR" />}
      saveButton={true}
      saveAction={closeModal}
      saveTitle={<FormattedMessage id="COMMON.OK" />}>
      <div className="default-modal-body-padding">{getErrorMessage(errors)}</div>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {
    errors: state.common.apiError.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetApiError,
    },
    dispatch
  );
};

OnServerError.propTypes = {
  shadowed: PropTypes.bool,
  errors: PropTypes.object,
  resetApiError: PropTypes.func,
  additionalStatuses: PropTypes.arrayOf(PropTypes.number),
};

export default connect(mapStateToProps, mapDispatchToProps)(OnServerError);
