import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';

import {
  Switcher,
  TextAreaField,
  TextField,
  SelectField,
  UpdateForm,
  ImageFileUploadField,
} from 'components';
import LessonsSpecificsTable from 'containers/Lessons/LessonsSpecificsTable/LessonsSpecificsTable';
import { auditDecisionEdit } from 'libs/auditDecision';
import { Yup } from 'util/Yup';
import { createShallowMergedObject } from 'util/objectUtil';
import { getUrlfromImageObject } from 'util/imagesMapper';
import { getMergedTagOptions } from 'util/getCreateableTagOptions';
import { convertSpecificsToSpecificsFormData } from 'util/lessonSpecificMapper';

const assignLessonOrSubmitRequired = (props, propName, componentName) => {
  if (props.hasRoleToDecision && !props.submit) {
    return new Error(
      `If 'hasRoleToDecision' is true then '${propName}' is required in '${componentName}'.`
    );
  } else if (!props.hasRoleToDecision && !props.assignLesson) {
    return new Error(
      `If 'hasRoleToDecision' is false or is not given then '${propName}' is required in '${componentName}'.`
    );
  }
};

const getInitialValues = ({ initialValues, lesson }) => ({
  ...createShallowMergedObject(initialValues, lesson),
  tags: (lesson?.tags || []).map(({ label, value }) => ({ id: value, title: label })),
  specifics: convertSpecificsToSpecificsFormData(lesson.specifics),
});

const initialValues = {
  title: '',
  public: false,
  tags: [],
  images: null,
  specifics: [],
};

const LessonForm = ({
  onSaved,
  lesson,
  closeEditor,
  assignLesson,
  submit,
  open,
  disabled,
  hasRoleToDecision,
  intl: { formatMessage },
  saveButton,
}) => {
  const basicValidationSchema = {
    title: Yup.string().max(255).required(),
    images: Yup.mixed().nullable(),
    tags: Yup.array(),
    public: Yup.boolean(),
    specifics: Yup.array(),
  };

  const validationSchema = hasRoleToDecision
    ? Yup.object().shape({
        ...basicValidationSchema,
        decision: Yup.string().required(),
        auditorDescription: Yup.string().required(),
      })
    : Yup.object().shape({ ...basicValidationSchema });

  const saveTitle = hasRoleToDecision ? (
    <FormattedMessage id="LESSONS.VALIDATE" />
  ) : (
    <FormattedMessage id="LESSONS.ASSIGN_TO_ME" />
  );

  const assignLessonFunc = () => {
    assignLesson(lesson.id);
  };

  const update = hasRoleToDecision ? submit : assignLessonFunc;
  const submitWithoutValidate = hasRoleToDecision ? submit : undefined;

  return (
    <UpdateForm
      onSaved={onSaved}
      open={open}
      closeEditor={closeEditor}
      update={update}
      submitWithoutValidate={submitWithoutValidate}
      modalTitle={<FormattedMessage id="LESSONS.LESSON_DETAILS" />}
      initialValues={getInitialValues({ initialValues: initialValues, lesson })}
      validateOnBlur={false}
      saveTitle={saveTitle}
      saveSecondaryTitle={hasRoleToDecision && formatMessage({ id: 'LESSONS.DETACH' })}
      saveButtonSecondary={hasRoleToDecision}
      saveButton={saveButton}
      validationSchema={validationSchema}>
      {({ errors, values }) => {
        return (
          <Form>
            <Field
              name="title"
              component={TextField}
              label={formatMessage({ id: 'COMMON.TITLE' })}
              disabled
            />

            <Field
              name="images"
              component={ImageFileUploadField}
              getFileUrl={getUrlfromImageObject}
              multi
              disabled
              disabledClick
            />

            <Field
              name="tags"
              component={SelectField}
              useOnlyValueAsFieldValue
              label={formatMessage({ id: 'LESSONS.LESSON_TAGS_REQ' })}
              multi
              disabled
              options={getMergedTagOptions({
                createdTags: values.tags,
              })}
            />

            <Field
              name="public"
              component={Switcher}
              warning={formatMessage({ id: 'LESSONS.PUBLICITY_WARNING' })}
              showWarning={values.canBePrivate || !values.id}
              disabled
              onText="Public"
              offText="Private"
            />

            <LessonsSpecificsTable
              specifics={values.specifics}
              disabledForm
              disabled
              errors={errors}
            />

            {hasRoleToDecision && (
              <>
                <Field
                  name="decision"
                  component={SelectField}
                  useOnlyValueAsFieldValue
                  label={formatMessage({ id: 'LESSONS.DECISION_REQ' })}
                  disabled={disabled}
                  options={auditDecisionEdit.map((label) => ({ label, value: label }))}
                />
                <Field
                  name="auditorDescription"
                  component={TextAreaField}
                  label={formatMessage({ id: 'LESSONS.DESCRIPTION_EXTENDED_REQ' })}
                  disabled={disabled}
                  options={auditDecisionEdit.map((label) => ({ label, value: label }))}
                />
              </>
            )}
          </Form>
        );
      }}
    </UpdateForm>
  );
};

LessonForm.propTypes = {
  onSaved: PropTypes.func,
  lesson: PropTypes.object.isRequired,
  closeEditor: PropTypes.func.isRequired,
  assignLesson: assignLessonOrSubmitRequired,
  submit: assignLessonOrSubmitRequired,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  hasRoleToDecision: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  saveButton: PropTypes.bool,
};

export default withRouter(injectIntl(LessonForm));
