import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import { TableContainer, EditOrReadControl, DeleteControl } from 'components';
import { widthOptions, horizontalAlignOptions } from 'components/Table/tableConstants';
import Settings from 'config/settings';
import { PathAccess } from 'libs/accessManagement';

const PathTable = ({
  profile,
  list = [],
  openEditor,
  openAssign,
  openDeleteModal,
  loadList,
  params,
  loading,
  intl: { formatMessage },
}) => {
  const { hasRoleToEdit, hasRoleToDelete, hasRoleToAssignUserOrGroup } = PathAccess.getHasRoles(
    profile.profile
  );

  return (
    <TableContainer
      loading={loading}
      columns={[
        {
          key: 'edit',
          hide: !hasRoleToEdit,
          width: widthOptions.xs,
          render: (path) => (
            <EditOrReadControl
              canEdit={PathAccess.getHasAccess(profile.profile, path).canEdit}
              isEnabled={PathAccess.getHasAccess(profile.profile, path).canEdit}
              openEditModal={() => openEditor(path)}
            />
          ),
        },
        {
          key: 'title',
          minWidth: widthOptions.md,
          maxWidth: widthOptions.lg,
          label: formatMessage({ id: 'COMMON.TITLE' }),
          multiLine: true,
          sortBy: Settings.SORT_BY.TITLE,
        },
        {
          key: 'owner',
          width: widthOptions.md,
          label: formatMessage({ id: 'LESSONS.SUBSIDIARY' }),
          sortBy: Settings.SORT_BY.SUBSIDIARY,
        },
        {
          key: 'lessonCount',
          width: widthOptions.md,
          label: formatMessage({ id: 'LESSONS.LESSONS_COUNT' }),
          horizontalAlign: horizontalAlignOptions.center,
          sortBy: Settings.SORT_BY.PATH_LESSON_COUNT,
        },
        {
          key: 'public_private',
          width: widthOptions.sm,
          label: formatMessage({ id: 'COMMON.PUBLIC_PRIVATE' }),
          render: (path) =>
            path.isPublic
              ? formatMessage({ id: 'COMMON.PUBLIC' })
              : formatMessage({ id: 'COMMON.PRIVATE' }),
        },
        {
          key: 'assign',
          minWidth: widthOptions.sm,
          hide: !hasRoleToAssignUserOrGroup,
          label: formatMessage({ id: 'LESSONS.ASSIGN' }),
          iconCell: true,
          iconClass: 'lemon icon-info dark-grey',
          onClick: (path) => openAssign(path),
        },
        {
          key: 'delete',
          hide: !hasRoleToDelete,
          render: (path) => (
            <DeleteControl
              show={PathAccess.getHasAccess(profile.profile, path).canDelete}
              enabled={PathAccess.getHasAccess(profile.profile, path).canDelete}
              item={path}
              deleteItem={() => openDeleteModal(path)}
            />
          ),
        },
      ]}
      list={list}
      loadList={loadList}
      params={params}
    />
  );
};

PathTable.propTypes = {
  loading: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object),
  profile: PropTypes.object.isRequired,
  loadList: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openEditor: PropTypes.func.isRequired,
  openAssign: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default withPagination(injectIntl(PathTable));
