import React from 'react';
import PropTypes from 'prop-types';

import { statisticsOverview } from 'common/propTypes';
import { BaseStatisticsComponent } from 'components';
import { withPagination } from 'hoc';

const StatisticsOverviewContainer = ({ statisticsOverviewData, getKey }) => {
  return (
    <div>
      {statisticsOverviewData &&
        statisticsOverviewData.map((overviewData) => (
          <div key={getKey(overviewData)}>
            <BaseStatisticsComponent baseData={overviewData} type={overviewData.type} />
          </div>
        ))}
    </div>
  );
};

StatisticsOverviewContainer.propTypes = {
  statisticsOverviewData: PropTypes.arrayOf(statisticsOverview),
  getKey: PropTypes.func.isRequired,
};

export default withPagination(StatisticsOverviewContainer);
