export const auditDecision = {
  UNASSIGNED: { id: 0, label: 'Unassigned' },
  ASSIGNED: { id: 1, label: 'Assigned' },
  ABANDON: { id: 2, label: 'Abandon' },
  VALIDATED: { id: 3, label: 'Validated', key: 'Accept' },
  REJECTED: { id: 4, label: 'Rejected', key: 'Reject' },
  CHANGED: { id: 5, label: 'Changed' },
};

export const auditDecisionEdit = ['', 'Accept', 'Reject'];
