import { Yup } from 'util/Yup';
import { getValidationMessageInput } from './validation';

export const getMessageForMinItemsError = (min) =>
  min === 1
    ? getValidationMessageInput('COMMON.FIELD_MIN_ITEMS_COUNT_ONE')
    : getValidationMessageInput('COMMON.FIELD_MIN_ITEMS_COUNT', { min });

export const getMessageForImageMinError = (min) =>
  min === 1
    ? getValidationMessageInput('COMMON.FIELD_IMAGE_REQUIRED')
    : getValidationMessageInput('COMMON.FIELD_MIN_IMAGES_COUNT', { min });

export const getMessageForImageMaxError = (max) =>
  getValidationMessageInput('COMMON.FIELD_MAX_IMAGES_COUNT', { max });

export const getSelectOptionShape = (valueShape = Yup.string().required()) =>
  Yup.object().shape({
    label: Yup.string().required(),
    value: valueShape,
  });
