import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import parseISO from 'date-fns/parseISO';

import { TableContainer } from 'components';
import { horizontalAlignOptions } from 'components/Table/tableConstants';
import { subscriptionStatus } from 'libs/subscriptionStatus';
import { getFormattedDate } from 'util/timeUtil';

const getStatusAsString = (subscription = {}) => {
  const status = Object.values(subscriptionStatus).find(({ id }) => id === subscription.state);
  return status ? status.label : '';
};

const getSubscriptionTitle = (subscription = {}) => {
  const { subscription: subscriptionDetail } = subscription;
  return subscriptionDetail ? subscriptionDetail.title : '';
};

const getDateAsString = (date) => (date ? getFormattedDate(parseISO(date)) : '');

const getCreatedAtAsString = (subscription = {}) => getDateAsString(subscription.created_at);

const getDeadlineAsString = (subscription = {}) => getDateAsString(subscription.deadline);

const getActualContent = (_, rowIndex) => {
  return rowIndex === 0 ? <i className="lemon icon-check" /> : null;
};

const CompanySubsciptionTable = ({ list, intl: { formatMessage } }) => {
  return (
    <TableContainer
      list={list}
      columns={[
        {
          key: 'status',
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'COMPANIES.STATUS' }),
          render: (subscription) => getStatusAsString(subscription),
        },
        {
          key: 'title',
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'COMPANIES.SUBSCRIPTION' }),
          render: (subscription) => getSubscriptionTitle(subscription),
        },
        {
          key: 'created_date',
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'COMPANIES.CREATED_DATE' }),
          render: (subscription) => getCreatedAtAsString(subscription),
        },
        {
          key: 'deadline',
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'COMPANIES.DEADLINE' }),
          render: (subscription) => getDeadlineAsString(subscription),
        },
        {
          key: 'actual',
          label: formatMessage({ id: 'COMPANIES.ACTUAL' }),
          render: (subscription, rowIndex) => getActualContent(subscription, rowIndex),
        },
      ]}
    />
  );
};

CompanySubsciptionTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  intl: PropTypes.object,
};

export default injectIntl(CompanySubsciptionTable);
