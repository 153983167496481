import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { ChartDetailContainer, IconChartDetailItem } from 'components';

const CompanySubsidiaryChartDetailContainer = ({ titleLabel, titleValue, details }) => {
  return (
    <ChartDetailContainer titleLabel={titleLabel} titleValue={titleValue}>
      {(details || []).map((detail) => (
        <div key={uuidv4()}>
          <IconChartDetailItem {...detail} />
        </div>
      ))}
    </ChartDetailContainer>
  );
};

CompanySubsidiaryChartDetailContainer.propTypes = {
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  details: PropTypes.arrayOf(
    PropTypes.shape({
      firstIcon: PropTypes.node,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      iconColor: PropTypes.string,
      lastIconClick: PropTypes.func,
      noDivider: PropTypes.bool,
    })
  ),
};

export default CompanySubsidiaryChartDetailContainer;
