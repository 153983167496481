import { templateLevel } from 'libs/template';
import { testTypes } from 'libs/testTypes';
import { getValidationMessageInput } from 'libs/validation/validation';
import { getRequestDataForImage } from 'util/imagesMapper';
import { Yup } from 'util/Yup';
import Settings from 'config/settings';
import { isBoolean } from 'lodash';

const timeLimit = { MIN: 15, MAX: 300 };
const maxQuestionLength = 255;
const maxAnswerLength = { type0: 50, type1: 10 };
const maxLengthErrorTranslateKey = 'COMMON.FIELD_MAX_LENGTH_ERROR';

const getDefaultSecByLevel = (level) => {
  switch (level) {
    case templateLevel.BEGINNER.id:
      return templateLevel.BEGINNER.sec;
    case templateLevel.ADVANCED.id:
      return templateLevel.ADVANCED.sec;
    case templateLevel.INTERMEDIATE.id:
      return templateLevel.INTERMEDIATE.sec;
    default:
      return templateLevel.BEGINNER.sec;
  }
};

const getAnswer = () => {
  return { answer: '', isGood: true };
};

export const mapTestFormDataToRequestData = (testForm) => {
  const test = { ...testForm };

  if (test && test.type === testTypes.PUZZLE_TYPE) {
    test.answers = [{ ...test.answer, isGood: true }];
  }

  return test;
};

export const preloadSaveData = async ({ values, testLevelId }) => {
  const { type, image, level, answer, answers, ...remainingValues } = values;

  return {
    ...remainingValues,
    type,
    level: level > -1 ? level : testLevelId,
    image: await getRequestDataForImage({
      image,
      getDataForExistingImage: (image) => image?.url,
    }),
    answers: type === testTypes.PUZZLE_TYPE ? [{ ...answer, isGood: true }] : answers,
  };
};

const oneOfAnswerIsGood = (formatMessage) =>
  Yup.array().test('oneOfIsGood', formatMessage({ id: 'TESTS.ANSWERS_REQUIREMENT' }), (value) => {
    return value.filter(({ isGood }) => isGood).length > 0;
  });

export const getTestFormSchema = (formatMessage) =>
  Yup.object().shape({
    type: Yup.string().required(),
    image: Yup.mixed().nullable(),
    question: Yup.string()
      .max(
        maxQuestionLength,
        getValidationMessageInput(maxLengthErrorTranslateKey, { max: maxQuestionLength })
      )
      .required(),
    answer: Yup.object().when('type', {
      is: (val) => {
        return +val === +Settings.QUESTION_TYPES[1].id;
      },
      then: Yup.object().shape({
        answer: Yup.string()
          .max(
            maxAnswerLength.type1,
            getValidationMessageInput(maxLengthErrorTranslateKey, {
              max: maxAnswerLength.type1,
            })
          )
          .required(),
      }),
      otherwise: Yup.object(),
    }),
    answers: oneOfAnswerIsGood(formatMessage).when('type', {
      is: (val) => {
        return +val === +Settings.QUESTION_TYPES[0].id;
      },
      then: Yup.array().of(
        Yup.object().shape({
          answer: Yup.string()
            .max(
              maxAnswerLength.type0,
              getValidationMessageInput(maxLengthErrorTranslateKey, {
                max: maxAnswerLength.type0,
              })
            )
            .required(),
          isGood: Yup.boolean(),
        })
      ),
      otherwise: Yup.array(),
    }),
    isExamQuestion: Yup.boolean(),
    isTestQuestion: Yup.boolean(),
    sec: Yup.number()
      .typeError(getValidationMessageInput('COMMON.FIELD_NUMBER_ERROR'))
      .min(
        timeLimit.MIN,
        getValidationMessageInput('COMMON.FIELD_MIN_NUMBER_ERROR', { min: timeLimit.MIN })
      )
      .max(
        timeLimit.MAX,
        getValidationMessageInput('COMMON.FIELD_MAX_NUMBER_ERROR', { max: timeLimit.MAX })
      ),
  });

export const getInitialValues = (test) => {
  const {
    type,
    image,
    question,
    isExamQuestion,
    isTestQuestion,
    level,
    sec,
    answers,
    ...remainingProps
  } = test || {};

  return {
    type: type || testTypes.MULTIPLE_TYPE,
    question: question || '',
    isExamQuestion: isBoolean(isExamQuestion) ? isExamQuestion : true,
    isTestQuestion: isBoolean(isTestQuestion) ? isTestQuestion : true,
    sec: sec || getDefaultSecByLevel(level),
    level: level || templateLevel.BEGINNER.id,
    image: image ? { url: image } : null,
    answer: answers?.length ? answers[0] : getAnswer(),
    answers: answers?.length ? answers : [getAnswer(), getAnswer(), getAnswer(), getAnswer()],
    ...remainingProps,
  };
};
