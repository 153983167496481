import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from 'formik';

import { path } from 'common';
import { UpdateForm, AssignGroupsAndUsers } from 'components';
import { Yup } from 'util/Yup';
import { addPathAssignsList, getPathAssignsList } from 'services/path';
import { mapToAssignedUsers, filterUsersAssignedOnlyAsUser } from 'util/assignUserAndGroupHelper';
import { usersMapper } from 'util/transferMapper';

const AssignPath = ({ open, close, path }) => {
  const [initialValues, setInitialValues] = useState({ users: [], groups: [] });

  const fetchAssign = useCallback(async () => {
    const res = await getPathAssignsList(path.id);
    setInitialValues({
      users: mapToAssignedUsers(usersMapper(res), res.groups),
      groups: res.groups,
    });
  }, [path]);

  const create = async (values) => {
    const users = filterUsersAssignedOnlyAsUser(values.users).map(({ id }) => id);
    const groups = values.groups.map((group) => group.id);
    await addPathAssignsList({ users, groups }, path.id);
  };

  useEffect(() => {
    if (open) {
      fetchAssign();
    }
  }, [fetchAssign, open]);

  return (
    <UpdateForm
      open={open}
      closeEditor={close}
      create={create}
      modalTitle={<FormattedMessage id="LESSONS.ASSIGN_LESSON_TO_USERS" />}
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        users: Yup.array(),
        groups: Yup.array(),
      })}>
      {({ values, setFieldValue }) => (
        <Form>
          <AssignGroupsAndUsers values={values} fetchData setFieldValue={setFieldValue} />
        </Form>
      )}
    </UpdateForm>
  );
};

AssignPath.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  path,
};

export default injectIntl(AssignPath);
