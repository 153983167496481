import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import PageHeader from './PageHeader';

import { showLoader, hideLoader, openSideBar, closeSideBar } from 'actions/common';
import { logout } from 'actions/users';

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile.profile,
    sideBar: state.common.sideBar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showLoader, hideLoader, openSideBar, closeSideBar, logout },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageHeader));
