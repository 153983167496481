import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';

import { CheckBoxField, SelectField, TextField } from 'components';
import Settings from 'config/settings';

import {
  templateLevel,
  templateTypes,
  notAllowedToGeneral,
  notAllowedToGenerate,
  notAllowedToShow,
  notAllowedToSearch,
} from '../../libs/template';

const setTypeRelatedValues = (type, setFieldValue) => {
  if (type === templateTypes.IMAGE.id || type === templateTypes.VIDEO.id) {
    setFieldValue('type', type);
    setFieldValue('isGenerate', false);
    setFieldValue('isShow', false);
    setFieldValue('isGeneral', false);
    setFieldValue('isSearchable', false);
    setFieldValue('question', '');
  }
};

const setIsGenerateRelatedValues = (isGenerate, setFieldValue) => {
  if (!isGenerate) {
    setFieldValue('question', '');
  }
};

const SpecificsFormContent = ({ disabled, intl: { formatMessage } }) => {
  const { values, setFieldValue } = useFormikContext();
  const typeValue = values?.type;
  const isGenerateValue = values?.isGenerate;

  useEffect(() => {
    setTypeRelatedValues(typeValue, setFieldValue);
  }, [typeValue, setFieldValue]);

  useEffect(() => {
    setIsGenerateRelatedValues(isGenerateValue, setFieldValue);
  }, [isGenerateValue, setFieldValue]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Field
            name="key"
            component={TextField}
            label={<FormattedMessage id="TEMPLATES.KEY_REQ" />}
          />
        </div>
        <div className="col-6">
          <Field
            name="type"
            component={SelectField}
            useOnlyValueAsFieldValue
            label={<FormattedMessage id="TEMPLATES.TYPE_REQ" />}
            options={
              templateTypes
                ? Object.values(templateTypes).map(({ id, label }) => ({
                    label: label,
                    value: id,
                  }))
                : []
            }
          />
        </div>
        <div className="col-6">
          <Field
            name="level"
            component={SelectField}
            useOnlyValueAsFieldValue
            label={<FormattedMessage id="TEMPLATES.LEVEL_REQ" />}
            options={
              templateLevel
                ? Object.values(templateLevel).map(({ id, label }) => ({
                    label: label,
                    value: id,
                  }))
                : []
            }
          />
        </div>
        <div className="col-3">
          <Field
            name="isSearchable"
            component={CheckBoxField}
            fullWidth
            label={formatMessage({ id: 'TEMPLATES.SEARCHABLE' })}
            disabled={disabled || notAllowedToSearch(values.type)}
          />
        </div>
        <div className="col-3">
          <Field
            name="isGenerate"
            component={CheckBoxField}
            fullWidth
            label={`${formatMessage({ id: 'TEMPLATES.IS_GENERATE_G' })}${formatMessage({
              id: 'TEMPLATES.IS_GENERATE',
            })}`}
            disabled={disabled || notAllowedToGenerate(values.type)}
          />
        </div>
        <div className="col-3">
          <Field
            name="isShow"
            component={CheckBoxField}
            fullWidth
            label={`${formatMessage({ id: 'TEMPLATES.IS_SHOW_S' })}${formatMessage({
              id: 'TEMPLATES.IS_SHOW',
            })}`}
            disabled={disabled || notAllowedToShow(values.type)}
          />
        </div>
        <div className="col-3">
          <Field
            name="isGeneral"
            component={CheckBoxField}
            fullWidth
            label={`${formatMessage({ id: 'TEMPLATES.IS_GENERAL_A' })}${formatMessage({
              id: 'TEMPLATES.IS_GENERAL',
            })}`}
            disabled={disabled || notAllowedToGeneral(values.type)}
          />
        </div>
        {values.isGenerate && (
          <div className="col-12 pt-4">
            <Field
              name="question"
              component={TextField}
              label={formatMessage({ id: 'TESTS.QUESTION_REQ' })}
              disabled={disabled}
              guideText={formatMessage({ id: 'TESTS.QUESTION_GUIDE' })}
              exampleValues={Settings.QUESTION_EXAMPLES}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SpecificsFormContent.propTypes = {
  intl: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default injectIntl(SpecificsFormContent);
