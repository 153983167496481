import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { MessageComponent } from 'components';

const withEmptyMessage = (Component) => {
  const wrappedEmptyMessage = injectIntl((props) => {
    const {
      loading,
      isEmpty,
      emptyMessage,
      height,
      intl: { formatMessage },
      ...remainingProps
    } = props;
    const defaultEmptyMessage = formatMessage({ id: 'COMMON.NO_DATA_TO_DISPLAY' });

    return !loading && !isEmpty ? (
      <Component height={height} loading={loading} {...remainingProps} />
    ) : (
      <div style={{ height }}>
        <MessageComponent type="info" message={emptyMessage || defaultEmptyMessage} />
      </div>
    );
  });

  wrappedEmptyMessage.propTypes = {
    loading: PropTypes.bool,
    isEmpty: PropTypes.bool,
    emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  return wrappedEmptyMessage;
};

export default withEmptyMessage;
