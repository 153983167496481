import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class DropdownItem extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { children, className, active, ...props } = this.props;
    return (
      <button
        className={classNames('dropdown-item', className, { active })}
        type="button"
        {...props}>
        {children}
      </button>
    );
  }
}
