import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Field, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
  CompanySubsidiaryChooser,
  InlineError,
  Switcher,
  TextField,
  SelectField,
  ImageFileUploadField,
  TextAreaField,
} from 'components';
import { history, match } from 'common';
import LessonsSpecificsTable from './LessonsSpecificsTable/LessonsSpecificsTable';
import { getTopLevelErrors } from 'libs/validation/validation';
import { getUrlfromImageObject } from 'util/imagesMapper';
import { getMergedTagOptions } from 'util/getCreateableTagOptions';
import { getCompanyPlaceholderKey, getSubsidiaryPlaceholderKey } from 'util/contentOwnerUtil';

const LessonsBaseDataForm = ({
  edit,
  templateList,
  disabled,
  canLessonBePrivate,
  isLessonPublic,
  filteredTags,
  onSortEnd,
  errors,
  error,
  isLoading,
  profile,
  lesson,
  intl: { formatMessage },
}) => {
  const { values, setFieldValue } = useFormikContext();
  const tagsValue = values?.tags || [];
  const lessonTemplateValue = values?.lessonTemplate;

  const templateOptions = (templateList || []).map((template) => ({
    label: template.name,
    value: template,
  }));

  const onTemplateChange = useCallback(
    (val) => {
      if (val && val.specifics) {
        setFieldValue(
          'specifics',
          val.specifics.map((specific) => ({ ...specific, value: '' }))
        );
      } else {
        setFieldValue('specifics', []);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    onTemplateChange(lessonTemplateValue);
  }, [lessonTemplateValue, onTemplateChange]);

  const onCreateTag = useCallback(
    (tag = '') => {
      setFieldValue('tags', [
        ...tagsValue,
        { title: (tag + '').toUpperCase(), id: uuidv4(), new: true },
      ]);
    },
    [setFieldValue, tagsValue]
  );

  return (
    <div className="row">
      {!edit ? (
        <div className="col-12">
          <Field
            name={'lessonTemplate'}
            component={SelectField}
            useOnlyValueAsFieldValue
            label={<FormattedMessage id="TEMPLATES.SPECIFIC_TEMPLATE" />}
            options={templateOptions}
            disabled={disabled}
          />
        </div>
      ) : null}

      <div className="col-12">
        <Field
          name="title"
          component={TextField}
          label={<FormattedMessage id="COMMON.TITLE_REQ" />}
          disabled={disabled}
        />
      </div>

      <div className="col-12">
        <CompanySubsidiaryChooser
          companyInputName="company"
          subsidiaryInputName="subsidiary"
          companyLabel={formatMessage({ id: 'COMPANIES.COMPANY_REQ' })}
          subsidiaryLabel={formatMessage({ id: 'SUBSIDIARY.TITLE_REQ' })}
          companyOptionAsObject
          subsidiaryOptionAsObject
          disabled={disabled}
          companyPlaceholder={formatMessage({
            id: getCompanyPlaceholderKey({ profile, content: lesson, isEdit: edit }),
          })}
          subsidiaryPlaceholder={formatMessage({
            id: getSubsidiaryPlaceholderKey({ profile, content: lesson, isEdit: edit }),
          })}
          isLoading={isLoading}
        />
      </div>

      <div className="col-12">
        <Field
          name="images"
          component={ImageFileUploadField}
          required
          deletable
          newModal
          disabled={disabled}
          multi
          getFileUrl={getUrlfromImageObject}
        />
      </div>

      <div className="col-6">
        <Field
          name="tags"
          component={SelectField}
          useOnlyValueAsFieldValue
          label={<FormattedMessage id="LESSONS.LESSON_TAGS_REQ" />}
          multi
          formatCreateLabel={(val) => `${formatMessage({ id: 'LESSONS.ADD_TAG' })} "${val}"`}
          addNewOption
          onCreateOption={(val) => onCreateTag(val)}
          disabled={disabled}
          options={getMergedTagOptions({
            baseTags: filteredTags,
            createdTags: values.tags,
          })}
        />
      </div>

      <div className="col-6">
        <Field
          name="public"
          component={Switcher}
          warning={<FormattedMessage id="LESSONS.PUBLICITY_WARNING" />}
          showWarning={canLessonBePrivate || !values.id}
          disabled={disabled || (edit && isLessonPublic && !canLessonBePrivate)}
          onText="Public"
          offText="Private"
          className="lesson-editor__switcher"
        />
      </div>

      <div className="col-6">
        <Field
          name="price"
          component={TextField}
          label={<FormattedMessage id="COURSES.PRICE_REQ" />}
          disabled={disabled}
        />
      </div>

      <div className="col-6">
        <Field
          name="acceptanceRate"
          component={TextField}
          label={<FormattedMessage id="COURSES.ACCEPTANCE_RATE_REQ" />}
          disabled={disabled}
        />
      </div>

      <div className="col-6">
        <Field
          name="expirationDays"
          component={TextField}
          label={<FormattedMessage id="COURSES.NEED_TO_PASS_IN_DAY_REQ" />}
          disabled={disabled}
        />
      </div>
      <div className="col-6">
        <Field
          name="lessonType"
          component={TextField}
          label={<FormattedMessage id="LESSONS.LESSON_TYPE_REQ" />}
          disabled={disabled}
        />
      </div>
      <div className="col-6">
        <Field
          name="whatDoYouLearn"
          component={TextAreaField}
          label={<FormattedMessage id="COMMON.DESCRIPTION" />}
          disabled={disabled}
          rows={1}
        />
      </div>
      <div className="col-6">
        <Field
          name="requirement"
          component={TextAreaField}
          label={<FormattedMessage id="COMMON.REQUIREMENTS" />}
          disabled={disabled}
          rows={1}
        />
      </div>
      <div className="col-12 p-0">
        <LessonsSpecificsTable
          afterWrappedComponent
          specifics={values.specifics}
          disabledForm={disabled}
          disabled={disabled}
          onSortEnd={onSortEnd}
          errors={errors}
          mainErrors={getTopLevelErrors(errors.specifics)}
        />
      </div>

      <div className="col-12 p-0">
        <InlineError className="lesson-editor__form-error" show={!!error} message={error} />
      </div>
    </div>
  );
};

LessonsBaseDataForm.propTypes = {
  profile: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isLessonPublic: PropTypes.bool,
  canLessonBePrivate: PropTypes.bool,
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  onSortEnd: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  filteredTags: PropTypes.array.isRequired,
  errors: PropTypes.object,
  error: PropTypes.string,
  templateList: PropTypes.array,
  intl: PropTypes.object.isRequired,
  match,
  history,
};

export default injectIntl(withRouter(LessonsBaseDataForm));
