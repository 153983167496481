import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { CheckBox } from 'components';

const OnlySubsidiariesFilter = ({
  isCourse,
  onChange,
  onlySubsidiaries,
  intl: { formatMessage },
}) => (
  <div className="ms-3 mb-3 d-flex align-items-center">
    <CheckBox
      label={
        isCourse
          ? formatMessage({ id: 'COURSES.ONLY_SUBSIDIARY_COURSES' })
          : formatMessage({ id: 'LESSONS.ONLY_SUBSIDIARY_LESSONS' })
      }
      checked={!!onlySubsidiaries}
      onChange={(e) => onChange(e.target.checked)}
    />
  </div>
);

OnlySubsidiariesFilter.propTypes = {
  isCourse: PropTypes.bool,
  onlySubsidiaries: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(OnlySubsidiariesFilter);
