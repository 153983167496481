import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Popover } from 'components';
import { widthOptions, verticalAlignOptions, horizontalAlignOptions } from '../tableConstants';

import './style.scss';

const TableCell = ({
  className,
  header,
  multiLine,
  verticalAlign = verticalAlignOptions.center,
  horizontalAlign = horizontalAlignOptions.left,
  width = widthOptions.md,
  minWidth,
  maxWidth,
  children,
  hasTooltip = true,
  tooltipConfig,
  always,
  onClick,
  colSpan,
  noPadding,
  noLeftBorder = true,
  noRightBorder = true,
  noTopBorder,
  noBottomBorder = true,
  fillRemainingSpace,
}) => {
  const { content, overflowContent, onOverflow = true, ...otherTooltipConfig } =
    tooltipConfig || {};

  const verticalAlignClassName =
    verticalAlign === verticalAlignOptions.center
      ? 'align-middle'
      : verticalAlign === verticalAlignOptions.top
      ? 'align-top'
      : verticalAlign === verticalAlignOptions.bottom
      ? 'align-bottom'
      : '';

  const tableClassNames = classNames('basic-table-cell', className, verticalAlignClassName, {
    'basic-table-cell--header-cell': header,
    [`basic-table-cell--width-${width?.toLowerCase()}`]: !minWidth && !maxWidth,
    [`basic-table-cell--min-width-${minWidth?.toLowerCase()}`]: minWidth,
    'basic-table-cell--fill-remaining-space': fillRemainingSpace,
    'basic-table-cell--no-padding': noPadding,
    'basic-table-cell--no-left-border': noLeftBorder,
    'basic-table-cell--no-right-border': noRightBorder,
    'basic-table-cell--no-top-border': noTopBorder,
    'basic-table-cell--no-bottom-border': noBottomBorder,
  });

  const tableCellContent = (
    <div
      className={classNames('basic-table-cell__content', {
        [`basic-table-cell__content--max-width-${maxWidth?.toLowerCase()}`]: maxWidth,
        [`basic-table-cell__content--horizontal-align-${horizontalAlign?.toLowerCase()}`]: horizontalAlign,
        'p-1': !noPadding,
      })}>
      {hasTooltip && content ? (
        <Popover
          className={classNames({ 'basic-table-cell__popover--multiLine': multiLine })}
          content={content}
          overflowContent={overflowContent}
          onOverflow={onOverflow}
          oneLine
          trigger="hover"
          always={always}
          {...otherTooltipConfig}>
          {children}
        </Popover>
      ) : (
        children
      )}
    </div>
  );

  return header ? (
    <th className={tableClassNames} onClick={onClick} colSpan={colSpan}>
      {tableCellContent}
    </th>
  ) : (
    <td className={tableClassNames} onClick={onClick} colSpan={colSpan}>
      {tableCellContent}
    </td>
  );
};

TableCell.propTypes = {
  className: PropTypes.string,
  header: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  always: PropTypes.bool,
  multiLine: PropTypes.bool,
  width: PropTypes.oneOf(Object.values(widthOptions)),
  minWidth: PropTypes.oneOf(Object.values(widthOptions)),
  maxWidth: PropTypes.oneOf(Object.values(widthOptions)),
  verticalAlign: PropTypes.oneOf(Object.values(verticalAlignOptions)),
  horizontalAlign: PropTypes.oneOf(Object.values(horizontalAlignOptions)),
  tooltipConfig: PropTypes.object,
  onClick: PropTypes.func,
  colSpan: PropTypes.number,
  noPadding: PropTypes.bool,
  noTopBorder: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
  noLeftBorder: PropTypes.bool,
  noRightBorder: PropTypes.bool,
  fillRemainingSpace: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default TableCell;
