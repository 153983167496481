import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import { injectIntl } from 'react-intl';

import { IconButton } from '../IconButton';
import { Modal } from '../index';

import './style.scss';

const FilterModal = ({
  className,
  isLoading,
  showLoader,
  hideLoader,
  count,
  total,
  largeModal,
  modalTitle,
  initialValues,
  validationSchema,
  filterForm,
  filterSubmit,
  intl: { formatMessage },
}) => {
  const [open, setOpen] = useState(false);

  const countPerTotal = total && !isLoading && `${count || 0}/${total || 0}`;

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  const onSubmit = (values) => {
    try {
      showLoader();
      filterSubmit(values);
      closeModal();
    } finally {
      hideLoader();
    }
  };

  return (
    <div className={classNames('filter-modal', className)}>
      <div className={classNames('filter-modal__icon-button-container')}>
        <IconButton
          iconClass="lemon icon-filter filter-modal__filter-icon-button"
          name="filter"
          tooltip="Filter"
          onClick={openModal}
        />
      </div>
      <div className="filter-modal__count_per_total">{countPerTotal}</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize>
        {(formikProps) => {
          return (
            <Form>
              <Modal
                title={modalTitle}
                saveTitle={formatMessage({ id: 'COMMON.SAVE' })}
                closeTitle={formatMessage({ id: 'COMMON.CANCEL' })}
                saveAction={formikProps.handleSubmit}
                large={largeModal}
                onClose={closeModal}
                open={open}>
                {filterForm(formikProps)}
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

FilterModal.propTypes = {
  filterSubmit: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  filterForm: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  count: PropTypes.number,
  total: PropTypes.number,
  largeModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  modalTitle: PropTypes.node,
  className: PropTypes.string,
  intl: PropTypes.object,
};
export default injectIntl(FilterModal);
