import React from 'react';
import PropTypes from 'prop-types';

import TransferListAction from './TransferListAction';

const TransferListVerticalButtons = ({
  toTop,
  toBottom,
  oneDown,
  oneUp,
  isUpButtonDisabled,
  isDownButtonDisabled,
}) => {
  return (
    <div className="TransferListsActions">
      <TransferListAction
        iconClass="fa fa-angle-double-up"
        onClick={toTop}
        disabled={isUpButtonDisabled}
      />
      <TransferListAction
        iconClass="fa fa-angle-up"
        onClick={oneUp}
        disabled={isUpButtonDisabled}
      />
      <TransferListAction
        iconClass="fa fa-angle-down"
        onClick={oneDown}
        disabled={isDownButtonDisabled}
      />
      <TransferListAction
        iconClass="fa fa-angle-double-down"
        onClick={toBottom}
        disabled={isDownButtonDisabled}
      />
    </div>
  );
};

TransferListVerticalButtons.propTypes = {
  toTop: PropTypes.func,
  toBottom: PropTypes.func,
  oneUp: PropTypes.func,
  oneDown: PropTypes.func,
  isUpButtonDisabled: PropTypes.bool,
  isDownButtonDisabled: PropTypes.bool,
};

export default TransferListVerticalButtons;
