import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'formik';

import { useIsMounted } from 'hooks/useIsMounted';
import { getUserList } from 'services/users';
import { TransferLists } from 'components';
import AssignSourceUser from '../AssignGroupsAndUsers/AssignSourceUser';
import { defaultFeatcUsersQueryParams } from 'util/defaultQueryParams';
import { Roles } from 'libs/roles';

const defaultRenderUser = (user) => <AssignSourceUser {...user} />;

const AssignUser = ({
  filterUsers,
  overrideAssign,
  overrideUnAssign,
  disabled,
  fetchData,
  roles,
  isUserNotAssignable,
  isUserNotUnAssignable,
  renderSourceUser = defaultRenderUser,
  renderResultUser = defaultRenderUser,
}) => {
  const [availableItems, setAvailableItems] = useState(null);
  const [listChanged, setListChanged] = useState(true);
  const isMounted = useIsMounted();

  const fetchUsers = useCallback(
    async (searchText) => {
      const filteredRoles = roles.filter(({ name }) => name === Roles.USER).map(({ id }) => id);

      const { data: availableCourses } = await getUserList({
        ...defaultFeatcUsersQueryParams,
        roles: filteredRoles,
        searchText,
      });
      if (isMounted.current) {
        setAvailableItems(availableCourses);
        setListChanged(true);
        setListChanged(false);
      }
    },
    [roles, isMounted]
  );

  useEffect(() => {
    if (fetchData && !availableItems) {
      fetchUsers();
    }
  }, [fetchData, fetchUsers, availableItems]);

  return (
    <Field
      name="users"
      itemsName="users"
      component={TransferLists}
      isItemUnMoveableToLeft={isUserNotUnAssignable}
      isItemUnMoveableToRight={isUserNotAssignable}
      overrideToLeft={overrideUnAssign}
      overrideToRight={overrideAssign}
      renderSourceEntry={renderSourceUser}
      renderResultEntry={renderResultUser}
      disabled={disabled}
      loadList={fetchUsers}
      sourceList={availableItems}
      listChanged={listChanged}
      filterSourceItems={filterUsers}
    />
  );
};

AssignUser.propTypes = {
  fetchData: PropTypes.bool,
  filterUsers: PropTypes.func,
  disabled: PropTypes.bool,
  renderSourceUser: PropTypes.func,
  renderResultUser: PropTypes.func,
  isUserNotAssignable: PropTypes.func,
  isUserNotUnAssignable: PropTypes.func,
  overrideAssign: PropTypes.func,
  overrideUnAssign: PropTypes.func,
  roles: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    roles: state.roles.roles.data,
  };
};

export default connect(mapStateToProps)(AssignUser);
