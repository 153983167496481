import { getProfileCompanyId } from './profileUtil';

const getContentCompanyId = (content) => content?.subsidiary?.companies?.id;
const getContentSubsidiaryId = (content) => content?.subsidiary?.id;

const isProfileFromContentOwnerCompany = ({ profile, content }) => {
  const profileCompanyId = getProfileCompanyId(profile.profile);
  const contentCompanyId = getContentCompanyId(content);

  return Boolean(contentCompanyId && profileCompanyId === contentCompanyId);
};

const isProfileFromContentOwnerSubsidiary = ({ profile, content }) => {
  const profileCompanyId = getProfileCompanyId(profile.profile);
  const contentSubsidiaryId = getContentSubsidiaryId(content);

  return Boolean(contentSubsidiaryId && profileCompanyId === contentSubsidiaryId);
};

const getPlaceholderKey = ({ isOwnerFn, notOwnerKey, ownerKey }) => {
  return ({ isEdit, profile, content }) => {
    if (isEdit) {
      return isOwnerFn({ profile, content }) ? ownerKey : notOwnerKey;
    }

    return ownerKey;
  };
};

export const getCompanyPlaceholderKey = getPlaceholderKey({
  isOwnerFn: isProfileFromContentOwnerCompany,
  ownerKey: 'COMPANIES.COMPANY',
  notOwnerKey: 'COMPANIES.COMPANY_NAME_HIDDEN',
});

export const getSubsidiaryPlaceholderKey = getPlaceholderKey({
  isOwnerFn: isProfileFromContentOwnerSubsidiary,
  ownerKey: 'SUBSIDIARY.TITLE',
  notOwnerKey: 'SUBSIDIARY.SUBSIDIARY_NAME_HIDDEN',
});
