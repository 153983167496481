import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { withPagination } from 'hoc';
import {
  TableContainer,
  TableCellAvatar,
  EnableDisableControl,
  DeleteControl,
  EditOrReadControl,
  Popover,
} from 'components';
import Settings from 'config/settings';
import {
  widthOptions,
  horizontalAlignOptions,
  verticalAlignOptions,
} from 'components/Table/tableConstants';
import { CompanyAccess } from 'libs/accessManagement';

import { canModifySomethingInCompany } from '../CompanyAndSubsidiaryEditor/companyUpdateFormUtil';

import { getNextLicenceExpirationFieldText } from 'libs/companyUtils';
import defaultCompanyLogo from 'assets/images/default-company-logo.png';

import './style.scss';

const CompaniesTable = ({
  profile,
  list = [],
  openEditModal,
  openDeleteModal,
  onClickCompany,
  loadList,
  params,
  loading,
  enableCompany,
  disableCompany,
  intl: { formatMessage },
}) => {
  const {
    hasRoleToRead,
    hasRoleToEdit,
    hasRoleToDisable: hasRoleToEnable,
    hasRoleToDisable,
    hasRoleToDelete,
  } = CompanyAccess.getHasRoles(profile.profile);

  return (
    <TableContainer
      loading={loading}
      list={list}
      loadList={loadList}
      params={params}
      columns={[
        {
          key: 'edit_read',
          hide: !(hasRoleToEdit || hasRoleToRead),
          render: (company) => (
            <EditOrReadControl
              canEdit={canModifySomethingInCompany(profile.profile)}
              isEnabled={!company.isDisabled}
              openEditModal={() => openEditModal(company)}
            />
          ),
          minWidth: widthOptions.auto,
          maxWidth: widthOptions.xs,
        },
        {
          key: 'avatar',
          render: (company) => (
            <TableCellAvatar
              hasTooltip
              always
              src={company.logo || defaultCompanyLogo}
              alt={'company_logo'}
              tooltip={company.name}
            />
          ),
        },
        {
          key: 'name',
          label: formatMessage({ id: 'COMMON.NAME' }),
          sortBy: Settings.SORT_BY.NAME,
          horizontalAlign: horizontalAlignOptions.left,
          verticalAlignOptions: verticalAlignOptions.center,
          minWidth: widthOptions.md,
          maxWidth: widthOptions.lg,
          render: (company) => {
            const label = company.name || '-';
            return (
              <div
                className={classNames('companies__name-cell-content', {
                  'companies__name-cell-content--clickable': !!onClickCompany,
                })}
                onClick={() => onClickCompany(company)}>
                <Popover content={label} onOverflow oneLine placement="top" trigger="hover">
                  {label}
                </Popover>
              </div>
            );
          },
        },
        {
          key: 'subsidiaryCount',
          label: formatMessage({ id: 'COMPANY_GROUPS.NUMBER_OF_SUBSIDIARIES' }),
          sortBy: Settings.SORT_BY.NUM_OF_SUBSIDIARIES,
          width: widthOptions.sm,
        },
        {
          key: 'paidSubscriptionCount',
          label: formatMessage({ id: 'COMPANY_GROUPS.NUMBER_OF_PAID_SUBS' }),
          width: widthOptions.sm,
        },
        {
          key: 'licenceExpiration',
          label: formatMessage({ id: 'COMPANY_GROUPS.NEXT_LICENCE_EXPERIATION' }),
          width: widthOptions.sm,
          horizontalAlign: horizontalAlignOptions.left,
          verticalAlignOptions: verticalAlignOptions.center,
          render: (company) => getNextLicenceExpirationFieldText(company.licenseExpiration),
          itemTooltip: (company) => getNextLicenceExpirationFieldText(company.licenseExpiration),
        },
        {
          key: 'usageTime',
          label: formatMessage({ id: 'COMPANY_GROUPS.USAGE_TIME_LAST_30_DAYS' }),
          width: widthOptions.sm,
        },
        {
          key: 'enable_disable',
          hide: !(hasRoleToEnable || hasRoleToDisable),
          horizontalAlign: horizontalAlignOptions.center,
          verticalAlignOptions: verticalAlignOptions.center,
          render: (company) => (
            <EnableDisableControl
              item={company}
              showEnable={hasRoleToEnable}
              showDisable={hasRoleToDisable}
              itemDisabled={company.isDisabled}
              enableItem={(company) => {
                enableCompany(company);
              }}
              enableLabel={formatMessage({ id: 'COMPANY_GROUPS.ENABLE_COMPANY_GROUP' })}
              disableItem={(company) => {
                disableCompany(company);
              }}
              disableLabel={formatMessage({ id: 'COMPANY_GROUPS.DISABLE_COMPANY_GROUP' })}
            />
          ),
        },
        {
          key: 'delete',
          hide: !hasRoleToDelete,
          horizontalAlign: horizontalAlignOptions.center,
          verticalAlignOptions: verticalAlignOptions.center,
          minWidth: widthOptions.auto,
          maxWidth: widthOptions.xs,
          render: (company) => (
            <DeleteControl
              show={company.isDisabled}
              enabled={company.isDisabled && company.canDeleted}
              disabledHoverText={formatMessage({ id: 'COMPANY_GROUPS.DELETE_COMPANY_DISABLED' })}
              item={company}
              deleteItem={openDeleteModal}
            />
          ),
        },
      ]}
    />
  );
};

CompaniesTable.propTypes = {
  profile: PropTypes.object.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  openEditModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  onClickCompany: PropTypes.func,
  loadList: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  enableCompany: PropTypes.func.isRequired,
  disableCompany: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withPagination(injectIntl(CompaniesTable));
