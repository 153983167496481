import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Tag } from 'components';
import AssignUserNameLabel from './AssignUserNameLabel';

import {
  assignAsUserColor,
  assignAsUserBackgroundColor,
  assignAsGroupColor,
  assignAsGroupBackgroundColor,
} from '../../styles/_colors.scss';
import './style.scss';

const AssignResultUser = ({
  firstName,
  lastName,
  email,
  assignedAsGroup,
  assignedAsUser,
  intl: { formatMessage },
}) => {
  return (
    <div className="assign-user">
      <AssignUserNameLabel firstName={firstName} lastName={lastName} email={email} />
      <div className="assign-user-tag-container">
        {assignedAsGroup ? (
          <Tag
            color={assignAsGroupColor}
            backgroundColor={assignAsGroupBackgroundColor}
            text={formatMessage({ id: 'ASSIGN.AS_GROUP' })}
          />
        ) : null}
        {assignedAsUser ? (
          <Tag
            color={assignAsUserColor}
            backgroundColor={assignAsUserBackgroundColor}
            text={formatMessage({ id: 'ASSIGN.AS_USER' })}
          />
        ) : null}
      </div>
    </div>
  );
};

AssignResultUser.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  assignedAsGroup: PropTypes.bool,
  assignedAsUser: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AssignResultUser);
