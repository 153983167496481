import {
  GET_LESSON_TYPES,
  GET_LESSON_TYPES_FAILED,
  GET_LESSON_TYPES_SUCCESS,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LESSON_TYPES:
      return {
        ...state,
        loading: true,
      };
    case GET_LESSON_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || [],
      };
    case GET_LESSON_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
