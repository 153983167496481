import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { TrendIcon } from 'components';
import { contentChangeTypes, contentChangeTypeConstants } from 'util/statisticsOverviewType';

import './style.scss';

const ContentTrendIndicator = ({ value, changeType, percent, intl: { formatMessage } }) => {
  const type = contentChangeTypeConstants[changeType];

  return (
    <div className="content-trend-indicator-container">
      <div className="content-trend-indicator">
        <div className="content-trend-indicator-value">{value}</div>
        <TrendIcon changeType={changeType} iconClassName={type.indicatorClassName} />
        <div className={classNames('content-trend-indicator-percent', type.indicatorClassName)}>
          {`(${
            percent || percent === 0 ? percent + '%' : formatMessage({ id: 'COMMON.NO_DATA' })
          })`}
        </div>
      </div>
      <div className="content-trend-text-container">
        {formatMessage({ id: 'CONTENT_STATISTICS.LAST_SUBSCRIPTIONS' })}
      </div>
    </div>
  );
};

ContentTrendIndicator.propTypes = {
  value: PropTypes.number,
  changeType: PropTypes.oneOf(Object.values(contentChangeTypes)),
  percent: PropTypes.number,
};

export default injectIntl(ContentTrendIndicator);
