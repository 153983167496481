import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { modalType } from 'util/modalType';
import { AddRow, SelectInput } from 'components';
import { getSelectValueFromOptions } from 'util/selectInputUtil';

const TestTablePreHeader = ({
  selectedLevel,
  templateLevelOptions,
  setSelectedLevel,
  lesson,
  disabled,
  generateTestsFetch,
  openModal,
}) => {
  const showGenerateTest = !disabled && lesson?.id;
  return (
    <div className="row align-items-center">
      <div className="col-4">
        <SelectInput
          value={getSelectValueFromOptions({
            value: selectedLevel,
            options: templateLevelOptions,
          })}
          label={<FormattedMessage id="JOBS.LEVEL" />}
          onChange={({ value }) => setSelectedLevel(value)}
          options={templateLevelOptions}
        />
      </div>
      <div className="col-8 d-flex justify-content-between p-0">
        <AddRow
          onAdd={() => openModal(modalType.EDIT)}
          label={<FormattedMessage id="LESSONS.ADD_TEST" />}
          wide
          hideAdd={disabled}
        />
        {showGenerateTest && (
          <AddRow
            onAdd={generateTestsFetch}
            label={<FormattedMessage id="LESSONS.GENERATE_TESTS" />}
            wide
          />
        )}
      </div>
    </div>
  );
};

TestTablePreHeader.propTypes = {
  selectedLevel: PropTypes.number,
  templateLevelOptions: PropTypes.array,
  setSelectedLevel: PropTypes.func,
  lesson: PropTypes.object,
  disabled: PropTypes.bool,
  generateTestsFetch: PropTypes.func,
  openModal: PropTypes.func,
};

export default TestTablePreHeader;
