import { useCallback, useMemo } from 'react';

import {
  getSelectOptions,
  getSelectValueFromOptions,
  isOptionDisabled,
} from 'util/selectInputUtil';

export const useSelectValues = ({
  values,
  optionKey = 'id',
  optionAsObject = false,
  onlyEnabledOptions = false,
  multi = false,
  isValueDisabled = (value) => !!value.isDisabled,
  getOptionLabel,
}) => {
  const options = useMemo(
    () =>
      getSelectOptions({
        values,
        itemKey: optionKey,
        valueAsObject: optionAsObject,
        getOptionLabel,
      }),
    [values, optionAsObject, optionKey, getOptionLabel]
  );

  const isSelectOptionDisabled = useCallback(
    (option) =>
      onlyEnabledOptions
        ? isOptionDisabled({
            option,
            valueAsObject: optionAsObject,
            itemKey: optionKey,
            optionSourceList: values,
            isDisabled: isValueDisabled,
          })
        : undefined,
    [onlyEnabledOptions, optionAsObject, optionKey, values, isValueDisabled]
  );

  const getSelectedValue = useCallback(
    (value) =>
      getSelectValueFromOptions({
        value,
        options: options,
        itemKey: optionKey,
        multi,
      }),
    [options, optionKey, multi]
  );

  return {
    getSelectedValue,
    options,
    isOptionDisabled: isSelectOptionDisabled,
  };
};
