import React from 'react';
import PropTypes from 'prop-types';

import { chart as chartType } from 'common/propTypes';
import CompanySubsidiaryPrimaryChart from './CompanySubsidiaryPrimaryChart/CompanySubsidiaryPrimaryChart';
import CompanySubsidiarySecondaryChart from './CompanySubsidiarySecondaryChart/CompanySubsidiarySecondaryChart';

const CompanySubsidiaryStatistics = ({ loading, statistics, labelFilter, ...props }) => {
  const { primaryChart, secondaryChart } = statistics || {};
  const { filterType } = labelFilter || {};

  return (
    <div>
      <div>
        <CompanySubsidiaryPrimaryChart
          loading={loading}
          chart={primaryChart}
          type={filterType}
          {...props}
        />
      </div>
      <div>
        <CompanySubsidiarySecondaryChart loading={loading} chart={secondaryChart} {...props} />
      </div>
    </div>
  );
};

CompanySubsidiaryStatistics.propTypes = {
  loading: PropTypes.bool,
  labelFilter: PropTypes.object,
  statistics: PropTypes.shape({ primaryChart: chartType, secondaryChart: chartType }),
};

export default CompanySubsidiaryStatistics;
