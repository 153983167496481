import debounce from 'lodash/debounce';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

export default ({ maxWidthIndex = 1, wideMaxWidthIndex = null, wideMaxWidth = 200 } = {}) => (
  WrappedComponent
) => {
  return class TableResizeDetector extends React.Component {
    state = {
      maxWidth: 200,
      wideMaxWidth,
      largeModal: false,
    };

    componentDidMount() {
      this.resize();
    }

    resize = () => {
      const resizedTable = document && document.getElementsByClassName('resized-table')[0];
      const resizedTableHeader = resizedTable && resizedTable.getElementsByClassName('header')[0];
      const resizedTableCol =
        resizedTableHeader && resizedTableHeader.getElementsByClassName('col')[maxWidthIndex];
      const resizedTableWideCol =
        resizedTableHeader && resizedTableHeader.getElementsByClassName('col')[wideMaxWidthIndex];

      if (resizedTableCol) {
        this.setState({
          maxWidth: resizedTableCol.clientWidth,
          wideMaxWidth: wideMaxWidthIndex && resizedTableWideCol.clientWidth,
        });
      }
      this.setState({ largeModal: window.innerWidth >= 1200 });
    };

    resizeWithDebounce = debounce(() => {
      this.resize();
    }, 100);

    render() {
      return (
        <ReactResizeDetector handleWidth onResize={this.resizeWithDebounce}>
          <WrappedComponent
            maxWidth={this.state.maxWidth}
            wideMaxWidth={this.state.wideMaxWidth}
            largeModal={this.state.largeModal}
            resize={this.resize}
            {...this.props}
          />
        </ReactResizeDetector>
      );
    }
  };
};
