import request from '../libs/request';
import { Methods } from '../libs/http';

export const continueRegistration = async (data) => {
  return await request({
    method: Methods.POST,
    resource: '/users/continue-registration',
    data,
  });
};

export const getBloodTypes = async () => {
  return await request({
    method: Methods.GET,
    resource: '/blood-types',
  });
};
