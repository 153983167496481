import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { IconButton } from 'components';
import { modalType } from 'util/modalType';
import UserDataEditor from '../../containers/UsersPage/UserEditor/UserDataEditor/UserDataEditor';
import RolesModal from './RolesModal';

import './style.scss';

const PageHeader = ({
  title,
  profile,
  sideBar,
  logout,
  showLoader,
  hideLoader,
  openSideBar,
  closeSideBar,
}) => {
  const history = useHistory();
  const pageHeaderRef = useRef(null);

  const [showFloatingHeader, setShowFloatingHeader] = useState(false);
  const [modal, setModal] = useState(null);

  const signOut = async () => {
    showLoader();
    try {
      await logout();
    } finally {
      history.push('/login');
      hideLoader();
    }
  };

  const toggleSideBar = () => {
    if (sideBar.open) {
      closeSideBar();
    } else {
      openSideBar();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      const height = pageHeaderRef.current.clientHeight;

      if (position >= height && !showFloatingHeader) {
        setShowFloatingHeader(true);
      }
      if (position < height && showFloatingHeader) {
        setShowFloatingHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showFloatingHeader]);

  const openModal = (modal) => {
    setModal(modal);
  };

  const closeModal = () => {
    setModal(null);
  };

  const profileId = profile?.id;
  const name = profile.username || profile.email;

  return (
    <div
      ref={pageHeaderRef}
      className="page-header d-flex justify-content-between align-items-xl-center flex-xl-row">
      <div className="page-header__title-container">
        <IconButton
          type="button"
          iconClass="fas fa-bars"
          className="close page-header__main-sidebar-close"
          onClick={toggleSideBar}
          aria-label="Close"
        />
        <h1 className="page-header__title mb-0">{title}</h1>
      </div>
      <div
        className={classNames('page-header__floating-header d-flex align-items-center', {
          'page-header__floating-header--show': showFloatingHeader,
          'page-header__floating-header--side-bar-open': sideBar.open,
        })}>
        <div className="page-header__title-container">
          <IconButton
            type="button"
            iconClass="fas fa-bars"
            className="close page-header__main-sidebar-close"
            onClick={toggleSideBar}
            aria-label="Close"
          />
          <h1 className="page-header__title">{title}</h1>
        </div>

        <div className="d-flex align-items-center ms-auto page-header__user-data-container">
          <i
            className="page-header__roles-info lemon icon-info"
            onClick={() => openModal(modalType.ROLES)}
          />
          <div className="page-header__profile" onClick={() => openModal(modalType.EDIT)}>
            <i className="lemon icon-user" />
            {name}
          </div>

          <IconButton
            iconClass="lemon icon-logout"
            name="logout"
            tooltip="Logout"
            onClick={signOut}
          />
        </div>
      </div>
      <div className="page-header__user-data-container">
        <i
          className="page-header__roles-info lemon icon-info"
          onClick={() => openModal(modalType.ROLES)}
        />
        <div
          className="page-header__profile ms-auto align-items-center"
          onClick={() => openModal(modalType.EDIT)}>
          <i className="lemon icon-user" />
          {name}
        </div>

        <IconButton
          iconClass="lemon icon-logout"
          name="logout"
          tooltip="Logout"
          onClick={signOut}
        />
      </div>

      <RolesModal open={modal === modalType.ROLES} profile={profile} closeModal={closeModal} />

      <UserDataEditor open={modal === modalType.EDIT} userId={profileId} closeEditor={closeModal} />
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  profile: PropTypes.object.isRequired,
  sideBar: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  openSideBar: PropTypes.func.isRequired,
  closeSideBar: PropTypes.func.isRequired,
};

export default PageHeader;
