import get from 'lodash/get';

export const getFieldError = ({ form, field }) => get(form.errors, field.name);

export const hasFieldError = ({ form, field }) =>
  Boolean(getFieldError({ form, field }) && get(form.touched, field.name));

export const handleFieldChange = ({ form, field }) => (text) => {
  if (form && field) {
    form.setFieldValue(field.name, text);
    form.setFieldTouched(field.name, true);
  }
};
