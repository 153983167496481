import React from 'react';

import { InlineError } from 'components';
import InputError from '../InputError/InputError';

const InputInlineError = ({ ...props }) => {
  return (
    <InputError
      {...props}
      renderErrorComponent={({ show, errorMessage }) => (
        <InlineError show={show} message={errorMessage} />
      )}
    />
  );
};

export default InputInlineError;
