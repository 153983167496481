export const MENUS = {
  DASHBOARD: { name: 'dashboard', match: 'dashboard' },
  STATISTICS: { name: 'statistics_route', match: 'statistics_route' },
  COMPANY_SUBSIDIARY_STATISTICS: { name: 'company-statistics', match: 'company-statistics' },
  CONTENT_STATISTICS: { name: 'content-statistics', match: 'content-statistics' },
  MEMBERS_STATISTICS: { name: 'members-statistics', match: 'members-statistics' },
  ACCESS_MANAGEMENT: { name: 'access_management', match: 'access' },
  SLT_ADMINISTRATION: { name: 'slt_administration', match: 'users' },
  COMPANY: { name: 'company', match: 'company-subsidiaries' },
  SUBSIDIARY: { name: 'subsidiary', match: 'companies' },
  GROUP: { name: 'group', match: 'groups' },
  USER: { name: 'user', match: 'users' },
  CONTENT_MANAGEMENT: { name: 'content_management', match: 'content-management' },
  TEMPLATE: { name: 'templates', match: 'templates' },
  LESSON: { name: 'lesson', match: 'lessons' },
  COURSE: { name: 'course', match: 'courses' },
  AUDIT_PAGE: { name: 'audit_page', match: 'audit-page' },
  FOR_AUDIT: { name: 'for_audit', match: 'for-audit' },
  PRIVACY_POLICY: { name: 'privacy_policy', match: 'privacy-policy' },
  TERMS_AND_CONDITIONS: { name: 'terms-and-conditions', match: 'terms-and-conditions' },
  PATH: { name: 'path', match: 'path' },
};
