import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerWrapper } from 'components';

const withLoading = (Component) => {
  const wrappedWithLoading = (props) => {
    const { loading, height, ...remainingProps } = props;
    return !loading ? (
      <Component height={height} loading={loading} {...remainingProps} />
    ) : (
      <SpinnerWrapper height={height} />
    );
  };

  wrappedWithLoading.propTypes = {
    loading: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  return wrappedWithLoading;
};

export default withLoading;
