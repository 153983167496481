import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withCustomIconStyling from './withCustomIconStyling';
import { orientationOptions } from './iconConstants';

const Caret = ({ orientation = orientationOptions.right, className, ...props }) => {
  const baseClassName = `fas fa-caret-${orientation}`;
  return <i className={classNames(baseClassName, className)} {...props} />;
};

Caret.propTypes = {
  orientation: PropTypes.oneOf(Object.values(orientationOptions)),
};

export default withCustomIconStyling(Caret);
