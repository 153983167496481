import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { SelectInput } from 'components';
import { statisticsTimePeriods, statisticsTimePeriodsConstants } from 'util/statisticsTimeUtil';
import { getSelectValueFromOptions } from 'util/selectInputUtil';

import './style.scss';

const StatisticsPeriodPicker = ({
  value,
  asLabel,
  options,
  disabled,
  intl: { formatMessage },
  onChange,
  ...props
}) => {
  const periodOptions =
    (options &&
      Object.values(options).map((period) => ({
        label: formatMessage({
          id: statisticsTimePeriodsConstants[period].selectTranslateId,
        }),
        value: period,
      }))) ||
    [];

  return (
    <div className={classNames('statistics-period-picker', { 'as-label': asLabel })}>
      <SelectInput
        childrenWrapperClass={classNames({ 'statistics-select--no-disabled-styling': asLabel })}
        hideDropdownIndicator={asLabel}
        hideSeparator
        hideErrorLabel
        hideUnderline
        boldValue
        disabled={disabled || asLabel}
        value={getSelectValueFromOptions({ value, options: periodOptions })}
        options={periodOptions}
        onChange={({ value }) => onChange(value)}
        {...props}
      />
    </div>
  );
};

StatisticsPeriodPicker.propTypes = {
  asLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.oneOf(Object.values(statisticsTimePeriods))),
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  value: PropTypes.string,
};

export default injectIntl(StatisticsPeriodPicker);
