import {
  Roles,
  getListableRoleNamesForProfile,
  getRoleNamesToDistributeForProfile,
  roleTypes,
} from 'libs/roles';

export const getFilterRoles = ({ roles, isAdmin, profile }) => {
  const rolesToList = getListableRoleNamesForProfile(profile);
  const filteredRoles = rolesToList
    .map((roleName) => roles.find((role) => role && role.name && role.name === roleName))
    .filter((item) => !!item);
  return isAdmin
    ? filteredRoles.filter((role) => role?.name === Roles.SYSTEM_ADMIN)
    : filteredRoles.filter((role) => role?.name !== Roles.SYSTEM_ADMIN);
};

export const getAssignableRoles = ({ roles, isAdmin, profile }) => {
  const rolesToAssign = getRoleNamesToDistributeForProfile(profile);
  const filteredRoles = rolesToAssign.map(
    (roleName) => roles?.find((role) => role && role.name && role.name === roleName) || []
  );
  return isAdmin
    ? filteredRoles.filter((role) => role?.name === Roles.SYSTEM_ADMIN)
    : filteredRoles.filter((role) => role?.name !== Roles.SYSTEM_ADMIN);
};

export const getQueryOrDefaultRoles = ({ roles, filteredRoles }) => {
  return roles
    ? filteredRoles
        .filter(({ id }) => roles.find((role) => parseInt(role) === id))
        .map(({ id }) => id)
    : filteredRoles.map(({ id }) => id);
};

export const isSystemUserPage = (pathname) => pathname.includes(roleTypes.SYSTEM);
