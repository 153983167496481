import 'babel-polyfill';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import translation from './config/translation';

import App from './containers/App';
import store, { history } from './config/store';

import './styles/index.scss';

// Add import to be able to use Bootstrap and its components.

import 'bootstrap/dist/js/bootstrap';

const { locale, messages } = translation();

render(
  <Provider store={store}>
    <IntlProvider locale={locale} messages={messages}>
      <Router history={history}>
        <App />
      </Router>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);
