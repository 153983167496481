import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { hideLoader, showLoader } from 'actions/common';
import { getLesson, editLesson } from 'services/lessons';
import { UpdateForm, AssignGroupsAndUsers } from 'components';
import { Yup } from 'util/Yup';
import { getRolesByType } from 'actions/roles';
import { mapToAssignedUsers, filterUsersAssignedOnlyAsUser } from 'util/assignUserAndGroupHelper';
import { usersMapper } from '../../util/transferMapper';

const AssignLesson = ({ closeEditor, showLoader, hideLoader, lessonId, open }) => {
  const [lesson, setLesson] = useState(null);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (open && lessonId) {
      const fetchData = async () => {
        showLoader();
        try {
          const lessonResponse = await getLesson(lessonId);
          setLesson(lessonResponse);
          const usersResponse = mapToAssignedUsers(
            usersMapper(lessonResponse),
            lessonResponse.groups || []
          );
          setUsers(usersResponse);
          const groupsResponse = lessonResponse.groups;
          setGroups(groupsResponse);
        } finally {
          hideLoader();
        }
      };

      fetchData();
    }
  }, [hideLoader, lessonId, open, showLoader]);

  const create = async (values) => {
    const newUsers = filterUsersAssignedOnlyAsUser(values.users).map(({ id }) => id);
    const newGroups = values.groups.map((group) => group.id);
    const newLesson = { ...lesson, users: newUsers, groups: newGroups };
    await editLesson(newLesson, lesson.id);
  };

  const close = () => {
    setLesson(null);
    setUsers([]);
    setGroups([]);
    closeEditor();
  };

  return (
    <UpdateForm
      open={open}
      closeEditor={close}
      create={create}
      modalTitle={<FormattedMessage id="LESSONS.ASSIGN_LESSON_TO_USERS" />}
      initialValues={{ users, groups }}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        users: Yup.array().nullable(),
      })}>
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <AssignGroupsAndUsers values={values} fetchData setFieldValue={setFieldValue} />
          </Form>
        );
      }}
    </UpdateForm>
  );
};

AssignLesson.propTypes = {
  closeEditor: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  getRolesByType: PropTypes.func.isRequired,
  lessonId: PropTypes.number,
  open: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
      getRolesByType,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AssignLesson);
