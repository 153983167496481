import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { get, uniqBy, intersectionBy, difference } from 'lodash';

import { AssignGroup, AssignUser, SelectInput } from 'components';
import AssignResultUser from './AssignResultUser';
import AssignSourceUser from './AssignSourceUser';
import {
  filterUniqueUsers,
  getUniqueUsersFromGroupArray,
  getUserAsUnAssignedAsGroup,
  getUserAsAssignedAsGroup,
  getUserAsAssignedAsUser,
  getUserAsUnAssignedAsUser,
  isAssignedAsGroup,
  isAssignedAsUser,
} from 'util/assignUserAndGroupHelper';

import './style.scss';

const getUserValuesOnAssign = (newUserValues, oldUserValues, assignAsUser) => {
  const getModifiedUser = assignAsUser ? getUserAsAssignedAsUser : getUserAsAssignedAsGroup;
  const intersection = intersectionBy(oldUserValues, newUserValues, 'id');
  const newUserValuesWithoutIntersection = difference(newUserValues, intersection);
  const oldUserValuesWithoutNewUserValues = difference(oldUserValues, newUserValues);
  return uniqBy(
    [
      ...intersection.map((user) => getModifiedUser(user)),
      ...newUserValuesWithoutIntersection.map((user) => getModifiedUser(user)),
      ...(oldUserValuesWithoutNewUserValues || []),
    ],
    'id'
  );
};

const getUserValuesOnUnAssign = (removedUsers, oldUsersValues, unAssignAsUser) => {
  const getModifiedUser = unAssignAsUser ? getUserAsUnAssignedAsUser : getUserAsUnAssignedAsGroup;
  return oldUsersValues
    .map((oldUser) =>
      removedUsers.find((removedUser) => +removedUser.id === +oldUser.id)
        ? getModifiedUser(oldUser)
        : oldUser
    )
    .filter((user) => isAssignedAsGroup(user) || isAssignedAsUser(user));
};

const AssignGroupsAndUsers = ({
  groupsInputName = 'groups',
  usersInputName = 'users',
  values,
  disabled,
  intl: { formatMessage },
  setFieldValue,
}) => {
  const TRANSLATED_MODE_OPTIONS = {
    ASSIGN_USER: { label: formatMessage({ id: 'COURSES.ASSIGN_USERS' }), value: 0 },
    ASSIGN_GROUP: { label: formatMessage({ id: 'COURSES.ASSIGN_GROUPS' }), value: 1 },
  };
  const [mode, setMode] = useState(TRANSLATED_MODE_OPTIONS.ASSIGN_USER);

  const filterSourceUsers = (user) => {
    const usersValues = get(values, usersInputName);
    const userInUsersValues = usersValues.find((item) => +item.id === +user.id);
    return userInUsersValues ? !isAssignedAsUser(userInUsersValues) : true;
  };

  const onGroupAssign = (addedGroups) => {
    const oldUsersValues = get(values, usersInputName);
    const uniqueUsersFromGroups = getUniqueUsersFromGroupArray(addedGroups);
    const newUserValues = getUserValuesOnAssign(uniqueUsersFromGroups, oldUsersValues, false);
    setFieldValue(usersInputName, newUserValues);
  };

  const onGroupUnAssign = (removedGroups, remainingGroups) => {
    const oldUsersValues = get(values, usersInputName);
    const removedUsers = filterUniqueUsers(
      getUniqueUsersFromGroupArray(removedGroups),
      getUniqueUsersFromGroupArray(remainingGroups)
    );
    const modifiedUserValues = getUserValuesOnUnAssign(removedUsers, oldUsersValues, false);

    setFieldValue(usersInputName, modifiedUserValues);
  };

  const onUserAssign = (addedUsers, _, setValuesChanged) => {
    const oldUsersValues = get(values, usersInputName);
    const mergedUsers = getUserValuesOnAssign(addedUsers, oldUsersValues, true);
    setFieldValue(usersInputName, mergedUsers);
    setValuesChanged(true);
  };

  const onUserUnAssign = (removedUsers, _, setValuesChanged) => {
    const oldUsersValues = get(values, usersInputName);
    const modifiedUserValues = getUserValuesOnUnAssign(removedUsers, oldUsersValues, true);
    setFieldValue(usersInputName, modifiedUserValues);
    setValuesChanged(true);
  };

  const isUserNotUnAssignable = (user) => {
    const usersValues = get(values, usersInputName);
    const valueUser = usersValues.find((item) => +item.id === +user.id);
    return !isAssignedAsUser(valueUser) && isAssignedAsGroup(user);
  };

  const renderSourceUser = (props) => <AssignSourceUser {...props} />;

  const renderResultUser = ({ isGroup = false, isAssignedAsUser = false, ...props }) => (
    <AssignResultUser assignedAsGroup={isGroup} assignedAsUser={isAssignedAsUser} {...props} />
  );

  return (
    <>
      <div className="AssignTypeSelector d-flex justify-content-star">
        <SelectInput
          disabled={disabled}
          value={mode}
          options={Object.values(TRANSLATED_MODE_OPTIONS)}
          onChange={(value) => setMode(value)}
        />
      </div>
      {mode.value === TRANSLATED_MODE_OPTIONS.ASSIGN_USER.value ? (
        <AssignUser
          filterUsers={filterSourceUsers}
          isUserNotUnAssignable={isUserNotUnAssignable}
          disabled={disabled}
          overrideAssign={onUserAssign}
          overrideUnAssign={onUserUnAssign}
          renderSourceUser={renderSourceUser}
          renderResultUser={renderResultUser}
        />
      ) : (
        <AssignGroup
          disabled={disabled}
          onGroupAssign={onGroupAssign}
          onGroupUnAssign={onGroupUnAssign}
        />
      )}
    </>
  );
};

AssignGroupsAndUsers.propTypes = {
  groupsInputName: PropTypes.string,
  usersInputName: PropTypes.string,
  values: PropTypes.object,
  disabled: PropTypes.bool,
  setFieldValue: PropTypes.func,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AssignGroupsAndUsers);
