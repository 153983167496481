import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { TypeSubTypeChooserComplexField } from 'components';

const getSubsidiaryServiceTypes = ({
  serviceTypes,
  companyServiceTypeOptionAsObject,
  companyServiceTypeKey,
}) => (option) => {
  const optionId = companyServiceTypeOptionAsObject ? option?.[companyServiceTypeKey] : option;
  const companyServiceTypes =
    serviceTypes && option ? serviceTypes.find(({ id }) => id === +optionId) : null;
  return companyServiceTypes ? companyServiceTypes.groupTypes : [];
};

const CompanySubsidiaryTypeChooser = ({
  serviceTypes,
  companyServiceTypeInputName = 'mainGroupType',
  companyServiceTypeKey = 'id',
  companyServiceTypeInputLabel,
  companyServiceTypeOptionAsObject,
  subsidiaryServiceTypeInputName,
  subsidiaryServiceTypeKey,
  subsidiaryServiceTypeInputLabel,
  subsidiaryServiceTypeOptionAsObject,
  hideSubtypeIfNoOptions = false,
  ...props
}) => {
  const loadSubtypeOptionsByTypeId = useCallback(
    getSubsidiaryServiceTypes({
      companyServiceTypeOptionAsObject,
      companyServiceTypeKey,
      serviceTypes,
    }),
    [companyServiceTypeOptionAsObject, serviceTypes, companyServiceTypeKey]
  );

  return (
    <TypeSubTypeChooserComplexField
      typeKey={companyServiceTypeKey}
      typeInputName={companyServiceTypeInputName}
      typeValueList={serviceTypes}
      typeLabel={companyServiceTypeInputLabel}
      typeOptionAsObject={companyServiceTypeOptionAsObject}
      subTypeKey={subsidiaryServiceTypeKey}
      subTypeLabel={subsidiaryServiceTypeInputLabel}
      subTypeInputName={subsidiaryServiceTypeInputName}
      subTypeOptionAsObject={subsidiaryServiceTypeOptionAsObject}
      hideSubtypeIfNoOptions={hideSubtypeIfNoOptions}
      loadSubtypesByTypeKey={loadSubtypeOptionsByTypeId}
      {...props}
    />
  );
};

CompanySubsidiaryTypeChooser.propTypes = {
  serviceTypes: PropTypes.arrayOf(PropTypes.object),
  companyServiceTypeInputName: PropTypes.string,
  companyServiceTypeKey: PropTypes.string,
  companyServiceTypeInputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subsidiaryServiceTypeKey: PropTypes.string,
  subsidiaryServiceTypeInputName: PropTypes.string,
  subsidiaryServiceTypeInputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  companyServiceTypeOptionAsObject: PropTypes.bool,
  subsidiaryServiceTypeOptionAsObject: PropTypes.bool,
  hasErrorComponent: PropTypes.bool,
  hideSubtypeIfNoOptions: PropTypes.bool,
};

export default CompanySubsidiaryTypeChooser;
