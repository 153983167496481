import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form } from 'formik';
import isEmpty from 'lodash/isEmpty';

import UserBaseDataTab from './UserBaseDataTab';
import UserCorporateDataTab from './UserCorporateDataTab';
import { hideLoader, showLoader } from 'actions/common';
import { TabsContainer, UpdateForm } from 'components';
import { getUserById, editUser } from 'services/users';
import { getProfile } from 'actions/users';

import {
  isCompanyAndSubsidiaryDisabled,
  isProfileUser,
  hasRoleToEditUserProfile,
  isUserSLTAdmin,
  preloadEditData,
  validationSchema,
  preLoadUser,
} from './utils';

const checkExistingRows = (item, data) => {
  const errors = {};
  if (item.email === data.email) {
    errors.email = <FormattedMessage id="COMMON.EMAIL_EXISTS" />;
  }
  return errors.email ? errors : {};
};

const UserDataEditor = ({
  open,
  profile,
  provinces,
  userId,
  shadowed,
  onSaved,
  disabled,
  baseDataDisabled,
  closeEditor,
  getProfile,
  showLoader,
  hideLoader,
  subsidiaryId,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState(preLoadUser());

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        setUserData(preLoadUser(await getUserById(userId, subsidiaryId)));
      } finally {
        hideLoader();
      }
    };
    if (userId && open) {
      setTabIndex(0);
      fetchData();
    } else {
      setUserData(preLoadUser());
    }
  }, [userId, subsidiaryId, hideLoader, showLoader, open]);

  const getTabs = ({ errors: { corporate: corporateErrors, ...restErrors } }) => {
    const isSLTAdmin = isUserSLTAdmin(userData);

    return [
      {
        id: 'base-data',
        hide: disabled,
        label: <FormattedMessage id="USERS.BASE_DATA" />,
        component: (
          <UserBaseDataTab countries={provinces} disabled={baseDataDisabled} create={false} />
        ),
        hasError: !isEmpty(restErrors),
      },
      {
        id: 'corporate-data',
        hide: disabled || isSLTAdmin,
        hasError: !isEmpty(corporateErrors),
        label: <FormattedMessage id="USERS.CORPORATE_DATA" />,
        component: (
          <UserCorporateDataTab
            disabled={disabled}
            isCompanyAndSubsidiaryDisabled={isCompanyAndSubsidiaryDisabled(userData)}
          />
        ),
      },
    ].map((tab) => ({ ...tab, forceRender: true }));
  };

  const update = async (values) => {
    const user = await preloadEditData(values, userData);
    if (hasRoleToEditUserProfile(profile, userData)) {
      await editUser(values.id, { ...user, position: null });
      if (isProfileUser(userData, profile)) {
        getProfile();
      }
    }
  };

  const modalTitle = isProfileUser(userData, profile) ? (
    <FormattedMessage id="USERS.EDIT_MY_PROFILE" />
  ) : (
    <FormattedMessage id="USERS.EDIT_USER" />
  );

  return (
    <UpdateForm
      onSaved={onSaved}
      shadowed={shadowed}
      open={open}
      closeEditor={closeEditor}
      saveButton={!disabled}
      update={update}
      modalTitle={modalTitle}
      initialValues={userData}
      validateOnBlur={false}
      checkAdditionalExistingRows={checkExistingRows}
      validationSchema={validationSchema(isUserSLTAdmin(userData), provinces)}
      tabsInModal>
      {({ errors }) => {
        const tabList = getTabs({ errors });
        return (
          <Form className="base-form">
            <TabsContainer
              tabList={tabList}
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            />
          </Form>
        );
      }}
    </UpdateForm>
  );
};

const mapStateToProps = (state) => {
  return {
    provinces: state.provinces.provinces.data,
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
      getProfile,
    },
    dispatch
  );
};

UserDataEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  provinces: PropTypes.array.isRequired,
  userId: PropTypes.number,
  shadowed: PropTypes.bool,
  onSaved: PropTypes.func,
  disabled: PropTypes.bool,
  baseDataDisabled: PropTypes.bool,
  closeEditor: PropTypes.func,
  getProfile: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  subsidiaryId: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDataEditor);
