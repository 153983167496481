export const FILTERS = {
  users: { label: 'users', value: 1 },
  activities: { label: 'activities', value: 2 },
  unsuccessful: { label: 'unsuccessful', value: 3 },
  successful: { label: 'successful', value: 4 },
};

export const PERIODS = {
  UNDEFINED: { label: 'UNDEFINED', value: 0 },
  DAY: { label: 'DAY', value: 1 },
  WEEK: { label: 'WEEK', value: 2 },
  MONTH: { label: 'MONTH', value: 3 },
};

export const FILTER_TYPES = {
  UNDEFINED: { label: 'UNDEFINED', value: 0 },
  TIME: { label: 'TIME', value: 1 },
  RESULT: { label: 'RESULT', value: 2 },
};
