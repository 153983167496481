import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const PathItemDnDWrapper = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} key={id}>
      {children({ attributes, listeners })}
    </div>
  );
};

PathItemDnDWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.func,
};

export default PathItemDnDWrapper;
