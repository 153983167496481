import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { IconButton, Popover } from 'components';

const DeleteControl = ({
  show,
  enabled,
  enabledHoverText,
  disabledHoverText,
  item,
  deleteItem,
  intl: { formatMessage },
}) => {
  const enabledMessage = enabledHoverText
    ? enabledHoverText
    : formatMessage({ id: 'COMMON.DELETE_ENABLED' });
  const disabledMessage = disabledHoverText
    ? disabledHoverText
    : formatMessage({ id: 'COMMON.DELETE_DISABLED' });

  const popoverContent = enabled ? enabledMessage : disabledMessage;

  return show ? (
    <Popover content={popoverContent} oneLine placement="top" trigger="hover" always={!enabled}>
      <IconButton
        disabled={!enabled}
        iconClass="lemon icon-trash dark-gray"
        onClick={() => deleteItem(item)}
      />
    </Popover>
  ) : null;
};

DeleteControl.propTypes = {
  enabledHoverText: PropTypes.string,
  disabledHoverText: PropTypes.string,
  deleteItem: PropTypes.func.isRequired,
  item: PropTypes.object,
  enabled: PropTypes.bool,
  show: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(DeleteControl);
