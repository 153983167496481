import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Field, Form, FieldArray } from 'formik';

import {
  TextField,
  SelectField,
  CheckBoxField,
  ImageFileUploadField,
  UpdateForm,
  InlineError,
  TextAreaField,
} from 'components';
import Settings from 'config/settings';
import { history } from 'common';
import { testTypes } from 'libs/testTypes';
import { getInitialValues, getTestFormSchema, preloadSaveData } from './testsTableUtil';

const TestForm = ({
  open,
  disabled,
  closeEditor,
  createTest,
  editTest,
  edit,
  test,
  intl: { formatMessage },
}) => {
  const testLevelId = 0;

  const createTestWithType = useCallback(
    async (values) => {
      const data = await preloadSaveData({ values, testLevelId });
      return await createTest(data);
    },
    [createTest, testLevelId]
  );

  const editTestWithType = useCallback(
    async (values) => {
      const data = await preloadSaveData({ values, testLevelId });
      return await editTest(data);
    },
    [editTest, testLevelId]
  );

  const answerField = {
    [testTypes.MULTIPLE_TYPE]: (testAnswers, answersError) => (
      <>
        <div>
          <div>{formatMessage({ id: 'TESTS.ANSWERS' })}</div>
          <FieldArray
            name="answers"
            render={() =>
              testAnswers &&
              testAnswers.map((answer, index) => {
                return (
                  <div className="row d-flex align-items-center" key={index}>
                    <div className="col-9">
                      <Field
                        name={`answers[${index}]answer`}
                        component={TextField}
                        label={formatMessage({ id: 'TESTS.ANSWER_REQ' })}
                        disabled={disabled}
                      />
                    </div>

                    <div className="col-3">
                      <Field
                        name={`answers[${index}]isGood`}
                        component={CheckBoxField}
                        label={formatMessage({ id: 'TESTS.GOOD' })}
                        disabled={disabled}
                        fullWidth
                      />
                    </div>
                  </div>
                );
              })
            }
          />
          <InlineError show={!!answersError} message={answersError} />
        </div>
        <div>{formatMessage({ id: 'TESTS.TIME_LIMIT' })}</div>
      </>
    ),
    [testTypes.PUZZLE_TYPE]: () => (
      <Field
        name="answer.answer"
        component={TextField}
        label={formatMessage({ id: 'TESTS.ANSWER_MAX_10_REQ' })}
        disabled={disabled}
      />
    ),
  };

  return (
    <UpdateForm
      open={open}
      closeEditor={closeEditor}
      create={createTestWithType}
      update={editTestWithType}
      modalTitle={
        edit ? formatMessage({ id: 'TESTS.EDIT_TEST' }) : formatMessage({ id: 'TESTS.ADD_TEST' })
      }
      initialValues={getInitialValues(test)}
      validateOnBlur={false}
      validationSchema={getTestFormSchema(formatMessage)}>
      {({ errors, values }) => {
        const answersError = errors['answers'] && !errors['answers'].length && errors['answers'];
        const testType = values?.type || null;
        const testAnswers = values?.answers || [];
        return (
          <Form>
            <Field
              name="type"
              component={SelectField}
              useOnlyValueAsFieldValue
              label={formatMessage({ id: 'COMMON.TYPE' })}
              disabled={disabled || edit}
              options={Settings.QUESTION_TYPES.map(({ title, id }) => ({
                label: title,
                value: id,
              }))}
            />

            <Field name="image" component={ImageFileUploadField} disabled={disabled} />

            <div className="d-flex align-items-center mb-2">
              <Field
                name="isExamQuestion"
                className="me-4"
                component={CheckBoxField}
                label={formatMessage({ id: 'TESTS.EXAM' })}
                tooltipLabel={formatMessage({ id: 'TESTS.EXAM_EXPLANATION' })}
                disabled={disabled}
                alwaysShowTooltip
              />

              <Field
                name="isTestQuestion"
                component={CheckBoxField}
                label={formatMessage({ id: 'TESTS.TEST' })}
                tooltipLabel={formatMessage({ id: 'TESTS.TEST_EXPLANATION' })}
                disabled={disabled}
                alwaysShowTooltip
              />
            </div>

            <Field
              name="question"
              component={TextAreaField}
              label={formatMessage({ id: 'TESTS.QUESTION_REQ' })}
              disabled={disabled}
              rows={1}
            />

            {answerField[testType]?.(testAnswers, answersError)}

            <Field
              name="sec"
              component={TextField}
              label={formatMessage({ id: 'TESTS.TIME_LIMIT_IN_SECONDS_REQ' })}
              disabled={disabled}
            />
          </Form>
        );
      }}
    </UpdateForm>
  );
};

TestForm.propTypes = {
  closeEditor: PropTypes.func.isRequired,
  createTest: PropTypes.func.isRequired,
  editTest: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  test: PropTypes.object,
  edit: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  history,
};

export default withRouter(injectIntl(TestForm));
