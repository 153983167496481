import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TimePeriodAndFromToFilter } from 'components';

import './style.scss';

const ChartContainer = ({
  noTimeFilter,
  disabledTimeFilter,
  chartTitle,
  headerClassName,
  children,
  ...props
}) => {
  return (
    <div className="chart-container">
      <div className={classNames('chart-container-header', headerClassName)}>
        <div className="chart-title">{chartTitle && chartTitle}</div>
        <div className="time-filter">
          {!noTimeFilter && <TimePeriodAndFromToFilter asLabel={disabledTimeFilter} {...props} />}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

ChartContainer.propTypes = {
  headerClassName: PropTypes.string,
  noTimeFilter: PropTypes.bool,
  chartTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabledTimeFilter: PropTypes.bool,
  children: PropTypes.node,
};

export default ChartContainer;
