import React from 'react';

import { BasicFilePreviewContent, ImagePreviewContent, VideoPreviewContent } from 'components';

const renderImage = ({ file, className, getFileUrl, alt, ...props }) => (
  <ImagePreviewContent
    className={className}
    file={file}
    getFileUrl={getFileUrl}
    alt={alt}
    {...props}
  />
);

const renderVideo = ({ file, isInModal, ...props }) => (
  <VideoPreviewContent file={file} canStart={isInModal} {...props} />
);

const renderFile = ({ file }) => <BasicFilePreviewContent fileName={file.name || file.path} />;

const renderFunctions = {
  image: renderImage,
  video: renderVideo,
};

export const getPreviewContent = ({ type, ...props } = {}, index) => {
  const renderFunction = renderFunctions[type] || renderFile;

  return renderFunction({ ...props, index });
};
