export const hasInputTextRendered = ({ value, placeholder }) =>
  Boolean(!!value || value === 0 || placeholder);

export const showErrorInputStyle = ({ hasErrorMessage, hideValue }) =>
  hasErrorMessage && !hideValue;

const getElementNumericalPropertyValue = (elementStyleValues) => (property) =>
  parseInt(elementStyleValues.getPropertyValue(property), 10);

export const getElementMargin = (element) => {
  const elementStyleValues = window && window.getComputedStyle(element);

  if (!elementStyleValues) {
    return undefined;
  }

  const getPropertyValue = getElementNumericalPropertyValue(elementStyleValues);

  return {
    marginTop: getPropertyValue('margin-top'),
    marginBottom: getPropertyValue('margin-bottom'),
    marginLeft: getPropertyValue('margin-left'),
    marginRight: getPropertyValue('margin-right'),
  };
};
