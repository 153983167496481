import {
  GET_JOBS,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILED,
  CREATE_JOB,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAILED,
  EDIT_JOB,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_FAILED,
  DELETE_JOB,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: { data: [], count: 0 },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_JOBS:
      return {
        ...INITIAL_STATE,
        loading: true,
      };
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || [],
      };
    case GET_JOBS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_JOB:
      return {
        ...state,
        loading: true,
      };
    case CREATE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_JOB_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EDIT_JOB:
      return {
        ...state,
        loading: true,
      };
    case EDIT_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_JOB_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_JOB:
      return {
        ...state,
        loading: false,
        data: {
          data: state.data.data.filter((course) => course.id !== action.payload),
          count: state.data.count - 1,
        },
      };
    default:
      return state;
  }
};
