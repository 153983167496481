import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';

import { Spinner } from '../../components';

const AppLoader = ({ show = false }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={classNames('app-loader h-100 justify-content-center align-items-center', {
        'app-loader--show': show,
        'app-loader--has-sidebar': pathname.includes('home'),
      })}>
      <div className="app-loader__spinner-container">
        <Spinner />
      </div>
    </div>
  );
};

AppLoader.propTypes = {
  show: PropTypes.bool,
};

export default withRouter(AppLoader);
