import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import * as SubsidiaryService from 'services/subsidiaries';
import * as CompaniesService from 'services/companies';
import { getSubscriptions } from 'services/subscriptions';
import { getPriceRanges } from 'services/priceRanges';
import { getOpenings } from 'services/openings';
import { getGroupTypes } from 'services/subsidiaryTypes';
import { showLoader, hideLoader } from 'actions/common';
import { TabsContainer, UpdateForm } from 'components';
import CompanyGeneralTab from './CompanyGeneralTab';
import CompanyContactsTab from './CompanyContactsTab';
import CompanySubscriptionTab from './CompanySubscriptionTab';
import CompanyUsersTab from './CompanyUsersTab';
import { CompanyAccess, SubsidiaryAccess } from 'libs/accessManagement';
import {
  getCompanyData,
  getInitialCompanyFormValues,
  checkExistingRows,
  validationSchema,
  preLoadCompanyData,
} from './companyUpdateFormUtil';

const GENERAL = 'general';
const CONTACTS = 'contacts';
const ADMINS = 'admins';
const SUBSCRIPTION = 'subscription';

const baseTabList = [GENERAL, CONTACTS, ADMINS, SUBSCRIPTION];

const hideFooter = (tabIndex) => baseTabList[tabIndex] !== GENERAL;

const CompanyUpdateForm = ({
  onSaved,
  closeEditor,
  open,
  profile,
  showLoader,
  hideLoader,
  companyId,
  showIsEnabled,
  isAdmin,
  isCompany,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [company, setCompany] = useState({});
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [openings, setOpenings] = useState();
  const [priceRanges, setPriceRanges] = useState();
  const [groupTypes, setGroupTypes] = useState();
  const [availableSubscriptions, setAvailableSubscriptions] = useState();

  const edit = !!companyId;

  const title = isCompany
    ? edit
      ? 'COMPANIES.EDIT_COMPANY'
      : 'COMPANIES.ADD_COMPANY'
    : edit
    ? 'SUBSIDIARY.EDIT_SUBSIDIARY'
    : 'SUBSIDIARY.ADD_SUBSIDIARY';

  const close = useCallback(() => {
    setCompany({});
    closeEditor();
  }, [closeEditor]);

  const getAvailableCompanies = async () => {
    setAvailableCompanies(
      await CompaniesService.getCompanies({
        sortBy: 'name',
        order: 'asc',
      })
    );
  };

  const fetchCompany = useCallback(async () => {
    let companyById = {};
    try {
      showLoader();
      if (isCompany) {
        companyById = await CompaniesService.getCompany(companyId);
      } else {
        companyById = await SubsidiaryService.getSubsidiaryById(companyId);
      }
      setCompany(preLoadCompanyData(companyById));
    } finally {
      hideLoader();
    }
  }, [hideLoader, isCompany, showLoader, companyId]);

  const saveLogo = async (imageUrl, id) => {
    if (id && imageUrl.preview) {
      const formData = new FormData();
      formData.append('image', imageUrl);

      if (isCompany) {
        return await CompaniesService.saveCompanyLogo(id, formData);
      } else {
        return await SubsidiaryService.saveSubsidiaryLogo(id, formData);
      }
    }
  };

  const create = async (values) => {
    const { imageUrl, parentCompany } = values;
    const companyData = getCompanyData(values, isCompany);
    let resCompany;

    if (isCompany) {
      resCompany = await CompaniesService.createCompany(companyData);
      await saveLogo(imageUrl, resCompany.id);
    } else {
      resCompany = await SubsidiaryService.createSubsidiary(parentCompany, companyData);
      await saveLogo(imageUrl, resCompany.id);
    }

    return resCompany;
  };

  const update = async (values, subsidiaryId) => {
    const { imageUrl, ...company } = values;
    const companyData = Object.assign({}, values);

    if (companyData.admins) {
      companyData.admins = companyData.admins.map((admin) => admin.id);
    }

    let res;
    if (isCompany) {
      delete companyData.subsidiaries;
      res = await CompaniesService.editCompany(getCompanyData(companyData));
    } else {
      res = await SubsidiaryService.editSubsidiary(companyData.id, getCompanyData(companyData));
    }
    await saveLogo(imageUrl, company.id || subsidiaryId);

    return res;
  };

  const showSubmitButton = (edit, isCompany, profile) => {
    const {
      hasRoleToCreate: hasRoleToCreateCompany,
      hasRoleToEdit: hasRoleToEditCompany,
    } = CompanyAccess.getHasRoles(profile.profile);
    const {
      hasRoleToCreate: hasRoleToCreateSubsidiary,
      hasRoleToEdit: hasRoleToEditSubsidiary,
    } = SubsidiaryAccess.getHasRoles(profile.profile);
    return edit
      ? isCompany
        ? hasRoleToEditCompany
        : hasRoleToEditSubsidiary
      : isCompany
      ? hasRoleToCreateCompany
      : hasRoleToCreateSubsidiary;
  };

  const getTabData = (id, { errors }) => {
    const { hasRoleToEdit } = CompanyAccess.getHasRoles(profile.profile);

    const {
      AdminAccess: { hasRoleToRead: hasRoleToReadCompanyAdmin },
      ContactAccess: { hasRoleToRead: hasRoleToReadCompanyContacts },
    } = CompanyAccess.getHasRoles(profile.profile);

    const {
      AdminAccess: { hasRoleToRead: hasRoleToReadSubsidiaryAdmin },
      SubscriptionAccess: {
        hasRoleToRead: hasRoleToReadSubscription,
        hasRoleToEdit: hasRoleToAssignSubscription,
      },
      ContactAccess: { hasRoleToRead: hasRoleToReadSubsidiaryContacts },
    } = SubsidiaryAccess.getHasRoles(profile.profile);

    const showContactsTab = edit
      ? isCompany
        ? hasRoleToReadCompanyContacts
        : hasRoleToReadSubsidiaryContacts
      : false;

    const showAdminsTab = edit
      ? company && company.id && isCompany
        ? hasRoleToReadCompanyAdmin
        : hasRoleToReadSubsidiaryAdmin
      : false;

    const showSubscriptionTab = edit ? !isCompany && hasRoleToReadSubscription : false;

    const tabData = {
      [GENERAL]: {
        id: GENERAL,
        label: <FormattedMessage id="COMPANIES.GENERAL" />,
        hasError: !isEmpty(errors),
        component: (
          <div className="company-form">
            <CompanyGeneralTab
              company={company}
              availableCompanies={availableCompanies}
              type={company.subsidiaryType}
              mainGroupType={
                company.subsidiaryType && company.subsidiaryType.maingroupType
                  ? company.subsidiaryType.maingroupType
                  : company.subsidiaryType
              }
              groupType={company.subsidiaryType}
              isCompany={isCompany}
              isAdmin={isAdmin}
              disabled={edit && !hasRoleToEdit}
              showIsEnabled={showIsEnabled}
              priceRanges={priceRanges}
              openings={openings}
              groupTypes={groupTypes}
            />
          </div>
        ),
      },
      [CONTACTS]: {
        id: CONTACTS,
        hide: !showContactsTab,
        label: <FormattedMessage id="COMPANIES.CONTACTS" />,
        component: <CompanyContactsTab company={company} isCompany={isCompany} />,
      },
      [ADMINS]: {
        id: ADMINS,
        hide: !showAdminsTab,
        label: <FormattedMessage id="COMPANIES.ADMINS" />,
        component: (
          <CompanyUsersTab company={company} isCompany={isCompany} onSaved={fetchCompany} />
        ),
      },
      [SUBSCRIPTION]: {
        id: SUBSCRIPTION,
        hide: !showSubscriptionTab,
        label: <FormattedMessage id="COMPANIES.SUBSCRIPTION" />,
        component: (
          <CompanySubscriptionTab
            company={company}
            availableSubscriptions={availableSubscriptions}
            disabled={!hasRoleToAssignSubscription}
          />
        ),
      },
    };
    return tabData[id];
  };

  const getTabs = (formikParams) =>
    baseTabList.map((tabId) => ({
      ...getTabData(tabId, formikParams),
      forceRender: true,
    }));

  useEffect(() => {
    if (open) {
      setTabIndex(0);
      const fetchData = async () => {
        const [resSubscriptions, resPriceRanges, resOpenings, resGroupTypes] = await Promise.all([
          getSubscriptions(),
          getPriceRanges(),
          getOpenings(),
          getGroupTypes(),
        ]);
        setAvailableSubscriptions(resSubscriptions);
        setPriceRanges(resPriceRanges);
        setOpenings(resOpenings);
        setGroupTypes(resGroupTypes);
      };
      fetchData();
    }
    if (open && companyId) {
      fetchCompany();
    }

    if (open && !isCompany) {
      getAvailableCompanies();
    }
  }, [isCompany, open, companyId, fetchCompany]);

  return (
    <UpdateForm
      onSaved={onSaved}
      saveButton={showSubmitButton(edit, isCompany, profile)}
      open={open}
      hideFooter={hideFooter(tabIndex)}
      closeEditor={close}
      create={create}
      update={update}
      modalTitle={<FormattedMessage id={title} />}
      initialValues={getInitialCompanyFormValues(company)}
      validateOnBlur={false}
      checkAdditionalExistingRows={checkExistingRows}
      validationSchema={validationSchema(isCompany)}
      tabsInModal
      maxHeight>
      {(formikProps) => {
        const tabs = getTabs(formikProps);
        return (
          <Form>
            <div className="company-tab-form">
              <TabsContainer
                tabList={tabs}
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              />
            </div>
          </Form>
        );
      }}
    </UpdateForm>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

CompanyUpdateForm.propTypes = {
  onSaved: PropTypes.func,
  closeEditor: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  showIsEnabled: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isCompany: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyUpdateForm));
