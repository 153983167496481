import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import isArray from 'lodash/isArray';

import { hasInputTextRendered, showErrorInputStyle } from '../inputUtil';
import InputInlineError from '../InputInlineError/InputInlineError';
import { inputErrorPropTypes } from 'common';
import ControlLabelWrapper from '../ControlLabelWrapper/ControlLabelWrapper';

import './style.scss';

const findTypeMismatch = (selected = {}, options = []) =>
  options.find(({ value }) => {
    return selected?.value && typeof value !== typeof selected?.value;
  });

const option = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
});

const SelectInputBase = ({
  typedText,
  value,
  disabled,
  placeholder,
  maxWidth,
  hideSeparator,
  hideUnderline,
  hideDropdownIndicator,
  pushDownContent,
  boldValue,
  label,
  hasErrorComponent,
  hasErrorMessage,
  hideValue,
  errorMessage,
  children,
  containerClass,
  childrenWrapperClass,
}) => {
  const [id] = useState(() => uniqueId('select-field-'));

  return (
    <ControlLabelWrapper
      id={id}
      className={classNames(
        'select-input-base',
        {
          'is-disabled': disabled,
          'max-width': maxWidth,
          pushdown: pushDownContent,
          'hide-separator': hideSeparator,
          'hide-dropdown-indicator': hideDropdownIndicator,
          'bold-value': boldValue,
        },
        containerClass
      )}
      childrenWrapperClass={childrenWrapperClass}
      hasInputValue={hasInputTextRendered({ value: value || typedText, placeholder })}
      hasError={showErrorInputStyle({ hasErrorMessage, hideValue })}
      label={label}
      disabled={disabled}
      hideUnderLine={hideUnderline}
      additions={
        <InputInlineError
          hasErrorComponent={hasErrorComponent}
          hasErrorMessage={hasErrorMessage}
          errorMessage={errorMessage}
          hideValue={hideValue}
        />
      }>
      {children({ classNamePrefix: 'Select', placeholder: placeholder || null })}
    </ControlLabelWrapper>
  );
};

SelectInputBase.propTypes = {
  containerClass: PropTypes.string,
  childrenWrapperClass: PropTypes.string,
  typedText: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  className: PropTypes.string,
  onNewOptionClick: PropTypes.func,
  allowCreate: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  boldValue: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  hideSeparator: PropTypes.bool,
  hideDropdownIndicator: PropTypes.bool,
  maxWidth: PropTypes.bool,
  pushDownContent: PropTypes.bool,
  children: PropTypes.func.isRequired,
  ...inputErrorPropTypes,
  value: function (props, propName, componentName) {
    PropTypes.checkPropTypes(
      {
        [propName]: props.multi ? PropTypes.arrayOf(option) : option,
      },
      props,
      propName,
      componentName
    );

    const value = props[propName];

    if (isArray(value) && !!value.find((item) => !!findTypeMismatch(item, props.options))) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. The ${propName} has to be of the same type as the option values!`
      );
    }
    if (findTypeMismatch(value, props.options)) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. The ${propName} has to be of the same type as the option values!`
      );
    }
  },
  options: PropTypes.arrayOf(option),
  placeholder: PropTypes.string,
};

export default SelectInputBase;
