export const GET_JOBS = 'GET_JOBS';
export const GET_JOBS_FAILED = 'GET_JOBS_FAILED';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';

export const GET_JOB_SEEKERS = 'GET_JOB_SEEKERS';
export const GET_JOB_SEEKERS_FAILED = 'GET_JOB_SEEKERS_FAILED';
export const GET_JOB_SEEKERS_SUCCESS = 'GET_JOB_SEEKERS_SUCCESS';

export const GET_FORM_OF_EMPLOYMENT = 'GET_FORM_OF_EMPLOYMENT';
export const GET_FORM_OF_EMPLOYMENT_FAILED = 'GET_FORM_OF_EMPLOYMENT_FAILED';
export const GET_FORM_OF_EMPLOYMENT_SUCCESS = 'GET_FORM_OF_EMPLOYMENT_SUCCESS';

export const GET_JOB = 'GET_JOB';
export const GET_JOB_FAILED = 'GET_JOB_FAILED';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';

export const CREATE_JOB = 'CREATE_JOB';
export const CREATE_JOB_FAILED = 'CREATE_JOB_FAILED';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';

export const EDIT_JOB = 'EDIT_JOB';
export const EDIT_JOB_FAILED = 'EDIT_JOB_FAILED';
export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS';

export const DELETE_JOB = 'DELETE_JOB';
