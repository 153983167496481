import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export default class Tooltip extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    top: PropTypes.number,
    left: PropTypes.number,
    isVisible: PropTypes.bool,
    color: PropTypes.string,
  };

  render() {
    return (
      <div
        className="tooltip-container"
        style={{
          top: this.props.top,
          left: this.props.left,
          display: this.props.isVisible ? 'block' : 'none',
        }}>
        <div className="tooltip" style={{ color: this.props.color }}>
          {this.props.text}
        </div>
      </div>
    );
  }
}
