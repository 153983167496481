import React from 'react';
import PropTypes from 'prop-types';
import { Yup } from 'util/Yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Field } from 'formik';

import { getValidationMessageInput } from 'libs/validation/validation';
import { FileUploadField, UpdateForm } from 'components';
import { zipMimeType, xlsxMimeType } from 'util/osUtil';
import { importLesson } from 'services/lessons';

import './style.scss';

const LESSON_DATA_ACCEPTED_FILE_EXTENSIONS = { [xlsxMimeType]: [] };
const ASSETS_ACCEPTED_FILE_EXTENSIONS = { [zipMimeType]: [] };

const ImportModal = ({
  open,
  template,
  closeEditor,
  tabsInModal,
  onSaved,
  shadowed,
  disabled,
  intl: { formatMessage },
}) => {
  const initialFormData = {
    assetsFile: null,
    lessonDataFile: null,
  };

  const validationSchema = Yup.object().shape({
    assetsFile: Yup.mixed().required(
      getValidationMessageInput('TEMPLATES.IMPORT_UPLOAD_ASSETS_FILE_REQUIRED')
    ),
    lessonDataFile: Yup.mixed().required(
      getValidationMessageInput('TEMPLATES.IMPORT_UPLOAD_LESSON_DATA_FILE_REQUIRED')
    ),
  });

  const preloadSaveData = ({ assetsFile, lessonDataFile }) => {
    const bodyFormData = new FormData();
    bodyFormData.append('imageszip', assetsFile);
    bodyFormData.append('excelfile', lessonDataFile);
    return bodyFormData;
  };

  const sendImportFile = async (values) => {
    const templateId = template?.id || null;
    await importLesson(templateId, preloadSaveData(values));
  };

  return (
    <UpdateForm
      open={open}
      onSaved={onSaved}
      shadowed={shadowed}
      saveButton={!disabled}
      closeEditor={closeEditor}
      create={sendImportFile}
      modalTitle={<FormattedMessage id="TEMPLATES.IMPORT_LESSON" />}
      initialValues={initialFormData}
      validateOnBlur={false}
      validationSchema={validationSchema}
      tabsInModal={tabsInModal}>
      {() => (
        <div className="default-modal-body-padding align-self-center">
          <div className="lesson-import__info-container row">
            <div className="lesson-import__info-icon-container col-12 col-md-2 col-xl-1 justify-content-center">
              <i className="lemon icon-info" />
            </div>
            <div className="lesson-import__info col-12 col-md-10 col-xl-11">
              {formatMessage({ id: 'TEMPLATES.IMPORT_LESSON_INFO' })}
            </div>
          </div>
          <Form className="base-form">
            <div className="row">
              <div className="col-12 col-lg-6 align-self-start">
                <div>{formatMessage({ id: 'TEMPLATES.IMPORT_UPLOAD_LESSON_DATA_FILE' })}</div>
                <Field
                  name="lessonDataFile"
                  accept={LESSON_DATA_ACCEPTED_FILE_EXTENSIONS}
                  component={FileUploadField}
                  disabled={disabled}
                  deletable
                  required
                  requiredMessage={
                    <FormattedMessage id="TEMPLATES.IMPORT_UPLOAD_LESSON_DATA_FILE_REQUIRED" />
                  }
                />
              </div>
              <div className="col-12 col-lg-6 align-self-start">
                <div>{formatMessage({ id: 'TEMPLATES.IMPORT_UPLOAD_ASSETS_FILE' })}</div>
                <Field
                  name="assetsFile"
                  accept={ASSETS_ACCEPTED_FILE_EXTENSIONS}
                  component={FileUploadField}
                  disabled={disabled}
                  deletable
                  required
                  requiredMessage={
                    <FormattedMessage id="TEMPLATES.IMPORT_UPLOAD_ASSETS_FILE_REQUIRED" />
                  }
                />
              </div>
              <div className="lesson-import__synchronization-text col-12 pt-2">
                {`* ${formatMessage({ id: 'TEMPLATES.IMPORT_UPLOAD_SYNCHRONIZATION_INFO' })}`}
              </div>
            </div>
          </Form>
        </div>
      )}
    </UpdateForm>
  );
};

ImportModal.propTypes = {
  open: PropTypes.bool,
  template: PropTypes.object,
  disabled: PropTypes.bool,
  closeEditor: PropTypes.func,
  tabsInModal: PropTypes.bool,
  onSaved: PropTypes.func,
  shadowed: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ImportModal);
