import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NotFound, Agreement, AgreementTypes } from 'components';
import LoginPage from '../LoginPage';
import Home from '../Home';
import PropTypes from 'prop-types';
import ResetPasswordPage from '../ResetPassword/ResetPasswordPage';
import ContinueRegistrationPage from '../ContinueRegistration/ContinueRegistrationPage';

export default class InitialRouting extends Component {
  static propTypes = {
    token: PropTypes.shape({ id: PropTypes.string, token: PropTypes.string }),
    tokenError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
  };

  render() {
    const { token } = this.props.token;

    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return token ? <Redirect to="/home" /> : <Redirect to="login" />;
          }}
        />
        <Route
          path="/login"
          render={() => (token && !this.props.tokenError ? <Redirect to="/home" /> : <LoginPage />)}
        />
        <Route path="/privacy-policy" render={() => <Agreement type={AgreementTypes.Privacy} />} />
        <Route
          path="/terms-and-conditions"
          render={() => <Agreement type={AgreementTypes.TermsAndConditions} />}
        />
        <Route
          path={`/home`}
          render={() => (!(token && !this.props.tokenError) ? <Redirect to="/login" /> : <Home />)}
        />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/continue_registration" component={ContinueRegistrationPage} />
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}
