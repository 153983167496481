import * as CoursesService from '../services/courses';

import * as actionTypes from 'actions/action-types';

export const getCourses = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_COURSES,
      });

      //TODO After BE is finished the request call should be modified to match it
      //onlyInTitles is 0 (meaning false) or 1 (meaning true) located in params.onlyInTitles
      const courses = await CoursesService.getCourses(params);

      dispatch({
        type: actionTypes.GET_COURSES_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_COURSES_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
