import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PreHeaderAdditionalItem = ({
  className,
  hide = false,
  additionalPreHeaderIconContainerItem,
}) => {
  return (
    <div
      className={classNames(className, { 'basic-page-pre-header__additional-item--hidden': hide })}>
      {!!additionalPreHeaderIconContainerItem && additionalPreHeaderIconContainerItem}
    </div>
  );
};

PreHeaderAdditionalItem.propTypes = {
  className: PropTypes.string,
  hide: PropTypes.bool,
  additionalPreHeaderIconContainerItem: PropTypes.node,
};

export default PreHeaderAdditionalItem;
