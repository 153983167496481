import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getTemplates, deleteTemplate } from 'services/templates';
import Settings from 'config/settings';
import { getToken } from 'libs/refreshToken';
import { BASE_URL as TEMPLATES_BASE_URL } from 'services/templates';
import { createDownloadLink } from 'libs/createDownloadLink';
import { modalType } from 'util/modalType';
import TemplatesTable from './TemplatesTable';
import TemplateEditor from './TemplateEditor';
import ImportModal from './ImportModal/ImportModal';
import { TemplateAccess } from 'libs/accessManagement';
import { CrudPage } from 'components';
import { mapSpecificsToSpecificsWithTempId } from 'util/templateSpecificsMapper';
import { useFetchWithQueryParams } from 'hooks/useFetchWithQueryParams';

const getTemplate = (template = {}) => {
  return {
    id: template.id || null,
    name: template.name || '',
    specifics: template.specifics || [],
    isGlobal: template.isGlobal,
  };
};

const TemplatesPage = ({ profile }) => {
  const [template, setTemplate] = useState(getTemplate());
  const [modal, setModal] = useState(null);

  const fetchTemplates = useCallback(async (params) => await getTemplates(params), []);
  const { data, isLoading, params, setCurrentParams, changeParams } = useFetchWithQueryParams({
    fetch: fetchTemplates,
  });
  const count = data?.count || 0;
  const templates =
    data?.data.map((template) => ({
      ...template,
      specifics: mapSpecificsToSpecificsWithTempId(template.specifics || []),
    })) || [];

  const exportTemplate = async (template) => {
    const token = getToken() || {};
    const downloadUrl = `${Settings.API_BASE_URL}${TEMPLATES_BASE_URL}/${template.id}/export?token=${token.token}`;
    createDownloadLink(downloadUrl);
  };

  const openModal = (modalType, template) => {
    setModal(modalType);
    setTemplate(template);
  };

  const closeModalState = () => {
    setModal(null);
    setTemplate(null);
  };

  const { hasRoleToCreate } = TemplateAccess.getHasRoles(profile.profile);

  return (
    <CrudPage
      title={<FormattedMessage id="TEMPLATES.TITLE" />}
      addRowLabel={<FormattedMessage id="TEMPLATES.ADD" />}
      deleteTitle={<FormattedMessage id="USERS.DELETE_USER" />}
      deleteItem={deleteTemplate}
      onDelete={() => setCurrentParams(params)}
      deleteKey="name"
      setCurrentParams={setCurrentParams}
      params={params}
      filterBySearchText
      hasRoleToCreate={hasRoleToCreate}
      initialData={template}>
      {({ data, isEditorOpen, openDeleteModal, openEditModal, closeModal }) => {
        return (
          <div className="TemplatesPage">
            <TemplatesTable
              list={templates}
              profile={profile}
              openEditModal={openEditModal}
              exportTemplate={exportTemplate}
              openImportLessonModal={(template) => openModal(modalType.IMPORT_LESSON, template)}
              openDeleteModal={(template) => openDeleteModal(template)}
              count={count}
              currentPage={params.page}
              loadList={setCurrentParams}
              loadPage={(page) => changeParams(page)}
              params={params}
              loading={isLoading}
            />
            <TemplateEditor
              onSaved={changeParams}
              open={isEditorOpen}
              template={data}
              closeEditor={closeModal}
            />
            <ImportModal
              open={modal === modalType.IMPORT_LESSON}
              template={template}
              closeEditor={closeModalState}
            />
          </div>
        );
      }}
    </CrudPage>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

TemplatesPage.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(TemplatesPage);
