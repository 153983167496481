import { isArray } from 'lodash';
import { getFileAsBase64 } from './getFileAsBase64';

export const getImages = (data) => {
  return data.images.map((image, index) => ({
    url: image,
    fullUrl: data.imagesFull[index],
  }));
};

export const getUrlfromLessonSpecificImageObject = (imageObject) => imageObject;

export const getUrlfromImageObject = (imageObject) =>
  imageObject && imageObject.url && imageObject.url.data ? imageObject.url.data : null;

export const getRequestDataForImageUrl = (imageObject) => {
  return imageObject?.data || null;
};

const getRequestDataForSingleImage = async ({
  image,
  useBase64,
  getDataForExistingImage = (image) => image,
  getDataForBrowsedImage = (image) => ({ data: image }),
}) => {
  if (image instanceof File) {
    return getDataForBrowsedImage(useBase64 ? await getFileAsBase64(image) : image);
  }
  return getDataForExistingImage(image) ? getDataForExistingImage(image) : null;
};

export const getRequestDataForImage = async ({
  image,
  useBase64 = true,
  emptyRequestData,
  getDataForExistingImage,
  getDataForBrowsedImage,
}) => {
  const commonParams = { useBase64, getDataForExistingImage, getDataForBrowsedImage };
  const currentEmptyRequestData = emptyRequestData || isArray(image) ? [] : null;

  if (isArray(image)) {
    const imageArray = await Promise.all(
      image.map((item) => getRequestDataForSingleImage({ image: item, ...commonParams }))
    );
    return imageArray.length ? imageArray : currentEmptyRequestData;
  }

  const singleImage = await getRequestDataForSingleImage({ image, ...commonParams });

  return singleImage || currentEmptyRequestData;
};

export const getPlainUrl = (imageObject) =>
  imageObject && imageObject.data ? imageObject.data : null;
