import React from 'react';
import PropTypes from 'prop-types';

import BootStrapModal from 'react-bootstrap/Modal';

import { IconButton, ProgressBar } from 'components';

import './style.scss';

const ModalHeader = ({ showProgressBar, title, closeIconDisabled, onCloseIconClick }) => (
  <BootStrapModal.Header className="my-modal-header">
    <div className="modal-header__loader">
      {showProgressBar && <ProgressBar className="modal-header__loader__progress-bar" />}
    </div>
    <h5 className="modal-header__title">{title}</h5>
    <IconButton
      className="modal-header__close-icon-button"
      type="button"
      iconClass="lemon-bold icon-close purple"
      disabled={closeIconDisabled}
      aria-label="Close"
      onClick={onCloseIconClick}
    />
  </BootStrapModal.Header>
);

ModalHeader.propTypes = {
  showProgressBar: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  closeIconDisabled: PropTypes.bool,
  onCloseIconClick: PropTypes.func,
  className: PropTypes.string,
};

export default ModalHeader;
