import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'formik';
import { isFunction, isArray } from 'lodash';

import { Yup } from 'util/Yup';
import { getProfile } from 'actions/users';
import { CompanySubsidiaryChooser, TextField, AsyncSelectField, UpdateForm } from 'components';
import UserRolesTab from './UserRolesTab/UserRolesTab';
import { getUsersLookup } from 'services/users';
import { getValidationMessageInput } from 'libs/validation/validation';
import { getType } from 'libs/validation/user/validation';
import { createShallowMergedObject } from 'util/objectUtil';
import { isProfileEmail } from 'libs/accessManagement';
import { getSelectOptionShape } from 'libs/validation/yupValidation';
import { createAdmin } from 'services/users';

const emptyRoleData = {
  email: '',
  roles: [],
  groups: [],
  companyObject: '',
  subsidiaryObject: '',
};

const UserRoleEditor = ({
  open,
  shadowed,
  fixedEmail,
  existingEmails,
  onSaved,
  getProfile,
  disabled,
  disabledCompanySubsidiaryChooser,
  tabsInModal,
  profile,
  availableRoles,
  initialRoleData,
  showCompanySubsidiaryChooser = true,
  closeEditor,
  modalTitle
}) => {
  const roleData = createShallowMergedObject(emptyRoleData, initialRoleData);

  const preloadAddRoleData = (values) => {
    const { roles, groups, email, companyObject, subsidiaryObject } = values;

    const roleIdList = roles
      .map((role) => (typeof role === 'object' ? role.id : role))
      .filter((roleId) => !!availableRoles.find(({ id }) => id === roleId));

    const groupIdList = groups
      ? groups.map((role) => (typeof role === 'object' ? role.id : role))
      : [];

    const type = getType(roleIdList, availableRoles);
    const companyId = companyObject && companyObject.id ? companyObject.id : null;
    const subsidiaryId = subsidiaryObject && subsidiaryObject.id ? subsidiaryObject.id : null;

    return {
      ...(type && { type }),
      ...(companyId && { companyId }),
      ...(subsidiaryId && {
        subsidiaryId,
      }),
      groups: groupIdList || [],
      roles: roleIdList,
      email: email?.value || email || '',
    };
  };

  const addRole = async (values) => {
    const preloadData = preloadAddRoleData(values);
    await createAdmin(preloadData);
    if (isProfileEmail(profile.profile, preloadData.email)) {
      getProfile();
    }
  };

  const getExistingEmails = async () => {
    if (isArray(existingEmails)) {
      return existingEmails;
    }
    if (isFunction(existingEmails)) {
      return await existingEmails();
    }
    return [];
  };

  const getEmailOptions = async (searchText) => {
    try {
      const [returnedUsers, existingUserEmails] = await Promise.all([
        getUsersLookup(searchText),
        getExistingEmails(),
      ]);
      return existingUserEmails
        ? returnedUsers.filter(({ value }) => value && !existingUserEmails.includes(value))
        : returnedUsers;
    } catch (e) {
      return [];
    }
  };

  const validationSchema = Yup.object().shape({
    email: fixedEmail ? Yup.string() : getSelectOptionShape(Yup.string().email().required()),
    roles: Yup.array()
      .min(1, getValidationMessageInput('USERS.AT_LEAST_ONE_ROLES_IS_REQUIRED'))
      .required(getValidationMessageInput('USERS.AT_LEAST_ONE_ROLES_IS_REQUIRED')),
    companyObject:
      showCompanySubsidiaryChooser && !disabledCompanySubsidiaryChooser
        ? Yup.object().required()
        : Yup.object(),
    subsidiaryObject:
      showCompanySubsidiaryChooser && !disabledCompanySubsidiaryChooser
        ? Yup.object().nullable().required()
        : Yup.object().nullable(),
  });

  return (
    <UpdateForm
      onSaved={onSaved}
      shadowed={shadowed}
      open={open}
      closeEditor={closeEditor}
      saveButton={!disabled}
      create={addRole}
      modalTitle={modalTitle || <FormattedMessage id="USERS.ADD_USER" />}
      initialValues={roleData}
      validateOnBlur={false}
      validationSchema={validationSchema}
      tabsInModal={tabsInModal}>
      {({ values, errors }) => {
        return (
          <Form className="base-form">
            <div className="default-modal-body-padding">
              {fixedEmail ? (
                <Field name="email" disabled component={TextField} />
              ) : (
                <Field
                  disabled={disabled}
                  name="email"
                  addNewOption
                  createOptionPosition="first"
                  formatCreateLabel={(newEmail) => (
                    <FormattedMessage id="USERS.ASSIGN_NEW_EMAIL" values={{ email: newEmail }} />
                  )}
                  pushDownContent
                  component={AsyncSelectField}
                  label={<FormattedMessage id="COMMON.EMAIL_REQ" />}
                  loadOptions={getEmailOptions}
                />
              )}

              {showCompanySubsidiaryChooser ? (
                <CompanySubsidiaryChooser
                  companyInputName="companyObject"
                  subsidiaryInputName="subsidiaryObject"
                  companyLabel={<FormattedMessage id="COMPANIES.COMPANY_REQ" />}
                  subsidiaryLabel={<FormattedMessage id="SUBSIDIARY.TITLE_REQ" />}
                  companyOptionAsObject
                  subsidiaryOptionAsObject
                  disabled={disabled || disabledCompanySubsidiaryChooser}
                />
              ) : null}
              {availableRoles && availableRoles.length > 1 ? (
                <UserRolesTab
                  errors={errors}
                  roles={values.roles}
                  availableRoles={availableRoles}
                  disabled={disabled}
                />
              ) : null}
            </div>
          </Form>
        );
      }}
    </UpdateForm>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProfile,
    },
    dispatch
  );
};

UserRoleEditor.propTypes = {
  open: PropTypes.bool,
  shadowed: PropTypes.bool,
  fixedEmail: PropTypes.bool,
  existingEmails: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  onSaved: PropTypes.func,
  getProfile: PropTypes.func,
  disabled: PropTypes.bool,
  disabledCompanySubsidiaryChooser: PropTypes.bool,
  tabsInModal: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  availableRoles: PropTypes.array,
  initialRoleData: PropTypes.object,
  showCompanySubsidiaryChooser: PropTypes.bool,
  closeEditor: PropTypes.func,
  modalTitle: PropTypes.node,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleEditor);
