import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CheckBox } from 'components';

const TransferListItem = ({
  item,
  renderItem,
  onChangeSelection,
  selectedItems,
  disabled,
  isItemDisabled,
  tooltipLabel,
}) => {
  const itemDisabled = disabled || (isItemDisabled && isItemDisabled(item));

  return (
    <div className={classNames({ disabled: itemDisabled }, 'd-flex w-100')}>
      <CheckBox
        disabled={itemDisabled}
        onChange={(e) => onChangeSelection(e, item)}
        checked={!!selectedItems.find(({ id }) => id === item.id)}
        label={renderItem ? renderItem(item) : item.name}
        tooltipLabel={tooltipLabel || item?.name || ''}
      />
    </div>
  );
};

TransferListItem.propTypes = {
  item: PropTypes.object.isRequired,
  renderItem: PropTypes.func,
  disabled: PropTypes.bool,
  onChangeSelection: PropTypes.func.isRequired,
  isItemDisabled: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  tooltipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default TransferListItem;
