import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import {
  CompanySubsidiaryChooser,
  ImageFileUploadField,
  TextField,
  Switcher,
  TextAreaField,
} from 'components';
import { getUrlfromImageObject } from 'util/imagesMapper';
import { getCompanyPlaceholderKey, getSubsidiaryPlaceholderKey } from 'util/contentOwnerUtil';

const CoursesBaseDataTab = ({ course, disabled, isLoading, profile }) => {
  const isEdit = !!course.id;

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <CompanySubsidiaryChooser
            companyInputName="company"
            subsidiaryInputName="subsidiary"
            companyLabel={<FormattedMessage id="COMPANIES.COMPANY_REQ" />}
            subsidiaryLabel={<FormattedMessage id="SUBSIDIARY.TITLE_REQ" />}
            companyOptionAsObject
            subsidiaryOptionAsObject
            disabled={disabled}
            companyPlaceholder={
              <FormattedMessage
                id={getCompanyPlaceholderKey({ profile, content: course, isEdit })}
              />
            }
            subsidiaryPlaceholder={
              <FormattedMessage
                id={getSubsidiaryPlaceholderKey({ profile, content: course, isEdit })}
              />
            }
            isLoading={isLoading}
          />
        </div>

        <div className="col-12">
          <Field
            name="images"
            component={ImageFileUploadField}
            disabled={disabled}
            multi
            deletable
            required
            getFileUrl={getUrlfromImageObject}
          />
        </div>

        <div className="col-12 col-md-6">
          <Field
            name="title"
            component={TextField}
            label={<FormattedMessage id="COMMON.TITLE_REQ" />}
            disabled={disabled}
          />
        </div>

        <div className="col-12 col-md-6">
          <Field
            name="price"
            component={TextField}
            label={<FormattedMessage id="COURSES.PRICE_REQ" />}
            disabled={disabled || course.isAdvert || !course.public}
          />
        </div>

        <div className="col-12 col-md-6">
          <Field
            name="examQuestionCount"
            component={TextField}
            label={<FormattedMessage id="COURSES.NUMBER_OF_QUESTIONS_REQ" />}
            disabled={disabled}
          />
        </div>

        <div className="col-12 col-md-6">
          <Field
            name="acceptanceRate"
            component={TextField}
            label={<FormattedMessage id="COURSES.ACCEPTANCE_RATE_REQ" />}
            disabled={disabled}
          />
        </div>

        <div className="col-12 d-flex">
          <Field
            className="mb-0"
            name="public"
            component={Switcher}
            // disabled={disabled || (course.id && isCoursePublic)} //REQUIRED if public course cannot be reverted to private
            disabled={disabled}
            onText="Public"
            offText="Private"
          />

          <Field
            className="ms-4 mb-0"
            name="isAdvert"
            component={Switcher}
            disabled={disabled || (course.id && course.public)}
            onText={<FormattedMessage id="COURSES.ADVERT" />}
            offText={<FormattedMessage id="COURSES.NOT_ADVERT" />}
          />
        </div>

        <div className="col-12 col-md-6">
          <Field
            name="deadline"
            component={TextField}
            label={<FormattedMessage id="COURSES.NEED_TO_PASS_IN_DAY_REQ" />}
            disabled={disabled}
          />
        </div>

        <div className="row">
          <div className="col-6">
            <Field
              name="whatDoYouLearn"
              component={TextAreaField}
              label={<FormattedMessage id="COMMON.DESCRIPTION" />}
              disabled={disabled}
              rows={1}
            />
          </div>

          <div className="col-6">
            <Field
              name="requirement"
              component={TextAreaField}
              label={<FormattedMessage id="COMMON.REQUIREMENTS" />}
              disabled={disabled}
              rows={1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CoursesBaseDataTab.propTypes = {
  course: PropTypes.object.isRequired,
  isCoursePublic: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  profile: PropTypes.object.isRequired,
};

export default CoursesBaseDataTab;
