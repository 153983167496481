import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import LogoAnimation from '../LogoAnimation/LogoAnimation';
import { getLatestAgreements } from 'services/agreements';
import { hideLoader, showLoader } from 'actions/common';

import './style.scss';

export const AgreementTypes = {
  Privacy: 'Privacy',
  TermsAndConditions: 'TermsAndConditions',
};

const Agreement = ({ type = AgreementTypes.TermsAndConditions, showLoader, hideLoader }) => {
  const [terms, setTerms] = useState('');
  const [privacy, setPrivacy] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const response = await getLatestAgreements();
        setTerms(response.terms);
        setPrivacy(response.privacy);
      } finally {
        hideLoader();
      }
    };
    fetchData();
  }, [hideLoader, showLoader]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 agreement__header d-flex justify-content-center">
          <div className="agreement__logo-container align-items-center d-none d-sm-flex">
            <LogoAnimation size="xxs" dark />
          </div>
          <h2 className="text-center mb-0 d-flex align-items-center">
            {type === AgreementTypes.Privacy ? (
              <FormattedMessage id="PRIVACY_POLICY.TITLE" />
            ) : (
              <FormattedMessage id="TERMS_AND_CONDITIONS.TITLE" />
            )}
          </h2>
        </div>
        <div className="col-12 mt-3">
          <div
            className="agreement__content"
            dangerouslySetInnerHTML={{
              __html: type === AgreementTypes.Privacy ? privacy : terms,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoader,
      hideLoader,
    },
    dispatch
  );
};

Agreement.propTypes = {
  type: PropTypes.oneOf([AgreementTypes.Privacy, AgreementTypes.TermsAndConditions]),
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Agreement);
