import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'components';

import './style.scss';

const SpinnerWrapper = ({ height }) => {
  return (
    <div className="loading-container" style={height && { height }}>
      <div className="spinner-wrapper">
        <Spinner />
      </div>
    </div>
  );
};

SpinnerWrapper.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SpinnerWrapper;
