import { auditDecision } from 'libs/auditDecision';
import { getUserIdentifier } from 'containers/UsersPage/User';

export const getLessonTagsAsString = (lesson = {}) =>
  lesson.tags ? lesson.tags.map(({ title }) => title).join(', ') : '';

export const getAuthorAsString = (lesson = {}) =>
  lesson.author ? getUserIdentifier(lesson.author) : '';

export const getDecisionAsString = (lesson = {}) => {
  const decision = lesson.latestAction
    ? Object.values(auditDecision).find(({ id }) => +id === +lesson.latestAction.action)
    : null;
  return decision ? decision.label : '';
};
