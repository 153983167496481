import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'formik';

import { CompanySubsidiaryChooser, TextField, DatePickerField } from 'components';

const UserCorporateDataTab = ({
  isCompanyAndSubsidiaryDisabled,
  disabled,
  intl: { formatMessage },
}) => {
  const companyInputName = 'corporate.company';
  const subsidiaryInputName = 'corporate.subsidiary';
  return (
    <div className="row">
      <div className="col-12">
        <CompanySubsidiaryChooser
          companyInputName={companyInputName}
          subsidiaryInputName={subsidiaryInputName}
          companyLabel={<FormattedMessage id="COMPANIES.COMPANY_REQ" />}
          subsidiaryLabel={<FormattedMessage id="SUBSIDIARY.TITLE_REQ" />}
          hideSubtypeIfNoOptions={isCompanyAndSubsidiaryDisabled}
          companyOptionAsObject
          subsidiaryOptionAsObject
          disabled={disabled || isCompanyAndSubsidiaryDisabled}
        />
      </div>

      <div className="col-12 col-md-6">
        <Field
          name="corporate.department"
          component={TextField}
          label={<FormattedMessage id="USERS.COMPANY_DEPARTMENT" />}
          disabled={disabled}
        />
      </div>
      <div className="col-12 col-md-6">
        <Field
          name="corporate.role"
          component={TextField}
          label={<FormattedMessage id="USERS.COMPANY_ROLE" />}
          disabled={disabled}
        />
      </div>
      <div className="col-12 col-md-6">
        <Field
          name="corporate.location"
          component={TextField}
          label={<FormattedMessage id="USERS.COMPANY_LOCATION" />}
          disabled={disabled}
        />
      </div>
      <div className="col-12 col-md-6">
        <Field
          name="corporate.employmentStatus"
          component={TextField}
          label={<FormattedMessage id="USERS.COMPANY_EMPLOYMENT_STATUS" />}
          disabled={disabled}
        />
      </div>
      <div className="col-12 col-md-6">
        <Field
          name="corporate.employmentStartDate"
          placeholder={formatMessage({ id: 'USERS.COMPANY_EMPLOYMENT_START' })}
          openingDirection="up"
          component={DatePickerField}
          label={<FormattedMessage id="USERS.COMPANY_EMPLOYMENT_START" />}
          disabled={disabled}
          showYearDropdown
          maxDate={new Date()}
        />
      </div>
    </div>
  );
};

UserCorporateDataTab.propTypes = {
  disabled: PropTypes.bool,
  isCompanyAndSubsidiaryDisabled: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(UserCorporateDataTab);
