import React from 'react';
import PropTypes from 'prop-types';

import { TablePagination } from 'components';

const withPagination = (Component) => {
  const wrappedWithPagination = ({ count, itemsPerPage, currentPage, loadPage, ...props }) => {
    return (
      <>
        <Component count={count} currentPage={currentPage} {...props} />
        <TablePagination
          count={count}
          itemsPerPage={itemsPerPage}
          loadList={(pageParams) => loadPage(pageParams)}
          currentPage={currentPage}
        />
      </>
    );
  };

  wrappedWithPagination.propTypes = {
    count: PropTypes.number,
    currentPage: PropTypes.number,
    loadPage: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number,
  };

  return wrappedWithPagination;
};

export default withPagination;
