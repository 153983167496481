import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { FileUploadField, FilePreviewContainer } from 'components';

const ImageFileUploadField = ({
  accept = {
    'image/jpeg': [],
    'image/png': [],
  },
  deletable,
  disabled,
  disabledClick,
  multi,
  newModal,
  type = 'image',
  form,
  field,
  file,
  getFileUrl,
  ...props
}) => {
  const renderPreview = useCallback(
    ({ files, deleteSelection }) => {
      const commonProps = {
        files,
        getFileUrl,
        type,
        newModal,
        deleteSelection,
        disabledClick,
      };

      return <FilePreviewContainer {...commonProps} />;
    },
    [disabledClick, getFileUrl, newModal, type]
  );

  return (
    <FileUploadField
      {...props}
      convertToBase64
      accept={accept}
      file={file}
      field={field}
      form={form}
      deletable={deletable}
      multi={multi}
      disabled={disabled}
      disabledClick={disabledClick}
      renderSelection={renderPreview}
    />
  );
};

ImageFileUploadField.propTypes = {
  accept: PropTypes.object,
  requiredMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  errorMessage: PropTypes.node,
  maxSize: PropTypes.number,
  deletable: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledClick: PropTypes.bool,
  required: PropTypes.bool,
  multi: PropTypes.bool,
  newModal: PropTypes.bool,
  type: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  getFileUrl: PropTypes.func,
};

export default injectIntl(ImageFileUploadField);
