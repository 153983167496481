import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { CompositeLineBarChart, ChartContainer } from 'components';
import { getChartLabelTraslateNameId } from 'util/chartRenderUtil';
import { primaryChartConstants } from '../chartConstants';

const height = 400;

const ContentPrimaryChart = ({ isCourse, chart, loading, intl: { formatMessage }, ...props }) => {
  const { chartAreas, chartBars, xAxisDataKey, xAxisPadding, yAxes: yAxesData } =
    primaryChartConstants || {};

  const getBars = () => {
    return (chartBars || []).map(({ key, color, ...props }) => ({
      ...props,
      dataKey: key,
      fill: color,
      name: getChartLabelTraslateNameId(key)
        ? formatMessage({ id: getChartLabelTraslateNameId(key) })
        : key,
    }));
  };

  const getAreas = () => {
    return (chartAreas || []).map(({ key, color, ...props }) => ({
      ...props,
      dataKey: key,
      stroke: color,
      name: getChartLabelTraslateNameId(key)
        ? formatMessage({ id: getChartLabelTraslateNameId(key) })
        : key,
    }));
  };

  const yAxes = (yAxesData || []).map(({ labelTranslateId, ...props }) => ({
    label: formatMessage({ id: labelTranslateId }),
    ...props,
  }));

  return (
    <ChartContainer
      chartTitle={
        isCourse ? (
          <FormattedMessage id="CONTENT_STATISTICS.COURSE_PRIMARY_CHART_TITLE" />
        ) : (
          <FormattedMessage id="CONTENT_STATISTICS.LESSON_PRIMARY_CHART_TITLE" />
        )
      }
      loading={loading}
      {...props}>
      <CompositeLineBarChart
        loading={loading}
        xAxisPadding={xAxisPadding}
        height={height}
        bars={getBars()}
        areas={getAreas()}
        data={chart}
        xAxisDataKey={xAxisDataKey}
        yAxes={yAxes}
      />
    </ChartContainer>
  );
};

ContentPrimaryChart.propTypes = {
  loading: PropTypes.bool,
  isCourse: PropTypes.bool,
  chart: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(ContentPrimaryChart);
