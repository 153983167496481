import * as SubscriptionService from '../services/subscriptions';

import * as actionTypes from 'actions/action-types';

export const getSubscriptions = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_SUBSCRIPTIONS,
        loading: true,
      });

      const response = await SubscriptionService.getSubscriptions();

      dispatch({
        type: actionTypes.GET_SUBSCRIPTIONS_SUCCESS,
        payload: response,
        loading: false,
      });

      return response;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_SUBSCRIPTIONS_FAILED,
        payload: error,
        loading: false,
      });
      throw error;
    }
  };
};
