import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { IconButton } from 'components';

import './style.scss';

const NOTIFICATION_TRANSITION_TIMEOUT = 200;

const Notification = ({ notifications = [], close }) => {
  return (
    <div className="Notification">
      <TransitionGroup>
        {notifications.map((child) => {
          return (
            <CSSTransition
              key={child.id}
              appear
              classNames="content-transition"
              timeout={NOTIFICATION_TRANSITION_TIMEOUT}>
              <div className="content">
                {child.content}
                <IconButton
                  type="button"
                  iconClass="lemon icon-close purple"
                  className="close"
                  onClick={() => close(child.id)}
                  aria-label="Close"
                />
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

Notification.propTypes = {
  close: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    })
  ),
};

export default Notification;
