import { combineReducers } from 'redux';

import jobsList from './jobsList';
import seekersList from './seekersList';
import job from './job';
import formsOfEmployment from './formsOfEmployment';

export default combineReducers({
  jobsList,
  seekersList,
  job,
  formsOfEmployment,
});
