import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILED,
  GET_ROLES_BY_TYPE,
  GET_ROLES_BY_TYPE_SUCCESS,
  GET_ROLES_BY_TYPE_FAILED,
} from '../../actions/action-types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
      };
    case GET_ROLES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ROLES_BY_TYPE:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLES_BY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
      };
    case GET_ROLES_BY_TYPE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
