export const subscriptionStatus = {
  UNDEFINED: { id: 0, label: 'Undefined' },
  PENDING: { id: 1, label: 'Pending' },
  ACTIVE: { id: 2, label: 'Active' },
  ON_HOLD: { id: 3, label: 'On Hold' },
  PENDING_CANCELLATION: { id: 4, label: 'Pending Cancellation' },
  CANCELLED: { id: 5, label: 'Cancelled' },
  EXPIRED: { id: 6, label: 'Expired' },
  SWITCHED: { id: 7, label: 'Switched' },
  RENEWED: { id: 8, label: 'Renewed' },
};

export const editableSubscriptionStatus = {
  ACTIVE: subscriptionStatus.ACTIVE,
  CANCELLED: subscriptionStatus.CANCELLED,
};

export const subscriptionState = {
  NO_SUBSCRIPTION: { id: 0, label: 'No subscription', key: 'no-subscription' },
  ACTIVE_SUBSCRIPTION: { id: 1, label: 'Active', key: 'active' },
  EXPIRED_SUBSCRIPTION: { id: 2, label: 'Expired', key: 'expired' },
  IN_FIVE_DAY_EXPIRED: { id: 3, label: 'Soon expire', key: 'soon-expire' },
  PAUSED_SUBSCRIPTION: { id: 4, label: 'Paused', key: 'paused' },
  CANCELLED_SUBSCRIPTION: { id: 5, label: 'Cancelled', key: 'cancelled' },
};
