import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination, withLoading } from 'hoc';
import { TableContainer, ChartAreaIcon } from 'components';
import { MIN_IN_MS, getDaysHoursMinutes, getHumanizedTimeUnits } from 'util/timeUtil';
import ImportanceTag from './ImportanceTag';
import LessonStatusTag from './LessonStatusTag';
import Settings from 'config/settings';
import { horizontalAlignOptions, widthOptions } from 'components/Table/tableConstants';

import { usageTrendIconColor } from '../../../styles/_colors.scss';
import './style.scss';

const LessonsTable = ({
  tableData,
  loading,
  loadTableData,
  params,
  selectItem,
  selectedItem,
  intl: { formatMessage },
}) => {
  const humanizeTime = (min) => {
    const { days, hours, mins } = getDaysHoursMinutes(min * MIN_IN_MS);
    const timeUnitArray = [
      {
        value: days,
        labels: {
          singular: formatMessage({ id: 'TIME_UNITS.DAY' }),
          plural: formatMessage({ id: 'TIME_UNITS.DAYS' }),
        },
      },
      {
        value: hours,
        labels: {
          singular: formatMessage({ id: 'TIME_UNITS.HOUR' }),
          plural: formatMessage({ id: 'TIME_UNITS.HOURS' }),
        },
      },
      {
        value: mins,
        labels: {
          singular: formatMessage({ id: 'TIME_UNITS.MIN' }),
          plural: formatMessage({ id: 'TIME_UNITS.MINS' }),
        },
      },
    ];
    return getHumanizedTimeUnits(timeUnitArray);
  };

  const getItemRowClassName = (rowItem) =>
    rowItem && selectedItem && rowItem.id === selectedItem.id
      ? 'member-course-table-active-row'
      : null;

  return (
    <TableContainer
      recordKey={(lesson = {}) =>
        `${lesson.course_title ? lesson.course_title : 'without_course'}-${lesson.id}`
      }
      sortIndicatorType="caret"
      loading={loading}
      list={tableData}
      loadList={loadTableData}
      params={params}
      getRecordRowClassName={getItemRowClassName}
      columns={[
        {
          key: 'importance',
          label: formatMessage({ id: 'MEMBERS_STATISTICS_TABLE.CATEGORY' }),
          horizontalAlign: horizontalAlignOptions.left,
          width: widthOptions.sm,
          render: (item) => <ImportanceTag isImportant={item && item.isImportant} />,
        },
        {
          key: 'course_title',
          label: formatMessage({ id: 'MEMBERS_STATISTICS_TABLE.COURSE' }),
          sortBy: Settings.SORT_BY.COURSE_TITLE,
          multiLine: true,
          minWidth: widthOptions.sm,
        },
        {
          key: 'lesson_title',
          label: formatMessage({ id: 'MEMBERS_STATISTICS_TABLE.LESSON' }),
          sortBy: Settings.SORT_BY.LESSON_TITLE,
          multiLine: true,
          minWidth: widthOptions.sm,
        },
        {
          key: 'spent_time',
          label: formatMessage({ id: 'MEMBERS_STATISTICS_TABLE.SPENT_TIME' }),
          sortBy: Settings.SORT_BY.SPENT_TIME,
          horizontalAlign: horizontalAlignOptions.left,
          minWidth: widthOptions.sm,
          maxWidth: widthOptions.md,
          multiLine: true,
          render: (item) => (item && item.time_spent ? humanizeTime(item.time_spent) : '-'),
        },
        {
          key: 'test_status',
          label: formatMessage({ id: 'MEMBERS_STATISTICS_TABLE.TEST' }),
          horizontalAlign: horizontalAlignOptions.left,
          sortBy: Settings.SORT_BY.STATUS,
          width: widthOptions.sm,
          render: (item) => <LessonStatusTag status={item && item.status} />,
        },
        {
          key: 'assigned_at',
          label: formatMessage({ id: 'MEMBERS_STATISTICS_TABLE.ASSIGNMENT_DATE' }),
          sortBy: Settings.SORT_BY.ASSIGNMENT_DATE,
          minWidth: widthOptions.sm,
          maxWidth: widthOptions.md,
        },
        {
          key: 'chart',
          render: (item) => (
            <ChartAreaIcon
              noWrapper
              color={usageTrendIconColor}
              onClick={selectItem ? () => selectItem(item) : null}
              disabled={selectedItem && item.id === selectedItem.id}
            />
          ),
        },
      ]}
    />
  );
};

LessonsTable.propTypes = {
  selectedItem: PropTypes.object,
  tableData: PropTypes.array,
  params: PropTypes.object.isRequired,
  loadTableData: PropTypes.func.isRequired,
  selectItem: PropTypes.func,
  loading: PropTypes.bool,
};

export default withLoading(withPagination(injectIntl(LessonsTable)));
