import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withCustomIconStyling from './withCustomIconStyling';

const ExpandIcon = ({ className, ...props }) => {
  return <i className={classNames(className, 'fas fa-expand-alt')} {...props} />;
};

ExpandIcon.propTypes = {
  className: PropTypes.string,
};

export default withCustomIconStyling(ExpandIcon);
