import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useDroppable, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

import { DnDWrapper, PathItemList } from 'components';
import { customCollisionDetectionAlgorithm } from 'util/dndCustomCollisionDetection';
import { createId } from 'util/dndIdCreator';
import { arrayInsertItem } from 'util/arrayInsertItem';
import settings from 'config/settings';

const AbstractPathDnDContainer = ({
  pathItems,
  setPathItems,
  showError,
  renderAddSectionMarker,
  renderSectionMarker: renderSectionMarkerWithoutDnD,
  renderCourse: renderCourseWithoutDnD,
  renderLesson: renderLessonWithoutDnD,
}) => {
  const { setNodeRef } = useDroppable({
    id: settings.DROP_AREA_NAME,
  });
  const { MARKER, COURSE, LESSON } = settings.ITEM_TYPES;

  const handleMarkerSave = (id) => (name) => {
    const index = pathItems.findIndex((item) => {
      const idName = `${item.itemType.toLowerCase()}Id`;
      return item[idName] === id;
    });
    setPathItems(
      arrayInsertItem(pathItems, index + 1, {
        itemType: MARKER,
        markerId: uuidv4(),
        name,
      })
    );
  };

  const renderItem = ({ item, deleteItem }) => {
    const renderMarker = ({ attributes, listeners }) => (
      <>
        {renderSectionMarkerWithoutDnD(item, deleteItem, attributes, listeners)}
        {renderAddSectionMarker({ onAdd: handleMarkerSave(item.markerId) })}
      </>
    );
    const renderCourse = ({ attributes, listeners }) => (
      <>
        {renderCourseWithoutDnD(item, deleteItem, attributes, listeners)}
        {renderAddSectionMarker({ onAdd: handleMarkerSave(item.courseId) })}
      </>
    );
    const renderLesson = ({ attributes, listeners }) => (
      <>
        {renderLessonWithoutDnD(item, deleteItem, attributes, listeners)}
        {renderAddSectionMarker({ onAdd: handleMarkerSave(item.lessonId) })}
      </>
    );
    const selectType = {
      [MARKER]: renderMarker,
      [COURSE]: renderCourse,
      [LESSON]: renderLesson,
    };

    const id = createId(item);
    return (
      <div className="path-item-container">
        <DnDWrapper id={id}>{selectType[item.itemType]}</DnDWrapper>
      </div>
    );
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = pathItems.findIndex((i) => createId(i) === active.id);
      const newIndex = pathItems.findIndex((i) => createId(i) === over.id);
      setPathItems(arrayMove(pathItems, oldIndex, newIndex));
    }
  };

  const deleteItem = (index) => {
    setPathItems(pathItems.filter((_, i) => i !== index));
  };

  return (
    <div
      className={classNames('col-12', 'sortable-container', { 'show-error': showError })}
      ref={setNodeRef}>
      <DndContext
        collisionDetection={customCollisionDetectionAlgorithm}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}>
        <SortableContext
          items={(pathItems || []).map((item) => createId(item))}
          strategy={verticalListSortingStrategy}>
          {renderAddSectionMarker({ onAdd: handleMarkerSave(0) })}
          <PathItemList
            isEmpty={!pathItems || pathItems.length === 0}
            items={pathItems}
            renderSectionMarker={renderItem}
            renderCourse={renderItem}
            renderLesson={renderItem}
            deleteItem={deleteItem}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};

AbstractPathDnDContainer.propTypes = {
  pathItems: PropTypes.array,
  setPathItems: PropTypes.func,
  showError: PropTypes.bool,
  renderAddSectionMarker: PropTypes.func,
  renderSectionMarker: PropTypes.func,
  renderCourse: PropTypes.func,
  renderLesson: PropTypes.func,
};

export default injectIntl(AbstractPathDnDContainer);
