import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withCustomIconStyling from './withCustomIconStyling';

const SearchIcon = ({ className, ...props }) => {
  return <i className={classNames(className, 'fas fa-search')} {...props} />;
};

SearchIcon.propTypes = { className: PropTypes.string };

export default withCustomIconStyling(SearchIcon);
