import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withCustomIconStyling from './withCustomIconStyling';

import { contentChangeTypes } from 'util/statisticsOverviewType';

const TrendIcon = ({ changeType, className, ...props }) => {
  return (
    <i
      className={classNames(
        className,
        ' content-trend-indicator-icon',
        changeType === contentChangeTypes.INCREASE
          ? `far fa-thumbs-up`
          : changeType === contentChangeTypes.DECREASE
          ? `far fa-thumbs-down`
          : 'fas fa-equals'
      )}
      {...props}
    />
  );
};

TrendIcon.propTypes = {
  changeType: PropTypes.oneOf(Object.values(contentChangeTypes)),
};

export default withCustomIconStyling(TrendIcon);
