import React from 'react';
import { injectIntl } from 'react-intl';

import { membersTestStatistics } from 'common/propTypes';
import { CheckIcon, Popover } from 'components';

import {
  testStatsTestsDoneColor,
  testStatsTestsDoneBackgroundColor,
  testStatsTestsSuccessColor,
  testStatsTestsSuccessBackgroundColor,
  testStatsTestsFailureColor,
  testStatsTestsFailureBackgroundColor,
} from '../../../styles/_colors.scss';
import './style.scss';

const TestStats = ({ statistics, intl: { formatMessage } }) => {
  const { successCount, failureCount, totalCount } = statistics || {};

  const getIndicator = (label, value, color, backgroundColor) => (
    <div className="test-stats-indicator col p-1">
      <div className="test-stats-indicator-icon">
        <CheckIcon backgroundColor={backgroundColor} color={color} />
      </div>
      <div className="test-stats-indicator-text">
        <Popover content={label || ''} oneLine placement="top" trigger="hover">
          <div className="test-stats-indicator-text-label">{label}</div>
        </Popover>
        <Popover content={value || ''} oneLine placement="top" trigger="hover">
          <div className="test-stats-indicator-text-value">{value}</div>
        </Popover>
      </div>
    </div>
  );

  const totalIndicator = getIndicator(
    formatMessage({ id: 'MEMBERS_STATISTICS.NUM_OF_TESTS_DONE' }),
    totalCount,
    testStatsTestsDoneColor,
    testStatsTestsDoneBackgroundColor
  );

  const successIndicator = getIndicator(
    formatMessage({ id: 'MEMBERS_STATISTICS.NUM_OF_TESTS_SUCCESS' }),
    successCount,
    testStatsTestsSuccessColor,
    testStatsTestsSuccessBackgroundColor
  );

  const failureIndicator = getIndicator(
    formatMessage({ id: 'MEMBERS_STATISTICS.NUM_OF_TESTS_FAILURE' }),
    failureCount,
    testStatsTestsFailureColor,
    testStatsTestsFailureBackgroundColor
  );

  return (
    <div className="test-stats">
      <div className="test-stats-title">
        {formatMessage({ id: 'MEMBERS_STATISTICS.TEST_STATS' })}
      </div>
      <div className="container-fluid">
        <div className="row">
          {totalIndicator}
          {successIndicator}
          {failureIndicator}
        </div>
      </div>
    </div>
  );
};

TestStats.propTypes = {
  statistics: membersTestStatistics,
};

export default injectIntl(TestStats);
