import PropTypes from 'prop-types';
import { statisticsType } from 'util/statisticsType';

export const location = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  key: PropTypes.string, // only in createBrowserHistory and createMemoryHistory
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]), // only in createBrowserHistory and createMemoryHistory
});

export const history = PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: PropTypes.func.isRequired,
  canGo: PropTypes.func, // only in createMemoryHistory
  createHref: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(location), // only in createMemoryHistory
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  index: PropTypes.number, // only in createMemoryHistory
  length: PropTypes.number,
  listen: PropTypes.func.isRequired,
  location: location.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
});

export const match = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const routeProps = {
  location,
  history,
  match,
};

export const statisticsOverview = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(statisticsType)),
});

export const navigationData = PropTypes.shape({
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['green', 'red', 'grey']),
});

export const basicChartProps = {
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
    bottom: PropTypes.number,
  }),
  height: PropTypes.number.isRequired,
  xAxisDataKey: PropTypes.string.isRequired,
  xAxisPadding: PropTypes.shape({ left: PropTypes.number, right: PropTypes.number }),
  yAxes: PropTypes.arrayOf(
    PropTypes.shape({ yAxisId: PropTypes.string.isRequired, orientation: PropTypes.string })
  ),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideLegend: PropTypes.bool,
};

const chartDataProps = {
  dataKey: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export const chart = PropTypes.shape({
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  xAxisDataKey: PropTypes.string,
  valueKeys: PropTypes.arrayOf(PropTypes.string),
});

export const chartAreaProps = PropTypes.shape({
  ...chartDataProps,
  type: PropTypes.oneOf([
    'basis',
    'basisClosed',
    'basisOpen',
    'linear',
    'linearClosed',
    'natural',
    'monotoneX',
    'monotoneY',
    'monotone',
    'step',
    'stepBefore',
    'stepAfter',
  ]),
  stroke: PropTypes.string.isRequired,
  strokeWidth: PropTypes.number,
}).isRequired;

export const chartBarProps = PropTypes.shape({
  ...chartDataProps,
  fill: PropTypes.string.isRequired,
  barSize: PropTypes.number,
}).isRequired;

export const membersTestStatistics = PropTypes.shape({
  successCount: PropTypes.number,
  failureCount: PropTypes.number,
  totalCount: PropTypes.number,
});

export const assignedGroup = PropTypes.shape({ id: PropTypes.number, name: PropTypes.string });

export const wordCloudExpression = PropTypes.shape({
  word: PropTypes.string,
  count: PropTypes.count,
});

export const wordCloudTag = PropTypes.shape({
  title: PropTypes.string,
  count: PropTypes.count,
});

export const membersChartDetail = PropTypes.shape({
  assignedGroups: PropTypes.arrayOf(assignedGroup),
  testStatistics: membersTestStatistics,
  mostSearchedWords: PropTypes.arrayOf(wordCloudExpression),
  mostUsedTags: PropTypes.arrayOf(wordCloudTag),
});

export const author = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
});

export const companies = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const subsidiary = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  legalName: PropTypes.string,
  companies: companies,
});

export const pathMarker = PropTypes.shape({
  itemType: PropTypes.string,
  name: PropTypes.string,
});

export const pathLesson = PropTypes.shape({
  itemType: PropTypes.string,
  lessonId: PropTypes.number,
  title: PropTypes.string,
});

export const pathCourse = PropTypes.shape({
  courseId: PropTypes.number,
  itemType: PropTypes.string,
  title: PropTypes.string,
  lessons: PropTypes.arrayOf(pathLesson),
});

export const path = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  public: PropTypes.bool,
  shortDescription: PropTypes.string,
  author: author,
  isCorporate: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.string),
  imagesFull: PropTypes.arrayOf(PropTypes.string),
  subsidiary: subsidiary,
  canEdit: PropTypes.bool,
  pathItems: PropTypes.arrayOf(PropTypes.oneOf([pathCourse, pathLesson, pathMarker])),
});

export const popperPlacement = PropTypes.oneOf([
  'top',
  'top-start',
  'top-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'right',
  'right-start',
  'right-end',
  'left',
  'left-start',
  'left-end',
  'auto',
  'auto-start',
  'auto-end',
]);

export const selectOption = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
});

export const inputErrorPropTypes = {
  hasErrorComponent: PropTypes.bool,
  hasErrorMessage: PropTypes.bool,
  hideValue: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export const tabData = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClass: PropTypes.string,
  hasError: PropTypes.bool,
  hide: PropTypes.bool,
  componentWrapperClass: PropTypes.string,
  forceRender: PropTypes.bool,
});
