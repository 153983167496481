import settings from 'config/settings';

const { MARKER, COURSE, LESSON } = settings.ITEM_TYPES;

export const createId = (item) => {
  const selectType = {
    lesson: (item) => {
      return `${LESSON}${item.lessonId}`;
    },
    course: (item) => {
      return `${COURSE}${item.courseId}`;
    },
    marker: (item) => {
      return `${MARKER}${item.markerId}`;
    },
  };

  return selectType[item.itemType?.toLowerCase()](item);
};
