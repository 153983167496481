import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import {
  CompanySubsidiaryChooser,
  ImageFileUploadField,
  TextAreaField,
  TextField,
  Switcher,
  InlineError,
} from 'components';
import { getPlainUrl } from 'util/imagesMapper';
import PathDnDContainer from '../PathDnDContainerField/PathDnDContainer';

const PathEditorForm = ({
  disabled,
  hasCompanyPathEditor = true,
  pathItems,
  setPathItems,
  pathEditorFieldName,
  intl: { formatMessage },
}) => {
  const { errors, touched } = useFormikContext();

  const errorMessage = get(errors, pathEditorFieldName);
  const hasErrorMessage = Boolean(errorMessage && get(touched, pathEditorFieldName));

  return (
    <>
      <div className="row">
        <div className="col-12 path-form-container">
          <div className="row">
            <div className="col-3 learning-path-image-upload">
              <Field
                name="image"
                component={ImageFileUploadField}
                required
                deletable
                newModal
                disabled={disabled}
                getFileUrl={getPlainUrl}
                multi={false}
              />
            </div>
            <div className="col-9">
              <div className="row">
                <div className="path-company-chooser col-12">
                  {hasCompanyPathEditor ? (
                    <CompanySubsidiaryChooser
                      selectClasses="col-6"
                      companyInputName="company"
                      subsidiaryInputName="subsidiary"
                      companyLabel={formatMessage({ id: 'COMPANIES.COMPANY_REQ' })}
                      subsidiaryLabel={formatMessage({ id: 'SUBSIDIARY.TITLE_REQ' })}
                      companyOptionAsObject
                      subsidiaryOptionAsObject
                      disabled={disabled}
                    />
                  ) : null}
                </div>
              </div>
              <Field
                name="title"
                component={TextField}
                label={<FormattedMessage id="COMMON.TITLE_REQ" />}
                disabled={disabled}
              />

              <Field
                name="shortDescription"
                component={TextAreaField}
                label={<FormattedMessage id="COMMON.DESCRIPTION_REQ" />}
                disabled={disabled}
                rows={1}
              />

              <Field
                name="public"
                component={Switcher}
                disabled={disabled}
                onText="Public"
                offText="Private"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 order-2">
        <PathDnDContainer
          pathItems={pathItems}
          setPathItems={setPathItems}
          pathEditorFieldName={pathEditorFieldName}
          hasErrorMessage={hasErrorMessage}
        />
        {hasErrorMessage && <InlineError show={hasErrorMessage} message={errorMessage} />}
      </div>
    </>
  );
};

PathEditorForm.propTypes = {
  disabled: PropTypes.bool,
  hasCompanyPathEditor: PropTypes.bool,
  pathItems: PropTypes.array,
  setPathItems: PropTypes.func,
  pathData: PropTypes.object,
  pathEditorFieldName: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PathEditorForm);
