import * as RegistrationService from '../services/continueRegistration';

import * as actionTypes from 'actions/action-types';

export const continueRegistration = (registrationObj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.CONTINUE_REGISTRATION,
      });

      const result = RegistrationService.continueRegistration(registrationObj);

      dispatch({
        type: actionTypes.CONTINUE_REGISTRATION_SUCCESS,
      });

      return result;
    } catch (error) {
      dispatch({
        type: actionTypes.CONTINUE_REGISTRATION_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

//Currently unused
export const getBloodTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_BLOOD_TYPES,
      });

      const bloodTypes = await RegistrationService.getBloodTypes();

      dispatch({
        type: actionTypes.GET_BLOOD_TYPES_SUCCESS,
        payload: bloodTypes,
      });

      return bloodTypes;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_BLOOD_TYPES_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
