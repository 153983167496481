export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';

export const GET_DASHBOARD_TOP_DATA = 'GET_DASHBOARD_TOP_DATA';
export const GET_DASHBOARD_TOP_DATA_FAILED = 'GET_DASHBOARD_TOP_DATA_FAILED';
export const GET_DASHBOARD_TOP_DATA_SUCCESS = 'GET_DASHBOARD_TOP_DATA_SUCCESS';

export const GET_DASHBOARD_COURSES_DATA = 'GET_DASHBOARD_COURSES_DATA';
export const GET_DASHBOARD_COURSES_DATA_FAILED = 'GET_DASHBOARD_COURSES_DATA_FAILED';
export const GET_DASHBOARD_COURSES_DATA_SUCCESS = 'GET_DASHBOARD_COURSES_DATA_SUCCESS';

export const GET_DASHBOARD_USERS_DATA = 'GET_DASHBOARD_USERS_DATA';
export const GET_DASHBOARD_USERS_DATA_FAILED = 'GET_DASHBOARD_USERS_DATA_FAILED';
export const GET_DASHBOARD_USERS_DATA_SUCCESS = 'GET_DASHBOARD_USERS_DATA_SUCCESS';

export const GET_DASHBOARD_USER_DATA = 'GET_DASHBOARD_USER_DATA';
export const GET_DASHBOARD_USER_DATA_FAILED = 'GET_DASHBOARD_USER_DATA_FAILED';
export const GET_DASHBOARD_USER_DATA_SUCCESS = 'GET_DASHBOARD_USER_DATA_SUCCESS';

export const GET_DASHBOARD_REGISTERED_USER_DATA = 'GET_DASHBOARD_REGISTERED_USER_DATA';
export const GET_DASHBOARD_REGISTERED_USER_DATA_FAILED =
  'GET_DASHBOARD_REGISTERED_USER_DATA_FAILED';
export const GET_DASHBOARD_REGISTERED_USER_DATA_SUCCESS =
  'GET_DASHBOARD_REGISTERED_USER_DATA_SUCCESS';
