export const templateTypes = {
  SHORTTEXT: { label: 'SHORTTEXT', id: 0 },
  LONGTEXT: { label: 'LONGTEXT', id: 1 },
  VIDEO: { label: 'VIDEO', id: 2 },
  IMAGE: { label: 'IMAGE', id: 3 },
};

export const templateLevel = {
  BEGINNER: { label: '1', hoverLabel: 'Generated Test for level 1', id: 0, sec: 30 },
  INTERMEDIATE: { label: '2', hoverLabel: 'Generated Test for level 2', id: 1, sec: 20 },
  ADVANCED: { label: '3', hoverLabel: 'Generated Test for level 3', id: 2, sec: 15 },
};

export const notAllowedToSearch = (type) =>
  type === templateTypes.IMAGE.id || type === templateTypes.VIDEO.id;

export const notAllowedToGenerate = (type) =>
  type === templateTypes.IMAGE.id || type === templateTypes.VIDEO.id;

export const notAllowedToShow = (type) =>
  type === templateTypes.IMAGE.id || type === templateTypes.VIDEO.id;

export const notAllowedToGeneral = (type) =>
  type === templateTypes.IMAGE.id || type === templateTypes.VIDEO.id;
