import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { AreaChart, ChartContainer } from 'components';
import { getLessonUsage } from 'services/statistics';
import { getChartLabelTraslateNameId } from 'util/chartRenderUtil';
import { secondaryChartConstants } from '../chartConstants.js';
import { memberTypeParam } from 'util/statisticsOverviewType';

const height = 400;

const UsageTrendChart = ({
  memberId,
  selectedItemId,
  startDate,
  endDate,
  isGroup,
  intl: { formatMessage },
  ...props
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { chartAreas, xAxisDataKey, yAxes: yAxesData } = secondaryChartConstants || {};

  useEffect(() => {
    const loadUsageChartData = async (selectedItemId) => {
      try {
        const convertedParams = {
          type: isGroup ? memberTypeParam.GROUP : memberTypeParam.USER,
          lessonId: selectedItemId,
          modelId: memberId,
          from: startDate,
          to: endDate,
        };
        setLoading(true);
        const res = await getLessonUsage(convertedParams);
        setData(res && res.activityData ? res.activityData : []);
      } catch (e) {
        setData([]);
        throw e;
      } finally {
        setLoading(false);
      }
    };
    loadUsageChartData(selectedItemId);
  }, [selectedItemId, memberId, startDate, endDate, isGroup]);

  const getAreas = () => {
    return (chartAreas || []).map(({ key, color, ...props }) => ({
      dataKey: key,
      stroke: color,
      name: getChartLabelTraslateNameId(key)
        ? formatMessage({ id: getChartLabelTraslateNameId(key) })
        : key,
      ...props,
    }));
  };

  const yAxes = (yAxesData || []).map(({ labelTranslateId, ...props }) => ({
    label: formatMessage({ id: labelTranslateId }),
    ...props,
  }));

  return (
    <ChartContainer
      chartTitle={formatMessage({ id: 'MEMBERS_STATISTICS.SECONDARY_CHART_TITLE' })}
      noTimeFilter
      loading={loading}
      {...props}>
      <AreaChart
        loading={loading}
        hideLegend
        height={height}
        data={data}
        xAxisDataKey={xAxisDataKey}
        areas={getAreas()}
        yAxes={yAxes}
      />
    </ChartContainer>
  );
};

UsageTrendChart.propTypes = {
  selectedItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  memberId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default injectIntl(UsageTrendChart);
