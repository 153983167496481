import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { DatePicker, TableFilter } from 'components';
import CoursesSummaryChart from '../CoursesSummaryChart';
import RegisteredUsers from '../RegisteredUsers/RegisteredUsers';

const Courses = ({
  intervalMin,
  intervalMax,
  groups,
  coursesData,
  changeCourse,
  course,
  filter,
  usersData,
  type,
  coursesChartDisable,
  loading,
  filterUserList,
  onUserClick,
  onChangedInterval,
  pokeUsers,
  intl,
}) => {
  const { formatMessage } = intl;

  return (
    <Fragment>
      <div className="col-12 p-0 position-relative">
        <div className="time-pickers-and-filter">
          <div className="d-flex mt-2">
            <DatePicker
              value={intervalMin}
              label={<FormattedMessage id="DASHBOARD.INTERVAL_MIN" />}
              placeholder={formatMessage({ id: 'USERS.BIRTH_DATE_REQ' })}
              maxDate={new Date()}
              customInputRef="to-not-disturb-select"
              onChange={(intervalMin) => onChangedInterval({ intervalMin, intervalMax })}
            />

            <div className="space" />

            <DatePicker
              value={intervalMax}
              label={<FormattedMessage id="DASHBOARD.INTERVAL_MAX" />}
              placeholder={formatMessage({ id: 'USERS.BIRTH_DATE_REQ' })}
              maxDate={new Date()}
              customInputRef="to-not-disturb-select"
              onChange={(intervalMax) => onChangedInterval({ intervalMax, intervalMin })}
            />
          </div>
        </div>
        <CoursesSummaryChart
          className="chart-container"
          data={coursesData}
          changeCourse={changeCourse}
          disabled={coursesChartDisable}
          loading={loading}
        />
      </div>
      {!coursesChartDisable ? (
        <div className="details col-12 flex-wrap">
          <div className="col-12 pre-header align-items-center px-3">
            {!course ? (
              <FormattedMessage id="DASHBOARD.ALL_USERS" />
            ) : (
              <FormattedMessage id="DASHBOARD.USERS_BY_COURSE" values={{ name: course.title }} />
            )}

            <div className="search-container ms-auto">
              <TableFilter filter={filter} loadList={filterUserList} />
            </div>
          </div>
          <RegisteredUsers
            hideFilter
            type={type}
            onUserClick={onUserClick}
            usersData={usersData}
            pokeUsers={pokeUsers}
            groups={groups}
          />
        </div>
      ) : null}
    </Fragment>
  );
};

Courses.propTypes = {
  intervalMin: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
  intervalMax: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
  groups: PropTypes.array.isRequired,
  coursesData: PropTypes.array.isRequired,
  course: PropTypes.object,
  filter: PropTypes.string.isRequired,
  usersData: PropTypes.array.isRequired,
  type: PropTypes.number.isRequired,
  coursesChartDisable: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  changeCourse: PropTypes.func.isRequired,
  filterUserList: PropTypes.func.isRequired,
  onUserClick: PropTypes.func.isRequired,
  onChangedInterval: PropTypes.func.isRequired,
  pokeUsers: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Courses);
