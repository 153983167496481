import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import isAfter from 'date-fns/isAfter';

import Settings from 'config/settings';
import { Collapse } from 'components';
import { statisticsType, statisticsTypeConstants } from 'util/statisticsType';
import { getFormattedDate } from 'util/timeUtil';
import BaseStatistics from './BaseStatistics';
import BaseStatisticsLabel from './BaseStatisticsLabel';
import GoTo from './GoTo/GoTo';

import {
  statisticsTimePeriods,
  statisticsTimePeriodsConstants,
  getDefaultStartDate,
  getDefaultEndDate,
} from 'util/statisticsTimeUtil';

import './style.scss';

const BaseStatisticsComponent = ({ baseData, type, profile }) => {
  const { defaultOption: intervalDefaultOption, options: intervalOptions } =
    (statisticsTypeConstants[type] && statisticsTypeConstants[type].intervalConfig) || {};

  const getLabelFilter = (type) =>
    (statisticsTypeConstants[type] && statisticsTypeConstants[type].labelFilter) || {};

  const getChildFilter = (type) =>
    (statisticsTypeConstants[type] && statisticsTypeConstants[type].childFilter) || {};

  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState(null);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [startDate, setStartDate] = useState(getDefaultStartDate(intervalDefaultOption));
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [interval, setInterval] = useState(intervalDefaultOption);
  const [labelFilter, setLabelFilter] = useState(getLabelFilter(type).defaultFilter);
  const [childFilter, setChildFilter] = useState(getChildFilter(type).defaultFilter);

  useEffect(() => {
    const loadStatistics = async () => {
      setLoading(true);
      try {
        const res = await statisticsTypeConstants[type].fetchStatistic({
          ...baseData,
          startDate: getFormattedDate(startDate),
          endDate: getFormattedDate(endDate),
          ...(childFilter ? childFilter : {}),
          ...(labelFilter ? labelFilter : {}),
        });
        setStatistics(res);
      } finally {
        setLoading(false);
      }
    };

    if (isAccordionOpen) {
      loadStatistics();
    }
  }, [isAccordionOpen, startDate, endDate, type, childFilter, labelFilter, baseData]);

  const setFilterIfExists = (data, getFilterData, setFilterData) => {
    if (getFilterData) {
      setFilterData(getFilterData(data));
    }
  };

  const handleSetLabelFilter = (data) => {
    setFilterIfExists(data, getLabelFilter(type).getFilterData, setLabelFilter);
  };

  const handleSetChildFilter = (data) => {
    setFilterIfExists(data, getChildFilter(type).getFilterData, setChildFilter);
  };

  const handleDateChange = (start) => (date) => {
    const setOtherDate = start ? setEndDate : setStartDate;
    const setDate = start ? setStartDate : setEndDate;

    if (interval !== statisticsTimePeriods.NO_PERIOD) {
      setOtherDate(statisticsTimePeriodsConstants[interval].getChangeDate(date, start));
    }
    setDate(date);
  };

  const handleStartDateChange = (date) => handleDateChange(true)(date);

  const handleEndDateChange = (date) => handleDateChange(false)(date);

  const handlePeriodChange = (interval) => {
    if (interval !== statisticsTimePeriods.NO_PERIOD) {
      const endDateCandidate = statisticsTimePeriodsConstants[interval].getChangeDate(startDate);

      if (isAfter(endDateCandidate, Settings.MAX_STATISTICS_DATE)) {
        setEndDate(Settings.MAX_STATISTICS_DATE);
        setStartDate(
          statisticsTimePeriodsConstants[interval].getChangeDate(
            Settings.MAX_STATISTICS_DATE,
            false
          )
        );
      } else {
        setEndDate(endDateCandidate);
      }
    }
    setInterval(interval);
  };

  const maxStartDate =
    interval !== statisticsTimePeriods.NO_PERIOD
      ? statisticsTimePeriodsConstants[interval].getChangeDate(new Date(), false)
      : endDate;

  const minEndDate =
    interval !== statisticsTimePeriods.NO_PERIOD
      ? statisticsTimePeriodsConstants[interval].getChangeDate(Settings.MIN_STATISTICS_DATE)
      : startDate;

  const minStartDate = Settings.MIN_STATISTICS_DATE;
  const maxEndDate = new Date();

  const hasRoleToNavigate = !!statisticsTypeConstants[type].hasRoleToNavigate(profile);

  return (
    <div className="base-statistics-component-container">
      <Collapse
        open={isAccordionOpen}
        setOpen={setAccordionOpen}
        headerClassName={classNames('accordion-header', { open: isAccordionOpen })}
        titleClassName="accordion-title"
        title={
          <div className="base-statistics-component-header container-fluid">
            <div className="row align-items-center">
              <div className="col-12 col-lg-9">
                <BaseStatisticsLabel
                  baseData={baseData}
                  type={type}
                  labelFilter={labelFilter}
                  handleSetLabelFilter={handleSetLabelFilter}
                  loading={loading}
                  isAccordionOpen={isAccordionOpen}
                  setAccordionOpen={setAccordionOpen}
                />
              </div>
              <div className="base-statistics-component-header-navigation-button-container col-12 col-lg-3">
                {hasRoleToNavigate && <GoTo type={type} baseData={baseData} />}
              </div>
            </div>
          </div>
        }
        className="base-statistics-component-collapse">
        {({ shown }) => (
          <div className="statistics-container">
            <BaseStatistics
              baseData={baseData}
              loading={loading}
              startDate={startDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              handlePeriodChange={handlePeriodChange}
              minStartDate={minStartDate}
              maxStartDate={maxStartDate}
              minEndDate={minEndDate}
              maxEndDate={maxEndDate}
              intervalOptions={intervalOptions}
              endDate={endDate}
              interval={interval}
              type={type}
              statistics={statistics}
              shown={shown}
              handleSetChildFilter={handleSetChildFilter}
              setAccordionOpen={setAccordionOpen}
              labelFilter={labelFilter}
            />
          </div>
        )}
      </Collapse>
    </div>
  );
};

BaseStatisticsComponent.propTypes = {
  baseData: PropTypes.object.isRequired,
  type: PropTypes.oneOf(Object.values(statisticsType)),
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.users.profile,
  };
};

export default connect(mapStateToProps)(BaseStatisticsComponent);
