import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withPagination } from 'hoc';
import { TableContainer, DeleteControl } from 'components';
import { widthOptions, horizontalAlignOptions } from 'components/Table/tableConstants';
import Settings from 'config/settings';
import { LessonAccess } from 'libs/accessManagement';

const TestsTable = ({
  list,
  loadList,
  disabled,
  params,
  profile,
  lesson,
  openDeleteModal,
  openEditModal,
  intl: { formatMessage },
}) => {
  const getCheckIcon = (show) => (show ? <i className="lemon icon-check" /> : null);

  const getTestTypeAsString = (test = {}) =>
    Settings.QUESTION_TYPES.find((question) => question.id === test.type).title;

  const getExamQuestionContent = (test = {}) => getCheckIcon(test.isExamQuestion);
  const getTestQuestionContent = (test = {}) => getCheckIcon(test.isTestQuestion);

  const {
    TestAccess: { canEdit, canDelete },
  } = LessonAccess.getHasAccess(profile.profile, lesson);

  return (
    <TableContainer
      list={list}
      loadList={loadList}
      params={params}
      columns={[
        {
          key: 'edit',
          hide: disabled || !canEdit,
          iconCell: true,
          onClick: (group) => openEditModal(group),
          editIcon: true,
          width: widthOptions.xs,
        },
        {
          key: 'type',
          width: widthOptions.md,
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'COMMON.TYPE' }),
          render: (test) => getTestTypeAsString(test),
        },
        {
          key: 'question',
          width: widthOptions.lg,
          horizontalAlign: horizontalAlignOptions.left,
          fillRemainingSpace: true,
          label: formatMessage({ id: 'TESTS.QUESTION' }),
        },
        {
          key: 'exam',
          width: widthOptions.md,
          onlyShowHeaderTooltipOnOverflow: false,
          headerTooltip: formatMessage({ id: 'TESTS.EXAM_EXPLANATION' }),
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'TESTS.EXAM' }),
          render: (test) => getExamQuestionContent(test),
        },
        {
          key: 'test',
          width: widthOptions.md,
          onlyShowHeaderTooltipOnOverflow: false,
          headerTooltip: formatMessage({ id: 'TESTS.TEST_EXPLANATION' }),
          horizontalAlign: horizontalAlignOptions.left,
          label: formatMessage({ id: 'TESTS.TEST' }),
          render: (test) => getTestQuestionContent(test),
        },
        {
          key: 'delete',
          render: (test) => (
            <DeleteControl
              show={!disabled && canDelete}
              enabled={!disabled && canDelete}
              item={test}
              deleteItem={openDeleteModal}
            />
          ),
        },
      ]}
    />
  );
};

TestsTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  loadList: PropTypes.func,
  disabled: PropTypes.bool,
  params: PropTypes.object,
  profile: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withPagination(injectIntl(TestsTable));
