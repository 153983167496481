import React from 'react';
import PropTypes from 'prop-types';
import { BarChart as RechartBarChart, ResponsiveContainer } from 'recharts';

import useChartElements from '../useChartElements';
import withChartDataCheck from '../withChartDataCheck';
import { defaultChartMargin } from '../chartUtil';
import { basicChartProps, chartBarProps } from 'common/propTypes';

import '../style.scss';

const BarChart = ({
  data,
  bars: barsProp,
  xAxisDataKey,
  xAxisPadding,
  height,
  hideLegend,
  margin,
  tooltipBorderColor,
  yAxes,
  ...props
}) => {
  const { bars, commonChartElements } = useChartElements({
    xAxisDataKey,
    xAxisPadding,
    tooltipBorderColor,
    hideLegend,
    bars: barsProp,
    yAxes,
  });

  return (
    <ResponsiveContainer width={'100%'} height={height}>
      <RechartBarChart data={data} margin={margin ? margin : defaultChartMargin} {...props}>
        {commonChartElements}
        {bars}
      </RechartBarChart>
    </ResponsiveContainer>
  );
};

BarChart.propTypes = {
  ...basicChartProps,
  bars: PropTypes.arrayOf(chartBarProps).isRequired,
  tooltipBorderColor: PropTypes.string,
};

export default withChartDataCheck(BarChart);
