import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SearchInput } from 'components';

import './style.scss';

const delay = 800;

const TableFilter = ({ loadList, filter, placeholder, hasUnderline, disabled }) => {
  const [filterText, setFilterText] = useState(filter || '');

  const debouncedLoadList = useCallback(
    debounce(() => {
      loadList(filterText, 1);
    }, delay),
    [filterText]
  );

  const onChange = (e) => {
    setFilterText(e.target.value);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (loadList) {
        loadList(filterText, 1);
      }
      e.preventDefault();
    }
  };

  useEffect(() => {
    debouncedLoadList();
    return () => {
      debouncedLoadList.cancel();
    };
  }, [filterText, debouncedLoadList]);

  const value = filterText || '';

  return (
    <div
      className={classNames('basic-table-filter', {
        'basic-table-filter--has-underline': hasUnderline,
      })}>
      <SearchInput
        onChange={onChange}
        value={value}
        name="filter"
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

TableFilter.propTypes = {
  loadList: PropTypes.func.isRequired,
  filter: PropTypes.string,
  placeholder: PropTypes.string,
  hasUnderline: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TableFilter;
