import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'components';

const TransferListAction = ({ iconClass, onClick, disabled }) => (
  <div className="ActionIconContiner">
    <IconButton iconClass={iconClass} onClick={onClick} disabled={disabled} />
  </div>
);

TransferListAction.propTypes = {
  iconClass: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default TransferListAction;
